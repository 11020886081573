import React from 'react'

import styled from 'styled-components'

import isLangSelect from 'helpers/isLangSelect'
import useLocalStorageState from 'hooks/useLocalStorageState'
import { CurrentUser } from 'views/components/CurrentUser/CurrentUser'
import { I18nLangSelect } from 'views/components/I18nLangSelect/I18nLangSelect'
import MainMenu from 'views/components/MainMenu/MainMenu'
import { Bar, Layout, Logo, Page, Sidebar } from 'views/layouts/MainLayout/MainLayout'
import { ToggleArrow } from 'views/ui/ToggleArrow/ToggleArrow'

const StyledBarContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const StyledBarBottom = styled.div`
  margin-top: auto;
`

const LangWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 8px;
`

const ToggleArrowWrapper = styled.div`
  position: absolute;
  top: 68px;
  left: 50%;
  transform: translate(-50%);
`

const PageWrapper: React.FC = ({ children }) => {
  const [collapsed, setCollapsed] = useLocalStorageState<boolean>(false, 'sidebarMenuCollapsed')

  const width = collapsed ? 72 : undefined

  return (
    <Layout>
      <Sidebar width={width}>
        <ToggleArrowWrapper>
          <ToggleArrow rotated={collapsed} onClick={() => setCollapsed(c => !c)} />
        </ToggleArrowWrapper>

        <Logo hideText={collapsed} />
        <Bar>
          <StyledBarContent>
            <MainMenu collapsed={collapsed} />

            <StyledBarBottom>
              {isLangSelect() && (
                <LangWrapper>
                  <I18nLangSelect />
                </LangWrapper>
              )}
              <CurrentUser collapsed={collapsed} />
            </StyledBarBottom>
          </StyledBarContent>
        </Bar>
      </Sidebar>
      <Page>{children}</Page>
    </Layout>
  )
}

export default PageWrapper
