import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Deal, DealBid } from 'modules/domain/deal/types'
import { User } from 'modules/domain/user/types'
import { WarehouseModal } from 'views/components/WarehouseModal/WarehouseModal'
import { WarehouseProps } from 'views/components/WarehouseProps/WarehouseProps'
import * as S from 'views/pages/Deal/DealEdit/styled'

type Props = {
  deal: Deal
}

export const WAREHOUSE_MODAL_OPEN = 'warehouseModalOpen'
const event = new Event(WAREHOUSE_MODAL_OPEN)

export const Warehouse: React.FC<Props> = ({ deal }) => {
  const { t } = useTranslation('warehouse')

  const saleAddressId = (deal.sale_bid as DealBid).address.id
  const purchaseAddressId = (deal.purchase_bid as DealBid).address.id
  const seller = deal.sale_bid.owner as User
  const customer = deal.purchase_bid.owner as User

  const dispatchEvent = useCallback(() => {
    document.dispatchEvent(event)
  }, [])

  return (
    <S.WarehouseWrapper>
      <S.WarehouseHeaderWrapper>
        <S.CardHeaderTitle>{t('loading')}</S.CardHeaderTitle>
        <WarehouseModal userId={seller.id} addressId={saleAddressId} type="loading" onClickEdit={dispatchEvent} />
      </S.WarehouseHeaderWrapper>
      <S.WarehouseHeaderWrapper>
        <S.CardHeaderTitle>{t('unloading')}</S.CardHeaderTitle>
        <WarehouseModal
          userId={customer.id}
          addressId={purchaseAddressId}
          type="unloading"
          onClickEdit={dispatchEvent}
        />
      </S.WarehouseHeaderWrapper>
      <S.WarehousePropsWrapper>
        <WarehouseProps type="loading" deal={deal} />
      </S.WarehousePropsWrapper>
      <S.WarehousePropsWrapper>
        <WarehouseProps type="unloading" deal={deal} />
      </S.WarehousePropsWrapper>
    </S.WarehouseWrapper>
  )
}
