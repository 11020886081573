import React from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'
import { CellProps } from 'react-table'

import { AdvancedHeadCell, Button, Chip, useAction } from '@agro-club/agroclub-shared'

import useLocationFrom from 'hooks/useLocationFrom'
import TaskActions from 'modules/domain/task/duck'
import { Task } from 'modules/domain/task/types'
import BidRoutes from 'views/pages/Bid/routes'
import DealRoutes from 'views/pages/Deal/routes'
import UserRoutes from 'views/pages/User/routes'
import { BlueLink } from 'views/styled/common'

export const TaskSortableHeadCell = AdvancedHeadCell<keyof Task>()

export const LinkedUserCell: React.FC<CellProps<Task>> = ({ row }) => {
  const name = row.original?.linked_user?.short_name?.trim()
  const phone = row.original?.linked_user?.phone
  const id = row.original?.linked_user?.id
  const from = useLocationFrom()
  if (!id) {
    return null
  }
  return (
    <BlueLink
      onClick={e => {
        e.stopPropagation()
      }}
      to={{
        pathname: generatePath(UserRoutes.Detail, { id }),
        state: { from },
      }}
    >
      {[name, phone].filter(Boolean).join(' / ')}
    </BlueLink>
  )
}

export const LinkedDealCell: React.FC<CellProps<Task>> = ({ row }) => {
  const seller = row.original?.linked_deal?.sale_bid?.company?.name
  const customer = row.original?.linked_deal?.sale_bid?.company?.name
  const product = row.original?.linked_deal?.product?.title
  const id = row.original?.linked_deal?.id
  const from = useLocationFrom()
  if (!id) {
    return null
  }
  return (
    <BlueLink
      onClick={e => {
        e.stopPropagation()
      }}
      to={{
        pathname: generatePath(DealRoutes.Edit, { id }),
        state: { from },
      }}
    >
      {!!product && <div>{product}</div>}
      {!!seller && <div>{seller}</div>}
      {!!customer && <div>{customer}</div>}
    </BlueLink>
  )
}

export const LinkedBidCell: React.FC<CellProps<Task>> = ({ row }) => {
  const linkedBid = row.original.linked_bid
  const from = useLocationFrom()

  if (!linkedBid?.id) {
    return null
  }
  return (
    <BlueLink
      onClick={e => {
        e.stopPropagation()
      }}
      to={{
        pathname: generatePath(BidRoutes.Edit, { id: linkedBid.id }),
        state: { from },
      }}
    >
      <div>{linkedBid.company.name}</div>
    </BlueLink>
  )
}

export const TaskStatusCell: React.FC<CellProps<Task>> = ({ row }) => {
  const { t } = useTranslation('task')
  const completeAction = useAction(TaskActions.completeTaskRequested)
  return row.original.is_completed ? (
    <Chip size="small" color="green">
      {t('complete')}
    </Chip>
  ) : (
    <>
      <div>{t('notComplete')}</div>
      <Button
        size="small"
        onClick={e => {
          e.stopPropagation()
          completeAction(row.original.id)
        }}
        intent="clear"
      >
        {t('makeComplete')}
      </Button>
    </>
  )
}
