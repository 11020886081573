import React, { useCallback, useContext, useMemo } from 'react'
import { useAudioPlayer } from 'react-use-audio-player'

import { IconPause, IconPlay } from '@agro-club/agroclub-shared'

import { PlayerContext } from 'views/components/Player/PlayerProvider'
import { PlayerSeekBar } from 'views/components/Player/PlayerSeekBar'
import { Button, PlayerWrapper } from 'views/components/Player/styled'

interface Props {
  file: string
  duration: number
}

export function Player({ file, duration }: Props) {
  const { play } = useContext(PlayerContext)
  const { player, playing, togglePlayPause } = useAudioPlayer()

  const isActive = useMemo(() => file === player?._src, [file, player])

  const isPlaying = useMemo(() => file === player?._src && playing, [file, player, playing])

  const toggle = useCallback(() => {
    play(file)
    togglePlayPause()
  }, [file, play, togglePlayPause])

  return (
    <PlayerWrapper>
      <Button onClick={toggle} isPlaying={isPlaying}>
        {isPlaying ? <IconPause /> : <IconPlay />}
      </Button>
      <PlayerSeekBar isActive={isActive} duration={duration} />
    </PlayerWrapper>
  )
}

export default React.memo(Player)
