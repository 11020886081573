import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useAction } from '@agro-club/agroclub-shared'
import { isNil } from 'ramda'

import CarsActions from 'modules/domain/cars/duck'
import CarsSelectors from 'modules/domain/cars/selectors'
import { Filters } from 'views/components/CommonTableComponents/CommonTableComponents'
import { ResetFilterButton } from 'views/components/TableFilters/ResetFilterButton'
import { Filter } from 'views/components/TableFilters/TableFilters'
import { CarStatusFilter } from 'views/pages/Cars/CarsTable/CarStatusFilter'
import { CarTypeFilter } from 'views/pages/Cars/CarsTable/CarTypeFilter'
import { IsCharterFilter } from 'views/pages/Cars/CarsTable/IsCharterFilter'
import { IsTruckFilter } from 'views/pages/Cars/CarsTable/IsTruckFilter'
import { SideHeightFilter } from 'views/pages/Cars/CarsTable/SideHeightFilter'
import { TrailerFilter } from 'views/pages/Cars/CarsTable/TrailerFilter'
import { UploadingTypeFilter } from 'views/pages/Cars/CarsTable/UploadingTypeFilter'

const CarsFilter: React.FC = () => {
  const { t } = useTranslation(['cars', 'common'])

  const filterUpdated = useAction(CarsActions.filterUpdated)
  const filterState = useSelector(CarsSelectors.filter)

  const handleClearFilters = useCallback(() => {
    filterUpdated({})
  }, [filterUpdated])

  const isFilterApplied = Object.values(filterState).some(value => !isNil(value))

  return (
    <Filters>
      <Filter title={t('filters.trailer')}>
        <TrailerFilter />
      </Filter>
      <Filter title={t('filters.status')}>
        <CarStatusFilter />
      </Filter>
      <Filter title={t('filters.car_type')}>
        <CarTypeFilter />
      </Filter>
      <Filter title={t('filters.unloading_type')}>
        <UploadingTypeFilter />
      </Filter>
      <Filter title={t('filters.side_height')}>
        <SideHeightFilter />
      </Filter>
      <Filter title={t('filters.is_dump_truck')}>
        <IsTruckFilter />
      </Filter>
      <Filter title={t('filters.is_in_charter')}>
        <IsCharterFilter />
      </Filter>
      <ResetFilterButton {...{ handleClearFilters, isFilterApplied }} />
    </Filters>
  )
}

export default CarsFilter
