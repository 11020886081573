import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { CountryPhoneInput, Progress, useAction } from '@agro-club/agroclub-shared'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import i18n from 'i18n'
import AuthActions from 'modules/domain/auth/duck'
import AuthSelectors from 'modules/domain/auth/selectors'
import { getCountryCodes, getDefaultCountryCode } from 'views/pages/Auth/countryCodes'

import { ErrorWrapper, FieldWrapper, FormWrapper, SubmitButton } from './styled'

type FormFields = {
  phone: string
}

const validationSchema = Yup.object({
  phone: Yup.string().required(i18n.t('validation:phone_required')),
})

const GetCodeForm: React.FC = () => {
  const { t } = useTranslation(['auth', 'common'])

  const submitAction = useAction(AuthActions.codeRequested)
  const error = useSelector(AuthSelectors.getCodeError)
  const initPhone = useSelector(AuthSelectors.getPhone)
  const progress = useSelector(AuthSelectors.codeRequestedProgress)

  const formik = useFormik<FormFields>({
    initialValues: { phone: initPhone },
    validationSchema,
    onSubmit: submitAction,
  })

  const handleSubmit = useCallback(() => {
    if (formik.isValid && formik.dirty) {
      formik.submitForm()
    }
  }, [formik])

  return (
    <FormWrapper
      onSubmit={e => {
        e.preventDefault()
        handleSubmit()
      }}
    >
      <FieldWrapper>
        <CountryPhoneInput
          defaultCountryCode={getDefaultCountryCode()}
          countryOptions={getCountryCodes()}
          countryOptionsProgress={Progress.SUCCESS}
          name="phone"
          phoneNumber={formik.values.phone}
          onBlur={() => {
            formik.setFieldTouched('phone', true)
          }}
          onChange={(phone: string) => {
            formik.setFieldValue('phone', phone)
            formik.setFieldTouched('phone', true)
          }}
          label={t('common:phoneNumber')}
        />
      </FieldWrapper>
      <SubmitButton
        size="big"
        type="submit"
        intent="primary"
        filled
        disabled={!formik.isValid || !formik.dirty}
        progress={progress}
      >
        {t('auth:next')}
      </SubmitButton>
      <ErrorWrapper>{error}</ErrorWrapper>
    </FormWrapper>
  )
}

export default GetCodeForm
