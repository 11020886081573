import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StylesConfig } from 'react-select'

import {
  Progress,
  ReactSelectOption,
  SimpleSelect,
  SimpleSelectProps,
  controlStyle,
  menuListStyle,
  menuStyle,
  optionStyle,
} from '@agro-club/agroclub-shared'

import capitalizeFirstLetter from 'helpers/capitalizeFirstLetter'
import { useTeams } from 'modules/domain/collection/hooks'

export const customSelectStyles: StylesConfig<ReactSelectOption, boolean> = {
  control: (...args) => ({
    ...controlStyle(...args),
    // height: '32px',
    // minHeight: '32px',
  }),
  menu: base => ({
    ...base,
    ...menuStyle,
    zIndex: 10,
    width: '100%',
  }),
  menuList: base => ({
    ...base,
    ...menuListStyle,
    width: '100%',
    maxHeight: '350px',
  }),
  option: base => ({
    ...base,
    ...optionStyle,
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    maxHeight: '32px',
  }),
}

type Props = {
  defaultTeamId?: string | null
  onChangeTeam: (teamId: string) => void
  required?: boolean
  menuPlacement?: SimpleSelectProps['menuPlacement']
  invalid?: boolean
  errorText?: string
}

export const TeamsSelect: React.FC<Props> = props => {
  const { defaultTeamId, onChangeTeam, required, menuPlacement, invalid, errorText } = props
  const [progress, teams] = useTeams()
  const [value, setValue] = useState<string | undefined | null>(defaultTeamId)
  const { t } = useTranslation('user')

  const options = useMemo<SimpleSelectProps['options']>(
    () => teams.map(team => ({ id: team.id, title: capitalizeFirstLetter(team.name) })),
    [teams],
  )

  return (
    <SimpleSelect
      invalid={invalid}
      errorText={errorText}
      menuPlacement={menuPlacement}
      label={t('common:team')}
      placeholder={t('form.teamPlaceholder')}
      loadingMessage={t('common:loading')}
      noOptionsMessage={() => t('form.noTeams')}
      customStyles={customSelectStyles}
      onChange={(teamId: string) => {
        setValue(teamId)
        onChangeTeam(teamId)
      }}
      options={options}
      progress={progress === Progress.IDLE ? Progress.SUCCESS : progress}
      value={value}
      required={required}
    />
  )
}
