import React from 'react'
import { Route, Switch } from 'react-router-dom'

import PageWrapper from 'views/components/PageWrapper/PageWrapper'
import TaskRoutes from 'views/pages/Task/routes'
import { TaskList } from 'views/pages/Task/TaskList'

const TaskPage: React.FC = () => (
  <PageWrapper>
    <Switch>
      <Route path={TaskRoutes.List}>
        <TaskList />
      </Route>
    </Switch>
  </PageWrapper>
)

export default TaskPage
