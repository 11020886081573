import React, { VFC } from 'react'
import { useTranslation } from 'react-i18next'

import { FormikContextType } from 'formik'

import { ParameterCondition } from 'modules/domain/bid/types'
import { Product } from 'modules/domain/collection/types'
import { CropsParam } from 'views/pages/Bid/BidAdd/CropsParam'
import { ParamsBody, ParamsTitle, ParamsWrapper } from 'views/pages/Bid/BidAdd/styled'
import { BidAddFields } from 'views/pages/Bid/BidAdd/types'
import { TextButton } from 'views/ui/TextButton/TextButton'

type Props = {
  product: Product
  formik: FormikContextType<BidAddFields>
}

export const Params: VFC<Props> = props => {
  const { product, formik } = props
  const formicParams = formik.values.parameters
  const mainParam = formik.values.mainParameter.parameter
  const { t } = useTranslation(['bid'])

  return (
    <ParamsWrapper>
      <ParamsTitle>{t('form.cropsParams')}</ParamsTitle>
      <ParamsBody>
        {formicParams.map((p, i) => {
          const usedParams = formicParams.slice(0, i).map(param => param.parameter)
          const availableParams = product.parameters.filter(
            param => param.id !== mainParam && !usedParams.includes(param.id),
          )
          /* eslint-disable react/no-array-index-key */
          return (
            <CropsParam
              onChange={(index, values) => formik.setFieldValue(`parameters[${index}]`, values)}
              index={i}
              onRemove={index =>
                formik.setFieldValue(
                  'parameters',
                  formik.values.parameters.filter((_, i) => i !== index),
                )
              }
              key={`form_param${i}`}
              parameters={availableParams}
              parameter={p}
            />
          )
          /* eslint-enable react/no-array-index-key */
        })}
        {formik.values.parameters.length < product.parameters.length - 1 ? (
          <div>
            <TextButton
              onClick={() =>
                formik.setFieldValue('parameters', [
                  ...formik.values.parameters,
                  {
                    parameter: undefined,
                    condition: Object.keys(ParameterCondition)[1],
                    parameter_value: undefined,
                  },
                ])
              }
            >
              {t('form.addParam')}
            </TextButton>
          </div>
        ) : null}
      </ParamsBody>
    </ParamsWrapper>
  )
}
