import { Link } from 'react-router-dom'

import { IconPencil, Typography } from '@agro-club/agroclub-shared'
import styled, { css } from 'styled-components'

import { Header, Item } from 'views/components/ColumnBoard/styled'
import { itemCardButtonCss } from 'views/pages/User/UserDetail/UserColumnBoard/styled'

export const UserColumnContainer = styled.div`
  display: inline-grid;
  grid-column-gap: 16px;
  grid-auto-flow: column;
  grid-template-columns: 272px 272px 272px;
  height: auto;
  min-height: 100%;
`

export const BoardHeader = styled(Header)`
  justify-content: space-between;
`

export const Title = styled.div``

const selectedItem = css`
  background-color: ${props => props.theme.color.primary300};
  color: ${props => props.theme.color.onPrimaryLight};
`

const archivedItem = css`
  color: ${props => props.theme.color.textSecondary};
`

export const VehicleItem = styled(Item)<{ isSelected?: boolean; isArchived?: boolean }>`
  padding: 12px;
  ${({ isSelected }) => isSelected && selectedItem};
  ${({ isArchived, isSelected }) => !isSelected && isArchived && archivedItem};
  cursor: pointer;
`

export const StateNumbers = styled.div`
  ${Typography.body2Bold};
`
export const TrailerStateNumber = styled.span`
  ${Typography.body2Default};
`
export const TypeOfCar = styled.div`
  ${Typography.body2Default};
  margin-bottom: 4px;
`

export const AdditionalInfo = styled.div<{ isSelected?: boolean }>`
  color: ${props => (props.isSelected ? props.theme.color.onPrimaryLight : props.theme.color.textSecondary)};
`

export const Unloading = styled.div`
  ${Typography.caption1Default};
`
export const SideHeight = styled.div`
  ${Typography.caption1Default};
  margin-bottom: 4px;
`

export const NumOfDrivers = styled.div`
  ${Typography.caption1Default};
`
export const CharterLabel = styled.span`
  position: absolute;
  right: 12px;
  bottom: 12px;
  color: inherit;
  display: inline-flex;
  align-items: center;
  & > svg {
    fill: currentColor;
    margin-left: 5px;
    width: 14.67px;
    height: 14.67px;
  }
  ${Typography.caption1Default};
`
export const EditButton = styled(IconPencil)`
  position: absolute;
  top: 10px;
  right: 10px;
  fill: ${props => props.theme.color.secondary200};
  width: 15px;
  height: 15px;
  cursor: pointer;

  &:hover {
    fill: ${props => props.theme.color.secondary300};
  }
`

export const CarDetailButton = styled(Link)`
  display: block;
  position: absolute;
  top: 8px;
  right: 8px;
  border-radius: 8px;
  transition: 0.1s background-color ease-out;
  ${itemCardButtonCss};
`

// CarDealItem

export const CarDealItem = styled(Item)<{ selected: boolean; archived?: boolean }>`
  position: relative;
  padding: 10px 14px;
  border: 1px solid ${props => (props.selected ? props.theme.color.primary600 : 'transparent')};
  cursor: default;
  color: ${props => (props.archived ? props.theme.color.textSecondary : props.theme.color.textPrimary)};
`

export const CarDealAgent = styled.div`
  &:after {
    ${Typography.caption1Bold};
    content: 'A';
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;

    width: 20px;
    height: 20px;
    border-radius: 10px;
    line-height: 20px;
    text-align: center;

    background-color: ${props => props.theme.color.secondary300};
    color: ${props => props.theme.color.onPrimaryLight};
  }
`

export const CarDealIdAndDate = styled.div`
  color: ${props => props.theme.color.textSecondary};
  margin-bottom: 4px;
  ${Typography.caption1Default}
`

export const CarDealProduct = styled.div`
  margin-bottom: 8px;
  ${Typography.body2Bold}
`

export const CarDealCompany = styled.div`
  margin-bottom: 2px;
  ${Typography.body2Default}
`

export const CarDealAddress = styled.div`
  margin-bottom: 8px;
  color: ${props => props.theme.color.textSecondary};
  ${Typography.caption1Default}
`

export const CarDealPrice = styled.div`
  margin-top: 8px;
  ${Typography.body2Heavy}
`

// DealTripItem

export const DealTripItem = styled(Item)<{ archived?: boolean }>`
  min-height: unset;
  padding: 10px 14px;
  color: ${props => (props.archived ? props.theme.color.textSecondary : props.theme.color.textPrimary)};
  ${Typography.footnoteDefault};
`

export const DealTripDate = styled.div`
  margin-bottom: 8px;
  ${Typography.body2Bold}
`

export const DealTripKmT = styled.div`
  display: inline-block;
  margin-right: 8px;
`

export const DealTripTKm = styled.div`
  display: inline-block;
  color: ${props => props.theme.color.textSecondary};
`

export const SpoilerButton = styled.div`
  background: ${props => props.theme.color.primary50};
  color: ${props => props.theme.color.primary600};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 0 12px;
  margin: 16px 0;
  border-radius: 8px;
  cursor: default;
  ${Typography.body2Default}
`

export const SpoilerIcon = styled.div<{ opened: boolean }>`
  transform: rotate(${props => (props.opened ? '180deg' : '0')});
`

export const Checks = styled.div`
  display: flex;
  align-items: center;
  margin-top: 9px;
  & > * + * {
    margin-left: 16px;
  }
  & > svg {
    margin-left: 8px;
  }
`

export const Label = styled.span``
