import axios, { AxiosError, AxiosRequestConfig, Method } from 'axios'
import { stringify } from 'query-string'

import { REACT_APP_API_BASE_URL } from 'env'
import { downloadBlob } from 'helpers/download'
import { noop } from 'helpers/noop'
import i18n from 'i18n'
import { getAccessToken } from 'modules/domain/auth/repository'
import { Sentry } from 'sentry'

import { handleApiErrors } from './handleApiErrors'

axios.interceptors.request.use(
  config => {
    Sentry.handleAxiosRequest(config)
    return config
  },
  error => {
    Sentry.handleAxiosError(error)
    return Promise.reject(error)
  },
)

axios.interceptors.response.use(
  response => {
    Sentry.handleAxiosResponse(response)
    return response
  },
  error => {
    Sentry.handleAxiosError(error)
    return Promise.reject(error)
  },
)

export type EmitterToken<T> =
  | {
      type: 'progress'
      total: number
      loaded: number
      percent: number
    }
  | { type: 'success'; result: T }
  | { type: 'error'; error: AxiosError }

type ParamType = string | number | boolean | undefined | null
type UrlParams = { [index: string]: ParamType | ParamType[] }
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RequestBody = Record<string, any> | FormData
type RequestExtraOptions<T> = {
  sagaEmitter?: (token: EmitterToken<T>) => unknown
  multipart?: boolean
}
type RequestOptions<T> = AxiosRequestConfig & RequestExtraOptions<T>
export const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

export const formHeaders = {
  Accept: 'application/json',
  'Content-Type': 'multipart/form-data',
}

const bodyToForm = (params: RequestBody): FormData =>
  !(params instanceof FormData)
    ? Object.keys(params)
        .filter(key => Boolean(params[key]))
        .reduce((form, key) => {
          form.append(key, params[key])
          return form
        }, new FormData())
    : params

const cancelTokenMap = new WeakMap<Promise<unknown>, (message?: string) => void>()

export function performRequest<T>(
  method: Method,
  lang: string,
  baseURL: string,
  url: string,
  params: UrlParams | null,
  _body: RequestBody | null,
  options: RequestOptions<T> = {},
): Promise<T> {
  const sagaEmitter = options.sagaEmitter ? options.sagaEmitter : noop
  const cancelToken = axios.CancelToken.source()
  const body = _body ? (options.multipart ? bodyToForm(_body) : _body) : undefined
  const headers = options.multipart ? formHeaders : defaultHeaders
  const token = getAccessToken()
  const request = axios({
    headers: {
      ...options.headers,
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
      'Accept-Language': lang,
    },
    method,
    url,
    params,
    baseURL,
    data: body,
    onUploadProgress: ({ total, loaded }) =>
      sagaEmitter({
        type: 'progress',
        total,
        loaded,
        percent: Math.round((loaded * 100) / total),
      }),
    cancelToken: cancelToken.token,
    responseType: options.responseType || 'json',
    paramsSerializer: params => stringify(params),
  })
    .then(res => {
      if (res.config.responseType === 'blob') {
        downloadBlob(res.data, res.headers)
      }
      sagaEmitter({ type: 'success', result: res.data })
      return res.data
    })
    .catch(err => {
      sagaEmitter({ type: 'error', error: err })
      return handleApiErrors(err)
    })

  cancelTokenMap.set(request, () => cancelToken.cancel())
  return request
}

export const cancel = (request?: Promise<unknown> | null) => {
  if (!request) {
    return
  }
  const entry = cancelTokenMap.get(request)
  if (entry) {
    entry('cancelled')
  }
}

export function makeCancelable<T extends (...args: any[]) => any>(
  manager: T,
): (...args: Parameters<T>) => [ReturnType<T>, () => void] {
  let promise: ReturnType<T>
  return (...args) => {
    cancel(promise)
    promise = manager(...args)
    return [promise, () => cancel(promise)]
  }
}

export function makeHttpClient(baseURL: string) {
  if (!baseURL) {
    // eslint-disable-next-line no-console
    console.error('REACT_APP_API_BASE_URL is missing')
  }

  let lang = i18n.language
  document.documentElement.lang = lang
  i18n.on('languageChanged', lng => {
    document.documentElement.lang = lng
    lang = lng
  })

  return {
    get<T>(path: string, params?: UrlParams, options?: RequestOptions<T>) {
      return performRequest<T>('get', lang, baseURL, path, params || null, null, options)
    },
    post<T>(path: string, body?: RequestBody, options?: RequestOptions<T>) {
      return performRequest<T>('post', lang, baseURL, path, null, body || null, options)
    },
    put<T>(path: string, body?: RequestBody, options?: RequestOptions<T>) {
      return performRequest<T>('put', lang, baseURL, path, null, body || null, options)
    },
    delete<T>(path: string, body?: RequestBody, options?: RequestOptions<T>) {
      return performRequest<T>('delete', lang, baseURL, path, null, body || null, options)
    },
    patch<T>(path: string, body?: RequestBody, options?: RequestOptions<T>) {
      return performRequest<T>('patch', lang, baseURL, path, null, body || null, options)
    },
    cancelRequest(req: Promise<unknown>) {
      const cancelHandler = cancelTokenMap.get(req)
      cancelHandler && cancelHandler()
    },
  }
}

export const axiosClient = (config: AxiosRequestConfig) => axios(config)
export const apiClient = makeHttpClient(REACT_APP_API_BASE_URL)
export const authClient = makeHttpClient(REACT_APP_API_BASE_URL)

export default makeHttpClient
