import formatSortParam from 'helpers/formatSortParam'
import {
  PotentialBid,
  PotentialBidDTO,
  PotentialBidListRequestFilter,
  PotentialBidListRequestSorting,
} from 'modules/domain/potentialBid/types'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { ListResponse } from 'types/api.d'

export const getList = (
  filter: PotentialBidListRequestFilter,
  sorting: PotentialBidListRequestSorting,
  page: number,
  pageSize: number,
) => {
  const reqFilter = { ...filter }
  delete reqFilter.address

  return apiClient.get<ListResponse<PotentialBid>>(endpoints.potentialBid(), {
    ...reqFilter,
    sort: formatSortParam(sorting),
    page,
    page_size: pageSize,
  })
}

export const getItem = (id: string) => apiClient.get<PotentialBid>(endpoints.potentialBid(id))

export const addItem = (dto: PotentialBidDTO) => apiClient.post<{ id: string }>(endpoints.potentialBid(), dto)

export const updateItem = (id: string, dto: Partial<PotentialBidDTO>) =>
  apiClient.put<PotentialBidDTO>(endpoints.potentialBid(id), dto)

export const removeItem = (id: string) => apiClient.delete<PotentialBid>(endpoints.potentialBid(id))

export const getUserBidList = (userId: string) => apiClient.get<PotentialBid[]>(endpoints.userPotentialBidList(userId))
