import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Progress, useAction } from '@agro-club/agroclub-shared'

import { useNotificationProgress } from 'hooks/useNotificationProgress'
import usePrevious from 'hooks/usePrevious'
import CarrierActions from 'modules/domain/carrier/duck'
import CarrierSelectors from 'modules/domain/carrier/selectors'
import UserActions from 'modules/domain/user/duck'
import UserSelectors from 'modules/domain/user/selectors'
import FilesByTypes from 'views/components/FilesByTypes/FilesByTypes'
import { DocumentsWrapper } from 'views/components/UserRightPanel/styled'

interface Props {
  context?: string
}

enum Context {
  user = 'user',
  carrier = 'carrier',
}

export const DocumentsSummary: React.FC<Props> = ({ context }) => {
  const { t } = useTranslation(['user', 'common'])
  const { id } = useParams<{ id: string }>()
  const userFiles = useSelector(state => UserSelectors.documentFiles(state, id))
  const carrierFiles = useSelector(state => CarrierSelectors.documentFiles(state, id))
  const list = context === Context.user ? userFiles : carrierFiles
  return <div>{list?.length ? t('common:thereIs') : t('common:no')}</div>
}

export const Documents: React.FC<Props> = ({ context }) => {
  const { t } = useTranslation('common')
  const { id } = useParams<{ id: string }>()
  const userDocType = useSelector(state => UserSelectors.documentTypes(state, id))
  const carrierDocType = useSelector(state => CarrierSelectors.documentTypes(state, id))
  const documentTypes = context === Context.user ? userDocType : carrierDocType
  const userDocumentTypesRequested = useAction(UserActions.getDocumentTypesRequested)
  const carrierDocumentTypesRequested = useAction(CarrierActions.getDocumentTypesRequested)
  const getDocumentTypesRequested =
    context === Context.user ? userDocumentTypesRequested : carrierDocumentTypesRequested
  const userUploadFile = useAction(UserActions.uploadDocumentRequested)
  const carrierUploadFile = useAction(CarrierActions.uploadDocumentRequested)
  const uploadFile = context === Context.user ? userUploadFile : carrierUploadFile
  const userDocRequested = useAction(UserActions.getDocumentFilesRequested)
  const carrierDocRequested = useAction(CarrierActions.getDocumentFilesRequested)
  const getDocumentFilesRequested = context === Context.user ? userDocRequested : carrierDocRequested
  const userDocumentFiles = useSelector(state => UserSelectors.documentFiles(state, id))
  const carrierDocumentFiles = useSelector(state => CarrierSelectors.documentFiles(state, id))
  const documentFiles = context === Context.user ? userDocumentFiles : carrierDocumentFiles
  const userUploadProgress = useSelector(state => UserSelectors.uploadProgress(state, id))
  const carrierUploadProgress = useSelector(state => CarrierSelectors.uploadProgress(state, id))
  const uploadProgress = context === Context.user ? userUploadProgress : carrierUploadProgress
  const userDeleteDoc = useAction(UserActions.deleteTypedDocumentRequested)
  const carrierDeleteDoc = useAction(CarrierActions.deleteTypedDocumentRequested)
  const deleteDocumentRequested = context === Context.user ? userDeleteDoc : carrierDeleteDoc
  const userDeleteProgress = useSelector(state => UserSelectors.deleteTypedDocumentProgress(state, id))
  const carrierDeleteProgress = useSelector(state => CarrierSelectors.deleteTypedDocumentProgress(state, id))
  const deleteDocumentProgress = context === Context.user ? userDeleteProgress : carrierDeleteProgress
  const userUploadErrorDetail = useSelector(state => UserSelectors.uploadErrorDetail(state, id))
  const carrierUploadErrorDetail = useSelector(state => CarrierSelectors.uploadErrorDetail(state, id))
  const uploadErrorDetail = context === Context.user ? userUploadErrorDetail : carrierUploadErrorDetail

  const prevUploadProgress = usePrevious(uploadProgress)
  const prevDeleteDocumentProgress = usePrevious(deleteDocumentProgress)

  useNotificationProgress(uploadProgress, undefined, uploadErrorDetail || t('uploadError'))

  useEffect(() => {
    getDocumentTypesRequested(id)
    getDocumentFilesRequested(id)
  }, [getDocumentFilesRequested, getDocumentTypesRequested, id])

  useEffect(() => {
    if (prevUploadProgress === Progress.WORK && uploadProgress === Progress.SUCCESS) {
      getDocumentFilesRequested(id)
    }
  }, [id, uploadProgress, prevUploadProgress, getDocumentFilesRequested])

  useEffect(() => {
    if (prevDeleteDocumentProgress === Progress.WORK && deleteDocumentProgress === Progress.SUCCESS) {
      getDocumentFilesRequested(id)
    }
  }, [id, prevDeleteDocumentProgress, deleteDocumentProgress, getDocumentFilesRequested])

  const onUploadHandler = (document_type, file) => {
    uploadFile(id, document_type, file)
  }

  const onDeleteHandler = document_id => {
    deleteDocumentRequested(id, document_id)
  }

  return (
    <DocumentsWrapper>
      <FilesByTypes types={documentTypes} files={documentFiles} onUpload={onUploadHandler} onDelete={onDeleteHandler} />
    </DocumentsWrapper>
  )
}
