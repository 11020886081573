import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Segment } from '@agro-club/agroclub-shared'

import { TaskType } from 'modules/domain/task/types'

type Props = {
  onChangeType: (type: TaskType) => void
  type: string
}

export const SwitchType: React.FC<Props> = props => {
  const { type, onChangeType } = props
  const { t } = useTranslation('task')
  const items = useMemo(
    () =>
      Object.keys(TaskType).map(type => ({
        id: type,
        title: t(`type.${type}`),
      })),
    [t],
  )
  return <Segment value={type} items={items} onChange={(v: TaskType) => onChangeType(v)} />
}
