import { useEffect, useState } from 'react'

import { Progress, useHistoryPush } from '@agro-club/agroclub-shared'

import { RequestError } from 'modules/errors'
import { apiClient } from 'modules/utils/httpClient'

export const useSingleEntity = function <T>(
  url: string,
  reFetchDeps?: unknown,
  routing = true,
): readonly [Progress, T | undefined, (silent?: boolean) => void] {
  const [progress, setProgress] = useState(Progress.IDLE)
  const [data, setData] = useState<T>()
  const push = useHistoryPush()

  const getEntity = async (silent = false) => {
    try {
      !silent && setProgress(Progress.WORK)

      const data = await apiClient.get<T>(url)

      setData(data)

      !silent && setProgress(Progress.SUCCESS)
    } catch (err) {
      // TS fires an error that err is type of unknown
      // So, workaround is to convert it to the RequestError manually
      const error = err as RequestError

      if (routing && error?.code === 404) {
        push({ route: '/404' })
        return
      }

      !silent && setProgress(Progress.ERROR)
    }
  }

  useEffect(() => {
    getEntity()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, reFetchDeps])

  return [progress, data, getEntity]
}
