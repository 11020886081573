import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button, Progress, useAction } from '@agro-club/agroclub-shared'
import { useFormik } from 'formik'

import env from 'env'
import useDateFnsLocale from 'hooks/useDateFnsLocale'
import usePrevious from 'hooks/usePrevious'
import useTonsAndBags from 'hooks/useTonsAndBags'
import DealActions from 'modules/domain/deal/duck'
import DealSelectors from 'modules/domain/deal/selectors'
import { DealShipment, DealShipmentDTO } from 'modules/domain/deal/types'
import { Label, StyledDatePicker, TwoCol } from 'views/pages/Deal/DealEdit/styled'
import { ModalCommon } from 'views/ui/ModalCommon/ModalCommon'
import { NumberInput } from 'views/ui/NumberInput/NumberInput'

type ShippingItemFormProps = DealShipmentDTO

export const ShippingItemForm: React.FC<{
  dealId: string
  shipment?: DealShipment
  onClose: () => void
  open?: boolean
}> = ({ dealId, shipment, onClose, open }) => {
  const { t } = useTranslation('deal')
  const dealItem = useSelector(state => DealSelectors.item(state, dealId))
  const currency = env.REACT_APP_CURRENCY_SYMBOL
  const addProgress = useSelector(state => DealSelectors.dealShipmentAddProgress(state, dealId))
  const prevAddProgress = usePrevious(addProgress)
  const editProgress = useSelector(state => DealSelectors.dealShipmentEditProgress(state, dealId))
  const prevEditProgress = usePrevious(editProgress)
  const deleteProgress = useSelector(state => DealSelectors.dealShipmentDeleteProgress(state, dealId))
  const prevDeleteProgress = usePrevious(deleteProgress)
  const [quantity_bags, quantity_tonsToBags, quantity_bagsToTons] = useTonsAndBags()
  const [price_bags, price_tonsToBags, price_bagsToTons] = useTonsAndBags()

  const addAction = useAction(DealActions.shipmentItemAddRequested)
  const editAction = useAction(DealActions.shipmentItemEditRequested)
  const locale = useDateFnsLocale()

  const initialValues = useMemo(
    () => ({
      gmv: 0,
      delivered_at: new Date().toISOString(),
      margin: 0,
      margin_percent: 0,
      price_logistics: 0,
      price_cpt: dealItem?.price_cpt ?? 0,
      price_exw: dealItem?.price_exw ?? 0,
      quantity: 0,
    }),
    [dealItem],
  )

  const formik = useFormik<ShippingItemFormProps>({
    initialValues,
    onSubmit(values) {
      if (shipment) {
        editAction(dealId, shipment.id, values)
      } else {
        addAction(dealId, values)
      }
    },
  })

  useEffect(() => {
    if (prevAddProgress === Progress.WORK && addProgress === Progress.SUCCESS) {
      formik.resetForm()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addProgress, prevAddProgress])

  useEffect(() => {
    if (
      (prevEditProgress === Progress.WORK && editProgress === Progress.SUCCESS) ||
      (prevDeleteProgress === Progress.WORK && deleteProgress === Progress.SUCCESS)
    ) {
      formik.resetForm()
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editProgress, deleteProgress])

  useEffect(() => {
    formik.setValues(shipment || initialValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipment, initialValues])

  return (
    <ModalCommon
      width={448}
      isOpen={Boolean(open)}
      close={onClose}
      title={t(shipment !== undefined ? 'common:editing' : 'common:newShipment')}
      content={
        <div>
          <Label>{t('fields.dateOfShipment')}</Label>
          <StyledDatePicker
            onChange={v => {
              formik.setFieldValue('delivered_at', v)
            }}
            date={formik.values.delivered_at}
            reactDatePickerProps={{
              locale,
              dateFormat: 'P',
              placeholderText: t('common:datePlaceholder'),
            }}
          />
          <TwoCol>
            <div>
              <Label>{t('fields.quantityShipment_bag')}</Label>
              <NumberInput
                value={quantity_bags}
                onChange={quantity_bagsToTons(v => formik.setFieldValue('quantity', v))}
                invalid={formik.touched.quantity && !!formik.errors.quantity}
                errorText={formik.errors.quantity}
              />
            </div>
            <div>
              <Label>{t('fields.quantityShipment')}</Label>
              <NumberInput
                value={formik.values.quantity}
                onChange={quantity_tonsToBags(v => formik.setFieldValue('quantity', v))}
                invalid={formik.touched.quantity && !!formik.errors.quantity}
                errorText={formik.errors.quantity}
              />
            </div>
          </TwoCol>
          <TwoCol>
            <div>
              <Label>{t('fields.price_logistics_per_bag', { currency })}</Label>
              <NumberInput
                max={327}
                value={price_bags}
                onChange={price_bagsToTons(v => formik.setFieldValue('price_logistics', v))}
                invalid={formik.touched.price_logistics && !!formik.errors.price_logistics}
                errorText={formik.errors.price_logistics}
              />
            </div>
            <div>
              <Label>{t('fields.price_logistics_per_ton', { currency })}</Label>
              <NumberInput
                max={327}
                value={formik.values.price_logistics}
                onChange={price_tonsToBags(v => formik.setFieldValue('price_logistics', v))}
                invalid={formik.touched.price_logistics && !!formik.errors.price_logistics}
                errorText={formik.errors.price_logistics}
              />
            </div>
          </TwoCol>
          <TwoCol>
            <div>
              <Label>{t('fields.price_exw', { currency })}</Label>
              <NumberInput
                value={formik.values.price_exw}
                onChange={v => formik.setFieldValue('price_exw', v)}
                invalid={formik.touched.price_exw && !!formik.errors.price_exw}
                errorText={formik.errors.price_exw}
              />
            </div>
            <div>
              <Label>{t('fields.price_cpt', { currency })}</Label>
              <NumberInput
                value={formik.values.price_cpt}
                onChange={v => formik.setFieldValue('price_cpt', v)}
                invalid={formik.touched.price_cpt && !!formik.errors.price_cpt}
                errorText={formik.errors.price_cpt}
              />
            </div>
          </TwoCol>
        </div>
      }
      footer={
        <>
          <Button
            onClick={() => {
              formik.dirty &&
                formik
                  .submitForm()
                  .then(() => {
                    onClose()
                  })
                  .catch()
            }}
            disabled={!formik.dirty}
            intent="primary"
            progress={editProgress}
            filled
            size="small"
          >
            {shipment === undefined ? t('common:send') : t('common:save')}
          </Button>
          <Button onClick={onClose} intent="minor-action" filled size="small">
            {t('common:cancel')}
          </Button>
        </>
      }
    />
  )
}
