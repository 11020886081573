import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, generatePath } from 'react-router-dom'

import { IconCheckmark, IconPlus } from '@agro-club/agroclub-shared'

import useFormatNumber from 'hooks/useFormatNumber'
import useLocationFrom from 'hooks/useLocationFrom'
import { Car, CarOwner } from 'modules/domain/car/types'
import CarrierRoutes from 'views/pages/Carrier/routes'
import * as S from 'views/pages/Logistics/LogisticsRightPanel/LogisticsCarsPanel/styled'
import LogisticsRoutes from 'views/pages/Logistics/routes'
import { Phone } from 'views/ui/Phone/Phone'
import { UserLink } from 'views/ui/UserLink/UserLink'

type Props = {
  car: Car
  dealId: string
  tripCounter?: number
}

export const LogisticsCarsItem: React.FC<Props> = props => {
  const { t } = useTranslation('vehicles')
  const formatNumber = useFormatNumber()
  const from = useLocationFrom()
  const {
    licence_number,
    car_type,
    is_dump_truck,
    drivers_count,
    is_in_charter,
    unloading_type,
    side_height,
    owner,
    id: car_id,
    default_trailer,
  } = props.car

  return (
    <S.CardWrapper>
      <div>
        <S.StateNumbersLink
          to={{
            pathname: generatePath(CarrierRoutes.CarDetail, { id: (owner as CarOwner).id, car_id }),
            state: { from },
          }}
        >
          {licence_number}
          {default_trailer?.licence_number && (
            <S.TrailerStateNumber>
              {' / '}
              {default_trailer?.licence_number}
            </S.TrailerStateNumber>
          )}
        </S.StateNumbersLink>

        <S.CarInfo>
          {!!car_type && t(`vehicleTypes.${car_type}`)}
          {is_dump_truck && `, ${t('is_dump_truck')}`}
          <br />
          {unloading_type ? `${t(`unloadingType.${unloading_type}`)}, ` : ''}
          {side_height ? formatNumber(side_height) : '?'} {t('side_height_units')}
        </S.CarInfo>

        <S.UserLinkWrapper>
          <UserLink user={owner} />
        </S.UserLinkWrapper>
        <S.UserLinkWrapper>
          <Phone phone={owner.phone} />
        </S.UserLinkWrapper>

        <S.NumOfDrivers>
          {t('driversCount')}: {drivers_count}
        </S.NumOfDrivers>
      </div>

      <S.BottomBlock>
        <S.TripCounter>
          <>
            {t('trips:tripCounterLabel')} <S.TripCounterValue>{props.tripCounter}</S.TripCounterValue>
          </>

          <Link to={generatePath(LogisticsRoutes.TripAdd, { dealId: props.dealId, carId: props.car.id })}>
            <S.CarAddButton>
              <IconPlus />
            </S.CarAddButton>
          </Link>
        </S.TripCounter>

        {is_in_charter && (
          <S.CharterLabel>
            {t('charter')} <IconCheckmark />
          </S.CharterLabel>
        )}
      </S.BottomBlock>
    </S.CardWrapper>
  )
}
