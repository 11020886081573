export enum ROLES {
  coordinator = 'coordinator',
  admin = 'admin',
  market_user = 'market_user',
}

// https://www.iana.org/assignments/language-subtag-registry/language-subtag-registry
export enum AvailableLanguages {
  ru = 'ru',
  pt_BR = 'pt-BR',
  en = 'en',
}
