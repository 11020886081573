import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { CheckboxSelect } from '@agro-club/agroclub-shared'

import { useTeams } from 'modules/domain/collection/hooks'
import { Filter } from 'views/components/TableFilters/TableFilters'

type Props = {
  onChange: (id: string) => void
  selectedIds: string[] | undefined
  onClear: () => void
  isNoTeam?: boolean
}

export const TeamFilter: React.FC<Props> = props => {
  const { selectedIds = [], onChange, onClear, isNoTeam } = props
  const [progress, teams] = useTeams()
  const { t } = useTranslation()

  const isSome = selectedIds.length > 1

  const options = useMemo(() => {
    const allTeams = teams?.slice().map(team => ({ id: team.id, title: team.name })) || []
    if (isNoTeam) {
      return [{ id: 'null', title: t('common:withoutTeam') }, ...allTeams]
    }
    return allTeams
  }, [teams, isNoTeam, t])

  return (
    <Filter title={t('common:team')}>
      <CheckboxSelect
        loadingMessage={t('common:loading')}
        noOptionsMessage={() => t('user:form.noTeams')}
        progress={progress}
        placeholder={isSome ? t('common:someTeams') : t('common:allTeams')}
        options={options}
        selected={selectedIds}
        onChange={onChange}
        size="small"
        additionalStyles={{
          control: {
            width: 186,
          },
        }}
        onClear={onClear}
        singleSelected={selectedIds.length === 1 ? options?.find(team => team.id === selectedIds[0])?.title : undefined}
      />
    </Filter>
  )
}
