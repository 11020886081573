import { Progress } from '@agro-club/agroclub-shared'
import { createSelector } from 'reselect'

import { CallStatistics } from 'modules/domain/callStatistics/types'
import { EntityMetadata } from 'modules/domain/types'
import { AppGlobalState } from 'modules/types'

const callStatisticsDict = (state: AppGlobalState) => state.callStatistics.items
const ids = (state: AppGlobalState) => state.callStatistics.ids
const callStatistics = (state: AppGlobalState, id: string): CallStatistics | undefined => state.callStatistics.items[id]
const meta = (state: AppGlobalState, id: string): EntityMetadata<CallStatistics> =>
  state.callStatistics.meta[id] || {
    fetchError: null,
    fetchProgress: Progress.IDLE,
    id,
  }

const listFetchProgress = (state: AppGlobalState) => state.callStatistics.listFetchProgress
const listFetchNextProgress = (state: AppGlobalState) => state.callStatistics.listFetchNextProgress
const listFetchNextError = (state: AppGlobalState) => state.callStatistics.listFetchNextError
const listFetchError = (state: AppGlobalState) => state.callStatistics.listFetchError
const callStatisticsList = createSelector(callStatisticsDict, ids, listFetchProgress, (dict, ids, progress) => {
  if (progress === Progress.WORK) {
    return []
  }
  const result: CallStatistics[] = []
  for (const id of ids) {
    result.push(dict[id])
  }
  return result
})

const filter = (state: AppGlobalState) => state.callStatistics.filter
const sorting = (state: AppGlobalState) => state.callStatistics.sorting
const page = (state: AppGlobalState) => state.callStatistics.page
const total = (state: AppGlobalState) => state.callStatistics.total
const hasNext = (state: AppGlobalState) => state.callStatistics.total > state.callStatistics.ids.length
const pageSize = (state: AppGlobalState) => state.callStatistics.pageSize
const pages = (state: AppGlobalState) => Math.ceil(state.callStatistics.total / state.callStatistics.pageSize)

const filterForRequestManager = (state: AppGlobalState) => {
  const { filter } = state.callStatistics
  return filter
}

const callStatisticsAverage = (state: AppGlobalState) => state.callStatistics.averageStatistics
const callStatisticsAverageFetchProgress = (state: AppGlobalState) =>
  state.callStatistics.averageStatisticsFetchProgress

const callStatisticsGeneral = (state: AppGlobalState) => state.callStatistics.generalStatistics
const callStatisticsGeneralFetchProgress = (state: AppGlobalState) =>
  state.callStatistics.generalStatisticsFetchProgress

const CallStatisticsSelectors = {
  callStatisticsDict,
  ids,
  callStatistics,
  meta,
  callStatisticsList,
  callStatisticsAverage,
  callStatisticsAverageFetchProgress,
  callStatisticsGeneral,
  callStatisticsGeneralFetchProgress,
  filter,
  sorting,
  page,
  total,
  hasNext,
  pageSize,
  pages,
  filterForRequestManager,
  listFetchProgress,
  listFetchError,
  listFetchNextProgress,
  listFetchNextError,
}

export default CallStatisticsSelectors
