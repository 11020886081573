import { Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import { NumberInput } from 'views/ui/NumberInput/NumberInput'

export const Fields = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  row-gap: 16px;
  column-gap: 16px;
  align-items: center;
`

export const Key = styled.div`
  ${Typography.body2Default};
  color: ${({ theme }) => theme.color.textSecondary};
`

export const DealDetailNumberInput = styled(NumberInput)`
  width: 80px;
`

export const ValueField = styled.div`
  color: ${({ theme }) => theme.color.textTertiary};
  background-color: ${({ theme }) => theme.color.secondary25};
  padding: 6px 8px;
  ${Typography.body2Default};
  width: 80px;
`

export const LongField = styled.div`
  width: 145px;
`

export const Footer = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-auto-flow: column;
  margin-top: 16px;
`
