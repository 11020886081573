import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button, IconCross, Input, Progress, Spinner } from '@agro-club/agroclub-shared'
import { useFormik } from 'formik'

import { useNotificationProgress } from 'hooks/useNotificationProgress'
import usePrevious from 'hooks/usePrevious'
import { useDrivers } from 'modules/domain/driver/hooks'
import DriverSelectors from 'modules/domain/driver/selectors'
import { Driver } from 'modules/domain/types'
import {
  AddDriverInfo,
  DriverButtonsWrapper,
  DriverInfo,
  DriverName,
  DriverPhone,
  DriverWrapper,
  DriversFormWrapper,
  DriversList,
  FormButtonsWrapper,
  LoaderWrapper,
  RemoveDriverBtn,
  UpdateDriverBtn,
} from 'views/pages/Carrier/CarrierDetail/UserCars/styled'
import { ConfirmationTooltip } from 'views/ui/ConfirmationTooltip/ConfirmationTooltip'
import { FormItem } from 'views/ui/FormItem/FormItem'

type Props = {
  carId: string
}

type EditableFields = {
  name: string
  phone: string
}

export const DriversForm: React.FC<Props> = ({ carId }) => {
  const { t } = useTranslation('vehicles')
  const drivers = useDrivers(carId, true)

  const [editableDriver, setEditableDriver] = useState<Driver | null>(null)
  const [driverIdToDetach, setDriverIdToDetach] = useState<string | null>(null)
  const hideConfirm = useCallback(() => {
    setDriverIdToDetach(null)
  }, [setDriverIdToDetach])
  const updateErrorFields = useSelector(DriverSelectors.updateDriverErrorFields)
  const addErrorFields = useSelector(DriverSelectors.addDriverErrorFields)
  const errorFields = editableDriver ? updateErrorFields : addErrorFields

  const handleSubmit = ({ name, phone }) => {
    if (!editableDriver) {
      drivers.add(carId, {
        name,
        phone,
      })
    } else {
      drivers.update(carId, editableDriver.id, {
        name,
        phone,
      })
    }
  }

  const formik = useFormik<EditableFields>({
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues: editableDriver
      ? { name: editableDriver.name, phone: editableDriver.phone }
      : { name: '', phone: '' },
    onSubmit: handleSubmit,
  })

  useNotificationProgress(drivers.addProgress, t('form.driverNotifyAddSuccess'), t('form.driverNotifyAddFail'))
  useNotificationProgress(drivers.updateProgress, t('form.driverNotifyUpdateSuccess'), t('form.driverNotifyUpdateFail'))
  useNotificationProgress(drivers.detachProgress, t('form.driverNotifyDetachSuccess'), t('form.driverNotifyDetachFail'))

  const prevAddProgress = usePrevious(drivers.addProgress)
  const prevUpdProgress = usePrevious(drivers.updateProgress)

  useEffect(() => {
    if (
      (prevAddProgress === Progress.WORK && drivers.addProgress === Progress.SUCCESS) ||
      (prevUpdProgress === Progress.WORK && drivers.updateProgress === Progress.SUCCESS)
    ) {
      formik.resetForm()
      setEditableDriver(null)
    }
  }, [prevAddProgress, prevUpdProgress, drivers.addProgress, drivers.updateProgress, formik])

  return (
    <DriversFormWrapper>
      <DriversList>
        {drivers.list.map(driver => (
          <DriverWrapper key={driver.id}>
            <DriverInfo>
              <DriverName>{driver.name}</DriverName>
              <DriverPhone phone={driver.phone} />
            </DriverInfo>
            {(!editableDriver || editableDriver.id !== driver.id) && (
              <DriverButtonsWrapper>
                <UpdateDriverBtn
                  onClick={() => {
                    setEditableDriver(driver)
                  }}
                />
                <ConfirmationTooltip
                  visible={driverIdToDetach === driver.id}
                  placement="bottomRight"
                  overlayProps={{
                    header: t('driverDeleting'),
                    body: t('areYouSureToDelete'),
                    okText: t('common:delete'),
                    okAction: () => {
                      drivers.detach(carId, driver.id)
                    },
                    okProgress: drivers.detachProgress,
                    cancelText: t('common:cancel'),
                    close: hideConfirm,
                  }}
                >
                  <RemoveDriverBtn
                    title={t('common:delete')}
                    onClick={() => {
                      setDriverIdToDetach(driver.id)
                    }}
                  >
                    <IconCross />
                  </RemoveDriverBtn>
                </ConfirmationTooltip>
              </DriverButtonsWrapper>
            )}
          </DriverWrapper>
        ))}

        {[drivers.fetchProgress, drivers.addProgress, drivers.updateProgress, drivers.detachProgress].includes(
          Progress.WORK,
        ) && (
          <LoaderWrapper>
            <Spinner />
          </LoaderWrapper>
        )}
      </DriversList>
      <form
        onSubmit={e => {
          e.preventDefault()
          formik.dirty && formik.submitForm()
        }}
      >
        <AddDriverInfo>
          <FormItem
            fieldName="name"
            errorFields={errorFields}
            formikInstance={formik}
            render={additionalProps => (
              <Input label={t('common:fio')} {...formik.getFieldProps('name')} {...additionalProps} />
            )}
          />
          <FormItem
            fieldName="phone"
            errorFields={errorFields}
            formikInstance={formik}
            render={additionalProps => (
              <Input label={t('common:phone')} {...formik.getFieldProps('phone')} {...additionalProps} />
            )}
          />
        </AddDriverInfo>
        <FormButtonsWrapper>
          <Button filled intent="primary" size="small" type="submit" disabled={!formik.dirty}>
            {t(editableDriver ? 'updateDriver' : 'addDriver')}
          </Button>

          {!!editableDriver && (
            <Button
              intent="primary"
              size="small"
              onClick={() => {
                setEditableDriver(null)
                formik.resetForm()
                drivers.resetErrors()
              }}
            >
              {t('common:cancel')}
            </Button>
          )}
        </FormButtonsWrapper>
      </form>
    </DriversFormWrapper>
  )
}
