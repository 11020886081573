import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { generatePath } from 'react-router-dom'

import { Button, Chip, useAction } from '@agro-club/agroclub-shared'

import useFormatDate from 'hooks/useFormatDate'
import useLocationFrom from 'hooks/useLocationFrom'
import TaskActions from 'modules/domain/task/duck'
import TaskSelectors from 'modules/domain/task/selectors'
import { UserShort } from 'modules/domain/types'
import { FormSection, LabeledContainer } from 'views/components/FormComponents/FormComponents'
import BidRoutes from 'views/pages/Bid/routes'
import DealRoutes from 'views/pages/Deal/routes'
import UserRoutes from 'views/pages/User/routes'
import { BlueLink } from 'views/styled/common'
import { ModalCommon } from 'views/ui/ModalCommon/ModalCommon'

type Props = {
  taskId: string | undefined
  isOpen: boolean
  close: () => void
}

const getLinkContent = (user?: UserShort) => {
  if (!user) return null

  const result: string[] = []
  const name = user.short_name?.trim()

  if (name) result.push(name)
  if (user.phone) result.push(user.phone)

  return result.join(' / ')
}

export const TaskModalDetail: React.FC<Props> = props => {
  const { isOpen, close, taskId } = props
  const task = useSelector(state => TaskSelectors.item(state, taskId))
  const { t } = useTranslation('task')
  const completeAction = useAction(TaskActions.completeTaskRequested)
  const completeProgress = useSelector(TaskSelectors.completeProgress)
  const formatDate = useFormatDate()

  const from = useLocationFrom()

  const renderDeal = useCallback(() => {
    if (!task || !task?.linked_deal?.id) {
      return null
    }
    const seller = task.linked_deal?.sale_bid?.company?.name
    const customer = task.linked_deal?.sale_bid?.company?.name
    const product = task.linked_deal?.product?.title
    const dealId = task.linked_deal?.id
    return (
      <LabeledContainer label={t('common:deal')}>
        <BlueLink
          to={{
            pathname: generatePath(DealRoutes.Edit, { id: dealId }),
            state: { from },
          }}
        >
          {`${product} | ${seller} | ${customer}`}
        </BlueLink>
      </LabeledContainer>
    )
  }, [task, from, t])

  const renderBid = () => {
    if (!task || !task.linked_bid) {
      return null
    }

    const { id: bidId } = task.linked_bid
    const { name: companyName } = task.linked_bid.company

    return (
      <LabeledContainer label={t('common:deal')}>
        <BlueLink
          to={{
            pathname: generatePath(BidRoutes.Detail, { id: bidId }),
            state: { from },
          }}
        >
          {companyName}
        </BlueLink>
      </LabeledContainer>
    )
  }

  return (
    <ModalCommon
      isOpen={isOpen}
      close={close}
      title={task?.subject || ''}
      content={
        task ? (
          <>
            <FormSection>
              <LabeledContainer label={t('form.deadline')}>{formatDate(task.deadline, true)}</LabeledContainer>
              <LabeledContainer label={t('common:team')}>{task.assigned_team?.name}</LabeledContainer>
              {!!task.assignee && (
                <LabeledContainer label={t('form.assignee')}>{getLinkContent(task.assignee)}</LabeledContainer>
              )}
              {task.comment && <LabeledContainer label={t('form.comment')}>{task.comment}</LabeledContainer>}
            </FormSection>
            <FormSection title={t('form.linkedObject')}>
              {!!task.linked_user?.id && (
                <LabeledContainer label={t('common:user')}>
                  <BlueLink
                    to={{
                      pathname: generatePath(UserRoutes.Detail, { id: task.linked_user?.id }),
                      state: { from },
                    }}
                  >
                    {getLinkContent(task.linked_user)}
                  </BlueLink>
                </LabeledContainer>
              )}
              {renderDeal()}
              {renderBid()}
            </FormSection>
          </>
        ) : null
      }
      footer={
        task?.is_completed ? (
          <Chip color="green">{t('complete')}</Chip>
        ) : (
          <Button
            progress={completeProgress}
            onClick={() => {
              task && completeAction(task.id)
            }}
            intent="primary"
            filled
          >
            {t('makeComplete')}
          </Button>
        )
      }
    />
  )
}
