import { Progress } from '@agro-club/agroclub-shared'

import { Team } from 'modules/domain/collection/types'
import { Company } from 'modules/domain/company/types'
import { AddError, EntityMetadata, FetchError, RemoveError, UpdateError, UserShort } from 'modules/domain/types'
import { Dict } from 'types/generics.d'

type BidShort = {
  id: string
  company: Company
}

type DealShort = {
  id: string
  product: {
    id: string
    slug: string
    title: string
  }
  sale_bid: BidShort
  purchase_bid: BidShort
}

export type Task = {
  assignee: UserShort
  created_by: UserShort
  assigned_team: Team
  id: string
  subject: string
  task_type: TaskType
  deadline: string // iso datetime
  comment: string | null
  linked_deal: DealShort | null // deal id
  linked_bid: BidShort | null
  linked_user: UserShort | null
  completed_at: string | null
  is_completed: boolean
  completed_by: UserShort | null
}

export type TaskListRequestFilter = {
  search?: string
  team?: string[]
  deadline_after?: string
  deadline_before?: string
  assignee?: string[]
}

export type TaskListRequestSorting = {
  sort_field?: keyof Task
  sort_reversed?: boolean
}

export enum TaskType {
  call = 'call',
  meeting = 'meeting',
  task = 'task',
}

export type TaskState = {
  items: Dict<Task>
  meta: Dict<EntityMetadata<Task>>
  ids: string[]
  listFetchProgress: Progress
  listFetchError: FetchError | null
  listFetchErrorDetail?: string
  itemFetchProgress: Progress
  itemFetchError: FetchError | null
  addProgress: Progress
  addError: AddError | null
  addErrorDetail?: string
  addErrorFields?: Record<keyof Task, string>
  updateProgress: Progress
  updateError: UpdateError | null
  removeProgress: Progress
  removeError: RemoveError | null
  removeErrorDetail?: string

  filter: TaskListRequestFilter
  sorting: TaskListRequestSorting
  page: number
  total: number
  pageSize: number

  myTasks: string[]
  myTasksFetchProgress: Progress
  completeProgress: Progress
  completeError: FetchError | null
  completeErrorDetail?: string
  taskId?: string

  userTasks: Dict<string[]>
  userTasksFetchProgress: Progress

  callBackIds: string[]
  callBackFetchProgress: Progress
  callBackPage: number
  callBackTotal: number
  callBackPageSize: number
}

export type TaskDTO = Partial<{
  id: string
  subject: string
  task_type: TaskType
  deadline: string // iso datetime
  assignee: string // user id
  comment: string
  linked_deal: string // deal id
  linked_user: string // user id
  assigned_team: string // team id
}>
