import React from 'react'
import { useTranslation } from 'react-i18next'

import { Checkbox } from '@agro-club/agroclub-shared'
import { FormikContextType } from 'formik'

import { VehicleType, WarehouseDTO } from 'modules/domain/types'
import * as S from 'views/components/WarehouseModal/styled'

type Props = {
  formik: FormikContextType<WarehouseDTO>
}

export const VehicleTypeField: React.FC<Props> = ({ formik }) => {
  const { t } = useTranslation('address')
  return (
    <>
      <S.Label>{t('fields.vehicle_type')}</S.Label>
      <S.InlineWrapper>
        {Object.keys(VehicleType).map(vehicle => (
          <Checkbox
            key={vehicle}
            onChange={(_value, isChecked) => {
              const set = new Set(formik.values.vehicle_type)
              if (isChecked) {
                if (!set.has(vehicle as VehicleType)) set.add(vehicle as VehicleType)
              } else if (set.has(vehicle as VehicleType)) set.delete(vehicle as VehicleType)
              formik.setFieldValue('vehicle_type', [...set])
            }}
            isChecked={formik.values.vehicle_type?.includes(vehicle as VehicleType)}
            label={t(`vehicles:vehicleTypes.${vehicle}`)}
          />
        ))}
      </S.InlineWrapper>
    </>
  )
}
