import React from 'react'

import { usePermissions } from 'views/components/PermissionCheck/usePermissions'

interface Props {
  scope: string[]
  children: React.ReactElement
}

const PermissionCheck: React.FC<Props> = ({ scope, children }) => {
  const available = usePermissions(scope)

  return available ? children : null
}

export default PermissionCheck
