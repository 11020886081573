import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useAction, useHelmet } from '@agro-club/agroclub-shared'

import { useNotificationProgress } from 'hooks/useNotificationProgress'
import CompanyActions from 'modules/domain/company/duck'
import CompanySelectors from 'modules/domain/company/selectors'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import { CompanyForm } from 'views/pages/Company/CompanyDetail/CompanyForm'
import { useCompanyButtons, useCompanyFormik, useCompanyGoBack } from 'views/pages/Company/CompanyDetail/hooks'
import { AddWrapper } from 'views/pages/Company/CompanyDetail/styled'
import * as Header from 'views/ui/Header/Header'

export function CompanyAdd() {
  const { t } = useTranslation('company')
  const progress = useSelector(CompanySelectors.addProgress)
  const createAction = useAction(CompanyActions.addRequested)
  const goBack = useCompanyGoBack()
  const formik = useCompanyFormik({ onSubmit: createAction })
  const buttons = useCompanyButtons({ formik, progress })

  useHelmet({ title: t('form.add') })

  useNotificationProgress(progress, t('notifySuccess'))

  return (
    <>
      <Layout.Header hasBottomBorder>
        <Header.Root onClickBack={goBack} rightBlock={buttons}>
          <Header.Title compact title={t('form.add')} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <AddWrapper>
          <CompanyForm formik={formik} mode="create" />
        </AddWrapper>
      </Layout.Content>
    </>
  )
}
