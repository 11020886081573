import React from 'react'

import styled from 'styled-components'

import * as Layout from 'views/layouts/MainLayout/MainLayout'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'
import * as Header from 'views/ui/Header/Header'

const Id = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.onPrimaryDark};
  opacity: 0.48;
  margin-top: 4px;
`

const ItemLoadingLayout: React.FC<{
  id: string
  onBack?(): void
}> = ({ id, onBack }) => (
  <>
    <Layout.Header>
      <Header.Root onClickBack={onBack}>
        <Id>{`ID ${id}`}</Id>
      </Header.Root>
    </Layout.Header>
    <Layout.Content>
      <SpinnerLayout />
    </Layout.Content>
  </>
)

export default ItemLoadingLayout
