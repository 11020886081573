import React from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'
import { CellProps } from 'react-table'

import env from 'env'
import useFormatNumber from 'hooks/useFormatNumber'
import useLocationFrom from 'hooks/useLocationFrom'
import { CarTrip } from 'modules/domain/carTrip/types'
import { DateCell } from 'views/components/CommonTableComponents/CommonTableComponents'
import {
  Address,
  Company,
  DealLink,
  LinkWrapper,
  Number,
  PhoneLink,
  ProductTitle,
} from 'views/pages/Cars/CarsDetail/styled'
import DealRoutes from 'views/pages/Deal/routes'

const DealCell = ({ row }: CellProps<CarTrip>) => {
  const from = useLocationFrom()

  return (
    <>
      <ProductTitle>{row.original.product_title}</ProductTitle>
      <LinkWrapper>
        <DealLink
          to={{
            pathname: generatePath(DealRoutes.Edit, { id: row.original.deal }),
            state: { from },
          }}
        >
          ID {row.original.deal}
        </DealLink>
      </LinkWrapper>
    </>
  )
}

const AddressFromCell = ({ row }: CellProps<CarTrip>) => (
  <>
    <Address>{row.original.from_address}</Address>
    <Company>{row.original.from_company}</Company>
    <LinkWrapper>
      <PhoneLink phone={row.original.from_owner_phone} />
    </LinkWrapper>
  </>
)

const AddressToCell = ({ row }: CellProps<CarTrip>) => (
  <>
    <Address>{row.original.to_address}</Address>
    <Company>{row.original.to_company}</Company>
    <LinkWrapper>
      <PhoneLink phone={row.original.to_owner_phone} />
    </LinkWrapper>
  </>
)

const NumberCell = ({ value }: CellProps<CarTrip>) => {
  const formatNumber = useFormatNumber()
  return <Number>{formatNumber(value)}</Number>
}
const QuantityCell = ({ value }: CellProps<CarTrip>) => {
  const formatNumber = useFormatNumber()
  return <Number>{formatNumber(value, true, 3)}</Number>
}
const PriceCell = ({ value }: CellProps<CarTrip>) => {
  const formatNumber = useFormatNumber()
  return <Number>{formatNumber(value, true)}</Number>
}

const currency = env.REACT_APP_CURRENCY_SYMBOL

const useCarTripsColumns = () => {
  const { t } = useTranslation('carTrips')
  return React.useMemo(
    () => [
      {
        Header: t('list.tableHeaders.load_date'),
        accessor: 'load_date' as const,
        Cell: DateCell,
        width: 50,
      },
      {
        Header: t('list.tableHeaders.deal'),
        Cell: DealCell,
      },
      {
        Header: t('list.tableHeaders.address_from'),
        Cell: AddressFromCell,
        width: 290,
      },
      {
        Header: t('list.tableHeaders.address_to'),
        Cell: AddressToCell,
        width: 290,
      },
      {
        Header: t('list.tableHeaders.distance'),
        accessor: 'distance' as const,
        Cell: NumberCell,
      },
      {
        Header: t('list.tableHeaders.price_logistics', { currency }),
        accessor: 'price_logistics' as const,
        Cell: PriceCell,
      },
      {
        Header: t('list.tableHeaders.load_quantity'),
        accessor: 'load_quantity' as const,
        Cell: QuantityCell,
      },
    ],
    [t],
  )
}

export default useCarTripsColumns
