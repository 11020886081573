import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Button, Typography, useAction } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import useFormatDate from 'hooks/useFormatDate'
import { DashboardTask } from 'modules/domain/dashboard/types'
import TaskActions from 'modules/domain/task/duck'
import { SubmitIcon, smallBox } from 'views/styled/common'

export const Item = styled.div`
  position: relative;
  overflow: hidden;
  ${smallBox};
`

export const Header = styled.div`
  padding: 10px 12px 9px 10px;
  border-bottom: ${({ theme }) => `1px solid ${theme.color.secondary50}`};
`

export const ItemBody = styled.div`
  padding: 8px 12px 12px;
  display: grid;
  grid-gap: 8px;
`

export const ItemTitle = styled.div`
  ${Typography.body2Bold};
  padding-right: 5px;
`

export const ItemHeaderTwoColumns = styled.div`
  display: flex;
  margin-bottom: 4px;
  & > *:first-child {
    flex-grow: 1;
  }
`

export const ItemHeaderDateCommand = styled.div`
  display: flex;
  padding-right: 38px;
  ${Typography.body2Default};
  color: ${({ theme }) => theme.color.textSecondary};
  & > *:first-child {
    flex-grow: 1;
  }
`

const ItemButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: ${({ theme }) => theme.color.primary50};
  border-radius: 8px;
  flex-shrink: 0;
  position: relative;
  transition: background-color 0.1s;
  cursor: pointer;
  svg {
    transition: fill 0.1s;
    fill: ${({ theme }) => theme.color.primary600};
  }
  &:hover {
    background-color: ${({ theme: { color } }) => color.primary600};
    svg {
      fill: ${({ theme }) => theme.color.onPrimaryLight};
    }
  }
`

export const ItemLink = styled(Link)`
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
`

export const AbsoluteContainer = styled.div`
  position: relative;
`
export const InputHeader: React.FC<{ task: DashboardTask }> = ({ task }) => {
  const completeAction = useAction(TaskActions.completeTaskRequested)
  const { t } = useTranslation()
  const formatDate = useFormatDate()

  return (
    <Header>
      <ItemHeaderTwoColumns>
        <ItemTitle>{task.subject}</ItemTitle>
        <ItemButton
          onClick={() => {
            !task.is_completed && completeAction(task.id)
          }}
        >
          <SubmitIcon />
        </ItemButton>
      </ItemHeaderTwoColumns>
      <ItemHeaderDateCommand>
        <div>
          {t('common:until')} {formatDate(task?.deadline, true)}
        </div>
        <div>{task.assigned_team?.name}</div>
      </ItemHeaderDateCommand>
    </Header>
  )
}

export const CheckButton = styled(Button)`
  background-color: ${({ theme }) => theme.color.primary50};
  width: 40px;
  height: 40px;
  padding: unset;
  &:hover {
    background-color: ${({ theme: { color } }) => color.primary600};
    svg {
      fill: ${({ theme }) => theme.color.onPrimaryLight};
    }
  }
`

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.color.primary600};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

export const Delimiter = styled.span`
  color: ${({ theme }) => theme.color.primary600};
`
