import React from 'react'
import { Link } from 'react-router-dom'

import styled, { StyledProps } from 'styled-components'

import logoImg from 'assets/images/logo-img.svg'
import logoText from 'assets/images/logo-text.svg'

type ThemedProps = StyledProps<unknown>

const Root = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
`

type ThemedColumnProps = StyledProps<{ width?: number }>

export const Sidebar = styled.div<ThemedColumnProps>`
  height: 100vh;
  display: flex;
  flex-direction: column;
  width: ${({ width, theme }: ThemedColumnProps) => `${width || theme.size.leftSideBarWidth}px`};
  transition: width 0.2s ease-out;
  position: relative;
`

export const Page = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  overflow: hidden;
`

export const LogoStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  border-right: ${({ theme }: ThemedProps) => `1px solid ${theme.color.outlineMid}`};
  flex-shrink: 0;
`

export const LogoTextStyled = styled.img`
  margin-left: 10px;
  transition: opacity 0.2s;
`

export const Logo: React.FC<{ hideText?: boolean }> = props => {
  const { hideText = false } = props
  return (
    <LogoStyled>
      <Link to="/">
        <img src={logoImg} alt="logo" />
      </Link>
      {!hideText && (
        <Link to="/">
          <LogoTextStyled src={logoText} alt="logo" />
        </Link>
      )}
    </LogoStyled>
  )
}

export const Bar = styled.div`
  border-top: ${({ theme }: ThemedProps) => `1px solid ${theme.color.outlineMid}`};
  border-right: ${({ theme }: ThemedProps) => `1px solid ${theme.color.outlineMid}`};
  flex-grow: 1;
`
export const Header = styled.div<StyledProps<{ hasBottomBorder?: boolean }>>`
  flex-shrink: 0;
  border-bottom: ${({ theme, hasBottomBorder }) => (hasBottomBorder ? `1px solid ${theme.color.outlineMid}` : 'none')};
`

export const Content = styled.div`
  background-color: ${({ theme }: ThemedProps) => theme.color.backgroundPrimary};
  flex-grow: 1;
  overflow: auto;
`

export const Layout: React.FC = ({ children }) => <Root>{children}</Root>
