import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Button, Progress, useAction, useHelmet, useHistoryPush } from '@agro-club/agroclub-shared'
import { useFormik } from 'formik'

import { useNotificationProgress } from 'hooks/useNotificationProgress'
import usePrevious from 'hooks/usePrevious'
import BidSelectors from 'modules/domain/bid/selectors'
import { useProducts } from 'modules/domain/collection/hooks'
import PotentialBidActions from 'modules/domain/potentialBid/duck'
import { usePotentialBid } from 'modules/domain/potentialBid/hooks'
import PotentialBidSelectors from 'modules/domain/potentialBid/selectors'
import Item404 from 'views/layouts/Item404/Item404'
import ItemGenericError from 'views/layouts/ItemGenericError/ItemGenericError'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import { InitialMainParameter } from 'views/pages/Bid/BidAdd/hooks'
import { Params } from 'views/pages/Bid/BidAdd/Params'
import * as Styled from 'views/pages/Bid/BidAdd/styled'
import { BidEditableFields } from 'views/pages/Bid/BidAdd/types'
import { useBidGoBack } from 'views/pages/Bid/hooks'
import BidRoutes from 'views/pages/Bid/routes'
import * as Header from 'views/ui/Header/Header'
import { NumberInput } from 'views/ui/NumberInput/NumberInput'

export const PotentialBidEdit: React.FC = () => {
  const { t } = useTranslation('bid')
  const { id } = useParams<{ id: string }>()
  const [fetchProgress, bid] = usePotentialBid(id)
  const [, products] = useProducts()
  const product = products?.find(p => p.id === bid?.product.id)
  const updateAction = useAction(PotentialBidActions.updateRequested)
  const progress = useSelector(PotentialBidSelectors.updateProgress)

  useNotificationProgress(progress, t('form.notifyEditSuccess'))

  useHelmet({ title: `${t('form.potentialEdit')} - ${product?.title}` })

  const push = useHistoryPush()
  const prevProgress = usePrevious(progress)
  useEffect(() => {
    if (progress === Progress.SUCCESS && prevProgress === Progress.WORK) {
      push({ route: BidRoutes.PotentialDetail, params: { id } })
    }
  }, [id, prevProgress, progress, push])

  const formik = useFormik<BidEditableFields>({
    initialValues: {
      quantity: bid?.quantity,
      parameters:
        bid?.parameters.map(p => ({
          parameter: p.parameter.id,
          condition: p.condition,
          parameter_value: p.parameter_value,
        })) || [],
      mainParameter: InitialMainParameter,
    },
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: () => {
      if (!formik.isValid) {
        return
      }
      const { quantity, parameters } = formik.values
      const dto = {
        quantity: quantity ?? undefined,
        parameters: parameters?.filter(p => typeof p.parameter_value !== 'undefined') || [],
      }
      updateAction(id, dto)
    },
  })

  const meta = useSelector(state => BidSelectors.meta(state, id))
  const goBack = useBidGoBack()
  const loading = () => <ItemLoadingLayout id={id} onBack={goBack} />
  const error404 = () => <Item404 id={id} onBack={goBack} title={t('errors.notFoundTitle')} />
  const errorUnknown = () => <ItemGenericError id={id} onBack={goBack} title={t('errors.unknownErrorTitle')} />

  if (fetchProgress === Progress.ERROR) {
    if (meta.fetchError === 'not_found') {
      return error404()
    }
    return errorUnknown()
  }

  if (fetchProgress === Progress.WORK || !bid) {
    return loading()
  }

  return (
    <>
      <Layout.Header hasBottomBorder>
        <Header.Root
          rightBlock={
            <Header.ButtonWrapper>
              <Button
                disabled={!formik.dirty}
                filled
                intent="primary"
                size="small"
                onClick={formik.submitForm}
                progress={progress}
              >
                {t('common:save')}
              </Button>
              <Button intent="primary" title={t('common:cancel')} size="small" onClick={goBack}>
                {t('common:cancel')}
              </Button>
            </Header.ButtonWrapper>
          }
        >
          <Header.Title title={t('form.potentialEdit')} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <Styled.TwoColumn>
          <Styled.Column>
            <Styled.Box>
              <Styled.PriceQuantity>
                <NumberInput
                  isInteger
                  invalid={formik.touched.quantity && !!formik.errors.quantity}
                  label={t('form.quantity')}
                  value={formik.values.quantity}
                  onChange={v => formik.setFieldValue('quantity', v)}
                />
              </Styled.PriceQuantity>
              {product && (
                <Params
                  {...{
                    formik,
                    product,
                  }}
                />
              )}
            </Styled.Box>
          </Styled.Column>
        </Styled.TwoColumn>
      </Layout.Content>
    </>
  )
}
