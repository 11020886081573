import { Progress } from '@agro-club/agroclub-shared'
import { createSelector } from 'reselect'

import filterDistricts from 'helpers/filterDistricts'
import { PotentialBid } from 'modules/domain/potentialBid/types'
import { EntityMetadata } from 'modules/domain/types'
import { AppGlobalState } from 'modules/types'

const dict = (state: AppGlobalState) => state.potentialBid.items
const ids = (state: AppGlobalState) => state.potentialBid.ids
const item = (state: AppGlobalState, id: string): PotentialBid | undefined => state.potentialBid.items[id]
const meta = (state: AppGlobalState, id: string): EntityMetadata<PotentialBid> =>
  state.potentialBid.meta[id] || {
    fetchError: null,
    fetchProgress: Progress.IDLE,
    id,
    removeError: null,
    removeProgress: Progress.IDLE,
    updateError: null,
    updateProgress: Progress.IDLE,
  }
const itemFetchProgress = (state: AppGlobalState) => state.potentialBid.itemFetchProgress
const listFetchProgress = (state: AppGlobalState) => state.potentialBid.listFetchProgress
const listFetchError = (state: AppGlobalState) => state.potentialBid.listFetchError
const listFetchErrorDetail = (state: AppGlobalState) => state.potentialBid.listFetchErrorDetail
const list = createSelector(dict, ids, listFetchProgress, (dict, ids, progress) => {
  if (progress === Progress.WORK) {
    return []
  }
  const result: PotentialBid[] = []
  for (const id of ids) {
    result.push(dict[id])
  }
  return result
})

const filter = (state: AppGlobalState) => state.potentialBid.filter
const filterForRequestManager = (state: AppGlobalState) => {
  const { filter } = state.potentialBid
  return filterDistricts(state, filter)
}
const sorting = (state: AppGlobalState) => state.potentialBid.sorting
const page = (state: AppGlobalState) => state.potentialBid.page
const total = (state: AppGlobalState) => state.potentialBid.total
const updateProgress = (state: AppGlobalState) => state.potentialBid.updateProgress
const removeProgress = (state: AppGlobalState) => state.potentialBid.removeProgress
const addProgress = (state: AppGlobalState) => state.potentialBid.addProgress
const hasNext = (state: AppGlobalState) => state.potentialBid.total > state.potentialBid.ids.length
const pageSize = (state: AppGlobalState) => state.potentialBid.pageSize
const pages = (state: AppGlobalState) => Math.ceil(state.potentialBid.total / state.potentialBid.pageSize)
const lastAddedId = (state: AppGlobalState) => state.potentialBid.lastAddedId
const userBidIds = (state: AppGlobalState, userId?: string) => (userId && state.potentialBid.userBidIds?.[userId]) || []
const userBidFetchProgress = (state: AppGlobalState) => state.potentialBid.userBidFetchProgress
const userBidList = (state: AppGlobalState, userId?: string) => {
  const progress = userBidFetchProgress(state)
  const ids = userBidIds(state, userId)
  const dictionary = dict(state)
  if (progress === Progress.WORK) {
    return []
  }
  const result: PotentialBid[] = []
  for (const id of ids) {
    result.push(dictionary[id])
  }
  return result
}

const PotentialBidSelectors = {
  filter,
  filterForRequestManager,
  sorting,
  page,
  total,
  updateProgress,
  removeProgress,
  addProgress,
  item,
  meta,
  itemFetchProgress,
  listFetchProgress,
  listFetchError,
  listFetchErrorDetail,
  list,
  dict,
  ids,
  hasNext,
  pageSize,
  pages,

  lastAddedId,

  userBidFetchProgress,
  userBidList,
}

export default PotentialBidSelectors
