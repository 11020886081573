import React, { VFC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { SimpleSelect, SimpleSelectProps } from '@agro-club/agroclub-shared'

import { useLegalTypes } from 'views/components/LegalTypeSelect/useLegalTypes'

type Props = {
  onChange: (val: string) => void
  invalid?: SimpleSelectProps['invalid']
  menuPlacement?: SimpleSelectProps['menuPlacement']
  value?: string
  showLabel?: boolean
  id?: string
}

export const LegalTypeSelect: VFC<Props> = ({ value, invalid, onChange, menuPlacement, showLabel = true, id }) => {
  const { t } = useTranslation('legalType')
  const [progress, items] = useLegalTypes(id)
  const options = useMemo(() => items.map(i => ({ id: i.slug, title: i.label })), [items])
  return (
    <SimpleSelect
      invalid={invalid}
      progress={progress}
      onChange={onChange}
      label={showLabel ? t('label') : undefined}
      placeholder={t('placeholder')}
      loadingMessage={t('common:loading')}
      noOptionsMessage={() => t('noLegalTypes')}
      options={options}
      value={value}
      menuPlacement={menuPlacement}
    />
  )
}
