import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { stringifyUrl } from 'helpers/stringifyUrl'
import BidSelectors from 'modules/domain/bid/selectors'
import { LocationStateFrom } from 'types/location'
import BidRoutes from 'views/pages/Bid/routes'

export const useBidGoBackLocation = () => {
  const page = useSelector(BidSelectors.page)
  const filter = useSelector(BidSelectors.filter)
  const sorting = useSelector(BidSelectors.sorting)

  const search = stringifyUrl({ url: '', query: { ...filter, ...sorting, page } })

  return {
    pathname: BidRoutes.List,
    search,
  }
}

export const useBidGoBack = () => {
  const location = useLocation<LocationStateFrom>()
  const defaultBackLocation = useBidGoBackLocation()
  const from = location.state?.from
  const history = useHistory()

  return () => {
    history.push({
      pathname: from?.pathname || defaultBackLocation.pathname,
      search: from?.pathname ? from?.search : defaultBackLocation.search,
    })
  }
}
