import React from 'react'

import { Button, Progress } from '@agro-club/agroclub-shared'

import { Body, Content, Cross, Footer, Header } from 'views/ui/ConfirmationTooltip/styled'

export type Props = {
  header: React.ReactElement
  body: React.ReactElement
  footer?: React.ReactElement
  okText?: string
  cancelText?: string
  okProgress?: Progress
  okAction?: () => void
  cancelAction?: () => void
  close: () => void
}

export const Overlay: React.FC<Props> = props => {
  const { header, body, footer, close, okAction, cancelAction, okText, cancelText, okProgress } = props

  return (
    <Content>
      <Cross onClick={close} />
      <Header>{header}</Header>
      <Body>{body}</Body>
      <Footer>
        {footer || (
          <>
            <Button
              progress={okProgress}
              intent="danger"
              onClick={() => {
                if (okAction) okAction()
              }}
            >
              {okText}
            </Button>
            <Button
              intent="primary"
              onClick={() => {
                if (cancelAction) {
                  cancelAction()
                } else {
                  close()
                }
              }}
            >
              {cancelText}
            </Button>
          </>
        )}
      </Footer>
    </Content>
  )
}
