import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import {
  Progress,
  useAction,
  useDidMount,
  useDidMountWithProgress,
  usePageQuery,
  useQuery,
} from '@agro-club/agroclub-shared'
import { isEmpty } from 'ramda'

import usePrevious from 'hooks/usePrevious'
import CarsActions, { ListRequestedParams } from 'modules/domain/cars/duck'
import CarsSelectors from 'modules/domain/cars/selectors'
import { Cars, CarsListRequestFilter, CarsListRequestSorting } from 'modules/domain/cars/types'
import { ResourceHook } from 'modules/types'

export const useCarsFilterAndSortQuery = () => {
  const query = useQuery()
  const search = query.get('query')
  const sort_field: keyof Cars = query.get('sort_field') as keyof Cars
  const sort_reversed = query.get('sort_reversed')
  const has_trailer = query.get('has_trailer')
  const car_type = query.get('car_type')
  const unloading_type = query.get('unloading_type')
  const side_height_range_max = query.get('side_height_range_max')
  const is_dump_truck = query.get('is_dump_truck')
  const is_in_charter = query.get('is_in_charter')
  const status = query.get('status')

  const filter: CarsListRequestFilter = {
    ...(search && { query: search }),
    ...(has_trailer && { has_trailer }),
    ...(car_type && { car_type }),
    ...(unloading_type && { unloading_type }),
    ...(side_height_range_max && { side_height_range_max }),
    ...(is_dump_truck && { is_dump_truck }),
    ...(is_in_charter && { is_in_charter }),
    ...(status && { status }),
  }

  const sorting: CarsListRequestSorting = {
    ...(sort_field && { sort_field }),
    ...(sort_reversed && { sort_reversed: sort_reversed === 'true' }),
  }

  return { filter, sorting }
}

export const useCarsList: ResourceHook<Cars[], void[]> = () => {
  const progress = useSelector(CarsSelectors.listFetchProgress)
  const list = useSelector(CarsSelectors.carsList)
  const page = usePageQuery()
  const { filter } = useCarsFilterAndSortQuery()
  const { sorting } = useCarsFilterAndSortQuery()

  const params: ListRequestedParams = {
    ...(!isEmpty(filter) && { filter }),
    ...(!isEmpty(sorting) && { sorting }),
    ...(page && { page }),
  }

  const fetchAction = useAction(CarsActions.listRequested, params)

  useDidMount(fetchAction)

  return [progress, list]
}

export const useLogisticsCarListReload = (progress: Progress) => {
  const page = usePageQuery()
  const { filter } = useCarsFilterAndSortQuery()
  const { sorting } = useCarsFilterAndSortQuery()

  const params: ListRequestedParams = {
    ...(!isEmpty(filter) && { filter }),
    ...(!isEmpty(sorting) && { sorting }),
    ...(page && { page }),
  }
  const fetchAction = useAction(CarsActions.listRequested, params)

  const prevProgress = usePrevious(progress)

  React.useEffect(() => {
    if (prevProgress === Progress.WORK && progress === Progress.SUCCESS) {
      fetchAction()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevProgress, progress])
}

export const useCars: ResourceHook<Cars, [string]> = (id: string) => {
  const meta = useSelector(state => CarsSelectors.meta(state, id))
  const cars = useSelector(state => CarsSelectors.cars(state, id))
  const fetchAction = useAction(CarsActions.itemRequested, id)

  const progress = useDidMountWithProgress(fetchAction, meta.fetchProgress)

  return [progress, cars]
}

export const useCarsById: ResourceHook<Cars, [string]> = (id: string) => {
  const meta = useSelector(state => CarsSelectors.meta(state, id))
  const item = useSelector(state => CarsSelectors.cars(state, id))
  const fetchAction = useAction(CarsActions.itemRequested, id)
  const notItem = !item

  useEffect(() => {
    if (id && notItem) {
      fetchAction()
    }
  }, [notItem, fetchAction, id])

  return [meta.fetchProgress, item]
}
