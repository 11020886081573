import React from 'react'
import { useTranslation } from 'react-i18next'

import { useHelmet } from '@agro-club/agroclub-shared'

import * as Layout from 'views/layouts/MainLayout/MainLayout'
import { TaskTable } from 'views/pages/Task/TaskTable'
import * as Header from 'views/ui/Header/Header'

export const TaskList: React.FC = () => {
  const { t } = useTranslation('task')
  useHelmet({ title: t('tasks') })
  return (
    <>
      <Layout.Header>
        <Header.Root>
          <Header.Title title={t('tasks')} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <TaskTable />
      </Layout.Content>
    </>
  )
}
