import React, { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'

import { useAction } from '@agro-club/agroclub-shared'

import DealActions from 'modules/domain/deal/duck'
import PageWrapper from 'views/components/PageWrapper/PageWrapper'
import { DealBoard } from 'views/pages/Deal/DealBoard/DealBoard'
import DealEdit from 'views/pages/Deal/DealEdit/DealEdit'
import DealRoutes from 'views/pages/Deal/routes'

const DealPage: React.FC = () => {
  const filterReset = useAction(DealActions.filterResetWithoutRequest)

  useEffect(
    () => () => {
      filterReset()
    },
    [filterReset],
  )

  return (
    <PageWrapper>
      <Switch>
        <Route path={DealRoutes.Edit}>
          <DealEdit />
        </Route>
        <Route path={DealRoutes.List}>
          <DealBoard />
        </Route>
      </Switch>
    </PageWrapper>
  )
}

export default DealPage
