import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { IconArrowDown } from '@agro-club/agroclub-shared'

import UserBidSelectors from 'modules/domain/userBid/selectors'
import { BidItem } from 'views/pages/User/UserDetail/UserColumnBoard/BidItem'
import { ToggleArchived } from 'views/styled/ToggleArchived'

type Props = {
  activeBidId?: string
}

export const ArchivedBids: React.FC<Props> = props => {
  const { id: userId } = useParams<{ id: string }>()
  const { activeBidId } = props
  const [open, setOpen] = useState<boolean>(false)
  const archivedBids = useSelector(state => UserBidSelectors.archivedBids(state, userId))
  const { t } = useTranslation('bid')
  if (!archivedBids || !archivedBids.length) {
    return null
  }
  return (
    <>
      <ToggleArchived
        $open={open}
        onClick={() => {
          setOpen(prev => !prev)
        }}
      >
        {open ? t('hideArchived') : t('showArchived')} <IconArrowDown />
      </ToggleArchived>
      {open && archivedBids.map(bid => <BidItem key={bid.id} {...{ bid, userId, activeBidId }} />)}
    </>
  )
}
