import React from 'react'

import { Button, ButtonFullProps, IconArrowLeftBold } from '@agro-club/agroclub-shared'
import styled, { StyledProps } from 'styled-components'

type ToggleButtonProps = StyledProps<{ rotated: boolean }>

const ToggleButton = styled(Button)<ToggleButtonProps>`
  padding: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: ${({ theme }) => `1px solid ${theme.color.outlineMid}`};
  transition: background-color 0.3s ease-out;

  &:focus {
    background-color: white;
  }

  &:active,
  &:hover {
    background-color: ${({ theme }) => theme.color.primary50} !important;
  }

  svg {
    margin-left: ${({ rotated }) => `${rotated ? '3px' : 0}`};
    transition: transform 0.3s ease-in-out;
    transform: ${({ rotated }) => `rotate(${rotated ? -180 : 0}deg)`};
  }
`

type Props = {
  rotated: boolean
  onClick: ButtonFullProps['onClick']
}

export const ToggleArrow: React.FC<Props> = props => {
  const { rotated, onClick } = props
  return (
    <ToggleButton rotated={rotated} onClick={onClick} intent="clear">
      <IconArrowLeftBold />
    </ToggleButton>
  )
}
