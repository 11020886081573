import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Input, Progress } from '@agro-club/agroclub-shared'
import { FormikContextType } from 'formik'

import { ActivityType, CompanyExtraData, CompanyExtraDataDTO } from 'modules/domain/company/types'
import { EntityMetadata } from 'modules/domain/types'
import { FormSection } from 'views/components/FormComponents/FormComponents'
import { ExtraRadio } from 'views/pages/Company/CompanyDetail/ExtraDataEdit/ExtraRadio'
import { Container, DetailHeader, ExtraDataWrapper } from 'views/pages/Company/CompanyDetail/styled'
import { FormItem } from 'views/ui/FormItem/FormItem'

type Props = {
  formik: FormikContextType<CompanyExtraDataDTO>
  errorFields: EntityMetadata<CompanyExtraData>['updateErrorFields']
  fetchProgress: Progress
}

export const ExtraDataEdit: React.FC<Props> = ({ formik, errorFields, fetchProgress }) => {
  const { t } = useTranslation('company')

  const activityOptions = useMemo(
    () =>
      (Object.keys(ActivityType) as Array<keyof typeof ActivityType>).map(key => ({
        value: key,
        label: t(`activityTypes.${key}`),
      })),
    [t],
  )
  const arbitrageOptions = useMemo(
    () => [
      { label: t('common:thereIs'), value: 1 },
      { label: t('common:no'), value: 0 },
    ],
    [t],
  )

  const yesNoOptions = useMemo(
    () => [
      { label: t('common:yes'), value: 1 },
      { label: t('common:no'), value: 0 },
    ],
    [t],
  )

  return (
    <Container>
      <DetailHeader>{t('companyExtraData')}</DetailHeader>
      <ExtraDataWrapper>
        {fetchProgress === Progress.ERROR ? (
          <p>{t('extraDataNotFound')}</p>
        ) : (
          <>
            <FormSection title={t('common')}>
              <ExtraRadio
                label={t('extra.activity_type')}
                onChange={value => {
                  formik.setFieldValue('activity_type', value)
                }}
                value={formik.values.activity_type}
                options={activityOptions}
              />
              <ExtraRadio
                label={t('extra.has_agro_arbitration_cases')}
                onChange={value => {
                  formik.setFieldValue('has_agro_arbitration_cases', !!value)
                }}
                value={formik.values.has_agro_arbitration_cases ? 1 : 0}
                options={arbitrageOptions}
              />
              <ExtraRadio
                label={t('extra.has_all_documents')}
                onChange={value => {
                  formik.setFieldValue('has_all_documents', !!value)
                }}
                value={formik.values.has_all_documents ? 1 : 0}
                options={yesNoOptions}
              />
              <ExtraRadio
                label={t('extra.directors_companies_have_problems')}
                onChange={value => {
                  formik.setFieldValue('directors_companies_have_problems', !!value)
                }}
                value={formik.values.directors_companies_have_problems ? 1 : 0}
                options={yesNoOptions}
              />
              <ExtraRadio
                label={t('extra.connected_companies_have_problems')}
                onChange={value => {
                  formik.setFieldValue('connected_companies_have_problems', !!value)
                }}
                value={formik.values.connected_companies_have_problems ? 1 : 0}
                options={yesNoOptions}
              />
            </FormSection>
            <FormSection title={t('arbitrage')}>
              <FormItem
                fieldName="arbitration_claims_sum"
                errorFields={errorFields}
                formikInstance={formik}
                render={additionalProps => (
                  <Input
                    label={t('extra.arbitration_claims_sum')}
                    {...formik.getFieldProps('arbitration_claims_sum')}
                    {...additionalProps}
                  />
                )}
              />
            </FormSection>
            <FormSection title={t('finance')}>
              <ExtraRadio
                label={t('extra.has_blocked_bank_accounts')}
                onChange={value => {
                  formik.setFieldValue('has_blocked_bank_accounts', !!value)
                }}
                value={formik.values.has_blocked_bank_accounts ? 1 : 0}
                options={yesNoOptions}
              />

              <FormItem
                fieldName="paid_us_without_delay_amount"
                errorFields={errorFields}
                formikInstance={formik}
                render={additionalProps => (
                  <Input
                    label={t('extra.paid_us_without_delay_amount')}
                    {...formik.getFieldProps('paid_us_without_delay_amount')}
                    {...additionalProps}
                  />
                )}
              />
            </FormSection>
          </>
        )}
      </ExtraDataWrapper>
    </Container>
  )
}
