import { AxiosError } from 'axios'

import { refreshToken as refreshTokenPair } from 'modules/domain/auth/managers'
import { clearToken, getRefreshToken, saveAccessToken, saveRefreshToken } from 'modules/domain/auth/repository'

import { axiosClient } from './httpClient'

export const refreshTokenAndRetry = async (error: AxiosError) => {
  const { response: errorResponse } = error
  const refreshToken = getRefreshToken()

  if (!refreshToken || !errorResponse?.config) {
    throw error
  }

  try {
    const response = await refreshTokenPair(refreshToken)
    saveRefreshToken(response.refreshToken)
    saveAccessToken(response.accessToken)

    const config = {
      ...errorResponse.config,
      headers: { ...errorResponse.config.headers, Authorization: `Bearer ${response.accessToken}` },
    }

    return await axiosClient(config)
  } catch (e) {
    clearToken()
    // eslint-disable-next-line no-console
    console.log('Failed to retry request')
    throw e
  }
}
