import React from 'react'
import { useSelector } from 'react-redux'

import { Progress, useAction, useDidMount, usePageQuery } from '@agro-club/agroclub-shared'

import CarTripActions from 'modules/domain/carTrip/duck'
import CarTripSelectors from 'modules/domain/carTrip/selectors'
import { CarTrip, ListRequestedParams } from 'modules/domain/carTrip/types'
import { ResourceHook } from 'modules/types'

export const useCarTripList: ResourceHook<CarTrip[], [string]> = (carId: string) => {
  const progress = useSelector(CarTripSelectors.listFetchProgress)
  const list = useSelector(state => CarTripSelectors.list(state, carId))
  const page = usePageQuery()

  const params: ListRequestedParams = {
    carId,
    ...(page && { page }),
  }

  const fetchAction = useAction(CarTripActions.listRequested, params)

  useDidMount(fetchAction)

  return [progress, list]
}

export const useCarDealTripList = (carId = '', dealId = ''): [Progress, CarTrip[]] => {
  const progress = useSelector(CarTripSelectors.listFetchProgress)
  const list = useSelector(state => CarTripSelectors.list(state, carId, dealId))
  const fetchAction = useAction(CarTripActions.listRequested, { carId, dealId })

  React.useEffect(() => {
    if (carId && dealId) {
      fetchAction()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carId, dealId])

  return [progress, list]
}
