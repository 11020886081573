import formatSortParam from 'helpers/formatSortParam'
import {
  AverageStatistics,
  CallStatistics,
  CallStatisticsListRequestFilter,
  CallStatisticsListRequestSorting,
  GeneralStatistics,
} from 'modules/domain/callStatistics/types'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { ListResponse } from 'types/api'

export const getCallStatisticsList = (
  filter: CallStatisticsListRequestFilter,
  sorting: CallStatisticsListRequestSorting,
  page: number,
  pageSize?: number,
) => {
  try {
    return apiClient.get<ListResponse<CallStatistics>>(endpoints.callStatistics(), {
      ...filter,
      sort: formatSortParam(sorting),
      page,
      page_size: pageSize,
    })
  } catch (err) {
    console.error('Call statistic list fetch error!', err)
    throw err
  }
}

export const getAverageStatistics = () => {
  try {
    return apiClient.get<ListResponse<AverageStatistics>>(endpoints.callStatisticsAverage())
  } catch (err) {
    console.error('Average statistic list fetch error!', err)
  }
}
export const getGeneralStatistics = () => {
  try {
    return apiClient.get<ListResponse<GeneralStatistics>>(endpoints.callStatisticsGeneral())
  } catch (err) {
    console.error('General statistic list fetch error!', err)
  }
}
