import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useAction, useDidMount, usePageQuery, useQuery } from '@agro-club/agroclub-shared'
import { isEmpty } from 'ramda'

import AuthSelectors from 'modules/domain/auth/selectors'
import LogisticsDealActions, { ListRequestedParams } from 'modules/domain/logisticsDeal/duck'
import { ResourceHook } from 'modules/types'

import LogisticsDealSelectors from './selectors'
import { LogisticsDeal, LogisticsDealListRequestFilter, LogisticsDealListRequestSorting } from './types'

export const useLogisticsDealFilterAndSortQuery = () => {
  const query = useQuery()
  const detail_status = query.get('detail_status')
  const sort_field: keyof LogisticsDeal = query.get('sort_field') as keyof LogisticsDeal
  const sort_reversed = query.get('sort_reversed')

  const teamStr = query.get('team')
  const team = teamStr ? teamStr.split(',') : undefined

  const logisticsStatusStr = query.get('logistics_status')
  const logistics_status = logisticsStatusStr ? logisticsStatusStr.split(',') : undefined

  const filter: LogisticsDealListRequestFilter = {
    ...(detail_status && { detail_status }),
    ...(team && team.length && { team }),
    ...(logistics_status && { logistics_status }),
  }

  const sorting: LogisticsDealListRequestSorting = {
    ...(sort_field && { sort_field }),
    ...(sort_reversed && { sort_reversed: sort_reversed === 'true' }),
  }

  return { filter, sorting }
}

export const useLogisticsDealList: ResourceHook<LogisticsDeal[], void[]> = () => {
  const progress = useSelector(LogisticsDealSelectors.listFetchProgress)
  const list = useSelector(LogisticsDealSelectors.list)
  const page = usePageQuery()
  const currentTeam = useSelector(AuthSelectors.team)
  let { filter } = useLogisticsDealFilterAndSortQuery()
  const { sorting } = useLogisticsDealFilterAndSortQuery()

  if (!filter?.team?.length && currentTeam?.id) {
    filter = { ...filter, team: [String(currentTeam.id)] }
  }

  const params: ListRequestedParams = {
    ...(!isEmpty(filter) && { filter }),
    ...(!isEmpty(sorting) && { sorting }),
    ...(page && { page }),
  }

  const fetchAction = useAction(LogisticsDealActions.listRequested, params)

  useDidMount(fetchAction)

  return [progress, list]
}

export const useLogisticsDeal: ResourceHook<LogisticsDeal, [string | undefined]> = (id?: string) => {
  const meta = useSelector(state => LogisticsDealSelectors.meta(state, id))
  const item = useSelector(state => LogisticsDealSelectors.item(state, id))
  const fetchAction = useAction(LogisticsDealActions.itemRequested, id)

  useDidMount(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  })

  return [meta.fetchProgress, item]
}

export const useLogisticsDealById: ResourceHook<LogisticsDeal, [string]> = (id: string) => {
  const meta = useSelector(state => LogisticsDealSelectors.meta(state, id))
  const item = useSelector(state => LogisticsDealSelectors.item(state, id))
  const fetchAction = useAction(LogisticsDealActions.itemRequested, id)

  useEffect(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  }, [item, id, fetchAction])

  return [meta.fetchProgress, item]
}
