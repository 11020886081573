import React from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@agro-club/agroclub-shared'
import styled, { StyledProps } from 'styled-components'

import useFormatDate from 'hooks/useFormatDate'
import { Task } from 'modules/domain/task/types'

const Item = styled.div`
  padding: 8px 0 12px;
  border-bottom: ${({ theme }) => `1px solid ${theme.color.outlineMid}`};
  cursor: pointer;
`

type DeadlineProps = StyledProps<{ $isCompleted: boolean }>
const Deadline = styled.div<DeadlineProps>`
  ${Typography.caption1Bold};
  color: ${({ theme, $isCompleted }) => ($isCompleted ? theme.color.onSurfaceMidEmphasys : theme.color.accentNotify)};
`

const Status = styled.div`
  ${Typography.body2Default};
  color: ${({ theme }) => theme.color.onSurfaceMidEmphasys};
`

const Subject = styled.div`
  ${Typography.body2Bold}
`

export const TaskItem: React.FC<{ task: Task; setOpenTask: (taskId: Task['id']) => void }> = ({
  task,
  setOpenTask,
}) => {
  const { t } = useTranslation('task')
  const formatDate = useFormatDate()
  return (
    <Item
      onClick={() => {
        setOpenTask(task.id)
      }}
    >
      <Deadline $isCompleted={task.is_completed}>{formatDate(task.deadline, true)}</Deadline>
      <Subject>{task.subject}</Subject>
      <Status>{task.is_completed ? t('complete') : t('notComplete')}</Status>
    </Item>
  )
}
