import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Progress, useAction } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import { useNotificationProgress } from 'hooks/useNotificationProgress'
import usePrevious from 'hooks/usePrevious'
import DealActions from 'modules/domain/deal/duck'
import DealSelectors from 'modules/domain/deal/selectors'
import { Deal, DealBid } from 'modules/domain/deal/types'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'

import { BidDetailSection } from './BidDetailSection'
import { DealDetailSection } from './DealDetailSection'
import { Mode } from './DealEdit'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'detail detail'
    'left right';
  grid-gap: 16px;
`

const DetailSection = styled.div`
  grid-area: detail;
`

const LeftSection = styled.div`
  grid-area: left;
`
const RightSection = styled.div`
  grid-area: right;
`

type DealEditProps = {
  item: Deal
  progress: Progress
}

export const DealEditBody: React.FC<DealEditProps> = ({ item, progress }: DealEditProps) => {
  const { t } = useTranslation('deal')

  const [leftMode, setLeftMode] = useState<Mode>('view')
  const [rightMode, setRightMode] = useState<Mode>('view')

  const updateSaleBidAction = useAction(DealActions.changeSaleBidRequested)
  const updatePurchaseBidAction = useAction(DealActions.changePurchaseBidRequested)

  const changeSaLeBidProgress = useSelector(state => DealSelectors.changeSaLeBidProgress(state, item?.id || ''))
  const changePurchaseBidProgress = useSelector(state => DealSelectors.changePurchaseBidProgress(state, item?.id || ''))
  useNotificationProgress(changeSaLeBidProgress, t('notifySaleBidChanged'))
  useNotificationProgress(changePurchaseBidProgress, t('notifyPurchaseBidChanged'))

  const prevSaLeBidProgress = usePrevious(changeSaLeBidProgress)
  useEffect(() => {
    if (changeSaLeBidProgress === Progress.SUCCESS && prevSaLeBidProgress === Progress.WORK) {
      setLeftMode('view')
    }
  }, [changeSaLeBidProgress, prevSaLeBidProgress])

  const prevPurchaseBidProgress = usePrevious(changePurchaseBidProgress)
  useEffect(() => {
    if (changePurchaseBidProgress === Progress.SUCCESS && prevPurchaseBidProgress === Progress.WORK) {
      setRightMode('view')
    }
  }, [changePurchaseBidProgress, prevPurchaseBidProgress])

  if (progress === Progress.WORK) {
    return <SpinnerLayout />
  }

  return (
    <Wrapper>
      <DetailSection>
        <DealDetailSection deal={item} />
      </DetailSection>
      <LeftSection>
        <BidDetailSection
          isClosed={!!item.closed_at}
          progress={changeSaLeBidProgress}
          mode={item.closed_at ? 'view' : leftMode}
          bid={item.sale_bid as DealBid}
          onStartEdit={() => {
            setLeftMode('edit')
            setRightMode('view')
          }}
          onCancel={() => setLeftMode('view')}
          onSubmit={({ price, volume }) => {
            updateSaleBidAction(item.id, { price, quantity: volume })
          }}
        />
      </LeftSection>
      <RightSection>
        <BidDetailSection
          isClosed={!!item.closed_at}
          progress={changePurchaseBidProgress}
          mode={item.closed_at ? 'view' : rightMode}
          bid={item.purchase_bid as DealBid}
          onStartEdit={() => {
            setRightMode('edit')
            setLeftMode('view')
          }}
          onCancel={() => setRightMode('view')}
          onSubmit={({ price, volume }) => {
            updatePurchaseBidAction(item.id, { price, quantity: volume })
          }}
        />
      </RightSection>
    </Wrapper>
  )
}
