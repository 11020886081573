import { Button } from '@agro-club/agroclub-shared'
import styled, { StyledProps, css, keyframes } from 'styled-components'

import { helpersColor } from 'views/components/CallStatisticsTable/color'
import { Spinner as SpinnerComponent } from 'views/components/CallStatisticsTable/Spinner'

type ThemedProps = StyledProps<Record<string, unknown>>

export const Table = styled.table<{ fixedLayout?: boolean }>`
  flex-grow: 1;
  border-spacing: 0 0px;
  margin-top: 16px;
  width: 100%;
  min-width: 800px;
  max-width: 1038px;
  table-layout: ${props => (props.fixedLayout ? 'fixed' : 'auto')};
  /* this is important to proper borders render with sticky header */
  border-collapse: separate;
`
export const TableWrapper = styled.div<{ isSummaryOpen?: boolean }>`
  flex-grow: 1;
  width: 100%;
  min-width: 995px;
  min-height: 0;
  padding-bottom: ${({ isSummaryOpen }: { isSummaryOpen?: boolean }) => (isSummaryOpen ? '120px' : 0)};
`
export const TableInner = styled.div`
  position: relative;
  min-height: 100%;
  padding: 0 24px 60px;
`

export const Body = styled.tbody`
  overflow: auto;
`
type HeadCellStyledProps = StyledProps<{
  width?: number | string
  sortable?: boolean
  top?: string
  emphatic?: boolean
  transparent?: boolean
  headerHorizontalAlign?: 'left' | 'right' | 'center'
  headerVerticalAlign?: 'top' | 'bottom' | 'middle'
}>

const headCellBackgroundCSS = (props: HeadCellStyledProps) => {
  let backgroundColor = props.theme.color.onPrimaryLight
  if (props.transparent) {
    backgroundColor = 'unset'
  }
  if (props.emphatic) {
    backgroundColor = props.theme.color.primary25
  }

  return css`
    background-color: ${backgroundColor};
    &:first-child,
    &:last-child {
      &::before {
        background-color: ${backgroundColor};
      }
    }
  `
}

const headerCellAlignCSS = (props: HeadCellStyledProps) => {
  const align = props.headerHorizontalAlign || 'left'
  const valign = props.headerVerticalAlign || 'middle'
  return css`
    vertical-align: ${valign};
    text-align: ${align};
  `
}

export const HeadCell = styled.th<HeadCellStyledProps>`
  ${headCellBackgroundCSS};
  ${headerCellAlignCSS};

  position: sticky;
  height: 32px;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  padding: 0 12px;
  white-space: normal;
  word-break: keep-all;
  top: 0;
  box-sizing: border-box;
  border-bottom: ${({ theme }) => `1px solid ${theme.color.outlineMid}`};

  &:last-child {
    border-right: none;
  }

  cursor: ${({ sortable }: HeadCellStyledProps) => (sortable ? 'pointer' : 'default')};

  &:first-child {
    border-radius: 8px 0 0 0;
  }
  &:last-child {
    border-radius: 0 8px 0 0;
  }
`

const mapVerticalAlignToAlignItems = {
  top: 'flex-start',
  bottom: 'flex-end',
  middle: 'center',
}

export const HeadCellWrapper = styled.div<{
  headerHorizontalAlign: 'left' | 'center' | 'right'
  headerVerticalValign: 'top' | 'bottom' | 'middle'
}>`
  display: inline-flex;
  flex-direction: row;
  align-items: ${props => mapVerticalAlignToAlignItems[props.headerVerticalValign]};
  justify-content: ${props => props.headerHorizontalAlign};
  width: 100%;
`

type CellInnerProps = StyledProps<{ isSorted?: boolean; sortable?: boolean }>
export const HeadCellInner = styled.div<CellInnerProps>`
  max-width: ${({ sortable }: CellInnerProps) => (sortable ? 'calc(100% - 20px)' : '100%')};
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  transition: 0.3s color;
  vertical-align: middle;
  color: ${({ isSorted, theme }: CellInnerProps) =>
    isSorted ? theme.color.primary600 : theme.color.onSurfaceMidEmphasys};
`

type BodyCellProps = StyledProps<{
  emphatic?: boolean
  cellAlign?: 'left' | 'right' | 'center'
  cellValign?: 'top' | 'bottom' | 'middle'
  width?: string | number
}>

const bodyCellAlignCSS = (props: BodyCellProps) => {
  const valign = props.cellValign || 'top'
  const align = props.cellAlign || 'left'
  return css`
    vertical-align: ${valign};
    text-align: ${align};
  `
}

export const BodyCell = styled.td<BodyCellProps>`
  ${bodyCellAlignCSS};
  height: 44px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.color.onPrimaryDark};
  background-color: ${({ theme, emphatic }) => (emphatic ? theme.color.primary25 : theme.color.onPrimaryLight)};

  transition: 0.2s background-color, 0.2s border-color;
  padding: 13px 12px 11px;

  &:not(:first-child > *) {
    border-top: ${({ theme }) => `1px solid ${theme.color.outlineMid}`};
  }

  width: ${({ width }: HeadCellStyledProps) => (typeof width === 'string' ? width : '')};
`

type RowStyledProps = StyledProps<{ clickable: boolean; selected?: boolean }>

export const Row = styled.tr<RowStyledProps>`
  min-height: 56px;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04);

  &:last-child > * {
    &:last-child {
      border-radius: 0 0 8px 0;
    }
    &:first-child {
      border-radius: 0 0 0 8px;
    }
  }

  ${({ selected, theme }: RowStyledProps) => {
    if (selected) {
      return css`
        cursor: pointer;

        ${BodyCell} {
          background: ${helpersColor.alphaColor(theme.color.primary600, 0.04)};
          border-color: ${helpersColor.alphaColor(theme.color.primary600, 0.48)};
        }

        &:hover {
          ${BodyCell} {
            border-color: ${helpersColor.alphaColor(theme.color.primary600, 0.48)};
          }
        }
      `
    }
  }}

  ${({ clickable }: RowStyledProps) => {
    if (clickable) {
      return css`
        cursor: pointer;

        &:hover {
          ${BodyCell} {
            border-color: rgba(3, 123, 255, 0.48);
          }
        }
      `
    }
  }};
`

export const EllipsisContainer = styled.span<CellInnerProps>`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
type SortStyledProps = StyledProps<{
  show?: boolean
  desc?: boolean
  headerHorizontalAlign: 'left' | 'right' | 'center'
}>
export const Sort = styled.div<SortStyledProps>`
  order: ${props => (props.headerHorizontalAlign === 'left' ? '1' : '-1')};
  margin: ${props => (props.headerHorizontalAlign === 'left' ? '0 0 0 6px' : '0 6px 0 0')};
  display: ${({ show }: SortStyledProps) => (show ? 'inline-block' : 'none')};
  > svg {
    transform: scale(${props => (props.headerHorizontalAlign === 'left' ? -1 : 1)}, ${props => (props.desc ? -1 : 1)});
    display: inline;
    width: 12px;
    height: 0.8rem;
  }
`
export const EmptyRowCell = styled.td<ThemedProps>`
  text-align: center;
  padding: 40px;
  color: ${({ theme }: ThemedProps) => theme.color.secondary200};
`

export const ActionPanelWrapper = styled.div<ThemedProps>`
  position: fixed;
  bottom: 20px;
  width: ${({ theme }: ThemedProps) => `calc(100% - ${theme.size.leftSideBarWidth}px)`};
  padding: 24px 24px 0 24px;
`

export const ActionPanelContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 20px 20px 40px;
  background: ${({ theme }) => theme.color.onPrimaryLight};
  box-shadow: 0 24px 48px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
`

export const ActionPanelContentWrapper = styled.div`
  display: flex;
  width: 100%;
`

export const CloseButton = styled(Button)`
  justify-self: flex-end;
  margin-left: auto;
  padding: 13px;
`

export const Spinner = styled(SpinnerComponent)`
  margin: 20px auto;
`

export const PaginationContainer = styled.div`
  position: absolute;
  bottom: 0;
`

export const HeadStubCell = styled.th`
  background-color: #fff;
  border: ${({ theme }: HeadCellStyledProps) => `1px solid ${theme.color.onSurfaceLowEmphasys}`};
  border-left: none;
  border-right: none;
`

const skeletonGradient = keyframes`
  0% {
    background-position-x: 100%;
  }
  50% {
    background-position-x: 0;
  }
  100% {
    background-position-x: -100%;
  }
`

export const SkeletonCell = styled(BodyCell)`
  position: relative;
  height: 48px;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(to left, rgba(255, 255, 255, 0) 48%, #fff 50%, rgba(255, 255, 255, 0) 52%);
    background-size: 200% 200%;
    animation: ${skeletonGradient} 1.1s cubic-bezier(0.5, 0.02, 0.46, 0.92) infinite;
  }
  &::before {
    width: 100%;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #efeded;
    display: block;
    border-radius: 4px;
  }
`
