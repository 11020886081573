import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DocumentFile } from 'modules/domain/types'
import { Ellipsis, FileItemStyled, Link, RemoveFile, SmallText, Sure } from 'views/components/FilesByTypes/styled'
import { ConfirmationTooltip } from 'views/ui/ConfirmationTooltip/ConfirmationTooltip'
import Tooltip from 'views/ui/Tooltip/Tooltip'

interface Props {
  file: DocumentFile
  onDelete: (document_id: string) => void
}

const FilesGroup: React.FC<Props> = ({ file, onDelete }) => {
  const { t } = useTranslation('common')
  const [isConfirmVisible, setConfirmVisible] = useState<boolean>(false)

  return (
    <FileItemStyled>
      <Link href={file.uploaded_file} target="_blank">
        <Tooltip placement="top" overlay={<SmallText> {file.original_filename}</SmallText>}>
          <Ellipsis>{file.original_filename}</Ellipsis>
        </Tooltip>
      </Link>
      <ConfirmationTooltip
        hideArrow
        visible={isConfirmVisible}
        placement="bottomRight"
        overlayProps={{
          header: t('removeDocumentHeader'),
          body: <Sure>{t('removeDocument')}</Sure>,
          okText: t('common:delete'),
          okAction: () => {
            onDelete(file.id)
          },
          cancelText: t('common:cancel'),
          close: () => {
            setConfirmVisible(false)
          },
        }}
      >
        <div>
          <RemoveFile
            onClick={() => {
              setConfirmVisible(true)
            }}
          />
        </div>
      </ConfirmationTooltip>
    </FileItemStyled>
  )
}

export default FilesGroup
