import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ColumnInstance, useTable } from 'react-table'

import { TableHeadCellProps, TableNoData, useAction } from '@agro-club/agroclub-shared'

import CallStatisticsActions from 'modules/domain/callStatistics/duck'
import { useCallStatisticsFilter, useCallStatisticsList } from 'modules/domain/callStatistics/hooks'
import CallStatisticsSelectors from 'modules/domain/callStatistics/selectors'
import { CallStatistics } from 'modules/domain/callStatistics/types'
import { AdvancedHeadCell } from 'views/components/CallStatisticsTable/AdvancedHeadCell'
import {
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHead,
  TableHeadRow,
} from 'views/components/CallStatisticsTable/Table'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import { RangeFilter } from 'views/components/TableFilters/RangeFilter'
import AverageStatisticsTable from 'views/pages/CallStatistics/CallStatisticsTable/AverageStatisticsTable'
import GeneralStatisticsTable from 'views/pages/CallStatistics/CallStatisticsTable/GeneralStatisticsTable'
import { BorderBottom, Content, RightPanelWrapper } from 'views/pages/CallStatistics/CallStatisticsTable/styled'
import useColumns from 'views/pages/CallStatistics/CallStatisticsTable/useColumns'

const CallStatisticsSortableHeadCell = AdvancedHeadCell<keyof CallStatistics>()
type ColumnProps = ColumnInstance<CallStatistics> & TableHeadCellProps

const CallStatisticsTable: React.FC = () => {
  const { t } = useTranslation('callStatistics')
  const [progress, data] = useCallStatisticsList()

  const sortingUpdated = useAction(CallStatisticsActions.sortingUpdated)
  const listRequested = useAction(CallStatisticsActions.listRequested)
  const filterUpdated = useAction(CallStatisticsActions.filterUpdated)

  const filterState = useSelector(CallStatisticsSelectors.filter)
  const total = useSelector(CallStatisticsSelectors.total)
  const pages = useSelector(CallStatisticsSelectors.pages)
  const page = useSelector(CallStatisticsSelectors.page)
  const pageSize = useSelector(CallStatisticsSelectors.pageSize)
  const { sort_field, sort_reversed } = useSelector(CallStatisticsSelectors.sorting)

  const { filter } = useCallStatisticsFilter()
  const start = filter.call_start_after || filterState.call_start_after
  const end = filter.call_start_before || filterState.call_start_before

  const visibleColumns = useColumns()

  const { columns, rows, prepareRow } = useTable<CallStatistics>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore weird issue with react-table typings — having more then 26 fields in type causes TS error
    columns: visibleColumns,
    data: data as CallStatistics[],
  })

  const onChangePeriod = React.useCallback(
    ([start, end]) => {
      filterUpdated({ ...filterState, call_start_after: start, call_start_before: end })
    },
    [filterState, filterUpdated],
  )

  const setPage = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  return (
    <TComponents.Wrapper>
      <BorderBottom>
        <TComponents.Filters>
          <RangeFilter label={t('callsPeriod')} onChange={onChangePeriod} start={start} end={end} />
        </TComponents.Filters>
      </BorderBottom>
      <Content>
        <div>
          <Table total={total} pages={pages} pageSize={pageSize} currentPage={page} onSetPage={setPage}>
            <TableHead>
              <TableHeadRow>
                {columns.map((column: ColumnProps) => (
                  <CallStatisticsSortableHeadCell
                    width={column.width}
                    zIndex={1}
                    key={column.getHeaderProps().key}
                    id={column.id as keyof CallStatistics}
                    sortable={column.sortable}
                    sortField={sort_field}
                    sortDesc={sort_reversed}
                    onChange={sortingUpdated}
                    headerHorizontalAlign={column.headerHorizontalAlign as TableHeadCellProps['headerHorizontalAlign']}
                  >
                    {column.render('Header')}
                  </CallStatisticsSortableHeadCell>
                ))}
              </TableHeadRow>
            </TableHead>
            <TableBody>
              {rows.map(row => {
                prepareRow(row)
                const { key, ...props } = row.getRowProps()

                return (
                  <TableBodyRow key={key} {...props}>
                    {row.cells.map(cell => {
                      const { key, ...props } = cell.getCellProps()
                      return (
                        <TableBodyCell key={key} {...props}>
                          {cell.render('Cell')}
                        </TableBodyCell>
                      )
                    })}
                  </TableBodyRow>
                )
              })}
              <TableNoData
                progress={progress}
                isEmpty={!rows.length}
                colSpan={visibleColumns.length}
                loading={<TComponents.Spinner />}
              >
                No data
              </TableNoData>
            </TableBody>
          </Table>
        </div>
        <RightPanelWrapper>
          <GeneralStatisticsTable />
          <AverageStatisticsTable />
        </RightPanelWrapper>
      </Content>
    </TComponents.Wrapper>
  )
}

export default CallStatisticsTable
