import { Typography } from '@agro-club/agroclub-shared'
import styled, { StyledProps } from 'styled-components'

export const FieldWrapper = styled.div<StyledProps<{ $width?: number }>>`
  overflow: visible;
  width: ${({ $width }) => ($width ? `${$width}px` : '100%')};
`

export const Error = styled.div`
  ${Typography.caption1Default};
  color: ${({ theme }) => theme.color.accentDestruct800};
  margin-top: 4px;
`
