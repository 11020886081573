import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, generatePath } from 'react-router-dom'

import {
  Button,
  DatePicker,
  FontWeight,
  IconBin,
  IconCross,
  IconLinkOpener,
  IconPencil,
  Progress,
  SectionBody,
  SectionContainer,
  SectionTable,
  SectionTableBodyRow,
  SectionTableHeadRow,
  Typography,
  SectionTableBodyCell as UISectionTableBodyCell,
  SectionTableHeadCell as UISectionTableHeadCell,
} from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import useFormatNumber from 'hooks/useFormatNumber'
import UserRoutes from 'views/pages/User/routes'
import { IconSubmitContainer, SubmitIcon } from 'views/styled/common'
import { NumberInput } from 'views/ui/NumberInput/NumberInput'

export const CardWrapper = styled(SectionContainer)`
  height: 100%;
`

export const CardBody = styled(SectionBody)`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 0;
`

export const CardHeaderWrapper = styled.div`
  padding: 12px 24px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const WarehouseHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  margin-top: 12px;
`

export const WarehouseWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

export const WarehousePropsWrapper = styled.div`
  padding: 0px 12px 24px 24px;
`

export const SpecificationWrapper = styled.div`
  padding: 20px 24px;
`

export const CardHeaderGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
`

export const BidCardHeaderGroup = styled(CardHeaderGroup)`
  min-width: 88px;
  margin-left: 10px;
`

export const CardHeaderTitle = styled.div`
  ${Typography.headlineSemibold};
`

export const CardHeaderTitleLarge = styled.div`
  ${Typography.largeHeadlineBold};
`

export const StyledLink = styled(Link)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: bottom;
  height: 24px;
  width: 24px;
`

export const CardHeaderLinkIcon = styled(IconLinkOpener)`
  fill: ${props => props.theme.color.secondary200};
  cursor: pointer;
  transition: fill 0.1s ease-in;

  &:hover {
    fill: ${props => props.theme.color.secondary300};
  }
`

export const CardHeaderSubTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
`

export const CardKeyList = styled.div`
  display: grid;
  grid-gap: 4px 50px;
  grid-template-columns: max-content max-content;
  align-items: center;
`
export const CardPairKey = styled.div<{ isBold?: boolean }>`
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
  ${Typography.body2Default};
  white-space: nowrap;
  ${({ isBold }) => (isBold ? FontWeight.Bold : '')}
`

export const CardPairValue = styled.div<{ align?: string }>`
  color: ${props => props.theme.color.onSurfaceHighEmphasys};
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  ${({ align }) => (align ? `text-align:${align}` : '')}
`
export const CardKeyValueGroup: React.FC<{
  title?: string
  value?: string | number | React.ReactNode
  unit?: string
  isPrice?: boolean
  customValue?: string | React.ReactElement
  isBold?: boolean
  align?: 'right' | 'left'
}> = ({ title, value, unit, isPrice = false, customValue, isBold, align }) => {
  const formatNumber = useFormatNumber()
  return (
    <>
      <CardPairKey isBold={isBold}>{title}</CardPairKey>
      <CardPairValue align={align}>
        {customValue || (
          <>
            {typeof value === 'string' || typeof value === 'number' ? formatNumber(value, isPrice) : value || ''} {unit}
          </>
        )}
      </CardPairValue>
    </>
  )
}

export const StyledNumberInput = styled(NumberInput)`
  width: 100px;
`

export const FieldUnitWrapper = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: max-content auto;
`

export const CancelIcon = styled(IconCross)``

export const IconContainer = styled(Button)`
  border: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
`

const CardEditModeIconsContainer = styled.div`
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  grid-gap: 8px;
`

type CardEditModeIconsProps = { onSubmit: () => void; onCancel: () => void; progress?: Progress }

export const CardEditModeIcons: React.FC<CardEditModeIconsProps> = ({ onSubmit, onCancel, progress }) => {
  const { t } = useTranslation('common')
  return (
    <CardEditModeIconsContainer>
      <IconContainer intent="minor-action" onClick={onCancel} title={t('cancel')}>
        <CancelIcon />
      </IconContainer>
      <IconContainer progress={progress} intent="primary" filled onClick={onSubmit} title={t('save')}>
        <IconSubmitContainer>
          <SubmitIcon />
        </IconSubmitContainer>
      </IconContainer>
    </CardEditModeIconsContainer>
  )
}

export const Beside = styled.div`
  display: grid;
  grid-template-columns: 1048px 566px;
  grid-column-gap: 16px;
  padding: 16px 22px;
`

export const Container = styled.div`
  && > div > div:before {
    background-color: ${props => props.theme.color.secondary50};
  }
`

export const DrawerShippingWrapper = styled.div``

export const TableWrapper = styled.div`
  border: 1px solid ${props => props.theme.color.secondary50};
  border-radius: 4px;
  margin-bottom: 8px;
`

export const ErrorWrapper = styled.div`
  display: flex;
  justify-content: center;
  color: ${props => props.theme.color.secondary300};
`

export const SectionTableHeadCell = styled(UISectionTableHeadCell)`
  vertical-align: top;
  font-size: 14px;
  color: ${({ theme }) => theme.color.onSurfaceMidEmphasys};
`

export const SectionTableWithoutBorder = styled(SectionTable)`
  border-collapse: collapse;
`

export const SectionTableHeadRowWithPadding = styled(SectionTableHeadRow)`
  & > td:first-child {
    padding-left: 8px;
  }
`

export const SelectableTableRow = styled(SectionTableBodyRow)<{ selected?: boolean }>`
  & > td {
    font-weight: 600;
    background-color: ${props => (props.selected ? props.theme.color.primary50 : '')};
    white-space: nowrap;

    &:first-child {
      border-radius: 4px 0 0 4px;
      padding-left: 8px;
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
    }
  }
`

export const TableEditButton = styled(IconPencil)`
  fill: ${props => props.theme.color.secondary200};
  cursor: pointer;
  margin-right: 14px;
  width: 15px;
  height: 15px;
  transition: fill 0.1s ease-in;

  &:hover {
    fill: ${props => props.theme.color.secondary300};
  }
`

export const TableDeleteButton = styled(IconBin)`
  fill: ${props => props.theme.color.secondary200};
  cursor: pointer;
  transition: fill 0.1s ease-in;

  &:hover {
    fill: ${props => props.theme.color.secondary300};
  }
`

export const StyledDatePicker = styled(DatePicker)`
  width: 144px;
`

export const Sure = styled.div`
  ${Typography.body2Default};
`

export const Label = styled.div`
  ${Typography.body2Default};
  color: ${props => props.theme.color.textSecondary};
  margin-bottom: 4px;

  &:not(:first-child) {
    margin-top: 16px;
  }
`

export const FormWrapper = styled.div`
  & div + svg {
    transform: translateY(-4px);
  }
`

export const SpecificationLabel = styled(Label)`
  display: flex;
  align-items: center;
  height: 32px;
`

export const SpecificationContent = styled.div`
  display: grid;
  grid-template-columns: max(96px) 1fr;
  padding-top: 6px;
`

export const TwoCol = styled.div<{ columns?: boolean }>`
  margin-top: 16px;
  width: ${({ columns }) => (columns ? '400px' : '264px')};
  display: grid;
  grid-template-columns: ${({ columns }) => (columns ? '1fr 1fr 1fr' : '1fr 1fr')};
  grid-column-gap: 24px;
`

export const HeadCell = styled(UISectionTableHeadCell)`
  ${Typography.caption2Default};
  color: ${props => props.theme.color.textSecondary};

  &,
  &&:first-child,
  &&:last-child {
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 12px;
    padding-right: 12px;
  }

  white-space: nowrap;
`
export const BodyCell = styled(UISectionTableBodyCell)`
  &,
  &:first-child,
  &:last-child {
    padding-left: 12px;
    padding-right: 12px;
  }
`

export const ButtonsWrapper = styled.div`
  position: absolute;
  right: 12px;
  top: 0;
  display: none;
  background: #ebf4ff;
  padding: 21px 0 21px 16px;

  & svg {
    width: 18px;
    height: 18px;
  }

  & svg path {
    fill: ${({ theme }) => theme.color.secondary300};
  }

  & svg:hover path {
    fill: ${({ theme }) => theme.color.primary600};
  }
`

export const TableRow = styled(SectionTableBodyRow)`
  position: relative;
  border-top: 1px solid ${props => props.theme.color.secondary50};

  &:hover {
    &,
    & + * {
      background: #ebf4ff;
    }

    ${ButtonsWrapper} {
      display: block;
      z-index: 1;
    }
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 100%;
    height: 100%;
    width: 100%;
  }
`

export const SecondRow = styled(SectionTableBodyRow)`
  & > * {
    ${Typography.body2Bold};
    padding-top: 3px;
  }
`

export const Cols = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 16px;
`

export const ColTitle = styled.div`
  ${Typography.body2Default}
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
  margin-bottom: 4px;
`

export const ColContent = styled.div`
  ${Typography.body2Default}
`

export const UserStyledLink = styled(Link)`
  display: block;
  color: ${({ theme }) => theme.color.primary600};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

interface UserLinkProps {
  id: string
  name: string
}

export const UserLink: React.FC<UserLinkProps> = ({ id, name }) => (
  <UserStyledLink to={generatePath(UserRoutes.Detail, { id: id || '0' })}>{name}</UserStyledLink>
)

export const Delimiter = styled.div`
  height: 0px;
  margin-top: 12px;
  border-bottom: ${({ theme }) => `1px solid ${theme.color.secondary50}`};
`

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

export const Footer = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  column-gap: 16px;
  margin-top: 16px;
`
