import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { useHelmet } from '@agro-club/agroclub-shared'

import { getUserCompanyName } from 'helpers/getUserData'
import useUserName from 'hooks/useUserName'
import { useUserById } from 'modules/domain/user/hooks'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import { useUserEditGoBack } from 'views/pages/User/hooks'
import { UserBidTable } from 'views/pages/User/UserBidPrice/UserBidTable'
import * as Header from 'views/ui/Header/Header'

export const UserBidPrice: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const goBack = useUserEditGoBack(id)
  const { t } = useTranslation()
  const [, user] = useUserById(id)

  const userName = useUserName(user)
  const userCompany = getUserCompanyName(user)

  useHelmet({ title: `${t('user:priceUpdating')} - ${userName}` })

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title compact title={t('user:priceUpdating')} />
          <Header.SubTitle>
            {userName} /{userCompany}
          </Header.SubTitle>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <UserBidTable user={user} />
      </Layout.Content>
    </>
  )
}
