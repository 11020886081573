import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SectionBody, SectionContainer, useAction } from '@agro-club/agroclub-shared'
import { useFormik } from 'formik'
import styled from 'styled-components'

import env from 'env'
import useFormatNumber from 'hooks/useFormatNumber'
import { useSingleEntity } from 'modules/domain/common/useSingleEntity'
import DealActions from 'modules/domain/deal/duck'
import { useCloseReasons, useReasonBySlug } from 'modules/domain/deal/hooks'
import { Deal, Executors } from 'modules/domain/deal/types'
import { endpoints } from 'modules/endpoints'
import { Tax } from 'views/pages/Dashboard/DealItem'
import { DealEditModal } from 'views/pages/Deal/DealEdit/DealEditModal/DealEditModal'
import { DealSpecification } from 'views/pages/Deal/DealEdit/DealSpecification/DealSpecification'
import * as S from 'views/pages/Deal/DealEdit/styled'
import { DealEditableFields } from 'views/pages/Deal/DealEdit/types'
import { Warehouse } from 'views/pages/Deal/DealEdit/Warehouse'
import { EditButton } from 'views/ui/EditButton/EditButton'

import { ExecutorsEditModal } from './ExecutorsEditModal'
import { Taxes } from './Taxes'

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: -12px 0;

  > * {
    margin: 12px 0;
  }
`
const GWMBlock = styled.div`
  background-color: ${props => props.theme.color.primary25};
  border-radius: 8px;
  padding: 16px;
`

// TODO: remove when taxes will be integrated
const TaxLinkBLock = styled.div`
  background-color: #fffbe6;
  border: 1px solid #ffe58f;
  border-radius: 8px;
  margin-top: 8px;
  padding: 8px;
  text-align: center;

  > a {
    color: ${props => props.theme.color.primary700};
    font-weight: 500;
    font-size: 14px;
  }
`

const DealSectionBody = styled(SectionBody)`
  padding: 16px 24px 12px;
`

const Col = styled.div`
  display: inline-block;
  width: 140px;
`

export const DealDetailSection: React.FC<{
  deal: Deal
}> = ({ deal }) => {
  const { t } = useTranslation('deal')
  const currency = env.REACT_APP_CURRENCY_SYMBOL
  const updateDeal = useAction(DealActions.updateRequested)
  const [executorsEditModalIsOpen, setExecutorsIsOpen] = useState(false)
  const [, executors] = useSingleEntity<Executors>(endpoints.executors(deal.id), executorsEditModalIsOpen, false)
  const [isOpen, setIsOpen] = useState(false)
  const formatNumber = useFormatNumber()
  useCloseReasons()

  const formik = useFormik<DealEditableFields>({
    initialValues: {
      deal_type: deal.deal_type,
      quantity: deal.quantity,
      distance: deal.distance,
      price_exw: deal.price_exw,
      price_cpt: deal.price_cpt,
      price_logistics: deal.price_logistics,
      paid_sum: deal.paid_sum,
    },
    enableReinitialize: true,
    onSubmit: () => {
      if (!formik.isValid) {
        return
      }
      updateDeal(deal.id, { ...formik.values })
    },
  })

  const close = useCallback(() => {
    setIsOpen(false)
    formik.resetForm()
  }, [formik])

  const renderKV = (options: {
    title: string
    field?: string
    unit?: string
    customValue?: React.ReactNode | string | number
    isPrice?: boolean
  }) => (
    <S.CardKeyValueGroup
      isPrice={options.isPrice}
      title={options.title}
      value={options.customValue || deal[options?.field || '']}
      unit={options.unit}
    />
  )

  const reason = useReasonBySlug(deal.closed_reason)

  const renderHeaderIconGroup = () => {
    if (deal.closed_at) {
      return null
    }

    return (
      <S.CardHeaderGroup>
        <EditButton
          onClick={() => {
            setIsOpen(true)
          }}
        />
      </S.CardHeaderGroup>
    )
  }

  const renderExecutorsHeaderIconGroup = () => (
    <S.CardHeaderGroup>
      <EditButton
        onClick={() => {
          setExecutorsIsOpen(true)
        }}
      />
    </S.CardHeaderGroup>
  )

  return (
    <SectionContainer noDivider>
      <S.CardHeaderWrapper>
        <S.CardHeaderGroup>
          <S.CardHeaderTitle>{t('executors:title')}</S.CardHeaderTitle>
        </S.CardHeaderGroup>
        <S.CardHeaderGroup>{renderExecutorsHeaderIconGroup()}</S.CardHeaderGroup>
      </S.CardHeaderWrapper>

      <DealSectionBody>
        <S.Cols>
          {['user_coordinator', 'deal_coordinator', 'logistician'].map(key => (
            <div key={key}>
              <S.ColTitle>{t(`executors:${key}`)}</S.ColTitle>
              <S.ColContent>
                {executors?.[key] ? (
                  <S.UserLink
                    id={executors?.[key].id}
                    name={
                      executors?.[key].is_active
                        ? executors?.[key].full_name
                        : `${executors?.[key].full_name}${t('common:deactivated')}`
                    }
                  />
                ) : (
                  <div>{t('executors:not_selected')}</div>
                )}
              </S.ColContent>
            </div>
          ))}
        </S.Cols>
      </DealSectionBody>

      <S.Delimiter />

      <S.CardHeaderWrapper>
        <S.CardHeaderGroup>
          <S.CardHeaderTitle>{t('dealDetail')}</S.CardHeaderTitle>
        </S.CardHeaderGroup>
        <S.CardHeaderGroup>{renderHeaderIconGroup()}</S.CardHeaderGroup>
      </S.CardHeaderWrapper>
      <DealSectionBody>
        <BodyWrapper>
          <S.CardKeyList>
            {!!reason && <S.CardKeyValueGroup title={t('fields.closed_reason')} value={reason} />}
            {renderKV({
              title: t('fields.deal_type'),
              field: 'deal_type',
              customValue: deal.deal_type ? (t(`dealTypes.${deal.deal_type}`) as string) : '',
            })}
            {renderKV({
              title: t('fields.quantityShort'),
              customValue: (
                <>
                  {formatNumber(deal.quantity_in_bags)} {t('common:bags')}
                  &nbsp; ({formatNumber(deal.quantity)} {t('common:ton')})
                </>
              ),
            })}
            {renderKV({
              title: t('fields.distance'),
              field: 'distance',
              unit: t('common:km'),
            })}
            {renderKV({
              title: t('fields.priceExw'),
              customValue: `${currency} ${formatNumber(deal?.price_exw, true)} ${t('common:per_bag')}`,
            })}
            <Tax sup />
            <Tax sup>
              {t('common:tax')} {currency} {formatNumber(deal?.tax_from_exw, true)} {t('common:per_bag')}
            </Tax>
            {renderKV({
              title: t('fields.priceCpt'),
              customValue: `${currency} ${formatNumber(deal?.price_cpt, true)} ${t('common:per_bag')}`,
            })}
            {renderKV({
              title: t('fields.price_logistics'),
              field: 'price_logistics',
              isPrice: true,
              customValue: (
                <>
                  <Col>{t('common:rubKg', { price: formatNumber(deal.price_logistics), currency })}</Col>
                  <Col>
                    {currency} {formatNumber(deal.price_logistics_per_ton)} {t('common:per_ton')}
                  </Col>
                </>
              ),
            })}
            <Tax sup />
            <Tax sup>
              {t('common:tax')} {currency} {formatNumber(deal?.tax_from_logistics_price, true)} {t('common:per_bag')}
            </Tax>
            <S.CardKeyValueGroup
              title={t('fields.price_with_logistics')}
              customValue={
                <>
                  <Col>{t('common:rubKg', { price: formatNumber(deal.price_with_logistics), currency })}</Col>
                  <Col>
                    {currency} {formatNumber(deal.price_with_logistics_per_ton)} {t('common:per_ton')}
                  </Col>
                </>
              }
            />
            <S.CardKeyValueGroup
              title={t('fields.commission')}
              customValue={`${currency} ${formatNumber(deal.commission, true)}`}
            />
            {renderKV({
              title: t('fields.paid_sum'),
              customValue: `${currency} ${formatNumber(deal.paid_sum, true)}`,
            })}
            <S.CardKeyValueGroup
              title={t('fields.delivered_quantity')}
              customValue={`${formatNumber(deal?.delivered_quantity_in_bags)} ${t('bags')}
                (${formatNumber(deal?.delivered_quantity)} ${t('ton')})`}
            />
          </S.CardKeyList>
          <div>
            <GWMBlock>
              <S.CardKeyList>
                <S.CardKeyValueGroup title="GMV" customValue={`${currency} ${formatNumber(deal.gmv, true)}`} />
                <S.CardKeyValueGroup
                  title={t('fields.margin', { currency })}
                  customValue={`${currency} ${formatNumber(deal.margin, true)}`}
                />
                <S.CardKeyValueGroup value={deal.margin_percent} unit="%" />
                <S.CardKeyValueGroup
                  customValue={`${currency} ${formatNumber(deal.margin_per_kg, true)} ${t('common:per_bag')}`}
                />
                <S.CardKeyValueGroup
                  title={t('fields.actual_margin')}
                  customValue={`${currency} ${formatNumber(deal.actual_margin, true)}`}
                />
                <S.CardKeyValueGroup value={deal.actual_margin_percent} unit="%" />
                <S.CardKeyValueGroup
                  customValue={`${currency} ${formatNumber(deal.actual_margin_per_kg, true)} ${t('common:per_bag')}`}
                />
                <S.CardKeyValueGroup
                  title={t('fields.price_logistics')}
                  customValue={`${currency} ${formatNumber(deal.price_logistics_bag_per_km, true)}
                    ${t('common:rubTKm')}`}
                />
              </S.CardKeyList>
            </GWMBlock>

            <TaxLinkBLock>
              <a
                href={
                  `${env.REACT_APP_API_BASE_URL}/taxes/` +
                  `?sale_bid=${deal.sale_bid.id}` +
                  `&purchase_bid=${deal.purchase_bid.id}`
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                Show taxes calculation
              </a>
            </TaxLinkBLock>
          </div>
        </BodyWrapper>
      </DealSectionBody>
      <Taxes dealId={deal.id} />
      <DealSpecification deal={deal} />
      <Warehouse deal={deal} />
      <DealEditModal deal={deal} formik={formik} isOpen={isOpen} close={close} />
      <ExecutorsEditModal
        id={deal.id}
        isOpen={executorsEditModalIsOpen}
        onClose={() => setExecutorsIsOpen(false)}
        executors={executors}
      />
    </SectionContainer>
  )
}
