import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Segment, useAction } from '@agro-club/agroclub-shared'

import DealActions from 'modules/domain/deal/duck'
import DealSelectors from 'modules/domain/deal/selectors'
import { DealCondition } from 'modules/domain/deal/types'

export const OpenClosedSegment: React.FC = () => {
  const filterState = useSelector(DealSelectors.filter)
  const filterUpdated = useAction(DealActions.filterUpdated)

  const onChange = (value: DealCondition) => {
    filterUpdated({ ...filterState, dealCondition: value })
  }

  const { t } = useTranslation('deal')
  const items = useMemo(() => Object.keys(DealCondition).map(v => ({ id: v, title: t(`dealCondition.${v}`) })), [t])
  return <Segment value={filterState.dealCondition} items={items} onChange={onChange} />
}
