import React from 'react'
import { useTranslation } from 'react-i18next'

import { SimpleSelect } from '@agro-club/agroclub-shared'
import { FormikHelpers, FormikState } from 'formik'

import { TaxRegimes } from 'modules/domain/bid/types'

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: FormikState<any> & FormikHelpers<any>
}

export const TaxesRegimeSelect: React.FC<Props> = ({ formik }) => {
  const { t } = useTranslation('bid')
  return (
    <div style={{ width: '252px' }}>
      <SimpleSelect
        invalid={formik.touched.aground_imputs && !!formik.errors.tax_regime}
        onChange={val => formik.setFieldValue('tax_regime', val)}
        label={t('taxes.tax_regime')}
        options={[
          { id: TaxRegimes.ACTUAL_PROFIT, title: t('taxes.actual_profit') },
          { id: TaxRegimes.OTHER, title: t('taxes.other') },
        ]}
        value={formik.values.tax_regime}
      />
    </div>
  )
}
