import React, { useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { Route as ReactRouterRoute, Redirect, RouteProps, Switch } from 'react-router-dom'

import { HelmetContext, Progress, defaultTheme, useAction } from '@agro-club/agroclub-shared'
import { ThemeProvider } from 'styled-components'

import useLanguage from 'hooks/useLanguage'
import { NotificationGlobalStyle } from 'hooks/useNotificationProgress'
import AuthActions from 'modules/domain/auth/duck'
import AuthSelectors from 'modules/domain/auth/selectors'
import { ROLES } from 'types/entities'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'
import AuthPage from 'views/pages/Auth/AuthPage'
import { NotPermitted } from 'views/pages/Auth/NotPermitted'
import AuthRoutes from 'views/pages/Auth/routes'
import BidPage from 'views/pages/Bid/BidPage'
import BidRoutes from 'views/pages/Bid/routes'
import CallsPage from 'views/pages/Calls/CallsPage'
import CallsRoutes from 'views/pages/Calls/routes'
import CallStatisticsPage from 'views/pages/CallStatistics/CallStatisticsPage'
import CallStatisticsRoutes from 'views/pages/CallStatistics/routes'
import CarrierPage from 'views/pages/Carrier/CarrierPage'
import CarrierRoutes from 'views/pages/Carrier/routes'
import CarsPage from 'views/pages/Cars/CarsPage'
import CarsRoutes from 'views/pages/Cars/routes'
import CompanyPage from 'views/pages/Company/CompanyPage'
import CompanyRoutes from 'views/pages/Company/routes'
import DashboardPage from 'views/pages/Dashboard/DashboardPage'
import DashboardRoutes from 'views/pages/Dashboard/routes'
import DealPage from 'views/pages/Deal/DealPage'
import DealRoutes from 'views/pages/Deal/routes'
import { Logistics } from 'views/pages/Logistics/Logistics'
import LogisticsRoutes from 'views/pages/Logistics/routes'
import TaskRoutes from 'views/pages/Task/routes'
import TaskPage from 'views/pages/Task/TaskPage'
import UsersRoutes from 'views/pages/User/routes'
import UserPage from 'views/pages/User/UserPage'
import { TooltipGlobalStyle } from 'views/ui/Tooltip/Tooltip'

const Route: React.FC<RouteProps> = ({ children, ...props }) => {
  const isAuthenticated = useSelector(AuthSelectors.isAuthenticated)
  const role = useSelector(AuthSelectors.role)
  const hasPermission = role === ROLES.admin || role === ROLES.coordinator

  return (
    <ReactRouterRoute {...props}>
      {isAuthenticated ? (
        hasPermission ? (
          children
        ) : (
          <Redirect to={AuthRoutes.NotPermitted} />
        )
      ) : (
        <Redirect to={AuthRoutes.Login} />
      )}
    </ReactRouterRoute>
  )
}

const Wrapper: React.FC = ({ children }) => {
  const { helmet } = useContext(HelmetContext)

  return (
    <ThemeProvider theme={defaultTheme}>
      <TooltipGlobalStyle />
      <NotificationGlobalStyle />
      <Helmet defaultTitle="Agro.Club">
        <title>{helmet.title}</title>
        <meta name="description" content={helmet.description} />
      </Helmet>
      {children}
    </ThemeProvider>
  )
}

const RootComponent: React.FC = () => {
  const initAction = useAction(AuthActions.init)
  const getInitProgress = useSelector(AuthSelectors.getInitProgress)
  const lang = useLanguage()

  useEffect(() => {
    initAction()
  }, [initAction])

  if ([Progress.IDLE, Progress.WORK].includes(getInitProgress)) {
    return (
      <Wrapper>
        <SpinnerLayout />
      </Wrapper>
    )
  }

  return (
    <Wrapper key={lang}>
      <Switch>
        <ReactRouterRoute path={AuthRoutes.Login}>
          <AuthPage />
        </ReactRouterRoute>
        <ReactRouterRoute path={AuthRoutes.NotPermitted}>
          <NotPermitted />
        </ReactRouterRoute>
        <Route path={DashboardRoutes.Dashboard}>
          <DashboardPage />
        </Route>
        <Route path={UsersRoutes.List}>
          <UserPage />
        </Route>
        <Route path={CompanyRoutes.List}>
          <CompanyPage />
        </Route>
        <Route path={BidRoutes.List}>
          <BidPage />
        </Route>
        <Route path={BidRoutes.Potential}>
          <BidPage />
        </Route>
        <Route path={DealRoutes.List}>
          <DealPage />
        </Route>
        <Route path={TaskRoutes.List}>
          <TaskPage />
        </Route>
        <Route path={LogisticsRoutes.List}>
          <Logistics />
        </Route>
        <Route path={CarsRoutes.List}>
          <CarsPage />
        </Route>
        <Route path={CarrierRoutes.List}>
          <CarrierPage />
        </Route>
        <Route path={CallsRoutes.List}>
          <CallsPage />
        </Route>
        <Route path={CallStatisticsRoutes.List}>
          <CallStatisticsPage />
        </Route>
        <Redirect to={CompanyRoutes.List} />
      </Switch>
    </Wrapper>
  )
}

export default RootComponent
