import { useCallback, useEffect, useRef, useState } from 'react'

type Props = {
  initialSeconds: number
}

const useTimer = ({ initialSeconds }: Props) => {
  const [seconds, setSeconds] = useState<number>(initialSeconds)
  const timer = useRef<GTymeout>()

  const stopTimer = useCallback(() => {
    timer.current && clearInterval(timer.current)
    timer.current = undefined
  }, [])

  const startTimer = useCallback(() => {
    if (!timer.current) {
      setSeconds(initialSeconds)
      timer.current = setInterval(
        () =>
          setSeconds(prev => {
            const next = prev - 1
            if (next === 0 && timer.current) {
              stopTimer()
            }
            return next
          }),
        1000,
      )
    }

    return () => {
      stopTimer()
    }
  }, [setSeconds, stopTimer, initialSeconds])

  useEffect(() => {
    startTimer()
  }, [startTimer])

  return { seconds, startTimer }
}
export default useTimer
