import { CountryCodeEntry } from '@agro-club/agroclub-shared'

import env from 'env'
import { AvailableLanguages } from 'types/entities'

const BR = {
  id: 'BR',
  name: 'BR',
  phone_prefix: '55',
}

const CA = {
  id: 'CA',
  name: 'CA',
  phone_prefix: '1',
}

const ES = {
  id: 'ES',
  name: 'ES',
  phone_prefix: '34',
}

const US = {
  id: 'US',
  name: 'US',
  phone_prefix: '1',
}

const RU = {
  id: 'RU',
  name: 'RU',
  phone_prefix: '7',
}

const countryCodes = {
  [AvailableLanguages.ru]: [RU],
  [AvailableLanguages.pt_BR]: [BR, ES, RU, US, CA],
  [AvailableLanguages.en]: [BR, ES, RU, US, CA],
}

export const getCountryCodes = (): CountryCodeEntry[] =>
  countryCodes[env.REACT_APP_LOCALE] || countryCodes[AvailableLanguages.ru]

export const getDefaultCountryCode = () => getCountryCodes()[0].id
