import { Link } from 'react-router-dom'

import { Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import { Phone } from 'views/ui/Phone/Phone'

export const RowHeader = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #111;
  white-space: nowrap;
  margin: 16px 0 8px;
`

export const PhoneLink = styled(Phone)`
  ${Typography.caption1Default}
`

export const ProductTitle = styled.div`
  ${Typography.body2Heavy};
  margin-bottom: 4px;
`

export const Address = styled.div`
  ${Typography.body2Default};
  margin-bottom: 4px;
`

export const Company = styled.div`
  color: ${props => props.theme.color.textSecondary};
  margin-bottom: 2px;
  ${Typography.caption1Default};
`

export const Number = styled.div`
  margin-bottom: 10px;
  text-align: right;
  ${Typography.body2Bold}
`

export const DealLink = styled(Link)`
  color: ${({ theme }) => theme.color.primary600};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  ${Typography.caption1Default};
`

export const LinkWrapper = styled.div`
  z-index: 0;
  position: relative;
`

export const NoWrap = styled.div`
  white-space: nowrap;
`
