import { useEffect, useState } from 'react'

import { Progress } from '@agro-club/agroclub-shared'

import { User } from 'modules/domain/user/types'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'

export const useEmployees = (companyId?: string): [Progress, User[]] => {
  const [users, setUsers] = useState<User[]>([])
  const [progress, setProgress] = useState<Progress>(Progress.IDLE)

  useEffect(() => {
    if (companyId) {
      setProgress(Progress.WORK)
      apiClient
        .get<User[]>(endpoints.employee(companyId))
        .then(data => {
          setUsers(data)
          setProgress(Progress.SUCCESS)
        })
        .catch(() => setProgress(Progress.ERROR))
    } else {
      setUsers([])
      setProgress(Progress.SUCCESS)
    }
  }, [companyId])

  return [progress, users]
}
