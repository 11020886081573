import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { SimpleSelect, useAction } from '@agro-club/agroclub-shared'

import CarsActions from 'modules/domain/cars/duck'
import CarsSelectors from 'modules/domain/cars/selectors'
import { IsCharterType } from 'modules/domain/cars/types'
import * as Styled from 'views/pages/Cars/CarsTable/styled'

export function IsCharterFilter() {
  const { t } = useTranslation('cars')
  const filterUpdated = useAction(CarsActions.filterUpdated)
  const { is_in_charter, ...restFilter } = useSelector(CarsSelectors.filter)
  const options = useMemo(
    () => Object.keys(IsCharterType).map(pType => ({ id: IsCharterType[pType], title: t(`isCharterFilter.${pType}`) })),
    [t],
  )

  const handleChange = (id: string) => {
    filterUpdated({ ...restFilter, is_in_charter: id })
  }

  const handleClear = () => {
    filterUpdated({ ...restFilter, is_in_charter: undefined })
  }

  return (
    <SimpleSelect
      placeholder={t('any')}
      isClearable
      options={options}
      onChange={handleChange}
      customStyles={Styled.customSelectStyles}
      value={is_in_charter}
      onClear={handleClear}
    />
  )
}
