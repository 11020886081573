import React from 'react'

import { useLogisticsDeal } from 'modules/domain/logisticsDeal/hooks'

const CompanyNames: React.FC<{ dealId: string }> = ({ dealId }) => {
  const [, deal] = useLogisticsDeal(dealId)
  if (!deal) return null

  const saleOwner = deal.sale_bid.owner
  const saleCompanyName = saleOwner.profile?.company.name ?? ''
  const purchaseOwner = deal.purchase_bid.owner
  const purchaseCompanyName = purchaseOwner.profile?.company.name ?? ''

  return (
    <>
      {saleCompanyName} / {purchaseCompanyName}
    </>
  )
}

export default CompanyNames
