import { generatePath, matchPath } from 'react-router'
import { call, delay, put, race, select, take } from 'redux-saga/effects'

import AnalyticEvents from 'analytics/events'
import { CollectedRoutes as Routes, routeToEvent } from 'analytics/routeToEvent'
import { LOCATION_CHANGE, LocationChangeAction, getLocation, replace } from 'connected-react-router'

import { inactiveBrowserTabLock } from 'helpers/inactiveBrowserTabLock'
import { stringifyUrl } from 'helpers/stringifyUrl'
import AuthActions from 'modules/domain/auth/duck'
import AuthSelectors from 'modules/domain/auth/selectors'
import { ROLES } from 'types/entities'

import { getAnalyticsInstance } from './sagaEffects'

type Param = string | number | undefined

export function* updateLocationQuery(nextLocation: string, query?: Record<string, Param | Param[]>) {
  const currentLocation = yield select(getLocation)
  const match = matchPath(currentLocation.pathname, { path: nextLocation, exact: true })

  if (match) {
    const result = stringifyUrl({
      url: generatePath(nextLocation, match.params),
      query,
    })
    const oldResult = stringifyUrl({ url: currentLocation.pathname, query: currentLocation.query })
    // prevent unnecessary push
    if (oldResult !== result) {
      yield put(replace(result))
    }
  }
}

export function* locationChangeWatcher() {
  const analyticsInstance = yield call(getAnalyticsInstance)
  const routes = Routes.map(r => r.split('/'))
    .sort((a, z) => z.filter(s => !!s).length - a.filter(s => !!s).length)
    .map(r => r.join('/'))

  while (1) {
    const action: LocationChangeAction<{ pathname: string }> = yield take(LOCATION_CHANGE)
    const match = matchPath(action.payload.location.pathname, routes)

    const props: { [key: string]: unknown } = {
      params: match?.params,
      url: match?.url,
    }

    const eventName = routeToEvent(match?.path as never)

    if (eventName) {
      analyticsInstance.track(AnalyticEvents.Page, { ...props, name: eventName })
    }
  }
}

export function ticker(loopBodyGen: (opts: { role: ROLES }) => Generator<any, boolean, any>, timeoutMs: number) {
  return function* tickerLoop(): unknown {
    yield take(AuthActions.initSucceed.type)
    while (1) {
      yield call(inactiveBrowserTabLock)
      const isAuthenticated: boolean = yield select(AuthSelectors.isAuthenticated)
      const role: ROLES = yield select(AuthSelectors.role)

      if (!isAuthenticated || !role) {
        break
      }

      const shouldWeContinue = yield call(loopBodyGen, { role })

      if (!shouldWeContinue) {
        break
      }

      const { timeout } = yield race({
        timeout: delay(timeoutMs),
        initError: take(AuthActions.initFailed.type),
        logout: take(AuthActions.signOutRequested.type),
      })

      if (!timeout) {
        break
      }
    }
    yield call(tickerLoop)
  }
}
