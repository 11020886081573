import React, { useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { useAction } from '@agro-club/agroclub-shared'

import UserActions from 'modules/domain/user/duck'
import PageWrapper from 'views/components/PageWrapper/PageWrapper'
import Routes from 'views/pages/User/routes'
import { UserBidAdd } from 'views/pages/User/UserBidAdd'
import { UserBidPrice } from 'views/pages/User/UserBidPrice/UserBidPrice'
import { UserAdd } from 'views/pages/User/UserDetail/UserAdd'
import { UserDetail } from 'views/pages/User/UserDetail/UserDetail'
import { UserEdit } from 'views/pages/User/UserDetail/UserEdit'
import UserList from 'views/pages/User/UserList'

function UserPage() {
  const resetFilter = useAction(UserActions.filterResetWithoutRequest)

  useEffect(
    () => () => {
      resetFilter()
    },
    [resetFilter],
  )

  return (
    <PageWrapper>
      <Switch>
        <Route path={Routes.List} exact>
          <UserList />
        </Route>
        <Route path={Routes.Detail} exact>
          <UserDetail />
        </Route>
        <Route path={Routes.Add} exact>
          <UserAdd />
        </Route>
        <Route path={Routes.Edit} exact>
          <UserEdit />
        </Route>
        <Route path={Routes.BidAdd} exact>
          <UserBidAdd />
        </Route>
        <Route path={Routes.BidPrice} exact>
          <UserBidPrice />
        </Route>
        <Redirect to={Routes.List} />
      </Switch>
    </PageWrapper>
  )
}

export default UserPage
