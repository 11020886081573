import { Link } from 'react-router-dom'

import { Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import { box } from 'views/styled/common'

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${box};
  position: relative;
  border: 1px solid ${props => props.theme.color.secondary100};
  padding: 12px;
`

export const StateNumbersLink = styled(Link)`
  ${Typography.headlineBold};
  margin-bottom: 4px;
  color: ${({ theme }) => theme.color.primary500};

  &:hover {
    text-decoration: underline;
  }
`
export const TrailerStateNumber = styled.span`
  ${Typography.headlineDefault};
`

export const CarInfo = styled.div`
  ${Typography.body2Default};
  margin-bottom: 4px;
`

export const NumOfDrivers = styled.div`
  ${Typography.body2Default};
  color: ${({ theme }) => theme.color.textSecondary};
  margin-top: 4px;
`

export const UserLinkWrapper = styled.div`
  ${Typography.body2Default};
`

export const CharterLabel = styled.span`
  color: #939eaa;
  display: inline-flex;
  align-items: center;
  & > svg {
    fill: currentColor;
    margin-left: 5px;
    width: 14.67px;
    height: 14.67px;
  }
  ${Typography.caption1Default};
`

export const CarAddButton = styled.div`
  width: 32px;
  height: 32px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 8px;
  border: 0;
  padding: 0;
  transition: 0.1s background-color ease-out, 0.1s fill ease-out;
  cursor: pointer;
  background: ${props => props.theme.color.primary50};

  svg {
    width: 20px;
    height: 20px;
    fill: ${props => props.theme.color.primary600};
  }

  &:hover {
    background: ${props => props.theme.color.primary100};
  }
`

export const TripCounter = styled.div`
  display: flex;
  align-items: center;
  ${Typography.body2Default}
`

export const TripCounterValue = styled.div`
  margin: 0 8px 0 0;
  min-width: 30px;
  text-align: right;
  ${Typography.body2Bold}
`

export const BottomBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
`

export const ListWrapper = styled.div`
  display: grid;
  grid-template-columns: 288px 288px;
  grid-gap: 12px;
  margin-top: 16px;
`

export const GroupTitle = styled.div`
  ${Typography.footnoteCAPS};
  display: flex;
  color: ${props => props.theme.color.textSecondary};
`

export const Counter = styled.div`
  background: ${props => props.theme.color.secondary25};
  width: 20px;
  height: 20px;
  border-radius: 10px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  margin-left: 10px;
`

export const CarListWrapper = styled.div`
  display: grid;
  grid-gap: 24px;
  padding-top: 16px;
`

export const ArchivedContent = styled.div`
  color: ${({ theme }) => theme.color.textSecondary};
`

export const ArchivedTitle = styled.div`
  ${Typography.headlineBold};
`

export const ArchivedOwner = styled.div`
  ${Typography.body2Default};
  margin-bottom: 4px;
`

export const ArchivedBottomBlock = styled(BottomBlock)`
  margin-top: 14px;
`

export const ArchivedDrivers = styled.div`
  ${Typography.body2Default};
`
