import React from 'react'
import { useTranslation } from 'react-i18next'

import { ButtonFullProps } from '@agro-club/agroclub-shared'

import { CardEditIcon, IconContainer } from 'views/ui/EditButton/styled'

type Props = Omit<ButtonFullProps, 'intent'>

export const EditButton: React.FC<Props> = ({ onClick, ...other }) => {
  const { t } = useTranslation('common')
  return (
    <IconContainer title={t('edit')} intent="minor-action" onClick={onClick} {...other}>
      <CardEditIcon />
    </IconContainer>
  )
}
