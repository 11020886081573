import { DashboardFilter } from 'modules/domain/dashboard/types'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { ListResponse } from 'types/api.d'

import { Task, TaskDTO, TaskListRequestFilter, TaskListRequestSorting } from './types'

export const getList = (
  filter: TaskListRequestFilter,
  sorting: TaskListRequestSorting,
  page: number,
  pageSize: number,
) =>
  apiClient.get<ListResponse<Task>>(endpoints.task(), {
    ...filter,
    ...sorting,
    page,
    page_size: pageSize,
  })

export const getItem = (id: string) => apiClient.get<Task>(endpoints.task(id))

export const addItem = (dto: TaskDTO) => apiClient.post<Task>(endpoints.task(), dto)

export const updateItem = (id: string, dto: Partial<TaskDTO>) => apiClient.put<Task>(endpoints.task(id), dto)

export const removeItem = (id: string) => apiClient.delete<Task>(endpoints.task(id))

export const fetchMyTasks = () => apiClient.get<ListResponse<Task>>(endpoints.myTasks(), { page_size: 10000 })

export const completeTask = (taskId: string) => apiClient.post<ListResponse<Task>>(endpoints.completeTask(taskId))

export const getUserTasks = (userId: string) => apiClient.get<Task[]>(endpoints.userTasks(userId))

export const getCallBackItems = ({
  page,
  pageSize,
  filter,
}: {
  page: number
  pageSize: number
  filter: DashboardFilter
}) =>
  apiClient.get<ListResponse<Task>>(endpoints.dashboardCalls(), {
    ...filter,
    page,
    page_size: pageSize,
  })
