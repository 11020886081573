import React, { VFC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { generatePath, useHistory } from 'react-router-dom'
import { CellProps, useTable } from 'react-table'

import {
  AdvancedHeadCell,
  Progress,
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHead,
  TableHeadRow,
  TableNoData,
  useAction,
  usePersistentScroll,
} from '@agro-club/agroclub-shared'

import CompanyActions from 'modules/domain/company/duck'
import { useCompanyList, useFilterAndSortQuery } from 'modules/domain/company/hooks'
import CompanySelectors from 'modules/domain/company/selectors'
import { Company } from 'modules/domain/company/types'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import { RangeFilter } from 'views/components/TableFilters/RangeFilter'
import { ResetFilterButton } from 'views/components/TableFilters/ResetFilterButton'
import CompanyRoutes from 'views/pages/Company/routes'
import { DangerousHtml } from 'views/ui/DangerousHtml/DangerousHtml'

const CompanySortableHeadCell = AdvancedHeadCell<keyof Company>()

const AgreementTypeCell: VFC<CellProps<Company>> = ({ row }) => {
  const { t } = useTranslation('company')
  return (
    <>
      {row.original.cooperation_type_agent && <div>{`${t('form.fields.cooperation_type_agent')}`}</div>}
      {row.original.cooperation_type_trader && <div>{`${t('form.fields.cooperation_type_trader')}`}</div>}
    </>
  )
}

const CompanyTable: React.FC = () => {
  const history = useHistory()

  const { t } = useTranslation('company')
  const [progress, data] = useCompanyList()
  const sortingUpdated = useAction(CompanyActions.sortingUpdated)
  const listRequested = useAction(CompanyActions.listRequested)
  const filterUpdated = useAction(CompanyActions.filterUpdated)
  const filterState = useSelector(CompanySelectors.filter)
  const total = useSelector(CompanySelectors.total)
  const pages = useSelector(CompanySelectors.pages)
  const page = useSelector(CompanySelectors.page)
  const pageSize = useSelector(CompanySelectors.pageSize)
  const { sort_field, sort_reversed } = useSelector(CompanySelectors.sorting)

  const { filter } = useFilterAndSortQuery()
  const start = filter.created_after || filterState.created_after
  const end = filter.created_before || filterState.created_before

  const visibleColumns = React.useMemo(
    () =>
      [
        {
          Header: t('list.tableHeaders.name'),
          accessor: 'name' as const,
          sortable: true,
        },
        {
          Header: t('list.tableHeaders.inn'),
          accessor: 'inn' as const,
        },
        {
          Header: t('form.fields.state_reg_number'),
          accessor: 'state_reg_number' as const,
        },
        {
          Header: <DangerousHtml html={t('list.tableHeaders.payment_delay_days')} />,
          accessor: 'payment_delay_days' as const,
        },
        {
          Header: t('list.tableHeaders.agreementType'),
          accessor: 'cooperation_type_agent' as const,
          Cell: AgreementTypeCell,
        },
        {
          Header: t('list.tableHeaders.created'),
          accessor: 'created' as const,
          sortable: true,
          Cell: TComponents.DateCell,
        },
      ].filter(Boolean),
    [t],
  )

  const hiddenColumns: (keyof Company)[] = useMemo(() => ['id', 'cooperation_type_trader'], [])

  const columnsAll = useMemo(
    () => [
      ...visibleColumns,
      ...hiddenColumns.map(col => ({
        Header: col,
        accessor: col,
        hidden: true,
      })),
    ],
    [hiddenColumns, visibleColumns],
  )

  const { columns, rows, prepareRow } = useTable<Company>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore weird issue with react-table typings — having more then 26 fields in type causes TS error
    columns: columnsAll,
    data: data as Company[],
    initialState: { hiddenColumns },
  })

  const setPage = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  const { scrollRef } = usePersistentScroll('companyList')

  const onChangeRange = React.useCallback(
    ([start, end]) => {
      filterUpdated({ ...filterState, created_after: start, created_before: end })
    },
    [filterState, filterUpdated],
  )

  const handleClearFilters = useCallback(() => {
    filterUpdated({})
  }, [filterUpdated])

  const isFilterApplied = Object.values(filterState).some(Boolean)

  return (
    <TComponents.Wrapper>
      <TComponents.Filters>
        <RangeFilter label={t('list.tableHeaders.created')} onChange={onChangeRange} start={start} end={end} />
        <ResetFilterButton {...{ handleClearFilters, isFilterApplied }} />
      </TComponents.Filters>
      <Table total={total} pages={pages} pageSize={pageSize} currentPage={page} onSetPage={setPage} ref={scrollRef}>
        <TableHead>
          <TableHeadRow>
            {columns.map(column => (
              <CompanySortableHeadCell
                zIndex={1}
                key={column.getHeaderProps().key}
                id={column.id as keyof Company}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                sortable={column.sortable}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                hidden={column.hidden}
                sortField={sort_field}
                sortDesc={sort_reversed}
                onChange={sortingUpdated}
              >
                {column.render('Header')}
              </CompanySortableHeadCell>
            ))}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map(row => {
            prepareRow(row)
            const { key, ...props } = row.getRowProps()
            return (
              <TableBodyRow
                key={key}
                {...props}
                onClick={() => {
                  history.push(generatePath(CompanyRoutes.Detail, { id: row.original.id.toString() }))
                }}
              >
                {row.cells.map(cell => {
                  const { key, ...props } = cell.getCellProps()
                  return (
                    <TableBodyCell key={key} {...props}>
                      {cell.render('Cell')}
                    </TableBodyCell>
                  )
                })}
              </TableBodyRow>
            )
          })}
          <TableNoData
            progress={progress}
            isEmpty={!rows.length}
            colSpan={visibleColumns.length}
            loading={<TComponents.Spinner />}
          >
            <div>{isFilterApplied ? t('list.emptyFilterMsg') : t('list.emptyMsg')}</div>
            {isFilterApplied && progress !== Progress.WORK && (
              <TComponents.ClearButton intent="cancel" size="small" onClick={handleClearFilters}>
                {t('common:resetAllFilters')}
              </TComponents.ClearButton>
            )}
          </TableNoData>
        </TableBody>
      </Table>
    </TComponents.Wrapper>
  )
}

export default CompanyTable
