import React, { useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { useAction } from '@agro-club/agroclub-shared'

import CompanyActions from 'modules/domain/company/duck'
import PageWrapper from 'views/components/PageWrapper/PageWrapper'
import { CompanyAdd } from 'views/pages/Company/CompanyDetail/CompanyAdd'
import { CompanyDetail } from 'views/pages/Company/CompanyDetail/CompanyDetail'
import { CompanyEdit } from 'views/pages/Company/CompanyDetail/CompanyEdit'

import CompanyList from './CompanyList'
import Routes from './routes'

function CompanyPage() {
  const filterReset = useAction(CompanyActions.filterResetWithoutRequest)

  useEffect(
    () => () => {
      filterReset()
    },
    [filterReset],
  )

  return (
    <PageWrapper>
      <Switch>
        <Route path={Routes.List} exact>
          <CompanyList />
        </Route>
        <Route path={Routes.Detail} exact>
          <CompanyDetail />
        </Route>
        <Route path={Routes.Edit} exact>
          <CompanyEdit />
        </Route>
        <Route path={Routes.Add} exact>
          <CompanyAdd />
        </Route>
        <Redirect to={Routes.List} />
      </Switch>
    </PageWrapper>
  )
}

export default CompanyPage
