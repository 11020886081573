import { Progress } from '@agro-club/agroclub-shared'
import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer'

import { DriverDTO, DriverState } from 'modules/domain/driver/types'
import { Driver, FetchError } from 'modules/domain/types'
import { arrToDict, getIds } from 'modules/utils/helpers'

const initialState: DriverState = {
  items: {},
  driversByCar: {},

  listFetchProgress: Progress.IDLE,
  listFetchError: null,
  addProgress: Progress.IDLE,
  addError: null,
  updateProgress: Progress.IDLE,
  updateError: null,
  detachProgress: Progress.IDLE,
  detachError: null,
}

class DriverReducer extends ImmerReducer<DriverState> {
  listRequested(_carId: string) {
    this.draftState.listFetchProgress = Progress.WORK
    this.draftState.listFetchError = null
  }

  listRequestSucceed(carId: string, drivers: Driver[]) {
    this.draftState.items = { ...this.draftState.items, ...arrToDict(drivers) }
    this.draftState.driversByCar[carId] = getIds(drivers)
    this.draftState.listFetchProgress = Progress.SUCCESS
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  listRequestFailed(carId: string, error: FetchError, errorDetail?: string) {
    this.draftState.listFetchProgress = Progress.ERROR
    this.draftState.listFetchError = error
    this.draftState.listFetchErrorDetail = errorDetail
  }

  addDriverRequested(_carId: string, _driver: DriverDTO) {
    this.draftState.addProgress = Progress.WORK
    this.draftState.addError = null
    this.draftState.updateErrorDetail = undefined
    this.draftState.updateErrorFields = undefined
  }

  addDriverSuccess(carId: string, driver: Driver) {
    this.draftState.items[driver.id] = driver
    if (!this.draftState.driversByCar[carId].includes(driver.id)) {
      this.draftState.driversByCar[carId] = [...this.draftState.driversByCar[carId], driver.id]
    }
    this.draftState.addProgress = Progress.SUCCESS
    this.draftState.addError = null
    this.draftState.addErrorDetail = undefined
    this.draftState.addErrorFields = undefined
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  addDriverFailed(carId: string, error: FetchError, errorDetail?: string, errorFields?: Record<string, string[]>) {
    this.draftState.addProgress = Progress.ERROR
    this.draftState.addError = error
    this.draftState.addErrorDetail = errorDetail

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.draftState.addErrorFields = errorFields
  }

  updateDriverRequested(_carId: string, _driverId: string, _driver: DriverDTO) {
    this.draftState.updateProgress = Progress.WORK
    this.draftState.updateError = null
    this.draftState.updateErrorDetail = undefined
    this.draftState.updateErrorFields = undefined
  }

  updateDriverSuccess(driverId, driver: Driver) {
    this.draftState.items[driver.id] = driver
    this.draftState.updateProgress = Progress.SUCCESS
    this.draftState.updateError = null
    this.draftState.updateErrorDetail = undefined
    this.draftState.updateErrorFields = undefined
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  updateDriverFailed(carId: string, error: FetchError, errorDetail?: string, errorFields?: Record<string, string[]>) {
    this.draftState.updateProgress = Progress.ERROR
    this.draftState.updateError = error
    this.draftState.updateErrorDetail = errorDetail

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.draftState.updateErrorFields = errorFields
  }

  detachDriverRequest(_carId: string, _driverId: string) {
    this.draftState.detachProgress = Progress.WORK
    this.draftState.detachError = null
  }

  detachDriverSuccess(carId: string, driverId: string) {
    this.draftState.driversByCar[carId] = this.draftState.driversByCar[carId].filter(id => id !== driverId)
    this.draftState.detachProgress = Progress.SUCCESS
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  detachDriverFailed(carId: string, error: FetchError, errorDetail?: string) {
    this.draftState.detachProgress = Progress.ERROR
    this.draftState.detachError = error
    this.draftState.detachErrorDetail = errorDetail
  }

  resetErrors() {
    this.draftState.addProgress = Progress.IDLE
    this.draftState.updateProgress = Progress.IDLE
    this.draftState.addErrorFields = undefined
    this.draftState.updateErrorFields = undefined
  }
}

export const DriverActions = createActionCreators(DriverReducer)
export default DriverActions
export const reducer = createReducerFunction(DriverReducer, initialState)
