import { all, call, put, takeLatest } from 'redux-saga/effects'

import { RequestError } from 'modules/errors'
import { ListResponse } from 'types/api.d'

import TripActions from './duck'
import * as managers from './managers'
import { Trip } from './types'

export const fetchList = function* ({ payload: dealId }: ReturnType<typeof TripActions.listRequested>) {
  try {
    const response: ListResponse<Trip> = yield call(managers.getList, dealId)
    yield put(TripActions.listRequestSucceed(dealId, response.results))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(TripActions.listRequestFailed(type, detail))
  }
}

export const fetchItem = function* ({ payload: id }: ReturnType<typeof TripActions.itemRequested>) {
  try {
    const item: Trip = yield call(managers.getItem, id)
    yield put(TripActions.itemRequestSucceed(item))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(TripActions.itemRequestFailed(id, type, detail))
  }
}

export const addItem = function* ({ payload: dto }: ReturnType<typeof TripActions.addRequested>) {
  try {
    const { id } = yield call(managers.addItem, dto)
    const item: Trip = yield call(managers.getItem, id)
    yield put(TripActions.addSucceed(item))
  } catch (err) {
    const { type, detail, errors } = RequestError.parseError(err)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    yield put(TripActions.addFailed(type, detail, errors))
  }
}
export const updateItem = function* ({ payload: [id, dto] }: ReturnType<typeof TripActions.updateRequested>) {
  try {
    yield call(managers.updateItem, id, dto)
    const item: Trip = yield call(managers.getItem, id)
    yield put(TripActions.updateSucceed(item))
  } catch (err) {
    const { type, detail, errors } = RequestError.parseError(err)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    yield put(TripActions.updateFailed(id, type, detail, errors))
  }
}

export const removeItem = function* ({ payload }: ReturnType<typeof TripActions.removeRequested>) {
  try {
    yield call(managers.removeItem, payload)
    yield put(TripActions.removeSucceed(payload))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(TripActions.removeFailed(payload, type, detail))
  }
}

export const getDocumentTypes = function* ({ payload: id }: ReturnType<typeof TripActions.getDocumentTypesRequested>) {
  try {
    const response = yield call(managers.getDocumentTypes, id)
    yield put(TripActions.getDocumentTypesSucceed(response))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(TripActions.getDocumentTypesFailed(errType))
  }
}

export const uploadDocument = function* ({
  payload: [id, document_type, file],
}: ReturnType<typeof TripActions.uploadDocumentRequested>) {
  try {
    yield call(managers.uploadFile, id, document_type, file)
    yield put(TripActions.uploadDocumentSucccess())
  } catch (err) {
    yield put(TripActions.uploadDocumentFailed())
  }
}

export const getDocumentFiles = function* ({ payload: id }: ReturnType<typeof TripActions.getDocumentFilesRequested>) {
  try {
    const response = yield call(managers.getDocumentFiles, id)
    yield put(TripActions.getDocumentFilesSucceed(response))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(TripActions.getDocumentFilesFailed(errType))
  }
}

export const deleteDocument = function* ({
  payload: [id, document_id],
}: ReturnType<typeof TripActions.deleteDocumentRequested>) {
  try {
    yield call(managers.deleteDocumentFile, id, document_id)
    yield put(TripActions.deleteDocumentSucccess())
  } catch (err) {
    yield put(TripActions.deleteDocumentFailed())
  }
}

const TripSaga = function* () {
  yield all([
    takeLatest(TripActions.itemRequested.type, fetchItem),
    takeLatest(TripActions.listRequested.type, fetchList),
    takeLatest(TripActions.addRequested.type, addItem),
    takeLatest(TripActions.updateRequested.type, updateItem),
    takeLatest(TripActions.removeRequested.type, removeItem),
    takeLatest(TripActions.getDocumentTypesRequested.type, getDocumentTypes),
    takeLatest(TripActions.uploadDocumentRequested.type, uploadDocument),
    takeLatest(TripActions.getDocumentFilesRequested.type, getDocumentFiles),
    takeLatest(TripActions.deleteDocumentRequested.type, deleteDocument),
  ])
}

export default TripSaga
