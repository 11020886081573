import React, { VFC } from 'react'
import { useTranslation } from 'react-i18next'

import { IconCheckmark } from '@agro-club/agroclub-shared'

import formatPhone from 'helpers/formatPhone'
import useFormatNumber from 'hooks/useFormatNumber'
import { Car, CarOwner } from 'modules/domain/car/types'
import {
  ArchivedBottomBlock,
  ArchivedContent,
  ArchivedDrivers,
  ArchivedOwner,
  ArchivedTitle,
  CarInfo,
  CardWrapper,
  CharterLabel,
  TrailerStateNumber,
  TripCounter,
  TripCounterValue,
} from 'views/pages/Logistics/LogisticsRightPanel/LogisticsCarsPanel/styled'

type Props = {
  car: Car
  tripCounter?: number
}

export const ArchivedCarItem: VFC<Props> = ({ car, tripCounter }) => {
  const { t } = useTranslation('vehicles')
  const formatNumber = useFormatNumber()
  const {
    licence_number,
    car_type,
    is_dump_truck,
    drivers_count,
    is_in_charter,
    unloading_type,
    side_height,
    owner,
    default_trailer,
  } = car
  return (
    <CardWrapper>
      <ArchivedContent>
        <ArchivedTitle>
          {licence_number}
          {default_trailer?.licence_number && (
            <TrailerStateNumber>
              {' / '}
              {default_trailer?.licence_number}
            </TrailerStateNumber>
          )}
        </ArchivedTitle>
        <CarInfo>
          {!!car_type && t(`vehicleTypes.${car_type}`)}
          {is_dump_truck && `, ${t('is_dump_truck')}`}
          <br />
          {unloading_type ? `${t(`unloadingType.${unloading_type}`)}, ` : ''}
          {side_height ? formatNumber(side_height) : '?'} {t('side_height_units')}
        </CarInfo>

        <ArchivedOwner>
          {(owner as CarOwner).full_name}
          <br />
          {formatPhone(owner.phone)}
        </ArchivedOwner>

        <ArchivedDrivers>
          {t('driversCount')}: {drivers_count}
        </ArchivedDrivers>

        <ArchivedBottomBlock>
          <TripCounter>
            {t('trips:tripCounterLabel')} <TripCounterValue>{tripCounter}</TripCounterValue>
          </TripCounter>

          {is_in_charter && (
            <CharterLabel>
              {t('charter')} <IconCheckmark />
            </CharterLabel>
          )}
        </ArchivedBottomBlock>
      </ArchivedContent>
    </CardWrapper>
  )
}
