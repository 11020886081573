import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Progress, useAction, useHelmet } from '@agro-club/agroclub-shared'

import usePrevious from 'hooks/usePrevious'
import UserActions from 'modules/domain/user/duck'
import { useUser } from 'modules/domain/user/hooks'
import UserSelectors from 'modules/domain/user/selectors'
import { UserForm } from 'views/components/UserForm/UserForm'
import Item404 from 'views/layouts/Item404/Item404'
import ItemGenericError from 'views/layouts/ItemGenericError/ItemGenericError'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import { useUserEditGoBack } from 'views/pages/User/hooks'
import * as Header from 'views/ui/Header/Header'

export function UserEdit() {
  const { t } = useTranslation(['user', 'common'])
  const { id } = useParams<{ id: string }>()
  const progress = useSelector(UserSelectors.updateProgress)
  const editAction = useAction(UserActions.updateRequested)
  const [fetchProgress, user] = useUser(id)
  const meta = useSelector(state => UserSelectors.meta(state, id))
  const goBack = useUserEditGoBack(id)
  const loading = () => <ItemLoadingLayout id={id} onBack={goBack} />
  const error404 = () => <Item404 id={id} onBack={goBack} title={t('errors.notFoundTitle')} />
  const errorUnknown = () => <ItemGenericError id={id} onBack={goBack} title={t('errors.unknownErrorTitle')} />

  useHelmet({ title: t('form.edit') })

  const prevProgress = usePrevious(progress)

  useEffect(() => {
    if (progress === Progress.SUCCESS && prevProgress === Progress.WORK) {
      goBack()
    }
  }, [progress, id, prevProgress, goBack])

  if (fetchProgress === Progress.ERROR) {
    if (meta.fetchError === 'not_found') {
      return error404()
    }
    return errorUnknown()
  }

  if (fetchProgress === Progress.WORK || !user) {
    return loading()
  }

  return (
    <>
      <Layout.Header hasBottomBorder>
        <Header.Root>
          <Header.Title title={t('common:editing')} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <UserForm
          goBack={goBack}
          initialValues={user}
          mode="edit"
          onSubmit={item => editAction(id, item)}
          progress={progress}
        />
      </Layout.Content>
    </>
  )
}
