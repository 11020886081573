import React, { VFC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, generatePath } from 'react-router-dom'
import { CellProps } from 'react-table'

import { Button, IconArrowRight } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import { CarStatuses } from 'modules/domain/car/types'
import { Cars } from 'modules/domain/cars/types'
import { NumberCell } from 'views/components/CommonTableComponents/CommonTableComponents'
import { LinkWrapper, OwnerWrapper, StatusWrapper } from 'views/pages/Cars/CarsTable/styled'
import LogisticsRoutes from 'views/pages/Logistics/routes'
import { Phone } from 'views/ui/Phone/Phone'
import { UserLink } from 'views/ui/UserLink/UserLink'

function LicenceNumberCell({ row, value }: CellProps<Cars>) {
  return (
    <>
      <div>{row.original.brand_model ? row.original.brand_model : ''}</div>
      <div>{value}</div>
    </>
  )
}

export const TrailerCell: VFC<CellProps<Cars>> = ({ row }) => {
  const { default_trailer } = row.original
  return (
    <>
      <div>{default_trailer?.brand_model || '-'}</div>
      <div>{default_trailer?.licence_number || ''}</div>
    </>
  )
}

function OwnerCell({ value }: CellProps<Cars>) {
  return (
    <OwnerWrapper>
      <LinkWrapper>
        <UserLink user={value} />
      </LinkWrapper>
      <LinkWrapper>
        <Phone phone={value.phone} />
      </LinkWrapper>
    </OwnerWrapper>
  )
}

const StatusCell: VFC<CellProps<Cars>> = ({ value }) => {
  const { t } = useTranslation('vehicles')
  return <StatusWrapper $isArchived={value === CarStatuses.archived}>{t(`statuses.${value}`)}</StatusWrapper>
}

const AddButton = styled(Button)`
  white-space: nowrap;

  svg {
    height: 14px;
    margin-left: 12px;
    fill: #fff;
  }
`

const useColumns = (props: { showTripsCount?: boolean; dealIdForAddToTrip?: string }) => {
  const { t } = useTranslation('cars')
  return React.useMemo(
    () =>
      [
        {
          Header: t('list.tableHeaders.licence_number'),
          accessor: 'licence_number' as const,
          Cell: LicenceNumberCell,
        },
        {
          Header: t('list.tableHeaders.default_trailer'),
          accessor: 'default_trailer' as const,
          Cell: TrailerCell,
        },
        {
          Header: t('common:status'),
          accessor: 'status' as const,
          Cell: StatusCell,
        },
        {
          Header: t('list.tableHeaders.drivers_count'),
          accessor: 'drivers_count' as const,
          Cell: NumberCell,
          headerHorizontalAlign: 'right',
        },
        {
          Header: t('list.tableHeaders.owner'),
          accessor: 'owner' as const,
          Cell: OwnerCell,
        },
        {
          Header: t('list.tableHeaders.car_type'),
          accessor: 'car_type' as const,
          Cell: ({ value }: CellProps<Cars>) => (value ? t(`vehicles:vehicleTypes.${value}`) : ''),
        },
        {
          Header: t('list.tableHeaders.unloading_type'),
          accessor: 'unloading_type' as const,
          Cell: ({ value }: CellProps<Cars>) => (value ? t(`vehicles:unloadingType.${value}`) : ''),
        },
        {
          Header: t('list.tableHeaders.side_height'),
          accessor: 'side_height' as const,
          Cell: NumberCell,
          headerHorizontalAlign: 'right',
        },
        {
          Header: t('list.tableHeaders.is_dump_truck'),
          accessor: 'is_dump_truck' as const,
          Cell: ({ value }: CellProps<Cars>) => (value ? t('common:yes') : t('common:no')),
        },
        {
          Header: t('list.tableHeaders.is_in_charter'),
          accessor: 'is_in_charter' as const,
          Cell: ({ value }: CellProps<Cars>) => (value ? t('common:yes') : t('common:no')),
        },
        {
          id: 'tripsCount',
          Header: t('list.tableHeaders.trips_count'),
          accessor: 'trips_count' as const,
          Cell: NumberCell,
          headerHorizontalAlign: 'right',
        },
        {
          id: 'addButton',
          Header: t('list.tableHeaders.action'),
          accessor: 'id' as const,
          Cell: ({ value }: CellProps<Cars>) => (
            <Link to={generatePath(LogisticsRoutes.TripAdd, { dealId: props.dealIdForAddToTrip ?? '', carId: value })}>
              <AddButton filled intent="primary" size="small">
                {t('list.addCarButton')}
                <IconArrowRight />
              </AddButton>
            </Link>
          ),
        },
      ].filter(
        (
          { id }, // TODO
        ) => !((id === 'trips_count' && !props.showTripsCount) || (id === 'addButton' && !props.dealIdForAddToTrip)),
      ),
    [t, props.showTripsCount, props.dealIdForAddToTrip],
  )
}

export default useColumns
