// eslint-disable-next-line max-classes-per-file
import { AxiosError } from 'axios'
import HttpStatuses from 'http-status-codes'

import flatErrorObject from 'helpers/flatErrorObject'

export type NetworkErrorType = 'bad_request' | 'not_found' | 'unauthorized_error' | 'forbidden_error' | 'client_error'

export abstract class RequestError extends Error {
  static parseError(error: unknown) {
    if (error instanceof RequestError) {
      return {
        type: error.type,
        detail: error.detail,
        errors: error.errors,
      } as const
    }
    return {
      type: 'unknown',
    } as const
  }

  method?: string

  url?: string

  code?: number

  params?: any

  detail?: string

  errors: Record<string, string[]> = {}

  public abstract readonly type: NetworkErrorType

  protected constructor(e: AxiosError) {
    super(e.name)

    if (e.response?.status === HttpStatuses.BAD_REQUEST && e.response?.data) {
      this.errors = flatErrorObject(e.response.data)
    }
    const detail = e.response?.data?.detail
    this.detail = typeof detail === 'string' ? detail : e.response?.data.message

    this.url = e.config.url
    this.code = e.response?.status
    this.method = e.config.method
    this.params = e.config.params
    Object.setPrototypeOf(this, new.target.prototype)
  }
}

export class BadRequestError extends RequestError {
  readonly type = 'bad_request'

  constructor(e: AxiosError) {
    super(e)
    Object.setPrototypeOf(this, new.target.prototype)
  }
}

export class ClientError extends RequestError {
  readonly type = 'client_error'

  constructor(e: AxiosError) {
    super(e)
    Object.setPrototypeOf(this, new.target.prototype)
  }
}

export class NotFoundError extends RequestError {
  readonly type = 'not_found'

  constructor(e: AxiosError) {
    super(e)
    Object.setPrototypeOf(this, new.target.prototype)
  }
}

export class UnauthorizedError extends RequestError {
  readonly type = 'unauthorized_error'

  constructor(e: AxiosError) {
    super(e)
    Object.setPrototypeOf(this, new.target.prototype)
  }
}

export class ForbiddenError extends RequestError {
  readonly type = 'forbidden_error'

  constructor(e: AxiosError) {
    super(e)
    Object.setPrototypeOf(this, new.target.prototype)
  }
}
