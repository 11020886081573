import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, SimpleSelect } from '@agro-club/agroclub-shared'
import { FormikContextType, useFormik } from 'formik'

import { useNotificationProgress } from 'hooks/useNotificationProgress'
import { Executors } from 'modules/domain/deal/types'
import { Footer, FormWrapper, Label } from 'views/pages/Deal/DealEdit/styled'
import { useExecutorsByType } from 'views/pages/Deal/DealEdit/useExecutorsByType'
import { useExecutorsEdit } from 'views/pages/Deal/DealEdit/useExecutorsEdit'
import { CancelButton } from 'views/styled/common'
import { ModalCommon } from 'views/ui/ModalCommon/ModalCommon'

type ExecutorsForm = {
  user_coordinator: string | number | null
  deal_coordinator: string | number | null
  logistician?: string | number | null
}

interface ExecutorItemProps {
  type: string
  value: string
  formik: FormikContextType<ExecutorsForm>
}

export const ExecutorItem: React.FC<ExecutorItemProps> = ({ type, value, formik }) => {
  const { t } = useTranslation('executors')
  const [progress, executors] = useExecutorsByType(type)

  const executorsOptions = useMemo(
    () =>
      executors?.map(item => ({
        id: item.id,
        title: item.full_name,
      })) || [],
    [executors],
  )

  return (
    <>
      <Label>{t(`executors:${value}`)}</Label>
      <SimpleSelect
        placeholder={t('executors:not_selected')}
        required
        isClearable
        progress={progress}
        options={executorsOptions}
        onChange={v => {
          formik.setFieldValue(value, v)
        }}
        value={formik.values[value]}
        invalid={formik.touched[value] && !!formik.errors[value]}
        errorText={formik.errors[value]}
        onClear={() => {
          formik.setFieldValue(value, null)
        }}
      />
    </>
  )
}

interface Props {
  id: string
  isOpen: boolean
  onClose: () => void
  executors?: Executors
}

export const ExecutorsEditModal: React.FC<Props> = ({ id, isOpen, onClose, executors }) => {
  const { t } = useTranslation('executors')
  const [updateProgress, updateExecutors] = useExecutorsEdit()

  const formItems = [
    { type: 'user-coordinators', value: 'user_coordinator' },
    { type: 'deal-coordinators', value: 'deal_coordinator' },
    { type: 'logisticians', value: 'logistician' },
  ]

  const formik = useFormik<ExecutorsForm>({
    initialValues: {
      user_coordinator: executors?.user_coordinator?.id || '',
      deal_coordinator: executors?.deal_coordinator?.id || '',
      logistician: executors?.logistician?.id || '',
    },
    enableReinitialize: true,
    validateOnBlur: false,
    onSubmit: () => {
      if (!formik.isValid) {
        return
      }
      updateExecutors(id, {
        ...{
          user_coordinator: null,
          deal_coordinator: null,
          logistician: null,
        },
        ...formik.values,
      }).then(() => {
        formik.resetForm()
        onClose()
      })
    },
  })

  const onCancel = useCallback(() => {
    formik.resetForm()
    onClose()
  }, [formik, onClose])

  useNotificationProgress(updateProgress)

  return (
    <ModalCommon
      width={448}
      isOpen={isOpen}
      close={onCancel}
      title={t('executors:title')}
      content={
        <FormWrapper>
          {formItems.map(item => (
            <ExecutorItem key={item.type} formik={formik} {...item} />
          ))}
        </FormWrapper>
      }
      footer={
        <Footer>
          <Button
            onClick={() => {
              formik.dirty && formik.submitForm().catch()
            }}
            disabled={!formik.dirty}
            intent="primary"
            progress={updateProgress}
            filled
          >
            {t('common:save')}
          </Button>
          <CancelButton onClick={onCancel} intent="minor-action">
            {t('common:cancel')}
          </CancelButton>
        </Footer>
      }
    />
  )
}
