import React from 'react'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import { BidListAddressFilter } from 'modules/domain/bid/types'
import AddressSuggest from 'views/components/Maps/AddressSuggest'
import { NumberInput } from 'views/ui/NumberInput/NumberInput'

const AddressWrapper = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
`

const SuggestWrapper = styled.div`
  max-width: 792px;
  width: 100%;
`

const Distance = styled(NumberInput)`
  margin-left: 16px;
  margin-right: 16px;
  width: 148px;
`

type Props = {
  onChange: (addr: BidListAddressFilter) => void
  filter: BidListAddressFilter
}

export const AddressFilter: React.FC<Props> = ({ onChange, filter, children }) => {
  const { t } = useTranslation('bid')

  return (
    <AddressWrapper>
      <SuggestWrapper>
        <AddressSuggest
          placeholder={t('filterByAddress')}
          initialSearch={filter.address}
          onChange={({ coords, address }) => {
            const [latitude, longitude] = coords || []
            onChange({
              ...filter,
              address: address || undefined,
              longitude,
              latitude,
            })
          }}
          size="small"
        />
      </SuggestWrapper>
      <Distance
        onChange={radius => onChange({ ...filter, radius: radius ? parseInt(String(radius), 10) : undefined })}
        inputSize="small"
        placeholder={t('filterByRadius')}
        value={filter.radius}
        isInteger
      />
      {children}
    </AddressWrapper>
  )
}
