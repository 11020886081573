import React from 'react'
import ReactDOM from 'react-dom'

import usePortal from 'views/ui/Portal/usePortal'

type Props = {
  id: string
}

export const Portal: React.FC<Props> = ({ id, children }) => {
  const target = usePortal(id)
  return ReactDOM.createPortal(children, target)
}
