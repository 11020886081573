import React, { VFC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TrailerModal } from 'views/components/AddTrailer/TrailerModal'
import { AddTextBtn } from 'views/styled/common'

type Props = {
  owner: string
}

export const AddTrailer: VFC<Props> = ({ owner }) => {
  const { t } = useTranslation('vehicles')
  const [isOpen, setIsOpen] = useState<boolean>(false)
  return (
    <>
      <AddTextBtn onClick={() => setIsOpen(true)}>{t('addTrailer')}</AddTextBtn>
      <TrailerModal
        owner={owner}
        isOpen={isOpen}
        onCancel={() => {
          setIsOpen(false)
        }}
      />
    </>
  )
}
