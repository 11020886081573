import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import DealSelectors from 'modules/domain/deal/selectors'
import { SpecificationModal } from 'views/components/AddSpecification/SpecificationModal'
import * as S from 'views/styled/common'

export const AddSpecification: React.VFC = () => {
  const { t } = useTranslation('specification')
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const { id } = useParams<{ id: string }>()

  const owner = useSelector(state => DealSelectors.item(state, id)?.purchase_bid?.owner?.id)
  const company = useSelector(state => DealSelectors.item(state, id)?.purchase_bid?.company?.id)

  return (
    <>
      <S.AddTextBtn onClick={() => setIsOpen(true)}>{t('add_specification')}</S.AddTextBtn>
      <SpecificationModal
        owner={String(owner)}
        company={String(company)}
        isOpen={isOpen}
        onCancel={() => {
          setIsOpen(false)
        }}
        disableProduct
      />
    </>
  )
}
