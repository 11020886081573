import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Progress, useAction, useDidMount, useDidMountWithProgress } from '@agro-club/agroclub-shared'

import usePrevious from 'hooks/usePrevious'
import CarActions from 'modules/domain/car/duck'
import CarSelectors, { CarStateUnity } from 'modules/domain/car/selectors'
import { Car, CarLogisticsDeal } from 'modules/domain/car/types'
import { ResourceHook } from 'modules/types'

export const useCarList = (unity: CarStateUnity, id: string): [Progress, Car[]] => {
  const progress = useSelector(state => CarSelectors.listFetchProgress(state, unity, id))
  const list = useSelector(state => CarSelectors.list(state, unity, id))
  const fetchAction = useAction(CarActions.listRequested, unity, id)

  useDidMount(fetchAction)

  return [progress, list]
}

export const useCarListsReload = (dealId: string, progress: Progress) => {
  const fetchInvAction = useAction(CarActions.listRequested, CarStateUnity.InvolvedCarsByDeal, dealId)
  const fetchRelAction = useAction(CarActions.listRequested, CarStateUnity.RelevantCarsByDeal, dealId)

  const prevProgress = usePrevious(progress)
  React.useEffect(() => {
    if (prevProgress === Progress.WORK && progress === Progress.SUCCESS) {
      fetchInvAction()
      fetchRelAction()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevProgress, progress])
}

export const useCar: ResourceHook<Car, [string]> = (id: string) => {
  const meta = useSelector(state => CarSelectors.meta(state, id))
  const item = useSelector(state => CarSelectors.item(state, id))
  const fetchAction = useAction(CarActions.itemRequested, id)

  useDidMount(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  })

  return [meta.fetchProgress, item]
}

export const useCarById: ResourceHook<Car, [string]> = (id: string) => {
  const meta = useSelector(state => CarSelectors.meta(state, id))
  const item = useSelector(state => CarSelectors.item(state, id))
  const fetchAction = useAction(CarActions.itemRequested, id)
  const progress = useDidMountWithProgress(fetchAction, meta.fetchProgress)

  return [progress, item]
}

export const useDealsByCar = (carId?: string): [Progress, Array<CarLogisticsDeal>] => {
  const progress = useSelector(state => CarSelectors.carDealsProgress(state, carId))
  const item = useSelector(state => CarSelectors.carDeals(state, carId))
  const fetchAction = useAction(CarActions.carDealsRequested, carId)

  useEffect(() => {
    if (carId) {
      fetchAction()
    }
  }, [carId, fetchAction])

  return [progress, item]
}
