import React, { VFC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { generatePath, useHistory } from 'react-router-dom'
import { CellProps, useTable } from 'react-table'

import {
  AdvancedHeadCell,
  Icons,
  Progress,
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHead,
  TableHeadRow,
  TableNoData,
  useAction,
  usePersistentScroll,
} from '@agro-club/agroclub-shared'
import { isNil } from 'ramda'
import styled from 'styled-components'

import useUserName from 'hooks/useUserName'
import UserActions from 'modules/domain/user/duck'
import { useUserFilterAndSortQuery, useUserList } from 'modules/domain/user/hooks'
import UserSelectors from 'modules/domain/user/selectors'
import { ProfileType, User } from 'modules/domain/user/types'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import { ActiveFilter } from 'views/components/TableFilters/ActiveFilter'
import { RangeFilter } from 'views/components/TableFilters/RangeFilter'
import { DistrictFilter } from 'views/components/TableFilters/RegionDistrict/DistrictFilter'
import { useOnChangeDistrict, useOnChangeRegion } from 'views/components/TableFilters/RegionDistrict/hooks'
import { RegionFilter } from 'views/components/TableFilters/RegionDistrict/RegionFilter'
import { ResetFilterButton } from 'views/components/TableFilters/ResetFilterButton'
import { TeamFilter } from 'views/components/TeamFilter/TeamFilter'
import UserRoutes from 'views/pages/User/routes'
import { NoTasksFilter } from 'views/pages/User/UserTable/NoTasksFilter'
import { ProfileTypeFilter } from 'views/pages/User/UserTable/ProfileTypeFilter'
import { RoleFilter } from 'views/pages/User/UserTable/RoleFilter'
import { Email } from 'views/ui/Email/Email'
import { SelectablePhone } from 'views/ui/SelectablePhone/SelectablePhone'
import { SelectableText } from 'views/ui/SelectableText/SelectableText'

const EmailLink = styled(Email)`
  display: block;
`

const UsersSortableHeadCell = AdvancedHeadCell<keyof User>()

const NameCell: VFC<CellProps<User>> = ({ cell: _cel, column: _col, row }) => {
  const isSellerOrCustomer =
    row.original.profile?.profile_type === ProfileType.seller ||
    row.original.profile?.profile_type === ProfileType.customer
  const verifyIcon =
    row?.original?.profile?.is_verified_by_security && row?.original?.profile?.is_verified_by_coordinator ? (
      <Icons.IconCheckMark />
    ) : null
  const showVerifyIcon = row?.original?.is_active ? verifyIcon : null
  const name = useUserName(row.values as User)
  return (
    <SelectableText>
      {name}
      {isSellerOrCustomer && showVerifyIcon}
    </SelectableText>
  )
}

function EmailPhoneCell({ cell: _cel, column: _col, row }: CellProps<User>) {
  const { email, phone } = row.original
  return (
    <>
      {!!phone && <SelectablePhone phone={phone} />}
      {!!email && <EmailLink email={email} />}
    </>
  )
}

const CompanyCell = ({ cell: _cel, column: _col, row }: CellProps<User>) => {
  const { profile } = row.values
  return (profile && profile.company && profile.company.name) || ''
}

const UserTable: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation('user')
  const [progress, data] = useUserList()
  const sortingUpdated = useAction(UserActions.sortingUpdated)
  const listRequested = useAction(UserActions.listRequested)
  const filterUpdated = useAction(UserActions.filterUpdated)
  const filterState = useSelector(UserSelectors.filter)
  const total = useSelector(UserSelectors.total)
  const pages = useSelector(UserSelectors.pages)
  const page = useSelector(UserSelectors.page)
  const pageSize = useSelector(UserSelectors.pageSize)
  const { sort_field, sort_reversed } = useSelector(UserSelectors.sorting)

  const { filter } = useUserFilterAndSortQuery()
  const start = filter.date_joined_after || filterState.date_joined_after
  const end = filter.date_joined_before || filterState.date_joined_before

  const visibleColumns = React.useMemo(
    () => [
      {
        Header: t('list.tableHeaders.firstNameLastName'),
        accessor: 'full_name' as const,
        Cell: NameCell,
      },
      {
        Header: TComponents.EmailPhoneHeader,
        Cell: EmailPhoneCell,
        accessor: 'phone' as const,
        sortable: true,
      },
      {
        Header: t('list.tableHeaders.company'),
        accessor: 'company_name' as const,
        Cell: CompanyCell,
      },
      {
        Header: t('list.tableHeaders.profile_type'),
        accessor: 'profile.profile_type' as const,
        Cell: ({ value }: CellProps<User>) => (value ? t(`profile_type.${value}`) : ''),
      },
      {
        Header: t('list.tableHeaders.role'),
        accessor: 'profile.role' as const,
        Cell: ({ value }: CellProps<User>) => (value ? t(`role.${value}`) : ''),
      },
      {
        Header: t('list.tableHeaders.active'),
        accessor: 'is_active' as const,
        Cell: ({ value }: CellProps<User>) => (value ? t('common:yes') : t('common:no')),
      },
      {
        Header: t('list.tableHeaders.team'),
        accessor: 'profile.team' as const,
        Cell: ({ row }: CellProps<User>) => row.values?.profile?.team?.name || '',
      },
      {
        Header: t('list.tableHeaders.dateJoined'),
        accessor: 'date_joined' as const,
        sortable: true,
        Cell: TComponents.DateCell,
      },
    ],
    [t],
  )
  const hiddenColumns: (keyof User)[] = useMemo(() => ['profile'], [])

  const columnsAll = useMemo(
    () => [
      ...visibleColumns,
      ...hiddenColumns.map(col => ({
        Header: col,
        accessor: col,
        hidden: true,
      })),
    ],
    [hiddenColumns, visibleColumns],
  )

  const { columns, rows, prepareRow } = useTable<User>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore weird issue with react-table typings — having more then 26 fields in type causes TS error
    columns: columnsAll,
    data: data as User[],
    initialState: { hiddenColumns },
  })

  const setPage = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  const { scrollRef } = usePersistentScroll('userList')

  const onChangeRange = React.useCallback(
    ([start, end]) => {
      filterUpdated({ ...filterState, date_joined_after: start, date_joined_before: end })
    },
    [filterState, filterUpdated],
  )

  const handleClearFilters = useCallback(() => {
    filterUpdated({})
  }, [filterUpdated])

  const isFilterApplied = Object.values(filterState).some(value => !isNil(value))

  const selectedTeams = filterState.team

  const onChangeTeam = (id: string) => {
    const set = new Set(selectedTeams)
    set.has(id) ? set.delete(id) : set.add(id)
    filterUpdated({ ...filterState, team: [...set] })
  }

  const onClearRegion = useCallback(() => {
    filterUpdated({ ...filterState, region: undefined, district: undefined })
  }, [filterState, filterUpdated])

  const onChangeDistrict = useOnChangeDistrict({ filterState, filterUpdated })

  const onChangeRegion = useOnChangeRegion({ filterState, filterUpdated })

  return (
    <TComponents.Wrapper>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <TComponents.Filters>
          <RangeFilter label={t('list.tableHeaders.dateJoined')} onChange={onChangeRange} start={start} end={end} />
          <ProfileTypeFilter />
          <RoleFilter />
          <ActiveFilter
            title={t('list.tableHeaders.active')}
            is_active={filterState.is_active}
            handleChange={(value?: string) => {
              const is_active = value === 'true' ? true : value === 'false' ? false : undefined
              filterUpdated({ ...filterState, is_active })
            }}
            handleClear={() => {
              filterUpdated({ ...filterState, is_active: undefined })
            }}
          />
          <RegionFilter onChange={onChangeRegion} onClear={onClearRegion} region={filterState.region} />
          <TeamFilter
            isNoTeam
            selectedIds={selectedTeams}
            onChange={onChangeTeam}
            onClear={() => {
              filterUpdated({ ...filterState, team: undefined })
            }}
          />
          <NoTasksFilter />
          <ResetFilterButton {...{ handleClearFilters, isFilterApplied }} />
        </TComponents.Filters>
        <DistrictFilter district={filterState.district} region={filterState.region} onChange={onChangeDistrict} />
      </div>

      <Table total={total} pages={pages} pageSize={pageSize} currentPage={page} onSetPage={setPage} ref={scrollRef}>
        <TableHead>
          <TableHeadRow>
            {columns.map(column => (
              <UsersSortableHeadCell
                zIndex={1}
                key={column.getHeaderProps().key}
                id={column.id as keyof User}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                sortable={column.sortable}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                hidden={column.hidden}
                sortField={sort_field}
                sortDesc={sort_reversed}
                onChange={sortingUpdated}
              >
                {column.render('Header')}
              </UsersSortableHeadCell>
            ))}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map(row => {
            prepareRow(row)
            const { key, ...props } = row.getRowProps()

            return (
              <TableBodyRow
                key={key}
                {...props}
                onClick={() => {
                  history.push(generatePath(UserRoutes.Detail, { id: row.original.id.toString() }))
                }}
              >
                {row.cells.map(cell => {
                  const { key, ...props } = cell.getCellProps()
                  return (
                    <TableBodyCell key={key} {...props}>
                      {cell.render('Cell')}
                    </TableBodyCell>
                  )
                })}
              </TableBodyRow>
            )
          })}
          <TableNoData
            progress={progress}
            isEmpty={!rows.length}
            colSpan={visibleColumns.length}
            loading={<TComponents.Spinner />}
          >
            <div>{isFilterApplied ? t('list.emptyFilterMsg') : t('list.emptyMsg')}</div>
            {isFilterApplied && progress !== Progress.WORK && (
              <TComponents.ClearButton intent="cancel" size="small" onClick={handleClearFilters}>
                {t('common:resetAllFilters')}
              </TComponents.ClearButton>
            )}
          </TableNoData>
        </TableBody>
      </Table>
    </TComponents.Wrapper>
  )
}

export default UserTable
