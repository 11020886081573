import React from 'react'
import { useTranslation } from 'react-i18next'

import env from 'env'
import useFormatDate from 'hooks/useFormatDate'
import useFormatNumber from 'hooks/useFormatNumber'
import { CarLogisticsDeal } from 'modules/domain/car/types'
import { DealType } from 'modules/domain/deal/types'
import * as S from 'views/pages/Carrier/CarrierDetail/UserVehiclesBoard/styled'

type Props = {
  deal: CarLogisticsDeal
  selected: boolean
  onClick: (dealId: string) => void
}

export const CarDealItem: React.FC<Props> = ({ deal, selected, onClick }) => {
  const { t } = useTranslation('common')
  const formatNumber = useFormatNumber()
  const formatDate = useFormatDate()
  const currency = env.REACT_APP_CURRENCY_SYMBOL

  return (
    <S.CarDealItem selected={selected} archived={!!deal.closed_at} onClick={() => onClick(deal.id)}>
      {deal.deal_type === DealType.agent && <S.CarDealAgent />}

      <S.CarDealIdAndDate>
        ID {deal.id} / {formatDate(deal.created)}
      </S.CarDealIdAndDate>
      <S.CarDealProduct>
        {t('productQuantity', {
          product: deal.product,
          quantity: deal.quantity,
          quantity_in_bags: formatNumber(deal?.quantity_in_bags),
        })}
      </S.CarDealProduct>
      <S.CarDealCompany>{deal.sale_company}</S.CarDealCompany>
      <S.CarDealAddress>{deal.from_address}</S.CarDealAddress>
      <S.CarDealCompany>{deal.purchase_company}</S.CarDealCompany>
      <S.CarDealAddress>{deal.to_address}</S.CarDealAddress>
      <S.CarDealPrice>
        {formatNumber(deal.margin)} {t('rub', { currency })}
      </S.CarDealPrice>
    </S.CarDealItem>
  )
}
