import { Progress } from '@agro-club/agroclub-shared'

import {
  AddError,
  DocumentFile,
  DocumentType,
  EntityMetadata,
  FetchError,
  RemoveError,
  UnloadingType,
  UpdateError,
} from 'modules/domain/types'
import { Dict } from 'types/generics.d'

export type CarrierState = {
  items: Dict<Carrier>
  meta: Dict<EntityMetadata<Carrier>>
  ids: string[]
  listFetchProgress: Progress
  listFetchError: FetchError | null
  listFetchErrorDetail?: string
  listFetchNextProgress: Progress
  listFetchNextError: FetchError | null
  listFetchNextErrorDetail?: string
  itemFetchProgress: Progress
  itemFetchError: FetchError | null
  addProgress: Progress
  addError: AddError | null
  addErrorDetail?: string
  addErrorFields?: Record<keyof Carrier, string>
  updateProgress: Progress
  updateError: UpdateError | null
  removeProgress: Progress
  removeError: RemoveError | null
  removeErrorDetail?: string

  filter: CarrierListRequestFilter
  sorting: CarrierListRequestSorting
  page: number
  total: number
  pageSize: number

  getDocumentProgress: Dict<Progress>
  getDocumentError: Dict<FetchError | null>
  getDocumentErrorDetail: Dict<string | undefined>
  documentTypes: Dict<DocumentType[]>
  getDocumentFilesProgress: Dict<Progress>
  getDocumentFilesError: Dict<FetchError | null>
  getDocumentFilesErrorDetail: Dict<string | undefined>
  documentFiles: Dict<DocumentFile[]>
  uploadProgress: Dict<Progress>
  uploadError: Dict<FetchError | null>
  uploadErrorDetail: Dict<string | undefined>
  deleteTypedDocumentProgress: Dict<Progress>
  deleteDocumentProgress: Progress
  trailers: Dict<{
    list: Trailer[]
    fetchProgress: Progress
  }> // dict by carrier id
  trailerLicenceNumber: string | null
  trailerAddProgress: Progress
  trailerAddError: AddError | null
  trailerAddErrorDetail?: string
  trailerAddErrorFields?: Record<string, string[]>
  trailerUpdateProgress: Progress
  trailerUpdateError: AddError | null
  trailerUpdateErrorDetail?: string
  trailerUpdateErrorFields?: Record<string, string[]>
  trailerArchiveProgress: Progress
  trailerArchiveError: AddError | null
  trailerArchiveErrorDetail?: string
  trailerArchiveErrorFields?: Record<string, string[]>
}

export type Carrier = {
  id: string
  full_name: string
  phone: string
  company_name: string
  cars_count: number
  is_active: boolean
  team_name: string
  date_joined: string
}

export type CarrierListRequestFilter = {
  search?: string
  date_joined_after?: string
  date_joined_before?: string
  is_active?: boolean
  region?: string[]
  team?: string[]
}

export type CarrierListRequestSorting = {
  sort_field?: keyof Carrier
  sort_reversed?: boolean
}

export type CarrierDTO = Omit<Carrier, 'id'> & { id?: string }

export enum TrailerStatuses {
  active = 'active',
  archived = 'archived',
}

export type Trailer = {
  id: string
  licence_number: string
  side_height: number | null
  unloading_type: UnloadingType | null
  brand_model?: string | null
  status?: TrailerStatuses
}

export type TrailerDTO = {
  licence_number: string | null
  owner: string
  unloading_type: UnloadingType | null
  side_height: number | null
  brand_model: string | null
}

export type UpdateTrailerDTO = {
  unloading_type: UnloadingType | null
  side_height: number | null
  brand_model: string | null
}
