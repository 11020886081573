import { DriverDTO } from 'modules/domain/driver/types'
import { Driver } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'

export const fetchDrivers = (carId: string) => apiClient.get<Driver[]>(endpoints.carDrivers(carId))

export const addDriver = (carId: string, dto: DriverDTO) => apiClient.post<Driver>(endpoints.carDrivers(carId), dto)

export const updateDriver = (driverId: string, dto: DriverDTO) =>
  apiClient.put<Driver>(endpoints.allDrivers(driverId), dto)

export const detachDriver = (carId: string, driverId: string) =>
  apiClient.delete<Driver>(endpoints.carDrivers(carId, driverId))
