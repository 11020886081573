import { User } from 'modules/domain/user/types'

const userNameFormatter = () => (user: User | undefined) => {
  const first_name = user?.profile?.first_name?.trim() || ''
  const last_name = user?.profile?.last_name?.trim() || ''
  const phone = user?.phone || ''
  const fioArr = [first_name, last_name].filter(Boolean)
  const fio = fioArr.join(' ')
  return fio || phone
}

const useUserName = (user: User | undefined) => {
  const formatter = userNameFormatter()
  return formatter(user)
}

export const useUserNameFormatter = () => userNameFormatter()

export default useUserName
