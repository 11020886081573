import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FileDropzone, Progress, Spinner } from '@agro-club/agroclub-shared'

import { RespFile } from 'modules/domain/types'
import { Dict } from 'types/generics.d'
import {
  DocumentWrapper,
  DropzoneWrapper,
  FileLink,
  FileRow,
  FilesList,
  SpinnerWrapper,
  Sure,
} from 'views/components/DocumentsView/styled'
import { BinButton } from 'views/ui/BinButton/BinButton'
import { ConfirmationTooltip } from 'views/ui/ConfirmationTooltip/ConfirmationTooltip'

type Props = {
  onDrop?: (files: File[]) => void
  files: RespFile[]
  uploadProgress?: Progress
  fetchProgress?: Progress
  onDelete?: (fileId: string) => void
  deleteDocumentProgress?: Dict<Progress>
}

export const DocumentsView: React.FC<Props> = props => {
  const { t } = useTranslation('common')
  const { onDrop, files, uploadProgress, fetchProgress, onDelete, deleteDocumentProgress } = props
  const [isConfirmVisible, setConfirmVisible] = useState<string>('')

  const showProgress =
    files.some(file => deleteDocumentProgress?.[file.pk] === Progress.WORK) ||
    uploadProgress === Progress.WORK ||
    fetchProgress === Progress.WORK

  return (
    <DocumentWrapper>
      <FilesList>
        {showProgress && (
          <SpinnerWrapper>
            <Spinner size="small" />
          </SpinnerWrapper>
        )}
        {files.map(file => (
          <FileRow key={file.pk}>
            <FileLink target="_blank" rel="noreferrer" href={file.uploaded_file}>
              {file.original_filename}
            </FileLink>
            <div>
              {onDelete && (
                <ConfirmationTooltip
                  hideArrow
                  visible={isConfirmVisible === file.pk}
                  placement="bottomRight"
                  overlayProps={{
                    header: t('removeFileHeader'),
                    body: <Sure>{t('removeFile')}</Sure>,
                    okText: t('common:delete'),
                    okAction: () => {
                      onDelete(file.pk)
                    },
                    cancelText: t('common:cancel'),
                    close: () => {
                      setConfirmVisible('')
                    },
                  }}
                >
                  <BinButton
                    onClick={() => {
                      setConfirmVisible(file.pk)
                    }}
                  />
                </ConfirmationTooltip>
              )}
            </div>
          </FileRow>
        ))}
      </FilesList>
      {onDrop && (
        <DropzoneWrapper>
          <FileDropzone accept="" maxFiles={20} onDrop={onDrop} />
        </DropzoneWrapper>
      )}
    </DocumentWrapper>
  )
}
