import { Progress } from '@agro-club/agroclub-shared'
import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer'

import { UserContact, UserContactDTO, UserContactItem, UserContactState } from 'modules/domain/userContact/types'

const initialState: UserContactState = {
  items: {},
}

const DEFAULT_ITEM: UserContactItem = {
  contacts: [],
  listFetchProgress: Progress.WORK,
  addProgress: Progress.IDLE,
  deleteProgress: {},
  editProgress: {},
}

class UserContactReducer extends ImmerReducer<UserContactState> {
  listRequested(userId: string) {
    if (!this.draftState.items[userId]) {
      this.draftState.items[userId] = { ...DEFAULT_ITEM }
    } else {
      this.draftState.items[userId].listFetchProgress = Progress.WORK
    }
  }

  listRequestSucceed(userId: string, list: UserContact[]) {
    this.draftState.items[userId].listFetchProgress = Progress.SUCCESS
    this.draftState.items[userId].contacts = list
  }

  listRequestFailed(userId: string) {
    this.draftState.items[userId].listFetchProgress = Progress.ERROR
  }

  addRequested(userId: string, _dto: UserContactDTO) {
    if (!this.draftState.items[userId]) {
      this.draftState.items[userId] = {
        ...DEFAULT_ITEM,
        addProgress: Progress.WORK,
      }
    } else {
      this.draftState.items[userId].addProgress = Progress.WORK
    }
  }

  addSucceed(userId: string) {
    if (!this.draftState.items[userId]) {
      this.draftState.items[userId] = {
        ...DEFAULT_ITEM,
        addProgress: Progress.SUCCESS,
      }
    } else {
      this.draftState.items[userId].addProgress = Progress.SUCCESS
    }
  }

  addFailed(userId: string) {
    if (!this.draftState.items[userId]) {
      this.draftState.items[userId] = {
        ...DEFAULT_ITEM,
        addProgress: Progress.ERROR,
      }
    } else {
      this.draftState.items[userId].addProgress = Progress.ERROR
    }
  }

  editRequested(userId: string, addressId: string, _dto: UserContactDTO) {
    this.draftState.items[userId] = {
      ...this.draftState.items[userId],
      editProgress: {
        [addressId]: Progress.WORK,
      },
    }
  }

  editSucceed(userId: string, addressId: string) {
    this.draftState.items[userId] = {
      ...this.draftState.items[userId],
      editProgress: {
        [addressId]: Progress.SUCCESS,
      },
    }
  }

  editFailed(userId: string, addressId: string) {
    this.draftState.items[userId] = {
      ...this.draftState.items[userId],
      editProgress: {
        [addressId]: Progress.ERROR,
      },
    }
  }

  deleteRequested(userId: string, addressId: string) {
    this.draftState.items[userId] = {
      ...this.draftState.items[userId],
      deleteProgress: {
        [addressId]: Progress.WORK,
      },
    }
  }

  deleteSucceed(userId: string, addressId: string) {
    this.draftState.items[userId] = {
      ...this.draftState.items[userId],
      contacts: this.draftState.items[userId].contacts.filter(address => String(addressId) !== String(address.id)),
      deleteProgress: {
        [addressId]: Progress.SUCCESS,
      },
    }
  }

  deleteFailed(userId: string, addressId: string) {
    this.draftState.items[userId] = {
      ...this.draftState.items[userId],
      deleteProgress: {
        [addressId]: Progress.ERROR,
      },
    }
  }
}

export const UserContactActions = createActionCreators(UserContactReducer)
export default UserContactActions
export const reducer = createReducerFunction(UserContactReducer, initialState)
