import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Button, IconAdd, IconBin, Progress, useAction, useDidMount } from '@agro-club/agroclub-shared'

import usePrevious from 'hooks/usePrevious'
import CarrierActions from 'modules/domain/carrier/duck'
import CarrierSelectors from 'modules/domain/carrier/selectors'
import { TrailerStatuses, Trailer as TrailerType } from 'modules/domain/carrier/types'
import { TrailerModal } from 'views/components/AddTrailer/TrailerModal'
import { UpdateTrailer } from 'views/components/AddTrailer/UpdateTrailer'
import {
  TrailerDescription,
  TrailerItem,
  TrailerTitle,
  TrailersWrapper,
} from 'views/components/UserRightPanel/Trailers/styled'
import {
  ButtonWrapper,
  Cell,
  Row,
  RowButton,
  RowButtonWrapper,
  Sure,
} from 'views/components/UserRightPanel/UserContact/styled'
import { EditIcon } from 'views/styled/common'
import { ConfirmationTooltip } from 'views/ui/ConfirmationTooltip/ConfirmationTooltip'

export const TrailersSummary: React.FC = () => {
  const { t } = useTranslation(['user', 'common'])
  const { id } = useParams<{ id: string }>()
  const list = useSelector(state => CarrierSelectors.trailers(state, id))
  return <div>{list.length || t('common:no')}</div>
}

type Props = {
  item: TrailerType
  onEdit: (item: TrailerType) => void
}

export const Trailer: React.FC<Props> = ({ item, onEdit }) => {
  const { t } = useTranslation(['common', 'vehicles'])
  const [isConfirmVisible, setConfirmVisible] = useState<boolean>(false)
  const archiveTrailer = useAction(CarrierActions.trailerArchiveRequested)
  const archived = item.status === TrailerStatuses.archived

  const trailerModel = item.brand_model || ''
  const trailerUnloadingType = t(`vehicles:unloadingType.${item.unloading_type}`) || ''
  const trailerHeight = `${item.side_height} ${t('common:m')}` || ''
  const trailerDescription = [trailerModel, trailerUnloadingType, trailerHeight].filter(el => el !== '').join(', ')

  return (
    <Row key={item.id}>
      <Cell>
        <TrailerItem archived={archived}>
          <TrailerTitle>{item.licence_number}</TrailerTitle>
          <TrailerDescription>{trailerDescription}</TrailerDescription>
        </TrailerItem>
      </Cell>
      <Cell>
        {!archived && (
          <RowButtonWrapper>
            <RowButton size="small" intent="minor-action" onClick={() => onEdit(item)}>
              <EditIcon />
            </RowButton>
            <ConfirmationTooltip
              hideArrow
              visible={isConfirmVisible}
              placement="bottomRight"
              overlayProps={{
                header: t('archiveTrailerHeader'),
                body: <Sure>{t('archiveTrailer')}</Sure>,
                okText: t('common:archive'),
                okAction: () => {
                  archiveTrailer(item.id)
                },
                cancelText: t('common:cancel'),
                close: () => {
                  setConfirmVisible(false)
                },
              }}
            >
              <div>
                <RowButton size="small" intent="minor-action" onClick={() => setConfirmVisible(true)}>
                  <IconBin />
                </RowButton>
              </div>
            </ConfirmationTooltip>
          </RowButtonWrapper>
        )}
      </Cell>
    </Row>
  )
}

export const Trailers: React.FC = () => {
  const { t } = useTranslation('common')
  const { id } = useParams<{ id: string }>()
  const fetchAction = useAction(CarrierActions.trailersRequested, id)
  const trailers = useSelector(state => CarrierSelectors.trailers(state, id))
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [editItem, setEditItem] = useState<TrailerType | null>(null)

  const trailerAddProgress = useSelector(CarrierSelectors.trailerAddProgress)
  const trailerUpdateProgress = useSelector(CarrierSelectors.trailerUpdateProgress)
  const trailerArchiveProgress = useSelector(CarrierSelectors.trailerArchiveProgress)
  const prevTrailerAddProgress = usePrevious(trailerAddProgress)
  const prevTrailerUpdateProgress = usePrevious(trailerUpdateProgress)
  const prevTrailerArchiveProgress = usePrevious(trailerArchiveProgress)

  useDidMount(fetchAction)

  useEffect(() => {
    const added = prevTrailerAddProgress === Progress.WORK && trailerAddProgress === Progress.SUCCESS
    const updated = prevTrailerUpdateProgress === Progress.WORK && trailerUpdateProgress === Progress.SUCCESS
    const archived = prevTrailerArchiveProgress === Progress.WORK && trailerArchiveProgress === Progress.SUCCESS
    if (added || updated || archived) {
      fetchAction()
    }
  }, [
    fetchAction,
    trailerAddProgress,
    trailerUpdateProgress,
    trailerArchiveProgress,
    prevTrailerAddProgress,
    prevTrailerUpdateProgress,
    prevTrailerArchiveProgress,
  ])

  return (
    <TrailersWrapper>
      <div>
        {trailers.map(item => (
          <Trailer key={item.id} item={item} onEdit={setEditItem} />
        ))}
      </div>
      <ButtonWrapper>
        <Button intent="action" filled onClick={() => setIsOpen(true)} size="small">
          <IconAdd />
          {t('common:addTrailer')}
        </Button>
      </ButtonWrapper>
      <TrailerModal
        owner={id}
        isOpen={isOpen}
        onCancel={() => {
          setIsOpen(false)
        }}
      />
      {editItem && <UpdateTrailer onCancel={() => setEditItem(null)} trailer={editItem} />}
    </TrailersWrapper>
  )
}
