import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, generatePath, useHistory, useLocation, useParams } from 'react-router-dom'

import { Button, Chip, useHelmet } from '@agro-club/agroclub-shared'

import { getUserCompanyName, getUserPhone } from 'helpers/getUserData'
import useUserName from 'hooks/useUserName'
import { useUser } from 'modules/domain/user/hooks'
import { ProfileType } from 'modules/domain/user/types'
import { LocationStateFrom } from 'types/location'
import { CarrierRightBlock } from 'views/components/UserRightPanel/CarrierRightBlock'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import { Beside } from 'views/pages/Carrier/CarrierDetail/styled'
import { UserVehiclesBoard } from 'views/pages/Carrier/CarrierDetail/UserVehiclesBoard/UserVehiclesBoard'
import { useCarrierGoBack } from 'views/pages/Carrier/hooks'
import CarrierRoutes from 'views/pages/Carrier/routes'
import UserRoutes from 'views/pages/User/routes'
import * as Header from 'views/ui/Header/Header'
import { Phone } from 'views/ui/Phone/Phone'

export const CarrierDetail: React.FC = () => {
  const location = useLocation<LocationStateFrom>()
  const { t } = useTranslation('user')
  const { id } = useParams<{ id: string }>()
  const [, user] = useUser(id)
  const userName = useUserName(user)
  const companyName = getUserCompanyName(user)
  const phone = getUserPhone(user)
  const goBack = useCarrierGoBack()
  const isCarrier = !user?.profile?.profile_type || user?.profile?.profile_type === ProfileType.carrier
  const history = useHistory()

  useEffect(() => {
    if (!isCarrier && id) {
      history.push(generatePath(UserRoutes.Detail, { id }))
    }
  }, [isCarrier, history, id])

  useHelmet({ title: userName })

  return (
    <>
      <Layout.Header hasBottomBorder>
        <Header.Root
          onClickBack={goBack}
          rightBlock={
            <Header.ButtonWrapper>
              <Link
                to={{
                  pathname: generatePath(CarrierRoutes.Edit, { id }),
                  state: { from: location.state?.from ? { ...location.state?.from } : undefined },
                }}
              >
                <Button filled intent="primary" size="small">
                  {t('common:edit')}
                </Button>
              </Link>
              {!user?.is_active && <Chip color="red">{t('blocked')}</Chip>}
            </Header.ButtonWrapper>
          }
        >
          <Header.Title compact title={userName} />
          <Header.SubTitle>
            {companyName} / <Phone phone={phone} />
          </Header.SubTitle>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <Beside>
          <UserVehiclesBoard userId={id} />
          <CarrierRightBlock />
        </Beside>
      </Layout.Content>
    </>
  )
}
