import { Progress } from '@agro-club/agroclub-shared'
import { createSelector } from 'reselect'

import { LogisticsDeal } from 'modules/domain/logisticsDeal/types'
import { EntityMetadata } from 'modules/domain/types'
import { AppGlobalState } from 'modules/types'

const dict = (state: AppGlobalState) => state.logisticsDeal.items
const ids = (state: AppGlobalState) => state.logisticsDeal.ids
const item = (state: AppGlobalState, id?: string): LogisticsDeal | undefined =>
  id ? state.logisticsDeal.items[id] : undefined
const meta = (state: AppGlobalState, id?: string): EntityMetadata<LogisticsDeal> =>
  (id && state.logisticsDeal.meta[id]) || {
    fetchError: null,
    fetchProgress: Progress.IDLE,
    id,
    removeError: null,
    removeProgress: Progress.IDLE,
    updateError: null,
    updateProgress: Progress.IDLE,
  }
const itemFetchProgress = (state: AppGlobalState) => state.logisticsDeal.itemFetchProgress
const listFetchProgress = (state: AppGlobalState) => state.logisticsDeal.listFetchProgress
const listFetchError = (state: AppGlobalState) => state.logisticsDeal.listFetchError
const listFetchErrorDetail = (state: AppGlobalState) => state.logisticsDeal.listFetchErrorDetail
const list = createSelector(dict, ids, listFetchProgress, (dict, ids, progress) => {
  if (progress === Progress.WORK) {
    return []
  }
  const result: LogisticsDeal[] = []
  for (const id of ids) {
    result.push(dict[id])
  }
  return result
})

const filter = (state: AppGlobalState) => state.logisticsDeal.filter
const sorting = (state: AppGlobalState) => state.logisticsDeal.sorting
const page = (state: AppGlobalState) => state.logisticsDeal.page
const total = (state: AppGlobalState) => state.logisticsDeal.total
const updateProgress = (state: AppGlobalState) => state.logisticsDeal.updateProgress
const removeProgress = (state: AppGlobalState) => state.logisticsDeal.removeProgress
const addProgress = (state: AppGlobalState) => state.logisticsDeal.addProgress
const hasNext = (state: AppGlobalState) => state.logisticsDeal.total > state.logisticsDeal.ids.length
const pageSize = (state: AppGlobalState) => state.logisticsDeal.pageSize
const pages = (state: AppGlobalState) => Math.ceil(state.logisticsDeal.total / state.logisticsDeal.pageSize)
const changeStatusProgress = (state: AppGlobalState, id?: string) =>
  (id && state.logisticsDeal.changeStatusProgress[id]) || Progress.IDLE

const LogisticsDealSelectors = {
  filter,
  sorting,
  page,
  total,
  updateProgress,
  removeProgress,
  addProgress,
  item,
  meta,
  itemFetchProgress,
  listFetchProgress,
  listFetchError,
  listFetchErrorDetail,
  list,
  dict,
  ids,
  hasNext,
  pageSize,
  pages,
  changeStatusProgress,
}

export default LogisticsDealSelectors
