import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { SimpleSelect, useAction } from '@agro-club/agroclub-shared'

import UserActions from 'modules/domain/user/duck'
import UserSelectors from 'modules/domain/user/selectors'
import { ROLES } from 'types/entities'
import { Filter } from 'views/components/TableFilters/TableFilters'

import * as Styled from './styled'

export function RoleFilter() {
  const { t } = useTranslation('user')
  const filterUpdated = useAction(UserActions.filterUpdated)
  const { profile_role, ...restFilter } = useSelector(UserSelectors.filter)
  const options = useMemo(() => Object.keys(ROLES).map(role => ({ id: role, title: t(`role.${role}`) })), [t])

  const handleChange = (id: string) => {
    filterUpdated({ ...restFilter, profile_role: id })
  }

  const handleClear = () => {
    filterUpdated({ ...restFilter, profile_role: undefined })
  }

  return (
    <Filter title={t('list.tableHeaders.role')}>
      <SimpleSelect
        placeholder={t('allRoles')}
        isClearable
        options={options}
        onChange={handleChange}
        customStyles={Styled.customSelectStyles}
        value={profile_role}
        onClear={handleClear}
      />
    </Filter>
  )
}
