import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Progress, useAction, useDidMount } from '@agro-club/agroclub-shared'

import { ResourceHook } from 'modules/types'

import TripActions from './duck'
import TripSelectors from './selectors'
import { Trip } from './types'

export const useTripList = (dealId: string): [Progress, Trip[]] => {
  const progress = useSelector(TripSelectors.listFetchProgress)
  const list = useSelector(state => TripSelectors.list(state, dealId))
  const fetchAction = useAction(TripActions.listRequested, dealId)

  useDidMount(fetchAction)

  return [progress, list]
}

export const useTrip: ResourceHook<Trip, [string]> = (id: string) => {
  const meta = useSelector(state => TripSelectors.meta(state, id))
  const item = useSelector(state => TripSelectors.item(state, id))
  const fetchAction = useAction(TripActions.itemRequested, id)

  useDidMount(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  })

  return [meta.fetchProgress, item]
}

export const useTripById: ResourceHook<Trip, [string]> = (id: string) => {
  const meta = useSelector(state => TripSelectors.meta(state, id))
  const item = useSelector(state => TripSelectors.item(state, id))
  const fetchAction = useAction(TripActions.itemRequested, id)

  useEffect(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  }, [item, id, fetchAction])

  return [meta.fetchProgress, item]
}
