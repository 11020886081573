import React from 'react'
import { useSelector } from 'react-redux'

import { Progress } from '@agro-club/agroclub-shared'

import DashboardSelectors from 'modules/domain/dashboard/selectors'
import { Spinner } from 'views/components/CommonTableComponents/CommonTableComponents'
import { BidItem } from 'views/pages/Dashboard/BidItem'

export const New: React.FC = () => {
  const newItems = useSelector(DashboardSelectors.newList)
  const progress = useSelector(DashboardSelectors.newFetchProgress)
  return progress === Progress.WORK ? (
    <Spinner />
  ) : (
    <>
      {newItems.map(task => (
        <BidItem key={task.id} task={task} />
      ))}
    </>
  )
}
