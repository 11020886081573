import { AppGlobalState } from '../../types'

const driversByCarList = (state: AppGlobalState, carId: string) =>
  (state.driver.driversByCar[carId] ?? []).map(id => state.driver.items[id])
const driversFetchProgress = (state: AppGlobalState) => state.driver.listFetchProgress
const addDriverProgress = (state: AppGlobalState) => state.driver.addProgress
const updateDriverProgress = (state: AppGlobalState) => state.driver.updateProgress
const detachDriverProgress = (state: AppGlobalState) => state.driver.detachProgress
const addDriverErrorFields = (state: AppGlobalState) => state.driver.addErrorFields
const updateDriverErrorFields = (state: AppGlobalState) => state.driver.updateErrorFields

const DriverSelectors = {
  driversByCarList,
  driversFetchProgress,
  addDriverProgress,
  updateDriverProgress,
  detachDriverProgress,
  addDriverErrorFields,
  updateDriverErrorFields,
}

export default DriverSelectors
