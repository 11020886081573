import { Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import { DealType } from 'modules/domain/deal/types'
import { box } from 'views/styled/common'

export const TwoColumnLayout = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 16px 24px;
`

export const Container = styled.div`
  width: 448px;
  padding: 16px;
  ${box};

  &:first-child {
    width: 684px;
    margin-right: 24px;
  }
`

export const Label = styled.div`
  color: ${props => props.theme.color.textSecondary};
  margin: 16px 0 4px;
  white-space: nowrap;
  ${Typography.subheadlineDefault};
`

export const Value = styled.div`
  color: ${props => props.theme.color.textPrimary};
  ${Typography.body2Default};
`

export const FlexHorizontal = styled.div`
  display: flex;
`

export const DealTypeCircle = styled.div<{ dealType: DealType }>`
  width: 44px;
  height: 44px;
  border-radius: 22px;

  ${({ dealType, theme }) =>
    dealType === DealType.trade
      ? `
        background-color: ${theme.color.accentNotify600}29;
        color: ${theme.color.accentNotify600};
      `
      : `
        background-color: ${theme.color.accentApprove400}29;
        color: ${theme.color.accentApprove600};
      `}

  line-height: 44px;
  text-align: center;
  font-weight: 700;
  font-size: 20px;

  margin-right: 8px;
`

export const TripInfoWrapper = styled.div`
  margin-bottom: 16px;
`

export const DealTypeValue = styled.div`
  color: ${props => props.theme.color.textSecondary};
  margin-bottom: 4px;
  white-space: nowrap;
  ${Typography.subheadlineDefault};
`

export const CarDescription = styled.div`
  color: ${props => props.theme.color.textPrimary};
  ${Typography.body2Default};
`

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
`
