import React from 'react'
import { useTranslation } from 'react-i18next'

import env from 'env'
import useFormatDate from 'hooks/useFormatDate'
import useFormatNumber from 'hooks/useFormatNumber'
import { CarTrip } from 'modules/domain/carTrip/types'
import { Checked } from 'views/components/Checked/Checked'
import * as S from 'views/pages/Carrier/CarrierDetail/UserVehiclesBoard/styled'

type Props = {
  carTrip: CarTrip
  archived: boolean
}

export const DealTripItem: React.FC<Props> = ({ carTrip, archived }) => {
  const { t } = useTranslation('common')
  const formatNumber = useFormatNumber()
  const formatDate = useFormatDate()
  const { load_date, load_quantity, distance, price_logistics, has_ttn_1, has_ttn_2 } = carTrip
  const currency = env.REACT_APP_CURRENCY_SYMBOL

  return (
    <S.DealTripItem archived={archived}>
      <S.DealTripDate>{formatDate(load_date)}</S.DealTripDate>
      <div>
        <S.DealTripKmT>{`${distance} ${t('km')} / ${load_quantity} ${t('t')} `}</S.DealTripKmT>
        <S.DealTripTKm>{`${formatNumber(price_logistics, true)} ${t('rub', { currency })} ${t('tKm')}`}</S.DealTripTKm>
      </div>
      <S.Checks>
        <S.Label>{t('vehicles:ttn1')}</S.Label> <Checked value={has_ttn_1} />
        <S.Label>{t('vehicles:ttn2')}</S.Label> <Checked value={has_ttn_2} />
      </S.Checks>
    </S.DealTripItem>
  )
}
