import { useState } from 'react'

const FRACTON_DIGITS = 3
const KG_IN_BAG = 60

type Value = string | number | undefined
type Converter = (v: Value) => void
type Callback = (callback: Converter) => Converter

export const formatNumber = (value: Value): number => parseFloat(Number(value).toFixed(FRACTON_DIGITS))

export const tonsToBags = (tons: Value): number => formatNumber((Number(tons) * 1000) / KG_IN_BAG)

export const bagsToTons = (bags: Value): number => formatNumber((Number(bags) * KG_IN_BAG) / 1000)

function useTonsAndBags(tons?: Value): [Value, Callback, Callback, Converter] {
  const [bags, setBags] = useState<Value>(tons ? formatNumber((Number(tons) * 1000) / KG_IN_BAG) : '')

  const tonsToBagsCallback = callback => tons => {
    setBags(tonsToBags(tons))
    callback(tons)
  }

  const bagsToTonsCallback = callback => bags => {
    setBags(bags)
    callback(bagsToTons(bags))
  }

  return [bags, tonsToBagsCallback, bagsToTonsCallback, setBags]
}

export default useTonsAndBags
