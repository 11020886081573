import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Progress, useAction } from '@agro-club/agroclub-shared'

import UserCallsActions from 'modules/domain/userCalls/duck'
import UserCallsSelectors from 'modules/domain/userCalls/selectors'
import { UserCall } from 'modules/domain/userCalls/types'

export const useUserCalls = (userId: string): [Progress, UserCall[]] => {
  const progress = useSelector(state => UserCallsSelectors.listFetchProgress(state, userId))
  const list = useSelector(state => UserCallsSelectors.callsByUserId(state, userId))
  const fetchAction = useAction(UserCallsActions.listRequested, userId)

  useEffect(() => {
    userId && fetchAction()
  }, [userId, fetchAction])

  return [progress, list]
}
