import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { StylesConfig } from 'react-select'

import {
  Progress,
  ReactSelectOption,
  SimpleSelect,
  SimpleSelectProps,
  controlStyle,
  menuListStyle,
  menuStyle,
  optionStyle,
  useAction,
} from '@agro-club/agroclub-shared'

import DriverActions from 'modules/domain/driver/duck'
import { useDriversList } from 'modules/domain/driver/hooks'

export const customSelectStyles: StylesConfig<ReactSelectOption, boolean> = {
  control: (...args) => ({
    ...controlStyle(...args),
    // height: '32px',
    // minHeight: '32px',
  }),
  menu: base => ({
    ...base,
    ...menuStyle,
    zIndex: 10,
    width: '100%',
  }),
  menuList: base => ({
    ...base,
    ...menuListStyle,
    width: '100%',
    maxHeight: '350px',
  }),
  option: base => ({
    ...base,
    ...optionStyle,
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    maxHeight: '32px',
  }),
}

type Props = {
  carId: string
  driverId: string | null
  onChangeDriver: (driverId: string) => void
  required?: boolean
  menuPlacement?: SimpleSelectProps['menuPlacement']
  invalid?: boolean
  errorText?: string
}

export const DriverSelect: React.FC<Props> = props => {
  const { carId, driverId, onChangeDriver, required, menuPlacement, invalid, errorText } = props
  const { t } = useTranslation('logistics')

  const [progress, drivers] = useDriversList(carId)
  const fetchAction = useAction(DriverActions.listRequested, carId)

  const options = useMemo<SimpleSelectProps['options']>(
    () => drivers.map(driver => ({ id: driver.id, title: `${driver.name} (${driver.phone})` })),
    [drivers],
  )

  return (
    <SimpleSelect
      invalid={invalid}
      errorText={errorText}
      menuPlacement={menuPlacement}
      label={t('driverSelect.label')}
      placeholder={t('driverSelect.teamPlaceholder')}
      loadingMessage={t('common:loading')}
      noOptionsMessage={() => t('driverSelect.noDrivers')}
      customStyles={customSelectStyles}
      options={options}
      progress={progress === Progress.IDLE ? Progress.SUCCESS : progress}
      value={driverId}
      required={required}
      onMenuOpen={fetchAction}
      onChange={(driverId: string) => {
        onChangeDriver(driverId)
      }}
    />
  )
}
