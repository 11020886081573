import { AppGlobalState } from 'modules/types'

const specificationAddProgress = (state: AppGlobalState) => state.specification.specificationAddProgress
const specificationAddErrorFields = (state: AppGlobalState) => state.specification.specificationAddErrorFields
const ownerSpecificationDocuments = (state: AppGlobalState) => state.specification.ownerDocuments

const SpecificationSelectors = {
  specificationAddProgress,
  specificationAddErrorFields,
  ownerSpecificationDocuments,
}

export default SpecificationSelectors
