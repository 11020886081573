import { User } from 'modules/domain/user/types'

export const getUser = (item): User =>
  item &&
  ({
    id: item.linked_user.id,
    date_joined: item.registration_date,
    phone: item.linked_user.phone,
    profile: {
      full_name: item.linked_user.full_name,
      profile_type: item.linked_user.profile_type,
    },
  } as User)
