import { Progress } from '@agro-club/agroclub-shared'

import { AppGlobalState } from 'modules/types'

const listFetchProgress = (state: AppGlobalState, userId: string) =>
  state.userContact.items?.[userId]?.listFetchProgress || Progress.IDLE
const addProgress = (state: AppGlobalState, userId: string) =>
  state.userContact.items?.[userId]?.addProgress || Progress.IDLE
const contactsByUserId = (state: AppGlobalState, userId?: string) =>
  userId ? state.userContact.items?.[userId]?.contacts || [] : []
const deleteProgress = (state: AppGlobalState, userId: string, contactId: string) =>
  state.userContact.items?.[userId]?.deleteProgress?.[contactId] || Progress.IDLE
const editProgress = (state: AppGlobalState, userId: string, contactId: string) =>
  state.userContact.items?.[userId]?.editProgress?.[contactId] || Progress.IDLE

const UserContactSelectors = {
  addProgress,
  listFetchProgress,
  deleteProgress,
  editProgress,
  contactsByUserId,
}

export default UserContactSelectors
