import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import CollectionSelectors from 'modules/domain/collection/selectors'

type ChangeRegionOptions = {
  filterState: {
    district?: string[]
    region?: string[]
  }
  filterUpdated: (filter: ChangeRegionOptions['filterState']) => void
}

export const useOnChangeRegion = ({ filterState, filterUpdated }: ChangeRegionOptions) => {
  const allRegions = useSelector(CollectionSelectors.getRegions)

  return useCallback(
    (regionId: string) => {
      const { region = [] } = filterState
      const set = new Set(region)

      let { district } = filterState

      if (set.has(regionId)) {
        set.delete(regionId)
        district = district?.reduce((acc: string[], districtId) => {
          const dist = allRegions.find(r => r.id === districtId)
          if (dist?.parent_id !== regionId) {
            acc.push(districtId)
          }
          return acc
        }, [])
      } else {
        set.add(regionId)
      }

      filterUpdated({ ...filterState, district, region: [...set] })
    },
    [filterState, filterUpdated, allRegions],
  )
}

export const useOnChangeDistrict = ({ filterState, filterUpdated }: ChangeRegionOptions) =>
  useCallback(
    (id: string) => {
      const { district = [] } = filterState
      const set = new Set(district)
      if (set.has(id)) {
        set.delete(id)
      } else {
        set.add(id)
      }
      filterUpdated({ ...filterState, district: [...set] })
    },
    [filterState, filterUpdated],
  )
