import { useCallback } from 'react'

import { toggleItemInList } from 'views/components/AssigneesFilter/helpers/toggleItemInList'

export const useOnChangeAssignee = ({ filterState, filterUpdated }) =>
  useCallback(
    (assignee: string) => {
      const assigneesList = toggleItemInList(assignee, filterState?.assignee || [])
      filterUpdated({ ...filterState, assignee: assigneesList })
    },
    [filterState, filterUpdated],
  )
