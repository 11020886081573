enum UserRoutes {
  List = '/users',
  Add = '/users/add',
  Detail = '/users/:id(\\d+)',
  Edit = '/users/:id(\\d+)/edit',
  BidAdd = '/users/:id(\\d+)/add-bid',
  BidPrice = '/users/:id(\\d+)/bid-price',
}

export default UserRoutes
