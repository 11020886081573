import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { SimpleSelect, useAction } from '@agro-club/agroclub-shared'

import CarsActions from 'modules/domain/cars/duck'
import CarsSelectors from 'modules/domain/cars/selectors'
import { VehicleType } from 'modules/domain/types'
import * as Styled from 'views/pages/Cars/CarsTable/styled'

export function CarTypeFilter() {
  const { t } = useTranslation('cars')
  const filterUpdated = useAction(CarsActions.filterUpdated)
  const { car_type, ...restFilter } = useSelector(CarsSelectors.filter)
  const options = useMemo(
    () => Object.keys(VehicleType).map(pType => ({ id: VehicleType[pType], title: t(`carTypeFilter.${pType}`) })),
    [t],
  )

  const handleChange = (id: string) => {
    filterUpdated({ ...restFilter, car_type: id })
  }

  const handleClear = () => {
    filterUpdated({ ...restFilter, car_type: undefined })
  }

  return (
    <SimpleSelect
      placeholder={t('any')}
      isClearable
      options={options}
      onChange={handleChange}
      customStyles={Styled.customSelectStyles}
      value={car_type}
      onClear={handleClear}
    />
  )
}
