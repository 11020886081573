import { Progress } from '@agro-club/agroclub-shared'
import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer'
import { isEmpty } from 'ramda'

import {
  AverageStatistics,
  CallStatistics,
  CallStatisticsListRequestFilter,
  CallStatisticsListRequestSorting,
  CallStatisticsState,
  GeneralStatistics,
} from 'modules/domain/callStatistics/types'
import { FetchError } from 'modules/domain/types'
import { arrToDict, getIds } from 'modules/utils/helpers'

const initialState: CallStatisticsState = {
  items: {},
  meta: {},
  ids: [],

  listFetchProgress: Progress.IDLE,
  listFetchError: null,
  listFetchNextProgress: Progress.IDLE,
  listFetchNextError: null,
  averageStatisticsFetchProgress: Progress.IDLE,
  averageStatisticsFetchError: null,
  averageStatistics: {},
  generalStatisticsFetchProgress: Progress.IDLE,
  generalStatisticsFetchError: null,
  generalStatistics: {},

  filter: {},
  sorting: {},
  page: 1,
  total: 0,
  pageSize: 20,
}

export type ListRequestedParams = {
  filter?: CallStatisticsListRequestFilter
  sorting?: CallStatisticsListRequestSorting
  page?: number
}

class CallStatisticsReducer extends ImmerReducer<CallStatisticsState> {
  listRequested(params: ListRequestedParams) {
    this.draftState.listFetchProgress = Progress.WORK
    this.draftState.listFetchError = null
    this.draftState.filter = (!isEmpty(params.filter) && params.filter) || this.draftState.filter
    this.draftState.sorting = params.sorting || this.draftState.sorting
    this.draftState.page = typeof params.page === 'undefined' ? this.draftState.page : params.page
  }

  listRequestSucceed(list: CallStatistics[], total: number, page: number) {
    this.draftState.listFetchProgress = Progress.SUCCESS
    this.draftState.total = total
    this.draftState.page = page
    this.draftState.items = arrToDict(list)
    this.draftState.meta = arrToDict(
      list.map(item => ({
        id: item.id,
        fetchProgress: Progress.SUCCESS,
        fetchError: null,
      })),
    )

    this.draftState.ids = getIds(list)
  }

  listRequestFailed(error: FetchError) {
    this.draftState.listFetchProgress = Progress.ERROR
    this.draftState.listFetchError = error
  }

  filterUpdated(filter: CallStatisticsListRequestFilter) {
    this.draftState.filter = filter
    this.draftState.listFetchProgress = Progress.WORK
    this.draftState.page = 1
  }

  filterResetWithoutRequest() {
    this.draftState.filter = {}
    this.draftState.listFetchProgress = Progress.IDLE
    this.draftState.page = 1
  }

  sortingUpdated(sorting: CallStatisticsListRequestSorting) {
    this.draftState.sorting = sorting
    this.draftState.listFetchProgress = Progress.WORK
  }

  filterHasBeenReset() {
    this.draftState.filter = {}
    this.draftState.listFetchProgress = Progress.WORK
  }

  sortingHasBeenReset() {
    this.draftState.sorting = {}
    this.draftState.listFetchProgress = Progress.WORK
  }

  statisticsAverageRequested() {
    this.draftState.averageStatisticsFetchProgress = Progress.WORK
    this.draftState.averageStatisticsFetchError = null
    this.draftState.averageStatistics = {}
  }

  statisticsAverageRequestedSuccess(data: AverageStatistics) {
    this.draftState.averageStatisticsFetchProgress = Progress.SUCCESS
    this.draftState.averageStatisticsFetchError = null
    this.draftState.averageStatistics = data
  }

  statisticsGeneralRequested() {
    this.draftState.generalStatisticsFetchProgress = Progress.WORK
    this.draftState.generalStatisticsFetchError = null
    this.draftState.generalStatistics = {}
  }

  statisticsGeneralRequestedSuccess(data: GeneralStatistics) {
    this.draftState.generalStatisticsFetchProgress = Progress.SUCCESS
    this.draftState.generalStatisticsFetchError = null
    this.draftState.generalStatistics = data
  }
}

export const CallStatisticsActions = createActionCreators(CallStatisticsReducer)
export const reducer = createReducerFunction(CallStatisticsReducer, initialState)
export default CallStatisticsActions
