import { Progress } from '@agro-club/agroclub-shared'

import { Team } from 'modules/domain/collection/types'
import { Company } from 'modules/domain/company/types'
import {
  AddError,
  DocumentFile,
  DocumentType,
  Documents,
  EntityMetadata,
  FetchError,
  RemoveError,
  UpdateError,
} from 'modules/domain/types'
import { UserAddressDTO } from 'modules/domain/userAddress/types'
import { ROLES } from 'types/entities'
import { Dict } from 'types/generics.d'

export type UserListRequestFilter = {
  search?: string
  date_joined_after?: string
  date_joined_before?: string
  team?: string[]
  profile_role?: string
  profile_type?: string
  is_active?: boolean
  is_linked_to_active_task?: boolean
  region?: string[]
  district?: string[]
}

export enum ProfileType {
  seller = 'seller',
  customer = 'customer',
  coordinator = 'coordinator',
  carrier = 'carrier',
}

export type User = {
  id: string
  date_joined: string
  phone: string
  email: string
  is_active: boolean
  role: string
  signup_step: string
  permissions?: string[]
  profile: {
    first_name: string
    last_name: string
    patronymic_name: string
    full_name: string
    profile_type: ProfileType
    company: Company
    role: ROLES
    team: null | Team
    recommended_margin_percent?: number
    credit_limit?: number
    forecast_credit_limit?: number
    is_verified_by_coordinator: boolean
    is_verified_by_security: boolean
    is_verified?: boolean
    coordinator_type?: string
  } | null
}

export type UserListRequestSorting = {
  sort_field?: keyof User
  sort_reversed?: boolean
}

export type UserDTO = Omit<User, 'id' | 'date_joined' | 'is_active' | 'role' | 'signup_step' | 'profile'> & {
  id?: string
  addresses?: UserAddressDTO[]
  profile: {
    first_name: string
    last_name: string
    patronymic_name: string
    profile_type: ProfileType
    company?: string // companyId
    team: string | null // teamId
    recommended_margin_percent?: number
    credit_limit?: number
  }
}

export type UsersState = {
  items: Dict<User>
  meta: Dict<EntityMetadata<User>>
  ids: string[]
  listFetchProgress: Progress
  listFetchError: FetchError | null
  itemFetchProgress: Progress
  itemFetchError: FetchError | null
  addProgress: Progress
  lastAddedUserId: string | null
  addError: AddError | null
  addErrorDetail?: string
  addErrorFields?: Record<string, string[]>
  updateProgress: Progress
  updateError: UpdateError | null
  removeProgress: Progress
  removeError: RemoveError | null

  filter: UserListRequestFilter
  sorting: UserListRequestSorting
  page: number
  total: number
  pageSize: number

  verifyCoordinatorProgress: Progress
  verifySecurityProgress: Progress
  userBlockProgress: Progress

  documents: Dict<Documents>
  deleteDocumentProgress: Dict<Progress> // dict by file pk

  getTypedDocumentProgress: Dict<Progress>
  getTypedDocumentError: Dict<FetchError | null>
  getTypedDocumentErrorDetail: Dict<string | undefined>
  documentTypes: Dict<DocumentType[]>
  getDocumentFilesProgress: Dict<Progress>
  getDocumentFilesError: Dict<FetchError | null>
  getDocumentFilesErrorDetail: Dict<string | undefined>
  documentFiles: Dict<DocumentFile[]>
  uploadProgress: Dict<Progress>
  uploadError: Dict<FetchError | null>
  uploadErrorDetail: Dict<string | undefined>
  deleteTypedDocumentProgress: Dict<Progress>
}
