import { Button, Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import { box } from 'views/styled/common'

export const TwoColumn = styled.div<{ single?: boolean; column?: number }>`
  display: ${({ single }) => (single ? 'inline-grid' : 'grid')};
  grid-template-columns: repeat(auto-fit, minmax(${({ column }) => column || 566}px, 1fr));
  grid-gap: 24px;
  padding: 16px 24px;
`

export const Column = styled.div``

export const Box = styled.div`
  padding: 24px;
  display: grid;
  grid-gap: 24px;
  ${box}
`

export const ProductFields = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 252px 100px 100px 100px;
`

export const PriceQuantity = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(3, 120px);
`

export const SelectWrapper = styled.div`
  width: 600px;
`

export const SaveButton = styled(Button)`
  margin-right: 16px;
`

export const ParamsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const ParamsTitle = styled.div`
  margin: 0 0 16px;
  color: ${({ theme }) => theme.color.textSecondary};
  ${Typography.subheadlineCAPS};
`

export const ParamsBody = styled.div`
  display: grid;
  grid-gap: 16px;
`

export const MainParamWrapper = styled.div`
  display: grid;
  grid-template-columns: 197px 74px 100px;
  grid-gap: 16px;
  align-items: center;
  ${Typography.body2Default}
`

export const TaxesContainer = styled.div`
  display: flex;
  grid-gap: 16px;
`
