import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import useFormatNumber from 'hooks/useFormatNumber'
import { Deal, DealBid } from 'modules/domain/deal/types'
import { LogisticsDeal } from 'modules/domain/logisticsDeal/types'
import { WarehouseDTO, YesNoUnknown } from 'modules/domain/types'
import UserAddressSelectors from 'modules/domain/userAddress/selectors'
import { NoWrap, Wrap } from 'views/components/UserRightPanel/Addresses/AddressItem/styled'

export type Props = {
  deal?: Deal | LogisticsDeal | undefined
  type: 'loading' | 'unloading'
  addressId?: string
}

export const WarehouseProps: React.FC<Props> = ({ deal, type, addressId }) => {
  const { t } = useTranslation('warehouse')
  const sellerAddressId = addressId || (deal?.sale_bid as DealBid)?.address?.id
  const customerAddressId = addressId || (deal?.purchase_bid as DealBid)?.address?.id
  const sellerWarehouse = useSelector(state => UserAddressSelectors.addressById(state, sellerAddressId))
  const customerWarehouse = useSelector(state => UserAddressSelectors.addressById(state, customerAddressId))
  const formatNumber = useFormatNumber()

  const formatVehicleType = useCallback(
    (vehicle_type: WarehouseDTO['vehicle_type']) =>
      vehicle_type?.map(vehicle => t(`vehicles:vehicleTypes.${vehicle}`))?.join(', ') || '—',
    [t],
  )

  const formatLoaderType = useCallback(
    (loader_type: WarehouseDTO['loader_type']) =>
      loader_type?.map(loader => t(`address:loaderTypes.${loader}`))?.join(', ') || '—',
    [t],
  )

  return (
    <>
      {type === 'loading' && (
        <Wrap>
          {sellerWarehouse.gates_height ? (
            <>
              <NoWrap>
                {formatNumber(sellerWarehouse.gates_height)} {t('common:m')}
              </NoWrap>
              ,&nbsp;
            </>
          ) : (
            ''
          )}
          <NoWrap>
            {sellerWarehouse.loader_type?.length ? formatLoaderType(sellerWarehouse.loader_type) : ''}
            {sellerWarehouse.loader_type?.length && sellerWarehouse.scales_capacity ? <>,&nbsp;</> : ''}
            {sellerWarehouse.scales_capacity ? formatNumber(sellerWarehouse.scales_capacity) : ''}
          </NoWrap>
          {sellerWarehouse.loader_type?.length || sellerWarehouse.scales_capacity ? <>,&nbsp;</> : ''}
          {sellerWarehouse.scales_capacity ? (
            <>
              <NoWrap>
                {formatNumber(sellerWarehouse.scales_capacity)} {t('common:t')}
              </NoWrap>
              ,&nbsp;
            </>
          ) : (
            ''
          )}
          {sellerWarehouse.scales_remoteness ? (
            <>
              <NoWrap>
                {formatNumber(sellerWarehouse.scales_remoteness)} {t('common:km')}
              </NoWrap>
              ,&nbsp;
            </>
          ) : (
            ''
          )}
          {sellerWarehouse.scales_length ? (
            <>
              <NoWrap>
                {formatNumber(sellerWarehouse.scales_length)} {t('common:m')}
              </NoWrap>
              ,&nbsp;
            </>
          ) : (
            ''
          )}
          {sellerWarehouse.volume_per_day ? (
            <>
              <NoWrap>
                {formatNumber(sellerWarehouse.volume_per_day)} {t('common:tDay')}
              </NoWrap>
              ,&nbsp;
            </>
          ) : (
            ''
          )}
          {sellerWarehouse.vehicle_type?.length ? (
            <>
              <NoWrap>{formatVehicleType(sellerWarehouse.vehicle_type)}</NoWrap>
              ,&nbsp;
            </>
          ) : (
            ''
          )}
          {sellerWarehouse.works_on_weekend === YesNoUnknown.yes ? (
            <>
              <NoWrap>{t('fields.works_on_weekend')}</NoWrap>
              ,&nbsp;
            </>
          ) : (
            ''
          )}
          {sellerWarehouse.car_delivery === YesNoUnknown.yes ? (
            <>
              <NoWrap>{t('fields.car_delivery')}</NoWrap>
              ,&nbsp;
            </>
          ) : (
            ''
          )}
          {sellerWarehouse.railway_delivery === YesNoUnknown.yes ? <NoWrap>{t('fields.railway_delivery')}</NoWrap> : ''}
        </Wrap>
      )}

      {type === 'unloading' && (
        <Wrap>
          <NoWrap>{formatVehicleType(customerWarehouse.vehicle_type)}</NoWrap>
          ,&nbsp;
          <NoWrap>{customerWarehouse.is_dump_truck ? t('common:yes') : t('common:no')}</NoWrap>
          ,&nbsp;
          {customerWarehouse.unloading_type ? (
            <>
              <NoWrap>{t(`vehicles:unloadingType.${customerWarehouse.unloading_type}`)}</NoWrap>
              ,&nbsp;
            </>
          ) : (
            ''
          )}
          {customerWarehouse.volume_per_day ? (
            <>
              <NoWrap>
                {formatNumber(customerWarehouse.volume_per_day)} {t('common:tDay')}
              </NoWrap>
              ,&nbsp;
            </>
          ) : (
            ''
          )}
          {sellerWarehouse.car_delivery === YesNoUnknown.yes ? (
            <>
              <NoWrap>{t('fields.car_delivery')}</NoWrap>
              ,&nbsp;
            </>
          ) : (
            ''
          )}
          {sellerWarehouse.railway_delivery === YesNoUnknown.yes ? <NoWrap>{t('fields.railway_delivery')}</NoWrap> : ''}
        </Wrap>
      )}
    </>
  )
}
