import { all, call, put, takeLatest } from 'redux-saga/effects'

import { WarehouseDTO } from 'modules/domain/types'
import UserAddressActions from 'modules/domain/userAddress/duck'
import * as managers from 'modules/domain/userAddress/managers'
import { RequestError } from 'modules/errors'

export const fetchList = function* ({ payload: userId }: ReturnType<typeof UserAddressActions.listRequested>) {
  try {
    const list = yield call(managers.getList, userId)
    yield put(UserAddressActions.listRequestSucceed(userId, list))
  } catch (err) {
    yield put(UserAddressActions.listRequestFailed(userId))
  }
}

export const addItem = function* ({ payload: [userId, dto] }: ReturnType<typeof UserAddressActions.addRequested>) {
  try {
    yield call(managers.addItem, userId, dto)
    yield put(UserAddressActions.listRequested(userId))
    yield put(UserAddressActions.addSucceed(userId))
  } catch (err) {
    const { type, errors } = RequestError.parseError(err)
    const detail = errors && Object.entries(errors)?.[0]?.[1]?.[0]
    yield put(UserAddressActions.addFailed(userId, type, detail))
  }
}

export const deleteItem = function* ({
  payload: [userId, addressId],
}: ReturnType<typeof UserAddressActions.deleteRequested>) {
  try {
    yield call(managers.deleteItem, userId, addressId)
    yield put(UserAddressActions.deleteSucceed(userId, addressId))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(UserAddressActions.deleteFailed(addressId, type, detail))
  }
}

export const editItem = function* ({
  payload: [userId, addressId, dto],
}: ReturnType<typeof UserAddressActions.editRequested>) {
  try {
    const item: WarehouseDTO = yield call(managers.editItem, userId, addressId, dto)
    yield put(UserAddressActions.editSucceed(addressId, item))
  } catch (err) {
    yield put(UserAddressActions.editFailed(addressId))
  }
}

const UserAddressSaga = function* () {
  yield all([
    takeLatest(UserAddressActions.listRequested.type, fetchList),
    takeLatest(UserAddressActions.addRequested.type, addItem),
    takeLatest(UserAddressActions.deleteRequested.type, deleteItem),
    takeLatest(UserAddressActions.editRequested.type, editItem),
  ])
}

export default UserAddressSaga
