import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, generatePath } from 'react-router-dom'

import { IconUser, Icons, Typography, defaultTheme } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import env from 'env'
import { getUserCompanyName, getUserPhone } from 'helpers/getUserData'
import useFormatNumber from 'hooks/useFormatNumber'
import useLocationFrom from 'hooks/useLocationFrom'
import useUserName from 'hooks/useUserName'
import { Bid as BidType } from 'modules/domain/bid/types'
import { PotentialBid } from 'modules/domain/potentialBid/types'
import { FormSection } from 'views/components/FormComponents/FormComponents'
import UserRoutes from 'views/pages/User/routes'
import { Phone } from 'views/ui/Phone/Phone'

const PriceQuantityWrapper = styled.div`
  display: flex;
  column-gap: 18px;
`

const PriceWrapper = styled.div`
  width: 120px;
`

const Label = styled.div`
  ${Typography.body2Default};
  color: ${({ theme }) => theme.color.onSurfaceMidEmphasys};
  margin-bottom: 4px;
`

const ValueBold = styled.div`
  ${Typography.body2Bold}
`

const Value = styled.div`
  ${Typography.body2Default}
`

const BidWrapper = styled.div`
  display: grid;
  gap: 24px;
`

const UserWrapper = styled.div`
  display: grid;
  gap: 4px;
`

const UserLink = styled.span`
  display: inline-flex;
  align-items: center;
  color: ${({ theme }) => theme.color.primary600};

  &:hover {
    text-decoration: underline;
  }

  & > div {
    height: 16px;
  }

  & > div > svg {
    height: 100%;
    display: flex;
    padding-left: 4px;

    path {
      fill: ${defaultTheme.color.primary600};
    }
  }

  & > svg {
    fill: currentColor;
    margin-right: 5px;
  }
`

const Parameters = styled.div`
  display: grid;
  row-gap: 10px;
  column-gap: 30px;
  grid-template-columns: 1fr 1fr;
  width: 315px;
`

const RowLabelValue = styled.div`
  display: flex;
  flex-direction: row;
`

const CreatorLink = styled(Link)`
  ${Typography.body2Bold};
  color: ${({ theme }) => theme.color.primary600};

  &:hover {
    text-decoration: underline;
  }
`

type Props = {
  bid: BidType | PotentialBid
}

export const Bid: React.FC<Props> = props => {
  const { t } = useTranslation(['bid', 'common'])
  const { bid } = props
  const isPurchase = bid.bid_type === 'purchase'
  const price = isPurchase ? bid.price_cpt : bid.price_exw
  const priceLabel = isPurchase ? t('common:price.cpt') : t('common:price.exw')
  const from = useLocationFrom()
  const formatNumber = useFormatNumber()
  const currency = env.REACT_APP_CURRENCY_SYMBOL
  const name = useUserName(bid.owner)
  const verifyIcon = bid?.owner?.profile?.is_verified ? <Icons.IconCheckMark /> : null

  return (
    <BidWrapper>
      <FormSection>
        <PriceQuantityWrapper>
          <PriceWrapper>
            <Label>
              {priceLabel} {t('common:per_bag')}
            </Label>
            <ValueBold>
              {currency} {formatNumber(price, true)}
            </ValueBold>
          </PriceWrapper>
          <PriceWrapper>
            <Label>{t('common:exw_tax_per_bag')}</Label>
            <ValueBold>{formatNumber(bid.tax_from_exw)}</ValueBold>
          </PriceWrapper>
          <PriceWrapper>
            <Label>
              {t('common:quantity')}, {t('common:bag')}
            </Label>
            <ValueBold>{formatNumber(bid.quantity_in_bags)}</ValueBold>
          </PriceWrapper>
          <PriceWrapper>
            <Label>{t('common:quantity')}</Label>
            <ValueBold>
              {formatNumber(bid.quantity)} {t('common:t')}
            </ValueBold>
          </PriceWrapper>
        </PriceQuantityWrapper>
        {bid.aground_imputs && bid.tax_regime && (
          <PriceQuantityWrapper>
            <PriceWrapper>
              <Label>{t('taxes.aground_imputs')}</Label>
              <ValueBold>{t(`common:${bid.aground_imputs}`)}</ValueBold>
            </PriceWrapper>
            <PriceWrapper>
              <Label>{t('taxes.tax_regime')}</Label>
              <ValueBold>{t(`taxes.${bid.tax_regime}`)}</ValueBold>
            </PriceWrapper>
          </PriceQuantityWrapper>
        )}
        {bid.bid_tag && (
          <PriceWrapper>
            <Label>{t('bidTag.label')}</Label>
            <ValueBold>{t(`bidTag.${bid.bid_tag}`)}</ValueBold>
          </PriceWrapper>
        )}
        <div>
          <Label>{t('common:address')}</Label>
          <Value>{bid.address?.address}</Value>
        </div>
      </FormSection>
      <FormSection title={t('form.createdBy')}>
        <CreatorLink to={{ pathname: generatePath(UserRoutes.Detail, { id: bid.created_by.id }), state: { from } }}>
          {bid.created_by.full_name}
        </CreatorLink>
      </FormSection>
      <FormSection title={t('common:user')}>
        <UserWrapper>
          <ValueBold>{getUserCompanyName(bid.owner)}</ValueBold>
          <RowLabelValue>
            <Label>{t('company:form.fields.inn')}</Label>
            <Value>
              &nbsp;
              {bid.owner?.profile?.company?.inn || ''}
            </Value>
            <Label>
              &nbsp;
              {t('company:form.fields.vat')}
            </Label>
            <Value>
              &nbsp;
              {t(`company:form.prepaymentValues.${bid.owner.profile?.company?.vat ? 'yes' : 'no'}`)}
            </Value>
          </RowLabelValue>
        </UserWrapper>
        <UserWrapper>
          <ValueBold>
            <Link to={{ pathname: generatePath(UserRoutes.Detail, { id: bid.owner.id }), state: { from } }}>
              <UserLink>
                <IconUser />
                {name}
                <div>{verifyIcon}</div>
              </UserLink>
            </Link>
          </ValueBold>
          <Value>
            <Phone phone={getUserPhone(bid.owner)} />
          </Value>
          <Value>{bid.owner.email || ''}</Value>
        </UserWrapper>
      </FormSection>
      <FormSection title={t('form.cropsParams')}>
        <Parameters>
          {bid.parameters.map(p => {
            if (!p.id) {
              return null
            }
            return (
              <React.Fragment key={p.id}>
                <Value>{p.parameter.name}</Value>
                <ValueBold>
                  {p.condition_label} {formatNumber(p.parameter_value)}
                </ValueBold>
              </React.Fragment>
            )
          })}
        </Parameters>
      </FormSection>
    </BidWrapper>
  )
}
