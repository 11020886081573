import { useSelector } from 'react-redux'

import { Progress, useAction, useDidMount, usePageQuery, useQuery } from '@agro-club/agroclub-shared'
import { format } from 'date-fns'
import { isEmpty } from 'ramda'

import { SERVER_DATE_FORMAT } from 'modules/constants'
import CallStatisticsActions, { ListRequestedParams } from 'modules/domain/callStatistics/duck'
import CallStatisticsSelectors from 'modules/domain/callStatistics/selectors'
import {
  AverageStatistics,
  CallStatistics,
  CallStatisticsListRequestFilter,
  CallStatisticsListRequestSorting,
  GeneralStatistics,
} from 'modules/domain/callStatistics/types'
import { ResourceHook } from 'modules/types'

export const useCallStatisticsFilter = () => {
  const query = useQuery()
  const call_start_after = query.get('call_start_after')
  const call_start_before = query.get('call_start_before')
  const sort_field: keyof CallStatistics = query.get('sort_field') as keyof CallStatistics
  const sort_reversed = query.get('sort_reversed')

  const filter: CallStatisticsListRequestFilter = {
    ...(call_start_after && { call_start_after }),
    ...(call_start_before && { call_start_before }),
  }

  const sorting: CallStatisticsListRequestSorting = {
    ...(sort_field && { sort_field }),
    ...(sort_reversed && { sort_reversed: sort_reversed === 'true' }),
  }
  return { filter, sorting }
}

export const useCallStatisticsList: ResourceHook<CallStatistics[]> = () => {
  const progress = useSelector(CallStatisticsSelectors.listFetchProgress)
  const list = useSelector(CallStatisticsSelectors.callStatisticsList)
  const page = usePageQuery()
  let { filter } = useCallStatisticsFilter()
  const { sorting } = useCallStatisticsFilter()

  const newDate = format(new Date(), SERVER_DATE_FORMAT)

  if (!filter.call_start_after && !filter.call_start_before) {
    filter = { ...filter, call_start_after: newDate, call_start_before: newDate }
  }

  const params: ListRequestedParams = {
    ...(!isEmpty(filter) && { filter }),
    ...(!isEmpty(sorting) && { sorting }),
    ...(page && { page }),
  }

  const fetchAction = useAction(CallStatisticsActions.listRequested, params)

  useDidMount(fetchAction)

  return [progress, list]
}

export const useCallStatisticsAverage: () => [Progress | undefined, AverageStatistics] = () => {
  const averageStatistics = useSelector(CallStatisticsSelectors.callStatisticsAverage)
  const meta = useSelector(CallStatisticsSelectors.callStatisticsAverageFetchProgress)
  const fetchAction = useAction(CallStatisticsActions.statisticsAverageRequested)

  useDidMount(fetchAction)

  return [meta, averageStatistics]
}

export const useCallStatisticsGeneral: () => [Progress | undefined, GeneralStatistics] = () => {
  const generalStatistics = useSelector(CallStatisticsSelectors.callStatisticsGeneral)
  const meta = useSelector(CallStatisticsSelectors.callStatisticsGeneralFetchProgress)
  const fetchAction = useAction(CallStatisticsActions.statisticsGeneralRequested)

  useDidMount(fetchAction)
  return [meta, generalStatistics]
}
