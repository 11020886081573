import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Accordion, AccordionContent, AccordionHeader, AccordionItem } from '@agro-club/agroclub-shared'

import { CommentType } from 'modules/domain/comment/types'
import UserSelectors from 'modules/domain/user/selectors'
import { ProfileType } from 'modules/domain/user/types'
import { endpoints } from 'modules/endpoints'
import { Comments } from 'views/components/Comments/Comments'
import { RightPanelComments } from 'views/components/Comments/styled'
import { Header } from 'views/components/UserRightPanel/AccordionHeader/AccordionHeader'
import { Addresses, AddressesSummary } from 'views/components/UserRightPanel/Addresses/AddressesBlock'
import { Documents, DocumentsSummary } from 'views/components/UserRightPanel/Documents'
import { Files, FilesSummary } from 'views/components/UserRightPanel/Files'
import { Container } from 'views/components/UserRightPanel/styled'
import { UserCalls, UserCallsSummary } from 'views/components/UserRightPanel/UserCalls/UserCallsBlock'
import { UserContact, UserContactSummary } from 'views/components/UserRightPanel/UserContact/UserContactBlock'
import { UserData, UserSummary } from 'views/components/UserRightPanel/UserDataBlock'
import { UserTasks, UserTasksSummary } from 'views/components/UserRightPanel/UserTasks/UserTasksBlock'

interface Props {
  context?: string
}

export const UserRightBlock: React.FC<Props> = ({ context }) => {
  const { t } = useTranslation(['user', 'common'])
  const { id } = useParams<{ id: string }>()
  const [commentsSummary, setCommentsSummary] = useState<string>('')
  const user = useSelector(state => UserSelectors.user(state, id))
  const isCarrier = user?.profile?.profile_type === ProfileType.carrier

  const options = useMemo<{ title: string; value: string; isOpened?: boolean }[]>(
    () =>
      [
        { value: 'comments', title: t('common:comments'), isOpened: true },
        { value: 'tasks', title: t('task:tasks') },
        { value: 'user', title: t('user') },
        { value: 'documents', title: t('common:documents') },
        { value: 'addresses', title: t('common:addresses') },
        { value: 'contacts', title: t('userContact:contacts') },
        { value: 'calls', title: t('common:calls') },
        { value: 'files', title: t('common:files') },
      ].filter(({ value }) => !isCarrier || !['tasks', 'contacts'].includes(value)),
    [isCarrier, t],
  )

  const components = {
    comments: (
      <RightPanelComments>
        <Comments
          id={id}
          type={CommentType.user}
          url={endpoints.userComments(id)}
          maxHeight={400}
          popSummary={setCommentsSummary}
        />
      </RightPanelComments>
    ),
    tasks: <UserTasks />,
    user: <UserData />,
    addresses: <Addresses />,
    files: <Files />,
    contacts: <UserContact />,
    calls: <UserCalls />,
    documents: <Documents context={context} />,
  }

  const summary = {
    comments: <div>{commentsSummary ? `${t('common:last')}: ${commentsSummary}` : t('common:no')}</div>,
    tasks: <UserTasksSummary />,
    user: <UserSummary />,
    addresses: <AddressesSummary />,
    files: <FilesSummary />,
    contacts: <UserContactSummary />,
    calls: <UserCallsSummary />,
    documents: <DocumentsSummary context={context} />,
  }

  return (
    <Container>
      <Accordion single>
        {options.map(option => (
          <AccordionItem key={option.title} id={option.title} isOpened={Boolean(option?.isOpened)}>
            <AccordionHeader>
              <Header summary={summary[option.value]}>{option.title}</Header>
            </AccordionHeader>
            <AccordionContent>{components[option.value]}</AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </Container>
  )
}
