import { Typography, defaultTheme } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const FieldWrapper = styled.div`
  display: grid;
  row-gap: 16px;
`

export const TwoFields = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 140px 300px;
  grid-gap: 50px;
  align-items: center;
  ${Typography.body2Default};

  & > label {
    color: ${defaultTheme.color.textSecondary};
  }
`

export const FileField = styled.div`

  ${Typography.body2Default};


  color: ${defaultTheme.color.textSecondary};
}
`
