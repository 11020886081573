import React from 'react'
import { useSelector } from 'react-redux'

import { Progress } from '@agro-club/agroclub-shared'

import DashboardSelectors from 'modules/domain/dashboard/selectors'
import { Spinner } from 'views/components/CommonTableComponents/CommonTableComponents'
import { DealItem } from 'views/pages/Dashboard/DealItem'

export const Responses: React.FC = () => {
  const responses = useSelector(DashboardSelectors.responsesList)
  const progress = useSelector(DashboardSelectors.responsesFetchProgress)
  return progress === Progress.WORK ? (
    <Spinner />
  ) : (
    <>
      {responses.map(task => (
        <DealItem key={task.id} task={task} />
      ))}
    </>
  )
}
