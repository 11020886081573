import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link, generatePath, useHistory, useLocation, useParams } from 'react-router-dom'

import { Button, Progress, useHelmet } from '@agro-club/agroclub-shared'

import { getUserCompanyName, getUserPhone } from 'helpers/getUserData'
import { useNotificationProgress } from 'hooks/useNotificationProgress'
import useUserName from 'hooks/useUserName'
import { useUser } from 'modules/domain/user/hooks'
import UserSelectors from 'modules/domain/user/selectors'
import { ProfileType } from 'modules/domain/user/types'
import { LocationStateFrom } from 'types/location'
import { TaskModal } from 'views/components/TaskModal/TaskModal'
import { UserRightBlock } from 'views/components/UserRightPanel/UserRightBlock'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import CarrierRoutes from 'views/pages/Carrier/routes'
import { useUserGoBack } from 'views/pages/User/hooks'
import UserRoutes from 'views/pages/User/routes'
import { Beside } from 'views/pages/User/UserDetail/styled'
import { UserColumnBoard } from 'views/pages/User/UserDetail/UserColumnBoard/UserColumnBoard'
import * as Header from 'views/ui/Header/Header'
import { Phone } from 'views/ui/Phone/Phone'
import VerifyUserStatus from 'views/ui/VerifyUserStatus/VerifyUserStatus'

export function UserDetail() {
  const location = useLocation<LocationStateFrom>()
  const { t } = useTranslation('user')
  const { id } = useParams<{ id: string }>()
  const [progress, user] = useUser(id)
  const userName = useUserName(user)
  const companyName = getUserCompanyName(user)
  const phone = getUserPhone(user)
  const goBack = useUserGoBack()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const blockProgress = useSelector(UserSelectors.userBlockProgress)
  const isCarrier = user?.profile?.profile_type === ProfileType.carrier
  const isActive = user?.is_active
  const isVerified = user?.profile?.is_verified
  const isVerifyCoordinator = user?.profile?.is_verified_by_coordinator
  const isVerifySecurity = user?.profile?.is_verified_by_security
  const showUserStatus =
    user?.profile?.profile_type === ProfileType.seller || user?.profile?.profile_type === ProfileType.customer
  const history = useHistory()

  useEffect(() => {
    if (isCarrier && id) {
      history.push(generatePath(CarrierRoutes.Detail, { id }))
    }
  }, [isCarrier, history, id])

  useHelmet({ title: userName })

  useNotificationProgress(blockProgress, t('blockedSuccessful'))

  return (
    <>
      <Layout.Header hasBottomBorder>
        <Header.Root
          onClickBack={goBack}
          rightBlock={
            <Header.ButtonWrapper>
              <Link
                to={{
                  pathname: generatePath(UserRoutes.BidPrice, { id }),
                  state: { from: location.state?.from ? { ...location.state?.from } : undefined },
                }}
              >
                <Button filled intent="primary" size="small">
                  {t('priceUpdating')}
                </Button>
              </Link>
              <Button filled intent="primary" size="small" onClick={() => setIsOpen(true)}>
                {t('common:setTask')}
              </Button>

              <Link
                to={{
                  pathname: generatePath(UserRoutes.Edit, { id }),
                  state: { from: location.state?.from ? { ...location.state?.from } : undefined },
                }}
              >
                <Button filled intent="primary" size="small">
                  {t('common:edit')}
                </Button>
              </Link>
            </Header.ButtonWrapper>
          }
        >
          <Header.Title noMargin compact title={userName}>
            {showUserStatus && (
              <VerifyUserStatus
                isActive={isActive}
                isVerified={isVerified}
                isVerifyCoordinator={isVerifyCoordinator}
                isVerifySecurity={isVerifySecurity}
              />
            )}
          </Header.Title>
          <Header.SubTitle>
            {companyName} / <Phone phone={phone} />
          </Header.SubTitle>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <Beside>
          <UserColumnBoard userId={id} />
          <UserRightBlock context="user" />
        </Beside>
      </Layout.Content>

      {progress === Progress.SUCCESS && (
        <TaskModal goBack={goBack} user={user} isOpen={isOpen} close={() => setIsOpen(false)} />
      )}
    </>
  )
}
