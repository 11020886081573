import { Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const SeekBarStyledWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

export const SeekBarStyled = styled.div`
  cursor: pointer;
  width: 100%;
  background: #8ac5ff;
  box-sizing: unset;
  border-radius: 0.5px;
  display: block;
  height: 2px;
  border-top: 4px solid #e2f1ff;
  border-bottom: 4px solid #e2f1ff;
  margin: 0 4px;
`

export const SeekBarTick = styled.div<{ isActive?: boolean }>`
  background-color: #037bff;
  height: 100%;
  position: relative;
  :after {
    content: ${({ isActive }) => (isActive ? '""' : 'unset')};
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: #037bff;
    position: absolute;
    top: -3px;
    right: -6px;
  }
`

export const PlayerWrapper = styled.div`
  display: grid;
  column-gap: 6px;
  grid-template-columns: 20px auto;
  align-items: center;
  background: #e2f1ff;
  border-radius: 8px;
  padding: 10px;
`

export const Time = styled.div`
  ${Typography.caption2Bold}
  font-variant-numeric: tabular-nums;
`

export const Button = styled.div<{ isPlaying?: boolean }>`
  background: #b9dbff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  & > svg {
    fill: #037bff;
    ${({ isPlaying }) => (isPlaying ? '' : 'transform: translateX(1px);')}
  }
`
