import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import LogisticsDealSelectors from 'modules/domain/logisticsDeal/selectors'
import { LogisticsBid } from 'modules/domain/logisticsDeal/types'
import { User } from 'modules/domain/user/types'
import { WarehouseModal } from 'views/components/WarehouseModal/WarehouseModal'
import { WarehouseProps } from 'views/components/WarehouseProps/WarehouseProps'

const Headline = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0 4px 0;
  ${Typography.headlineSemibold}
`

export const Conditions: React.FC = () => {
  const { t } = useTranslation('warehouse')
  const { id: dealId } = useParams<{ id: string }>()
  const deal = useSelector(state => LogisticsDealSelectors.item(state, dealId))
  const saleBid = deal?.sale_bid as LogisticsBid
  const purchaseBid = deal?.purchase_bid as LogisticsBid
  const saleAddressId = saleBid?.address.id
  const purchaseAddressId = purchaseBid?.address.id
  const seller = saleBid?.owner as User
  const customer = purchaseBid?.owner as User

  return (
    <>
      <Headline>
        {t('loading')} <WarehouseModal userId={seller.id} addressId={saleAddressId} type="loading" />
      </Headline>
      <WarehouseProps type="loading" deal={deal} />
      <Headline>
        {t('unloading')} <WarehouseModal userId={customer.id} addressId={purchaseAddressId} type="unloading" />
      </Headline>
      <WarehouseProps type="unloading" deal={deal} />
    </>
  )
}
