import { Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const Summary = styled.div`
  ${Typography.body2Default};
  color: ${props => props.theme.color.textSecondary};
  margin-left: 8px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`

export const Flex = styled.div`
  ${Typography.body1Bold};
  display: flex;
  align-items: center;
`
