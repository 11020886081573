import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link, generatePath, useLocation, useParams } from 'react-router-dom'

import { AddButton, IconArrowRight, Progress, useAction, useHelmet } from '@agro-club/agroclub-shared'

import { useNotificationProgress } from 'hooks/useNotificationProgress'
import { useLogisticStatuses } from 'modules/domain/collection/hooks'
import LogisticsDealActions from 'modules/domain/logisticsDeal/duck'
import { useLogisticsDeal } from 'modules/domain/logisticsDeal/hooks'
import LogisticsDealSelectors from 'modules/domain/logisticsDeal/selectors'
import { useTripList } from 'modules/domain/trip/hooks'
import { LocationStateFrom } from 'types/location'
import { ChangeDealStatus } from 'views/components/ChangeDealStatus/ChangeDealStatus'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import StickyFooterLayout from 'views/layouts/StickyFooterLayout/StickyFooterLayout'
import { useLogisticsDetailBack } from 'views/pages/Logistics/hooks'
import LogisticsTripsBoard from 'views/pages/Logistics/LogisticsDetail/LogisticsTripsBoard'
import * as S from 'views/pages/Logistics/LogisticsDetail/styled'
import { LogisticsRightPanel } from 'views/pages/Logistics/LogisticsRightPanel/LogisticsRightPanel'
import LogisticsRoutes from 'views/pages/Logistics/routes'
import * as Header from 'views/ui/Header/Header'
import { Phone } from 'views/ui/Phone/Phone'
import { UserLink } from 'views/ui/UserLink/UserLink'

export function LogisticsDetail() {
  const { id: dealId } = useParams<{ id: string }>()
  const { t } = useTranslation('logistics')
  const location = useLocation<LocationStateFrom>()

  const [dealProgress, deal] = useLogisticsDeal(dealId)
  const [tripsProgress, trips] = useTripList(dealId)

  const goBack = useLogisticsDetailBack()
  useHelmet({ title: `${t('logisticsByDeal')} - ${deal?.product?.title}` })

  const saleOwner = deal?.sale_bid.owner
  const saleCompanyName = saleOwner?.profile?.company.name ?? ''
  const saleOwnerPhone = saleOwner?.phone ?? ''

  const purchaseOwner = deal?.purchase_bid.owner
  const purchaseCompanyName = purchaseOwner?.profile?.company.name ?? ''
  const purchaseOwnerPhone = purchaseOwner?.phone ?? ''

  let content
  if (dealProgress === Progress.WORK || tripsProgress === Progress.WORK || !deal || !trips) {
    content = <ItemLoadingLayout id={dealId} onBack={goBack} />
  } else {
    content = <LogisticsTripsBoard trips={trips} progress={tripsProgress} />
  }

  const changeStatusProgress = useSelector(state => LogisticsDealSelectors.changeStatusProgress(state, dealId))
  const changeStatusAction = useAction(LogisticsDealActions.changeStatusRequested)
  const [, statuses] = useLogisticStatuses()

  useNotificationProgress(changeStatusProgress, t('changeStatusSuccess'))

  const logistics_status = deal?.logistics_status

  const currentIdx = useMemo(
    () => statuses.findIndex(status => status.slug === logistics_status),
    [statuses, logistics_status],
  )

  const currentStatus = currentIdx !== -1 ? statuses[currentIdx] : undefined
  const nextStatus = currentIdx !== -1 ? statuses[currentIdx + 1] : undefined

  return (
    <>
      <Layout.Header hasBottomBorder>
        <Header.Root
          onClickBack={goBack}
          rightBlock={
            <Header.ButtonWrapper>
              {deal && currentStatus && (
                <ChangeDealStatus
                  changeStatusAction={changeStatusAction}
                  changeStatusProgress={changeStatusProgress}
                  currentStatus={currentStatus}
                  nextStatus={nextStatus}
                  id={dealId}
                />
              )}
              <Link
                to={{
                  pathname: generatePath(LogisticsRoutes.Cars, { id: dealId }),
                  state: { from: location.state?.from ? { ...location.state?.from } : undefined },
                }}
              >
                <AddButton text={t('addCarToDeal')} />
              </Link>
            </Header.ButtonWrapper>
          }
        >
          <S.MultiColumnsHeader>
            <div>
              <Header.Title compact title={saleCompanyName} />
              <Header.SubTitle>
                {!!saleOwner && (
                  <>
                    <UserLink user={saleOwner} />
                    {' / '}
                    <Phone phone={saleOwnerPhone} />
                  </>
                )}
              </Header.SubTitle>
            </div>
            <div>
              <IconArrowRight />
            </div>
            <div>
              <Header.Title compact title={purchaseCompanyName} />
              <Header.SubTitle>
                {!!purchaseOwner && (
                  <>
                    <UserLink user={purchaseOwner} />
                    {' / '}
                    <Phone phone={purchaseOwnerPhone} />
                  </>
                )}
              </Header.SubTitle>
            </div>
          </S.MultiColumnsHeader>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <StickyFooterLayout body={content} drawer={<LogisticsRightPanel />} bodyMaxWidth={1360} noPadding />
      </Layout.Content>
    </>
  )
}
