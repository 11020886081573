import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { RangeDatePicker, useAction } from '@agro-club/agroclub-shared'

import { formatFilterDateTime } from 'helpers/formatFilterDate'
import useDateFnsLocale from 'hooks/useDateFnsLocale'
import CallsActions from 'modules/domain/calls/duck'
import CallsSelectors from 'modules/domain/calls/selectors'

export function CallStartFilter() {
  const { t } = useTranslation('common')
  const filterUpdated = useAction(CallsActions.filterUpdated)
  const { call_start_before, call_start_after, ...restFilter } = useSelector(CallsSelectors.filter)
  const locale = useDateFnsLocale()

  const handleChange = ([startDate, endDate]: [string, string]) => {
    filterUpdated({
      ...restFilter,
      call_start_after: formatFilterDateTime(startDate),
      call_start_before: formatFilterDateTime(endDate),
    })
  }

  return (
    <div style={{ width: 234 }}>
      <RangeDatePicker
        onChange={handleChange}
        start={call_start_after || ''}
        end={call_start_before || ''}
        showCalendarIcon
        reactDatePickerProps={{
          dateFormat: 'P',
          placeholderText: t('rangeDatePlaceholder'),
          locale,
        }}
      />
    </div>
  )
}
