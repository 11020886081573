import { Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const Nowrap = styled.div`
  white-space: nowrap;
`

export const UserContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const UserContactTableWrapper = styled.div`
  height: 100%;
`

export const Ellipsis = styled.div`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`

export const PlayerWrapper = styled.div`
  width: 132px;
  & > div {
    background: transparent;
  }
  & div {
    border-top-color: ${props => props.theme.color.onPrimaryLight};
    border-bottom-color: ${props => props.theme.color.onPrimaryLight};
  }
`

export const TableBodyRow = styled.tr`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-left: 1px solid ${props => props.theme.color.secondary50};
  border-right: 1px solid ${props => props.theme.color.secondary50};
  border-top: 1px solid ${props => props.theme.color.secondary50};
  padding: 0 13px;
  height: 44px;
  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom: 1px solid ${props => props.theme.color.secondary50};
  }
`
export const SmallText = styled.div`
  ${Typography.caption2Default};
  color: ${props => props.theme.color.textSecondary};
`

export const Empty = styled.tr`
  ${Typography.body2Default};
  color: ${({ theme }) => theme.color.onSurfaceMidEmphasys};
`

export const Col = styled.div`
  display: flex;
  & svg {
    margin-right: 20px;
    width: 16px;
    height: 16px;
  }
`

export const PaginationWrapper = styled.div`
  & > div {
    margin: 5px 0 0 -3px;
  }
`
