import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { CheckboxSelect } from '@agro-club/agroclub-shared'

import { useProducts } from 'modules/domain/collection/hooks'
import { Filter } from 'views/components/TableFilters/TableFilters'

type Props = {
  onChange: (id: string) => void
  selectedIds?: string[]
  onClear: () => void
}

export const ProductFilter: React.FC<Props> = props => {
  const { onChange, onClear, selectedIds = [] } = props
  const [progress, products] = useProducts()
  const { t } = useTranslation('bid')

  const isSome = selectedIds.length > 1

  const options = useMemo(
    () =>
      products
        ?.slice()
        .sort((a, b) => a.order - b.order)
        .map(p => ({ id: p.slug, title: p.title })) || [],
    [products],
  )

  return (
    <Filter title={t('list.tableHeaders.product')}>
      <CheckboxSelect
        progress={progress}
        placeholder={isSome ? t('someCrops') : t('allCrops')}
        options={options}
        selected={selectedIds}
        onChange={onChange}
        size="small"
        additionalStyles={{
          control: {
            width: 186,
          },
        }}
        onClear={onClear}
        singleSelected={selectedIds.length === 1 ? products?.find(p => p.slug === selectedIds[0])?.title : undefined}
        isSearchable
      />
    </Filter>
  )
}
