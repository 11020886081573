import { Flag } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const OptionStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const FlagStyled = styled(Flag)`
  margin-right: 5px;
`
