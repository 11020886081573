import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Button, IconAdd } from '@agro-club/agroclub-shared'

import { useNotificationProgress } from 'hooks/useNotificationProgress'
import { useUserAddress } from 'modules/domain/userAddress/hooks'
import UserAddressSelectors from 'modules/domain/userAddress/selectors'
import { AddressItem } from 'views/components/UserRightPanel/Addresses/AddressItem/AddressBlockItem'
import { AddressList, AddressesWrapper } from 'views/components/UserRightPanel/Addresses/styled'
import { AddressModal } from 'views/components/UserRightPanel/AddressModal'
import { ButtonWrapper } from 'views/components/UserRightPanel/UserContact/styled'

export const AddressesSummary: React.FC = () => {
  const { t } = useTranslation(['user', 'common'])
  const { id } = useParams<{ id: string }>()
  const list = useSelector(state => UserAddressSelectors.addressesByUserId(state, id))
  if (list.length === 1) {
    return <div>{list[0].address}</div>
  }
  return <div>{list.length || t('common:no')}</div>
}

export const Addresses: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const [, addresses] = useUserAddress(id)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { t } = useTranslation('user')
  const addProgress = useSelector(state => UserAddressSelectors.addProgress(state, id))
  const addErrorDetail = useSelector(UserAddressSelectors.addErrorDetail)

  useNotificationProgress(addProgress, undefined, addErrorDetail)

  return (
    <AddressesWrapper>
      <AddressList>
        {addresses.map(address => (
          <AddressItem key={address.id} address={address} userId={id} />
        ))}
      </AddressList>
      <ButtonWrapper>
        <Button filled intent="action" size="small" onClick={() => setIsOpen(true)}>
          <IconAdd />
          {t('form.addAddress')}
        </Button>
      </ButtonWrapper>
      <AddressModal userId={id} isOpen={isOpen} close={() => setIsOpen(false)} />
    </AddressesWrapper>
  )
}
