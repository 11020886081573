import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { generatePath } from 'react-router-dom'
import { Row, useTable } from 'react-table'

import {
  AdvancedHeadCell,
  Table,
  TableBody,
  TableBodyRow,
  TableHead,
  TableHeadRow,
  TableNoData,
  useAction,
  usePersistentScroll,
} from '@agro-club/agroclub-shared'

import useLocationFrom from 'hooks/useLocationFrom'
import { LIST_PAGE_SIZE } from 'modules/constants'
import { Cars } from 'modules/domain/cars/types'
import CarTripActions from 'modules/domain/carTrip/duck'
import { useCarTripList } from 'modules/domain/carTrip/hooks'
import CarTripSelectors from 'modules/domain/carTrip/selectors'
import { CarTrip } from 'modules/domain/carTrip/types'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import { RelativeTableBodyCell, TableCellLink } from 'views/components/CommonTableComponents/CommonTableComponents'
import useCarTripsColumns from 'views/pages/Cars/CarsDetail/useCarTripsColumns'
import LogisticsRoutes from 'views/pages/Logistics/routes'

const CarsSortableHeadCell = AdvancedHeadCell<keyof Cars>()

type Props = {
  carId: string
}

const getPathname = (row: Row<CarTrip>) => {
  const dealId = row?.original?.deal
  return dealId ? generatePath(LogisticsRoutes.Detail, { id: dealId }) : ''
}

const CarTripsBoard: React.FC<Props> = ({ carId }) => {
  const { t } = useTranslation(['cars', 'common'])
  const from = useLocationFrom()

  const [progress, trips] = useCarTripList(carId)

  const listRequested = useAction(CarTripActions.listRequested)
  const page = useSelector(CarTripSelectors.page)
  const total = useSelector(CarTripSelectors.total)
  const pages = useSelector(CarTripSelectors.pages)

  const visibleColumns = useCarTripsColumns()

  const setPage = useCallback(
    num => {
      listRequested({ carId, page: num })
    },
    [carId, listRequested],
  )

  const { columns, rows, prepareRow } = useTable<CarTrip>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore weird issue with react-table typings — having more then 26 fields in type causes TS error
    columns: visibleColumns,
    data: trips as CarTrip[],
  })

  const { scrollRef } = usePersistentScroll('carsList')

  return (
    <TComponents.Wrapper>
      <Table
        total={total}
        pages={pages}
        pageSize={LIST_PAGE_SIZE}
        currentPage={page}
        onSetPage={setPage}
        ref={scrollRef}
      >
        <TableHead>
          <TableHeadRow>
            {columns.map(column => (
              <CarsSortableHeadCell
                zIndex={1}
                key={column.getHeaderProps().key}
                id={column.id as keyof Cars}
                onChange={() => null /* sortingUpdated */}
                width={column.width}
              >
                {column.render('Header')}
              </CarsSortableHeadCell>
            ))}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map(row => {
            prepareRow(row)
            const { key, ...props } = row.getRowProps()
            const pathname = getPathname(row)

            return (
              <TableBodyRow key={key} {...props}>
                {row.cells.map(cell => {
                  const { key, ...props } = cell.getCellProps()
                  return (
                    <RelativeTableBodyCell key={key} {...props}>
                      {!!pathname && <TableCellLink to={{ pathname, state: { from } }} />}
                      {cell.render('Cell')}
                    </RelativeTableBodyCell>
                  )
                })}
              </TableBodyRow>
            )
          })}
          <TableNoData
            progress={progress}
            isEmpty={!rows.length}
            colSpan={visibleColumns.length}
            loading={<TComponents.Spinner />}
          >
            <div>{t('list.emptyMsg')}</div>
          </TableNoData>
        </TableBody>
      </Table>
    </TComponents.Wrapper>
  )
}

export default CarTripsBoard
