import React, { VFC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { IconArrowDown } from '@agro-club/agroclub-shared'

import { useUserPotentialBidList } from 'modules/domain/potentialBid/hooks'
import { PotentialBidItem } from 'views/pages/User/UserDetail/UserColumnBoard/PotentialBidItem'
import { ToggleArchived } from 'views/styled/ToggleArchived'

export const PotentialBids: VFC = () => {
  const { id: userId } = useParams<{ id: string }>()
  const [open, setOpen] = useState<boolean>(false)
  const [, items] = useUserPotentialBidList(userId)
  const { t } = useTranslation('bid')
  if (!items || !items.length) {
    return null
  }
  return (
    <>
      <ToggleArchived
        $open={open}
        onClick={() => {
          setOpen(prev => !prev)
        }}
      >
        {open ? t('hidePotential') : t('showPotential')} <IconArrowDown />
      </ToggleArchived>
      {open && items.map(bid => <PotentialBidItem key={`potential_${bid.id}`} {...{ bid }} />)}
    </>
  )
}
