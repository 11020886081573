import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Progress, SimpleSelect, useAction } from '@agro-club/agroclub-shared'

import DealActions from 'modules/domain/deal/duck'
import { usePurchaseSpecification } from 'modules/domain/deal/hooks'
import DealSelectors from 'modules/domain/deal/selectors'
import { Deal } from 'modules/domain/deal/types'
import SpecificationSelectors from 'modules/domain/specification/selectors'
import { AddSpecification } from 'views/components/AddSpecification/AddSpecification'
import * as S from 'views/pages/Deal/DealEdit/styled'

export type Props = {
  deal: Deal
}

export const DealSpecification: React.FC<Props> = ({ deal }) => {
  const { t } = useTranslation('user')
  const bindingSpecification = useAction(DealActions.bindSpecificationRequested)
  const fetchAction = useAction(DealActions.specificationsForPurchaseRequested)
  const specificationAddProgress = useSelector(SpecificationSelectors.specificationAddProgress)
  const dealId = deal?.id
  const ownerId = deal?.purchase_bid?.owner?.id
  const productSlug = deal?.product?.slug
  const specificationPurchase = useSelector(state => DealSelectors.specificationPurchase(state, dealId))
  const [value, setValue] = useState(Number(specificationPurchase?.id) || [])

  useEffect(() => {
    specificationAddProgress === 'WORK' ? fetchAction(dealId, ownerId, productSlug) : null
  }, [specificationAddProgress, dealId, fetchAction, ownerId, productSlug])

  const [progress, list] = usePurchaseSpecification(dealId, ownerId, productSlug)

  const specificationsList = useMemo(() => {
    if (progress === Progress.SUCCESS) {
      return (
        list?.map(el => ({
          id: el.id,
          title: t('specification:specificationNumber', { number: el.number, date: el.signed_date }),
        })) || []
      )
    }
    return []
  }, [list, progress, t])

  const changeBindingHandler = useMemo(
    () => (dealId: string, specificationId: string) => {
      bindingSpecification(dealId, specificationId)
      setValue(() => Number(specificationId))
    },
    [bindingSpecification],
  )

  return (
    <S.SpecificationWrapper>
      <S.CardHeaderTitle>{t('specification:specification')}</S.CardHeaderTitle>
      <S.SpecificationContent>
        <S.SpecificationLabel>{t('profile_type.customer')}</S.SpecificationLabel>
        <div>
          <SimpleSelect
            progress={progress}
            additionalStyles={{ container: { width: '250px' } }}
            size="small"
            onChange={specificationId => changeBindingHandler(dealId, String(specificationId))}
            options={specificationsList}
            value={value}
          />

          <div>
            <AddSpecification />
          </div>
        </div>
      </S.SpecificationContent>
    </S.SpecificationWrapper>
  )
}
