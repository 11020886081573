import { IconExit, IconUser, Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  padding: 16px 0;
  border-top: ${({ theme }) => `1px solid ${theme.color.outlineMid}`};
  justify-content: center;
`

export const Name = styled.div`
  margin-right: 12px;
  width: min-content;
  ${Typography.footnoteDefault}
  color: ${({ theme }) => theme.color.onPrimaryDark}
`

export const LogoutButton = styled.div`
  display: flex;
  align-items: center;
`

export const StyledExit = styled(IconExit)`
  margin: auto;
  width: 18px;
  height: 18px;
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;
  opacity: 1;
  &:hover {
    opacity: 0.7;
  }
`

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  margin-left: 12px;
  cursor: default;
`

export const StyledIconUser = styled(IconUser)`
  width: 36px;
  height: 36px;
  fill: ${({ theme }) => theme.color.secondary200};
  cursor: pointer;
`
