import React, { VFC, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button, Progress, SimpleSelect, useAction } from '@agro-club/agroclub-shared'
import { FormikContextType } from 'formik'

import env from 'env'
import useFormatNumber from 'hooks/useFormatNumber'
import { useNotificationProgress } from 'hooks/useNotificationProgress'
import usePrevious from 'hooks/usePrevious'
import useTonsAndBags, { bagsToTons, tonsToBags } from 'hooks/useTonsAndBags'
import DealActions from 'modules/domain/deal/duck'
import DealSelectors from 'modules/domain/deal/selectors'
import { Deal, DealType } from 'modules/domain/deal/types'
import {
  DealDetailNumberInput,
  Fields,
  Footer,
  Key,
  LongField,
  ValueField,
} from 'views/pages/Deal/DealEdit/DealEditModal/styled'
import { DealEditableFields } from 'views/pages/Deal/DealEdit/types'
import { CancelButton } from 'views/styled/common'
import { FormItem } from 'views/ui/FormItem/FormItem'
import { ModalCommon } from 'views/ui/ModalCommon/ModalCommon'
import { NumberInput } from 'views/ui/NumberInput/NumberInput'

type Props = {
  formik: FormikContextType<DealEditableFields>
  isOpen: boolean
  close: () => void
  deal: Deal
}

export const DealEditModal: VFC<Props> = ({ formik, close: closeProps, isOpen, deal }) => {
  const { id } = deal
  const { t } = useTranslation('deal')
  const updateDealProgress = useSelector(DealSelectors.updateProgress)
  const meta = useSelector(state => DealSelectors.meta(state, id))
  const resetErrors = useAction(DealActions.resetMetaErrors, id)
  const prevUpdateDealProgress = usePrevious(updateDealProgress)
  const currency = env.REACT_APP_CURRENCY_SYMBOL
  const errorFields = meta.updateErrorFields
  const formatNumber = useFormatNumber()
  const [quantityBags, quantityTonsToBagsCallback, quantityBagsToTonsCallback] = useTonsAndBags(formik.values.quantity)

  const [priceLogistics, priceLogisticsTonsToBagsCallback, priceLogisticsBagsToTonsCallback] = useTonsAndBags(
    formik.values.price_logistics,
  )

  const close = useCallback(() => {
    closeProps()
    resetErrors()
  }, [closeProps, resetErrors])

  useNotificationProgress(updateDealProgress, t('notifyDealChanged'))
  useEffect(() => {
    if (updateDealProgress === Progress.SUCCESS && prevUpdateDealProgress === Progress.WORK) {
      close()
    }
  }, [close, prevUpdateDealProgress, updateDealProgress])

  const content = useMemo(
    () => (
      <Fields>
        <Key>{t('fields.deal_type')}</Key>
        <LongField>
          <SimpleSelect
            size="small"
            value={formik.values.deal_type}
            options={Object.keys(DealType).map(type => ({ id: type, title: t(`dealTypes.${type}`) }))}
            onChange={value => {
              formik.setFieldValue('deal_type', value)
            }}
          />
        </LongField>
        <Key>{t('fields.quantity_bag')}</Key>
        <FormItem
          fieldName="quantity"
          errorFields={errorFields}
          render={additionalProps => (
            <DealDetailNumberInput
              value={quantityBags}
              onChange={quantityBagsToTonsCallback(v => formik.setFieldValue('quantity', v))}
              inputSize="small"
              {...additionalProps}
            />
          )}
        />
        <Key>{t('fields.quantity_ton')}</Key>
        <FormItem
          fieldName="quantity"
          errorFields={errorFields}
          render={additionalProps => (
            <DealDetailNumberInput
              value={formik.values.quantity}
              onChange={quantityTonsToBagsCallback(v => formik.setFieldValue('quantity', v))}
              inputSize="small"
              {...additionalProps}
            />
          )}
        />
        <Key>
          {t('fields.distance')}, {t('common:km')}
        </Key>
        <FormItem
          fieldName="distance"
          errorFields={errorFields}
          render={additionalProps => (
            <DealDetailNumberInput
              isInteger
              value={formik.values.distance}
              onChange={v => formik.setFieldValue('distance', v)}
              inputSize="small"
              {...additionalProps}
            />
          )}
        />
        <Key>
          {t('fields.price_exw')}, {t('common:rub', { currency })} {t('common:per_bag')}
        </Key>
        <FormItem
          fieldName="price_exw"
          errorFields={errorFields}
          render={additionalProps => (
            <DealDetailNumberInput
              value={formik.values.price_exw}
              onChange={v => formik.setFieldValue('price_exw', v)}
              inputSize="small"
              {...additionalProps}
            />
          )}
        />
        <Key>
          {t('fields.price_cpt')}, {t('common:rub', { currency })} {t('common:per_bag')}
        </Key>
        <FormItem
          fieldName="price_cpt"
          errorFields={errorFields}
          render={additionalProps => (
            <DealDetailNumberInput
              value={formik.values.price_cpt}
              onChange={v => formik.setFieldValue('price_cpt', v)}
              inputSize="small"
              {...additionalProps}
            />
          )}
        />
        <Key>
          {t('fields.price_logistics')}, {t('common:rubKgnoprice', { currency })}
          &nbsp;
          {t('common:per_bag')}
        </Key>
        <FormItem
          fieldName="price_logistics"
          errorFields={errorFields}
          render={additionalProps => (
            <DealDetailNumberInput
              value={priceLogistics}
              onChange={priceLogisticsBagsToTonsCallback(v => formik.setFieldValue('price_logistics', v))}
              inputSize="small"
              {...additionalProps}
            />
          )}
        />

        <Key>
          {t('fields.price_logistics')}, {t('common:rubKgnoprice', { currency })}
          &nbsp;
          {t('common:per_ton')}
        </Key>
        <FormItem
          fieldName="price_logistics"
          errorFields={errorFields}
          render={additionalProps => (
            <DealDetailNumberInput
              value={formik.values.price_logistics}
              onChange={priceLogisticsTonsToBagsCallback(v => formik.setFieldValue('price_logistics', v))}
              inputSize="small"
              {...additionalProps}
            />
          )}
        />

        <Key>
          {t('fields.price_with_logistics')}, {t('common:rubKgnoprice', { currency })}
          &nbsp;
          {t('common:per_bag')}
        </Key>
        <ValueField>{formatNumber(deal.price_with_logistics, true)}</ValueField>

        <Key>
          {t('fields.price_with_logistics')}, {t('common:rubKgnoprice', { currency })}
          &nbsp;
          {t('common:per_ton')}
        </Key>
        <ValueField>{formatNumber(bagsToTons(deal.price_with_logistics), true)}</ValueField>

        <Key>
          {t('fields.commission')}, {t('common:rub', { currency })} {t('common:per_bag')}
        </Key>
        <ValueField>{formatNumber(deal.commission, true)}</ValueField>
        <Key>
          {t('fields.paid_sum')}, {t('common:rub', { currency })}
        </Key>
        <FormItem
          fieldName="paid_sum"
          errorFields={errorFields}
          render={additionalProps => (
            <LongField>
              <NumberInput
                value={formik.values.paid_sum}
                onChange={v => formik.setFieldValue('paid_sum', v)}
                inputSize="small"
                {...additionalProps}
              />
            </LongField>
          )}
        />
        <Key>{t('fields.delivered_quantity')}</Key>
        <ValueField>
          {tonsToBags(deal.delivered_quantity)} {t('common:bag')}
        </ValueField>

        <Key>{t('fields.delivered_quantity')}</Key>
        <ValueField>
          {formatNumber(deal.delivered_quantity)} {t('common:ton')}
        </ValueField>
      </Fields>
    ),
    [
      t,
      formik,
      errorFields,
      currency,
      formatNumber,
      deal.price_with_logistics,
      deal.commission,
      deal.delivered_quantity,
      quantityBags,
      quantityBagsToTonsCallback,
      quantityTonsToBagsCallback,
      priceLogistics,
      priceLogisticsBagsToTonsCallback,
      priceLogisticsTonsToBagsCallback,
    ],
  )

  return (
    <ModalCommon
      width={658}
      isOpen={isOpen}
      close={close}
      title={t('dealDetail')}
      content={content}
      footer={
        <Footer>
          <Button
            onClick={() => {
              formik.dirty && formik.submitForm().catch()
            }}
            disabled={!formik.dirty}
            intent="primary"
            progress={updateDealProgress}
            filled
          >
            {t('common:save')}
          </Button>
          <CancelButton onClick={close} intent="minor-action">
            {t('common:cancel')}
          </CancelButton>
        </Footer>
      }
    />
  )
}
