import React, { Fragment, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import CollectionSelectors from 'modules/domain/collection/selectors'
import { Region } from 'modules/domain/collection/types'
import {
  DistrictWrapper,
  Header,
  HiddenList,
  HiddenListWrapper,
  List,
  Mid,
  ShowAll,
  StyledChip,
  StyledSwitch,
  ThreeDotWrapper,
  Title,
} from 'views/components/TableFilters/RegionDistrict/styles'

type Props = {
  onChange: (id: string) => void
  region?: string[]
  district?: string[]
}

export const DistrictFilter: React.FC<Props> = props => {
  const { onChange, region, district } = props
  const { t } = useTranslation('regionFilter')
  const list = useSelector(CollectionSelectors.getRegions)
  const [showAll, setShowAl] = useState(false)

  const renderItem = useCallback(
    (item: Region) => (
      <StyledChip
        active={district?.includes(item.id)}
        onClick={() => {
          onChange(item.id)
        }}
        key={item.id}
        size="small"
      >
        {item.name}
      </StyledChip>
    ),
    [district, onChange],
  )

  if (!region || region.length === 0) {
    return null
  }

  return (
    <DistrictWrapper>
      <div>
        {region?.map((regionId, idx) => {
          const currentRegion = list.find(r => r.id === regionId)

          if (!currentRegion || (!showAll && idx > 0)) {
            return null
          }

          return (
            <Fragment key={regionId}>
              <Header>
                {showAll ? (
                  <Title>
                    <Mid>{t('regionDistricts')}:</Mid> {currentRegion.name}
                  </Title>
                ) : (
                  <Title>
                    <Mid>{region?.length === 1 ? t('regionDistricts') : t('regionsDistricts')}:</Mid>
                    {region?.map(id => list.find(item => item.id === id)?.name)?.join(', ')}
                  </Title>
                )}

                {idx === 0 && (
                  <ShowAll>
                    <Mid>{t('showAll')}</Mid>
                    <StyledSwitch
                      on={showAll}
                      onClick={() => {
                        setShowAl(prev => !prev)
                      }}
                    />
                  </ShowAll>
                )}
              </Header>

              {showAll ? (
                <List>{list.filter(item => item.parent_id === currentRegion.id).map(renderItem)}</List>
              ) : (
                <HiddenListWrapper>
                  <ThreeDotWrapper>...</ThreeDotWrapper>
                  <HiddenList>
                    {list
                      .filter(item => !!item.parent_id && region?.includes(item.parent_id))
                      .sort((a, b) => {
                        const isA = district?.includes(a.id)
                        const isB = district?.includes(b.id)

                        if (isA === isB) {
                          return 0
                        }
                        if (isA) {
                          return -1
                        }
                        if (isB) {
                          return 1
                        }

                        return 0
                      })
                      .map(renderItem)}
                  </HiddenList>
                </HiddenListWrapper>
              )}
            </Fragment>
          )
        })}
      </div>
    </DistrictWrapper>
  )
}
