import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { generatePath } from 'react-router-dom'

import useFormatDate from 'hooks/useFormatDate'
import useFormatNumber from 'hooks/useFormatNumber'
import useLocationFrom from 'hooks/useLocationFrom'
import { Car } from 'modules/domain/car/types'
import { Deal, DealBid, DealType } from 'modules/domain/deal/types'
import UserAddressSelectors from 'modules/domain/userAddress/selectors'
import DealRoutes from 'views/pages/Deal/routes'
import {
  CarDescription,
  DealTypeCircle,
  DealTypeValue,
  FlexHorizontal,
  Label,
  TripInfoWrapper,
  Value,
} from 'views/pages/Logistics/TripPages/styled'
import { TwoColumnGrid } from 'views/pages/Logistics/TripPages/TripDetails/styled'
import { BlueLink } from 'views/styled/common'

const useCarInfo = (car?: Car) => {
  const { t } = useTranslation('tripPages')

  if (!car) return null

  const vehicleType = car.car_type ? t(`vehicles:vehicleTypes.${car.car_type}`) : ''
  const unloadingType = car.unloading_type ? t(`vehicles:unloadingType.${car.unloading_type}`) : ''
  const isDumpTruckHeader = car.is_dump_truck && `, ${t('vehicles:is_dump_truck')}`
  const carHeight = car.side_height ? `${car.side_height} ${t('common:m')}` : ''
  const carData = [
    car.brand_model,
    car.licence_number,
    ...(vehicleType ? [vehicleType] : []),
    ...(unloadingType ? [unloadingType] : []),
    ...(isDumpTruckHeader ? [isDumpTruckHeader] : []),
    carHeight,
  ]
    .filter(el => el !== '')
    .join(', ')

  return carData
}

export const TripInfoBlock: React.FC<{ deal: Deal; car?: Car }> = ({ deal, car }) => {
  const from = useLocationFrom()
  const { t } = useTranslation('tripPages')
  const formatDate = useFormatDate()

  const saleBid = deal.sale_bid as DealBid
  const purchaseBid = deal.purchase_bid as DealBid

  const saleAddress = useSelector(state => UserAddressSelectors.addressById(state, saleBid.address.id))
  const purchaseAddress = useSelector(state => UserAddressSelectors.addressById(state, purchaseBid.address.id))

  const carInfo = useCarInfo(car)

  const formatNumber = useFormatNumber()

  return (
    <TripInfoWrapper>
      <FlexHorizontal>
        <DealTypeCircle dealType={deal.deal_type}>{deal.deal_type === DealType.trade ? 'Т' : 'А'}</DealTypeCircle>
        <div>
          <DealTypeValue>{t(deal.deal_type === DealType.trade ? 'tradeDeal' : 'agentDeal')}</DealTypeValue>
          <CarDescription>
            <BlueLink
              $isInline
              to={{
                pathname: generatePath(DealRoutes.Edit, { id: deal.id }),
                state: { from },
              }}
            >
              ID {deal.id}
            </BlueLink>
            {' / '}
            {[
              formatDate(deal.created),
              deal.product.title,
              `${formatNumber(deal.quantity_in_bags)} ${t('common:bags')}`,
            ]
              .filter(Boolean)
              .join(', ')}
          </CarDescription>
        </div>
      </FlexHorizontal>

      <TwoColumnGrid>
        <div>
          <Label>{t('fromLabel')}</Label>
          <Value>{saleAddress.address}</Value>
        </div>
        <div>
          <Label>{t('toLabel')}</Label>
          <Value>{purchaseAddress.address}</Value>
        </div>
      </TwoColumnGrid>

      {!!carInfo && (
        <>
          <Label>{t('carLabel')}</Label>
          <Value>{carInfo}</Value>
        </>
      )}
    </TripInfoWrapper>
  )
}
