import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button, Progress } from '@agro-club/agroclub-shared'

import { useNotificationProgress } from 'hooks/useNotificationProgress'
import usePrevious from 'hooks/usePrevious'
import UserAddressSelectors from 'modules/domain/userAddress/selectors'
import { CustomerFields } from 'views/components/WarehouseModal/CustomerFields'
import { useWarehouseFormik } from 'views/components/WarehouseModal/hooks'
import { SellerFields } from 'views/components/WarehouseModal/SellerFields'
import { FieldWrapper } from 'views/components/WarehouseModal/styled'
import { Props as WarehousePropsType } from 'views/components/WarehouseProps/WarehouseProps'
import { CancelButton } from 'views/styled/common'
import { EditButton } from 'views/ui/EditButton/EditButton'
import { ModalCommon } from 'views/ui/ModalCommon/ModalCommon'

type Props = {
  type: WarehousePropsType['type']
  addressId: string
  userId: string
  onClickEdit?: () => void
}

export const WarehouseModal: React.FC<Props> = ({ type, addressId, userId, onClickEdit }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { t } = useTranslation('warehouse')
  const address = useSelector(state => UserAddressSelectors.addressById(state, addressId))
  const editProgress = useSelector(state => UserAddressSelectors.editProgress(state, addressId))
  const formik = useWarehouseFormik({ address, userId })
  const prevEditProgress = usePrevious(editProgress)

  useNotificationProgress(editProgress)

  const onCancel = useCallback(() => {
    setIsOpen(false)
    formik.resetForm()
  }, [formik, setIsOpen])

  useEffect(() => {
    if (prevEditProgress === Progress.WORK && editProgress === Progress.SUCCESS) {
      onCancel()
    }
  }, [editProgress, onCancel, prevEditProgress])

  const content = useMemo(
    () => (type === 'loading' ? <SellerFields formik={formik} /> : <CustomerFields formik={formik} />),
    [formik, type],
  )

  return (
    <>
      <EditButton
        onClick={() => {
          setIsOpen(true)
          onClickEdit?.()
        }}
      />
      <ModalCommon
        width={658}
        isOpen={isOpen}
        close={onCancel}
        title={t(type)}
        content={<FieldWrapper>{content}</FieldWrapper>}
        footer={
          <>
            <Button
              onClick={() => {
                formik.dirty && formik.submitForm().catch()
              }}
              disabled={!formik.dirty}
              intent="primary"
              progress={editProgress}
              filled
            >
              {t('common:save')}
            </Button>
            <CancelButton onClick={onCancel} intent="minor-action">
              {t('common:cancel')}
            </CancelButton>
          </>
        }
      />
    </>
  )
}
