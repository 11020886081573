import React from 'react'

import {
  GoogleAddressSuggest,
  GoogleAddressSuggestProps,
  YandexAddressSuggest,
  YandexAddressSuggestProps,
} from '@agro-club/agroclub-shared'

import env from 'env'

type AddressSuggestProps = Omit<YandexAddressSuggestProps, 'apikey'> | Omit<GoogleAddressSuggestProps, 'apikey'>

const AddressSuggest: React.FC<AddressSuggestProps> = props => {
  if (env.REACT_APP_USE_GOOGLE_MAPS) {
    return (
      <GoogleAddressSuggest {...props} apikey={env.REACT_APP_GOOGLE_MAPS_API_KEY} language={env.REACT_APP_LOCALE} />
    )
  }

  return <YandexAddressSuggest apikey={env.REACT_APP_YA_MAPS_API_KEY} {...props} />
}

export default AddressSuggest
