import React from 'react'

import { Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const FormContentBody = styled.form`
  display: grid;
  grid-gap: 24px;
  padding: 16px 24px;
`

export const FormContentFooter = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 72px;
  box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.08);
  padding: 0 24px;
`

export const FormContentWrapper = styled.div`
  margin: 16px 24px;
  border-radius: 8px;
  background: ${({ theme }) => theme.color.onPrimaryLight};
`

export const FormButtonsSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  background: ${({ theme }) => theme.color.onPrimaryLight};

  & > *:not(:last-child) {
    margin-right: 20px;
  }
`

const SectionBody = styled.div`
  display: grid;
  grid-gap: 16px;
`

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const SectionTitle = styled.h3`
  margin: 0 0 16px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.textPrimary};
  ${Typography.body2Bold};
  letter-spacing: 0.0125em;
`

const LabeledContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
`
const LabelStyled = styled.div`
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
  margin-bottom: 4px;
  ${Typography.caption1Default}
`
export const LabeledContainer: React.FC<{ label: string; className?: string }> = ({ children, label, className }) => (
  <LabeledContainerStyled className={className}>
    <LabelStyled>{label}</LabelStyled>
    {children}
  </LabeledContainerStyled>
)

export const FormSection: React.FC<{ title?: string; className?: string; testId?: string }> = ({
  title,
  className,
  children,
  testId,
}) => (
  <SectionWrapper className={className} data-test-id={testId}>
    {title && <SectionTitle>{title}</SectionTitle>}
    <SectionBody>{children}</SectionBody>
  </SectionWrapper>
)
