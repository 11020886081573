import React, { VFC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button, Chip, Progress, useAction } from '@agro-club/agroclub-shared'

import usePrevious from 'hooks/usePrevious'
import CarActions from 'modules/domain/car/duck'
import CarSelectors from 'modules/domain/car/selectors'
import { CarStatuses } from 'modules/domain/car/types'
import { ConfirmationTooltip } from 'views/ui/ConfirmationTooltip/ConfirmationTooltip'

type Props = {
  carId: string
  currentStatus: CarStatuses
}

export const ArchiveCarBtn: VFC<Props> = ({ carId, currentStatus }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const { t } = useTranslation()
  const progress = useSelector(state => CarSelectors.changeStatusProgress(state, carId))
  const changeStatus = useAction(CarActions.changeStatusRequested)
  const prevProgress = usePrevious(progress)

  const close = useCallback(() => {
    setVisible(false)
  }, [setVisible])

  useEffect(() => {
    if (progress === Progress.SUCCESS && prevProgress === Progress.WORK) {
      close()
    }
  }, [progress, close, prevProgress])

  return currentStatus === CarStatuses.active ? (
    <ConfirmationTooltip
      visible={visible}
      placement="bottomRight"
      overlayProps={{
        header: t('common:archive'),
        body: t('vehicles:areYouSureToArchiveCar'),
        okText: t('common:yes'),
        okAction: () => {
          changeStatus(carId, CarStatuses.archived)
        },
        okProgress: progress,
        cancelText: t('common:cancel'),
        close,
      }}
    >
      <Button
        intent="danger"
        size="small"
        onClick={() => {
          setVisible(true)
        }}
      >
        {t('common:archive')}
      </Button>
    </ConfirmationTooltip>
  ) : (
    <Chip color="red">{t('vehicles:statuses:archived')}</Chip>
  )
}
