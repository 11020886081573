import React, { VFC, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button, Input, Progress, SimpleSelect, useAction } from '@agro-club/agroclub-shared'
import { useFormik } from 'formik'

import { useNotificationProgress } from 'hooks/useNotificationProgress'
import usePrevious from 'hooks/usePrevious'
import CarrierActions from 'modules/domain/carrier/duck'
import CarrierSelectors from 'modules/domain/carrier/selectors'
import { Trailer } from 'modules/domain/carrier/types'
import { UnloadingType } from 'modules/domain/types'
import { FieldWrapper, TwoFields } from 'views/components/AddTrailer/styled'
import { CancelButton } from 'views/styled/common'
import { FormItem } from 'views/ui/FormItem/FormItem'
import { ModalSmall } from 'views/ui/ModalSmall/ModalSmall'
import { NumberInput } from 'views/ui/NumberInput/NumberInput'

type Props = {
  onCancel: () => void
  trailer: Trailer | null
}

export const UpdateTrailer: VFC<Props> = ({ trailer, onCancel }) => {
  const { t } = useTranslation('vehicles')
  const updateTrailer = useAction(CarrierActions.trailerUpdateRequested)
  const progress = useSelector(CarrierSelectors.trailerUpdateProgress)
  const errorFields = useSelector(CarrierSelectors.trailerUpdateErrorFields)
  const resetErrors = useAction(CarrierActions.trailerResetUpdateErrors)
  const prevProgress = usePrevious(progress)
  useNotificationProgress(progress, t('trailerAddSuccess'))

  const formik = useFormik({
    initialValues: {
      unloading_type: trailer?.unloading_type || null,
      side_height: trailer?.side_height || null,
      brand_model: trailer?.brand_model || null,
    },
    enableReinitialize: false,
    validateOnBlur: false,

    onSubmit: () => {
      const { unloading_type, side_height, brand_model } = formik.values
      updateTrailer(trailer?.id, {
        unloading_type,
        side_height,
        brand_model,
      })
    },
  })

  useEffect(() => {
    if (prevProgress === Progress.WORK && progress === Progress.SUCCESS) {
      formik.resetForm()
      resetErrors()
      onCancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress, prevProgress])

  const content = useMemo(
    () => (
      <FieldWrapper>
        <TwoFields>
          <label htmlFor="licence_number"> {t('form.licence_number')}</label>
          <FormItem
            fieldName="licence_number"
            errorFields={errorFields}
            render={() => (
              <Input
                inputSize="small"
                disabled
                placeholder={t('form.trailerLicenceNumberPlaceholder')}
                value={trailer?.licence_number}
              />
            )}
          />
        </TwoFields>
        <TwoFields>
          <label htmlFor="brand_model">{t('form.trailer_model')}</label>
          <FormItem
            fieldName="brand_model"
            errorFields={errorFields}
            render={additionalProps => (
              <Input
                inputSize="small"
                value={formik.values.brand_model ?? undefined}
                onChange={(event, value) => formik.setFieldValue('brand_model', value)}
                {...additionalProps}
              />
            )}
          />
        </TwoFields>
        <TwoFields>
          <label htmlFor="unloading_type"> {t('form.unloading_type')}</label>
          <FormItem
            fieldName="unloading_type"
            errorFields={errorFields}
            render={additionalProps => (
              <SimpleSelect
                size="small"
                value={formik.values.unloading_type}
                onChange={value => formik.setFieldValue('unloading_type', value)}
                options={Object.values(UnloadingType).map(v => ({
                  id: v,
                  title: t(`vehicles:unloadingType.${v}`),
                }))}
                {...additionalProps}
              />
            )}
          />
        </TwoFields>
        <TwoFields>
          <label htmlFor="side_height"> {t('form.side_height')}</label>
          <FormItem
            fieldName="side_height"
            errorFields={errorFields}
            render={additionalProps => (
              <NumberInput
                inputSize="small"
                value={formik.values.side_height ?? undefined}
                onChange={value => formik.setFieldValue('side_height', value)}
                {...additionalProps}
              />
            )}
          />
        </TwoFields>
      </FieldWrapper>
    ),
    [errorFields, formik, t, trailer],
  )

  return (
    <ModalSmall
      isOpen
      close={onCancel}
      title={t('common:editing')}
      content={content}
      footer={
        <>
          <Button
            onClick={() => {
              formik.dirty && formik.submitForm().catch()
            }}
            disabled={!formik.dirty}
            intent="primary"
            progress={progress}
            filled
          >
            {t('common:save')}
          </Button>
          <CancelButton onClick={onCancel} intent="minor-action">
            {t('common:cancel')}
          </CancelButton>
        </>
      }
    />
  )
}
