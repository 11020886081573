import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { generatePath } from 'react-router-dom'
import { ColumnInstance, Row, useTable } from 'react-table'

import {
  AdvancedHeadCell,
  Progress,
  Table,
  TableBody,
  TableBodyRow,
  TableHead,
  TableHeadCellProps,
  TableHeadRow,
  TableNoData,
  useAction,
  usePersistentScroll,
} from '@agro-club/agroclub-shared'

import useLocationFrom from 'hooks/useLocationFrom'
import LogisticsDealActions from 'modules/domain/logisticsDeal/duck'
import { useLogisticsDealList } from 'modules/domain/logisticsDeal/hooks'
import LogisticsDealSelectors from 'modules/domain/logisticsDeal/selectors'
import { LogisticsDeal } from 'modules/domain/logisticsDeal/types'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import { LogisticsFilter } from 'views/pages/Logistics/LogisticsFilter/LogisticsFilter'
import { useColumns } from 'views/pages/Logistics/LogisticsTable/useColumns'
import LogisticsRoutes from 'views/pages/Logistics/routes'

const LogisticsDealSortableHeadCell = AdvancedHeadCell<keyof LogisticsDeal>()

const getPathname = (row: Row<LogisticsDeal>) => {
  const dealId = row?.original?.id

  return dealId ? generatePath(LogisticsRoutes.Detail, { id: dealId }) : ''
}

type ColumnProps = ColumnInstance<LogisticsDeal> & TableHeadCellProps

const LogisticsTable: React.FC = () => {
  const { t } = useTranslation('logistics')
  const [progress, data] = useLogisticsDealList()
  const listRequested = useAction(LogisticsDealActions.listRequested)
  const filterUpdated = useAction(LogisticsDealActions.filterUpdated)
  const filterState = useSelector(LogisticsDealSelectors.filter)
  const total = useSelector(LogisticsDealSelectors.total)
  const pages = useSelector(LogisticsDealSelectors.pages)
  const page = useSelector(LogisticsDealSelectors.page)
  const pageSize = useSelector(LogisticsDealSelectors.pageSize)
  const { sort_field, sort_reversed } = useSelector(LogisticsDealSelectors.sorting)
  const from = useLocationFrom()
  const visibleColumns = useColumns()
  const hiddenColumns: (keyof LogisticsDeal)[] = useMemo(() => ['id'], [])

  const columnsAll = useMemo(
    () => [
      ...visibleColumns,
      ...hiddenColumns.map(col => ({
        Header: col,
        accessor: col,
        hidden: true,
      })),
    ],
    [hiddenColumns, visibleColumns],
  )

  const { columns, rows, prepareRow } = useTable<LogisticsDeal>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore weird issue with react-table typings — having more then 26 fields in type causes TS error
    columns: columnsAll,
    data: data as LogisticsDeal[],
    initialState: { hiddenColumns },
  })
  const setPage = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  const { scrollRef } = usePersistentScroll('logisticsList')

  const handleClearFilters = useCallback(() => {
    filterUpdated({})
  }, [filterUpdated])

  const isFilterApplied = Object.values(filterState).some(Boolean)
  return (
    <TComponents.Wrapper>
      <LogisticsFilter />
      <Table total={total} pages={pages} pageSize={pageSize} currentPage={page} onSetPage={setPage} ref={scrollRef}>
        <TableHead>
          <TableHeadRow>
            {columns.map((column: ColumnProps) => (
              <LogisticsDealSortableHeadCell
                zIndex={1}
                key={column.getHeaderProps().key}
                id={column.id as keyof LogisticsDeal}
                sortable={column.sortable}
                hidden={column.hidden}
                sortField={sort_field}
                sortDesc={sort_reversed}
                onChange={() => undefined}
                headerHorizontalAlign={column.headerHorizontalAlign as TableHeadCellProps['headerHorizontalAlign']}
              >
                {column.render('Header')}
              </LogisticsDealSortableHeadCell>
            ))}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map(row => {
            prepareRow(row)
            const { key, ...props } = row.getRowProps()
            const pathname = getPathname(row)
            return (
              <TableBodyRow key={key} {...props}>
                {row.cells.map(cell => {
                  const { key, ...props } = cell.getCellProps()

                  return (
                    <TComponents.RelativeTableBodyCell key={key} {...props}>
                      {!!pathname && <TComponents.TableCellLink to={{ pathname, state: { from } }} />}
                      {cell.render('Cell')}
                    </TComponents.RelativeTableBodyCell>
                  )
                })}
              </TableBodyRow>
            )
          })}
          <TableNoData
            progress={progress}
            isEmpty={!rows.length}
            colSpan={visibleColumns.length}
            loading={<TComponents.Spinner />}
          >
            <div>{isFilterApplied ? t('list.emptyFilterMsg') : t('list.emptyMsg')}</div>
            {isFilterApplied && progress !== Progress.WORK && (
              <TComponents.ClearButton intent="cancel" size="small" onClick={handleClearFilters}>
                {t('common:resetAllFilters')}
              </TComponents.ClearButton>
            )}
          </TableNoData>
        </TableBody>
      </Table>
    </TComponents.Wrapper>
  )
}

export default LogisticsTable
