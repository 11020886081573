import React from 'react'

import { BackBtn, Typography } from '@agro-club/agroclub-shared'
import styled, { StyledProps } from 'styled-components'

const RootStyled = styled.header`
  min-height: 80px;
  padding: 16px 24px 16px 36px;
  display: flex;
`

type TitleStyledProps = StyledProps<{ compact?: boolean; noMargin?: boolean }>

const TitleContainerStyled = styled.div<TitleStyledProps>`
  display: flex;
  align-items: center;
  margin-top: ${({ compact }: TitleStyledProps) => (compact ? '10px' : '0px')};
`

const TitleStyled = styled.div<TitleStyledProps>`
  display: flex;
  flex-direction: row;
  margin-right: ${props => (props.noMargin ? '0' : '20px')};
  color: ${({ theme }: TitleStyledProps) => theme.color.onPrimaryDark};
  ${({ compact }: TitleStyledProps) => (compact ? Typography.largeHeadlineBold : Typography.largeTitleBold)};
`

export const Title: React.FC<{
  title: string
  compact?: boolean
  onClick?: () => void
  noMargin?: boolean
}> = ({ children, compact, title, onClick, noMargin }) => (
  <TitleContainerStyled onClick={onClick}>
    <TitleStyled noMargin={noMargin} compact={compact}>
      {title}
    </TitleStyled>
    {children}
  </TitleContainerStyled>
)

export const SubTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
  margin-top: 4px;
`

export const ButtonWrapper = styled.div`
  display: grid;
  gap: 16px;
  grid-auto-flow: column;
  grid-auto-rows: max-content;
`

type RightBlockProps = StyledProps<{ withBackBtn?: boolean }>
const RightBlock = styled.div<RightBlockProps>`
  display: flex;
  justify-content: flex-end;
  padding-top: ${({ withBackBtn }) => (withBackBtn ? '8px' : 0)};
`

const LeftBlock = styled.div`
  flex: 1;
`

type HeaderProps = {
  onClickBack?: () => void
  rightBlock?: React.ReactNode | React.ReactNode[] | string
}

export const Root: React.FC<HeaderProps> = ({ rightBlock, onClickBack, children }) => (
  <RootStyled>
    <LeftBlock>
      {!!onClickBack && <BackBtn onClick={onClickBack} />}
      {children}
    </LeftBlock>
    {!!rightBlock && <RightBlock withBackBtn={!!onClickBack}>{rightBlock}</RightBlock>}
  </RootStyled>
)

export const TabsContainer = styled(RootStyled)`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.color.outlineMid};
  padding: 0 24px;
  min-height: 81px;
`

export const TabsRoot: React.FC = ({ children }) => <TabsContainer>{children}</TabsContainer>

export const Tab = styled(Title)`
  ${({ theme, isActive }: StyledProps<{ isActive: boolean }>) => {
    if (isActive) {
      return {
        borderBottom: `4px solid ${theme.color.primary600}`,
        opacity: 1,
        cursor: 'auto',
      }
    }
    return {
      borderBottom: '4px solid transparent',
      opacity: 0.5,
      cursor: 'pointer',
    }
  }}
  margin-right: 48px;
`
