import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { generatePath } from 'react-router-dom'
import { Row, useTable } from 'react-table'

import {
  AdvancedHeadCell,
  Progress,
  Table,
  TableBody,
  TableBodyRow,
  TableHead,
  TableHeadRow,
  TableNoData,
  useAction,
  usePersistentScroll,
} from '@agro-club/agroclub-shared'
import { isNil } from 'ramda'

import useLocationFrom from 'hooks/useLocationFrom'
import CarrierActions from 'modules/domain/carrier/duck'
import { useCarrierList } from 'modules/domain/carrier/hooks'
import CarrierSelectors from 'modules/domain/carrier/selectors'
import { Carrier } from 'modules/domain/carrier/types'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import { CarrierFilters } from 'views/pages/Carrier/CarrierTable/CarrierFilters'
import useColumns from 'views/pages/Carrier/CarrierTable/useColumns'
import CarrierRoutes from 'views/pages/Carrier/routes'

const CarriersSortableHeadCell = AdvancedHeadCell<keyof Carrier>()

const getPathname = (row: Row<Carrier>) => {
  const dealId = row?.original?.id
  return dealId ? generatePath(CarrierRoutes.Detail, { id: dealId }) : ''
}

const CarrierTable: React.FC = () => {
  const { t } = useTranslation('carrier')
  const [progress, data] = useCarrierList()
  const sortingUpdated = useAction(CarrierActions.sortingUpdated)
  const listRequested = useAction(CarrierActions.listRequested)
  const filterUpdated = useAction(CarrierActions.filterUpdated)
  const filterState = useSelector(CarrierSelectors.filter)
  const total = useSelector(CarrierSelectors.total)
  const pages = useSelector(CarrierSelectors.pages)
  const page = useSelector(CarrierSelectors.page)
  const pageSize = useSelector(CarrierSelectors.pageSize)
  const { sort_field, sort_reversed } = useSelector(CarrierSelectors.sorting)

  const from = useLocationFrom()

  const visibleColumns = useColumns()
  const hiddenColumns: (keyof Carrier)[] = useMemo(() => ['id'], [])

  const columnsAll = useMemo(
    () => [
      ...visibleColumns,
      ...hiddenColumns.map(col => ({
        Header: col,
        accessor: col,
        hidden: true,
      })),
    ],
    [hiddenColumns, visibleColumns],
  )

  const { columns, rows, prepareRow } = useTable<Carrier>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore weird issue with react-table typings — having more then 26 fields in type causes TS error
    columns: columnsAll,
    data: data as Carrier[],
    initialState: { hiddenColumns },
  })

  const setPage = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  const { scrollRef } = usePersistentScroll('carrierList')

  const handleClearFilters = useCallback(() => {
    filterUpdated({})
  }, [filterUpdated])

  const isFilterApplied = Object.values(filterState).some(value => !isNil(value))

  return (
    <TComponents.Wrapper>
      <CarrierFilters handleClearFilters={handleClearFilters} isFilterApplied={isFilterApplied} />
      <Table total={total} pages={pages} pageSize={pageSize} currentPage={page} onSetPage={setPage} ref={scrollRef}>
        <TableHead>
          <TableHeadRow>
            {columns.map(column => (
              <CarriersSortableHeadCell
                zIndex={1}
                key={column.getHeaderProps().key}
                id={column.id as keyof Carrier}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                sortable={column.sortable}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                hidden={column.hidden}
                sortField={sort_field}
                sortDesc={sort_reversed}
                onChange={sortingUpdated}
              >
                {column.render('Header')}
              </CarriersSortableHeadCell>
            ))}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map(row => {
            prepareRow(row)
            const { key, ...props } = row.getRowProps()
            const pathname = getPathname(row)
            return (
              <TableBodyRow key={key} {...props}>
                {row.cells.map(cell => {
                  const { key, ...props } = cell.getCellProps()

                  return (
                    <TComponents.RelativeTableBodyCell key={key} {...props}>
                      {!!pathname && <TComponents.TableCellLink to={{ pathname, state: { from } }} />}
                      {cell.render('Cell')}
                    </TComponents.RelativeTableBodyCell>
                  )
                })}
              </TableBodyRow>
            )
          })}
          <TableNoData
            progress={progress}
            isEmpty={!rows.length}
            colSpan={visibleColumns.length}
            loading={<TComponents.Spinner />}
          >
            <div>{isFilterApplied ? t('list.emptyFilterMsg') : t('list.emptyMsg')}</div>
            {isFilterApplied && progress !== Progress.WORK && (
              <TComponents.ClearButton intent="cancel" size="small" onClick={handleClearFilters}>
                {t('common:resetAllFilters')}
              </TComponents.ClearButton>
            )}
          </TableNoData>
        </TableBody>
      </Table>
    </TComponents.Wrapper>
  )
}

export default CarrierTable
