import { Specification } from 'modules/domain/specification/types'
import { DocumentWithTypeFile } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'

export const addSpecification = (data: Specification) => apiClient.post<Specification>(endpoints.specification(), data)

export const uploadFiles = (file: File) => {
  const form = new FormData()
  form.append('uploaded_file', file)

  return apiClient.post<DocumentWithTypeFile>(endpoints.files(), form)
}
