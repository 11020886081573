import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import UserCallsActions from 'modules/domain/userCalls/duck'
import * as managers from 'modules/domain/userCalls/managers'
import UserCallsSelectors from 'modules/domain/userCalls/selectors'
import { RequestError } from 'modules/errors'

export const fetchList = function* ({ payload }: ReturnType<typeof UserCallsActions.listRequested>) {
  const userId = typeof payload === 'string' ? payload : payload[0]

  try {
    const pageSize = yield select(UserCallsSelectors.pageSize, userId)
    const data = yield call(managers.getList, userId, 1, pageSize)
    yield put(UserCallsActions.listRequestSucceed(userId, data))
  } catch (err) {
    yield put(UserCallsActions.listRequestFailed(userId))
  }
}

export const fetchListNext = function* ({
  payload: [userId, page],
}: ReturnType<typeof UserCallsActions.listRequestedNext>) {
  try {
    const pageSize = yield select(UserCallsSelectors.pageSize, userId)
    const data = yield call(managers.getList, userId, page, pageSize)
    yield put(UserCallsActions.listRequestNextSucceed(userId, data))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(UserCallsActions.listRequestNextFailed(userId, errType))
  }
}

const UserCallsSaga = function* () {
  yield all([
    takeLatest(UserCallsActions.listRequested.type, fetchList),
    takeLatest(UserCallsActions.listRequestedNext.type, fetchListNext),
  ])
}

export default UserCallsSaga
