import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import { push, replace } from 'connected-react-router'

import i18n from 'i18n'
import { UserProfile } from 'modules/domain/auth/types'
import Routes from 'views/pages/Auth/routes'
import CompanyRoutes from 'views/pages/Company/routes'

import AuthActions from './duck'
import { fetchCurrentUser, obtainCode, obtainToken, updateCurrentUser } from './managers'
import { clearToken, getAccessToken, saveAccessToken, saveRefreshToken } from './repository'
import AuthSelectors from './selectors'

export const getLocation = function* () {
  const location = yield select(AuthSelectors.location)
  if (location === Routes.Login) {
    return CompanyRoutes.List
  }

  return location
}

export const initSaga = function* () {
  try {
    const token = yield call(getAccessToken)

    if (!token) {
      yield put(AuthActions.initFailed())
    } else {
      const response: UserProfile = yield call(fetchCurrentUser)

      yield put(AuthActions.initSucceed(response))
    }
  } catch (e) {
    yield put(AuthActions.initFailed())
    return yield put(replace(Routes.Login))
  }
}

export const getCodeSaga = function* ({ payload }: ReturnType<typeof AuthActions.codeRequested>) {
  try {
    yield call(obtainCode, payload)
    yield put(AuthActions.codeRequestSucceed(payload))
  } catch (e) {
    yield put(AuthActions.codeRequestFailed({ message: i18n.t('errors:wrong_phone') }))
  }
}

export const loginSaga = function* ({ payload }: ReturnType<typeof AuthActions.tokenRequested>) {
  try {
    const { code } = payload
    const location = yield call(getLocation)
    const phone = yield select(AuthSelectors.getPhone)
    const result = yield call(obtainToken, { code, phone })
    yield put(AuthActions.tokenRequestSucceed(result))
    yield put(push(CompanyRoutes.List))
    const profile: UserProfile = yield call(fetchCurrentUser)
    yield put(AuthActions.initSucceed(profile))
    yield put(push(location))
  } catch (e) {
    yield put(AuthActions.tokenRequestFailed({ message: i18n.t('errors:wrong_code') }))
  }
}

export const updateTokensSaga = function* ({ payload }: ReturnType<typeof AuthActions.tokenRequestSucceed>) {
  yield call(saveAccessToken, payload.accessToken)
  yield call(saveRefreshToken, payload.refreshToken)
}

export const updateUserProfile = function* ({ payload }: ReturnType<typeof AuthActions.userUpdateRequested>) {
  try {
    const profile = yield call(updateCurrentUser, payload)
    yield put(AuthActions.userUpdateSucceed(profile))
  } catch (err) {
    yield put(AuthActions.userUpdateFailed())
  }
}

export const signOutSaga = function* () {
  yield call(clearToken)
  yield put(push(Routes.Login))
}

export const AuthSaga = function* (): Generator {
  yield all([
    takeLatest(AuthActions.init.type, initSaga),
    takeLatest(AuthActions.codeRequested.type, getCodeSaga),
    takeLatest(AuthActions.tokenRequested.type, loginSaga),
    takeLatest(AuthActions.tokenRequestSucceed.type, updateTokensSaga),
    takeLatest(AuthActions.userUpdateRequested.type, updateUserProfile),
    takeLatest(AuthActions.signOutRequested.type, signOutSaga),
  ])
}

export default AuthSaga
