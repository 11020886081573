import { FormikContextType } from 'formik'

export const useServerValidation = (
  errorFields: Record<string, string[]> | undefined,
  fieldName?: string,
  formikInstance?: FormikContextType<unknown>,
): [boolean, string | undefined] => {
  if (!fieldName) {
    return [false, undefined]
  }
  const serverError = errorFields?.[fieldName]?.join(',')

  const isInvalid = !!(serverError || (formikInstance?.touched[fieldName] && !!formikInstance?.errors[fieldName]))
  const formikErrors = formikInstance?.errors[fieldName]
  const formikErrorsFormatted = formikErrors && Array.isArray(formikErrors) ? formikErrors.join(',') : formikErrors

  const errorText = serverError || formikErrorsFormatted

  return [isInvalid, errorText]
}
