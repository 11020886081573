import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link, generatePath, useLocation, useParams } from 'react-router-dom'

import {
  Button,
  Progress,
  SectionBody,
  SectionContainer,
  SectionTitle,
  Tabs,
  useHelmet,
} from '@agro-club/agroclub-shared'

import useFormatNumber from 'hooks/useFormatNumber'
import { useCar } from 'modules/domain/car/hooks'
import CarSelectors from 'modules/domain/car/selectors'
import { LocationStateFrom } from 'types/location'
import Item404 from 'views/layouts/Item404/Item404'
import ItemGenericError from 'views/layouts/ItemGenericError/ItemGenericError'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import { ArchiveCarBtn } from 'views/pages/Carrier/CarrierDetail/UserCars/ArchiveCarBtn'
import { Documents } from 'views/pages/Carrier/CarrierDetail/UserCars/Documents'
import { DriversForm } from 'views/pages/Carrier/CarrierDetail/UserCars/DriversForm'
import * as S from 'views/pages/Carrier/CarrierDetail/UserCars/styled'
import { UserInfo } from 'views/pages/Carrier/CarrierDetail/UserCars/UserInfo'
import CarrierRoutes from 'views/pages/Carrier/routes'
import { useUserEditGoBack } from 'views/pages/User/hooks'
import * as Header from 'views/ui/Header/Header'

enum TabsVal {
  Description = 'description',
  Documents = 'documents',
  Comments = 'comments',
}

export const CarDetail: React.FC = () => {
  const location = useLocation<LocationStateFrom>()
  const { t } = useTranslation('vehicles')
  const { id, car_id } = useParams<{ id: string; car_id: string }>()
  const [fetchProgress, car] = useCar(car_id)
  const meta = useSelector(state => CarSelectors.meta(state, id))
  const goBack = useUserEditGoBack(id)
  const formatNumber = useFormatNumber()

  const loading = () => <ItemLoadingLayout id={id} onBack={goBack} />
  const error404 = () => <Item404 id={id} onBack={goBack} title={t('errors.notFoundTitle')} />
  const errorUnknown = () => <ItemGenericError id={id} onBack={goBack} title={t('errors.unknownErrorTitle')} />

  const vehicleType = car && car.car_type ? t(`vehicles:vehicleTypes.${car.car_type}`) : '-'
  const unloadingType = car && car.unloading_type ? t(`vehicles:unloadingType.${car.unloading_type}`) : '-'
  const isDumpTruckHeader = car?.is_dump_truck && `, ${t('is_dump_truck')}`
  const isDumpTruckVal =
    car && car.is_dump_truck !== null ? t(`common:${['no', 'yes'][+car.is_dump_truck]}`) : t('common:dontKnow')
  const isInCharter =
    car && car.is_in_charter !== null ? t(`common:${['no', 'yes'][+car.is_in_charter]}`) : t('common:dontKnow')

  const licenseNumberTitle = car?.licence_number || ''

  const [activeTab, setActiveTab] = React.useState(TabsVal.Description)

  const tabsOptions = [
    { value: TabsVal.Description, title: t(`detailsTabs.${TabsVal.Description}`) },
    { value: TabsVal.Documents, title: t(`detailsTabs.${TabsVal.Documents}`) },
  ]

  useHelmet({ title: car?.licence_number })

  const renderDescription = () => (
    <div>
      {car?.brand_model && (
        <>
          <S.CarLabel>{t('form.brand_model')}</S.CarLabel>
          <S.CarValue>{car.brand_model}</S.CarValue>
        </>
      )}

      <S.CarLabel>{t('form.licence_number')}</S.CarLabel>
      <S.CarValue>{car?.licence_number ?? ''}</S.CarValue>

      {car?.default_trailer && (
        <>
          <S.CarLabel>{t('form.trailer_model')}</S.CarLabel>
          <S.CarValue>{car.default_trailer?.brand_model || '-'}</S.CarValue>

          <S.CarLabel>{t('form.trailer_licence_number')}</S.CarLabel>
          <S.CarValue>{car.default_trailer?.licence_number}</S.CarValue>
        </>
      )}

      <S.CarLabel>{t('form.car_type_full')}</S.CarLabel>
      <S.CarValue>{vehicleType}</S.CarValue>

      <S.CarLabel>{t('form.unloading_type')}</S.CarLabel>
      <S.CarValue>{unloadingType}</S.CarValue>

      <S.CarLabel>{t('form.side_height')}</S.CarLabel>
      <S.CarValue>{formatNumber(car?.side_height) ?? '-'}</S.CarValue>

      <S.CarLabel>{t('form.is_dump_truck')}</S.CarLabel>
      <S.CarValue>{isDumpTruckVal}</S.CarValue>

      <S.CarLabel>{t('form.is_in_charter')}</S.CarLabel>
      <S.CarValue>{isInCharter}</S.CarValue>
    </div>
  )

  const renderDocuments = () => <Documents />

  if (fetchProgress === Progress.ERROR) {
    if (meta.fetchError === 'not_found') {
      return error404()
    }
    return errorUnknown()
  }

  if (fetchProgress === Progress.WORK || !car) {
    return loading()
  }

  return (
    <>
      <Layout.Header hasBottomBorder>
        <Header.Root
          onClickBack={goBack}
          rightBlock={
            <Header.ButtonWrapper>
              <Link
                to={{
                  pathname: generatePath(CarrierRoutes.CarEdit, { id: car.owner.id, car_id }),
                  state: { from: location.state?.from ? { ...location.state?.from } : undefined },
                }}
              >
                <Button filled intent="primary" size="small">
                  {t('common:edit')}
                </Button>
              </Link>
              <ArchiveCarBtn carId={car_id} currentStatus={car.status} />
            </Header.ButtonWrapper>
          }
        >
          <Header.Title compact title={licenseNumberTitle} />
          <Header.SubTitle>
            {vehicleType}
            {isDumpTruckHeader}
            {car && <UserInfo userId={car.owner.id} />}
          </Header.SubTitle>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <S.TwoColumnLayoutFlex>
          <SectionContainer>
            <S.TabsContainer>
              <Tabs options={tabsOptions} active={activeTab} onChange={value => setActiveTab(value as TabsVal)} />
            </S.TabsContainer>
            <SectionBody>
              {activeTab === TabsVal.Description && renderDescription()}
              {activeTab === TabsVal.Documents && renderDocuments()}
            </SectionBody>
          </SectionContainer>

          <SectionContainer>
            <SectionTitle>{t('drivers')}</SectionTitle>
            <SectionBody>
              <DriversForm carId={car_id} />
            </SectionBody>
          </SectionContainer>
        </S.TwoColumnLayoutFlex>
      </Layout.Content>
    </>
  )
}
