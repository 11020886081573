import React, { VFC } from 'react'
import { useTranslation } from 'react-i18next'

import { Radio, RadioItem } from '@agro-club/agroclub-shared'

import { YesNoUnknown } from 'modules/domain/types'

type Props = {
  value?: YesNoUnknown
  onChange: (value: string) => void
}

export const YesNoUnknownRadio: VFC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation()
  return (
    <Radio
      onChange={val => {
        onChange(val as YesNoUnknown)
      }}
      value={value}
    >
      {Object.keys(YesNoUnknown).map(val => (
        <RadioItem key={val} value={val} label={t(`warehouse:yesNoUnknown.${val}`)} />
      ))}
    </Radio>
  )
}
