import React, { VFC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Car, CarStatuses } from 'modules/domain/car/types'
import { Dict } from 'types/generics.d'
import { ArchivedCarItem } from 'views/pages/Logistics/LogisticsRightPanel/LogisticsCarsPanel/ArchivedCarItem'
import { LogisticsCarsItem } from 'views/pages/Logistics/LogisticsRightPanel/LogisticsCarsPanel/LogisticsCarsItem'
import {
  CarListWrapper,
  Counter,
  GroupTitle,
  ListWrapper,
} from 'views/pages/Logistics/LogisticsRightPanel/LogisticsCarsPanel/styled'

type Props = {
  cars: Car[]
  dealId: string
  tripCounters?: Dict<number>
}

export const LogisticsCarsList: VFC<Props> = ({ cars, dealId, tripCounters }) => {
  const archivedCars = useMemo(() => cars.filter(c => c.status === CarStatuses.archived), [cars])
  const activeCars = useMemo(() => cars.filter(c => c.status === CarStatuses.active), [cars])
  const { t } = useTranslation('trips')

  return (
    <CarListWrapper>
      <div>
        <GroupTitle>
          {t('participatingCarsLabel')} <Counter>{activeCars.length}</Counter>
        </GroupTitle>
        <ListWrapper>
          {activeCars.map(car => (
            <LogisticsCarsItem car={car} tripCounter={tripCounters?.[car.id]} dealId={dealId} key={car.id} />
          ))}
        </ListWrapper>
      </div>
      {archivedCars.length > 0 && (
        <div>
          <GroupTitle>
            {t('common:archived')} <Counter>{archivedCars.length}</Counter>
          </GroupTitle>
          <ListWrapper>
            {archivedCars.map(car => (
              <ArchivedCarItem car={car} tripCounter={tripCounters?.[car.id]} key={car.id} />
            ))}
          </ListWrapper>
        </div>
      )}
    </CarListWrapper>
  )
}
