import React from 'react'
import { useTranslation } from 'react-i18next'

import env from 'env'
import { FloatNumberCell, NumberCell } from 'views/components/CommonTableComponents/CommonTableComponents'
import { Culture, Customer, Seller, Status, Team } from 'views/pages/Logistics/LogisticsTable/Cells'

export const useColumns = () => {
  const { t } = useTranslation('logistics')
  const currency = env.REACT_APP_CURRENCY_SYMBOL
  return React.useMemo(
    () => [
      {
        Header: t('common:status'),
        accessor: 'logistics_status',
        Cell: Status,
      },
      {
        Header: t('common:product'),
        accessor: 'product' as const,
        Cell: Culture,
      },
      {
        Header: t('list.tableHeaders.seller'),
        accessor: 'sale_bid' as const,
        Cell: Seller,
      },
      {
        Header: t('list.tableHeaders.customer'),
        accessor: 'purchase_bid' as const,
        Cell: Customer,
      },
      {
        Header: t('list.tableHeaders.distance'),
        accessor: 'distance' as const,
        Cell: NumberCell,
        headerHorizontalAlign: 'right',
      },
      {
        Header: t('list.tableHeaders.price_logistics', { currency }),
        accessor: 'price_logistics' as const,
        Cell: FloatNumberCell,
        headerHorizontalAlign: 'right',
      },
      {
        Header: t('list.tableHeaders.quantity'),
        accessor: 'quantity_in_bags' as const,
        Cell: NumberCell,
        headerHorizontalAlign: 'right',
      },
      {
        Header: t('list.tableHeaders.trip_delivered_quantity'),
        accessor: 'trip_delivered_quantity' as const,
        Cell: NumberCell,
        headerHorizontalAlign: 'right',
      },
      {
        Header: t('list.tableHeaders.trip_remained_quantity'),
        accessor: 'trip_remained_quantity' as const,
        Cell: NumberCell,
        headerHorizontalAlign: 'right',
      },
      {
        Header: t('list.tableHeaders.team'),
        accessor: 'team' as const,
        Cell: Team,
      },
    ],
    [t, currency],
  )
}
