import React, { VFC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Segment } from '@agro-club/agroclub-shared'

import { BidParameterDTO, ParameterCondition } from 'modules/domain/bid/types'
import { ProductParameter } from 'modules/domain/collection/types'
import { LabeledContainer } from 'views/components/FormComponents/FormComponents'
import { MainParamWrapper } from 'views/pages/Bid/BidAdd/styled'
import { NumberInput } from 'views/ui/NumberInput/NumberInput'

type Props = {
  parameter: BidParameterDTO
  parameters: ProductParameter[]
  onChange: (values: BidParameterDTO) => void
  isValid?: boolean
}

export const MainParam: VFC<Props> = ({ parameter, parameters, onChange, isValid = true }) => {
  const { t } = useTranslation('bid')

  const mainParameter = useMemo(
    () => parameters.find(p => p.id === parameter.parameter),
    [parameter.parameter, parameters],
  )

  return (
    <MainParamWrapper>
      <LabeledContainer label={t('form.mainParameter')}>{mainParameter?.name}</LabeledContainer>
      <Segment
        items={Object.entries(ParameterCondition).map(([key, value]) => ({
          id: key,
          title: value,
        }))}
        value={parameter.condition}
        onChange={(v: string) => onChange({ ...parameter, condition: v as BidParameterDTO['condition'] })}
      />
      <NumberInput
        onChange={v => {
          onChange({ ...parameter, parameter_value: v as BidParameterDTO['parameter_value'] })
        }}
        value={parameter.parameter_value}
        invalid={!isValid}
      />
    </MainParamWrapper>
  )
}
