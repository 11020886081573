import { Region } from 'modules/domain/collection/types'
import { AppGlobalState } from 'modules/types'

export const getProductsFetchProgress = (state: AppGlobalState) => state.collection.productsFetchProgress
export const getProducts = (state: AppGlobalState) => state.collection.products

export const getCompaniesFetchProgress = (state: AppGlobalState) => state.collection.companiesFetchProgress
export const getCompanies = (state: AppGlobalState) => state.collection.companies
export const getCompanyByInn = (state: AppGlobalState, inn) => state.collection.companies.find(c => c.inn === inn)

export const getDealStatuses = (state: AppGlobalState) => state.collection.dealStatuses
export const getDealStatusBySlug = (state: AppGlobalState, slug: string) =>
  state.collection.dealStatuses.find(s => s.slug === slug)
export const getDealStatusesFetchProgress = (state: AppGlobalState) => state.collection.dealStatusesFetchProgress

export const getTeams = (state: AppGlobalState) => state.collection.teams
export const getTeamsFetchProgress = (state: AppGlobalState) => state.collection.teamsFetchProgress

export const getRegions = (state: AppGlobalState) => state.collection.regions
export const getRootRegions = (state: AppGlobalState) => {
  const ids = state.collection.regions.map(r => r.id)
  return state.collection.regions.reduce((acc: Region[], region) => {
    if (!region.parent_id || !ids.includes(region.parent_id)) {
      acc.push(region)
    }
    return acc
  }, [])
}

export const getRegionsFetchProgress = (state: AppGlobalState) => state.collection.regionsFetchProgress

export const getLogisticStatuses = (state: AppGlobalState) => state.collection.logisticStatuses
export const getLogisticStatusesFetchProgress = (state: AppGlobalState) =>
  state.collection.logisticStatusesFetchProgress

const CollectionSelectors = {
  getProducts,
  getProductsFetchProgress,

  getCompanies,
  getCompaniesFetchProgress,
  getCompanyByInn,

  getDealStatuses,
  getDealStatusesFetchProgress,
  getDealStatusBySlug,

  getTeams,
  getTeamsFetchProgress,

  getRegions,
  getRootRegions,
  getRegionsFetchProgress,

  getLogisticStatuses,
  getLogisticStatusesFetchProgress,
}

export default CollectionSelectors
