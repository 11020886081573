import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'
import { Link, useLocation } from 'react-router-dom'

import { useCarList, useDealsByCar } from 'modules/domain/car/hooks'
import { CarStateUnity } from 'modules/domain/car/selectors'
import { CarStatuses } from 'modules/domain/car/types'
import { useCarDealTripList } from 'modules/domain/carTrip/hooks'
import { UnloadingType, VehicleType } from 'modules/domain/types'
import { LocationStateFrom } from 'types/location'
import { CarDealItem } from 'views/pages/Carrier/CarrierDetail/UserVehiclesBoard/CarDealItem'
import { ColumnSpoiler } from 'views/pages/Carrier/CarrierDetail/UserVehiclesBoard/ColumnSpoiler'
import { DealTripItem } from 'views/pages/Carrier/CarrierDetail/UserVehiclesBoard/DealTripItem'
import { groupedDeals } from 'views/pages/Carrier/CarrierDetail/UserVehiclesBoard/helpers'
import * as S from 'views/pages/Carrier/CarrierDetail/UserVehiclesBoard/styled'
import { UserVehiclesBoardColumn } from 'views/pages/Carrier/CarrierDetail/UserVehiclesBoard/UserVehiclesBoardColumn'
import { VehicleItem } from 'views/pages/Carrier/CarrierDetail/UserVehiclesBoard/VehicleItem'
import CarrierRoutes from 'views/pages/Carrier/routes'
import { SmallAddButton } from 'views/ui/SmallAddButton/SmallAddButton'

type Props = {
  userId: string
}

export type Vehicle = {
  id: string
  state_number: string
  hasTrailer: boolean
  trailer_state_number?: string
  type_of_car: VehicleType
  type_of_unloading: UnloadingType
  is_dump_truck: boolean
  side_height: number
  is_charter: boolean
  num_of_drivers: number
  num_of_deals: number
}

export const UserVehiclesBoard: React.FC<Props> = props => {
  const { userId } = props
  const { t } = useTranslation()
  const [carsProgress, cars] = useCarList(CarStateUnity.User, userId)
  const location = useLocation<LocationStateFrom>()
  const [selectedCarId, setSelectedCarId] = useState<string | undefined>()
  const [selectedDealId, setSelectedDealId] = useState<string | undefined>()

  const [dealsProgress, carDeals] = useDealsByCar(selectedCarId)
  const [tripsProgress, carTrips] = useCarDealTripList(selectedCarId, selectedDealId)

  const { active: activeDeals, archived: archivedDeals } = useMemo(() => groupedDeals(carDeals), [carDeals])
  const selectedDeal = carDeals.find(({ id }) => id === selectedDealId)

  const archivedCars = useMemo(() => cars.filter(c => c.status === CarStatuses.archived), [cars])
  const activeCars = useMemo(() => cars.filter(c => c.status === CarStatuses.active), [cars])
  const [firstActive] = activeCars
  const [firstArchived] = archivedCars
  useEffect(() => {
    if (firstActive?.id) {
      setSelectedCarId(firstActive.id)
    } else if (firstArchived?.id) {
      setSelectedCarId(firstArchived.id)
    }
  }, [firstActive?.id, firstArchived?.id])

  useEffect(() => {
    const [firstDeal] = carDeals
    if (selectedCarId && firstDeal) {
      setSelectedDealId(firstDeal.id)
    }
  }, [selectedCarId, carDeals])

  return (
    <S.UserColumnContainer>
      <UserVehiclesBoardColumn
        title={t('vehicles:firstColumnHeader')}
        counterValue={cars.length || 0}
        progress={carsProgress}
        headerButton={
          <Link
            to={{
              pathname: generatePath(CarrierRoutes.CarAdd, { id: userId }),
              state: { from: location.state?.from ? { ...location.state?.from } : undefined },
            }}
          >
            <SmallAddButton title={t('vehicles:add_vehicle')} onClick={() => undefined} />
          </Link>
        }
      >
        {activeCars.map(car => (
          <VehicleItem car={car} onClick={setSelectedCarId} isSelected={selectedCarId === car.id} key={car.id} />
        ))}

        <ColumnSpoiler visible={archivedCars.length > 0}>
          {archivedCars.map(car => (
            <VehicleItem car={car} onClick={setSelectedCarId} isSelected={selectedCarId === car.id} key={car.id} />
          ))}
        </ColumnSpoiler>
      </UserVehiclesBoardColumn>

      <UserVehiclesBoardColumn
        title={t('vehicles:secondColumnHeader')}
        counterValue={carDeals.length}
        progress={dealsProgress}
      >
        <>
          {activeDeals.map(deal => (
            <CarDealItem deal={deal} selected={selectedDealId === deal.id} key={deal.id} onClick={setSelectedDealId} />
          ))}
          <ColumnSpoiler visible={archivedDeals.length > 0}>
            {archivedDeals.map(deal => (
              <CarDealItem
                deal={deal}
                selected={selectedDealId === deal.id}
                key={deal.id}
                onClick={setSelectedDealId}
              />
            ))}
          </ColumnSpoiler>
        </>
      </UserVehiclesBoardColumn>

      <UserVehiclesBoardColumn
        title={t('vehicles:thirdColumnHeader')}
        counterValue={carTrips.length}
        progress={tripsProgress}
      >
        {carTrips.map(trip => (
          <DealTripItem carTrip={trip} archived={!!selectedDeal?.closed_at} key={trip.id} />
        ))}
      </UserVehiclesBoardColumn>
    </S.UserColumnContainer>
  )
}
