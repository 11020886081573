import { useMemo } from 'react'

import { useAction } from '@agro-club/agroclub-shared'
import { useFormik } from 'formik'
import { pick } from 'ramda'

import { WarehouseDTO } from 'modules/domain/types'
import UserAddressActions from 'modules/domain/userAddress/duck'
import { UserAddress } from 'modules/domain/userAddress/types'

type WarehouseFormikOptions = {
  address: UserAddress
  userId: string
}

export const useWarehouseFormik = ({ address, userId }: WarehouseFormikOptions) => {
  const editAction = useAction(UserAddressActions.editRequested)

  const warehouseProps = useMemo<WarehouseDTO>(
    () =>
      pick(
        [
          'gates_height',
          'is_dump_truck',
          'loader_type',
          'scales_capacity',
          'scales_length',
          'scales_remoteness',
          'schedule_from',
          'schedule_to',
          'unloading_type',
          'vehicle_type',
          'volume_per_day',
          'works_on_weekend',
          'car_delivery',
          'railway_delivery',
          'legal_type',
        ],
        address,
      ),
    [address],
  )

  const formik = useFormik<WarehouseDTO>({
    initialValues: {
      ...warehouseProps,
    },
    enableReinitialize: true,
    onSubmit: () => {
      if (!formik.isValid) {
        return
      }
      editAction(userId, address.id, formik.values)
    },
  })

  return formik
}
