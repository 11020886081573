import React from 'react'

import { IconLoader } from '@agro-club/agroclub-shared'
import styled, { StyledProps, keyframes } from 'styled-components'

type SpinnerSize = 'small' | 'medium' | 'big'

const rotate = keyframes`
  from  {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const DefaultIcon = styled(IconLoader)`
  width: 100%;
  height: 100%;
  fill: #fff;
  &[data-filled='true'] {
    fill: #037bff;
  }
`

const CustomIcon = styled.img`
  width: 100%;
  height: 100%;
`

const Container = styled.div<StyledProps<{ size: SpinnerSize }>>`
  display: flex;
  transform-origin: 51% 51%;
  ${(props: StyledProps<{ size: SpinnerSize }>) => {
    let size
    switch (props.size) {
      case 'small':
        size = '20px'
        break
      case 'medium':
        size = '50px'
        break
      case 'big':
        size = '80px'
        break
    }
    return {
      fontSize: size,
      width: size,
      height: size,
    }
  }}
`

const SpinnerContainer = styled(Container)`
  animation: ${rotate} 1s linear infinite;
`

type PropsType = {
  size?: SpinnerSize
  className?: string
  filled?: boolean
  spinnerImageUrl?: string
}

export const Spinner: React.FC<PropsType> = ({
  size = 'medium',
  className,
  filled = true,
  spinnerImageUrl,
}: PropsType) => {
  const spinnerJsx = spinnerImageUrl ? (
    <CustomIcon src={spinnerImageUrl} alt="spinner" />
  ) : (
    <DefaultIcon data-filled={filled} />
  )
  return (
    <SpinnerContainer size={size} className={className} data-test-id="loader">
      {spinnerJsx}
    </SpinnerContainer>
  )
}
