import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Progress, Spinner } from '@agro-club/agroclub-shared'

import { useSingleEntity } from 'modules/domain/common/useSingleEntity'
import { Executors } from 'modules/domain/deal/types'
import { endpoints } from 'modules/endpoints'
import { FormWrapper, LoadingWrapper } from 'views/pages/Deal/DealEdit/styled'
import { CancelButton } from 'views/styled/common'
import { ModalCommon } from 'views/ui/ModalCommon/ModalCommon'

import { DealStatusExecutorsModal } from './DealStatusExecutorsModal'

export const FORM_ITEMS = [
  { type: 'user-coordinators', value: 'user_coordinator' },
  { type: 'deal-coordinators', value: 'deal_coordinator' },
]

interface Props {
  id: string
  isOpen: boolean
  onClose: () => void
  onChange: () => void
}

export const DealStatusModal: React.FC<Props> = ({ id, isOpen, onClose, onChange }) => {
  const { t } = useTranslation('logistics')
  const [loadExecutorsProgress, executors] = useSingleEntity<Executors>(endpoints.executors(id), isOpen, false)
  const isLoading = loadExecutorsProgress === Progress.WORK

  const needToSetExecutors = useMemo(() => {
    const result: string[] = FORM_ITEMS.map(e => e.value).filter(field => !executors?.[field])
    return result.length > 0
  }, [executors])

  if (needToSetExecutors && !isLoading) {
    return <DealStatusExecutorsModal id={id} executors={executors} onClose={onClose} onChange={onChange} />
  }

  let content
  if (isLoading) {
    content = (
      <LoadingWrapper>
        <Spinner />
      </LoadingWrapper>
    )
  } else {
    content = <div>{t('areYouSureToChangeStatus', { status: 'new' })}</div>
  }

  return (
    <ModalCommon
      width={660}
      isOpen
      close={onClose}
      title={t('statusChanging')}
      content={<FormWrapper>{content}</FormWrapper>}
      footer={
        <>
          <Button
            onClick={() => {
              onChange()
              onClose()
            }}
            disabled={isLoading}
            intent="primary"
            filled
          >
            {t('common:yes')}
          </Button>
          <CancelButton onClick={onClose} intent="minor-action">
            {t('common:cancel')}
          </CancelButton>
        </>
      }
    />
  )
}
