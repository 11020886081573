import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { StylesConfig } from 'react-select'

import {
  ReactSelectOption,
  SimpleSelect,
  Typography,
  controlStyle,
  menuListStyle,
  menuStyle,
  optionStyle,
  useAction,
} from '@agro-club/agroclub-shared'
import { TooltipProps } from 'rc-tooltip/lib/Tooltip'
import styled from 'styled-components'

import DealActions from 'modules/domain/deal/duck'
import DealSelectors from 'modules/domain/deal/selectors'
import { ClosedReason } from 'modules/domain/deal/types'
import { ConfirmationTooltip } from 'views/ui/ConfirmationTooltip/ConfirmationTooltip'

export const customSelectStyles: StylesConfig<ReactSelectOption, boolean> = {
  control: (...args) => ({
    ...controlStyle(...args),
  }),
  menu: base => ({
    ...base,
    ...menuStyle,
    zIndex: 10,
    width: '100%',
  }),
  menuList: base => ({
    ...base,
    ...menuListStyle,
    maxHeight: '60vh',
    width: '100%',
  }),
  option: base => ({
    ...base,
    ...optionStyle,
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
  }),
}

const LabelStyled = styled.div`
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
  margin-bottom: 8px;
  ${Typography.body2Default}
`

type Props = {
  dealId: string
  children: TooltipProps['children']
  placement: TooltipProps['placement']
  visible: TooltipProps['visible']
  close: () => void
}

export const CloseDealModal: React.FC<Props> = props => {
  const { dealId, placement, visible, close } = props
  const { t } = useTranslation('deal')
  const [selectedReason, setReason] = useState<ClosedReason>(ClosedReason.price_ss)
  const closeDealAction = useAction(DealActions.closeDealRequested)
  const closeReasons = useSelector(DealSelectors.closeReasons)
  const closeReasonsProgress = useSelector(DealSelectors.closeReasonsProgress)
  const progress = useSelector(state => DealSelectors.closeDealProgress(state, dealId))
  const options = useMemo(() => closeReasons.map(({ slug, label }) => ({ id: slug, title: label })), [closeReasons])

  return (
    <ConfirmationTooltip
      visible={visible}
      overlayProps={{
        close,
        header: t('closeDealModal.title'),
        body: (
          <>
            <LabelStyled>{t('closeDealModal.reason')}</LabelStyled>
            <SimpleSelect
              placeholder={t('closeDealModal.reasonPlaceholder')}
              options={options}
              onChange={(value: ClosedReason) => {
                setReason(value)
              }}
              customStyles={customSelectStyles}
              value={selectedReason}
              progress={closeReasonsProgress}
            />
          </>
        ),
        okText: t('closeDeal'),
        okProgress: progress,
        okAction: () => {
          closeDealAction(dealId, selectedReason)
        },
        cancelText: t('common:cancel'),
      }}
      placement={placement}
    >
      {props.children}
    </ConfirmationTooltip>
  )
}
