import React from 'react'
import { useTranslation } from 'react-i18next'
import { CellProps } from 'react-table'

import { IconPhoneIn, IconPhoneMissed, IconPhoneOut, useAction } from '@agro-club/agroclub-shared'
import { format } from 'date-fns'

import formatPhone from 'helpers/formatPhone'
import CallsActions from 'modules/domain/calls/duck'
import { Calls } from 'modules/domain/calls/types'
import Player from 'views/components/Player/Player'
import { ClickableLink, PhoneLink, StyledText } from 'views/pages/Calls/CallsTable/styled'
import { UserLink } from 'views/ui/UserLink/UserLink'

const MAKE_CALL_WEB_HOOK = false

function UserCell({ row, value }: CellProps<Calls>) {
  const makeCall = useAction(CallsActions.makeCall)

  return (
    <>
      {row.original.client_id && (
        <UserLink
          user={{
            full_name: value,
            id: row.original.client_id,
            phone: row.original.client_phone,
          }}
        />
      )}
      {row.original.client_company && <StyledText>{row.original.client_company}</StyledText>}
      {MAKE_CALL_WEB_HOOK ? (
        <ClickableLink onClick={() => makeCall(row.original.client_phone)}>
          {formatPhone(row.original.client_phone)}
        </ClickableLink>
      ) : (
        <PhoneLink phone={row.original.client_phone} />
      )}
    </>
  )
}

const phoneIcon = {
  in: <IconPhoneIn />,
  out: <IconPhoneOut />,
  missed: <IconPhoneMissed />,
}

const useColumns = () => {
  const { t } = useTranslation('userCalls')
  return React.useMemo(
    () => [
      {
        Header: t('type_call'),
        accessor: 'call_type' as const,
        Cell: ({ value }: CellProps<Calls>) => phoneIcon[value],
      },
      {
        Header: t('date_time'),
        accessor: 'call_start' as const,
        Cell: ({ value }: CellProps<Calls>) => format(new Date(value), 'dd.MM.y kk:mm'),
      },
      {
        Header: t('coordinator'),
        accessor: 'coordinator_name' as const,
      },
      {
        Header: t('user'),
        accessor: 'client_name' as const,
        Cell: UserCell,
      },
      {
        Header: t('record'),
        accessor: 'record_link' as const,
        Cell: ({ row, value }: CellProps<Calls>) =>
          row.original.conversation_duration ? (
            <Player file={value} duration={row.original.conversation_duration} />
          ) : (
            <>&mdash;</>
          ),
      },
    ],
    [t],
  )
}

export default useColumns
