enum LogisticsRoutes {
  List = '/logistics',
  Detail = '/logistics/:id(\\d+)',
  Cars = '/logistics/:id(\\d+)/cars',
  TripDetails = '/logistics/:dealId(\\d+)/trip/:tripId(\\d+)',
  TripAdd = '/logistics/:dealId(\\d+)/add_trip/:carId(\\d+)',
  TripEdit = '/logistics/:dealId(\\d+)/edit_trip/:tripId(\\d+)',
}

export default LogisticsRoutes
