import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { generatePath, useParams } from 'react-router-dom'

import { IconArrowRight, IconBin, Progress, useAction } from '@agro-club/agroclub-shared'

import env from 'env'
import useFormatNumber from 'hooks/useFormatNumber'
import useLocationFrom from 'hooks/useLocationFrom'
import useMainParameter from 'hooks/useMainParameter'
import { useNotificationProgress } from 'hooks/useNotificationProgress'
import usePrevious from 'hooks/usePrevious'
import { Bid } from 'modules/domain/bid/types'
import { DealStatus } from 'modules/domain/collection/types'
import DealActions from 'modules/domain/deal/duck'
import { useReasonBySlug } from 'modules/domain/deal/hooks'
import DealSelectors from 'modules/domain/deal/selectors'
import UserBidActions from 'modules/domain/userBid/duck'
import { UserDeal } from 'modules/domain/userBid/types'
import { Tax } from 'views/pages/Dashboard/DealItem'
import { CloseDealModal } from 'views/pages/Deal/DealEdit/CloseDealModal'
import DealRoutes from 'views/pages/Deal/routes'
import * as S from 'views/pages/User/UserDetail/UserColumnBoard/styled'
import Tooltip from 'views/ui/Tooltip/Tooltip'

import { DealStatusModal } from './DealStatusModal'

type Props = {
  deal: UserDeal
  activeBid?: Bid
  statuses: DealStatus[]
}

export const Deal: React.FC<Props> = props => {
  const { deal, activeBid, statuses } = props
  const { t } = useTranslation('common')
  const { id: userId } = useParams<{ id: string }>()
  const [isCloseTooltipVisible, setCloseTooltipVisible] = useState<boolean>(false)
  const [isOpenStatusChangeModal, setIsOpenStatusChangeModal] = useState<boolean>(false)
  const hideTooltip = useCallback(() => {
    setCloseTooltipVisible(false)
  }, [setCloseTooltipVisible])
  const setActiveBid = useAction(UserBidActions.setActiveBidId)
  const closeDealProgress = useSelector(state => DealSelectors.closeDealProgress(state, deal.id))
  const prevCloseDealProgress = usePrevious(closeDealProgress)
  const formatNumber = useFormatNumber()
  const currency = env.REACT_APP_CURRENCY_SYMBOL
  const isPurchase = activeBid?.bid_type === 'purchase'
  const bid = isPurchase ? deal.sale_bid : deal.purchase_bid
  const exw = isPurchase ? deal.price_exw : deal.estimated_price_exw
  const cpt = isPurchase ? deal.estimated_price_cpt : deal.price_cpt
  const parameter = useMainParameter(bid.parameters)
  const reason = useReasonBySlug(deal.closed_reason)
  const from = useLocationFrom()

  useNotificationProgress(closeDealProgress)

  useEffect(() => {
    if (closeDealProgress === Progress.SUCCESS && prevCloseDealProgress === Progress.WORK) {
      setActiveBid(userId, activeBid?.id) // reload deals after status changed
    }
  }, [activeBid?.id, closeDealProgress, prevCloseDealProgress, setActiveBid, userId])

  const status = statuses.find(s => s.slug === deal.status)

  const changeStatusAction = useAction(DealActions.changeStatusRequested)
  const changeStatusProgress = useSelector(state => DealSelectors.changeStatusProgress(state, deal.id))

  useNotificationProgress(changeStatusProgress, t('deal:notifySuccessStatusChange'))
  const prevStatusProgress = usePrevious(changeStatusProgress)

  useEffect(() => {
    if (changeStatusProgress === Progress.SUCCESS && prevStatusProgress === Progress.WORK) {
      setActiveBid(userId, activeBid?.id) // reload deals after status changed
    }
  }, [activeBid?.id, changeStatusProgress, prevStatusProgress, setActiveBid, userId])

  if (!activeBid) {
    return null
  }

  const handlerChangeModal = e => {
    e.stopPropagation()
    setIsOpenStatusChangeModal(true)
  }

  return (
    <S.DealItem key={deal.id}>
      <S.DealItemLink
        to={{
          pathname: generatePath(DealRoutes.Edit, { id: deal.id }),
          state: { from },
        }}
      >
        <S.DealItemStatus>
          {deal.closed_at ? (
            <span>
              {t('deal:isClosed')} {reason}
            </span>
          ) : (
            <span>{status?.label || ''}</span>
          )}
          <span>{bid?.owner_team?.name || '-'}</span>
        </S.DealItemStatus>
        <S.DealItemExw>{t('rubKgEXW', { price: exw, currency })}</S.DealItemExw>
        <Tax>
          {t('common:tax_per_bag')} {currency} {deal?.tax_from_exw}
        </Tax>
        <S.DealItemCpt>{t('rubKgCPT', { price: cpt, currency })}</S.DealItemCpt>
        <S.DealItemDescription>
          {formatNumber(deal.quantity_in_bags)} {t('bag')} ({formatNumber(deal.quantity)} {t('ton')}) / {parameter}
        </S.DealItemDescription>
        <S.DealItemMargin>
          {currency} {formatNumber(deal.margin)} / {formatNumber(deal.margin_percent)} %
        </S.DealItemMargin>
        <S.DealItemCompany>
          {bid.company.name}
          {bid.has_work_deals && (
            <Tooltip placement="top" overlay={t('hasWorkDeals')}>
              <span>
                <S.HasDealIcon />
              </span>
            </Tooltip>
          )}
        </S.DealItemCompany>
        <S.DealItemAddress>{bid.address?.address}</S.DealItemAddress>
      </S.DealItemLink>

      {deal.status === 'draft' && (
        <S.DealProgressButton intent="minor-action" progress={changeStatusProgress} onClick={handlerChangeModal}>
          <IconArrowRight />
        </S.DealProgressButton>
      )}

      {isOpenStatusChangeModal && (
        <DealStatusModal
          id={deal.id}
          isOpen={isOpenStatusChangeModal}
          onChange={() => {
            changeStatusAction(deal.id, 'new')
          }}
          onClose={() => setIsOpenStatusChangeModal(false)}
        />
      )}

      {!deal.closed_at && (
        <CloseDealModal visible={isCloseTooltipVisible} close={hideTooltip} placement="topRight" dealId={deal.id}>
          <S.DealArchiveButtonWrapper>
            <S.DealArchiveButton
              onClick={() => {
                setCloseTooltipVisible(true)
              }}
              intent="minor-action"
            >
              <IconBin />
            </S.DealArchiveButton>
          </S.DealArchiveButtonWrapper>
        </CloseDealModal>
      )}
    </S.DealItem>
  )
}
