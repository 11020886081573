import React, { VFC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useAction } from '@agro-club/agroclub-shared'

import { useNotificationProgress } from 'hooks/useNotificationProgress'
import { useDealStatuses } from 'modules/domain/collection/hooks'
import DealActions from 'modules/domain/deal/duck'
import DealSelectors from 'modules/domain/deal/selectors'
import { Deal } from 'modules/domain/deal/types'
import { ChangeDealStatus } from 'views/components/ChangeDealStatus/ChangeDealStatus'

type Props = {
  deal: Deal
}

export const StatusSelect: VFC<Props> = props => {
  const { deal } = props
  const [, statuses] = useDealStatuses()
  const { t } = useTranslation('deal')
  const changeStatusAction = useAction(DealActions.changeStatusRequested)
  const changeStatusProgress = useSelector(state => DealSelectors.changeStatusProgress(state, deal.id))

  const { status, available_statuses } = deal
  const availableStatus = available_statuses && available_statuses.length && available_statuses[0]

  useNotificationProgress(changeStatusProgress, t('notifySuccessStatusChange'))

  const currentStatus = useMemo(() => statuses.find(s => s.slug === status), [statuses, status])
  const nextStatus = useMemo(
    () => (availableStatus && statuses.find(s => s.slug === availableStatus)) || undefined,
    [availableStatus, statuses],
  )

  return currentStatus ? (
    <ChangeDealStatus
      changeStatusAction={changeStatusAction}
      changeStatusProgress={changeStatusProgress}
      currentStatus={currentStatus}
      nextStatus={nextStatus}
      id={deal.id}
    />
  ) : null
}
