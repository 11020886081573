import { Chip, Switch, Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const DistrictWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 40vh;
  overflow-y: scroll;
  padding: 12px 24px 0 36px;
  border-top: ${({ theme }) => `1px solid ${theme.color.outlineMid}`};
  background-color: ${({ theme }) => theme.color.onPrimaryLight};
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Title = styled.div`
  flex: 1;
  ${Typography.body2Default};
  padding-bottom: 12px;
`

export const Mid = styled.span`
  ${Typography.body2Default};
  color: ${({ theme }) => theme.color.onSurfaceMidEmphasys};
`

export const ShowAll = styled.div`
  display: flex;
  align-items: flex-start;
  width: 172px;

  span:first-child {
    margin-right: 10px;
  }
`

export const StyledSwitch = styled(Switch)`
  button {
    margin-right: 0;
  }
`

export const List = styled.div`
  padding-bottom: 8px;
  display: flex;
  flex-wrap: wrap;

  & > *:not(:last-child) {
    margin: 0 8px 8px 0;
  }

  & > *:last-child {
    margin: 0 0 8px 0;
  }
`

export const HiddenListWrapper = styled.div`
  overflow-x: hidden;
  overflow-y: visible;
  position: relative;
`

export const HiddenList = styled(List)`
  flex-wrap: nowrap;
`

export const ThreeDotWrapper = styled.div`
  position: absolute;
  right: 0;
  width: 35px;
  height: 28px;
  text-align: right;
  font-size: 33px;
  line-height: 10px;
  background-image: linear-gradient(to right, transparent 0%, white 30%, white 100%);
  color: ${({ theme }) => theme.color.secondary300};
  cursor: default;
`

export const StyledChip = styled(Chip)`
  cursor: pointer;
`

export const RegionFilterWrapper = styled.div`
  & > div > div > label {
    ${Typography.caption1Default};
  }
`
