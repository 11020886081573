import React, { VFC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { SimpleSelect, useAction } from '@agro-club/agroclub-shared'

import { CarStatuses } from 'modules/domain/car/types'
import CarsActions from 'modules/domain/cars/duck'
import CarsSelectors from 'modules/domain/cars/selectors'

export const CarStatusFilter: VFC = () => {
  const { t } = useTranslation()
  const filterUpdated = useAction(CarsActions.filterUpdated)
  const { status, ...restFilter } = useSelector(CarsSelectors.filter)
  const options = useMemo(
    () =>
      Object.keys(CarStatuses).map(status => ({ id: CarStatuses[status], title: t(`vehicles:statuses.${status}`) })),
    [t],
  )

  const handleChange = (status: string) => {
    filterUpdated({ ...restFilter, status })
  }

  const handleClear = () => {
    filterUpdated({ ...restFilter, status: undefined })
  }

  return (
    <SimpleSelect
      value={status}
      isClearable
      additionalStyles={{
        control: {
          width: 180,
        },
      }}
      size="small"
      placeholder={t('cars:any')}
      options={options}
      onChange={handleChange}
      onClear={handleClear}
    />
  )
}
