import styled from 'styled-components'

const calcHeight = (count = 0) => count * 50

interface SubmenuProps {
  open?: boolean
  count?: number
}

export const SubMenu = styled.div<SubmenuProps>`
  max-height: ${({ open, count }) => (open ? calcHeight(count) : 0)}px;
  transition: max-height 0.2s;
  overflow-y: hidden;
`

export const CollabsedSubMenu = styled.div<SubmenuProps>`
  display: ${({ open }) => (open ? 'block' : 'none')};
  background: ${({ theme }) => theme.color.backgroundPrimary};
  position: absolute;
  top: 0px;
  left: calc(100% + 20px);
  border-radius: 8px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
  width: ${({ theme }) => theme.size.leftSideBarWidth}px;
  z-index: 1;
  overflow: hidden;
  & > * {
    border-radius: 0;
  }
`

export const GroupHeader = styled.div<{ active?: boolean; open?: boolean }>`
  position: relative;
  & > div:first-child {
    background-color: transparent;
  }
  & > svg {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%) rotate(${({ open }) => (open ? 180 : 0)}deg);
    transition: transform 0.5s;
    path {
      fill: ${({ theme, active }) => (active ? theme.color.primary500 : theme.color.textPrimary)};
    }
  }
  &:hover > svg path {
    fill: ${({ theme }) => theme.color.primary500};
  }
`

export const GroupContainer = styled.div`
  position: relative;
  a:not(:first-child) {
    margin-top: 0px;
  }
`

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 12px 12px 12px;
  height: 100%;
`
