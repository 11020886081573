import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OptionProps } from 'react-select'

import { AsyncSimpleSelect, Progress, SimpleSelectOption, SimpleSelectProps } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import { Company } from 'modules/domain/company/types'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { ListResponse } from 'types/api.d'
import { StyledOption } from 'views/styled/common'

const Inn = styled.span`
  color: ${({ theme }) => theme.color.onSurfaceMidEmphasys};
`

const Option: React.FC<OptionProps<SimpleSelectOption, boolean> & { value: string }> = props => {
  const { innerProps, value, label } = props
  return (
    <StyledOption onClick={innerProps.onClick}>
      {label} <Inn>{value}</Inn>
    </StyledOption>
  )
}

type OptionType = {
  value: string
  label: string
}

type Props = {
  onCompanyChange: (company: Company) => void
  invalid?: SimpleSelectProps['invalid']
  menuPlacement?: SimpleSelectProps['menuPlacement']
  defaultValue?: OptionType
}

type CompanySelectOption = {
  label: string
  value: string
}

export const CompanySelect: React.FC<Props> = props => {
  const { onCompanyChange, invalid, menuPlacement, defaultValue } = props
  const { t } = useTranslation(['company', 'common'])
  const [progress, setProgress] = useState<Progress>(Progress.IDLE)
  const [value, setValue] = useState<OptionType | undefined>(defaultValue)
  const [companies, setCompanies] = useState<Company[]>([])

  return (
    <AsyncSimpleSelect
      invalid={invalid}
      menuPlacement={menuPlacement}
      errorText={t('form.noCompanyError')}
      progress={progress === Progress.IDLE ? Progress.SUCCESS : progress}
      label={t('common:company')}
      placeholder={t('form.selectPlaceholder')}
      loadingMessage={t('common:loading')}
      noOptionsMessage={() => t('form.noOptionsMessage')}
      value={value}
      defaultOptions={value ? [value] : undefined}
      onChange={v => {
        const company = companies.find(c => c.inn === (v as CompanySelectOption)?.value)
        if (company) {
          onCompanyChange(company)
          setValue({ value: company.inn, label: company.name })
        }
      }}
      loadOptions={(query, callback) => {
        setProgress(Progress.WORK)
        apiClient
          .get<ListResponse<Company>>(endpoints.company(), { search: query, page: 1, page_size: 20 })
          .then(res => {
            setProgress(Progress.SUCCESS)
            setCompanies(res.results)
            callback(res.results.map(r => ({ value: r.inn, label: r.name })))
          })
          .catch(() => {
            setProgress(Progress.ERROR)
          })
      }}
      components={{
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        Option,
      }}
    />
  )
}
