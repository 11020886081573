import React, { VFC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button, useAction } from '@agro-club/agroclub-shared'

import PotentialBidActions from 'modules/domain/potentialBid/duck'
import PotentialBidSelectors from 'modules/domain/potentialBid/selectors'
import { ConfirmationTooltip } from 'views/ui/ConfirmationTooltip/ConfirmationTooltip'

type Props = {
  id: string
}

export const DeletePotentialButton: VFC<Props> = ({ id }) => {
  const [isConfirmVisible, setConfirmVisible] = useState<boolean>(false)
  const { t } = useTranslation('bid')
  const deleteAction = useAction(PotentialBidActions.removeRequested)
  const deleteProgress = useSelector(PotentialBidSelectors.removeProgress)

  const hideConfirm = useCallback(() => {
    setConfirmVisible(false)
  }, [setConfirmVisible])

  return (
    <ConfirmationTooltip
      visible={isConfirmVisible}
      placement="bottomRight"
      overlayProps={{
        header: t('deletePotential'),
        body: t('areYouSureToDeletePotential'),
        okText: t('common:delete'),
        okAction: () => {
          deleteAction(id)
        },
        okProgress: deleteProgress,
        cancelText: t('common:cancel'),
        close: hideConfirm,
      }}
    >
      <Button
        intent="danger"
        size="small"
        onClick={() => {
          setConfirmVisible(true)
        }}
      >
        {t('common:delete')}
      </Button>
    </ConfirmationTooltip>
  )
}
