import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StylesConfig } from 'react-select'

import {
  ReactSelectOption,
  SimpleSelect,
  SimpleSelectProps,
  controlStyle,
  menuListStyle,
  menuStyle,
  optionStyle,
} from '@agro-club/agroclub-shared'

import { Assignee, useAssignees } from 'views/components/AssigneeSelect/useAssignees'

export const customSelectStyles: StylesConfig<ReactSelectOption, boolean> = {
  control: (...args) => ({
    ...controlStyle(...args),
  }),
  menu: base => ({
    ...base,
    ...menuStyle,
    zIndex: 10,
    width: '100%',
  }),
  menuList: base => ({
    ...base,
    ...menuListStyle,
    width: '100%',
    maxHeight: '350px',
  }),
  option: base => ({
    ...base,
    ...optionStyle,
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    maxHeight: '32px',
  }),
}

type Props = {
  onAssigneeChange: (a: Assignee) => void
  invalid?: SimpleSelectProps['invalid']
  menuPlacement?: SimpleSelectProps['menuPlacement']
  initialValue: string | number | undefined
  label?: string
  errorText?: string
  placeholder?: string
  noOptionsMessage?: SimpleSelectProps['noOptionsMessage']
  required?: boolean
  teamId?: string
}

export const AssigneeSelect: React.FC<Props> = props => {
  const {
    onAssigneeChange,
    invalid,
    menuPlacement,
    initialValue,
    label,
    errorText,
    placeholder,
    noOptionsMessage,
    required,
    teamId,
  } = props
  const [value, setValue] = useState<string | number | undefined>(initialValue)
  const { t } = useTranslation()
  const [progress, assignees] = useAssignees(teamId)

  const options = useMemo<SimpleSelectProps['options']>(
    () =>
      assignees.map(assignee => ({
        id: assignee.id,
        title: assignee.short_name || assignee.phone,
      })),
    [assignees],
  )

  const onChange = useCallback(
    (id: string | number | undefined) => {
      setValue(id)
      const user = assignees.find(user => user.id === id)
      user && onAssigneeChange(user)
    },
    [setValue, onAssigneeChange, assignees],
  )

  return (
    <SimpleSelect
      isClearable
      invalid={invalid}
      errorText={errorText}
      progress={progress}
      onChange={onChange}
      customStyles={customSelectStyles}
      label={label}
      placeholder={placeholder}
      loadingMessage={t('common:loading')}
      noOptionsMessage={noOptionsMessage}
      options={options}
      value={value}
      isSearchable
      menuPlacement={menuPlacement}
      required={required}
    />
  )
}
