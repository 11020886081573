import { Progress } from '@agro-club/agroclub-shared'
import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer'
import { isEmpty } from 'ramda'

import { LIST_PAGE_SIZE } from 'modules/constants'
import { Calls, CallsListRequestFilter, CallsListRequestSorting, CallsState } from 'modules/domain/calls/types'
import { FetchError } from 'modules/domain/types'
import { arrToDict, getIds } from 'modules/utils/helpers'

const initialState: CallsState = {
  items: {},
  meta: {},
  ids: [],

  listFetchProgress: Progress.IDLE,
  listFetchError: null,
  accountsFetchProgress: Progress.IDLE,
  accountsFetchError: null,
  accounts: [],
  makeCall: '',

  filter: {},
  sorting: {},
  page: 1,
  total: 0,
  pageSize: LIST_PAGE_SIZE,

  callsBlockProgress: Progress.IDLE,
}

export type ListRequestedParams = { filter?: CallsListRequestFilter; sorting?: CallsListRequestSorting; page?: number }

class CallsReducer extends ImmerReducer<CallsState> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  listRequested(params: ListRequestedParams) {
    this.draftState.listFetchProgress = Progress.WORK
    this.draftState.listFetchError = null
    this.draftState.filter = (!isEmpty(params.filter) && params.filter) || this.draftState.filter
    this.draftState.sorting = params.sorting || this.draftState.sorting
    this.draftState.page = typeof params.page === 'undefined' ? this.draftState.page : params.page
  }

  listRequestSucceed(list: Calls[], total: number, page: number) {
    this.draftState.listFetchProgress = Progress.SUCCESS
    this.draftState.total = total
    this.draftState.page = page
    this.draftState.items = arrToDict(list)
    this.draftState.meta = arrToDict(
      list.map(item => ({
        id: item.id,
        fetchProgress: Progress.SUCCESS,
        fetchError: null,
      })),
    )
    this.draftState.ids = getIds(list)
  }

  listRequestFailed(error: FetchError) {
    this.draftState.listFetchProgress = Progress.ERROR
    this.draftState.listFetchError = error
  }

  filterUpdated(filter: CallsListRequestFilter) {
    this.draftState.filter = filter
    this.draftState.listFetchProgress = Progress.WORK
    this.draftState.page = 1
  }

  filterResetWithoutRequest() {
    this.draftState.filter = {}
    this.draftState.listFetchProgress = Progress.IDLE
    this.draftState.page = 1
  }

  sortingUpdated(sorting: CallsListRequestSorting) {
    this.draftState.sorting = sorting
    this.draftState.listFetchProgress = Progress.WORK
  }

  filterHasBeenReset() {
    this.draftState.filter = {}
    this.draftState.listFetchProgress = Progress.WORK
  }

  sortingHasBeenReset() {
    this.draftState.sorting = {}
    this.draftState.listFetchProgress = Progress.WORK
  }

  accountsRequested() {
    this.draftState.accountsFetchProgress = Progress.WORK
    this.draftState.accountsFetchError = null
    this.draftState.accounts = []
  }

  accountsRequestSuccess(data) {
    this.draftState.accountsFetchProgress = Progress.SUCCESS
    this.draftState.accounts = data
  }

  accountsRequestFailed(error: FetchError) {
    this.draftState.accountsFetchProgress = Progress.ERROR
    this.draftState.accountsFetchError = error
  }

  makeCall(phone) {
    this.draftState.makeCall = phone
  }
}

export const CallsActions = createActionCreators(CallsReducer)
export default CallsActions
export const reducer = createReducerFunction(CallsReducer, initialState)
