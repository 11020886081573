import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Progress, useAction, useDidMount, usePageQuery, useQuery } from '@agro-club/agroclub-shared'
import { isEmpty } from 'ramda'

import usePrevious from 'hooks/usePrevious'
import { ResourceHook } from 'modules/types'

import AuthSelectors from '../auth/selectors'
import TaskActions from './duck'
import TaskSelectors from './selectors'
import { Task, TaskListRequestFilter, TaskListRequestSorting } from './types'

export const useBidFilterAndSortQuery = () => {
  const query = useQuery()
  const sort_field = query.get('sort_field') as keyof Task
  const sort_reversed = query.get('sort_reversed')

  const teamStr = query.get('team')
  const teamArr = teamStr ? teamStr.split(',') : undefined

  const deadline_after = query.get('deadline_after') ?? undefined
  const deadline_before = query.get('deadline_before') ?? undefined

  const assigneeStr = query.get('assignee')
  const assigneeArr = assigneeStr ? assigneeStr.split(',') : undefined

  const filter: TaskListRequestFilter = {
    ...(teamArr && teamArr.length && { team: teamArr }),
    ...(assigneeArr && assigneeArr.length && { assignee: assigneeArr }),
    deadline_after,
    deadline_before,
  }

  const sorting: TaskListRequestSorting = {
    ...(sort_field && { sort_field }),
    ...(sort_reversed && { sort_reversed: sort_reversed === 'true' }),
  }

  return { filter, sorting }
}

export const useTaskList: ResourceHook<Task[], void[]> = () => {
  const progress = useSelector(TaskSelectors.listFetchProgress)
  const list = useSelector(TaskSelectors.list)
  const currentTeam = useSelector(AuthSelectors.team)
  const page = usePageQuery()
  let { filter } = useBidFilterAndSortQuery()
  const { sorting } = useBidFilterAndSortQuery()

  if (!filter?.team?.length && currentTeam?.id) {
    filter = { ...filter, team: [String(currentTeam.id)] }
  }

  const params = {
    ...(!isEmpty(filter) && { filter }),
    ...(!isEmpty(sorting) && { sorting }),
    ...(page && { page }),
  }

  const fetchAction = useAction(TaskActions.listRequested, params)

  useDidMount(fetchAction)

  return [progress, list]
}

export const useTask: ResourceHook<Task, [string]> = (id: string) => {
  const meta = useSelector(state => TaskSelectors.meta(state, id))
  const item = useSelector(state => TaskSelectors.item(state, id))
  const fetchAction = useAction(TaskActions.itemRequested, id)

  useDidMount(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  })

  return [meta.fetchProgress, item]
}

export const useTaskById: ResourceHook<Task, [string]> = (id: string) => {
  const meta = useSelector(state => TaskSelectors.meta(state, id))
  const item = useSelector(state => TaskSelectors.item(state, id))
  const fetchAction = useAction(TaskActions.itemRequested, id)

  useEffect(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  }, [item, id, fetchAction])

  return [meta.fetchProgress, item]
}

export const useMyTasks = (): [Progress, Task[]] => {
  const progress = useSelector(TaskSelectors.fetchMyTasksProgress)
  const items = useSelector(TaskSelectors.myTasks)
  const fetchAction = useAction(TaskActions.fetchMyTasksRequested)

  useDidMount(fetchAction)

  return [progress, items]
}

export const useCallBackList = (): [Progress, Task[]] => {
  const progress = useSelector(TaskSelectors.callBackFetchProgress)
  const list = useSelector(TaskSelectors.callBackList)
  const page = useSelector(TaskSelectors.callBackPage)
  const fetchAction = useAction(TaskActions.callBackListRequested, page ? { page } : {})

  useDidMount(fetchAction)

  return [progress, list]
}

export const useUserTasks = (userId?: string): [Progress, Task[]] => {
  const progress = useSelector(TaskSelectors.userTasksFetchProgress)
  const list = useSelector(state => TaskSelectors.userTasksList(state, userId))
  const fetchAction = useAction(TaskActions.userTasksRequested)
  const addProgress = useSelector(TaskSelectors.addProgress)
  const prevAddProgress = usePrevious(addProgress)
  const completeProgress = useSelector(TaskSelectors.completeProgress)
  const prevCompleteProgress = usePrevious(completeProgress)

  useEffect(() => {
    if (completeProgress === Progress.SUCCESS && prevCompleteProgress === Progress.WORK && userId) {
      fetchAction(userId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completeProgress, prevCompleteProgress, userId])

  useEffect(() => {
    if (addProgress === Progress.SUCCESS && prevAddProgress === Progress.WORK && userId) {
      fetchAction(userId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addProgress, prevAddProgress, userId])

  useDidMount(() => {
    userId && fetchAction(userId)
  })

  return [progress, list]
}
