import React from 'react'
import { Route, Switch } from 'react-router-dom'

import PageWrapper from 'views/components/PageWrapper/PageWrapper'
import { Dashboard } from 'views/pages/Dashboard/Dashboard'
import DashboardRoutes from 'views/pages/Dashboard/routes'

function DashboardPage() {
  return (
    <PageWrapper>
      <Switch>
        <Route path={DashboardRoutes.Dashboard}>
          <Dashboard />
        </Route>
      </Switch>
    </PageWrapper>
  )
}

export default DashboardPage
