import { Progress } from '@agro-club/agroclub-shared'
import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer'

import { Specification, SpecificationState, ownerDocuments } from 'modules/domain/specification/types'
import { AddError, FetchError } from 'modules/domain/types'

const initialState: SpecificationState = {
  items: {},
  meta: {},
  ids: [],

  specificationAddProgress: Progress.IDLE,
  specificationAddError: null,
  specificationAddErrorDetail: undefined,
  specificationAddErrorFields: undefined,

  getDocumentProgress: Progress.IDLE,
  getDocumentError: null,
  getDocumentErrorDetail: undefined,
  documentTypes: [],
  uploadProgress: Progress.IDLE,
  uploadError: null,
  uploadErrorDetail: undefined,
  getDocumentFilesProgress: Progress.IDLE,
  getDocumentFilesError: null,
  getDocumentFilesErrorDetail: undefined,
  documentFiles: [],
  ownerDocuments: [],
  deleteDocumentProgress: Progress.IDLE,
}

class SpecificationReducer extends ImmerReducer<SpecificationState> {
  AddRequested(_data: Specification) {
    this.draftState.specificationAddProgress = Progress.WORK
    this.draftState.specificationAddError = null
    this.draftState.specificationAddErrorDetail = undefined
    this.draftState.specificationAddErrorFields = undefined
  }

  AddSucceed() {
    this.draftState.specificationAddProgress = Progress.SUCCESS
    this.draftState.specificationAddError = null
    this.draftState.specificationAddErrorDetail = undefined
    this.draftState.specificationAddErrorFields = undefined
  }

  AddError(error: AddError, errorDetail?: string, errorFields?: Record<string, string[]>) {
    this.draftState.specificationAddProgress = Progress.WORK
    this.draftState.specificationAddError = error
    this.draftState.specificationAddErrorDetail = errorDetail
    this.draftState.specificationAddErrorFields = errorFields
  }

  AddResetErrors() {
    this.draftState.specificationAddProgress = Progress.IDLE
    this.draftState.specificationAddError = null
    this.draftState.specificationAddErrorDetail = undefined
    this.draftState.specificationAddErrorFields = undefined
  }

  uploadDocumentRequested(_file: File) {
    this.draftState.uploadProgress = Progress.WORK
  }

  uploadDocumentSucccess(file: ownerDocuments) {
    this.draftState.ownerDocuments = [...this.draftState.ownerDocuments, file]
    this.draftState.uploadProgress = Progress.SUCCESS
  }

  uploadDocumentFailed(error: FetchError, errorDetail?: string) {
    this.draftState.uploadProgress = Progress.ERROR
    this.draftState.uploadError = error
    this.draftState.uploadErrorDetail = errorDetail
  }

  deleteOwnerDocument(pk: string) {
    if (pk) {
      this.draftState.ownerDocuments = [...this.draftState.ownerDocuments.filter(el => el.pk !== pk)]
    } else if (!pk) {
      this.draftState.ownerDocuments = []
    }
  }

  getDocumentFilesRequested(_id: string) {
    this.draftState.getDocumentFilesProgress = Progress.WORK
    this.draftState.getDocumentFilesErrorDetail = undefined
    this.draftState.getDocumentFilesError = null
  }

  getDocumentFilesSucceed(list) {
    this.draftState.getDocumentFilesProgress = Progress.SUCCESS
    this.draftState.getDocumentFilesErrorDetail = undefined

    this.draftState.documentFiles = list
  }

  getDocumentFilesFailed(error: FetchError, errorDetail?: string) {
    this.draftState.getDocumentFilesProgress = Progress.ERROR
    this.draftState.getDocumentFilesError = error
    this.draftState.getDocumentFilesErrorDetail = errorDetail
  }

  deleteDocumentRequested(_id: string, _document_id: string) {
    this.draftState.deleteDocumentProgress = Progress.WORK
  }

  deleteDocumentSucccess() {
    this.draftState.deleteDocumentProgress = Progress.SUCCESS
  }

  deleteDocumentFailed() {
    this.draftState.deleteDocumentProgress = Progress.ERROR
  }
}

const SpecificationActions = createActionCreators(SpecificationReducer)
export default SpecificationActions
export const reducer = createReducerFunction(SpecificationReducer, initialState)
