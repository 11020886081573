import { Progress } from '@agro-club/agroclub-shared'
import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer'

import { CarTrip, CarTripState, ListRequestedParams } from 'modules/domain/carTrip/types'
import { FetchError } from 'modules/domain/types'
import { arrToDict, getIds } from 'modules/utils/helpers'

const initialState: CarTripState = {
  tripsByCar: {},
  tripsByCarDeal: {},

  items: {},
  ids: [],
  page: 1,
  total: 0,

  listFetchProgress: Progress.IDLE,
  listFetchError: null,
}

class CarTripReducer extends ImmerReducer<CarTripState> {
  listRequested({ carId, dealId, page }: ListRequestedParams) {
    if (dealId) {
      this.draftState.tripsByCarDeal[carId] = this.draftState.tripsByCarDeal[carId] ?? {}
      this.draftState.tripsByCarDeal[carId][dealId] = this.draftState.tripsByCarDeal[carId][dealId] ?? []
    } else {
      this.draftState.tripsByCar[carId] = this.draftState.tripsByCar[carId] ?? []
    }
    this.draftState.listFetchProgress = Progress.WORK
    this.draftState.listFetchErrorDetail = undefined
    this.draftState.listFetchError = null
    this.draftState.page = page ?? 1
  }

  listRequestSucceed(list: CarTrip[], { carId, dealId, page }: ListRequestedParams, total: number) {
    this.draftState.listFetchProgress = Progress.SUCCESS
    this.draftState.listFetchErrorDetail = undefined
    this.draftState.ids = getIds(list)
    this.draftState.total = total
    this.draftState.page = page ?? 1

    this.draftState.items = {
      ...this.draftState.items,
      ...arrToDict(list),
    }

    if (dealId) {
      this.draftState.tripsByCarDeal[carId] = {
        ...this.draftState.tripsByCarDeal[carId],
        [dealId]: getIds(list),
      }
    } else {
      this.draftState.tripsByCar[carId] = getIds(list)
    }
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  listRequestFailed(error: FetchError, errorDetail?: string) {
    this.draftState.listFetchProgress = Progress.ERROR
    this.draftState.listFetchError = error
    this.draftState.listFetchErrorDetail = errorDetail
  }
}

export const CarTripActions = createActionCreators(CarTripReducer)
export default CarTripActions
export const reducer = createReducerFunction(CarTripReducer, initialState)
