import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import { Bid } from 'modules/domain/bid/types'
import UserBidActions from 'modules/domain/userBid/duck'
import * as managers from 'modules/domain/userBid/managers'
import UserBidSelectors from 'modules/domain/userBid/selectors'

import { DealGroup } from './types'

export const fetchUserBidList = function* ({ payload: userId }: ReturnType<typeof UserBidActions.bidListRequested>) {
  try {
    const list: Bid[] = yield call(managers.getUserBidList, userId)
    yield put(UserBidActions.bidListRequestSucceed(userId, list))

    const publishedBids = yield select(state => UserBidSelectors.publishedBids(state, userId))

    if (publishedBids && publishedBids.length) {
      yield put(UserBidActions.setActiveBidId(userId, publishedBids[0].id))
    }
  } catch (err) {
    // const { type, detail } = RequestError.parseError(err)
    yield put(UserBidActions.bidListRequestError(userId))
  }
}

export const fetchDealGroupList = function* ({
  payload: [userId, bidId],
}: ReturnType<typeof UserBidActions.setActiveBidId>) {
  try {
    const list: DealGroup[] = yield call(managers.getDealGroupList, bidId)
    yield put(UserBidActions.dealGroupListRequestSucceed(userId, list))
  } catch (err) {
    // const { type, detail } = RequestError.parseError(err)
    yield put(UserBidActions.dealGroupListRequestError(userId))
  }
}

const UserBidSaga = function* () {
  yield all([
    takeLatest(UserBidActions.bidListRequested.type, fetchUserBidList),
    takeLatest(UserBidActions.setActiveBidId.type, fetchDealGroupList),
  ])
}

export default UserBidSaga
