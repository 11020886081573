import { RefObject, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { Progress } from '@agro-club/agroclub-shared'

import usePrevious from 'hooks/usePrevious'
import { getDealId } from 'views/pages/Deal/DealBoard/DealItem'

type Options = {
  progress: Progress
  boardRef: RefObject<HTMLDivElement>
  wrapperRef: RefObject<HTMLDivElement>
}

export default function useScrollToDeal({ progress, boardRef, wrapperRef }: Options) {
  const prevProgress = usePrevious(progress)
  const location = useLocation<{ dealToScroll?: string }>()
  const dealId = location.state?.dealToScroll

  useEffect(() => {
    if (prevProgress === Progress.WORK && progress === Progress.SUCCESS) {
      if (!dealId) {
        return
      }
      const elId = getDealId(dealId)
      const dealEl = document.getElementById(elId)
      const boardEl = boardRef.current
      const wrapperEl = wrapperRef.current

      if (!wrapperEl || !dealEl || !dealEl?.offsetParent || !boardEl || dealEl?.offsetParent !== boardEl) {
        console.error('DealBoard scroll restoration is broken!')
        return
      }

      const scrollLeft = dealEl.offsetLeft - wrapperEl.clientWidth / 2 + dealEl.clientWidth / 2
      const scrollTop = dealEl.offsetTop - wrapperEl.clientHeight / 2 + dealEl.clientHeight / 2

      wrapperEl.scrollLeft = scrollLeft
      wrapperEl.scrollTop = scrollTop
    }
  }, [progress, dealId, prevProgress, boardRef, wrapperRef])
}
