import React from 'react'

import { Progress } from '@agro-club/agroclub-shared'

import * as ColumnBoard from 'views/components/ColumnBoard/styled'
import { Spinner } from 'views/components/CommonTableComponents/CommonTableComponents'
import * as S from 'views/pages/Carrier/CarrierDetail/UserVehiclesBoard/styled'

type Props = {
  children: React.ReactNode
  title: string
  counterValue: number
  headerButton?: React.ReactNode
  progress: Progress
}

export const UserVehiclesBoardColumn: React.FC<Props> = ({ children, title, counterValue, headerButton, progress }) => (
  <ColumnBoard.Column>
    <S.BoardHeader size="small">
      <S.Title>
        {title}
        <ColumnBoard.Counter>{counterValue}</ColumnBoard.Counter>
      </S.Title>
      {headerButton}
    </S.BoardHeader>

    {progress === Progress.WORK ? (
      <div>
        <Spinner />
      </div>
    ) : (
      children
    )}
  </ColumnBoard.Column>
)
