import { useEffect, useState } from 'react'

import { Progress } from '@agro-club/agroclub-shared'

import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'

export const useExecutorsByType = (type: string): [Progress, any] => {
  const [executors, setExecutors] = useState()
  const [progress, setProgress] = useState<Progress>(Progress.IDLE)

  useEffect(() => {
    setProgress(Progress.WORK)
    apiClient
      .get(endpoints.executorsByType(type), { page_size: 10000 })
      .then((data: any) => {
        setExecutors(data?.results)
        setProgress(Progress.SUCCESS)
      })
      .catch(() => setProgress(Progress.ERROR))
  }, [type])

  return [progress, executors]
}
