import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Progress, useAction } from '@agro-club/agroclub-shared'

import { Bid } from 'modules/domain/bid/types'
import UserBidActions from 'modules/domain/userBid/duck'
import UserBidSelectors from 'modules/domain/userBid/selectors'

export const useUserBidList = (userId: string): [Progress, Bid[]] => {
  const progress = useSelector(state => UserBidSelectors.bidListProgress(state, userId))
  const list = useSelector(state => UserBidSelectors.bidList(state, userId))
  const fetchAction = useAction(UserBidActions.bidListRequested, userId)
  useEffect(() => {
    userId && fetchAction()
  }, [userId, fetchAction])

  return [progress, list]
}
