import React, { VFC, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Progress } from '@agro-club/agroclub-shared'

import capitalizeFirstLetter from 'helpers/capitalizeFirstLetter'
import usePrevious from 'hooks/usePrevious'
import { Sure } from 'views/components/ChangeDealStatus/styled'
import { ConfirmationTooltip } from 'views/ui/ConfirmationTooltip/ConfirmationTooltip'

export type Props = {
  changeStatusProgress: Progress
  nextStatus: { label: string; slug: string }
  changeStatusAction: (id: string, status: string) => void
  id: string
}

export const DealStatusButton: VFC<Props> = ({ changeStatusProgress, nextStatus, changeStatusAction, id }) => {
  const [isConfirmVisible, setConfirmVisible] = useState<boolean>(false)
  const { t } = useTranslation('logistics')
  const close = useCallback(() => {
    setConfirmVisible(false)
  }, [setConfirmVisible])

  const prevProgress = usePrevious<Progress>(changeStatusProgress)
  React.useEffect(() => {
    if (prevProgress === Progress.WORK && changeStatusProgress === Progress.SUCCESS) {
      close()
    }
  }, [close, prevProgress, changeStatusProgress])

  const footer = useMemo(
    () => (
      <>
        <Button
          progress={changeStatusProgress}
          intent="primary"
          filled
          onClick={() => {
            changeStatusAction(id, nextStatus.slug)
          }}
        >
          {t('common:change')}
        </Button>
        <Button intent="primary" onClick={close}>
          {t('common:cancel')}
        </Button>
      </>
    ),
    [changeStatusProgress, t, close, changeStatusAction, id, nextStatus.slug],
  )

  return (
    <ConfirmationTooltip
      visible={isConfirmVisible}
      placement="bottomRight"
      overlayProps={{
        header: t('statusChanging'),
        body: <Sure>{t('areYouSureToChangeStatus', { status: capitalizeFirstLetter(nextStatus.label) })}</Sure>,
        footer,
        close,
      }}
    >
      <Button
        filled
        intent="primary"
        size="small"
        onClick={() => {
          setConfirmVisible(true)
        }}
      >
        {capitalizeFirstLetter(nextStatus.label)}
      </Button>
    </ConfirmationTooltip>
  )
}
