import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Accordion, AccordionContent, AccordionHeader, AccordionItem, Progress } from '@agro-club/agroclub-shared'

import { Bid } from 'modules/domain/bid/types'
import { CommentType } from 'modules/domain/comment/types'
import DealSelectors from 'modules/domain/deal/selectors'
import UserSelectors from 'modules/domain/user/selectors'
import { endpoints } from 'modules/endpoints'
import { Comments } from 'views/components/Comments/Comments'
import { RightPanelComments } from 'views/components/Comments/styled'
import { Header } from 'views/components/UserRightPanel/AccordionHeader/AccordionHeader'
import { DealShipping, DealShippingSummary } from 'views/pages/Deal/DealEdit/DealShipping'
import { Documents, DocumentsFrom, DocumentsSummary } from 'views/pages/Deal/DealEdit/Documents'
import { Files, FilesSummary } from 'views/pages/Deal/DealEdit/Files'
import { PurchaseFiles, PurchaseFilesSummary } from 'views/pages/Deal/DealEdit/PurchaseFiles'
import { SaleFiles, SaleFilesSummary } from 'views/pages/Deal/DealEdit/SaleFiles'
import { Container } from 'views/pages/Deal/DealEdit/styled'

export const DealBlock: React.FC<{ dealId: string }> = ({ dealId }) => {
  const { t } = useTranslation('common')
  const [commentsSummary, setCommentsSummary] = useState<string>('')

  const { id } = useParams<{ id: string }>()
  const deal = useSelector(state => DealSelectors.item(state, id))
  const bid = (deal?.purchase_bid as Bid) || undefined
  const ownerId = bid?.owner.id
  const purchaseFilesProgress = useSelector(state => UserSelectors.filesFetchProgress(state, ownerId))
  const purchaseDocumentTypesProgress = useSelector(state => DealSelectors.documentProgress(state, ownerId))

  const options = [
    { value: 'comment', title: t('comments'), isOpened: true },
    { value: 'files', title: t('dealFiles') },
    { value: 'saleFiles', title: t('saleFiles') },
    { value: 'purchaseFiles', title: t('purchaseFiles') },
    { value: 'saleDocuments', title: t('saleDocuments') },
    { value: 'purchaseDocuments', title: t('purchaseDocuments') },
    { value: 'shipping', title: t('shipping') },
  ]

  const components = {
    comment: (
      <RightPanelComments>
        <Comments
          id={dealId}
          type={CommentType.deal}
          url={endpoints.dealComment(dealId)}
          maxHeight={400}
          popSummary={setCommentsSummary}
        />
      </RightPanelComments>
    ),
    files: <Files />,
    saleFiles: <SaleFiles ready={purchaseFilesProgress === Progress.SUCCESS} />,
    purchaseFiles: <PurchaseFiles />,
    saleDocuments: (
      <Documents context={DocumentsFrom.sale} ready={purchaseDocumentTypesProgress === Progress.SUCCESS} />
    ),
    purchaseDocuments: <Documents context={DocumentsFrom.purchase} ready />,
    shipping: <DealShipping dealId={dealId} />,
  }

  const summary = {
    comment: <div>{commentsSummary ? `${t('common:last')}: ${commentsSummary}` : t('common:no')}</div>,
    files: <FilesSummary />,
    saleFiles: <SaleFilesSummary />,
    purchaseFiles: <PurchaseFilesSummary />,
    saleDocuments: <DocumentsSummary context={DocumentsFrom.sale} />,
    purchaseDocuments: <DocumentsSummary context={DocumentsFrom.purchase} />,
    shipping: <DealShippingSummary dealId={dealId} />,
  }

  return (
    <Container>
      <Accordion single>
        {options.map(option => (
          <AccordionItem key={option.title} id={option.title} isOpened={Boolean(option?.isOpened)}>
            <AccordionHeader>
              <Header summary={summary[option.value]}>{option.title}</Header>
            </AccordionHeader>
            <AccordionContent>{components[option.value]}</AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </Container>
  )
}
