import { Reducer, combineReducers } from 'redux'

import { connectRouter } from 'connected-react-router'
import { History } from 'history'

import { reducer as auth } from 'modules/domain/auth/duck'
import { reducer as bid } from 'modules/domain/bid/duck'
import { reducer as calls } from 'modules/domain/calls/duck'
import { reducer as callStatistics } from 'modules/domain/callStatistics/duck'
import { reducer as car } from 'modules/domain/car/duck'
import { reducer as carrier } from 'modules/domain/carrier/duck'
import { reducer as cars } from 'modules/domain/cars/duck'
import { reducer as carTrips } from 'modules/domain/carTrip/duck'
import { reducer as collection } from 'modules/domain/collection/duck'
import { reducer as comment } from 'modules/domain/comment/duck'
import { reducer as company } from 'modules/domain/company/duck'
import { reducer as dashboard } from 'modules/domain/dashboard/duck'
import { reducer as deal } from 'modules/domain/deal/duck'
import { reducer as driver } from 'modules/domain/driver/duck'
import { reducer as logisticsDeal } from 'modules/domain/logisticsDeal/duck'
import { reducer as potentialBid } from 'modules/domain/potentialBid/duck'
import { reducer as specification } from 'modules/domain/specification/duck'
import { reducer as task } from 'modules/domain/task/duck'
import { reducer as trip } from 'modules/domain/trip/duck'
import { reducer as user } from 'modules/domain/user/duck'
import { reducer as userAddress } from 'modules/domain/userAddress/duck'
import { reducer as userBid } from 'modules/domain/userBid/duck'
import { reducer as userCalls } from 'modules/domain/userCalls/duck'
import { reducer as userContact } from 'modules/domain/userContact/duck'

const rootReducer = (history: History): Reducer =>
  combineReducers({
    router: connectRouter(history),
    auth,
    user,
    company,
    bid,
    collection,
    userBid,
    deal,
    task,
    userAddress,
    dashboard,
    comment,
    userContact,
    car,
    driver,
    logisticsDeal,
    trip,
    cars,
    carrier,
    userCalls,
    calls,
    callStatistics,
    carTrips,
    potentialBid,
    specification,
  })

export default rootReducer
