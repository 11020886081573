import { Progress } from '@agro-club/agroclub-shared'
import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer'

import { FetchError } from 'modules/domain/types'
import { UserCallItem, UserCallsState } from 'modules/domain/userCalls/types'

const initialState: UserCallsState = {
  items: {},
}

const DEFAULT_ITEM: UserCallItem = {
  calls: [],
  listFetchProgress: Progress.WORK,
  listFetchNextProgress: Progress.IDLE,
  listFetchNextError: null,
  page: 1,
  total: 0,
  count: 0,
  pageSize: 5,
}

class UserCallsReducer extends ImmerReducer<UserCallsState> {
  listRequested(userId: string, page?: number) {
    if (!this.draftState.items[userId]) {
      this.draftState.items[userId] = { ...DEFAULT_ITEM }
    } else {
      this.draftState.items[userId].listFetchProgress = Progress.WORK
      this.draftState.items[userId].page = typeof page === 'undefined' ? this.draftState.items[userId].page : page
    }
  }

  listRequestSucceed(userId: string, data) {
    this.draftState.items[userId].listFetchProgress = Progress.SUCCESS
    this.draftState.items[userId].calls = data?.results || []
    this.draftState.items[userId].total = Math.ceil(data.count / data.page_size)
    this.draftState.items[userId].count = data.count
  }

  listRequestFailed(userId: string) {
    if (!this.draftState.items[userId]) {
      this.draftState.items[userId] = { ...DEFAULT_ITEM }
    }
    this.draftState.items[userId].listFetchProgress = Progress.ERROR
  }

  listRequestedNext(userId: string, page: number) {
    this.draftState.items[userId].page = page
    this.draftState.items[userId].listFetchNextProgress = Progress.WORK
    this.draftState.items[userId].listFetchNextError = null
  }

  listRequestNextSucceed(userId: string, data) {
    this.draftState.items[userId].listFetchNextProgress = Progress.SUCCESS
    this.draftState.items[userId].calls = data?.results || []
    this.draftState.items[userId].total = Math.ceil(data.count / data.page_size)
  }

  listRequestNextFailed(userId, error: FetchError) {
    this.draftState.items[userId].listFetchNextProgress = Progress.ERROR
    this.draftState.items[userId].listFetchNextError = error
  }
}

export const UserCallsActions = createActionCreators(UserCallsReducer)
export default UserCallsActions
export const reducer = createReducerFunction(UserCallsReducer, initialState)
