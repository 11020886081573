import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { generatePath, useHistory, useLocation, useParams } from 'react-router-dom'

import { Tabs, useAction, useHelmet } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import DashboardActions from 'modules/domain/dashboard/duck'
import DashboardSelectors from 'modules/domain/dashboard/selectors'
import TaskSelectors from 'modules/domain/task/selectors'
import { Counter } from 'views/components/ColumnBoard/styled'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import { Calls } from 'views/pages/Dashboard/Calls'
import { DashboardFilters } from 'views/pages/Dashboard/DashboardFilters'
import { Matches } from 'views/pages/Dashboard/Matches'
import { New } from 'views/pages/Dashboard/New'
import { NewUsers } from 'views/pages/Dashboard/NewUsers'
import { Responses } from 'views/pages/Dashboard/Responses'
import DashboardRoutes from 'views/pages/Dashboard/routes'
import { Tasks } from 'views/pages/Dashboard/Tasks'
import * as Header from 'views/ui/Header/Header'

const TabWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.backgroundSecondary};
  padding-left: 7px;
  border-top: ${({ theme }) => `1px solid ${theme.color.outlineMid}`};
  padding-top: 14px;
`

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const DashboardLayout = styled.div`
  flex-grow: 1;
  display: flex;
  overflow: hidden;
`

const TylesWrapper = styled.div`
  flex: 1;
  overflow-y: scroll;
`

const Tiles = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fit, 272px);
  padding: 16px 24px;
  border-top: ${({ theme }) => `1px solid ${theme.color.outlineMid}`};
`

const RightBLock = styled.div`
  background-color: ${({ theme }) => theme.color.onPrimaryLight};
  border-left: ${({ theme }) => `1px solid ${theme.color.outlineMid}`};
  width: 292px;
  display: flex;
  flex-direction: column;
`

export function Dashboard() {
  const { t } = useTranslation('dashboard')
  useHelmet({ title: t('metaTitle') })
  const responses = useSelector(DashboardSelectors.responsesList)
  const responsesLength = responses.length
  const matches = useSelector(DashboardSelectors.matchesList)
  const matchesLength = matches.length
  const newItems = useSelector(DashboardSelectors.newList)
  const newItemsLength = newItems.length
  const newUsersCount = useSelector(DashboardSelectors.newUsersCount)

  const cancelDashRequest = useAction(DashboardActions.cancelDashboardRequest)

  useEffect(
    () => () => {
      cancelDashRequest()
    },
    [cancelDashRequest],
  )

  const callBackTotal = useSelector(TaskSelectors.callBackTotal)

  const tabTitle = useCallback(
    (title: string, counter: number, isImportant?: boolean) => (
      <>
        {title} <Counter isImportant={isImportant}>{counter}</Counter>
      </>
    ),
    [],
  )

  const options = useMemo<{ title: any; value: string }[]>(
    () => [
      {
        value: 'responses',
        title: tabTitle(t('responses'), responsesLength),
      },
      {
        value: 'matches',
        title: tabTitle(t('matches'), matchesLength),
      },
      {
        value: 'callBack',
        title: tabTitle(t('callBack'), callBackTotal, callBackTotal > 0),
      },
      {
        value: 'new',
        title: tabTitle(t('new'), newItemsLength),
      },
      {
        value: 'newUsers',
        title: tabTitle(t('newUsers'), newUsersCount, newUsersCount > 0),
      },
    ],
    [tabTitle, t, responsesLength, matchesLength, callBackTotal, newItemsLength, newUsersCount],
  )
  const { activeTab: activeTabParams } = useParams<{ activeTab?: string }>()
  const [activeTab, setActiveTab] = useState<string>(activeTabParams || options[2].value)
  const location = useLocation()
  const history = useHistory()

  const renderItems = useCallback(() => {
    switch (activeTab) {
      case 'responses':
        return (
          <Tiles>
            <Responses />
          </Tiles>
        )
      case 'matches':
        return (
          <Tiles>
            <Matches />
          </Tiles>
        )
      case 'new':
        return (
          <Tiles>
            <New />
          </Tiles>
        )
      case 'newUsers':
        return <NewUsers />
      case 'callBack':
        return <Calls />
      default:
        return null
    }
  }, [activeTab])

  return (
    <>
      <Layout.Header>
        <Header.Root>
          <Header.Title title={t('menu:dashboard')} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <Wrapper>
          <DashboardFilters />
          <TabWrapper>
            <Tabs
              options={options}
              active={activeTab}
              onChange={val => {
                history.replace({
                  pathname: generatePath(DashboardRoutes.Dashboard, { activeTab: val }),
                  search: location.search,
                })
                setActiveTab(val)
              }}
            />
          </TabWrapper>
          <DashboardLayout>
            <TylesWrapper>{renderItems()}</TylesWrapper>
            <RightBLock>
              <Tasks />
            </RightBLock>
          </DashboardLayout>
        </Wrapper>
      </Layout.Content>
    </>
  )
}
