import { Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const Key = styled.div`
  color: ${props => props.theme.color.textSecondary};
  ${Typography.body2Default};
  white-space: nowrap;
`
export const Value = styled.div`
  ${Typography.body2Default};
`
