import React, { FC, ReactNode } from 'react'

import { Typography, defaultTheme } from '@agro-club/agroclub-shared'
import styled, { StyledProps } from 'styled-components'

import { box } from 'views/styled/common'

const FormTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${Typography.body2Default}
`

const Label = styled.div`
  color: ${props => props.theme.color.textSecondary};
  margin-bottom: 4px;
`

type Props = {
  label: ReactNode
}

export const FormText: FC<Props> = ({ children, label }) => (
  <FormTextWrapper>
    <Label>{label}</Label>
    {children}
  </FormTextWrapper>
)

export const TwoColBlock = styled.div`
  display: flex;

  & > *:first-child {
    margin-right: 64px;
  }
`

export const GroupTitle = styled.div`
  ${Typography.subheadlineCAPS};
  color: ${({ theme }) => theme.color.textSecondary};
`

export const UserDataWrapper = styled.div`
  display: grid;
  grid-gap: 16px;
  padding-top: 24px;
`

export const UserDataShort = styled.div`
  display: grid;
  grid-gap: 16px;
`

export const DocumentsWrapper = styled.div``

export const Container = styled.div`
  width: 566px;
  min-width: 566px;
  padding: 16px;
  margin: 0 16px;
  ${box};

  & > div {
    box-shadow: unset;
    padding: unset;
  }

  && > div > div:before {
    background-color: ${props => props.theme.color.secondary50};
  }
`

export const CarrierContainer = styled.div`
  && > div > div:before {
    background-color: ${props => props.theme.color.secondary50};
  }
`

export const TextList = styled.div`
  & > * {
    display: inline;
    padding: 0;
  }
`

export const StatusList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`

const StatusStyle = `
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  ${Typography.caption1Default};

   svg {
    width: 30px;
    height: 100%;
    margin-right: 10px;
  }

  &:hover {
  color: ${defaultTheme.color.secondary900};

  svg,
   svg > path {
    fill: ${defaultTheme.color.secondary600};
  }

  }

  & > div {
    display: flex;
    align-items: center;
  }
`

type SgvStyleProps = StyledProps<{
  isVerifyCoordinator?: boolean
  isVerifySecurity?: boolean
  isVerified?: boolean
  isActive?: boolean
}>

export const Coordinator = styled.div<SgvStyleProps>`
  ${StatusStyle}
  color: ${props => (props.isVerifyCoordinator ? defaultTheme.color.secondary300 : defaultTheme.color.secondary600)};
  pointer-events: ${props => (props.isVerifyCoordinator || !props.isActive ? 'none' : 'auto')};

  svg {
    fill: ${props =>
      props.isVerifyCoordinator ? defaultTheme.color.accentNotify600 : defaultTheme.color.secondary300};
  }
`

export const Security = styled.div<SgvStyleProps>`
  ${StatusStyle}
  color: ${props => (props.isVerifySecurity ? defaultTheme.color.secondary300 : defaultTheme.color.secondary600)};
  pointer-events: ${props => (props.isVerifySecurity || !props.isActive ? 'none' : 'auto')};

  svg {
    fill: ${props => (props.isVerifySecurity ? defaultTheme.color.accentNotify600 : defaultTheme.color.secondary300)};
  }
`

export const VerifiedUser = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  pointer-events: none;
  color: ${defaultTheme.color.secondary600};
  ${Typography.caption1Default};

  svg {
    width: 30px;
    height: 100%;
    margin-right: 10px;
    fill: ${defaultTheme.color.primary600};
  }
`

export const BlockUser = styled.div<SgvStyleProps>`
  ${StatusStyle}
  pointer-events: ${props => (props.isActive ? 'auto' : 'none')};

  svg > path {
    fill: ${props => (props.isActive ? defaultTheme.color.secondary300 : defaultTheme.color.accentDestruct800)};
  }

  &::before {
    content: '';
    display: block;
    width: 2px;
    height: 100%;
    float: left;
    background: ${defaultTheme.color.textQuarternary};
    margin-right: 16px;
  }
`

export const BlockUserNoSeparator = styled(BlockUser)`
  &::before {
    display: none;
  }
`
