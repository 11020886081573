import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useNotificationProgress } from 'hooks/useNotificationProgress'
import UserSelectors from 'modules/domain/user/selectors'
import { ProfileType } from 'modules/domain/user/types'
import UserAddressSelectors from 'modules/domain/userAddress/selectors'
import { UserAddress } from 'modules/domain/userAddress/types'
import { useLegalTypes } from 'views/components/LegalTypeSelect/useLegalTypes'
import { WarehouseProps } from 'views/components/UserRightPanel/Addresses/AddressItem/AddressBlockWarenhouse'
import { AddressButtons } from 'views/components/UserRightPanel/Addresses/AddressItem/AddressButtons'
import { Address, WarehouseWrapper, Wrapper } from 'views/components/UserRightPanel/Addresses/AddressItem/styled'

type Props = {
  address: UserAddress
  userId: string
}

export const AddressItem: React.FC<Props> = props => {
  const { address, userId } = props
  const { t } = useTranslation('address')
  const [, setShown] = useState<boolean>(false)
  const user = useSelector(state => UserSelectors.user(state, userId))
  const isCarrier = user?.profile?.profile_type === ProfileType.carrier
  const [, legal_types] = useLegalTypes()
  const legalType = legal_types?.find(item => item.slug === address.legal_type)?.label
  const deleteProgress = useSelector(state => UserAddressSelectors.deleteProgress(state, address.id))
  const deleteErrorDetail = useSelector(state => UserAddressSelectors.deleteErrorDetail(state, address.id))
  useNotificationProgress(deleteProgress, undefined, deleteErrorDetail)

  return (
    <Wrapper>
      <div>
        <Address>{address.address}</Address>
        <WarehouseWrapper>
          {address.is_main ? t('main') : t('additional')}
          ,&nbsp;
          {user?.profile?.profile_type === ProfileType.seller ? (
            <WarehouseProps type="loading" addressId={address.id} />
          ) : (
            <WarehouseProps type="unloading" addressId={address.id} />
          )}
          {legalType}
        </WarehouseWrapper>
      </div>
      <AddressButtons
        profileType={user?.profile?.profile_type}
        isCarrier={isCarrier}
        addressId={address.id}
        userId={userId}
        setShown={setShown}
        address={address}
      />
    </Wrapper>
  )
}
