import { Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const DropzoneWrapper = styled.div`
  width: 100%;
  height: 200px;
`

export const FileRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > *:last-child {
    opacity: 0;
  }
  &:hover > *:last-child {
    opacity: 1;
  }
  &:hover {
    background: ${props => props.theme.color.primary8a};
  }
  border-left: 1px solid ${props => props.theme.color.secondary50};
  border-right: 1px solid ${props => props.theme.color.secondary50};
  border-top: 1px solid ${props => props.theme.color.secondary50};
  height: 44px;
  padding: 0 12px;
  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom: 1px solid ${props => props.theme.color.secondary50};
    margin-bottom: 8px;
  }
  & button {
    background: transparent;
    &:hover {
      background: transparent;
      svg {
        fill: ${({ theme }) => theme.color.primary600};
      }
    }
  }
`

export const FileLink = styled.a`
  color: ${({ theme }) => theme.color.primary600};
  ${Typography.body2Default};
`

export const DocumentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const FilesList = styled.div`
  overflow-y: auto;
  position: relative;
`

export const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const Sure = styled.div`
  ${Typography.body2Default};
`

export const UploadLabel = styled.div`
  ${Typography.body2Default};
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`
