import { all, call, put, takeLeading } from 'redux-saga/effects'

import CollectionActions from './duck'
import * as managers from './managers'

export const fetchProductsSaga = function* () {
  try {
    const response = yield call(managers.fetchProducts)
    yield put(CollectionActions.productsRequestSucceed(response))
  } catch (err) {
    yield put(CollectionActions.productsRequestFailed())
  }
}

export const fetchCompaniesSaga = function* ({
  payload: query,
}: ReturnType<typeof CollectionActions.companiesRequested>) {
  try {
    const response = yield call(managers.fetchCompanies, query)
    yield put(CollectionActions.companiesRequestSucceed(response))
  } catch (err) {
    yield put(CollectionActions.companiesRequestFailed())
  }
}

export const fetchDealStatusesSaga = function* () {
  try {
    const response = yield call(managers.fetchDealStatuses)
    yield put(CollectionActions.dealStatusesRequestSucceed(response))
  } catch (err) {
    yield put(CollectionActions.dealStatusesRequestFailed())
  }
}

export const fetchTeams = function* () {
  try {
    const response = yield call(managers.fetchTeams)
    yield put(CollectionActions.teamsRequestSucceed(response.results))
  } catch (err) {
    yield put(CollectionActions.teamsRequestFailed())
  }
}

export const fetchRegions = function* () {
  try {
    const items = yield call(managers.fetchRegions)
    yield put(CollectionActions.regionsRequestSucceed(items))
  } catch (err) {
    yield put(CollectionActions.regionsRequestFailed())
  }
}

export const fetchLogisticStatusesSaga = function* () {
  try {
    const response = yield call(managers.fetchLogisticStatuses)
    yield put(CollectionActions.logisticStatusesRequestSucceed(response))
  } catch (err) {
    yield put(CollectionActions.logisticStatusesRequestFailed())
  }
}

const CollectionSaga = function* () {
  yield all([
    takeLeading(CollectionActions.productsRequested.type, fetchProductsSaga),
    takeLeading(CollectionActions.companiesRequested.type, fetchCompaniesSaga),
    takeLeading(CollectionActions.dealStatusesRequested.type, fetchDealStatusesSaga),
    takeLeading(CollectionActions.teamsRequested.type, fetchTeams),
    takeLeading(CollectionActions.regionsRequested.type, fetchRegions),
    takeLeading(CollectionActions.logisticStatusesRequested.type, fetchLogisticStatusesSaga),
  ])
}

export default CollectionSaga
