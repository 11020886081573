import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Input, useAction } from '@agro-club/agroclub-shared'

import CarsActions from 'modules/domain/cars/duck'
import CarsSelectors from 'modules/domain/cars/selectors'
import { InputWrapper } from 'views/pages/Cars/CarsTable/styled'

export function SideHeightFilter() {
  const { t } = useTranslation('cars')
  const filterUpdated = useAction(CarsActions.filterUpdated)
  const { side_height_range_max, ...restFilter } = useSelector(CarsSelectors.filter)

  const handleChange = (id: string) => {
    filterUpdated({ ...restFilter, side_height_range_max: id })
  }

  const onChange = e => {
    const { value } = e.target
    if (/^\d+$/.test(value) || !value) {
      handleChange(value ? Number(e.target.value).toString() : '')
    }
  }

  return (
    <InputWrapper>
      <Input value={side_height_range_max} onChange={onChange} placeholder={t('any_f')} />
    </InputWrapper>
  )
}
