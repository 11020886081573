import { Progress } from '@agro-club/agroclub-shared'
import { createSelector } from 'reselect'

import { EntityMetadata } from 'modules/domain/types'

import { AppGlobalState } from '../../types'
import { Deal, DealShipment } from './types'

const emptyArr = []

const dict = (state: AppGlobalState) => state.deal.items
const ids = (state: AppGlobalState) => state.deal.ids
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const item = (state: AppGlobalState, id: string): Deal | undefined => state.deal.items[id]
const meta = (state: AppGlobalState, id: string): EntityMetadata<Deal> =>
  state.deal.meta[id] || {
    fetchError: null,
    fetchProgress: Progress.IDLE,
    id,
    removeError: null,
    removeProgress: Progress.IDLE,
    updateError: null,
    updateProgress: Progress.IDLE,
  }
const itemFetchProgress = (state: AppGlobalState) => state.deal.itemFetchProgress
const listFetchProgress = (state: AppGlobalState) => state.deal.listFetchProgress
const listFetchError = (state: AppGlobalState) => state.deal.listFetchError
const listFetchErrorDetail = (state: AppGlobalState) => state.deal.listFetchErrorDetail
const list = createSelector(dict, ids, listFetchProgress, (dict, ids, progress) => {
  if (progress === Progress.WORK) {
    return emptyArr
  }
  const result: Deal[] = []
  for (const id of ids) {
    result.push(dict[id])
  }
  return result
})

const filter = (state: AppGlobalState) => state.deal.filter
const sorting = (state: AppGlobalState) => state.deal.sorting
const page = (state: AppGlobalState) => state.deal.page
const total = (state: AppGlobalState) => state.deal.total
const updateProgress = (state: AppGlobalState) => state.deal.updateProgress
const removeProgress = (state: AppGlobalState) => state.deal.removeProgress
const addProgress = (state: AppGlobalState) => state.deal.addProgress
const hasNext = (state: AppGlobalState) => state.deal.total > state.deal.ids.length
const pageSize = (state: AppGlobalState) => state.deal.pageSize
const pages = (state: AppGlobalState) => Math.ceil(state.deal.total / state.deal.pageSize)

const dealShipmentDict = (state: AppGlobalState) => state.deal.shipmentItems
const dealShipmentIds = (state: AppGlobalState, dealId: string) => state.deal.shipmentIds[dealId] || emptyArr
const dealShipmentFetchProgress = (state: AppGlobalState, dealId: string) =>
  state.deal.shipmentListFetchProgress[dealId] || Progress.IDLE
const dealShipmentAddProgress = (state: AppGlobalState, dealId: string) =>
  state.deal.shipmentAddProgress[dealId] || Progress.IDLE
const dealShipmentEditProgress = (state: AppGlobalState, dealId: string) =>
  state.deal.shipmentEditProgress[dealId] || Progress.IDLE
const dealShipmentDeleteProgress = (state: AppGlobalState, dealId: string) =>
  state.deal.shipmentDeleteProgress[dealId] || Progress.IDLE

const dealShipmentList = createSelector(
  dealShipmentDict,
  dealShipmentIds,
  dealShipmentFetchProgress,
  (dict, ids, progress) => {
    if (progress === Progress.WORK) {
      return emptyArr
    }
    const result: DealShipment[] = []
    ids.forEach(id => {
      if (dict[id]) {
        result.push(dict[id])
      }
    })
    return result
  },
)

const dealColumns = (state: AppGlobalState) => {
  const columns: { [key: string]: { deals: Deal[] } } = {}
  state.deal.ids.forEach(id => {
    const deal = state.deal.items?.[id]
    const status = deal?.status
    if (status) {
      if (columns[status]) {
        columns[status].deals.push(deal)
      } else {
        columns[status] = {
          deals: [deal],
        }
      }
    }
  })

  return columns
}

const changeStatusProgress = (state: AppGlobalState, dealId?: string) =>
  (dealId && state.deal.changeStatusProgress[dealId]) || Progress.IDLE

const changePurchaseBidProgress = (state: AppGlobalState, dealId: string) =>
  state.deal.changePurchaseBidProgress[dealId] || Progress.IDLE

const changeSaLeBidProgress = (state: AppGlobalState, dealId: string) =>
  state.deal.changeSaLeBidProgress[dealId] || Progress.IDLE

const files = (state: AppGlobalState, dealId: string) => state.deal.documents?.[dealId]?.files || []
const filesFetchProgress = (state: AppGlobalState, dealId: string) =>
  state.deal.documents?.[dealId]?.fetchProgress || Progress.IDLE
const filesUploadProgress = (state: AppGlobalState, dealId: string) =>
  state.deal.documents?.[dealId]?.uploadProgress || Progress.IDLE

const deleteDocumentProgress = (state: AppGlobalState) => state.deal.deleteDocumentProgress

const closeDealProgress = (state: AppGlobalState, dealId?: string) =>
  (!!dealId && state.deal.closeDealProgress?.[dealId]) || Progress.IDLE

const closeReasonsProgress = (state: AppGlobalState) => state.deal.closeReasonsProgress
const closeReasons = (state: AppGlobalState) => state.deal.closeReasons

const specificationsForPurchase = (state: AppGlobalState, id: string) =>
  state.deal.items[id].specifications_for_purchase
const fetchPurchaseSpecificationProgress = (state: AppGlobalState, id: string) =>
  state.deal.items[id].fetchPurchaseSpecificationProgress
const specificationPurchase = (state: AppGlobalState, id: string) => state.deal.items[id].purchase_specification

const documentTypes = (state: AppGlobalState, id: string) => state.deal.documentTypes[id]
const documentProgress = (state: AppGlobalState, id: string) => state.deal.getTypedDocumentProgress[id]
const uploadProgress = (state: AppGlobalState, id: string) => state.deal.uploadProgress[id]
const uploadError = (state: AppGlobalState, id: string) => state.deal.uploadError[id]
const uploadErrorDetail = (state: AppGlobalState, id: string) => state.deal.uploadErrorDetail?.[id]
const documentFiles = (state: AppGlobalState, id: string) => state.deal.documentFiles[id]
const deleteTypedDocumentProgress = (state: AppGlobalState, id: string) => state.deal.deleteTypedDocumentProgress[id]

const DealSelectors = {
  filter,
  sorting,
  page,
  total,
  updateProgress,
  removeProgress,
  addProgress,
  item,
  meta,
  itemFetchProgress,
  listFetchProgress,
  listFetchError,
  listFetchErrorDetail,
  list,
  dict,
  ids,
  hasNext,
  pageSize,
  pages,

  dealShipmentDict,
  dealShipmentFetchProgress,
  dealShipmentAddProgress,
  dealShipmentEditProgress,
  dealShipmentDeleteProgress,
  dealShipmentList,

  dealColumns,
  changeStatusProgress,

  changePurchaseBidProgress,
  changeSaLeBidProgress,

  specificationsForPurchase,
  fetchPurchaseSpecificationProgress,
  specificationPurchase,

  files,
  filesFetchProgress,
  filesUploadProgress,
  deleteDocumentProgress,

  closeDealProgress,

  closeReasonsProgress,
  closeReasons,

  documentTypes,
  documentProgress,
  uploadProgress,
  uploadError,
  uploadErrorDetail,
  documentFiles,
  deleteTypedDocumentProgress,
}

export default DealSelectors
