import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import BidActions from 'modules/domain/bid/duck'
import * as managers from 'modules/domain/bid/managers'
import BidSelectors from 'modules/domain/bid/selectors'
import { Bid } from 'modules/domain/bid/types'
import { RequestError } from 'modules/errors'
import { updateLocationQuery } from 'modules/sagaHelpers'
import { ListResponse } from 'types/api.d'
import BidRoutes from 'views/pages/Bid/routes'

export const fetchList = function* () {
  try {
    let currentPage = yield select(BidSelectors.page)
    const filter = yield select(BidSelectors.filterForRequestManager)
    const sorting = yield select(BidSelectors.sorting)
    const pageSize = yield select(BidSelectors.pageSize)

    const getList = filter.isPotential ? managers.getPotentialList : managers.getList

    let response: ListResponse<Bid> = yield call(getList, filter, sorting, currentPage, pageSize)
    const pages = Math.ceil(response.count / pageSize)

    if (pages !== 0 && pages < currentPage) {
      response = yield call(getList, filter, sorting, pages, pageSize)
      currentPage = pages
    }

    const { results, count, current } = response
    yield put(BidActions.listRequestSucceed(results, count, current))

    yield call(updateLocationQuery, BidRoutes.List, { page: currentPage, ...filter, ...sorting })
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(BidActions.listRequestFailed(type, detail))
  }
}

export const fetchItem = function* ({ payload: id }: ReturnType<typeof BidActions.itemRequested>) {
  try {
    const item: Bid = yield call(managers.getItem, id)
    yield put(BidActions.itemRequestSucceed(item))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(BidActions.itemRequestFailed(id, type, detail))
  }
}

export const addItem = function* ({ payload: { dto } }: ReturnType<typeof BidActions.addRequested>) {
  try {
    const item: { id: string } = yield call(managers.addItem, dto)
    yield put(BidActions.addSucceed(item.id))
  } catch (err) {
    const { type, detail, errors } = RequestError.parseError(err)
    yield put(BidActions.addFailed(type, detail, errors))
  }
}

export const updateItem = function* ({ payload: [id, dto] }: ReturnType<typeof BidActions.updateRequested>) {
  try {
    yield call(managers.updateItem, id, dto)
    const item: Bid = yield call(managers.getItem, id)
    yield put(BidActions.updateSucceed(item))
  } catch (err) {
    const { type, detail, errors } = RequestError.parseError(err)
    yield put(BidActions.updateFailed(id, type, detail, errors))
  }
}

export const removeItem = function* ({ payload }: ReturnType<typeof BidActions.removeRequested>) {
  try {
    yield call(managers.removeItem, payload)
    yield put(BidActions.removeSucceed(payload))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(BidActions.removeFailed(payload, type, detail))
  }
}

export const changeStatus = function* ({ payload: [id, status] }: ReturnType<typeof BidActions.changeStatusRequested>) {
  try {
    yield call(managers.changeStatus, id, status)
    yield put(BidActions.changeStatusRequestSucceed(id, status))
  } catch (err) {
    yield put(BidActions.changeStatusRequestFailed(id))
  }
}

export const fetchUserBidList = function* ({
  payload: { userId },
}: ReturnType<typeof BidActions.userBidListRequested>) {
  try {
    const filter = yield select(BidSelectors.userBidFilter)
    const list = yield call(managers.getUserBidList, userId, filter)
    yield put(BidActions.userBidListRequestSucceed(userId, list))
  } catch (err) {
    const { type } = RequestError.parseError(err)
    yield put(BidActions.userBidListRequestFailed(type))
  }
}

const BidSaga = function* () {
  yield all([
    takeLatest(BidActions.itemRequested.type, fetchItem),
    takeLatest(BidActions.listRequested.type, fetchList),
    takeLatest(BidActions.filterUpdated.type, fetchList),
    takeLatest(BidActions.sortingUpdated.type, fetchList),
    takeLatest(BidActions.filterHasBeenReset.type, fetchList),
    takeLatest(BidActions.sortingHasBeenReset.type, fetchList),

    takeLatest(BidActions.addRequested.type, addItem),
    takeLatest(BidActions.updateRequested.type, updateItem),
    takeLatest(BidActions.removeRequested.type, removeItem),
    takeLatest(BidActions.changeStatusRequested.type, changeStatus),

    takeLatest(BidActions.userBidListRequested.type, fetchUserBidList),
    takeLatest(BidActions.userBidFilterUpdated.type, fetchUserBidList),
  ])
}

export default BidSaga
