import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { useAction } from '@agro-club/agroclub-shared'

import UserActions from 'modules/domain/user/duck'
import UserSelectors from 'modules/domain/user/selectors'
import { DocumentsView } from 'views/components/DocumentsView/DocumentsView'

export const FilesSummary: React.FC = () => {
  const { t } = useTranslation('common')
  const { id } = useParams<{ id: string }>()
  const files = useSelector(state => UserSelectors.files(state, id))
  return <div>{files.length || t('common:no')}</div>
}

export const Files: React.FC = () => {
  const { id } = useParams<{ id: string }>()

  const upload = useAction(UserActions.uploadDocumentsRequested)
  const fetchFiles = useAction(UserActions.documentsRequested, id)

  useEffect(fetchFiles, [fetchFiles])

  const files = useSelector(state => UserSelectors.files(state, id))
  const uploadProgress = useSelector(state => UserSelectors.filesUploadProgress(state, id))
  const fetchProgress = useSelector(state => UserSelectors.filesFetchProgress(state, id))
  const deleteDocument = useAction(UserActions.deleteDocumentRequested)
  const deleteDocumentProgress = useSelector(UserSelectors.deleteDocumentProgress)

  return (
    <DocumentsView
      onDelete={fileId => {
        deleteDocument(id, fileId)
      }}
      deleteDocumentProgress={deleteDocumentProgress}
      files={files}
      onDrop={files => upload(id, files)}
      fetchProgress={fetchProgress}
      uploadProgress={uploadProgress}
    />
  )
}
