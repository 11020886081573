import React from 'react'
import { useTranslation } from 'react-i18next'

import { SimpleSelect } from '@agro-club/agroclub-shared'
import { FormikHelpers, FormikState } from 'formik'

import { AgroundImputs } from 'modules/domain/bid/types'

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: FormikState<any> & FormikHelpers<any>
}

export const AgroundImputsSelect: React.FC<Props> = ({ formik }) => {
  const { t } = useTranslation('bid')
  return (
    <div style={{ width: '252px' }}>
      <SimpleSelect
        invalid={formik.touched.aground_imputs && !!formik.errors.aground_imputs}
        onChange={val => formik.setFieldValue('aground_imputs', val)}
        label={t('taxes.aground_imputs')}
        options={[
          { id: AgroundImputs.YES, title: t('common:yes') },
          { id: AgroundImputs.NO, title: t('common:no') },
        ]}
        value={formik.values.aground_imputs}
      />
    </div>
  )
}
