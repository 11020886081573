import React from 'react'

import { IconCheck, IconMinus } from '@agro-club/agroclub-shared'
import { useTheme } from 'styled-components'

interface Props {
  value?: boolean
}

export const Checked: React.FC<Props> = ({ value }) => {
  const theme = useTheme()
  return value ? <IconCheck fill={theme.color.textPrimary} /> : <IconMinus />
}
