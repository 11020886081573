import '@agro-club/agroclub-shared/dist/esm/style.css'

import './i18n'

import React from 'react'
import { Provider } from 'react-redux'

import analytics from 'analytics/initAnalytics'
import { ConnectedRouter as Router } from 'connected-react-router'
import { AnalyticsProvider } from 'use-analytics'

import { history } from 'modules/store'
import { HelmetProvider } from 'views/components/HelmetProvider/HelmetProvider'
import GlobalStyle from 'views/globalStyles'
import RootComponent from 'views/RootComponent'

import store from './modules'
import { Sentry } from './sentry'

class App extends React.Component {
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    Sentry.handleReactException(error, errorInfo, store.getState())
  }

  render() {
    return (
      <Provider store={store}>
        <HelmetProvider>
          <AnalyticsProvider instance={analytics}>
            <GlobalStyle />
            <Router history={history}>
              <RootComponent />
            </Router>
          </AnalyticsProvider>
        </HelmetProvider>
      </Provider>
    )
  }
}

export default App
