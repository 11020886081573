import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import DashboardActions from 'modules/domain/dashboard/duck'
import DashboardSelectors from 'modules/domain/dashboard/selectors'
import { RequestError } from 'modules/errors'
import { updateLocationQuery } from 'modules/sagaHelpers'
import { ListResponse } from 'types/api.d'
import DashboardRoutes from 'views/pages/Dashboard/routes'
import TaskRoutes from 'views/pages/Task/routes'

import TaskActions from './duck'
import * as managers from './managers'
import TaskSelectors from './selectors'
import { Task } from './types'

export const fetchList = function* () {
  try {
    let currentPage = yield select(TaskSelectors.page)
    const filter = yield select(TaskSelectors.filter)
    const sorting = yield select(TaskSelectors.sorting)
    const pageSize = yield select(TaskSelectors.pageSize)

    let response: ListResponse<Task> = yield call(managers.getList, filter, sorting, currentPage, pageSize)
    const pages = Math.ceil(response.count / pageSize)

    if (pages !== 0 && pages < currentPage) {
      response = yield call(managers.getList, filter, sorting, pages, pageSize)
      currentPage = pages
    }

    const { results, count, current } = response
    yield put(TaskActions.listRequestSucceed(results, count, current))

    yield call(updateLocationQuery, TaskRoutes.List, { page: currentPage, ...filter, ...sorting })
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(TaskActions.listRequestFailed(type, detail))
  }
}

export const fetchCallBackList = function* () {
  try {
    let currentPage = yield select(TaskSelectors.callBackPage)
    const pageSize = yield select(TaskSelectors.callBackPageSize)

    const filter = yield select(DashboardSelectors.filter)
    const filterForRequestManager = yield select(DashboardSelectors.filterForRequestManager)

    yield put(TaskActions.callBackFetchProgressWork())

    let response: ListResponse<Task> = yield call(managers.getCallBackItems, {
      page: currentPage,
      pageSize,
      filter: filterForRequestManager,
    })
    const pages = Math.ceil(response.count / pageSize)

    if (pages !== 0 && pages < currentPage) {
      response = yield call(managers.getCallBackItems, { page: pages, pageSize, filter: filterForRequestManager })
      currentPage = pages
    }

    const { results, count, current } = response
    yield put(TaskActions.callBackListRequestSucceed(results, count, current))
    yield call(updateLocationQuery, DashboardRoutes.Dashboard, { ...filter })
  } catch (err) {
    yield put(TaskActions.callBackListRequestFailed())
  }
}

export const fetchItem = function* ({ payload: id }: ReturnType<typeof TaskActions.itemRequested>) {
  try {
    const item: Task = yield call(managers.getItem, id)
    yield put(TaskActions.itemRequestSucceed(item))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(TaskActions.itemRequestFailed(id, type, detail))
  }
}

export const addItem = function* ({ payload: dto }: ReturnType<typeof TaskActions.addRequested>) {
  try {
    const item: Task = yield call(managers.addItem, dto)
    yield put(TaskActions.addSucceed(item))
  } catch (err) {
    const { type, detail, errors } = RequestError.parseError(err)
    yield put(TaskActions.addFailed(type, detail, errors))
  }
}
export const updateItem = function* ({ payload: [id, dto] }: ReturnType<typeof TaskActions.updateRequested>) {
  try {
    const item: Task = yield call(managers.updateItem, id, dto)
    yield put(TaskActions.updateSucceed(item))
  } catch (err) {
    const { type, detail, errors } = RequestError.parseError(err)
    yield put(TaskActions.updateFailed(id, type, detail, errors))
  }
}

export const removeItem = function* ({ payload }: ReturnType<typeof TaskActions.removeRequested>) {
  try {
    yield call(managers.removeItem, payload)
    yield put(TaskActions.removeSucceed(payload))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(TaskActions.removeFailed(payload, type, detail))
  }
}

export const fetchMyTasksSaga = function* () {
  try {
    const { results } = yield call(managers.fetchMyTasks)
    yield put(TaskActions.fetchMyTasksSuccess(results))
  } catch (err) {
    yield put(TaskActions.fetchMyTasksFailed())
  }
}

export const fetchUserTasks = function* ({ payload: userId }: ReturnType<typeof TaskActions.userTasksRequested>) {
  try {
    const list = yield call(managers.getUserTasks, userId)
    yield put(TaskActions.userTasksSuccess(userId, list))
  } catch (err) {
    yield put(TaskActions.userTasksFailed())
  }
}

export const completeTaskSaga = function* ({ payload: id }: ReturnType<typeof TaskActions.completeTaskRequested>) {
  try {
    yield call(managers.completeTask, id)
    yield put(TaskActions.itemRequested(id))
    yield put(TaskActions.completeTaskSuccess(id))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(TaskActions.completeTaskFailed(type, detail))
  }
}

const TaskSaga = function* () {
  yield all([
    takeLatest(TaskActions.itemRequested.type, fetchItem),
    takeLatest(TaskActions.listRequested.type, fetchList),
    takeLatest(TaskActions.filterUpdated.type, fetchList),
    takeLatest(TaskActions.sortingUpdated.type, fetchList),
    takeLatest(TaskActions.filterHasBeenReset.type, fetchList),
    takeLatest(TaskActions.sortingHasBeenReset.type, fetchList),

    takeLatest(TaskActions.addRequested.type, addItem),
    takeLatest(TaskActions.updateRequested.type, updateItem),
    takeLatest(TaskActions.removeRequested.type, removeItem),

    takeLatest(TaskActions.fetchMyTasksRequested.type, fetchMyTasksSaga),
    takeLatest(TaskActions.completeTaskRequested.type, completeTaskSaga),

    takeLatest(TaskActions.userTasksRequested.type, fetchUserTasks),

    takeLatest(TaskActions.callBackListRequested.type, fetchCallBackList),
    takeLatest(DashboardActions.filterUpdate.type, fetchCallBackList),
  ])
}

export default TaskSaga
