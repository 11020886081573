import { Progress } from '@agro-club/agroclub-shared'
import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer'

import { LIST_PAGE_SIZE } from 'modules/constants'
import {
  Carrier,
  CarrierListRequestFilter,
  CarrierListRequestSorting,
  CarrierState,
  Trailer,
  TrailerDTO,
  UpdateTrailerDTO,
} from 'modules/domain/carrier/types'
import { AddError, FetchError } from 'modules/domain/types'
import { arrToDict, getIds } from 'modules/utils/helpers'

const initialState: CarrierState = {
  items: {},
  meta: {},
  ids: [],

  listFetchProgress: Progress.IDLE,
  listFetchError: null,
  listFetchNextProgress: Progress.IDLE,
  listFetchNextError: null,
  itemFetchProgress: Progress.IDLE,
  itemFetchError: null,
  addProgress: Progress.IDLE,
  addError: null,
  updateProgress: Progress.IDLE,
  updateError: null,
  removeProgress: Progress.IDLE,
  removeError: null,

  filter: {},
  sorting: {},
  page: 1,
  total: 0,
  pageSize: LIST_PAGE_SIZE,

  getDocumentProgress: {},
  getDocumentError: {},
  getDocumentErrorDetail: {},
  documentTypes: {},
  uploadProgress: {},
  uploadError: {},
  uploadErrorDetail: {},
  getDocumentFilesProgress: {},
  getDocumentFilesError: {},
  getDocumentFilesErrorDetail: {},
  documentFiles: {},
  deleteTypedDocumentProgress: {},
  deleteDocumentProgress: Progress.IDLE,
  trailers: {},
  trailerLicenceNumber: null,
  trailerAddProgress: Progress.IDLE,
  trailerAddError: null,
  trailerAddErrorDetail: undefined,
  trailerAddErrorFields: undefined,
  trailerUpdateProgress: Progress.IDLE,
  trailerUpdateError: null,
  trailerUpdateErrorDetail: undefined,
  trailerUpdateErrorFields: undefined,
  trailerArchiveProgress: Progress.IDLE,
  trailerArchiveError: null,
  trailerArchiveErrorDetail: undefined,
  trailerArchiveErrorFields: undefined,
}

export type ListRequestedParams = {
  filter?: CarrierListRequestFilter
  sorting?: CarrierListRequestSorting
  page?: number
}

class CarrierReducer extends ImmerReducer<CarrierState> {
  listRequested(params: ListRequestedParams) {
    this.draftState.listFetchProgress = Progress.WORK
    this.draftState.listFetchErrorDetail = undefined
    this.draftState.listFetchError = null
    this.draftState.filter = params.filter || this.draftState.filter
    this.draftState.sorting = params.sorting || this.draftState.sorting
    this.draftState.page = typeof params.page === 'undefined' ? this.draftState.page : params.page
  }

  listRequestSucceed(list: Carrier[], total: number, page: number) {
    this.draftState.listFetchProgress = Progress.SUCCESS
    this.draftState.listFetchErrorDetail = undefined
    this.draftState.items = arrToDict(list)
    this.draftState.meta = arrToDict(
      list.map(item => ({
        id: item.id,
        fetchProgress: Progress.SUCCESS,
        fetchError: null,
        removeProgress: Progress.IDLE,
        removeError: null,
        updateProgress: Progress.IDLE,
        updateError: null,
      })),
    )
    this.draftState.ids = getIds(list)
    this.draftState.total = total
    this.draftState.page = page
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  listRequestFailed(error: FetchError, errorDetail?: string) {
    this.draftState.listFetchProgress = Progress.ERROR
    this.draftState.listFetchError = error
    this.draftState.listFetchErrorDetail = errorDetail
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  itemRequested(id: string) {
    this.draftState.itemFetchProgress = Progress.WORK

    const meta = {
      id,
      updateProgress: Progress.IDLE,
      updateError: null,
      removeProgress: Progress.IDLE,
      removeError: null,
    }

    this.draftState.meta[id] = {
      ...meta,
      ...this.draftState.meta[id],
      fetchProgress: Progress.WORK,
      fetchError: null,
    }
  }

  itemRequestSucceed(item: Carrier) {
    this.draftState.itemFetchProgress = Progress.SUCCESS
    this.draftState.meta[item.id].fetchProgress = Progress.SUCCESS
    this.draftState.meta[item.id].fetchError = null
    this.draftState.meta[item.id].fetchErrorDetail = undefined
    this.draftState.items[item.id] = item
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  itemRequestFailed(id: string, error: FetchError, errorDetail?: string) {
    this.draftState.itemFetchProgress = Progress.ERROR
    this.draftState.meta[id].fetchProgress = Progress.ERROR
    this.draftState.meta[id].fetchError = error
    this.draftState.meta[id].fetchErrorDetail = errorDetail
  }

  filterUpdated(filter: CarrierListRequestFilter) {
    this.draftState.filter = filter
    this.draftState.listFetchProgress = Progress.WORK
    this.draftState.page = 1
  }

  filterResetWithoutRequest() {
    this.draftState.filter = {}
    this.draftState.listFetchProgress = Progress.IDLE
    this.draftState.page = 1
  }

  sortingUpdated(sorting: CarrierListRequestSorting) {
    this.draftState.sorting = sorting
    this.draftState.listFetchProgress = Progress.WORK
  }

  filterHasBeenReset() {
    this.draftState.filter = {}
    this.draftState.listFetchProgress = Progress.WORK
  }

  sortingHasBeenReset() {
    this.draftState.sorting = {}
    this.draftState.listFetchProgress = Progress.WORK
  }

  getDocumentTypesRequested(id: string) {
    this.draftState.getDocumentProgress[id] = Progress.WORK
    this.draftState.getDocumentErrorDetail[id] = undefined
    this.draftState.getDocumentError[id] = null
  }

  getDocumentTypesSucceed(id, list) {
    this.draftState.getDocumentProgress[id] = Progress.SUCCESS
    this.draftState.getDocumentErrorDetail[id] = undefined

    this.draftState.documentTypes[id] = list
  }

  getDocumentTypesFailed(id: string, error: FetchError, errorDetail?: string) {
    this.draftState.getDocumentProgress[id] = Progress.ERROR
    this.draftState.getDocumentError[id] = error
    this.draftState.getDocumentErrorDetail[id] = errorDetail
  }

  uploadDocumentRequested(id: string, _document_type: string, _file: File) {
    this.draftState.uploadProgress[id] = Progress.WORK
  }

  uploadDocumentSucccess(id: string) {
    this.draftState.uploadProgress[id] = Progress.SUCCESS
  }

  uploadDocumentFailed(id: string, error: FetchError, errorDetail?: string) {
    this.draftState.uploadProgress[id] = Progress.ERROR
    this.draftState.uploadError[id] = error
    this.draftState.uploadErrorDetail[id] = errorDetail
  }

  getDocumentFilesRequested(id: string) {
    this.draftState.getDocumentFilesProgress[id] = Progress.WORK
    this.draftState.getDocumentFilesErrorDetail[id] = undefined
    this.draftState.getDocumentFilesError[id] = null
  }

  getDocumentFilesSucceed(id, list) {
    this.draftState.getDocumentFilesProgress[id] = Progress.SUCCESS
    this.draftState.getDocumentFilesErrorDetail[id] = undefined

    this.draftState.documentFiles[id] = list
  }

  getDocumentFilesFailed(id: string, error: FetchError, errorDetail?: string) {
    this.draftState.getDocumentFilesProgress[id] = Progress.ERROR
    this.draftState.getDocumentFilesError[id] = error
    this.draftState.getDocumentFilesErrorDetail[id] = errorDetail
  }

  deleteDocumentRequested(_id: string, _document_id: string) {
    this.draftState.deleteDocumentProgress = Progress.WORK
  }

  deleteDocumentSucccess() {
    this.draftState.deleteDocumentProgress = Progress.SUCCESS
  }

  deleteDocumentFailed() {
    this.draftState.deleteDocumentProgress = Progress.ERROR
  }

  deleteTypedDocumentRequested(id: string, _document_id: string) {
    this.draftState.deleteTypedDocumentProgress[id] = Progress.WORK
  }

  deleteTypedDocumentSucccess(id) {
    this.draftState.deleteTypedDocumentProgress[id] = Progress.SUCCESS
  }

  deleteTypedDocumentFailed(id) {
    this.draftState.deleteTypedDocumentProgress[id] = Progress.ERROR
  }

  trailersRequested(id: string) {
    this.draftState.trailers[id] = {
      list: [],
      fetchProgress: Progress.WORK,
    }
  }

  trailersRequestSuccess(id: string, list: Trailer[]) {
    this.draftState.trailers[id] = {
      list,
      fetchProgress: Progress.SUCCESS,
    }
  }

  trailersRequestError(id: string) {
    this.draftState.trailers[id] = {
      list: [],
      fetchProgress: Progress.ERROR,
    }
  }

  trailerAddRequested(dto: TrailerDTO) {
    this.draftState.trailerLicenceNumber = dto.licence_number
    this.draftState.trailerAddProgress = Progress.WORK
    this.draftState.trailerAddError = null
    this.draftState.trailerAddErrorDetail = undefined
    this.draftState.trailerAddErrorFields = undefined
  }

  trailerAddSuccess() {
    this.draftState.trailerAddProgress = Progress.SUCCESS
    this.draftState.trailerAddError = null
    this.draftState.trailerAddErrorDetail = undefined
    this.draftState.trailerAddErrorFields = undefined
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  trailerAddError(error: AddError, errorDetail?: string, errorFields?: Record<string, string[]>) {
    this.draftState.trailerAddProgress = Progress.ERROR
    this.draftState.trailerAddError = error
    this.draftState.trailerAddErrorDetail = errorDetail
    this.draftState.trailerAddErrorFields = errorFields
  }

  trailerResetAddErrors() {
    this.draftState.trailerAddProgress = Progress.IDLE
    this.draftState.trailerAddError = null
    this.draftState.trailerAddErrorDetail = undefined
    this.draftState.trailerAddErrorFields = undefined
  }

  trailerUpdateRequested(_id: string, _dto: UpdateTrailerDTO) {
    this.draftState.trailerUpdateProgress = Progress.WORK
    this.draftState.trailerUpdateError = null
    this.draftState.trailerUpdateErrorDetail = undefined
    this.draftState.trailerUpdateErrorFields = undefined
  }

  trailerUpdateSuccess() {
    this.draftState.trailerUpdateProgress = Progress.SUCCESS
    this.draftState.trailerUpdateError = null
    this.draftState.trailerUpdateErrorDetail = undefined
    this.draftState.trailerUpdateErrorFields = undefined
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  trailerUpdateError(error: AddError, errorDetail?: string, errorFields?: Record<string, string[]>) {
    this.draftState.trailerUpdateProgress = Progress.ERROR
    this.draftState.trailerUpdateError = error
    this.draftState.trailerUpdateErrorDetail = errorDetail
    this.draftState.trailerUpdateErrorFields = errorFields
  }

  trailerResetUpdateErrors() {
    this.draftState.trailerUpdateProgress = Progress.IDLE
    this.draftState.trailerUpdateError = null
    this.draftState.trailerUpdateErrorDetail = undefined
    this.draftState.trailerUpdateErrorFields = undefined
  }

  trailerArchiveRequested(_id: string) {
    this.draftState.trailerArchiveProgress = Progress.WORK
    this.draftState.trailerArchiveError = null
    this.draftState.trailerArchiveErrorDetail = undefined
    this.draftState.trailerArchiveErrorFields = undefined
  }

  trailerArchiveSuccess() {
    this.draftState.trailerArchiveProgress = Progress.SUCCESS
    this.draftState.trailerArchiveError = null
    this.draftState.trailerArchiveErrorDetail = undefined
    this.draftState.trailerArchiveErrorFields = undefined
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  trailerArchiveError(error: AddError, errorDetail?: string, errorFields?: Record<string, string[]>) {
    this.draftState.trailerArchiveProgress = Progress.ERROR
    this.draftState.trailerArchiveError = error
    this.draftState.trailerArchiveErrorDetail = errorDetail
    this.draftState.trailerArchiveErrorFields = errorFields
  }
}

export const CarrierActions = createActionCreators(CarrierReducer)
export default CarrierActions
export const reducer = createReducerFunction(CarrierReducer, initialState)
