import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { generatePath } from 'react-router-dom'
import { CellProps, useTable } from 'react-table'

import {
  AdvancedHeadCell,
  IconCheck,
  Progress,
  Table,
  TableBody,
  TableBodyRow,
  TableHead,
  TableHeadRow,
  TableNoData,
  useAction,
  usePersistentScroll,
} from '@agro-club/agroclub-shared'
import { isNil } from 'ramda'

import { noop } from 'helpers/noop'
import useLocationFrom from 'hooks/useLocationFrom'
import { useNotificationProgress } from 'hooks/useNotificationProgress'
import usePrevious from 'hooks/usePrevious'
import { LIST_PAGE_SIZE } from 'modules/constants'
import DashboardActions from 'modules/domain/dashboard/duck'
import DashboardSelectors from 'modules/domain/dashboard/selectors'
import { NewUser } from 'modules/domain/dashboard/types'
import TaskActions from 'modules/domain/task/duck'
import TaskSelectors from 'modules/domain/task/selectors'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import { TaskModal } from 'views/components/TaskModal/TaskModal'
import { getUser } from 'views/pages/Dashboard/helpers/getUser'
import { CheckButton, Delimiter, StyledLink } from 'views/pages/Dashboard/styled'
import UserRoutes from 'views/pages/User/routes'
import { Phone } from 'views/ui/Phone/Phone'

export const TaskSortableHeadCell = AdvancedHeadCell<keyof NewUser>()

const HandleCell = ({ row }) => {
  const completeAction = useAction(TaskActions.completeTaskRequested)
  const completeProgress = useSelector(TaskSelectors.completeProgress)
  const taskId = useSelector(TaskSelectors.taskId)

  const progress = taskId === row.original.linked_user.id ? completeProgress : undefined

  return (
    <CheckButton
      onClick={() => completeAction(row.original.id)}
      intent="minor-action"
      filled
      size="medium"
      progress={progress}
    >
      <IconCheck />
    </CheckButton>
  )
}

function OwnerCell({ row }: CellProps<NewUser>) {
  const from = useLocationFrom()
  return (
    <>
      <StyledLink
        to={{
          pathname: generatePath(UserRoutes.Detail, { id: row.original.linked_user.id }),
          state: { from },
        }}
      >
        {row.original.linked_user.full_name}
      </StyledLink>
      <Delimiter>&nbsp;/&nbsp;</Delimiter>
      <Phone phone={row.original.linked_user.phone} />
    </>
  )
}

export const NewUsers: React.FC = () => {
  const { t } = useTranslation('newUsers')
  const progress = useSelector(DashboardSelectors.newUsersFetchProgress)
  const data = useSelector(DashboardSelectors.newUsers)

  const total = useSelector(DashboardSelectors.newUsersCount)
  const pages = Math.floor(total / LIST_PAGE_SIZE)
  const page = useSelector(DashboardSelectors.newUsersPage)
  const pageSize = LIST_PAGE_SIZE

  const filterState = useSelector(DashboardSelectors.filter)

  const completeProgress = useSelector(TaskSelectors.completeProgress)
  const completeErrorDetail = useSelector(TaskSelectors.completeErrorDetail)
  const addProgress = useSelector(TaskSelectors.addProgress)
  const taskId = useSelector(TaskSelectors.taskId)

  const filterUpdated = useAction(DashboardActions.filterUpdate)

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const prevProgress = usePrevious(completeProgress)
  const prevAddProgress = usePrevious(addProgress)

  const user = useMemo(() => data.find(item => item.id === taskId), [taskId, data])

  const visibleColumns = React.useMemo(
    () => [
      {
        Header: t('list.tableHeaders.date_joined'),
        accessor: 'registration_date' as const,
        Cell: TComponents.DateTimeCell,
      },
      {
        Header: t('list.tableHeaders.user'),
        accessor: 'linked_user.full_name' as const,
        Cell: OwnerCell,
      },
      {
        Header: t('list.tableHeaders.user_type'),
        accessor: 'linked_user.profile_type' as const,
        Cell: ({ value }) => t(`list.profile_type.${value}`),
      },
      {
        Header: t('list.tableHeaders.team'),
        accessor: 'assigned_team.name',
      },
      {
        Header: t('list.tableHeaders.action'),
        Cell: HandleCell,
      },
    ],
    [t],
  )

  const { columns, rows, prepareRow } = useTable<NewUser>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore weird issue with react-table typings — having more then 26 fields in type causes TS error
    columns: visibleColumns,
    data,
  })

  const setPage = useCallback(
    num => {
      filterUpdated(undefined, num)
    },
    [filterUpdated],
  )

  const { scrollRef } = usePersistentScroll('taskList')

  const isFilterApplied = Object.values(filterState).some(value => !isNil(value))

  useNotificationProgress(completeProgress, t('userHandled'), completeErrorDetail)
  useNotificationProgress(addProgress, t('userHandled'))

  useEffect(() => {
    if (prevProgress === Progress.WORK && completeProgress === Progress.ERROR) {
      setIsOpen(true)
    }

    if (prevProgress === Progress.WORK && completeProgress === Progress.SUCCESS) {
      filterUpdated()
    }
  }, [prevProgress, completeProgress, filterUpdated])

  useEffect(() => {
    if (prevAddProgress === Progress.WORK && addProgress === Progress.SUCCESS) {
      filterUpdated()
    }
  }, [prevAddProgress, addProgress, filterUpdated])

  return (
    <>
      <TComponents.Wrapper>
        <Table total={total} pages={pages} pageSize={pageSize} currentPage={page} onSetPage={setPage} ref={scrollRef}>
          <TableHead>
            <TableHeadRow>
              {columns.map(column => (
                <TaskSortableHeadCell
                  zIndex={1}
                  key={column.getHeaderProps().key}
                  id={column.id as keyof NewUser}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  sortable={column.sortable}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  hidden={column.hidden}
                  // sortField={sort_field}
                  // sortDesc={sort_reversed}
                  onChange={noop}
                >
                  {column.render('Header')}
                </TaskSortableHeadCell>
              ))}
            </TableHeadRow>
          </TableHead>
          <TableBody>
            {rows.map(row => {
              prepareRow(row)
              const { key, ...props } = row.getRowProps()
              return (
                <TableBodyRow key={key} {...props}>
                  {row.cells.map(cell => {
                    const { key, ...props } = cell.getCellProps()

                    return (
                      <TComponents.RelativeTableBodyCell key={key} {...props}>
                        {cell.render('Cell')}
                      </TComponents.RelativeTableBodyCell>
                    )
                  })}
                </TableBodyRow>
              )
            })}
            <TableNoData
              progress={progress}
              isEmpty={!rows.length}
              colSpan={visibleColumns.length}
              loading={<TComponents.Spinner />}
            >
              <div>{isFilterApplied ? t('list.emptyFilterMsg') : t('list.emptyMsg')}</div>
              {isFilterApplied && progress !== Progress.WORK && (
                <TComponents.ClearButton intent="cancel" size="small">
                  {t('common:resetAllFilters')}
                </TComponents.ClearButton>
              )}
            </TableNoData>
          </TableBody>
        </Table>
      </TComponents.Wrapper>

      <TaskModal user={getUser(user)} isOpen={isOpen} close={() => setIsOpen(false)} />
    </>
  )
}
