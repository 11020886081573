import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createLogger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'

import analyticsInstance from 'analytics/initAnalytics'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import HttpStatuses from 'http-status-codes'

import { DEVELOPMENT, REACT_APP_REDUX_LOGGER, REACT_APP_REDUX_LOGGER_HIDE_ACTIONS } from 'env'
import { middleware as sentryMiddleware } from 'sentry'

import rootReducer from './rootReducer'
import rootSaga from './rootSaga'
import { refreshTokenAndRetry } from './utils/refreshAndRetry'

export const history = createBrowserHistory()

const sagaMiddleware = createSagaMiddleware({
  context: {
    analyticsInstance,
  },
})
// const composeEnhancers = (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose) || compose

const hiddenActions = [] as const

type ElementType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<infer ElementType> ? ElementType : never
const hideActions = (
  _: unknown,
  action: {
    type: ElementType<typeof hiddenActions>
  },
) => !hiddenActions.includes(action.type)

const middlewares = [sentryMiddleware, sagaMiddleware, routerMiddleware(history)]

if (REACT_APP_REDUX_LOGGER) {
  middlewares.push(
    createLogger({
      collapsed: true,
      logErrors: false,
      diff: true,
      predicate: REACT_APP_REDUX_LOGGER_HIDE_ACTIONS ? hideActions : undefined,
    }),
  )
}

const store = createStore(
  rootReducer(history),
  DEVELOPMENT ? composeWithDevTools(applyMiddleware(...middlewares)) : applyMiddleware(...middlewares),
)

let isTokenRefreshing = false

axios.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError) => {
    if (error.response?.status !== HttpStatuses.UNAUTHORIZED || isTokenRefreshing) throw error
    try {
      isTokenRefreshing = true
      const result = await refreshTokenAndRetry(error)
      isTokenRefreshing = false
      return result
    } catch (e) {
      isTokenRefreshing = false
      throw e
    }
  },
)

sagaMiddleware.run(rootSaga)

export default store
