import { Trip, TripDTO } from 'modules/domain/trip/types'
import { DocumentWithTypeFile } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { ListResponse } from 'types/api.d'

export const getList = (deal: string) => apiClient.get<ListResponse<Trip>>(endpoints.trip(), { deal, page_size: 10000 })

export const getItem = (id: string) => apiClient.get<Trip>(endpoints.trip(id))

export const addItem = (dto: TripDTO) => apiClient.post<Trip>(endpoints.trip(), dto)

export const updateItem = (id: string, dto: Partial<TripDTO>) => apiClient.put<Trip>(endpoints.trip(id), dto)

export const removeItem = (id: string) => apiClient.delete<Trip>(endpoints.trip(id))

export const getDocumentTypes = (id: string) =>
  apiClient.get<ListResponse<DocumentType>>(endpoints.tripsDocumentTypes(id))

export const uploadFile = (id: string, document_type: string, file: File) => {
  const form = new FormData()
  form.append('uploaded_file', file)
  form.append('document_type', document_type)
  return apiClient.post<DocumentWithTypeFile>(endpoints.tripsDocuments(id), form)
}

export const getDocumentFiles = (id: string) =>
  apiClient.get<ListResponse<DocumentType>>(endpoints.tripsDocumentFiles(id))

export const deleteDocumentFile = (id: string, document_id: string) =>
  apiClient.delete(endpoints.tripsDeleteDocumentFile(id, document_id))
