import { useCallback } from 'react'

import { isNil } from 'ramda'

import { REACT_APP_LOCALE } from 'env'

export default function useFormatNumber() {
  return useCallback((value?: number | string | null, isFloat = false, fractionDigits = 2) => {
    if (isNil(value)) {
      return ''
    }
    if (typeof value === 'string' && Number.isNaN(Number(value))) {
      return value
    }
    const options = isFloat
      ? { minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits }
      : undefined
    return Number(value).toLocaleString(REACT_APP_LOCALE, options)
  }, [])
}
