import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { SimpleSelect, useAction } from '@agro-club/agroclub-shared'

import CallsActions from 'modules/domain/calls/duck'
import { useAccounts } from 'modules/domain/calls/hooks'
import CallsSelectors from 'modules/domain/calls/selectors'
import * as Styled from 'views/pages/Calls/CallsTable/styled'

export function CoordinatorFilter() {
  const { t } = useTranslation('calls')
  const filterUpdated = useAction(CallsActions.filterUpdated)
  const { coordinator, ...restFilter } = useSelector(CallsSelectors.filter)

  const [, accounts] = useAccounts()

  const options = useMemo(
    () =>
      accounts?.map(account => ({
        id: account.id,
        title: account.name,
      })) || [],
    [accounts],
  )

  const handleChange = (id: string) => {
    filterUpdated({ ...restFilter, coordinator: id })
  }

  const handleClear = () => {
    filterUpdated({ ...restFilter, coordinator: undefined })
  }

  return (
    <SimpleSelect
      placeholder={t('any')}
      isClearable
      isSearchable
      options={options}
      onChange={handleChange}
      customStyles={Styled.customSelectCoordinarorStyles}
      value={coordinator}
      onClear={handleClear}
    />
  )
}
