import { Button, Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import { FormItem } from 'views/ui/FormItem/FormItem'

export const TwoColumn = styled.div<{ $marginBottom?: number }>`
  display: grid;
  grid-template-columns: 212px 212px;
  grid-column-gap: 44px;
`

export const StateNumbers = styled.div`
  ${Typography.body2Bold};
  margin-bottom: 4px;
`
export const TrailerStateNumber = styled.span`
  ${Typography.body2Default};
  color: ${({ theme }) => theme.color.textSecondary};
`

export const ProductTitle = styled.div`
  ${Typography.body2Bold};
  margin-bottom: 4px;
`

export const Field = styled.div`
  & label {
    ${Typography.body2Default};
    margin-bottom: 4px;
    color: ${({ theme }) => theme.color.textSecondary};
    opacity: 1;
  }
  margin-bottom: 24px;
`

export const FormButton = styled(Button)`
  margin-right: 16px;
`
export const DeleteButton = styled(Button)`
  width: 40px;
  height: 40px;
  background-color: ${({ theme }) => theme.color.accentDestruct16a};
  padding: 0;

  :hover {
    background-color: ${({ theme }) => theme.color.accentDestruct100};
  }

  svg {
    fill: ${({ theme }) => theme.color.accentDestruct500};
  }
`

export const AddDriverItem = styled(FormItem)`
  :not(:last-child) {
    margin-bottom: 16px;
  }
`
