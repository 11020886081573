import { endpoints } from 'modules/endpoints'
import { apiClient, authClient } from 'modules/utils/httpClient'

import { CodeCredentials, Credentials, ProfileDTO, TokensPair, UserProfile } from './types'

type TokenResponse = {
  access: string
  refresh: string
}

export const obtainCode = async ({ phone }: CodeCredentials): Promise<void> => {
  await authClient.post<TokenResponse>(endpoints.getCode(), { phone })
}

export const obtainToken = async ({ code, phone }: Credentials): Promise<TokensPair> => {
  const response = await authClient.post<TokenResponse>(endpoints.login(), { code, phone })

  return { accessToken: response.access, refreshToken: response.refresh }
}

export const refreshToken = async (refresh: string): Promise<TokensPair> => {
  const response = await authClient.post<TokenResponse>(endpoints.refreshToken(), { refresh })
  return {
    accessToken: response.access,
    refreshToken: response.refresh,
  }
}

export const fetchCurrentUser = async () => apiClient.get<UserProfile>(endpoints.profile())

export const updateCurrentUser = async (profile: Partial<ProfileDTO>) =>
  apiClient.put<UserProfile>(endpoints.profile(), profile)
