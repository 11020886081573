import { all, call, put, takeLatest } from 'redux-saga/effects'

import DriverActions from 'modules/domain/driver/duck'
import * as managers from 'modules/domain/driver/managers'
import { Driver } from 'modules/domain/types'
import { RequestError } from 'modules/errors'

export const fetchDriversList = function* ({ payload: carId }: ReturnType<typeof DriverActions.listRequested>) {
  try {
    const list: Driver[] = yield call(managers.fetchDrivers, carId)
    yield put(DriverActions.listRequestSucceed(carId, list))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(DriverActions.listRequestFailed(carId, type, detail))
  }
}

export const addDriver = function* ({ payload: [carId, dto] }: ReturnType<typeof DriverActions.addDriverRequested>) {
  try {
    const item: Driver = yield call(managers.addDriver, carId, dto)
    yield put(DriverActions.addDriverSuccess(carId, item))
  } catch (err) {
    const { type, detail, errors } = RequestError.parseError(err)
    yield put(DriverActions.addDriverFailed(carId, type, detail, errors))
  }
}

export const updateDriver = function* ({
  payload: [carId, driverId, car],
}: ReturnType<typeof DriverActions.updateDriverRequested>) {
  try {
    const item: Driver = yield call(managers.updateDriver, driverId, car)
    yield put(DriverActions.updateDriverSuccess(driverId, item))
  } catch (err) {
    const { type, detail, errors } = RequestError.parseError(err)
    yield put(DriverActions.updateDriverFailed(carId, type, detail, errors))
  }
}

export const detachDriver = function* ({
  payload: [carId, driverId],
}: ReturnType<typeof DriverActions.detachDriverRequest>) {
  try {
    yield call(managers.detachDriver, carId, driverId)
    yield put(DriverActions.detachDriverSuccess(carId, driverId))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(DriverActions.detachDriverFailed(carId, type, detail))
  }
}

const DriverSaga = function* () {
  yield all([
    takeLatest(DriverActions.listRequested.type, fetchDriversList),
    takeLatest(DriverActions.addDriverRequested.type, addDriver),
    takeLatest(DriverActions.updateDriverRequested.type, updateDriver),
    takeLatest(DriverActions.detachDriverRequest.type, detachDriver),
  ])
}

export default DriverSaga
