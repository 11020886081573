import React, { ReactElement, VFC } from 'react'

import { FormikContextType } from 'formik'

import { useServerValidation } from 'hooks/useServerValidation'
import { Error, FieldWrapper } from 'views/ui/FormItem/styled'

export type AdditionalProps = {
  invalid: boolean
}

export type Props = {
  formikInstance?: FormikContextType<any>
  fieldName?: string
  errorFields: Record<string, string[]> | undefined
  width?: number
  render: (additionalProps: AdditionalProps) => ReactElement
  className?: string
}

export const FormItem: VFC<Props> = ({ errorFields, fieldName, formikInstance, width, render, className }) => {
  const [invalid, errorText] = useServerValidation(errorFields, fieldName, formikInstance)
  return (
    <div className={className}>
      <FieldWrapper $width={width}>{render({ invalid })}</FieldWrapper>
      {invalid && !!errorText && <Error>{errorText}</Error>}
    </div>
  )
}
