import React from 'react'
import { useTranslation } from 'react-i18next'

import { SimpleSelect } from '@agro-club/agroclub-shared'

import { Filter } from 'views/components/TableFilters/TableFilters'
import * as Styled from 'views/pages/User/UserTable/styled'

type ActiveFilterProps = {
  handleChange: (value?: string) => void
  handleClear: () => void
  is_active?: boolean
  title: string
}

export const ActiveFilter: React.FC<ActiveFilterProps> = ({ handleChange, handleClear, is_active, title }) => {
  const { t } = useTranslation('common')
  const options = [
    { id: 'true', title: t('yes') },
    { id: 'false', title: t('no') },
  ]
  return (
    <Filter title={title}>
      <SimpleSelect
        placeholder={t('common:allActiveStatus')}
        isClearable
        options={options}
        onChange={handleChange}
        customStyles={Styled.customSelectStyles}
        value={String(is_active)}
        onClear={handleClear}
      />
    </Filter>
  )
}
