import React from 'react'
import { useTranslation } from 'react-i18next'

import { IconArrowDown } from '@agro-club/agroclub-shared'

import { ToggleArchived } from 'views/styled/ToggleArchived'

type Props = {
  children: React.ReactNode
  visible: boolean
}

export const ColumnSpoiler: React.FC<Props> = ({ children, visible }) => {
  const { t } = useTranslation()

  const [open, setOpen] = React.useState(false)

  if (!visible) return null

  return (
    <>
      <ToggleArchived $open={open} onClick={() => setOpen(oldVal => !oldVal)}>
        {t('common:archived')} <IconArrowDown />
      </ToggleArchived>
      {open && children}
    </>
  )
}
