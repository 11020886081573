import React, { useEffect, useRef, useState } from 'react'

import styled, { StyledProps } from 'styled-components'

import useLocalStorageState from 'hooks/useLocalStorageState'
import { ToggleArrow } from 'views/ui/ToggleArrow/ToggleArrow'

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  margin-top: -1px;
`

const BodyStyled = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`

const BodyInner = styled.div<{ noPadding?: boolean; maxWidth?: number }>`
  max-width: ${({ maxWidth }) => maxWidth ?? 1156}px;
  padding: ${({ noPadding }) => (noPadding ? '0' : '16px 22px')};
`

const Body = React.forwardRef<
  HTMLDivElement,
  { children: React.ReactNode | React.ReactNode[]; maxWidth?: number; noPadding?: boolean }
>(({ children, maxWidth, noPadding }, ref) => (
  <BodyStyled>
    <BodyInner noPadding={noPadding} maxWidth={maxWidth} ref={ref}>
      {children}
    </BodyInner>
  </BodyStyled>
))

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Footer = styled.div`
  flex-grow: 0;
  background: ${({ theme }) => theme.color.onPrimaryLight};
`

const ButtonsFooter = styled.div`
  border-top: 1px solid #e6e6e6;
  flex-grow: 0;
  flex-shrink: 0;
  height: 69px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.color.onPrimaryLight};

  & > *:not(:last-child) {
    margin-right: 20px;
  }
`

const DrawerWrapper = styled.div<StyledProps<{ translateX?: number }>>`
  position: absolute;
  top: 0;
  left: 0;
  transform: ${({ translateX }) => `translate(${translateX}px)`};
  z-index: 10;
  width: auto;
  height: 100%;
  transition: transform 0.3s cubic-bezier(0.06, 0.975, 0.195, 0.985);
  background-color: ${props => props.theme.color.onPrimaryLight};
  box-shadow: -1px 0 0 rgba(0, 0, 0, 0.08);
`

const DrawerInner = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`

const DrawerArrowWrapper = styled.div`
  position: absolute;
  top: 38px;
  left: -12px;
`

const Drawer = React.forwardRef<
  HTMLDivElement,
  {
    hideArrow: boolean
    offset: number
    onToggle: () => void
    hidden: boolean
    children: React.ReactNode | React.ReactNode[]
  }
>(({ hideArrow, hidden, offset, onToggle, children }, ref) => (
  <DrawerWrapper translateX={offset} ref={ref}>
    <DrawerInner>
      {!hideArrow ? (
        <DrawerArrowWrapper>
          <ToggleArrow rotated={!hidden} onClick={onToggle} />
        </DrawerArrowWrapper>
      ) : null}
      {children}
    </DrawerInner>
  </DrawerWrapper>
))

const StickyFooterLayout: React.FC<{
  body: React.ReactNode | React.ReactNode[] | string
  footer?: React.ReactNode | React.ReactNode[] | string
  drawer?: React.ReactNode | React.ReactNode[] | string
  bodyMaxWidth?: number
  noPadding?: boolean
}> = ({ body, footer, drawer, bodyMaxWidth, noPadding }) => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const bodyRef = useRef<HTMLDivElement>(null)
  const drawerRef = useRef<HTMLDivElement>(null)
  const [hidden, setHidden] = useLocalStorageState<boolean>(true, 'userRightPanelHidden')
  const [size, setSize] = useState({ body: 0, inner: 100000, drawer: 0 })

  useEffect(() => {
    const wrapper = wrapperRef.current
    const body = bodyRef.current
    const drawer = drawerRef.current
    if (!wrapper || !body || !drawer) {
      return
    }

    const calcOffset = () => {
      setSize({
        inner: window.innerWidth - (wrapper.getBoundingClientRect().left || 0),
        body: body.getBoundingClientRect().width,
        drawer: drawer.getBoundingClientRect().width,
      })
    }
    calcOffset()
    window.addEventListener('resize', calcOffset)
    return () => {
      window.removeEventListener('resize', calcOffset)
    }
  }, [wrapperRef])

  const gap = 26
  const delta = size.inner - size.body - gap
  const offset = delta < 0 ? size.inner - gap : delta < size.drawer ? size.body : size.inner - size.drawer
  const translateX = !hidden ? Math.max(26, size.inner - size.drawer) : offset

  return (
    <Wrapper ref={wrapperRef}>
      <Body ref={bodyRef} maxWidth={bodyMaxWidth} noPadding={noPadding}>
        {body}
      </Body>
      {footer ? <ButtonsFooter>{footer}</ButtonsFooter> : null}
      {drawer ? (
        <Drawer
          ref={drawerRef}
          hidden={hidden}
          hideArrow={delta > size.drawer}
          onToggle={() => setHidden(!hidden)}
          offset={translateX}
        >
          {drawer}
        </Drawer>
      ) : null}
    </Wrapper>
  )
}

export default StickyFooterLayout
