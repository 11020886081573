import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Progress, useAction } from '@agro-club/agroclub-shared'

import { useNotificationProgress } from 'hooks/useNotificationProgress'
import usePrevious from 'hooks/usePrevious'
import { Bid } from 'modules/domain/bid/types'
import DealActions from 'modules/domain/deal/duck'
import DealSelectors from 'modules/domain/deal/selectors'
import FilesByTypes from 'views/components/FilesByTypes/FilesByTypes'
import { DocumentsWrapper } from 'views/components/UserRightPanel/styled'

export enum DocumentsFrom {
  purchase = 'purchase',
  sale = 'sale',
}

interface Props {
  context: DocumentsFrom
}

export const DocumentsSummary: React.FC<Props> = ({ context }) => {
  const { t } = useTranslation(['user', 'common'])
  const { id: dealId } = useParams<{ id: string }>()
  const deal = useSelector(state => DealSelectors.item(state, dealId))
  const bid = context === DocumentsFrom.sale ? (deal?.sale_bid as Bid) : (deal?.purchase_bid as Bid)
  const id = bid?.owner.id
  const list = useSelector(state => DealSelectors.documentFiles(state, id))
  return <div>{list?.length ? t('common:thereIs') : t('common:no')}</div>
}

export const Documents: React.FC<Props & { ready: boolean }> = ({ context, ready }) => {
  const { t } = useTranslation('common')
  const { id: dealId } = useParams<{ id: string }>()
  const deal = useSelector(state => DealSelectors.item(state, dealId))
  const bid = context === DocumentsFrom.sale ? (deal?.sale_bid as Bid) : (deal?.purchase_bid as Bid)
  const id = bid?.owner.id

  const documentTypes = useSelector(state => DealSelectors.documentTypes(state, id))
  const getDocumentTypesRequested = useAction(DealActions.getDocumentTypesRequested)
  const uploadFile = useAction(DealActions.uploadDocumentRequested)
  const getDocumentFilesRequested = useAction(DealActions.getDocumentFilesRequested)
  const documentFiles = useSelector(state => DealSelectors.documentFiles(state, id))
  const uploadProgress = useSelector(state => DealSelectors.uploadProgress(state, id))
  const deleteDocumentRequested = useAction(DealActions.deleteTypedDocumentRequested)
  const deleteDocumentProgress = useSelector(state => DealSelectors.deleteTypedDocumentProgress(state, id))
  const uploadErrorDetail = useSelector(state => DealSelectors.uploadErrorDetail(state, id))

  const prevUploadProgress = usePrevious(uploadProgress)
  const prevDeleteDocumentProgress = usePrevious(deleteDocumentProgress)

  useNotificationProgress(uploadProgress, undefined, uploadErrorDetail || t('uploadError'))

  useEffect(() => {
    if (!ready) {
      return
    }

    if (id) {
      getDocumentTypesRequested(id)
      getDocumentFilesRequested(id)
    }
  }, [getDocumentFilesRequested, getDocumentTypesRequested, id, ready])

  useEffect(() => {
    if (prevUploadProgress === Progress.WORK && uploadProgress === Progress.SUCCESS && id) {
      getDocumentFilesRequested(id)
    }
  }, [id, uploadProgress, prevUploadProgress, getDocumentFilesRequested])

  useEffect(() => {
    if (prevDeleteDocumentProgress === Progress.WORK && deleteDocumentProgress === Progress.SUCCESS && id) {
      getDocumentFilesRequested(id)
    }
  }, [id, prevDeleteDocumentProgress, deleteDocumentProgress, getDocumentFilesRequested])

  const onUploadHandler = (document_type, file) => {
    uploadFile(id, document_type, file)
  }

  const onDeleteHandler = document_id => {
    deleteDocumentRequested(id, document_id)
  }

  return (
    <DocumentsWrapper>
      <FilesByTypes types={documentTypes} files={documentFiles} onUpload={onUploadHandler} onDelete={onDeleteHandler} />
    </DocumentsWrapper>
  )
}
