import React from 'react'

import { IconCross, IconSort, Progress, useInfiniteScroll } from '@agro-club/agroclub-shared'

import { Pagination } from 'views/components/CallStatisticsTable/helpers'
import * as Styled from 'views/components/CallStatisticsTable/styled'

type TableProps = {
  hasMore: boolean
  handler: () => void
  isLoading: boolean
  delta?: number
  isSummaryOpen?: boolean
  summary?: React.ReactElement
  className?: string
}

export const InfiniteScrollTable: React.FC<TableProps> = ({
  children,
  hasMore,
  handler,
  delta,
  isLoading,
  isSummaryOpen,
  summary,
  ...props
}) => {
  const { wrapperRef, anchor } = useInfiniteScroll(handler, hasMore, isLoading, delta)
  return (
    <Styled.TableWrapper ref={wrapperRef} isSummaryOpen={isSummaryOpen}>
      <Styled.Table {...props}>{children}</Styled.Table>
      {isLoading ? <Styled.Spinner /> : null}
      {anchor}
      {summary}
    </Styled.TableWrapper>
  )
}

export const Table = React.forwardRef<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  {
    total: number
    pages: number
    pageSize: number
    currentPage: number
    onSetPage: (page: number) => void
    isSummaryOpen?: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children?: any
    className?: string
    fixedLayout?: boolean
  }
>(({ children, isSummaryOpen, total, pageSize, currentPage, onSetPage, pages, ...props }, ref) => (
  <Styled.TableWrapper isSummaryOpen={isSummaryOpen} ref={ref}>
    <Styled.TableInner>
      <Styled.Table {...props}>{children}</Styled.Table>
      {total > pageSize ? (
        <Styled.PaginationContainer>
          <Pagination total={pages} current={currentPage} onClick={onSetPage} />
        </Styled.PaginationContainer>
      ) : null}
    </Styled.TableInner>
  </Styled.TableWrapper>
))
Table.displayName = 'Table'

export const TableHead: React.FC<{ className?: string }> = ({ children, ...props }) => (
  <thead {...props}>{children}</thead>
)
export const TableBody: React.FC<{ className?: string }> = ({ children, ...props }) => (
  <Styled.Body {...props}>{children}</Styled.Body>
)
export const TableHeadRow: React.FC<{ className?: string }> = ({ children, ...props }) => <tr {...props}>{children}</tr>
export const TableBodyRow: React.FC<{ className?: string; onClick?: () => void; selected?: boolean }> = ({
  children,
  onClick,
  selected,
  ...props
}) => (
  <Styled.Row {...props} onClick={onClick} selected={selected} clickable={!!onClick}>
    {children}
  </Styled.Row>
)

export type TableHeadCellProps = {
  sortable?: boolean
  testId?: string
  isSorted?: boolean
  hidden?: boolean
  desc?: boolean
  className?: string
  width?: number | string
  onClick?: () => void
  top?: string
  zIndex?: number
  emphatic?: boolean
  transparent?: boolean
  headerHorizontalAlign?: 'left' | 'right' | 'center'
  headerVerticalAlign?: 'top' | 'bottom' | 'middle'
  title?: string
}
export const TableHeadCell: React.FC<TableHeadCellProps> = ({
  children,
  isSorted,
  testId,
  desc,
  className,
  width,
  sortable,
  onClick,
  hidden,
  top,
  zIndex = 0,
  emphatic = false,
  transparent = false,
  headerHorizontalAlign = 'left',
  headerVerticalAlign = 'middle',
  title,
}) => {
  if (hidden) {
    return null
  }
  return (
    <Styled.HeadCell
      style={{ zIndex, width }}
      data-test-id={testId}
      emphatic={emphatic}
      sortable={sortable}
      transparent={transparent}
      onClick={onClick}
      top={top}
      headerHorizontalAlign={headerHorizontalAlign}
      headerVerticalAlign={headerVerticalAlign}
      title={title || (typeof children === 'string' ? children : '')}
    >
      <Styled.HeadCellWrapper
        className={className}
        headerHorizontalAlign={headerHorizontalAlign}
        headerVerticalValign={headerVerticalAlign}
      >
        <Styled.HeadCellInner isSorted={isSorted}>{children}</Styled.HeadCellInner>
        {sortable && (
          <Styled.Sort show={isSorted} desc={desc} headerHorizontalAlign={headerHorizontalAlign}>
            <IconSort />
          </Styled.Sort>
        )}
      </Styled.HeadCellWrapper>
    </Styled.HeadCell>
  )
}

export const TableBodyCell: React.FC<{
  noWrap?: boolean
  stopClickPropagation?: boolean
  className?: string
  emphatic?: boolean
  cellAlign?: 'left' | 'right' | 'center'
  cellValign?: 'top' | 'bottom' | 'middle'
  colSpan?: number
  rowSpan?: number
}> = ({ children, noWrap, stopClickPropagation, className, ...props }) => (
  <Styled.BodyCell {...props} className={className} onClick={(e: any) => stopClickPropagation && e.stopPropagation()}>
    {noWrap ? <Styled.EllipsisContainer>{children}</Styled.EllipsisContainer> : children}
  </Styled.BodyCell>
)

export const TableNoData: React.FC<{
  progress: Progress
  isEmpty: boolean
  colSpan: number
  loading?: React.ReactElement | string
  className?: string
}> = ({ children = 'Sorry, nothing found', loading = 'Loading...', isEmpty, colSpan, progress, className }) => {
  if (progress === Progress.WORK || progress === Progress.IDLE) {
    return (
      <tr>
        <Styled.EmptyRowCell className={className} colSpan={colSpan}>
          {loading}
        </Styled.EmptyRowCell>
      </tr>
    )
  }

  if ([Progress.SUCCESS, Progress.ERROR].includes(progress) && isEmpty) {
    return (
      <tr>
        <Styled.EmptyRowCell className={className} colSpan={colSpan}>
          {children}
        </Styled.EmptyRowCell>
      </tr>
    )
  }
  return null
}

export const TableActionPanel: React.FC<{ onClose: () => void; className?: string }> = ({
  children,
  onClose,
  className,
}) => (
  <Styled.ActionPanelWrapper className={className}>
    <Styled.ActionPanelContainer>
      <Styled.ActionPanelContentWrapper>{children}</Styled.ActionPanelContentWrapper>
      <Styled.CloseButton intent="cancel" onClick={onClose}>
        <IconCross />
      </Styled.CloseButton>
    </Styled.ActionPanelContainer>
  </Styled.ActionPanelWrapper>
)

export const TableSkeletonRow: React.FC<{ className?: string; colSpan: number }> = ({ className, colSpan }) => (
  <TableBodyRow>
    <Styled.SkeletonCell className={className} colSpan={colSpan} />
  </TableBodyRow>
)
