import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Progress, useAction } from '@agro-club/agroclub-shared'

import useFormatDate from 'hooks/useFormatDate'
import TaskActions from 'modules/domain/task/duck'
import { useUserTasks } from 'modules/domain/task/hooks'
import TaskSelectors from 'modules/domain/task/selectors'
import { Task } from 'modules/domain/task/types'
import {
  AdditionalInfo,
  CompleteButton,
  Deadline,
  Item,
  NoTasks,
  TaskDetail,
  TaskWrapper,
  TasksList,
  Text,
} from 'views/components/UserRightPanel/UserTasks/styled'
import { SubmitIcon } from 'views/styled/common'

export const UserTasksSummary: React.FC = () => {
  const { t } = useTranslation(['user', 'common'])
  const { id } = useParams<{ id: string }>()
  const list = useSelector(state => TaskSelectors.userTasksList(state, id))
  return <div>{list.length || t('common:no')}</div>
}

export const TaskItem: React.FC<{ task: Task }> = ({ task }) => {
  const { t } = useTranslation('task')
  const completeAction = useAction(TaskActions.completeTaskRequested)
  const completeProgress = useSelector(TaskSelectors.completeProgress)
  const team = task?.assigned_team?.name || ''
  const assignee = task?.assignee?.short_name || ''
  const additionalInfo = [team, assignee].filter(Boolean).join(' / ')
  const formatDate = useFormatDate()

  return (
    <Item>
      <TaskDetail>
        <Deadline>{formatDate(task.deadline, true)}</Deadline>
        <Text>{task.subject}</Text>
        <Text>{task.comment}</Text>
        <AdditionalInfo>{additionalInfo}</AdditionalInfo>
      </TaskDetail>
      <CompleteButton
        title={t('makeComplete')}
        intent="minor-action"
        onClick={() => {
          if (task) completeAction(task.id)
        }}
        progress={completeProgress}
      >
        <SubmitIcon />
      </CompleteButton>
    </Item>
  )
}

export const UserTasks: React.FC = () => {
  const { id: userId } = useParams<{ id: string }>()
  const [progress, list] = useUserTasks(userId)
  const { t } = useTranslation('task')
  return (
    <TaskWrapper>
      <TasksList>
        {progress === Progress.SUCCESS && !list.length ? (
          <NoTasks>{t('noTasks')}</NoTasks>
        ) : (
          list.map(item => <TaskItem key={item.id} task={item} />)
        )}
      </TasksList>
    </TaskWrapper>
  )
}
