import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Progress, SimpleSelect, SimpleSelectProps } from '@agro-club/agroclub-shared'

import { Address } from 'modules/domain/types'
import { useUserAddress } from 'modules/domain/userAddress/hooks'

type Props = {
  onChange: (address: Address) => void
  invalid?: SimpleSelectProps['invalid']
  menuPlacement?: SimpleSelectProps['menuPlacement']
  userId?: string
  initialValue?: string | number | undefined
  label?: string
  errorText?: string
  placeholder?: string
  noOptionsMessage?: SimpleSelectProps['noOptionsMessage']
  required?: boolean
  additionalStyles?: SimpleSelectProps['additionalStyles']
  isClearable?: SimpleSelectProps['isClearable']
  onClear?: () => void
  size?: SimpleSelectProps['size']
}

export const UserAddressSelect: React.FC<Props> = props => {
  const {
    onChange: onChangeProps,
    invalid,
    menuPlacement,
    userId,
    initialValue,
    label,
    errorText,
    placeholder,
    noOptionsMessage,
    required,
    additionalStyles,
    isClearable,
    onClear,
    size,
  } = props
  const [value, setValue] = useState<string | number | undefined>(initialValue)
  const { t } = useTranslation()
  const [progress, addresses] = useUserAddress(userId)
  const options = useMemo<SimpleSelectProps['options']>(
    () =>
      addresses.map(address => ({
        id: address.id,
        title: address.address,
      })),
    [addresses],
  )

  const onChange = useCallback(
    (id: string | number | undefined) => {
      setValue(id)
      const address = addresses.find(address => address.id === id)
      if (address) {
        onChangeProps(address)
      } else {
        onClear && onClear()
      }
    },
    [setValue, onChangeProps, addresses, onClear],
  )

  const isDisabled = !userId

  return (
    <SimpleSelect
      size={size}
      additionalStyles={additionalStyles}
      isDisabled={isDisabled}
      invalid={invalid}
      errorText={errorText}
      progress={progress === Progress.IDLE ? Progress.SUCCESS : progress}
      onChange={onChange}
      label={label}
      placeholder={placeholder}
      loadingMessage={t('common:loading')}
      noOptionsMessage={noOptionsMessage}
      options={options}
      value={value}
      isSearchable
      menuPlacement={menuPlacement}
      required={required}
      isClearable={isClearable}
    />
  )
}
