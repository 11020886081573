import React from 'react'

import { Typography } from '@agro-club/agroclub-shared'
import styled, { StyledProps } from 'styled-components'

import { box } from 'views/styled/common'

export const Container = styled.div`
  ${box};
`

const COMPANY_FORM_WIDTH = '566px'

export const AddWrapper = styled.div`
  margin: 16px 24px;
  width: ${COMPANY_FORM_WIDTH};
`

export const TwoColumn = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(${COMPANY_FORM_WIDTH}, 1fr));
  grid-gap: 24px;
  padding: 16px 24px;
`

export const DetailHeader = styled.div`
  padding: 16px 24px;
  border-bottom: ${({ theme }) => `1px solid ${theme.color.secondary50}`};
  ${Typography.largeHeadlineBold};
`

export const CommonContent = styled.div`
  padding: 16px 24px;
  display: grid;
  grid-gap: 16px;
`

export const ExtraContent = styled(CommonContent)`
  grid-gap: 32px;
`

export const CommonWrapper = styled.div`
  margin-top: -8px;
`

export const ExtraHeader = styled.div`
  ${Typography.footnoteCAPS};
  text-transform: uppercase;
  margin-bottom: 12px;
`

export const OkvedCode = styled.div`
  ${Typography.body2Default}
`

export const OkvedText = styled(OkvedCode)`
  color: ${({ theme }) => theme.color.textSecondary};
`

export const ExtraData = styled.div`
  display: grid;
  grid-template-columns: 330px 1fr;
  grid-auto-rows: max-content;
  row-gap: 16px;
  column-gap: 0;
`

export const Key = styled.div`
  color: ${({ theme }) => theme.color.textSecondary};
  ${Typography.body2Default};
  margin-bottom: 4px;
`

export const Value = styled.div<StyledProps<{ $isNumber?: boolean }>>`
  color: ${({ theme }) => theme.color.textPrimary};
  ${Typography.body2Default};
  font-variant-numeric: ${({ $isNumber }) => ($isNumber ? 'tabular-nums' : 'normal')};
`

export const VerticalKeyValue: React.FC<{ label: React.ReactNode }> = props => {
  const { label } = props
  return (
    <div>
      <Key>{label}</Key>
      <Value>{props.children}</Value>
    </div>
  )
}

export const Agreement = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-auto-flow: column;
`

export const ButtonsWrapper = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-auto-flow: column;
`

export const ExtraDataWrapper = styled.div`
  display: grid;
  grid-gap: 32px;
  padding: 16px 24px 24px;
`

export const ExtraDataField = styled.div`
  display: grid;
  grid-gap: 32px;
  grid-auto-flow: column;
`
