import { Link } from 'react-router-dom'

import { Button, IconCheck, IconPencil, Typography } from '@agro-club/agroclub-shared'
import styled, { css } from 'styled-components'

export const box = css`
  background-color: ${({ theme }) => theme.color.onPrimaryLight};
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04);
`

export const smallBox = css`
  background-color: ${({ theme }) => theme.color.onPrimaryLight};
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04);
`

export const StyledOption = styled.div`
  &:hover {
    background: ${props => props.theme.color.primary50};
    cursor: pointer;
  }

  ${Typography.caption1Default}
`

export const FirstCapitalize = styled.span`
  display: inline-block;

  ${Typography.body1Bold}
  &::first-letter {
    text-transform: capitalize;
  }
`

export const BlueLink = styled(Link)<{ $isInline?: boolean }>`
  display: ${({ $isInline }) => ($isInline ? 'inline-block' : 'block')};
  position: relative;
  color: ${({ theme }) => theme.color.primary500};

  &:hover {
    text-decoration: underline;
  }
`

export const FlexBr = styled.div`
  width: 100%;
`

export const EditIcon = styled(IconPencil)`
  fill: ${props => props.theme.color.secondary200};
  transition: fill 0.1s ease-in;

  &:hover {
    fill: ${props => props.theme.color.secondary300};
  }
`

export const IconSubmitContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: ${props => props.theme.color.primary600};
  border-radius: 8px;
`

export const SubmitIcon = styled(IconCheck)`
  fill: ${props => props.theme.color.onPrimaryLight};
  width: 25px;
  height: 15px;
`

export const CancelButton = styled(Button)`
  background-color: ${({ theme }) => theme.color.primary50};

  &:hover {
    background-color: ${({ theme }) => theme.color.primary25};
  }
`

export const AddTextBtn = styled.span`
  display: inline-block;
  margin-top: 8px;
  color: ${({ theme }) => theme.color.primary600};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  ${Typography.body2Default}
`
