import React, { VFC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import {
  Checkbox,
  Input,
  Progress,
  Radio,
  RadioItem,
  SimpleSelect,
  useAction,
  useHelmet,
} from '@agro-club/agroclub-shared'
import { useFormik } from 'formik'

import { useNotificationProgress } from 'hooks/useNotificationProgress'
import usePrevious from 'hooks/usePrevious'
import CarActions from 'modules/domain/car/duck'
import CarSelectors from 'modules/domain/car/selectors'
import CarrierSelectors from 'modules/domain/carrier/selectors'
import { UnloadingType, VehicleType } from 'modules/domain/types'
import { AddTrailer } from 'views/components/AddTrailer/AddTrailer'
import { TrailerSelect } from 'views/components/TrailerSelect/TrailerSelect'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import { CarFormButtons } from 'views/pages/Carrier/CarrierDetail/UserCars/CarFormButtons'
import { convertOptionalValue } from 'views/pages/Carrier/CarrierDetail/UserCars/helpers'
import * as S from 'views/pages/Carrier/CarrierDetail/UserCars/styled'
import { TrailerWrapper } from 'views/pages/Carrier/CarrierDetail/UserCars/styled'
import { useUserEditGoBack } from 'views/pages/User/hooks'
import { FormItem } from 'views/ui/FormItem/FormItem'
import * as Header from 'views/ui/Header/Header'

type EditableFields = {
  brand_model: string
  licence_number: string
  default_trailer: string | null
  car_type?: VehicleType
  unloading_type?: UnloadingType
  side_height?: number
  is_in_charter: boolean | null
  is_dump_truck: boolean | null
}

export const CarAdd: VFC = () => {
  const { t } = useTranslation('vehicles')
  const { id } = useParams<{ id: string }>()
  const progress = useSelector(CarSelectors.addProgress)
  const createAction = useAction(CarActions.addRequested)
  const goBack = useUserEditGoBack(id)
  const errorFields = useSelector(CarSelectors.addErrorFields)
  const [withTrailer, setWithTrailer] = useState<boolean>(false)
  const trailerLicenceNumber = useSelector(CarrierSelectors.trailerLicenceNumber)
  const trailerAddProgress = useSelector(CarrierSelectors.trailerAddProgress)
  const prevTrailerAddProgress = usePrevious(trailerAddProgress)
  const trailers = useSelector(state => CarrierSelectors.activeTrailers(state, id))
  const [addedLicenceNumber, setAddedLicenceNumber] = useState('')

  useHelmet({ title: t('form.add') })
  useNotificationProgress(progress, t('form.notifyAddSuccess'))

  const prevProgress = usePrevious(progress)
  useEffect(() => {
    if (progress === Progress.SUCCESS && prevProgress === Progress.WORK) {
      goBack()
    }
  }, [progress, goBack, prevProgress])

  const formik = useFormik<EditableFields>({
    initialValues: {
      is_in_charter: null,
      is_dump_truck: null,
      licence_number: '',
      default_trailer: null,
      brand_model: '',
    },
    enableReinitialize: true,
    validateOnBlur: false,

    onSubmit: () => {
      if (!formik.isValid) {
        return
      }
      const {
        brand_model,
        licence_number,
        default_trailer,
        car_type,
        unloading_type,
        side_height,
        is_in_charter,
        is_dump_truck,
      } = formik.values

      createAction({
        brand_model,
        owner: id,
        licence_number,
        default_trailer,
        car_type,
        unloading_type,
        side_height: side_height || undefined,
        is_in_charter,
        is_dump_truck,
      })
    },
  })

  const setUpperCase = useCallback(
    (field: string, value: string) => {
      formik.setFieldValue(field, value.replace(/\s/g, '').toUpperCase())
    },
    [formik],
  )

  useEffect(() => {
    if (
      withTrailer &&
      !formik.values.default_trailer &&
      trailerLicenceNumber &&
      prevTrailerAddProgress === Progress.WORK &&
      trailerAddProgress === Progress.SUCCESS
    ) {
      setAddedLicenceNumber(trailerLicenceNumber)
    }
  }, [withTrailer, formik, trailerLicenceNumber, trailerAddProgress, prevTrailerAddProgress])

  useEffect(() => {
    if (addedLicenceNumber && trailers.some(item => item.licence_number === addedLicenceNumber)) {
      const licence_id = trailers.find(item => item.licence_number === addedLicenceNumber)?.id
      setAddedLicenceNumber('')
      formik.setFieldValue('default_trailer', licence_id)
    }
  }, [trailers, addedLicenceNumber, formik])

  return (
    <>
      <Layout.Header hasBottomBorder>
        <Header.Root
          rightBlock={
            <CarFormButtons
              onSubmitClick={formik.submitForm}
              disableSubmitButton={!formik.dirty}
              progress={progress}
              goBack={goBack}
            />
          }
        >
          <Header.Title title={t('form.add')} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <S.TwoColumnLayout>
          <form
            onSubmit={e => {
              e.preventDefault()
              formik.dirty && formik.submitForm()
            }}
          >
            <S.FormWrapper>
              <S.FormGrid>
                <S.FormLabel>{t('form.brand_model')}</S.FormLabel>
                <FormItem
                  width={153}
                  fieldName="brand_model"
                  errorFields={errorFields}
                  formikInstance={formik}
                  render={additionalProps => (
                    <Input
                      inputSize="small"
                      maxLength={30}
                      {...formik.getFieldProps('brand_model')}
                      {...additionalProps}
                    />
                  )}
                />

                <S.FormLabel>{t('form.licence_number')}</S.FormLabel>
                <FormItem
                  width={153}
                  fieldName="licence_number"
                  errorFields={errorFields}
                  formikInstance={formik}
                  render={additionalProps => (
                    <S.LicenseNumberWrapper>
                      <Input
                        inputSize="small"
                        maxLength={10}
                        onChange={(_, v) => {
                          setUpperCase('licence_number', v)
                        }}
                        value={formik.values.licence_number}
                        placeholder={t('form.licenceNumberPlaceholder')}
                        {...additionalProps}
                      />
                      <Checkbox
                        onChange={(_v, isChecked) => {
                          setWithTrailer(isChecked)
                        }}
                        label={t('form.withTrailer')}
                        isChecked={withTrailer}
                      />
                    </S.LicenseNumberWrapper>
                  )}
                />

                {withTrailer && (
                  <>
                    <S.FormLabel>{t('form.trailer_licence_number')}</S.FormLabel>
                    <FormItem
                      fieldName="default_trailer"
                      errorFields={errorFields}
                      formikInstance={formik}
                      render={additionalProps => (
                        <div>
                          <TrailerWrapper>
                            <TrailerSelect
                              selectedId={formik.values.default_trailer}
                              onChange={value => {
                                formik.setFieldValue('default_trailer', value)
                              }}
                              owner={id}
                              invalid={additionalProps.invalid}
                              size="small"
                            />
                          </TrailerWrapper>
                          <div>
                            <AddTrailer owner={id} />
                          </div>
                        </div>
                      )}
                    />
                  </>
                )}

                <S.FormLabel>{t('form.car_type')}</S.FormLabel>
                <FormItem
                  width={200}
                  fieldName="car_type"
                  errorFields={errorFields}
                  formikInstance={formik}
                  render={additionalProps => (
                    <SimpleSelect
                      size="small"
                      value={formik.values.car_type}
                      onChange={value => formik.setFieldValue('car_type', value)}
                      options={Object.values(VehicleType).map(v => ({
                        id: v,
                        title: t(`vehicles:vehicleTypes.${v}`),
                      }))}
                      {...additionalProps}
                    />
                  )}
                />

                <S.FormLabel>{t('form.unloading_type')}</S.FormLabel>
                <FormItem
                  width={200}
                  fieldName="unloading_type"
                  errorFields={errorFields}
                  formikInstance={formik}
                  render={additionalProps => (
                    <SimpleSelect
                      size="small"
                      value={formik.values.unloading_type}
                      onChange={value => formik.setFieldValue('unloading_type', value)}
                      options={Object.values(UnloadingType).map(v => ({
                        id: v,
                        title: t(`vehicles:unloadingType.${v}`),
                      }))}
                      {...additionalProps}
                    />
                  )}
                />

                <S.FormLabel>{t('form.side_height')}</S.FormLabel>
                <S.SideHeightInput
                  value={formik.values.side_height}
                  onChange={v => formik.setFieldValue('side_height', v)}
                  invalid={formik.touched.side_height && !!formik.errors.side_height}
                  inputSize="small"
                />

                <S.FormLabel>{t('form.is_dump_truck')}</S.FormLabel>
                <Radio
                  onChange={value => formik.setFieldValue('is_dump_truck', convertOptionalValue(value as string))}
                  value={convertOptionalValue(formik.values.is_dump_truck) as string}
                >
                  <RadioItem value="true" label={t('common:yes')} />
                  <RadioItem value="false" label={t('common:no')} />
                  <RadioItem value="null" label={t('common:dontKnow')} />
                </Radio>

                <S.FormLabel>{t('form.is_in_charter')}</S.FormLabel>
                <Radio
                  onChange={value => formik.setFieldValue('is_in_charter', convertOptionalValue(value as string))}
                  value={convertOptionalValue(formik.values.is_in_charter) as string}
                >
                  <RadioItem value="true" label={t('common:yes')} />
                  <RadioItem value="false" label={t('common:no')} />
                  <RadioItem value="null" label={t('common:dontKnow')} />
                </Radio>
              </S.FormGrid>
            </S.FormWrapper>
          </form>
        </S.TwoColumnLayout>
      </Layout.Content>
    </>
  )
}
