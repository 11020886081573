import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  GoogleAddressForm,
  GoogleAddressFormProps,
  YandexAddressForm,
  YandexAddressFormProps,
} from '@agro-club/agroclub-shared'

import {
  REACT_APP_GOOGLE_MAPS_API_KEY,
  REACT_APP_LOCALE,
  REACT_APP_USE_GOOGLE_MAPS,
  REACT_APP_YA_MAPS_API_KEY,
} from 'env'

type AddressFormProps = Omit<YandexAddressFormProps, 'apikey'> | Omit<GoogleAddressFormProps, 'apikey'>

const AddressForm: React.FC<AddressFormProps> = props => {
  const { t } = useTranslation('bid')

  if (REACT_APP_USE_GOOGLE_MAPS) {
    return (
      <GoogleAddressForm
        {...props}
        apikey={REACT_APP_GOOGLE_MAPS_API_KEY}
        language={REACT_APP_LOCALE}
        placeholder={props.placeholder || t('filterByAddress')}
      >
        {props.children}
      </GoogleAddressForm>
    )
  }

  return (
    <YandexAddressForm {...props} apikey={REACT_APP_YA_MAPS_API_KEY}>
      {props.children}
    </YandexAddressForm>
  )
}

export default AddressForm
