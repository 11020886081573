import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { generatePath } from 'react-router'
import { useHistory } from 'react-router-dom'

import { Progress, useAction, useHelmet } from '@agro-club/agroclub-shared'

import usePrevious from 'hooks/usePrevious'
import UserActions from 'modules/domain/user/duck'
import UserSelectors from 'modules/domain/user/selectors'
import { UserForm } from 'views/components/UserForm/UserForm'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import { useCarrierGoBack } from 'views/pages/Carrier/hooks'
import CarrierRoutes from 'views/pages/Carrier/routes'
import * as Header from 'views/ui/Header/Header'

export function CarrierAdd() {
  const { t } = useTranslation('carrier')
  const progress = useSelector(UserSelectors.addProgress)
  const createAction = useAction(UserActions.addRequested)
  const goBack = useCarrierGoBack()

  const history = useHistory()
  const lastId = useSelector(UserSelectors.lastAddedUserId)
  const prevProgress = usePrevious(progress)

  useEffect(() => {
    if (lastId && prevProgress === Progress.WORK && progress === Progress.SUCCESS) {
      history.push(generatePath(CarrierRoutes.Detail, { id: lastId }))
    }
  }, [progress, lastId, prevProgress, history])

  useHelmet({ title: t('add') })

  return (
    <>
      <Layout.Header hasBottomBorder>
        <Header.Root onClickBack={goBack}>
          <Header.Title compact title={t('add')} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <UserForm isCarrierForm goBack={goBack} mode="create" onSubmit={createAction} progress={progress} />
      </Layout.Content>
    </>
  )
}
