import React from 'react'
import { useTranslation } from 'react-i18next'

import { useHelmet } from '@agro-club/agroclub-shared'

import * as Layout from 'views/layouts/MainLayout/MainLayout'
import LogisticsTable from 'views/pages/Logistics/LogisticsTable/LogisticsTable'
import * as Header from 'views/ui/Header/Header'

export const LogisticsList: React.FC = () => {
  const { t } = useTranslation('logistics')
  useHelmet({ title: t('list.heading') })
  return (
    <>
      <Layout.Header>
        <Header.Root>
          <Header.Title title={t('list.heading')} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <LogisticsTable />
      </Layout.Content>
    </>
  )
}
