import { useSelector } from 'react-redux'

import { useAction, useDidMount, useDidMountWithProgress, usePageQuery, useQuery } from '@agro-club/agroclub-shared'
import { isEmpty } from 'ramda'

import CallsActions, { ListRequestedParams } from 'modules/domain/calls/duck'
import CallsSelectors from 'modules/domain/calls/selectors'
import { Account, Calls, CallsListRequestFilter, CallsListRequestSorting } from 'modules/domain/calls/types'
import { ResourceHook } from 'modules/types'

export const useCallsFilterAndSortQuery = () => {
  const query = useQuery()
  const search = query.get('query')
  const sort_field: keyof Calls = query.get('sort_field') as keyof Calls
  const sort_reversed = query.get('sort_reversed')
  const call_type = query.get('call_type')
  const coordinator = query.get('coordinator')
  const call_start_after = query.get('call_start_after')
  const call_start_before = query.get('call_start_before')

  const filter: CallsListRequestFilter = {
    ...(search && { search }),
    ...(call_type && { call_type }),
    ...(coordinator && { coordinator }),
    ...(call_start_after && { call_start_after }),
    ...(call_start_before && { call_start_before }),
  }

  const sorting: CallsListRequestSorting = {
    ...(sort_field && { sort_field }),
    ...(sort_reversed && { sort_reversed: sort_reversed === 'true' }),
  }

  return { filter, sorting }
}

export const useCallsList: ResourceHook<Calls[], void[]> = () => {
  const progress = useSelector(CallsSelectors.listFetchProgress)
  const list = useSelector(CallsSelectors.callsList)
  const page = usePageQuery()
  const { filter } = useCallsFilterAndSortQuery()
  const { sorting } = useCallsFilterAndSortQuery()

  const params: ListRequestedParams = {
    ...(!isEmpty(filter) && { filter }),
    ...(!isEmpty(sorting) && { sorting }),
    ...(page && { page }),
  }

  const fetchAction = useAction(CallsActions.listRequested, params)

  useDidMount(fetchAction)

  return [progress, list]
}

export const useAccounts: ResourceHook<Account[]> = () => {
  const accounts = useSelector(CallsSelectors.accounts)
  const meta = useSelector(CallsSelectors.accountsProgress)
  const fetchAction = useAction(CallsActions.accountsRequested)

  const progress = useDidMountWithProgress(fetchAction, meta)

  return [progress, accounts]
}
