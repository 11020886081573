import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { CheckboxSelect } from '@agro-club/agroclub-shared'

import { useRegions } from 'modules/domain/collection/hooks'
import CollectionSelectors from 'modules/domain/collection/selectors'
import { Filter } from 'views/components/TableFilters/TableFilters'

type Option = {
  id: string
  title: string
}

type Props = {
  onChange: (regionId: string) => void
  onClear: () => void
  region?: string[]
}

export const RegionFilter: React.FC<Props> = ({ onChange, onClear, region = [] }) => {
  const { t } = useTranslation('regionFilter')
  const [progress] = useRegions()
  const regions = useSelector(CollectionSelectors.getRootRegions)

  const options: Option[] = useMemo(
    () =>
      regions.map(region => ({
        id: region.id,
        title: region.name,
      })),
    [regions],
  )

  return (
    <Filter title={t('label')}>
      <CheckboxSelect
        isSearchable
        loadingMessage={t('common:loading')}
        noOptionsMessage={() => t('noRegionFound')}
        progress={progress}
        options={options}
        selected={region}
        onChange={onChange}
        size="small"
        additionalStyles={{
          control: {
            width: 186,
          },
          menu: {
            width: '100%',
            minWidth: 256,
          },
        }}
        onClear={onClear}
        singleSelected={region.length === 1 ? options?.find(team => team.id === region[0])?.title : undefined}
        placeholder={t('placeholder')}
      />
    </Filter>
  )
}
