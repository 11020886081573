import { Progress } from '@agro-club/agroclub-shared'
import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer'

import { AuthState, CodeCredentials, ProfileDTO, TokensPair, UserProfile } from './types'

type TokenRequestFailedPayload = {
  message: string
}

const initialState: AuthState = {
  isAuthenticated: false,
  error: '',
  profile: undefined,
  initProgress: Progress.IDLE,
  profileUpdateProgress: Progress.IDLE,
  tokenRequestProgress: Progress.IDLE,
  codeRequestedProgress: Progress.IDLE,
  codeError: '',
  step: 'get_code',
  phone: '+7',
}

class AuthReducer extends ImmerReducer<AuthState> {
  init() {
    this.draftState.initProgress = Progress.WORK
  }

  initSucceed(profile: UserProfile) {
    this.draftState.profile = profile
    this.draftState.initProgress = Progress.SUCCESS
    this.draftState.isAuthenticated = true
  }

  initFailed() {
    this.draftState.initProgress = Progress.ERROR
    this.draftState.isAuthenticated = false
  }

  codeRequested(_payload: CodeCredentials) {
    this.draftState.codeRequestedProgress = Progress.WORK
    this.draftState.codeError = ''
  }

  codeRequestFailed(payload: TokenRequestFailedPayload) {
    this.draftState.codeError = payload.message
    this.draftState.codeRequestedProgress = Progress.ERROR
  }

  codeRequestSucceed({ phone }: CodeCredentials) {
    this.draftState.codeRequestedProgress = Progress.IDLE
    this.draftState.phone = phone
    this.draftState.step = 'login'
  }

  goToGetCodeStep() {
    this.draftState.step = 'get_code'
  }

  tokenRequested(_payload: { code: string }) {
    this.draftState.tokenRequestProgress = Progress.WORK
    this.draftState.error = ''
  }

  tokenRequestSucceed(_payload: TokensPair) {
    this.draftState.tokenRequestProgress = Progress.SUCCESS
    this.draftState.step = 'get_code'
  }

  tokenRequestFailed(payload: TokenRequestFailedPayload) {
    this.draftState.error = payload.message
    this.draftState.tokenRequestProgress = Progress.ERROR
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  userUpdateRequested(profile: Partial<ProfileDTO>) {
    this.draftState.profileUpdateProgress = Progress.WORK
  }

  userUpdateSucceed(profile: UserProfile) {
    this.draftState.profileUpdateProgress = Progress.SUCCESS
    this.draftState.profile = profile
  }

  userUpdateFailed() {
    this.draftState.profileUpdateProgress = Progress.ERROR
  }

  signOutRequested() {
    this.draftState.isAuthenticated = false
  }
}

const AuthActions = createActionCreators(AuthReducer)
export default AuthActions
export const reducer = createReducerFunction(AuthReducer, initialState)
