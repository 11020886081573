import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { generatePath } from 'react-router-dom'
import { Row, useTable } from 'react-table'

import {
  Progress,
  Table,
  TableBody,
  TableBodyRow,
  TableHead,
  TableHeadRow,
  TableNoData,
  useAction,
  usePersistentScroll,
} from '@agro-club/agroclub-shared'

import { noop } from 'helpers/noop'
import useLocationFrom from 'hooks/useLocationFrom'
import TaskActions from 'modules/domain/task/duck'
import TaskSelectors from 'modules/domain/task/selectors'
import { Task } from 'modules/domain/task/types'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import {
  LinkedDealCell,
  LinkedUserCell,
  TaskSortableHeadCell,
  TaskStatusCell,
} from 'views/components/TaskCells/TaskCells'
import DealRoutes from 'views/pages/Deal/routes'
import UserRoutes from 'views/pages/User/routes'

const getPathname = (row: Row<Task>) => {
  const userId = row?.original?.linked_user?.id
  const dealId = row?.original?.linked_deal?.id

  if (userId) {
    return generatePath(UserRoutes.Detail, { id: userId })
  }

  if (dealId) {
    return generatePath(DealRoutes.Edit, { id: dealId })
  }

  return null
}

export const Calls: React.FC = () => {
  const { t } = useTranslation('task')
  const progress = useSelector(TaskSelectors.callBackFetchProgress)
  const data = useSelector(TaskSelectors.callBackList)

  const listRequested = useAction(TaskActions.callBackListRequested)
  const total = useSelector(TaskSelectors.callBackTotal)
  const pages = useSelector(TaskSelectors.callBackPages)
  const page = useSelector(TaskSelectors.callBackPage)
  const pageSize = useSelector(TaskSelectors.callBackPageSize)

  const visibleColumns = React.useMemo(
    () => [
      {
        Header: t('list.tableHeaders.deadline'),
        accessor: 'deadline' as const,
        sortable: false,
        Cell: TComponents.DateTimeCell,
      },
      {
        Header: t('list.tableHeaders.subject'),
        accessor: 'subject' as const,
        sortable: false,
      },
      {
        Header: t('common:team'),
        accessor: 'assigned_team.name' as const,
      },
      {
        Header: t('common:user'),
        accessor: 'linked_user.short_name',
        Cell: LinkedUserCell,
      },
      {
        Header: t('common:deal'),
        accessor: 'linked_deal.id',
        Cell: LinkedDealCell,
      },
      {
        Header: t('list.tableHeaders.assignee'),
        accessor: 'assignee.short_name' as const,
      },
      {
        Header: t('list.tableHeaders.status'),
        accessor: 'is_completed' as const,
        Cell: TaskStatusCell,
      },
    ],
    [t],
  )

  const hiddenColumns: (keyof Task)[] = useMemo(() => ['id'], [])

  const columnsAll = useMemo(
    () => [
      ...visibleColumns,
      ...hiddenColumns.map(col => ({
        Header: col,
        accessor: col,
        hidden: true,
      })),
    ],
    [hiddenColumns, visibleColumns],
  )

  const { columns, rows, prepareRow } = useTable<Task>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore weird issue with react-table typings — having more then 26 fields in type causes TS error
    columns: columnsAll,
    data: data as Task[],
    initialState: { hiddenColumns },
  })

  const setPage = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  const { scrollRef } = usePersistentScroll('taskList')

  const isFilterApplied = false

  const from = useLocationFrom()

  return (
    <TComponents.Wrapper>
      <Table total={total} pages={pages} pageSize={pageSize} currentPage={page} onSetPage={setPage} ref={scrollRef}>
        <TableHead>
          <TableHeadRow>
            {columns.map(column => (
              <TaskSortableHeadCell
                zIndex={1}
                key={column.getHeaderProps().key}
                id={column.id as keyof Task}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                sortable={column.sortable}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                hidden={column.hidden}
                // sortField={sort_field}
                // sortDesc={sort_reversed}
                onChange={noop}
              >
                {column.render('Header')}
              </TaskSortableHeadCell>
            ))}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map(row => {
            prepareRow(row)
            const { key, ...props } = row.getRowProps()
            const pathname = getPathname(row)
            return (
              <TableBodyRow key={key} {...props}>
                {row.cells.map(cell => {
                  const { key, ...props } = cell.getCellProps()

                  return (
                    <TComponents.RelativeTableBodyCell key={key} {...props}>
                      {!!pathname && <TComponents.TableCellLink to={{ pathname, state: { from } }} />}
                      {cell.render('Cell')}
                    </TComponents.RelativeTableBodyCell>
                  )
                })}
              </TableBodyRow>
            )
          })}
          <TableNoData
            progress={progress}
            isEmpty={!rows.length}
            colSpan={visibleColumns.length}
            loading={<TComponents.Spinner />}
          >
            <div>{isFilterApplied ? t('list.emptyFilterMsg') : t('list.emptyMsg')}</div>
            {isFilterApplied && progress !== Progress.WORK && (
              <TComponents.ClearButton intent="cancel" size="small">
                {t('common:resetAllFilters')}
              </TComponents.ClearButton>
            )}
          </TableNoData>
        </TableBody>
      </Table>
    </TComponents.Wrapper>
  )
}
