import React from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'

import { Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import env from 'env'
import useFormatDate from 'hooks/useFormatDate'
import useFormatNumber from 'hooks/useFormatNumber'
import useLocationFrom from 'hooks/useLocationFrom'
import useMainParameter from 'hooks/useMainParameter'
import { DashboardTask } from 'modules/domain/dashboard/types'
import BidRoutes from 'views/pages/Bid/routes'

import { InputHeader, Item, ItemBody, ItemLink } from './styled'

type Props = {
  task: DashboardTask
}

const DateWraper = styled.div`
  ${Typography.body2Default};
  color: ${({ theme }) => theme.color.textSecondary};
`

export const BidItemStatus = styled.div`
  ${Typography.caption1Default}
`

export const BidItemProduct = styled.div`
  ${Typography.body2Bold}
`
export const BidItemDescription = styled.div`
  ${Typography.body2Default}
`
export const BidItemPrice = styled.div`
  ${Typography.body2Heavy}
`

export const BidItem: React.FC<Props> = props => {
  const { task } = props
  const bid = task.linked_bid
  const { t } = useTranslation('common')
  const price = bid?.bid_type === 'purchase' ? bid?.price_cpt : bid?.price_exw
  const formatNumber = useFormatNumber()
  const formatDate = useFormatDate()
  const from = useLocationFrom()
  const currency = env.REACT_APP_CURRENCY_SYMBOL
  const parameter = useMainParameter(bid?.parameters)

  return (
    <Item>
      {!!bid?.id && <ItemLink to={{ pathname: generatePath(BidRoutes.Detail, { id: bid?.id }), state: { from } }} />}

      <InputHeader task={task} />

      <ItemBody>
        <DateWraper>
          <div>
            {t('dashboard:bidId')} {bid?.id}
          </div>
          <div>
            {t('dashboard:created')} {formatDate(bid?.created, true)}
          </div>
        </DateWraper>
        <BidItemStatus>{t(`bid:bidStatuses.${bid?.status}`)}</BidItemStatus>
        <BidItemProduct>{bid?.product?.title}</BidItemProduct>
        <BidItemDescription>
          {formatNumber(bid?.quantity_in_bags)} {t('bags')}
          &nbsp; ({formatNumber(bid?.quantity)} {t('ton')} ) / {parameter}
        </BidItemDescription>
        <BidItemPrice>
          {currency} {formatNumber(price, true)}
        </BidItemPrice>
      </ItemBody>
    </Item>
  )
}
