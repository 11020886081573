import { Link } from 'react-router-dom'

import { Button, IconCheck, IconLinkOpener, Typography } from '@agro-club/agroclub-shared'
import styled, { StyledProps, css } from 'styled-components'

import { Header, Item } from 'views/components/ColumnBoard/styled'

export const UserColumnContainer = styled.div`
  display: inline-grid;
  grid-column-gap: 16px;
  grid-auto-flow: column;
  grid-template-columns: 272px 272px 272px;
  height: auto;
  min-height: 100%;
`

export const BidColumnHeader = styled(Header)`
  justify-content: space-between;
`

export const DealItem = styled(Item)`
  position: relative;
  padding: 10px 40px 26px 12px;
  &:hover {
    background-color: ${({ theme }) => theme.color.primary25};
  }
`

export const itemCardButtonCss = css`
  border: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  background-color: ${({ theme }) => theme.color.primary50};
  svg {
    fill: ${({ theme }) => theme.color.primary600};
  }
`

export const DealProgressButton = styled(Button)`
  position: absolute;
  top: 8px;
  right: 8px;
  :hover {
    background-color: ${({ theme }) => theme.color.primary100};
  }
  ${itemCardButtonCss};
`

export const DealArchiveButton = styled(Button)`
  ${itemCardButtonCss};
  background-color: transparent;
  svg {
    fill: ${({ theme }) => theme.color.secondary300};
  }
  :hover {
    background-color: ${({ theme }) => theme.color.accentDestruct16a};
    svg {
      fill: ${({ theme }) => theme.color.accentDestruct500};
    }
  }
`

export const DealArchiveButtonWrapper = styled.div`
  position: absolute;
  bottom: 8px;
  right: 8px;
`

export const DealDetailsLinkButton = styled(Link)`
  display: block;
  position: absolute;
  top: 8px;
  right: 8px;
  border-radius: 8px;
  transition: 0.1s background-color ease-out;
  ${itemCardButtonCss};
`

export const StyledIconLinkOpener = styled(IconLinkOpener)`
  width: 18px;
  height: 18px;
`

export const DealItemStatus = styled.div`
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
  padding-right: 18px;
  margin-bottom: 8px;
  ${Typography.caption1Default};
  color: ${({ theme }) => theme.color.onSurfaceMidEmphasys};
`

export const DealItemExw = styled.div`
  ${Typography.subheadlineBold}
`
export const DealItemCpt = styled.div`
  margin-bottom: 4px;
  ${Typography.body2Bold};
`
export const DealItemDescription = styled.div`
  margin-bottom: 9px;
  ${Typography.body2Default}
`

export const DealItemMargin = styled.div`
  margin-bottom: 9px;
  ${Typography.body2Default}
`

export const DealItemCompany = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 2px;
  ${Typography.body2Default}
`

export const HasDealIcon = styled(IconCheck)`
  fill: ${props => props.theme.color.primary500};
  width: 25px;
  height: 15px;
  margin-left: 16px;
`

export const DealItemAddress = styled.div`
  color: ${({ theme }) => theme.color.onSurfaceMidEmphasys};
  ${Typography.caption1Default}
`

export const BidItem = styled(Item)<StyledProps<{ $isActive?: boolean }>>`
  padding: 8px 28px 12px 12px;
  background-color: ${({ $isActive, theme }) => ($isActive ? theme.color.primary600 : theme.color.onPrimaryLight)};
  &:hover {
    background-color: ${({ $isActive, theme }) => ($isActive ? theme.color.primary500 : theme.color.primary25)};
  }
  color: ${({ $isActive, theme }) => ($isActive ? theme.color.onPrimaryLight : theme.color.onSurfaceHighEmphasys)};
  cursor: pointer;
`

export const BidItemStatus = styled.div`
  text-transform: capitalize;
  margin-bottom: 3px;
  ${Typography.caption1Default}
`

export const BidItemProduct = styled.div`
  ${Typography.body2Bold}
`
export const BidItemDescription = styled.div`
  margin-bottom: 6px;
  ${Typography.body2Default}
`
export const BidItemPrice = styled.div`
  margin-bottom: 6px;
  ${Typography.subheadlineBold}
`

export const BidItemAddress = styled.div<StyledProps<{ $isActive?: boolean }>>`
  color: ${({ $isActive, theme }) => ($isActive ? theme.color.onPrimaryLight : theme.color.onSurfaceMidEmphasys)};
  ${Typography.caption1Default}
`

export const DealItemLink = styled(Link)`
  display: block;
`

export const PotentialItem = styled(Item)`
  padding: 8px 12px;
  &:hover {
    background-color: ${({ theme }) => theme.color.primary25};
  }
`

export const PotentialItemLink = styled(Link)`
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
`

export const PotentialBidStatus = styled.div`
  ${Typography.caption1Default};
  margin-bottom: 4px;
`

export const PotentialBidProduct = styled.div`
  ${Typography.body2Bold};
`

export const PotentialBidDescription = styled.div`
  ${Typography.body2Default};
`
