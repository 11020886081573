import { Progress } from '@agro-club/agroclub-shared'
import { createSelector } from 'reselect'

import { EntityMetadata } from 'modules/domain/types'
import { Dict } from 'types/generics.d'

import { AppGlobalState } from '../../types'
import { Trip } from './types'

const dict = (state: AppGlobalState) => state.trip.items
const ids = (state: AppGlobalState, dealId: string) => (dealId ? state.trip.tripsByDeal[dealId] ?? [] : state.trip.ids)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const item = (state: AppGlobalState, id: string): Trip | undefined => state.trip.items[id]
const meta = (state: AppGlobalState, id: string): EntityMetadata<Trip> =>
  state.trip.meta[id] || {
    fetchError: null,
    fetchProgress: Progress.IDLE,
    id,
    removeError: null,
    removeProgress: Progress.IDLE,
    updateError: null,
    updateProgress: Progress.IDLE,
  }
const itemFetchProgress = (state: AppGlobalState) => state.trip.itemFetchProgress
const listFetchProgress = (state: AppGlobalState) => state.trip.listFetchProgress
const listFetchError = (state: AppGlobalState) => state.trip.listFetchError
const listFetchErrorDetail = (state: AppGlobalState) => state.trip.listFetchErrorDetail
const addErrorFields = (state: AppGlobalState) => state.trip.addErrorFields

const list = createSelector(dict, ids, listFetchProgress, (dict, ids, progress) => {
  if (progress === Progress.WORK) {
    return []
  }
  const result: Trip[] = []
  for (const id of ids) {
    result.push(dict[id])
  }
  return result.filter(Boolean)
})

const carCounter = createSelector(dict, ids, listFetchProgress, (dict, ids, progress) => {
  const counters: Dict<number> = {}
  if (progress === Progress.WORK) {
    return {}
  }

  for (const id of ids) {
    const carId = dict[id]?.car.id
    if (carId) {
      counters[carId] = (counters[carId] ?? 0) + 1
    }
  }
  return counters
})

const updateProgress = (state: AppGlobalState) => state.trip.updateProgress
const removeProgress = (state: AppGlobalState) => state.trip.removeProgress
const addProgress = (state: AppGlobalState) => state.trip.addProgress

const documentTypes = (state: AppGlobalState) => state.trip.documentTypes
const documentProgress = (state: AppGlobalState) => state.trip.getDocumentProgress
const uploadProgress = (state: AppGlobalState) => state.trip.uploadProgress
const documentFiles = (state: AppGlobalState) => state.trip.documentFiles
const documentFilesProgress = (state: AppGlobalState) => state.trip.getDocumentFilesProgress
const deleteDocumentProgress = (state: AppGlobalState) => state.trip.deleteDocumentProgress

const TripSelectors = {
  updateProgress,
  removeProgress,
  addProgress,
  item,
  meta,
  itemFetchProgress,
  listFetchProgress,
  listFetchError,
  listFetchErrorDetail,
  addErrorFields,
  list,
  carCounter,
  dict,
  ids,
  documentTypes,
  documentProgress,
  uploadProgress,
  documentFiles,
  documentFilesProgress,
  deleteDocumentProgress,
}

export default TripSelectors
