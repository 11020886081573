import React, { VFC } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'

import useFormatNumber from 'hooks/useFormatNumber'
import useLocationFrom from 'hooks/useLocationFrom'
import useMainParameter from 'hooks/useMainParameter'
import { PotentialBid } from 'modules/domain/potentialBid/types'
import BidRoutes from 'views/pages/Bid/routes'
import {
  PotentialBidDescription,
  PotentialBidProduct,
  PotentialBidStatus,
  PotentialItem,
  PotentialItemLink,
} from 'views/pages/User/UserDetail/UserColumnBoard/styled'

type Props = {
  bid: PotentialBid
}

export const PotentialBidItem: VFC<Props> = ({ bid }) => {
  const from = useLocationFrom()
  const { t } = useTranslation('bid')
  const parameter = useMainParameter(bid.parameters)
  const formatNumber = useFormatNumber()

  return (
    <PotentialItem>
      <PotentialItemLink to={{ pathname: generatePath(BidRoutes.PotentialDetail, { id: bid.id }), state: { from } }} />
      <PotentialBidStatus>{t('potentialBidStatus')}</PotentialBidStatus>
      <PotentialBidProduct>{bid.product?.title}</PotentialBidProduct>
      <PotentialBidDescription>
        {formatNumber(bid.quantity_in_bags) ?? '?'} {t('common:bags')} / {parameter}
      </PotentialBidDescription>
    </PotentialItem>
  )
}
