import { Button, IconUser, Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const CommentTextAreaWrapper = styled.div`
  margin-top: auto;
  & > div:first-child {
    height: 82px;
  }
  & > div:first-child textarea {
    min-height: 80px;
  }
`

export const CommentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const CommentsList = styled.div<{ maxHeight?: number }>`
  flex-grow: 1;
  overflow-y: auto;
  margin-top: -2px;
  margin-bottom: 24px;
  max-height: ${props => (props.maxHeight ? `${props.maxHeight}px` : 'none')};
`

export const CommentContainer = styled.div`
  &:not(:first-child) {
    margin-top: 18px;
  }
`

export const CommentHeader = styled.div`
  margin-bottom: 8px;
`

export const CommentUserIcon = styled(IconUser)`
  fill: ${props => props.theme.color.textTertiary};
  margin-right: 6px;
  vertical-align: middle;
`

export const CommentUserName = styled.span`
  ${Typography.caption1Bold};
`

export const CommentDate = styled.span`
  ${Typography.caption1Default};
  margin-left: 8px;
  color: ${props => props.theme.color.textSecondary};
`

export const CommentText = styled.div`
  ${Typography.body2Default};
  color: ${props => props.theme.color.onSurfaceHighEmphasys};
  white-space: pre-line;
`

export const SendCommentButton = styled(Button)`
  margin-top: 12px;
`

export const RightPanelComments = styled.div`
  margin-top: -5px;
  height: 100%;
`
