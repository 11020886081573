import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import PotentialBidActions from 'modules/domain/potentialBid/duck'
import * as managers from 'modules/domain/potentialBid/managers'
import PotentialBidSelectors from 'modules/domain/potentialBid/selectors'
import { PotentialBid } from 'modules/domain/potentialBid/types'
import { RequestError } from 'modules/errors'
import { ListResponse } from 'types/api.d'

export const fetchList = function* () {
  try {
    let currentPage = yield select(PotentialBidSelectors.page)
    const filter = yield select(PotentialBidSelectors.filterForRequestManager)
    const sorting = yield select(PotentialBidSelectors.sorting)
    const pageSize = yield select(PotentialBidSelectors.pageSize)

    let response: ListResponse<PotentialBid> = yield call(managers.getList, filter, sorting, currentPage, pageSize)
    const pages = Math.ceil(response.count / pageSize)

    if (pages !== 0 && pages < currentPage) {
      response = yield call(managers.getList, filter, sorting, pages, pageSize)
      currentPage = pages
    }

    const { results, count, current } = response
    yield put(PotentialBidActions.listRequestSucceed(results, count, current))

    // yield call(updateLocationQuery, PotentialBidRoutes.List, { page: currentPage, ...filter, ...sorting })
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(PotentialBidActions.listRequestFailed(type, detail))
  }
}

export const fetchItem = function* ({ payload: id }: ReturnType<typeof PotentialBidActions.itemRequested>) {
  try {
    const item: PotentialBid = yield call(managers.getItem, id)
    yield put(PotentialBidActions.itemRequestSucceed(item))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(PotentialBidActions.itemRequestFailed(id, type, detail))
  }
}

export const addItem = function* ({ payload: { dto } }: ReturnType<typeof PotentialBidActions.addRequested>) {
  try {
    const item: { id: string } = yield call(managers.addItem, dto)
    yield put(PotentialBidActions.addSucceed(item.id))
  } catch (err) {
    const { type, detail, errors } = RequestError.parseError(err)
    yield put(PotentialBidActions.addFailed(type, detail, errors))
  }
}

export const updateItem = function* ({ payload: [id, dto] }: ReturnType<typeof PotentialBidActions.updateRequested>) {
  try {
    yield call(managers.updateItem, id, dto)
    const item: PotentialBid = yield call(managers.getItem, id)
    yield put(PotentialBidActions.updateSucceed(item))
  } catch (err) {
    const { type, detail, errors } = RequestError.parseError(err)
    yield put(PotentialBidActions.updateFailed(id, type, detail, errors))
  }
}

export const removeItem = function* ({ payload }: ReturnType<typeof PotentialBidActions.removeRequested>) {
  try {
    yield call(managers.removeItem, payload)
    yield put(PotentialBidActions.removeSucceed(payload))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(PotentialBidActions.removeFailed(payload, type, detail))
  }
}

export const fetchUserBidList = function* ({
  payload: userId,
}: ReturnType<typeof PotentialBidActions.userBidListRequested>) {
  try {
    const list = yield call(managers.getUserBidList, userId)
    yield put(PotentialBidActions.userBidListRequestSucceed(userId, list))
  } catch (err) {
    const { type } = RequestError.parseError(err)
    yield put(PotentialBidActions.userBidListRequestFailed(type))
  }
}

const PotentialBidSaga = function* () {
  yield all([
    takeLatest(PotentialBidActions.itemRequested.type, fetchItem),
    takeLatest(PotentialBidActions.listRequested.type, fetchList),
    takeLatest(PotentialBidActions.filterUpdated.type, fetchList),
    takeLatest(PotentialBidActions.sortingUpdated.type, fetchList),
    takeLatest(PotentialBidActions.filterHasBeenReset.type, fetchList),
    takeLatest(PotentialBidActions.sortingHasBeenReset.type, fetchList),

    takeLatest(PotentialBidActions.addRequested.type, addItem),
    takeLatest(PotentialBidActions.updateRequested.type, updateItem),
    takeLatest(PotentialBidActions.removeRequested.type, removeItem),
    takeLatest(PotentialBidActions.userBidListRequested.type, fetchUserBidList),
  ])
}

export default PotentialBidSaga
