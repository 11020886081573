import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { Button, Progress } from '@agro-club/agroclub-shared'
import { useFormik } from 'formik'
import { isNil } from 'ramda'

import { stringifyUrl } from 'helpers/stringifyUrl'
import CompanySelectors from 'modules/domain/company/selectors'
import { CompanyBR, CompanyExtraDataDTO } from 'modules/domain/company/types'
import { LocationStateFrom } from 'types/location'
import { ButtonsWrapper } from 'views/pages/Company/CompanyDetail/styled'
import {
  CompanyButtonsOptions,
  CompanyEditableFields,
  CompanyFormikOptions,
  ExtraDataOptions,
} from 'views/pages/Company/CompanyDetail/types'
import CompanyRoutes from 'views/pages/Company/routes'

export const useCompanyGoBackLocation = () => {
  const page = useSelector(CompanySelectors.page)
  const filter = useSelector(CompanySelectors.filter)
  const sorting = useSelector(CompanySelectors.sorting)

  const search = stringifyUrl({ url: '', query: { ...filter, ...sorting, page } })

  return {
    pathname: CompanyRoutes.List,
    search,
  }
}

export const useCompanyGoBack = () => {
  const location = useLocation<LocationStateFrom>()
  const defaultBackLocation = useCompanyGoBackLocation()
  const from = location.state?.from
  const history = useHistory()

  return () => {
    history.push({
      pathname: from?.pathname || defaultBackLocation.pathname,
      search: from?.pathname ? from?.search : defaultBackLocation.search,
    })
  }
}

export const useYesNo = () => {
  const { t } = useTranslation()
  return useCallback(
    (val: boolean | undefined | null) => (isNil(val) ? t('common:unknown') : val ? t('common:yes') : t('common:no')),
    [t],
  )
}

export const useThereIsNo = () => {
  const { t } = useTranslation()
  return useCallback(
    (val: boolean | undefined | null) =>
      isNil(val) ? t('common:unknown') : val ? t('common:thereIs') : t('common:no'),
    [t],
  )
}

export const useCompanyFormik = ({ onSubmit, initialValues }: CompanyFormikOptions) => {
  const {
    inn = '',
    name = '',
    payment_delay_days = 0,
    cooperation_type_agent = false,
    cooperation_type_trader = false,
    state_reg_number,
  } = initialValues || {}

  const formik = useFormik<CompanyEditableFields>({
    initialValues: {
      inn,
      name,
      payment_delay_days,
      cooperation_type_agent,
      cooperation_type_trader,
      prepayment: undefined,
      vat: undefined,
      state_reg_number,
    },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: () => {
      if (!formik.isValid) {
        return
      }
      onSubmit({
        ...formik.values,
        inn: formik.values.inn || null,
      } as CompanyBR)
    },
  })

  return formik
}

export const useCompanyButtons = ({ formik, progress, extraFormik, extraProgress }: CompanyButtonsOptions) => {
  const goBack = useCompanyGoBack()
  const { t } = useTranslation('company')
  const enabled = (formik.dirty || extraFormik?.dirty) && extraProgress !== Progress.WORK && progress !== Progress.WORK

  const combinedProgress = progress === Progress.WORK || extraProgress === Progress.WORK ? Progress.WORK : Progress.IDLE
  const onClick = useCallback(() => {
    if (formik.dirty) {
      formik.submitForm().catch()
    }
    if (extraFormik && extraFormik.dirty) {
      extraFormik.submitForm().catch()
    }
  }, [formik, extraFormik])

  return (
    <ButtonsWrapper>
      <Button
        filled
        size="small"
        progress={combinedProgress}
        disabled={!enabled}
        intent="primary"
        onClick={onClick}
        data-test-id="save-button"
      >
        {t('common:save')}
      </Button>
      <Button
        size="small"
        intent="primary"
        onClick={() => {
          goBack()
        }}
      >
        {t('common:cancel')}
      </Button>
    </ButtonsWrapper>
  )
}

export const useExtraDataFormik = ({ initialValues, onSubmit }: ExtraDataOptions) => {
  const {
    activity_type = '',
    has_agro_arbitration_cases = null,
    arbitration_claims_sum = null,
    has_blocked_bank_accounts = null,
    directors_companies_have_problems = null,
    connected_companies_have_problems = null,
    has_all_documents = false,
    paid_us_without_delay_amount = null,
  } = initialValues || {}

  const formik = useFormik<CompanyExtraDataDTO>({
    initialValues: {
      activity_type,
      has_agro_arbitration_cases,
      arbitration_claims_sum,
      has_blocked_bank_accounts,
      directors_companies_have_problems,
      connected_companies_have_problems,
      has_all_documents,
      paid_us_without_delay_amount,
    },
    enableReinitialize: true,
    onSubmit: () => {
      onSubmit(formik.values)
    },
  })

  return formik
}
