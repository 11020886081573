import React, { useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { useAction } from '@agro-club/agroclub-shared'

import BidActions from 'modules/domain/bid/duck'
import PageWrapper from 'views/components/PageWrapper/PageWrapper'
import { BidAdd } from 'views/pages/Bid/BidAdd/BidAdd'
import { BidEdit } from 'views/pages/Bid/BidAdd/BidEdit'
import { PotentialBidEdit } from 'views/pages/Bid/BidAdd/PotentialBidEdit'
import { BidDetail } from 'views/pages/Bid/BidDetail/BidDetail'
import { BidDetailPotential } from 'views/pages/Bid/BidDetail/BidDetailPotential'
import BidList from 'views/pages/Bid/BidList'
import Routes from 'views/pages/Bid/routes'

function BidPage() {
  const filterReset = useAction(BidActions.filterResetWithoutRequest)

  useEffect(
    () => () => {
      filterReset()
    },
    [filterReset],
  )

  return (
    <PageWrapper>
      <Switch>
        <Route path={Routes.List} exact>
          <BidList />
        </Route>
        <Route path={Routes.Detail} exact>
          <BidDetail />
        </Route>
        <Route path={Routes.Edit} exact>
          <BidEdit />
        </Route>
        <Route path={Routes.PotentialDetail} exact>
          <BidDetailPotential />
        </Route>
        <Route path={Routes.PotentialEdit} exact>
          <PotentialBidEdit />
        </Route>
        <Route path={Routes.Add} exact>
          <BidAdd />
        </Route>
        <Redirect to={Routes.List} />
      </Switch>
    </PageWrapper>
  )
}

export default BidPage
