import { all, call, put, select, takeLatest, takeLeading } from 'redux-saga/effects'

import formatDateWithoutLocale from 'helpers/formatDateWithoutLocale'
import normalizeBidAddress from 'helpers/normalizeBidAddress'
import { SERVER_DATE_FORMAT } from 'modules/constants'
import CollectionSelectors from 'modules/domain/collection/selectors'
import DealActions from 'modules/domain/deal/duck'
import * as managers from 'modules/domain/deal/managers'
import DealSelectors from 'modules/domain/deal/selectors'
import { Deal, DealShipment } from 'modules/domain/deal/types'
import { RespFile } from 'modules/domain/types'
import * as userManagers from 'modules/domain/user/managers'
import UserAddressActions from 'modules/domain/userAddress/duck'
import { RequestError } from 'modules/errors'
import { updateLocationQuery } from 'modules/sagaHelpers'
import { ListResponse } from 'types/api.d'
import DealRoutes from 'views/pages/Deal/routes'

export const fetchList = function* () {
  try {
    let currentPage = yield select(DealSelectors.page)
    const filter = yield select(DealSelectors.filter)
    const sorting = yield select(DealSelectors.sorting)
    const pageSize = yield select(DealSelectors.pageSize)

    let response: ListResponse<Deal> = yield call(managers.getList, filter, sorting, currentPage, pageSize)
    const pages = Math.ceil(response.count / pageSize)

    if (pages !== 0 && pages < currentPage) {
      response = yield call(managers.getList, filter, sorting, pages, pageSize)
      currentPage = pages
    }

    const { results, count, current } = response
    yield put(DealActions.listRequestSucceed(results, count, current))

    yield call(updateLocationQuery, DealRoutes.List, { page: currentPage, ...filter, ...sorting })
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(DealActions.listRequestFailed(type, detail))
  }
}

export const fetchItem = function* ({ payload: id }: ReturnType<typeof DealActions.itemRequested>) {
  try {
    const item: Deal = yield call(managers.getItem, id)
    const { saleAddress, purchaseAddress, normalizedItem } = normalizeBidAddress(item)
    yield put(UserAddressActions.itemRequestSucceed(saleAddress))
    yield put(UserAddressActions.itemRequestSucceed(purchaseAddress))
    yield put(DealActions.itemRequestSucceed(normalizedItem as Deal))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(DealActions.itemRequestFailed(id, type, detail))
  }
}

export const addItem = function* ({ payload: dto }: ReturnType<typeof DealActions.addRequested>) {
  try {
    const item: Deal = yield call(managers.addItem, dto)
    yield put(DealActions.addSucceed(item))
  } catch (err) {
    const { type, detail, errors } = RequestError.parseError(err)
    yield put(DealActions.addFailed(type, detail, errors))
  }
}

export const updateItem = function* ({ payload: [id, dto] }: ReturnType<typeof DealActions.updateRequested>) {
  try {
    yield call(managers.updateItem, id, dto)
    const item: Deal = yield call(managers.getItem, id)

    const { saleAddress, purchaseAddress, normalizedItem } = normalizeBidAddress(item)
    yield put(UserAddressActions.itemRequestSucceed(saleAddress))
    yield put(UserAddressActions.itemRequestSucceed(purchaseAddress))

    yield put(DealActions.updateSucceed(id, normalizedItem as Deal))
  } catch (err) {
    const { type, detail, errors } = RequestError.parseError(err)
    yield put(DealActions.updateFailed(id, type, detail, errors))
  }
}

export const changeStatus = function* ({
  payload: [dealId, status],
}: ReturnType<typeof DealActions.changeStatusRequested>) {
  try {
    yield call(managers.changeStatus, dealId, status)
    const deal = yield select(state => DealSelectors.item(state, dealId))
    const { label: status_label = '' } = yield select(state => CollectionSelectors.getDealStatusBySlug(state, status))
    yield put(
      DealActions.changeStatusSuccess({
        ...deal,
        status,
        status_label,
        id: dealId,
      }),
    )
  } catch (e) {
    yield put(DealActions.changeStatusError(dealId))
  }
}

export const updatePurchaseBid = function* ({
  payload: [dealId, bidDto],
}: ReturnType<typeof DealActions.changePurchaseBidRequested>) {
  try {
    yield call(managers.updatePurchaseBid, dealId, bidDto)
    const deal = yield select(state => DealSelectors.item(state, dealId))
    const nextDeal = {
      ...deal,
      purchase_bid: {
        ...deal.purchase_bid,
        ...bidDto,
      },
    }
    yield put(DealActions.changePurchaseBidSuccess(nextDeal))
  } catch (e) {
    yield put(DealActions.changePurchaseBidError(dealId))
  }
}

export const updateSaleBid = function* ({
  payload: [dealId, bidDto],
}: ReturnType<typeof DealActions.changeSaleBidRequested>) {
  try {
    yield call(managers.updateSaleBid, dealId, bidDto)
    const deal = yield select(state => DealSelectors.item(state, dealId))
    const nextDeal = {
      ...deal,
      sale_bid: {
        ...deal.sale_bid,
        ...bidDto,
      },
    }
    yield put(DealActions.changeSaleBidSuccess(nextDeal))
  } catch (e) {
    yield put(DealActions.changeSaleBidError(dealId))
  }
}

export const removeItem = function* ({ payload }: ReturnType<typeof DealActions.removeRequested>) {
  try {
    yield call(managers.removeItem, payload)
    yield put(DealActions.removeSucceed(payload))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(DealActions.removeFailed(payload, type, detail))
  }
}

export const fetchShipmentList = function* ({ payload }: ReturnType<typeof DealActions.shipmentListRequested>) {
  const dealId = payload
  try {
    const list: DealShipment[] = yield call(managers.getShipmentList, dealId)
    yield put(DealActions.shipmentListRequestSucceed(dealId, list))
  } catch (err) {
    yield put(DealActions.shipmentListRequestFailed(dealId))
  }
}

export const addShipmentItem = function* ({
  payload: [dealId, dto],
}: ReturnType<typeof DealActions.shipmentItemAddRequested>) {
  try {
    yield call(managers.addShipmentItem, dealId, {
      ...dto,
      delivered_at: formatDateWithoutLocale(dto.delivered_at, SERVER_DATE_FORMAT),
    })
    yield put(DealActions.itemRequested(dealId))
    yield put(DealActions.shipmentListRequested(dealId))
    yield put(DealActions.shipmentItemAddSucceed(dealId))
  } catch (err) {
    yield put(DealActions.shipmentItemAddFailed(dealId))
  }
}

export const editShipmentItem = function* ({
  payload: [dealId, shipmentId, dto],
}: ReturnType<typeof DealActions.shipmentItemEditRequested>) {
  try {
    yield call(managers.editShipmentItem, dealId, shipmentId, {
      ...dto,
      delivered_at: formatDateWithoutLocale(dto.delivered_at, SERVER_DATE_FORMAT),
    })
    yield put(DealActions.itemRequested(dealId))
    yield put(DealActions.shipmentListRequested(dealId))
    yield put(DealActions.shipmentItemEditSucceed(dealId))
  } catch (err) {
    yield put(DealActions.shipmentItemEditFailed(dealId))
  }
}

export const deleteShipmentItem = function* ({
  payload: [dealId, shipmentId],
}: ReturnType<typeof DealActions.shipmentItemDeleteRequested>) {
  try {
    yield call(managers.deleteShipmentItem, dealId, shipmentId)
    yield put(DealActions.shipmentListRequested(dealId))
    yield put(DealActions.shipmentItemDeleteSucceed(dealId))
  } catch (err) {
    yield put(DealActions.shipmentItemDeleteFailed(dealId))
  }
}

export const uploadDocuments = function* ({
  payload: [id, files],
}: ReturnType<typeof DealActions.uploadDocumentsRequested>) {
  try {
    const res = yield all(managers.uploadFiles(id, files))
    const repFiles: RespFile[] = res.filter(r => r.status === 'fulfilled').map(r => r.results)
    yield put(DealActions.uploadDocumentsSucccess(id, repFiles))
  } catch (err) {
    yield put(DealActions.uploadDocumentsFailed(id))
  }
}

export const fetchDocuments = function* ({ payload: id }: ReturnType<typeof DealActions.documentsRequested>) {
  try {
    const files = yield call(managers.fetchDocuments, id)
    yield put(DealActions.documentsRequestSuccess(id, files))
  } catch (err) {
    yield put(DealActions.documentsRequestFailed(id))
  }
}

export const deleteDocument = function* ({
  payload: [dealId, fileId],
}: ReturnType<typeof DealActions.deleteDocumentRequested>) {
  try {
    yield call(managers.deleteDocument, dealId, fileId)
    yield put(DealActions.deleteDocumentSucccess(dealId, fileId))
  } catch (err) {
    yield put(DealActions.deleteDocumentFailed(fileId))
  }
}

export const closeDeal = function* ({
  payload: [dealId, closed_reason],
}: ReturnType<typeof DealActions.closeDealRequested>) {
  try {
    yield call(managers.closeDeal, dealId, closed_reason)
    yield put(DealActions.itemRequested(dealId))
    yield put(DealActions.closeDealSuccess(dealId))
  } catch (err) {
    yield put(DealActions.closeDealError(dealId))
  }
}

export const fetchCloseReasons = function* () {
  try {
    const closeReasons = yield call(managers.fetchCloseReasons)
    yield put(DealActions.closeReasonsSuccess(closeReasons))
  } catch (err) {
    yield put(DealActions.closeReasonsFailed())
  }
}

export function* fetchSpecificationsForPurchase({
  payload: [id, ownerId, productSlug],
}: ReturnType<typeof DealActions.specificationsForPurchaseRequested>) {
  try {
    const specificationForPurchaseList = yield call(managers.getSpecificationForPurchase, ownerId, productSlug)
    yield put(DealActions.specificationsForPurchaseSucceed(id, specificationForPurchaseList))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(DealActions.specificationsForPurchaseFailed(id, errType))
  }
}

export function* bindSpecificationToDeal({
  payload: [dealId, specificationId],
}: ReturnType<typeof DealActions.bindSpecificationRequested>) {
  try {
    yield call(managers.bindSpecificationForDeal, dealId, specificationId)
    yield put(DealActions.bindSpecificationSucceed(dealId))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(DealActions.bindSpecificationFailed(dealId, errType))
  }
}

export const getDocumentTypes = function* ({ payload: id }: ReturnType<typeof DealActions.getDocumentTypesRequested>) {
  try {
    const response = yield call(userManagers.getDocumentTypes, id)
    yield put(DealActions.getDocumentTypesSucceed(id, response))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(DealActions.getDocumentTypesFailed(id, errType))
  }
}

export const uploadDocument = function* ({
  payload: [id, document_type, file],
}: ReturnType<typeof DealActions.uploadDocumentRequested>) {
  try {
    yield call(userManagers.uploadFile, id, document_type, file)
    yield put(DealActions.uploadDocumentSucccess(id))
  } catch (err) {
    const { type } = RequestError.parseError(err)
    const detail = err instanceof RequestError ? err?.errors?.uploaded_file?.[0] : undefined
    yield put(DealActions.uploadDocumentFailed(id, type, detail))
  }
}

export const getDocumentFiles = function* ({ payload: id }: ReturnType<typeof DealActions.getDocumentFilesRequested>) {
  try {
    const response = yield call(userManagers.getDocumentFiles, id)
    yield put(DealActions.getDocumentFilesSucceed(id, response))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(DealActions.getDocumentFilesFailed(id, errType))
  }
}

export const deleteTypedDocument = function* ({
  payload: [id, document_id],
}: ReturnType<typeof DealActions.deleteDocumentRequested>) {
  try {
    yield call(userManagers.deleteDocumentFile, id, document_id)
    yield put(DealActions.deleteTypedDocumentSucccess(id))
  } catch (err) {
    yield put(DealActions.deleteDocumentFailed(''))
  }
}

const DealSaga = function* () {
  yield all([
    takeLatest(DealActions.itemRequested.type, fetchItem),
    takeLatest(DealActions.listRequested.type, fetchList),
    takeLatest(DealActions.filterUpdated.type, fetchList),
    takeLatest(DealActions.sortingUpdated.type, fetchList),
    takeLatest(DealActions.filterHasBeenReset.type, fetchList),
    takeLatest(DealActions.sortingHasBeenReset.type, fetchList),

    takeLatest(DealActions.addRequested.type, addItem),
    takeLatest(DealActions.updateRequested.type, updateItem),
    takeLatest(DealActions.removeRequested.type, removeItem),
    takeLatest(DealActions.shipmentListRequested.type, fetchShipmentList),
    takeLatest(DealActions.shipmentItemAddRequested.type, addShipmentItem),
    takeLatest(DealActions.shipmentItemEditRequested.type, editShipmentItem),
    takeLatest(DealActions.shipmentItemDeleteRequested.type, deleteShipmentItem),

    takeLatest(DealActions.specificationsForPurchaseRequested.type, fetchSpecificationsForPurchase),
    takeLatest(DealActions.bindSpecificationRequested.type, bindSpecificationToDeal),

    takeLatest(DealActions.changeStatusRequested.type, changeStatus),
    takeLatest(DealActions.changePurchaseBidRequested.type, updatePurchaseBid),
    takeLatest(DealActions.changeSaleBidRequested.type, updateSaleBid),

    takeLatest(DealActions.uploadDocumentsRequested.type, uploadDocuments),
    takeLatest(DealActions.documentsRequested.type, fetchDocuments),
    takeLatest(DealActions.deleteDocumentRequested.type, deleteDocument),

    takeLatest(DealActions.closeDealRequested.type, closeDeal),

    takeLeading(DealActions.closeReasonsRequested.type, fetchCloseReasons),

    takeLatest(DealActions.getDocumentTypesRequested.type, getDocumentTypes),
    takeLatest(DealActions.uploadDocumentRequested.type, uploadDocument),
    takeLatest(DealActions.getDocumentFilesRequested.type, getDocumentFiles),
    takeLatest(DealActions.deleteTypedDocumentRequested.type, deleteTypedDocument),
  ])
}

export default DealSaga
