import { Button, Input } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import { box } from 'views/styled/common'

export const HintText = styled.div`
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  white-space: pre-line;
  color: ${({ theme }) => theme.color.onPrimaryDark};
`

export const ErrorWrapper = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.color.accentDestructive};
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 0;
  text-align: center;
  position: absolute;
  bottom: -28px;
  width: calc(100% + 60px);
  left: -30px;
`

export const SubmitButton = styled(Button)`
  width: 100%;
`

export const SubmitButtonWrapper = styled.div`
  display: flex;
`

export const BackButtonWrapper = styled.div`
  padding-right: 16px;
`

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`

export const FieldWrapper = styled.div`
  margin-bottom: 24px;
  div[class^='InputsContainer'] {
    width: 100%;
  }
`

export const InputStyled = styled(Input)`
  margin-bottom: 24px;
`

export const InputWrapper = styled.div`
  width: 144px;
  margin-right: 16px;
`

export const CodeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`
export const RetryBtn = styled.button`
  border: none;
  background: none;
  box-shadow: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  height: 20px;
  padding: 0;
  color: ${props => props.theme.color.primary600};
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
  &:focus {
    outline: none;
  }
`

export const RetryDelay = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
  white-space: nowrap;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding-top: 80px;
  background: ${({ theme }) => theme.color.backgroundBase};
`

export const LogoStyled = styled.img`
  margin-bottom: 24px;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
  padding: 8px 40px 40px 40px;
  margin-bottom: 40px;
  ${box}
`
