import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import CarActions from 'modules/domain/car/duck'
import * as managers from 'modules/domain/car/managers'
import { CarStateUnity } from 'modules/domain/car/selectors'
import { Car } from 'modules/domain/car/types'
import { RespFile } from 'modules/domain/types'
import { RequestError } from 'modules/errors'
import { ListResponse } from 'types/api.d'

export const fetchList = function* ({ payload: [unity, id] }: ReturnType<typeof CarActions.listRequested>) {
  try {
    if (unity === CarStateUnity.User) {
      const list: Car[] = yield call(managers.getUserList, id)
      yield put(CarActions.listRequestSucceed(unity, id, list))
    } else if (unity === CarStateUnity.RelevantCarsByDeal) {
      const resp: ListResponse<Car> = yield call(managers.getRelevantList, id)
      yield put(CarActions.listRequestSucceed(unity, id, resp.results))
    } else if (unity === CarStateUnity.InvolvedCarsByDeal) {
      const resp: Car[] = yield call(managers.getInvolvedList, id)
      yield put(CarActions.listRequestSucceed(unity, id, resp))
    }
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(CarActions.listRequestFailed(unity, id, type, detail))
  }
}

export const fetchItem = function* ({ payload: id }: ReturnType<typeof CarActions.itemRequested>) {
  try {
    const item: Car = yield call(managers.getItem, id)
    yield put(CarActions.itemRequestSucceed(item))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(CarActions.itemRequestFailed(id, type, detail))
  }
}

export const addItem = function* ({ payload: dto }: ReturnType<typeof CarActions.addRequested>) {
  try {
    const { id } = yield call(managers.addItem, dto)
    const item: Car = yield call(managers.getItem, id)
    yield put(CarActions.addSucceed(item))
  } catch (err) {
    const { type, detail, errors } = RequestError.parseError(err)
    yield put(CarActions.addFailed(type, detail, errors))
  }
}
export const updateItem = function* ({ payload: [id, dto] }: ReturnType<typeof CarActions.updateRequested>) {
  try {
    yield call(managers.updateItem, id, dto)
    const item: Car = yield call(managers.getItem, id)
    yield put(CarActions.updateSucceed(item))
  } catch (err) {
    const { type, detail, errors } = RequestError.parseError(err)
    yield put(CarActions.updateFailed(id, type, detail, errors))
  }
}

export const removeItem = function* ({ payload }: ReturnType<typeof CarActions.removeRequested>) {
  try {
    yield call(managers.removeItem, payload)
    yield put(CarActions.removeSucceed(payload))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(CarActions.removeFailed(payload, type, detail))
  }
}

export const uploadDocuments = function* ({
  payload: [id, files],
}: ReturnType<typeof CarActions.uploadDocumentsRequested>) {
  try {
    const res = yield all(managers.uploadFiles(id, files))
    const repFiles: RespFile[] = res.filter(r => r.status === 'fulfilled').map(r => r.results)
    yield put(CarActions.uploadDocumentsSuccess(id, repFiles))
  } catch (err) {
    yield put(CarActions.uploadDocumentsFailed(id))
  }
}

export const fetchDocuments = function* ({ payload: id }: ReturnType<typeof CarActions.documentsRequested>) {
  try {
    const files = yield call(managers.fetchDocuments, id)
    yield put(CarActions.documentsRequestSuccess(id, files))
  } catch (err) {
    yield put(CarActions.documentsRequestFailed(id))
  }
}

export const deleteDocument = function* ({
  payload: [carId, fileId],
}: ReturnType<typeof CarActions.deleteDocumentRequested>) {
  try {
    yield call(managers.deleteDocument, carId, fileId)
    yield put(CarActions.deleteDocumentSucccess(carId, fileId))
  } catch (err) {
    yield put(CarActions.deleteDocumentFailed(fileId))
  }
}

export const fetchCarDeals = function* ({ payload: id }: ReturnType<typeof CarActions.carDealsRequested>) {
  try {
    const deals = yield call(managers.fetchCarDealsList, id)
    yield put(CarActions.carDealsRequestSuccess(id, deals))
  } catch (err) {
    yield put(CarActions.carDealsRequestFailed(id))
  }
}

export const changeStatus = function* ({ payload: [id, status] }: ReturnType<typeof CarActions.changeStatusRequested>) {
  try {
    yield call(managers.changeStatus, id, status)
    yield put(CarActions.changeStatusSuccess(id, status))
  } catch (err) {
    yield put(CarActions.changeStatusError(id))
  }
}

const CarSaga = function* () {
  yield all([
    takeLatest(CarActions.itemRequested.type, fetchItem),
    takeEvery(CarActions.listRequested.type, fetchList),

    takeLatest(CarActions.addRequested.type, addItem),
    takeLatest(CarActions.updateRequested.type, updateItem),
    takeLatest(CarActions.removeRequested.type, removeItem),

    takeLatest(CarActions.uploadDocumentsRequested.type, uploadDocuments),
    takeLatest(CarActions.documentsRequested.type, fetchDocuments),
    takeLatest(CarActions.deleteDocumentRequested.type, deleteDocument),

    takeLatest(CarActions.carDealsRequested.type, fetchCarDeals),

    takeLatest(CarActions.changeStatusRequested.type, changeStatus),
  ])
}

export default CarSaga
