import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { useHelmet } from '@agro-club/agroclub-shared'

import useFormatNumber from 'hooks/useFormatNumber'
import { useCars } from 'modules/domain/cars/hooks'
import { UnloadingType, VehicleType } from 'modules/domain/types'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import CarTripsBoard from 'views/pages/Cars/CarsDetail/CarTripsBoard'
import { useCarsGoBack } from 'views/pages/Cars/hooks'
import * as Header from 'views/ui/Header/Header'

export function CarsDetail() {
  const { t } = useTranslation('cars')
  const { id } = useParams<{ id: string }>()
  const [, car] = useCars(id)
  const goBack = useCarsGoBack()
  const formatNumber = useFormatNumber()

  const { licence_number, default_trailer, car_type, unloading_type, side_height, is_in_charter, is_dump_truck } =
    car || {}

  useHelmet({ title: licence_number })

  const licenses = useMemo((): string => {
    if (default_trailer?.licence_number) {
      return `${licence_number} / ${default_trailer?.licence_number}`
    }
    return licence_number || ''
  }, [licence_number, default_trailer?.licence_number])

  const description = useMemo(() => {
    const list: string[] = []
    if (car_type && car_type in VehicleType) {
      list.push(t(`vehicles:vehicleTypes.${car_type}`))
    }
    if (unloading_type && unloading_type in UnloadingType) {
      list.push(t(`vehicles:unloadingType.${unloading_type}`))
    }
    if (side_height) {
      list.push(`${formatNumber(side_height)} ${t('common:m')}`)
    }
    if (is_in_charter) {
      list.push(t('is_in_charter'))
    }
    if (is_dump_truck) {
      list.push(t('is_dump_truck'))
    }
    return list.join(', ')
  }, [car_type, unloading_type, side_height, is_in_charter, is_dump_truck, t, formatNumber])

  return (
    <>
      <Layout.Header hasBottomBorder>
        <Header.Root onClickBack={goBack}>
          <Header.Title compact title={licenses} />
          <Header.SubTitle>{description}</Header.SubTitle>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <CarTripsBoard carId={id} />
      </Layout.Content>
    </>
  )
}
