import { omit } from 'ramda'

import { AppGlobalState } from 'modules/types'

export default function filterDistricts(state: AppGlobalState, filter: { district?: string[]; region?: string[] }) {
  const allRegions = state.collection.regions
  const districtIds = filter.district || []
  const districtParentIds = districtIds?.map(id => allRegions.find(r => r.id === id)?.parent_id)
  const filteredRegions = filter.region?.filter(id => !districtParentIds?.includes(id)) || []
  const region = [...filteredRegions, ...districtIds]

  return {
    ...omit(['district', 'region'], filter),
    region: region.length ? region.join(',') : undefined,
  }
}
