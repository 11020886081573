import { Progress } from '@agro-club/agroclub-shared'
import { createSelector } from 'reselect'

import { EntityMetadata } from 'modules/domain/types'

import { AppGlobalState } from '../../types'
import { Task } from './types'

const listCombiner = (dict, ids, progress) => {
  if (progress === Progress.WORK) {
    return []
  }
  const result: Task[] = []
  for (const id of ids) {
    result.push(dict[id])
  }
  return result
}

const dict = (state: AppGlobalState) => state.task.items
const ids = (state: AppGlobalState) => state.task.ids

const item = (state: AppGlobalState, id: string | undefined): Task | undefined =>
  id ? state.task.items[id] : undefined

const meta = (state: AppGlobalState, id: string): EntityMetadata<Task> =>
  state.task.meta[id] || {
    fetchError: null,
    fetchProgress: Progress.IDLE,
    id,
    removeError: null,
    removeProgress: Progress.IDLE,
    updateError: null,
    updateProgress: Progress.IDLE,
  }
const itemFetchProgress = (state: AppGlobalState) => state.task.itemFetchProgress
const listFetchProgress = (state: AppGlobalState) => state.task.listFetchProgress
const listFetchError = (state: AppGlobalState) => state.task.listFetchError
const listFetchErrorDetail = (state: AppGlobalState) => state.task.listFetchErrorDetail
const list = createSelector(dict, ids, listFetchProgress, listCombiner)

const filter = (state: AppGlobalState) => state.task.filter
const sorting = (state: AppGlobalState) => state.task.sorting
const page = (state: AppGlobalState) => state.task.page
const total = (state: AppGlobalState) => state.task.total
const updateProgress = (state: AppGlobalState) => state.task.updateProgress
const removeProgress = (state: AppGlobalState) => state.task.removeProgress
const addProgress = (state: AppGlobalState) => state.task.addProgress
const hasNext = (state: AppGlobalState) => state.task.total > state.task.ids.length
const pageSize = (state: AppGlobalState) => state.task.pageSize
const pages = (state: AppGlobalState) => Math.ceil(state.task.total / state.task.pageSize)

const myTasks = (state: AppGlobalState) => state.task.myTasks.map(id => state.task.items[id])
const fetchMyTasksProgress = (state: AppGlobalState) => state.task.myTasksFetchProgress
const completeProgress = (state: AppGlobalState) => state.task.completeProgress
const completeErrorDetail = (state: AppGlobalState) => state.task.completeErrorDetail
const taskId = (state: AppGlobalState) => state.task.taskId

const callBackIds = (state: AppGlobalState) => state.task.callBackIds
const callBackFetchProgress = (state: AppGlobalState) => state.task.callBackFetchProgress
const callBackList = createSelector(dict, callBackIds, callBackFetchProgress, listCombiner)
const callBackTotal = (state: AppGlobalState) => state.task.callBackTotal
const callBackPages = (state: AppGlobalState) => Math.ceil(state.task.callBackTotal / state.task.callBackPageSize)
const callBackPage = (state: AppGlobalState) => state.task.callBackPage
const callBackPageSize = (state: AppGlobalState) => state.task.callBackPageSize

const userTasksFetchProgress = (state: AppGlobalState) => state.task.userTasksFetchProgress
const userTasksIds = (state: AppGlobalState, userId?: string) => (userId && state.task.userTasks?.[userId]) || []
const userTasksList = (state: AppGlobalState, userId?: string) => {
  const progress = userTasksFetchProgress(state)
  if (progress === Progress.WORK) {
    return []
  }
  const ids = userTasksIds(state, userId)
  const dictionary = dict(state)

  return ids.map(id => dictionary[id])
}

const TaskSelectors = {
  filter,
  sorting,
  page,
  total,
  updateProgress,
  removeProgress,
  addProgress,
  item,
  meta,
  itemFetchProgress,
  listFetchProgress,
  listFetchError,
  listFetchErrorDetail,
  list,
  dict,
  ids,
  hasNext,
  pageSize,
  pages,

  myTasks,
  fetchMyTasksProgress,
  completeProgress,
  completeErrorDetail,
  taskId,

  userTasksList,
  userTasksFetchProgress,

  callBackList,
  callBackFetchProgress,
  callBackTotal,
  callBackPages,
  callBackPage,
  callBackPageSize,
}

// const pageSize = useSelector(TaskSelectors.pageSize)

export default TaskSelectors
