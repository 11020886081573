import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { generatePath } from 'react-router'
import { Link } from 'react-router-dom'

import { Progress } from '@agro-club/agroclub-shared'

import useLocationFrom from 'hooks/useLocationFrom'
import { useDealStatuses } from 'modules/domain/collection/hooks'
import { useCloseReasons } from 'modules/domain/deal/hooks'
import { useUserBidList } from 'modules/domain/userBid/hooks'
import UserBidSelectors from 'modules/domain/userBid/selectors'
import * as ColumnBoard from 'views/components/ColumnBoard/styled'
import { Spinner } from 'views/components/CommonTableComponents/CommonTableComponents'
import UserRoutes from 'views/pages/User/routes'
import { ArchivedBids } from 'views/pages/User/UserDetail/UserColumnBoard/ArchivedBids'
import { BidItem } from 'views/pages/User/UserDetail/UserColumnBoard/BidItem'
import { ClosedDeals } from 'views/pages/User/UserDetail/UserColumnBoard/ClosedDeals'
import { Deal } from 'views/pages/User/UserDetail/UserColumnBoard/Deal'
import { PotentialBids } from 'views/pages/User/UserDetail/UserColumnBoard/PotentialBids'
import * as S from 'views/pages/User/UserDetail/UserColumnBoard/styled'
import { SmallAddButton } from 'views/ui/SmallAddButton/SmallAddButton'

type Props = {
  userId: string
}

export const UserColumnBoard: React.FC<Props> = props => {
  useCloseReasons()
  const { userId } = props
  const { t } = useTranslation('bid')
  const activeBidId = useSelector(state => UserBidSelectors.activeBidId(state, userId))
  const dealGroupListProgress = useSelector(state => UserBidSelectors.dealGroupListProgress(state, userId))
  const dealGroupList = useSelector(state => UserBidSelectors.dealGroupList(state, userId))
  const [bidsProgress, bids] = useUserBidList(userId)

  const publishedBids = useSelector(state => UserBidSelectors.publishedBids(state, userId))

  const isLoading = bidsProgress === Progress.WORK || dealGroupListProgress === Progress.WORK
  const activeBid = bids.find(bid => bid.id === activeBidId)
  const [, statuses] = useDealStatuses()

  const from = useLocationFrom()

  return (
    <S.UserColumnContainer>
      <ColumnBoard.Column>
        <S.BidColumnHeader size="small">
          {t('firstUserColumn')}
          <Link to={{ pathname: generatePath(UserRoutes.BidAdd, { id: userId }), state: { from } }}>
            <SmallAddButton />
          </Link>
        </S.BidColumnHeader>
        {publishedBids.map(bid => (
          <BidItem key={bid.id} {...{ bid, userId, activeBidId }} />
        ))}
        <ArchivedBids activeBidId={activeBidId} />
        <PotentialBids />
      </ColumnBoard.Column>
      {isLoading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <>
          {dealGroupList.map(dealGroup => (
            <ColumnBoard.Column key={dealGroup.slug}>
              <ColumnBoard.Header size="small">
                {dealGroup.title}
                <ColumnBoard.Counter>{dealGroup.deals.length}</ColumnBoard.Counter>
              </ColumnBoard.Header>
              {dealGroup.deals
                .filter(deal => !deal.closed_at)
                .map(deal => (
                  <Deal key={deal.id} {...{ deal, activeBid, statuses }} />
                ))}
              <ClosedDeals deals={dealGroup.deals} {...{ activeBid, statuses }} />
            </ColumnBoard.Column>
          ))}
        </>
      )}
    </S.UserColumnContainer>
  )
}
