import React from 'react'
import { useTranslation } from 'react-i18next'

import { Company } from 'modules/domain/company/types'
import { useYesNo } from 'views/pages/Company/CompanyDetail/hooks'
import { CommonContent, Container, DetailHeader, VerticalKeyValue } from 'views/pages/Company/CompanyDetail/styled'

type Props = {
  company: Company
}

export const CompanyData: React.FC<Props> = ({ company }) => {
  const { t } = useTranslation('company')
  const yesNo = useYesNo()

  return (
    <Container>
      <DetailHeader>{t('commonInfo')}</DetailHeader>
      <CommonContent>
        <VerticalKeyValue label={t('form.fields.inn')}>{company.inn}</VerticalKeyValue>
        <VerticalKeyValue label={t('form.fields.state_reg_number')}>{company.state_reg_number || '-'}</VerticalKeyValue>
        <VerticalKeyValue label={t('form.fields.name')}>{company.name}</VerticalKeyValue>
        <VerticalKeyValue label={t('form.fields.payment_delay_days')}>{company.payment_delay_days}</VerticalKeyValue>
        <VerticalKeyValue label={t('form.fields.agreementType')}>{`${t('form.fields.cooperation_type_agent')}: ${yesNo(
          company.cooperation_type_agent,
        )} | ${t('form.fields.cooperation_type_trader')}: ${yesNo(company.cooperation_type_trader)}`}</VerticalKeyValue>
      </CommonContent>
    </Container>
  )
}
