import React from 'react'

import useFormatDate from 'hooks/useFormatDate'
import {
  CommentContainer,
  CommentDate,
  CommentHeader,
  CommentText,
  CommentUserIcon,
  CommentUserName,
} from 'views/components/Comments/styled'

export const CommentsItem: React.FC<{ text: string; date: string; userName: string }> = ({ text, date, userName }) => {
  const formatDate = useFormatDate()
  return (
    <CommentContainer>
      <CommentHeader>
        <CommentUserIcon />
        <CommentUserName>{userName}</CommentUserName>
        <CommentDate>{formatDate(date, true)}</CommentDate>
      </CommentHeader>
      <CommentText>{text}</CommentText>
    </CommentContainer>
  )
}
