import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import {
  IconPhoneIn,
  IconPhoneMissed,
  IconPhoneOut,
  Pagination,
  SectionTable,
  SectionTableBody,
  SectionTableBodyCell,
  useAction,
} from '@agro-club/agroclub-shared'

import useFormatDate from 'hooks/useFormatDate'
import { UserCallsActions } from 'modules/domain/userCalls/duck'
import { useUserCalls } from 'modules/domain/userCalls/hooks'
import UserCallsSelectors from 'modules/domain/userCalls/selectors'
import { UserCall } from 'modules/domain/userCalls/types'
import Player from 'views/components/Player/Player'
import PlayerProvider from 'views/components/Player/PlayerProvider'
import {
  Col,
  Ellipsis,
  Empty,
  PaginationWrapper,
  PlayerWrapper,
  SmallText,
  TableBodyRow,
  UserContactTableWrapper,
  UserContactWrapper,
} from 'views/components/UserRightPanel/UserCalls/styled'
import Tooltip from 'views/ui/Tooltip/Tooltip'

export const UserCallsSummary: React.FC = () => {
  const { t } = useTranslation(['user', 'common'])
  const { id } = useParams<{ id: string }>()
  const count = useSelector(state => UserCallsSelectors.count(state, id))
  return <div>{count || t('common:no')}</div>
}

const phoneIcon = {
  in: <IconPhoneIn />,
  out: <IconPhoneOut />,
  missed: <IconPhoneMissed />,
}

type Props = {
  item: UserCall
}

export const UserCallsRow: React.FC<Props> = ({ item }) => {
  const formatDate = useFormatDate()
  return (
    <TableBodyRow key={item.id}>
      <SectionTableBodyCell>
        <Col>
          {phoneIcon[item.call_type]}
          <Tooltip
            placement="top"
            overlay={
              <SmallText>
                {formatDate(item.call_start, true)}, {item.coordinator_name}
              </SmallText>
            }
          >
            <Ellipsis>
              {formatDate(item.call_start, true)}, {item.coordinator_name}
            </Ellipsis>
          </Tooltip>
        </Col>
      </SectionTableBodyCell>
      <SectionTableBodyCell>
        <PlayerWrapper>
          {item.record_link ? <Player file={item.record_link} duration={item.conversation_duration} /> : ''}
        </PlayerWrapper>
      </SectionTableBodyCell>
    </TableBodyRow>
  )
}

export const UserCalls: React.FC = () => {
  const { t } = useTranslation(['calls'])
  const { id } = useParams<{ id: string }>()
  const [, items] = useUserCalls(id)
  const listRequestedNext = useAction(UserCallsActions.listRequestedNext)
  const page = useSelector(state => UserCallsSelectors.page(state, id))
  const total = useSelector(state => UserCallsSelectors.total(state, id))

  const setPage = useCallback(
    (num: number) => {
      listRequestedNext(id, num)
    },
    [id, listRequestedNext],
  )

  return (
    <UserContactWrapper>
      <UserContactTableWrapper>
        <SectionTable>
          <PlayerProvider>
            <SectionTableBody>
              {items.map(item => (
                <UserCallsRow key={item.id} item={item} />
              ))}
              {items?.length === 0 && (
                <Empty>
                  <td>{t('empty')}</td>
                </Empty>
              )}
            </SectionTableBody>
          </PlayerProvider>
        </SectionTable>
        {total > 1 && (
          <PaginationWrapper>
            <Pagination total={total} current={page} onClick={setPage} />
          </PaginationWrapper>
        )}
      </UserContactTableWrapper>
    </UserContactWrapper>
  )
}
