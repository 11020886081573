import React from 'react'

import { TooltipProps } from 'rc-tooltip/lib/Tooltip'

import { Overlay, Props as OverlayProps } from 'views/ui/ConfirmationTooltip/Overlay'
import { BackgroundOverlay } from 'views/ui/ConfirmationTooltip/styled'
import Tooltip from 'views/ui/Tooltip/Tooltip'

type Props = {
  overlayProps: OverlayProps
  placement?: TooltipProps['placement']
  children: TooltipProps['children']
  visible: TooltipProps['visible']
  isModal?: boolean
  hideArrow?: boolean
}

export const ConfirmationTooltip: React.FC<Props> = props => {
  const { overlayProps, placement, visible, isModal, hideArrow } = props
  return (
    <>
      <Tooltip
        hideArrow={hideArrow}
        isModal
        placement={placement}
        visible={visible}
        overlay={<Overlay {...overlayProps} />}
      >
        {props.children}
      </Tooltip>
      {isModal && visible && <BackgroundOverlay />}
    </>
  )
}
