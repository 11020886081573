import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { useAction } from '@agro-club/agroclub-shared'

import DealActions from 'modules/domain/deal/duck'
import DealSelectors from 'modules/domain/deal/selectors'
import { DocumentsView } from 'views/components/DocumentsView/DocumentsView'

export const FilesSummary: React.FC = () => {
  const { t } = useTranslation(['user', 'common'])
  const { id } = useParams<{ id: string }>()
  const list = useSelector(state => DealSelectors.files(state, id))
  return <div>{list.length || t('common:no')}</div>
}

export const Files: React.FC = () => {
  const { id } = useParams<{ id: string }>()

  const upload = useAction(DealActions.uploadDocumentsRequested)
  const fetchFiles = useAction(DealActions.documentsRequested, id)

  useEffect(fetchFiles, [fetchFiles])

  const files = useSelector(state => DealSelectors.files(state, id))
  const uploadProgress = useSelector(state => DealSelectors.filesUploadProgress(state, id))
  const fetchProgress = useSelector(state => DealSelectors.filesFetchProgress(state, id))
  const deleteDocument = useAction(DealActions.deleteDocumentRequested)
  const deleteDocumentProgress = useSelector(DealSelectors.deleteDocumentProgress)

  return (
    <DocumentsView
      onDelete={fileId => {
        deleteDocument(id, fileId)
      }}
      deleteDocumentProgress={deleteDocumentProgress}
      onDrop={files => upload(id, files)}
      files={files}
      uploadProgress={uploadProgress}
      fetchProgress={fetchProgress}
    />
  )
}
