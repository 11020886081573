import { Progress } from '@agro-club/agroclub-shared'

import { Documents, EntityMetadata, FetchError } from 'modules/domain/types'
import { Dict } from 'types/generics.d'

import { Car } from '../car/types'

export type CarsListRequestFilter = {
  query?: string
  has_trailer?: string
  car_type?: string
  unloading_type?: string
  side_height_range_max?: string
  is_dump_truck?: string
  is_in_charter?: string
  status?: string
}

export enum TrailerType {
  yes = 'true',
  no = 'false',
}

export enum IsTruckType {
  yes = 'true',
  no = 'false',
}

export enum IsCharterType {
  yes = 'true',
  no = 'false',
}

export type Cars = {
  brand_model?: string
  trips_count: number
} & Car

export type CarsListRequestSorting = {
  sort_field?: keyof Cars
  sort_reversed?: boolean
}

export type CarsState = {
  items: Dict<Cars>
  meta: Dict<EntityMetadata<Cars>>
  ids: string[]
  listFetchProgress: Progress
  listFetchError: FetchError | null
  itemFetchProgress: Progress
  itemFetchError: FetchError | null

  filter: CarsListRequestFilter
  sorting: CarsListRequestSorting
  page: number
  total: number
  pageSize: number

  carsBlockProgress: Progress

  documents: Dict<Documents>
  deleteDocumentProgress: Dict<Progress> // dict by file pk
}
