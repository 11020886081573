import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SimpleSelect, SimpleSelectProps } from '@agro-club/agroclub-shared'

import { useEmployees } from 'hooks/useEmployees'
import { useUserNameFormatter } from 'hooks/useUserName'
import { ProfileType, User } from 'modules/domain/user/types'

const profileTypeTransl = {
  [ProfileType.customer]: 'user:profile_type.customer',
  [ProfileType.seller]: 'user:profile_type.seller',
  [ProfileType.coordinator]: 'user:profile_type.coordinator',
}

type Props = {
  onUserChange: (user: User) => void
  onUsersLoaded: (users: User[]) => void
  invalid?: SimpleSelectProps['invalid']
  companyId?: string
  defaultValue?: string | number
}

export const EmployeeSelect: React.FC<Props> = props => {
  const { onUserChange, invalid, companyId, defaultValue, onUsersLoaded } = props
  const [value, setValue] = useState<string | number | undefined>(defaultValue)
  const { t } = useTranslation(['bid', 'common'])

  const userNameFormatter = useUserNameFormatter()

  const [progress, users] = useEmployees(companyId)
  const options = useMemo(
    () =>
      users
        .sort((a, b) => {
          const aName = userNameFormatter(a)
          const bName = userNameFormatter(b)
          return aName.localeCompare(bName)
        })
        .map(u => {
          const profileType = u.profile?.profile_type ? ` (${t(profileTypeTransl[u.profile?.profile_type])})` : ''
          return { id: u.id, title: `${userNameFormatter(u)} ${profileType}` }
        }),
    [users, t, userNameFormatter],
  )

  useEffect(() => {
    onUsersLoaded(users)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users])

  const onChange = useCallback(
    (id: string | number | undefined) => {
      setValue(id)
      const user = users.find((u: User) => u.id === id)
      user && onUserChange(user)
    },
    [setValue, onUserChange, users],
  )

  return (
    <SimpleSelect
      isDisabled={!companyId}
      invalid={invalid}
      errorText={t('form.noEmployeeError')}
      progress={progress}
      onChange={onChange}
      label={t('common:user')}
      placeholder={t('form.selectEmployeePlaceholder')}
      loadingMessage={t('common:loading')}
      noOptionsMessage={() => t('form.noEmployeeOptionsMessage')}
      options={options}
      value={value}
      isSearchable
    />
  )
}
