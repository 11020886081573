import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Button, Progress, useHelmet } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import useFormatDate from 'hooks/useFormatDate'
import { useDeal } from 'modules/domain/deal/hooks'
import DealSelectors from 'modules/domain/deal/selectors'
import { TaskModal } from 'views/components/TaskModal/TaskModal'
import Item404 from 'views/layouts/Item404/Item404'
import ItemGenericError from 'views/layouts/ItemGenericError/ItemGenericError'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import { CloseDealModal } from 'views/pages/Deal/DealEdit/CloseDealModal'
import { DealBlock } from 'views/pages/Deal/DealEdit/DealBlock'
import { useDealGoBack } from 'views/pages/Deal/DealEdit/hooks'
import { StatusSelect } from 'views/pages/Deal/DealEdit/StatusSelect'
import { Beside } from 'views/pages/Deal/DealEdit/styled'
import { FirstCapitalize } from 'views/styled/common'
import { Badge } from 'views/ui/Badge/Badge'
import * as Header from 'views/ui/Header/Header'

import { DealEditBody } from './DealEditBody'

const ChipsWrapper = styled.div`
  display: grid;
  gap: 8px;
  grid-auto-flow: column;
  grid-auto-rows: max-content;
`

export type Mode = 'view' | 'edit'

const DealEdit: React.FC = () => {
  const { t } = useTranslation('deal')
  const { id } = useParams<{ id: string }>()
  const goBack = useDealGoBack(id)
  const formatDate = useFormatDate()

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isCloseTooltipVisible, setCloseTooltipVisible] = useState<boolean>(false)

  const hideTooltip = useCallback(() => {
    setCloseTooltipVisible(false)
  }, [setCloseTooltipVisible])

  const [fetchProgress, item] = useDeal(id)
  const meta = useSelector(state => DealSelectors.meta(state, id))

  useHelmet({ title: `${t('common:deal')} - ${item?.product?.title}` })

  const loading = () => <ItemLoadingLayout id={id} onBack={goBack} />
  const error404 = () => <Item404 id={id} onBack={goBack} title={t('errors.notFoundTitle')} />
  const errorUnknown = () => <ItemGenericError id={id} onBack={goBack} title={t('errors.unknownErrorTitle')} />

  if (fetchProgress === Progress.ERROR) {
    if (meta.fetchError === 'not_found') {
      return error404()
    }
    return errorUnknown()
  }

  if (!item) {
    return loading()
  }

  const rightBlock = !item.closed_at ? (
    <Header.ButtonWrapper>
      {item && item.id && <StatusSelect deal={item} />}
      <Button filled intent="primary" size="small" onClick={() => setIsOpen(true)}>
        {t('common:setTask')}
      </Button>

      <CloseDealModal visible={isCloseTooltipVisible} close={hideTooltip} placement="bottomRight" dealId={id}>
        <Button
          intent="danger"
          size="small"
          onClick={() => {
            setCloseTooltipVisible(true)
          }}
        >
          {t('closeDeal')}
        </Button>
      </CloseDealModal>
    </Header.ButtonWrapper>
  ) : undefined

  return (
    <>
      <Layout.Header hasBottomBorder>
        <Header.Root onClickBack={goBack} rightBlock={rightBlock}>
          <Header.Title title={item?.product?.title || ''} compact>
            <ChipsWrapper>
              {!!item.closed_at && <Badge color="red">{t('isClosed')}</Badge>}
              <Badge color="gray">
                <FirstCapitalize>{item?.status_label || ''}</FirstCapitalize>
              </Badge>
            </ChipsWrapper>
          </Header.Title>
          <Header.SubTitle>
            ID {item.id} / {formatDate(item.created, true)}
          </Header.SubTitle>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <Beside>
          <DealEditBody item={item} progress={fetchProgress} />
          <DealBlock dealId={id} />
        </Beside>
      </Layout.Content>
      {fetchProgress === Progress.SUCCESS && <TaskModal deal={item} isOpen={isOpen} close={() => setIsOpen(false)} />}
    </>
  )
}

export default DealEdit
