import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Progress, Typography, useHistoryPush } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import { useMyTasks } from 'modules/domain/task/hooks'
import { TaskItem } from 'views/components/TaskItem/TaskItem'
import { TaskModalDetail } from 'views/components/TaskModalDetail/TaskModalDetail'
import TaskRoutes from 'views/pages/Task/routes'
import { TextButton } from 'views/ui/TextButton/TextButton'

const TaskHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  border-bottom: ${({ theme }) => `1px solid ${theme.color.outlineMid}`};
  border-top: ${({ theme }) => `1px solid ${theme.color.outlineMid}`};
`

const Title = styled.div`
  ${Typography.headlineSemibold}
`
const TaskContent = styled.div`
  overflow-y: auto;
  flex-grow: 1;
`

const TaskList = styled.div`
  padding: 2px 16px 12px;
`

const NoTasks = styled.div`
  ${Typography.body2Default};
  color: ${({ theme }) => theme.color.onSurfaceMidEmphasys};
  text-align: center;
  padding-top: 16px;
`

export const Tasks: React.FC = () => {
  const { t } = useTranslation('task')
  const [progress, items] = useMyTasks()
  const push = useHistoryPush()
  const [openTask, setOpenTask] = useState<string | undefined>()
  return (
    <>
      <TaskHeader>
        <Title>{t('myTasks')}</Title>
        <TextButton onClick={() => push({ route: TaskRoutes.List })}>{t('seeAll')}</TextButton>
      </TaskHeader>
      <TaskContent>
        <TaskList>
          {progress === Progress.SUCCESS && !items.length ? (
            <NoTasks>{t('noTasks')}</NoTasks>
          ) : (
            items.map(item => <TaskItem setOpenTask={setOpenTask} key={item.id} task={item} />)
          )}
        </TaskList>
      </TaskContent>
      <TaskModalDetail taskId={openTask} isOpen={!!openTask} close={() => setOpenTask(undefined)} />
    </>
  )
}
