import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IconArrowDown, MenuItemLink, useDebounce } from '@agro-club/agroclub-shared'

import useHover from 'hooks/useHover'
import useOnClickOutside from 'hooks/useOnClickOutside'
import OPTIONS from 'views/components/MainMenu/options'
import { CollabsedSubMenu, GroupContainer, GroupHeader, MenuContainer, SubMenu } from 'views/components/MainMenu/styled'
import { OptionItem } from 'views/components/MainMenu/types'
import useNavigation from 'views/components/MainMenu/useNavigation'

type ItemProps = { option: OptionItem; collapsed?: boolean }

const NoIcon: React.FC = () => (
  <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="2" cy="2" r="2" fill="#939EAA" />
  </svg>
)

const Item: React.FC<ItemProps> = ({ option, collapsed }) => {
  const { isCurrent } = useNavigation()
  const { t } = useTranslation('menu')
  const containerRef = useRef<HTMLDivElement>(null)
  const [, containerHovered] = useHover(containerRef)
  const testId = `menu-${option.value}`
  const active = option.children ? option.children.some(opt => isCurrent(opt?.path)) : isCurrent(option.path)
  const [isOpen, setOpen] = useState(active && !collapsed)
  const debouncedOpen = useDebounce(isOpen, 300)
  const open = collapsed ? isOpen || debouncedOpen : isOpen

  useOnClickOutside(containerRef, () => {
    if (collapsed) {
      setOpen(false)
    }
  })

  useEffect(() => {
    if (!collapsed) {
      return
    }
    setOpen(containerHovered)
  }, [containerHovered, collapsed])

  if (option?.children?.length) {
    const Wrapper = collapsed ? CollabsedSubMenu : SubMenu

    return (
      <GroupContainer ref={containerRef}>
        <GroupHeader active={active} open={open} onClick={() => setOpen(!open)}>
          <MenuItemLink data-test-id={testId} icon={option.icon || NoIcon} active={active}>
            {!collapsed ? t(option.value) : null}
          </MenuItemLink>
          {!collapsed ? <IconArrowDown /> : null}
        </GroupHeader>
        <Wrapper open={open} count={option.children.length}>
          {option.children.map(opt => (
            <Item option={opt} key={opt.value} />
          ))}
        </Wrapper>
      </GroupContainer>
    )
  }

  return (
    <MenuItemLink data-test-id={testId} icon={option.icon || NoIcon} active={active} path={option.path}>
      {!collapsed ? t(option.value) : null}
    </MenuItemLink>
  )
}

const MainMenu: React.FC<{ collapsed: boolean }> = props => {
  const { collapsed } = props
  return (
    <MenuContainer>
      {OPTIONS.map(opt => (
        <Item collapsed={collapsed} option={opt} key={opt.value} />
      ))}
    </MenuContainer>
  )
}

export default MainMenu
