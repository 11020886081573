import React, { useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { useAction } from '@agro-club/agroclub-shared'

import CarrierActions from 'modules/domain/carrier/duck'
import PageWrapper from 'views/components/PageWrapper/PageWrapper'
import { CarrierAdd } from 'views/pages/Carrier/CarrierDetail/CarrierAdd'
import { CarrierDetail } from 'views/pages/Carrier/CarrierDetail/CarrierDetail'
import { CarrierEdit } from 'views/pages/Carrier/CarrierDetail/CarrierEdit'
import { CarAdd } from 'views/pages/Carrier/CarrierDetail/UserCars/CarAdd'
import { CarDetail } from 'views/pages/Carrier/CarrierDetail/UserCars/CarDetail'
import { CarEdit } from 'views/pages/Carrier/CarrierDetail/UserCars/CarEdit'
import CarrierList from 'views/pages/Carrier/CarrierList'
import CarrierRoutes from 'views/pages/Carrier/routes'

const CarrierPage: React.FC = () => {
  const resetFilter = useAction(CarrierActions.filterResetWithoutRequest)

  useEffect(
    () => () => {
      resetFilter()
    },
    [resetFilter],
  )

  return (
    <PageWrapper>
      <Switch>
        <Route path={CarrierRoutes.List} exact>
          <CarrierList />
        </Route>
        <Route path={CarrierRoutes.Detail} exact>
          <CarrierDetail />
        </Route>
        <Route path={CarrierRoutes.Add} exact>
          <CarrierAdd />
        </Route>
        <Route path={CarrierRoutes.Edit} exact>
          <CarrierEdit />
        </Route>
        <Route path={CarrierRoutes.CarDetail} exact>
          <CarDetail />
        </Route>
        <Route path={CarrierRoutes.CarAdd} exact>
          <CarAdd />
        </Route>
        <Route path={CarrierRoutes.CarEdit} exact>
          <CarEdit />
        </Route>
        <Redirect to={CarrierRoutes.List} />
      </Switch>
    </PageWrapper>
  )
}

export default CarrierPage
