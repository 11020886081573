import React, { VFC, useCallback } from 'react'
import { useSelector } from 'react-redux'

import { useAction } from '@agro-club/agroclub-shared'
import { isNil } from 'ramda'

import LogisticsDealActions from 'modules/domain/logisticsDeal/duck'
import LogisticsDealSelectors from 'modules/domain/logisticsDeal/selectors'
import { Filters } from 'views/components/CommonTableComponents/CommonTableComponents'
import { ResetFilterButton } from 'views/components/TableFilters/ResetFilterButton'
import { TeamFilter } from 'views/components/TeamFilter/TeamFilter'
import { StatusFilter } from 'views/pages/Logistics/LogisticsFilter/StatusFilter'

export const LogisticsFilter: VFC = () => {
  const filterState = useSelector(LogisticsDealSelectors.filter)
  const filterUpdated = useAction(LogisticsDealActions.filterUpdated)

  const selectedTeams = filterState.team

  const onChangeTeam = (id: string) => {
    const set = new Set(selectedTeams)
    set.has(id) ? set.delete(id) : set.add(id)
    filterUpdated({ ...filterState, team: [...set] })
  }

  const onClearTeam = () => {
    filterUpdated({ ...filterState, team: undefined })
  }

  const isFilterApplied = Object.values(filterState).some(value => !isNil(value))
  const handleClearFilters = useCallback(() => {
    filterUpdated({})
  }, [filterUpdated])

  return (
    <Filters>
      <TeamFilter selectedIds={selectedTeams} onChange={onChangeTeam} onClear={onClearTeam} />
      <StatusFilter />
      <ResetFilterButton {...{ handleClearFilters, isFilterApplied }} />
    </Filters>
  )
}
