import { Button, Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const NoTasks = styled.div`
  ${Typography.body2Default};
  color: ${({ theme }) => theme.color.onSurfaceMidEmphasys};
`

export const TasksList = styled.div``

export const TaskWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const Item = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 8px 12px;
  border-left: 1px solid ${props => props.theme.color.secondary50};
  border-right: 1px solid ${props => props.theme.color.secondary50};
  border-top: 1px solid ${props => props.theme.color.secondary50};
  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom: 1px solid ${props => props.theme.color.secondary50};
  }
  & > button {
    opacity: 0;
    background: transparent;
  }
  &:hover {
    & > button {
      opacity: 1;
    }
    background: ${props => props.theme.color.primary8a};
  }
`

export const Deadline = styled.div`
  ${Typography.body2Default};
`

export const Text = styled.div`
  ${Typography.body2Default}
`

export const TaskDetail = styled.div`
  flex: 1;
`

export const CompleteButton = styled(Button)`
  border: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  & svg {
    fill: ${props => props.theme.color.secondary300};
  }
  &:hover {
    & svg {
      fill: ${props => props.theme.color.primary600};
    }
  }
`

export const AdditionalInfo = styled.div`
  ${Typography.body2Default};
  color: ${({ theme }) => theme.color.textSecondary};
`
