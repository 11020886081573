import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Progress, useAction, useDidMount } from '@agro-club/agroclub-shared'

import CollectionActions from 'modules/domain/collection/duck'
import CollectionSelectors from 'modules/domain/collection/selectors'
import { CompanyDadata, DealStatus, LogisticStatus, Product, Region, Team } from 'modules/domain/collection/types'
import { ResourceHook } from 'modules/types'

export const useProducts: ResourceHook<Product[], void[]> = () => {
  const progress = useSelector(CollectionSelectors.getProductsFetchProgress)
  const products = useSelector(CollectionSelectors.getProducts)
  const fetchAction = useAction(CollectionActions.productsRequested)

  useDidMount(fetchAction)

  return [progress, products]
}

export const useCompaniesDadata = (query: string): [Progress, CompanyDadata[]] => {
  const progress = useSelector(CollectionSelectors.getCompaniesFetchProgress)
  const items = useSelector(CollectionSelectors.getCompanies)
  const fetchAction = useAction(CollectionActions.companiesRequested)
  const resetAction = useAction(CollectionActions.companiesReset)

  useEffect(() => {
    if (query) {
      fetchAction(query)
    } else {
      resetAction()
    }
  }, [query, fetchAction, resetAction])

  return [progress, items]
}

export const useDealStatuses = (): [Progress, DealStatus[]] => {
  const progress = useSelector(CollectionSelectors.getDealStatusesFetchProgress)
  const items = useSelector(CollectionSelectors.getDealStatuses)
  const fetchAction = useAction(CollectionActions.dealStatusesRequested)

  useDidMount(fetchAction)

  return [progress, items]
}

export const useTeams = (): [Progress, Team[]] => {
  const progress = useSelector(CollectionSelectors.getTeamsFetchProgress)
  const items = useSelector(CollectionSelectors.getTeams)
  const fetchAction = useAction(CollectionActions.teamsRequested)

  useDidMount(fetchAction)

  return [progress, items]
}

export const useRegions = (): [Progress, Region[]] => {
  const progress = useSelector(CollectionSelectors.getRegionsFetchProgress)
  const items = useSelector(CollectionSelectors.getRegions)
  const fetchAction = useAction(CollectionActions.regionsRequested)

  useDidMount(fetchAction)

  return [progress, items]
}

export const useLogisticStatuses = (): [Progress, LogisticStatus[]] => {
  const progress = useSelector(CollectionSelectors.getLogisticStatusesFetchProgress)
  const items = useSelector(CollectionSelectors.getLogisticStatuses)
  const fetchAction = useAction(CollectionActions.logisticStatusesRequested)

  useDidMount(fetchAction)

  return [progress, items]
}
