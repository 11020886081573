import React from 'react'
import { useTranslation } from 'react-i18next'

import { Progress, Spinner } from '@agro-club/agroclub-shared'

import env from 'env'
import useFormatDate from 'hooks/useFormatDate'
import useFormatNumber from 'hooks/useFormatNumber'
import { useDeal } from 'modules/domain/deal/hooks'
import { useTrip } from 'modules/domain/trip/hooks'
import { Label, LoadingWrapper, Value } from 'views/pages/Logistics/TripPages/styled'
import { FiveColumnGrid } from 'views/pages/Logistics/TripPages/TripDetails/styled'
import { TripInfoBlock } from 'views/pages/Logistics/TripPages/TripInfoBlock'
import { Phone } from 'views/ui/Phone/Phone'

export const TripViewer: React.FC<{ tripId: string; dealId: string }> = ({ tripId, dealId }) => {
  const { t } = useTranslation('tripPages')
  const formatNumber = useFormatNumber()
  const formatDate = useFormatDate()
  const [tripProgress, trip] = useTrip(tripId)
  const [dealProgress, deal] = useDeal(dealId)
  const currency = env.REACT_APP_CURRENCY_SYMBOL

  const trailerModel = trip?.trailer?.brand_model || ''
  const trailerNumber = trip?.trailer?.licence_number || ''
  const trailerHeight = trip?.trailer?.side_height ? `${trip?.trailer?.side_height} ${t('common:m')}` : ''
  const trailerData = [trailerModel, trailerNumber, trailerHeight].filter(el => el !== '').join(', ')

  if (tripProgress === Progress.WORK || dealProgress === Progress.WORK || !trip || !deal) {
    return (
      <LoadingWrapper>
        <Spinner />
      </LoadingWrapper>
    )
  }

  return (
    <>
      <TripInfoBlock deal={deal} car={trip.car} />

      {!!trailerData && (
        <>
          <Label>{t('tripDetails.trailerLabel')}</Label>
          <Value>{trailerData}</Value>
        </>
      )}
      {!!trip.driver && (
        <>
          <Label>{t('tripDetails.driverLabel')}</Label>
          <Value>
            {trip.driver?.name ?? '-'}, {!!trip.driver?.phone && <Phone phone={trip.driver?.phone} />}
          </Value>
        </>
      )}

      <FiveColumnGrid>
        <div>
          <Label>{t('tripDetails.loadDateLabel')}</Label>
          <Value>{trip.load_date ? formatDate(trip.load_date) : '-'}</Value>
        </div>
        <div>
          <Label>{t('tripDetails.unloadDateLabel')}</Label>
          <Value>{trip.unload_date ? formatDate(trip.unload_date) : '-'}</Value>
        </div>
        <div>
          <Label>{t('tripDetails.logisticsLabel')}</Label>
          <Value>
            {typeof trip.price_logistics === 'number'
              ? t('common:rubKg', { price: formatNumber(trip.price_logistics, true), currency })
              : '-'}
          </Value>
        </div>
        <div>
          <Label>{t('tripDetails.loadQuantityLabel')}</Label>
          <Value>
            {typeof trip.load_quantity === 'number' ? `${formatNumber(trip.load_quantity)} ${t('common:t')}` : '-'}
          </Value>
        </div>
        <div>
          <Label>{t('tripDetails.unloadQuantityLabel')}</Label>
          <Value>
            {typeof trip.unload_quantity === 'number' ? `${formatNumber(trip.unload_quantity)} ${t('common:t')}` : '-'}
          </Value>
        </div>
      </FiveColumnGrid>
    </>
  )
}
