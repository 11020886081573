import { generatePath } from 'react-router-dom'

import {
  IconCallsHistory,
  IconCheckCircle,
  IconCompany,
  IconDashboard,
  IconDeal,
  IconDocument,
  IconSupport,
  IconTruck,
  IconUsers,
} from '@agro-club/agroclub-shared'

import { OPTION, OptionItem } from 'views/components/MainMenu/types'
import BidRoutes from 'views/pages/Bid/routes'
import CallsRoutes from 'views/pages/Calls/routes'
import CallStatisticsRoutes from 'views/pages/CallStatistics/routes'
import CarrierRoutes from 'views/pages/Carrier/routes'
import CarsRoutes from 'views/pages/Cars/routes'
import CompanyRoutes from 'views/pages/Company/routes'
import DashboardRoutes from 'views/pages/Dashboard/routes'
import DealRoutes from 'views/pages/Deal/routes'
import LogisticsRoutes from 'views/pages/Logistics/routes'
import TaskRoutes from 'views/pages/Task/routes'
import UsersRoutes from 'views/pages/User/routes'

const OPTIONS: OptionItem[] = [
  {
    path: generatePath(DashboardRoutes.Dashboard),
    value: OPTION.DASHBOARD,
    icon: IconDashboard,
  },
  {
    path: CompanyRoutes.List,
    value: OPTION.COMPANIES,
    icon: IconCompany,
  },
  {
    path: UsersRoutes.List,
    value: OPTION.USERS,
    icon: IconUsers,
  },
  {
    path: BidRoutes.List,
    value: OPTION.BIDS,
    icon: IconDocument,
  },
  {
    path: DealRoutes.List,
    value: OPTION.DEALS,
    icon: IconDeal,
  },
  {
    path: TaskRoutes.List,
    value: OPTION.TASKS,
    icon: IconCheckCircle,
  },
  {
    value: OPTION.LOGISTICS,
    icon: IconTruck,
    children: [
      {
        path: LogisticsRoutes.List,
        value: OPTION.LOGISTICS_LIST,
      },
      {
        path: CarrierRoutes.List,
        value: OPTION.CARRIERS,
      },
      {
        path: CarsRoutes.List,
        value: OPTION.CARS,
      },
    ],
  },
  {
    value: OPTION.CALLS,
    icon: IconSupport,
    path: CallsRoutes.List,
  },
  {
    value: OPTION.CALL_STATISTICS,
    icon: IconCallsHistory,
    path: CallStatisticsRoutes.List,
  },
]

export default OPTIONS
