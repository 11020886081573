import { Car, CarDTO, CarLogisticsDeal } from 'modules/domain/car/types'
import { RespFile } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { ListResponse } from 'types/api.d'

export const getUserList = (ownerId: string) => apiClient.get<Car[]>(endpoints.userCars(ownerId))

export const getRelevantList = (dealId: string) => apiClient.get<ListResponse<Car>>(endpoints.relevantCars(dealId))

export const getInvolvedList = (dealId: string) => apiClient.get<Car[]>(endpoints.involvedCars(dealId))

export const getItem = (id: string) => apiClient.get<Car>(endpoints.car(id))

export const addItem = (dto: CarDTO) => apiClient.post<Car>(endpoints.car(), dto)

export const updateItem = (id: string, dto: Partial<CarDTO>) => apiClient.put<Car>(endpoints.car(id), dto)

export const removeItem = (id: string) => apiClient.delete<Car>(endpoints.car(id))

export const fetchDocuments = (carId: string) => apiClient.get<RespFile[]>(endpoints.carFiles(carId))

export const uploadFiles = (carId: string, files: File[]) => {
  const promises = files.map(file => {
    const form = new FormData()
    form.append('uploaded_file', file)
    return apiClient
      .post<RespFile>(endpoints.carFiles(carId), form)
      .then(results => ({
        status: 'fulfilled',
        results,
      }))
      .catch(err => ({ status: 'rejected', err }))
  })

  return promises
}

export const deleteDocument = (carId: string, fileId: string) => apiClient.delete(endpoints.carFiles(carId, fileId))

export const fetchCarDealsList = (carId: string) => apiClient.get<CarLogisticsDeal[]>(endpoints.carDeals(carId))

export const changeStatus = (id: string, new_status: string) =>
  apiClient.post<Car>(endpoints.carChangeStatus(id), { new_status })
