import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link, generatePath, useHistory, useLocation, useParams } from 'react-router-dom'

import {
  Accordion,
  AccordionContent,
  AccordionHeader,
  AccordionItem,
  Button,
  Progress,
  useAction,
} from '@agro-club/agroclub-shared'

import usePrevious from 'hooks/usePrevious'
import { CommentType } from 'modules/domain/comment/types'
import TripActions from 'modules/domain/trip/duck'
import TripSelectors from 'modules/domain/trip/selectors'
import { endpoints } from 'modules/endpoints'
import { LocationStateFrom } from 'types/location'
import { Comments } from 'views/components/Comments/Comments'
import FilesByTypes from 'views/components/FilesByTypes/FilesByTypes'
import { Header as HeaderWrapper } from 'views/components/UserRightPanel/AccordionHeader/AccordionHeader'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import LogisticsRoutes from 'views/pages/Logistics/routes'
import { Container, TwoColumnLayout } from 'views/pages/Logistics/TripPages/styled'
import { AccordionContainer, DeleteButton } from 'views/pages/Logistics/TripPages/TripDetails/styled'
import { TripViewer } from 'views/pages/Logistics/TripPages/TripDetails/TripViewer'
import { ConfirmationTooltip } from 'views/ui/ConfirmationTooltip/ConfirmationTooltip'
import * as Header from 'views/ui/Header/Header'

export const TripDetails: React.FC = () => {
  const { t } = useTranslation('tripPages')
  const history = useHistory()
  const location = useLocation<LocationStateFrom>()
  const { dealId, tripId } = useParams<{ dealId: string; tripId: string }>()
  const [commentsSummary, setCommentsSummary] = useState<string>('')

  const [deleteConfirmOpened, setDeleteConfirmOpened] = useState<boolean>(false)
  const deleteTripAction = useAction(TripActions.removeRequested)
  const deleteTrip = useCallback(() => deleteTripAction(tripId), [tripId, deleteTripAction])
  const deleteProgress = useSelector(TripSelectors.removeProgress)

  const documentTypes = useSelector(TripSelectors.documentTypes)
  const getDocumentTypesRequested = useAction(TripActions.getDocumentTypesRequested)
  const uploadFile = useAction(TripActions.uploadDocumentRequested)
  const getDocumentFilesRequested = useAction(TripActions.getDocumentFilesRequested)
  const documentFiles = useSelector(TripSelectors.documentFiles)
  const documentFilesProgress = useSelector(TripSelectors.documentFilesProgress)
  const uploadProgress = useSelector(TripSelectors.uploadProgress)
  const deleteDocumentRequested = useAction(TripActions.deleteDocumentRequested)
  const deleteDocumentProgress = useSelector(TripSelectors.deleteDocumentProgress)

  const prevUploadProgress = usePrevious(uploadProgress)
  const prevDeleteDocumentProgress = usePrevious(deleteDocumentProgress)

  const loading = [uploadProgress, deleteDocumentProgress, documentFilesProgress].includes(Progress.WORK)

  const prevDeleteProgress = usePrevious<Progress>(deleteProgress)
  useEffect(() => {
    if (prevDeleteProgress === Progress.WORK && deleteProgress === Progress.SUCCESS) {
      history.push(generatePath(LogisticsRoutes.Detail, { id: dealId }))
    }
  }, [prevDeleteProgress, deleteProgress, history, dealId])

  useEffect(() => {
    getDocumentTypesRequested(tripId)
    getDocumentFilesRequested(tripId)
  }, [getDocumentFilesRequested, getDocumentTypesRequested, tripId])

  useEffect(() => {
    if (prevUploadProgress === Progress.WORK && uploadProgress === Progress.SUCCESS) {
      getDocumentFilesRequested(tripId)
    }
  }, [tripId, uploadProgress, prevUploadProgress, getDocumentFilesRequested])

  useEffect(() => {
    if (prevDeleteDocumentProgress === Progress.WORK && deleteDocumentProgress === Progress.SUCCESS) {
      getDocumentFilesRequested(tripId)
    }
  }, [tripId, prevDeleteDocumentProgress, deleteDocumentProgress, getDocumentFilesRequested])

  const onUploadHandler = (document_type, file) => {
    uploadFile(tripId, document_type, file)
  }

  const onDeleteHandler = document_id => {
    deleteDocumentRequested(tripId, document_id)
  }

  const goBack = useCallback(() => {
    history.push({
      pathname: generatePath(LogisticsRoutes.Detail, { id: dealId }),
    })
  }, [dealId, history])

  return (
    <>
      <Layout.Header hasBottomBorder>
        <Header.Root
          onClickBack={goBack}
          rightBlock={
            <Header.ButtonWrapper>
              <Link
                to={{
                  pathname: generatePath(LogisticsRoutes.TripEdit, { dealId, tripId }),
                  state: { from: location.state?.from ? { ...location.state?.from } : undefined },
                }}
              >
                <Button intent="primary" size="small" filled>
                  {t('tripDetails.editTripButton')}
                </Button>
              </Link>

              <ConfirmationTooltip
                isModal
                hideArrow
                visible={deleteConfirmOpened}
                overlayProps={{
                  header: t('trips:deleteTripHeader'),
                  body: t('trips:areYouSureToDeleteTrip'),
                  okText: t('common:delete'),
                  okAction: deleteTrip,
                  okProgress: deleteProgress,
                  cancelText: t('common:cancel'),
                  close: () => setDeleteConfirmOpened(false),
                }}
              >
                <div>
                  <DeleteButton intent="minor-action" size="small" onClick={() => setDeleteConfirmOpened(true)}>
                    {t('tripDetails.deleteTripButton')}
                  </DeleteButton>
                </div>
              </ConfirmationTooltip>
            </Header.ButtonWrapper>
          }
        >
          <Header.Title compact title={t('tripDetails.header', { tripId })} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <TwoColumnLayout>
          <Container>
            <TripViewer tripId={tripId} dealId={dealId} />
          </Container>
          <AccordionContainer>
            <Accordion single>
              <AccordionItem id="documents">
                <AccordionHeader>
                  <HeaderWrapper summary={<div>{documentFiles.length ? t('common:thereIs') : t('common:no')}</div>}>
                    {t('tripDetails.documents')}
                  </HeaderWrapper>
                </AccordionHeader>
                <AccordionContent>
                  <FilesByTypes
                    types={documentTypes}
                    files={documentFiles}
                    onUpload={onUploadHandler}
                    onDelete={onDeleteHandler}
                    loading={loading}
                  />
                </AccordionContent>
              </AccordionItem>
              <AccordionItem id="comments" isOpened>
                <AccordionHeader>
                  <HeaderWrapper
                    summary={<div>{commentsSummary ? `${t('common:last')}: ${commentsSummary}` : t('common:no')}</div>}
                  >
                    {t('tripDetails.commentsLabel')}
                  </HeaderWrapper>
                </AccordionHeader>
                <AccordionContent>
                  <Comments
                    id={tripId}
                    type={CommentType.trip}
                    url={endpoints.tripComments(tripId)}
                    maxHeight={400}
                    popSummary={setCommentsSummary}
                  />
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </AccordionContainer>
        </TwoColumnLayout>
      </Layout.Content>
    </>
  )
}
