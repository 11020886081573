import React, { VFC } from 'react'
import { useTranslation } from 'react-i18next'

import { Progress } from '@agro-club/agroclub-shared'

import capitalizeFirstLetter from 'helpers/capitalizeFirstLetter'
import { DealStatusButton } from 'views/components/ChangeDealStatus/DealStatusButton'
import { ChangeStatusWrapper, CurrentStatus, NextArrow, NextElement } from 'views/components/ChangeDealStatus/styled'

type Props = {
  changeStatusProgress: Progress
  nextStatus?: { label: string; slug: string }
  currentStatus: { label: string; slug: string }
  changeStatusAction: (id: string, status: string) => void
  id: string
}

export const Arrow: VFC = () => (
  <NextElement>
    <NextArrow />
  </NextElement>
)

export const ChangeDealStatus: VFC<Props> = ({
  id,
  changeStatusAction,
  changeStatusProgress,
  currentStatus,
  nextStatus,
}) => {
  const { t } = useTranslation('logistics')

  return (
    <ChangeStatusWrapper>
      <CurrentStatus>
        {t('common:status')}: {capitalizeFirstLetter(currentStatus.label)}
      </CurrentStatus>
      {nextStatus && (
        <>
          <Arrow />
          <DealStatusButton
            id={id}
            nextStatus={nextStatus}
            changeStatusAction={changeStatusAction}
            changeStatusProgress={changeStatusProgress}
          />
        </>
      )}
    </ChangeStatusWrapper>
  )
}
