import React, { VFC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, generatePath, useLocation } from 'react-router-dom'

import { isNil } from 'ramda'
import styled from 'styled-components'

import env, { REACT_APP_LOCALE } from 'env'
import capitalizeFirstLetter from 'helpers/capitalizeFirstLetter'
import useFormatNumber from 'hooks/useFormatNumber'
import { useReasonBySlug } from 'modules/domain/deal/hooks'
import { Deal, DealType } from 'modules/domain/deal/types'
import { AvailableLanguages } from 'types/entities'
import {
  ActualMargin,
  Badges,
  ClosedReason,
  Company,
  Item,
  ItemWrapper,
  Margin,
  MarginInTransit,
  MarginWrapper,
  Product,
  QuestionStyled,
  QuestionWrapper,
  ToolTipText,
} from 'views/pages/Deal/DealBoard/styled'
import DealRoutes from 'views/pages/Deal/routes'
import { A, T } from 'views/ui/LetterIcons/LetterIcons'
import Tooltip from 'views/ui/Tooltip/Tooltip'

type Props = {
  id: Deal['id']
  sale_bid: Deal['sale_bid']
  purchase_bid: Deal['purchase_bid']
  margin: Deal['margin']
  margin_level: Deal['margin_level']
  margin_percent: Deal['margin_percent']
  actual_margin: Deal['actual_margin']
  actual_margin_percent: Deal['actual_margin_percent']
  margin_on_way: Deal['margin_on_way']
  product: Deal['product']
  quantity: Deal['quantity']
  quantity_in_bags?: Deal['quantity_in_bags']
  deal_type: Deal['deal_type']
  closed_reason: Deal['closed_reason']
  sale_company_name: Deal['sale_bid']['company']['name']
  purchase_company_name: Deal['purchase_bid']['company']['name']
}

export function getDealId(id: string | number) {
  return `boardDealItem_${id}`
}

const Id = styled.div`
  color: ${({ theme }) => theme.color.textSecondary};
  font-size: 12px;
  line-height: 16px;
`

export const DealItem: VFC<Props> = ({
  id,
  margin,
  margin_level,
  margin_percent,
  actual_margin,
  actual_margin_percent,
  margin_on_way,
  product,
  quantity,
  quantity_in_bags,
  deal_type,
  closed_reason,
  sale_company_name,
  purchase_company_name,
}) => {
  const { t } = useTranslation('deal')
  const formatNumber = useFormatNumber()
  const currency = env.REACT_APP_CURRENCY_SYMBOL
  const isRussia = REACT_APP_LOCALE === AvailableLanguages.ru

  const tooltipOverlay = useMemo(
    () => (
      <>
        <ToolTipText>{t('deal_type')}</ToolTipText>
        <ToolTipText>{t('seller')}</ToolTipText>
        <ToolTipText>{t('buyer')}</ToolTipText>
        <ToolTipText>{t('expected_margin')}</ToolTipText>
        <ToolTipText>{t('actual_margin')}</ToolTipText>
        {isRussia && <ToolTipText>{t('margin_on_way')}</ToolTipText>}
        <ToolTipText>{t('product_volume')}</ToolTipText>
      </>
    ),
    [isRussia, t],
  )

  const location = useLocation<{ dealToScroll?: string }>()
  const dealToScrollId = location.state?.dealToScroll

  const reason = useReasonBySlug(closed_reason)

  return (
    <Link id={getDealId(id)} to={generatePath(DealRoutes.Edit, { id })}>
      <Item $isActive={dealToScrollId === String(id)}>
        <Badges>{deal_type === DealType.agent ? <A /> : <T />}</Badges>
        {!!reason && <ClosedReason>{capitalizeFirstLetter(reason)}</ClosedReason>}
        <ItemWrapper>
          <Id>ID: {id}</Id>

          <Company>{sale_company_name}</Company>
          <Company>{purchase_company_name}</Company>
          <MarginWrapper>
            <Margin marginLevel={margin_level}>
              {currency} {formatNumber(margin)}
              {!isNil(margin_percent) ? ` / ${formatNumber(margin_percent)}%` : ' /  - %'}
            </Margin>
            <ActualMargin>
              {currency} {formatNumber(actual_margin)}
              {!isNil(actual_margin_percent) ? ` / ${formatNumber(actual_margin_percent)}%` : ' /  - %'}
            </ActualMargin>
            <MarginInTransit>
              {isRussia ? (
                <>
                  {formatNumber(margin_on_way)} {t('common:rub', { currency })}
                </>
              ) : null}
            </MarginInTransit>
          </MarginWrapper>

          <Product>
            {product.title} / {formatNumber(quantity_in_bags)} {t('common:bags')}
            &nbsp; ({formatNumber(quantity)} {t('common:ton')})
            <Tooltip overlay={tooltipOverlay} placement="top" trigger={['hover', 'click']}>
              <QuestionWrapper>
                <QuestionStyled />
              </QuestionWrapper>
            </Tooltip>
          </Product>
        </ItemWrapper>
      </Item>
    </Link>
  )
}
