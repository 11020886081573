import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { AddButton, Segment, useAction, useHelmet } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import BidActions from 'modules/domain/bid/duck'
import { useBidFilterAndSortQuery } from 'modules/domain/bid/hooks'
import BidSelectors from 'modules/domain/bid/selectors'
import TableSearch from 'views/components/TableSearch/TableSearch'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import BidTable from 'views/pages/Bid/BidTable/BidTable'
import Routes from 'views/pages/Bid/routes'
import * as Header from 'views/ui/Header/Header'

const SegmentWrapper = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-auto-flow: column;
  align-items: center;
`

const BidList: React.FC = () => {
  const { t } = useTranslation('bid')
  useHelmet({ title: t('bidMetaTitle') })
  const filterUpdated = useAction(BidActions.filterUpdated)
  const filterState = useSelector(BidSelectors.filter)
  const { filter } = useBidFilterAndSortQuery()

  const onChangeSearch = useCallback(
    (search: string) => {
      filterUpdated({ ...filterState, search })
    },
    [filterUpdated, filterState],
  )

  const onChangeBidType = useCallback(
    (bid_type: string) => {
      filterUpdated({
        ...filterState,
        isPotential: bid_type !== 'sale' ? undefined : filterState.isPotential,
        bid_type,
      })
    },
    [filterUpdated, filterState],
  )

  const search = filter.search || filterState.search || ''

  return (
    <>
      <Layout.Header>
        <Header.Root>
          <Header.Title title={t('list.heading')}>
            <SegmentWrapper>
              <Segment
                items={[
                  { id: 'purchase', title: t('bidTypes.purchase') },
                  { id: 'sale', title: t('bidTypes.sale') },
                ]}
                value={filterState.bid_type}
                onChange={onChangeBidType}
              />
              <AddButton to={Routes.Add} />
            </SegmentWrapper>
          </Header.Title>
          <TableSearch value={search} onChangeSearch={onChangeSearch} placeholder={t('list.searchPlaceholder')} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <BidTable />
      </Layout.Content>
    </>
  )
}

export default BidList
