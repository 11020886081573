import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import PageWrapper from 'views/components/PageWrapper/PageWrapper'
import LogisticsCars from 'views/pages/Logistics/LogisticsCars'
import { LogisticsDetail } from 'views/pages/Logistics/LogisticsDetail/LogisticsDetail'
import { LogisticsList } from 'views/pages/Logistics/LogisticsList'
import Routes from 'views/pages/Logistics/routes'
import { TripDetails } from 'views/pages/Logistics/TripPages/TripDetails/TripDetails'
import { TripAddPage } from 'views/pages/Logistics/TripPages/TripEditor/TripAddPage'
import { TripEditPage } from 'views/pages/Logistics/TripPages/TripEditor/TripEditPage'

export const Logistics: React.FC = () => (
  <PageWrapper>
    <Switch>
      <Route path={Routes.List} exact>
        <LogisticsList />
      </Route>
      <Route path={Routes.Detail} exact>
        <LogisticsDetail />
      </Route>
      <Route path={Routes.Cars} exact>
        <LogisticsCars />
      </Route>
      <Route path={Routes.TripDetails} exact>
        <TripDetails />
      </Route>
      <Route path={Routes.TripAdd} exact>
        <TripAddPage />
      </Route>
      <Route path={Routes.TripEdit} exact>
        <TripEditPage />
      </Route>
      <Redirect to={Routes.List} />
    </Switch>
  </PageWrapper>
)
