import React from 'react'
import { useTranslation } from 'react-i18next'

import { IconCheckmark, IconVerifiedShield, Icons } from '@agro-club/agroclub-shared'

import PermissionCheck from 'views/components/PermissionCheck/PermissionCheck'
import {
  BlockUser,
  BlockUserNoSeparator,
  Coordinator,
  Security,
  StatusList,
  VerifiedUser,
} from 'views/components/UserRightPanel/styled'
import { VerifyList } from 'views/ui/types'

export const VerifyStatusList: React.FC<VerifyList> = ({
  isVerifyCoordinator,
  verifyCoordinatorAction,
  isVerifySecurity,
  verifySecurityAction,
  isActive,
  blockUserAction,
  showVerifyList,
}) => {
  const { t } = useTranslation(['user'])

  if (showVerifyList) {
    return (
      <StatusList style={{ paddingTop: ' 8px' }}>
        {isVerifyCoordinator && isVerifySecurity ? (
          <VerifiedUser>
            <div>
              <IconCheckmark />
            </div>
            {t('verify_user')}
          </VerifiedUser>
        ) : (
          <>
            <PermissionCheck scope={['users.can_verify_as_coordinator']}>
              <Coordinator
                isActive={isActive}
                isVerifyCoordinator={isVerifyCoordinator}
                onClick={() => verifyCoordinatorAction()}
              >
                <div>
                  <IconCheckmark />
                </div>
                {t('verify_coordinator')}
              </Coordinator>
            </PermissionCheck>
            <PermissionCheck scope={['users.can_verify_as_security']}>
              <Security isActive={isActive} isVerifySecurity={isVerifySecurity} onClick={() => verifySecurityAction()}>
                <div>
                  <IconVerifiedShield />
                </div>
                {t('verify_security')}
              </Security>
            </PermissionCheck>
          </>
        )}
        <BlockUser isActive={isActive} onClick={() => blockUserAction()}>
          <div>
            <Icons.IconCancelled />
          </div>
          {isActive ? t('block') : t('blocked')}
        </BlockUser>
      </StatusList>
    )
  }

  return (
    <StatusList style={{ paddingTop: ' 8px' }}>
      <BlockUserNoSeparator isActive={isActive} onClick={() => blockUserAction()}>
        <div>
          <Icons.IconCancelled />
        </div>
        {isActive ? t('block') : t('blocked')}
      </BlockUserNoSeparator>
    </StatusList>
  )
}

export default VerifyStatusList
