import React from 'react'

import { Button, ButtonFullProps } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const StyledButton = styled(Button)`
  padding: 0;
  height: 18px;
`

type Props = Omit<ButtonFullProps, 'intent'>

export const TextButton: React.FC<Props> = props => (
  <StyledButton intent="minor-action" {...props}>
    {props.children}
  </StyledButton>
)
