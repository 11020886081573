import { Progress } from '@agro-club/agroclub-shared'

import { CarLogisticsDeal } from 'modules/domain/car/types'
import { NetworkErrorType } from 'modules/errors'

export type FetchError = NetworkErrorType | 'unknown'
export type UpdateError = NetworkErrorType | 'unknown'
export type RemoveError = NetworkErrorType | 'unknown'
export type AddError = NetworkErrorType | 'unknown'
export type DownloadError = NetworkErrorType | 'unknown'
export type UploadError = NetworkErrorType | 'unknown'
export type NetworkError = NetworkErrorType | 'unknown'

export type NonFieldErrors = { non_field_errors?: string[] }

export type EntityMetadata<T> = {
  id?: string
  fetchProgress: Progress
  fetchError: FetchError | null
  fetchErrorDetail?: string

  removeProgress?: Progress
  removeError?: RemoveError | null
  removeErrorDetail?: string
  updateProgress?: Progress
  updateError?: UpdateError | null
  updateErrorDetail?: string
  updateErrorFields?: Record<keyof T & NonFieldErrors, string[]>
}

export enum UnloadingType {
  left = 'left',
  right = 'right',
  rear = 'rear',
  right_rear = 'right_rear',
  left_rear = 'left_rear',
  right_left = 'right_left',
  three_sides = 'three_sides',
}

export enum VehicleType {
  tonar = 'tonar',
  coupling = 'coupling',
  semi_trailer = 'semi_trailer',
  grain_truck = 'grain_truck',
}

export enum YesNoUnknown {
  yes = 'yes',
  no = 'no',
  unknown = 'unknown',
}

export enum LoaderType {
  kuhn = 'kuhn',
  manitou = 'manitou',
  grain_thrower = 'grain_thrower',
}

export type Address = {
  id: string
  title: string
  address: string
  latitude: number
  longitude: number
  is_main: boolean
  geo_object: unknown
  short_address: string

  // warehouse properties
  gates_height?: number | null
  is_dump_truck?: boolean
  loader_type?: LoaderType[]
  scales_capacity?: number | null
  scales_length?: number | null
  scales_remoteness?: number | null
  schedule_from?: string | null
  schedule_to?: string | null
  unloading_type?: UnloadingType
  vehicle_type?: VehicleType[]
  volume_per_day?: number | null
  works_on_weekend?: YesNoUnknown
  car_delivery?: YesNoUnknown
  railway_delivery?: YesNoUnknown

  legal_type?: string
}

export type WarehouseDTO = Omit<
  Address,
  'id' | 'title' | 'address' | 'latitude' | 'longitude' | 'is_main' | 'geo_object' | 'short_address'
>

export type RespFile = {
  original_filename: string
  pk: string
  uploaded_file: string
}

export type Documents = {
  fetchProgress: Progress
  uploadProgress: Progress
  files: RespFile[]
}

export type Product = {
  id: string
  slug: string
  title: string
}

export type UserShort = {
  id: string
  phone: string
  short_name: string
}

export type Driver = {
  id: string
  name: string
  phone: string
}

export type CarDeals = {
  fetchProgress: Progress
  deals: CarLogisticsDeal[]
}

export type CloseReason = {
  slug: string
  label: string
}

export type DocumentType = {
  slug: string
  label: string
}

export type DocumentFile = {
  id: string
  uploaded_file: string
  original_filename: string
  document_type?: string
}

export type DocumentWithTypeFile = {
  document_type: string
  uploaded_file: string
}
