import { Progress } from '@agro-club/agroclub-shared'
import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer'

import { LIST_PAGE_SIZE } from 'modules/constants'
import { AddError, FetchError, RemoveError, UpdateError } from 'modules/domain/types'
import { arrToDict, getIds } from 'modules/utils/helpers'

import { Task, TaskDTO, TaskListRequestFilter, TaskListRequestSorting, TaskState } from './types'

const initialState: TaskState = {
  items: {},
  meta: {},
  ids: [],

  listFetchProgress: Progress.IDLE,
  listFetchError: null,
  itemFetchProgress: Progress.IDLE,
  itemFetchError: null,
  addProgress: Progress.IDLE,
  addError: null,
  updateProgress: Progress.IDLE,
  updateError: null,
  removeProgress: Progress.IDLE,
  removeError: null,

  filter: {},
  sorting: {},
  page: 1,
  total: 0,
  pageSize: LIST_PAGE_SIZE,

  myTasks: [],
  myTasksFetchProgress: Progress.IDLE,
  completeProgress: Progress.IDLE,
  completeError: null,

  userTasks: {},
  userTasksFetchProgress: Progress.IDLE,

  callBackIds: [],
  callBackFetchProgress: Progress.IDLE,
  callBackPage: 1,
  callBackTotal: 0,
  callBackPageSize: LIST_PAGE_SIZE,
}

class TaskReducer extends ImmerReducer<TaskState> {
  listRequested(params: { filter?: TaskListRequestFilter; sorting?: TaskListRequestSorting; page?: number }) {
    this.draftState.listFetchProgress = Progress.WORK
    this.draftState.listFetchErrorDetail = undefined
    this.draftState.listFetchError = null
    this.draftState.filter = params.filter || this.draftState.filter
    this.draftState.sorting = params.sorting || this.draftState.sorting
    this.draftState.page = typeof params.page === 'undefined' ? this.draftState.page : params.page
  }

  listRequestSucceed(list: Task[], total: number, page: number) {
    this.draftState.listFetchProgress = Progress.SUCCESS
    this.draftState.listFetchErrorDetail = undefined
    this.draftState.items = { ...this.draftState.items, ...arrToDict(list) }
    this.draftState.meta = {
      ...this.draftState.meta,
      ...arrToDict(
        list.map(item => ({
          id: item.id,
          fetchProgress: Progress.SUCCESS,
          fetchError: null,
          removeProgress: Progress.IDLE,
          removeError: null,
          updateProgress: Progress.IDLE,
          updateError: null,
        })),
      ),
    }
    this.draftState.ids = getIds(list)
    this.draftState.total = total
    this.draftState.page = page
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  listRequestFailed(error: FetchError, errorDetail?: string) {
    this.draftState.listFetchProgress = Progress.ERROR
    this.draftState.listFetchError = error
    this.draftState.listFetchErrorDetail = errorDetail
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  itemRequested(id: string) {
    this.draftState.itemFetchProgress = Progress.WORK

    const meta = {
      id,
      updateProgress: Progress.IDLE,
      updateError: null,
      removeProgress: Progress.IDLE,
      removeError: null,
    }

    this.draftState.meta[id] = {
      ...meta,
      ...this.draftState.meta[id],
      fetchProgress: Progress.WORK,
      fetchError: null,
    }
  }

  itemRequestSucceed(item: Task) {
    this.draftState.itemFetchProgress = Progress.SUCCESS
    this.draftState.meta[item.id].fetchProgress = Progress.SUCCESS
    this.draftState.meta[item.id].fetchError = null
    this.draftState.meta[item.id].fetchErrorDetail = undefined
    this.draftState.items[item.id] = item
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  itemRequestFailed(id: string, error: FetchError, errorDetail?: string) {
    this.draftState.itemFetchProgress = Progress.ERROR
    this.draftState.meta[id].fetchProgress = Progress.ERROR
    this.draftState.meta[id].fetchError = error
    this.draftState.meta[id].fetchErrorDetail = errorDetail
  }

  addRequested(_dto: TaskDTO) {
    this.draftState.addProgress = Progress.WORK
    this.draftState.addError = null
    this.draftState.addErrorDetail = undefined
    this.draftState.addErrorFields = undefined
  }

  addSucceed(item: Task) {
    this.draftState.addProgress = Progress.SUCCESS
    this.draftState.items[item.id] = item
    this.draftState.meta[item.id] = {
      id: item.id,
      fetchProgress: Progress.SUCCESS,
      fetchError: null,
      updateProgress: Progress.IDLE,
      updateError: null,
      removeProgress: Progress.IDLE,
      removeError: null,
    }
    this.draftState.addErrorDetail = undefined
    this.draftState.addErrorFields = undefined
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  addFailed(error: AddError, errorDetail?: string, errorFields?: Record<string, string[]>) {
    this.draftState.addProgress = Progress.ERROR
    this.draftState.addError = error
    this.draftState.addErrorDetail = errorDetail
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.draftState.addErrorFields = errorFields
  }

  updateRequested(id: string, _item: Partial<TaskDTO>) {
    this.draftState.updateProgress = Progress.WORK
    this.draftState.meta[id].updateProgress = Progress.WORK
    this.draftState.meta[id].updateError = null
    this.draftState.meta[id].updateErrorDetail = undefined
    this.draftState.meta[id].updateErrorFields = undefined
  }

  updateSucceed(item: Task) {
    this.draftState.items[item.id] = item
    this.draftState.updateProgress = Progress.SUCCESS
    this.draftState.meta[item.id].updateProgress = Progress.SUCCESS
    this.draftState.meta[item.id].updateError = null
    this.draftState.meta[item.id].updateErrorDetail = undefined
    this.draftState.meta[item.id].updateErrorFields = undefined
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  updateFailed(id: string, error: UpdateError, errorText?: string, errorFields?: Record<string, string[]>) {
    this.draftState.updateProgress = Progress.ERROR
    this.draftState.meta[id].updateProgress = Progress.ERROR
    this.draftState.meta[id].updateError = error
    this.draftState.meta[id].updateErrorDetail = errorText
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.draftState.meta[id].updateErrorFields = errorFields
  }

  filterUpdated(filter: TaskListRequestFilter) {
    this.draftState.filter = filter
    this.draftState.listFetchProgress = Progress.WORK
    this.draftState.page = 1
  }

  sortingUpdated(sorting: TaskListRequestSorting) {
    this.draftState.sorting = sorting
    this.draftState.listFetchProgress = Progress.WORK
  }

  filterHasBeenReset() {
    this.draftState.filter = {}
    this.draftState.listFetchProgress = Progress.WORK
  }

  sortingHasBeenReset() {
    this.draftState.sorting = {}
    this.draftState.listFetchProgress = Progress.WORK
  }

  removeRequested(id: string) {
    this.draftState.removeProgress = Progress.WORK
    this.draftState.meta[id].removeProgress = Progress.WORK
    this.draftState.meta[id].removeError = null
    this.draftState.meta[id].removeErrorDetail = undefined
  }

  removeSucceed(id: string) {
    this.draftState.removeProgress = Progress.SUCCESS
    delete this.draftState.items[id]
    delete this.draftState.meta[id]
    const i = this.draftState.ids.findIndex(item => item === id)
    if (i !== -1) {
      const { ids } = this.draftState
      this.draftState.ids = [...ids.slice(0, i), ...ids.slice(i + 1)]
    }
  }

  removeFailed(id: string, error: RemoveError, errorDetail?: string) {
    this.draftState.removeProgress = Progress.ERROR
    this.draftState.meta[id].removeProgress = Progress.ERROR
    this.draftState.meta[id].removeError = error
    this.draftState.meta[id].removeErrorDetail = errorDetail
  }

  fetchMyTasksRequested() {
    this.draftState.myTasksFetchProgress = Progress.WORK
  }

  fetchMyTasksSuccess(list: Task[]) {
    this.draftState.myTasksFetchProgress = Progress.SUCCESS
    this.draftState.items = { ...this.draftState.items, ...arrToDict(list) }
    this.draftState.meta = {
      ...this.draftState.meta,
      ...arrToDict(
        list.map(item => ({
          id: item.id,
          fetchProgress: Progress.SUCCESS,
          fetchError: null,
          removeProgress: Progress.IDLE,
          removeError: null,
          updateProgress: Progress.IDLE,
          updateError: null,
        })),
      ),
    }
    this.draftState.myTasks = getIds(list)
  }

  fetchMyTasksFailed() {
    this.draftState.myTasksFetchProgress = Progress.ERROR
  }

  userTasksRequested(_userId: string) {
    this.draftState.userTasksFetchProgress = Progress.WORK
  }

  userTasksSuccess(userId, list: Task[]) {
    this.draftState.userTasksFetchProgress = Progress.SUCCESS
    this.draftState.items = { ...this.draftState.items, ...arrToDict(list) }
    this.draftState.meta = {
      ...this.draftState.meta,
      ...arrToDict(
        list.map(item => ({
          id: item.id,
          fetchProgress: Progress.SUCCESS,
          fetchError: null,
          removeProgress: Progress.IDLE,
          removeError: null,
          updateProgress: Progress.IDLE,
          updateError: null,
        })),
      ),
    }
    this.draftState.userTasks[userId] = getIds(list)
  }

  userTasksFailed() {
    this.draftState.userTasksFetchProgress = Progress.ERROR
  }

  completeTaskRequested(id: string) {
    this.draftState.taskId = id
    this.draftState.completeProgress = Progress.WORK
  }

  completeTaskSuccess(_id: string) {
    this.draftState.completeProgress = Progress.SUCCESS
  }

  completeTaskFailed(error: FetchError, errorDetail?: string) {
    this.draftState.completeProgress = Progress.ERROR
    this.draftState.completeError = error
    this.draftState.completeErrorDetail = errorDetail
  }

  callBackFetchProgressWork() {
    this.draftState.callBackFetchProgress = Progress.WORK
  }

  callBackListRequested(params: { page?: number }) {
    this.draftState.callBackFetchProgress = Progress.WORK
    this.draftState.callBackPage = typeof params.page === 'undefined' ? this.draftState.page : params.page
  }

  callBackListRequestSucceed(list: Task[], total: number, page: number) {
    this.draftState.callBackFetchProgress = Progress.SUCCESS
    this.draftState.items = { ...this.draftState.items, ...arrToDict(list) }
    this.draftState.meta = {
      ...this.draftState.meta,
      ...arrToDict(
        list.map(item => ({
          id: item.id,
          fetchProgress: Progress.SUCCESS,
          fetchError: null,
          removeProgress: Progress.IDLE,
          removeError: null,
          updateProgress: Progress.IDLE,
          updateError: null,
        })),
      ),
    }
    this.draftState.callBackIds = getIds(list)
    this.draftState.callBackTotal = total
    this.draftState.callBackPage = page
  }

  callBackListRequestFailed() {
    this.draftState.callBackFetchProgress = Progress.ERROR
  }
}

export const TaskActions = createActionCreators(TaskReducer)
export default TaskActions
export const reducer = createReducerFunction(TaskReducer, initialState)
