import React, { useMemo, useRef } from 'react'

import { Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import formatPhone from 'helpers/formatPhone'

type Props = {
  phone: string
  className?: string
}

const StyledPhone = styled.span`
  display: block;
  color: ${({ theme }) => theme.color.primary600};
  ${Typography.body2Default};
  &:hover {
    text-decoration: underline;
  }
  user-select: text;
  cursor: default;
  padding: 0 8px;
  position: relative;
`

export const SelectablePhone: React.FC<Props> = props => {
  const { phone, className } = props
  const formatted = useMemo(() => formatPhone(phone), [phone])
  const drag = useRef<boolean>(false)

  return (
    <StyledPhone
      className={className}
      onMouseDown={() => {
        drag.current = false
      }}
      onMouseMove={() => {
        drag.current = true
      }}
      onMouseUp={() => {
        if (!drag.current) {
          window.location.href = `tel:${phone}`
        }
      }}
      onClick={e => {
        e.stopPropagation()
        e.preventDefault()
      }}
    >
      {formatted}
    </StyledPhone>
  )
}
