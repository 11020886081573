import { Progress } from '@agro-club/agroclub-shared'
import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer'

import {
  CollectionState,
  CompanyDadata,
  DealStatus,
  LogisticStatus,
  Product,
  Region,
  Team,
} from 'modules/domain/collection/types'

const initialState: CollectionState = {
  products: [],
  productsFetchProgress: Progress.IDLE,
  companies: [],
  companiesFetchProgress: Progress.SUCCESS,
  dealStatuses: [],
  dealStatusesFetchProgress: Progress.IDLE,
  teams: [],
  teamsFetchProgress: Progress.IDLE,
  regions: [],
  regionsFetchProgress: Progress.IDLE,
  logisticStatuses: [],
  logisticStatusesFetchProgress: Progress.IDLE,
}

class CollectionReducer extends ImmerReducer<CollectionState> {
  productsRequested() {
    this.draftState.productsFetchProgress = Progress.WORK
  }

  productsRequestSucceed(products: Product[]) {
    this.draftState.products = products
    this.draftState.productsFetchProgress = Progress.SUCCESS
  }

  productsRequestFailed() {
    this.draftState.productsFetchProgress = Progress.ERROR
  }

  companiesRequested(_query: string) {
    this.draftState.companies = []
    this.draftState.companiesFetchProgress = Progress.WORK
  }

  companiesRequestSucceed(companies: CompanyDadata[]) {
    this.draftState.companies = companies
    this.draftState.companiesFetchProgress = Progress.SUCCESS
  }

  companiesRequestFailed() {
    this.draftState.companiesFetchProgress = Progress.ERROR
  }

  companiesReset() {
    this.draftState.companies = []
  }

  dealStatusesRequested() {
    this.draftState.dealStatusesFetchProgress = Progress.WORK
  }

  dealStatusesRequestSucceed(dealStatuses: DealStatus[]) {
    this.draftState.dealStatuses = dealStatuses
    this.draftState.dealStatusesFetchProgress = Progress.SUCCESS
  }

  dealStatusesRequestFailed() {
    this.draftState.dealStatusesFetchProgress = Progress.ERROR
  }

  teamsRequested() {
    this.draftState.teamsFetchProgress = Progress.WORK
  }

  teamsRequestSucceed(teams: Team[]) {
    this.draftState.teams = teams.map(team => ({ ...team, id: String(team.id) }))
    this.draftState.teamsFetchProgress = Progress.SUCCESS
  }

  teamsRequestFailed() {
    this.draftState.teamsFetchProgress = Progress.ERROR
  }

  regionsRequested() {
    this.draftState.regionsFetchProgress = Progress.WORK
  }

  regionsRequestSucceed(regions: Region[]) {
    this.draftState.regions = regions.map(region => ({
      ...region,
      id: String(region.id),
      parent_id: region.parent_id ? String(region.parent_id) : region.parent_id,
    }))
    this.draftState.regionsFetchProgress = Progress.SUCCESS
  }

  regionsRequestFailed() {
    this.draftState.regionsFetchProgress = Progress.ERROR
  }

  logisticStatusesRequested() {
    this.draftState.logisticStatusesFetchProgress = Progress.WORK
  }

  logisticStatusesRequestSucceed(logisticStatuses: LogisticStatus[]) {
    this.draftState.logisticStatuses = logisticStatuses
    this.draftState.logisticStatusesFetchProgress = Progress.SUCCESS
  }

  logisticStatusesRequestFailed() {
    this.draftState.logisticStatusesFetchProgress = Progress.ERROR
  }
}

export const CollectionActions = createActionCreators(CollectionReducer)
export default CollectionActions
export const reducer = createReducerFunction(CollectionReducer, initialState)
