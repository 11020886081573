import { WarehouseDTO } from 'modules/domain/types'
import { UserAddress, UserAddressDTO } from 'modules/domain/userAddress/types'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'

export const getList = (userId: string) => apiClient.get<UserAddress[]>(endpoints.userAddress(userId))

export const addItem = (userId: string, dto: UserAddressDTO) =>
  apiClient.post<UserAddress>(endpoints.userAddress(userId), dto)

export const deleteItem = (userId: string, addressId: string) =>
  apiClient.delete<unknown>(endpoints.userAddress(userId, addressId))

export const editItem = (userId: string, addressId: string, dto: WarehouseDTO) =>
  apiClient.put<WarehouseDTO>(endpoints.userAddress(userId, addressId), dto)
