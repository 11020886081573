import { Progress } from '@agro-club/agroclub-shared'
import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer'
import { isEmpty } from 'ramda'

import flatErrorObject from 'helpers/flatErrorObject'
import { LIST_PAGE_SIZE } from 'modules/constants'
import { AddError, FetchError, RemoveError, RespFile, UpdateError } from 'modules/domain/types'
import { User, UserDTO, UserListRequestFilter, UserListRequestSorting, UsersState } from 'modules/domain/user/types'
import { arrToDict, getIds } from 'modules/utils/helpers'

const initialState: UsersState = {
  items: {},
  meta: {},
  ids: [],

  listFetchProgress: Progress.IDLE,
  listFetchError: null,
  itemFetchProgress: Progress.IDLE,
  itemFetchError: null,
  addProgress: Progress.IDLE,
  lastAddedUserId: null,
  addError: null,
  updateProgress: Progress.IDLE,
  updateError: null,
  removeProgress: Progress.IDLE,
  removeError: null,

  filter: {},
  sorting: {},
  page: 1,
  total: 0,
  pageSize: LIST_PAGE_SIZE,

  verifyCoordinatorProgress: Progress.IDLE,
  verifySecurityProgress: Progress.IDLE,
  userBlockProgress: Progress.IDLE,

  documents: {},
  deleteDocumentProgress: {},

  getTypedDocumentProgress: {},
  getTypedDocumentError: {},
  getTypedDocumentErrorDetail: {},
  documentTypes: {},
  uploadProgress: {},
  uploadError: {},
  uploadErrorDetail: {},
  getDocumentFilesProgress: {},
  getDocumentFilesError: {},
  getDocumentFilesErrorDetail: {},
  documentFiles: {},
  deleteTypedDocumentProgress: {},
}

export type ListRequestedParams = { filter?: UserListRequestFilter; sorting?: UserListRequestSorting; page?: number }

const DEFAULT_DOCUMENTS_STATE = {
  fetchProgress: Progress.IDLE,
  uploadProgress: Progress.IDLE,
  files: [],
}

class UsersReducer extends ImmerReducer<UsersState> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  listRequested(params: ListRequestedParams) {
    this.draftState.listFetchProgress = Progress.WORK
    this.draftState.listFetchError = null
    this.draftState.filter = (!isEmpty(params.filter) && params.filter) || this.draftState.filter
    this.draftState.sorting = params.sorting || this.draftState.sorting
    this.draftState.page = typeof params.page === 'undefined' ? this.draftState.page : params.page
  }

  listRequestSucceed(list: User[], total: number, page: number) {
    this.draftState.listFetchProgress = Progress.SUCCESS
    this.draftState.total = total
    this.draftState.page = page
    this.draftState.items = arrToDict(list)
    this.draftState.meta = arrToDict(
      list.map(item => ({
        id: item.id,
        fetchProgress: Progress.SUCCESS,
        fetchError: null,
        removeProgress: Progress.IDLE,
        removeError: null,
        updateProgress: Progress.IDLE,
        updateError: null,
      })),
    )
    this.draftState.ids = getIds(list)
  }

  listRequestFailed(error: FetchError) {
    this.draftState.listFetchProgress = Progress.ERROR
    this.draftState.listFetchError = error
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  itemRequested(id: string) {
    this.draftState.itemFetchProgress = Progress.WORK

    const meta = {
      id,
      updateProgress: Progress.IDLE,
      updateError: null,
      removeProgress: Progress.IDLE,
      removeError: null,
    }

    this.draftState.meta[id] = {
      ...meta,
      ...this.draftState.meta[id],
      fetchProgress: Progress.WORK,
      fetchError: null,
    }
  }

  itemRequestSucceed(user: User) {
    this.draftState.itemFetchProgress = Progress.SUCCESS
    this.draftState.items[user.id] = user

    if (this.draftState.meta[user.id]) {
      this.draftState.meta[user.id].fetchProgress = Progress.SUCCESS
      this.draftState.meta[user.id].fetchError = null
      this.draftState.meta[user.id].updateErrorFields = undefined
      this.draftState.meta[user.id].updateError = null
      this.draftState.meta[user.id].updateErrorDetail = undefined
    } else {
      this.draftState.meta[user.id] = {
        id: user.id,
        updateProgress: Progress.IDLE,
        updateError: null,
        removeProgress: Progress.IDLE,
        removeError: null,
        fetchProgress: Progress.SUCCESS,
        fetchError: null,
      }
    }
  }

  itemRequestFailed(id: string, error: FetchError) {
    this.draftState.itemFetchProgress = Progress.ERROR
    this.draftState.meta[id].fetchProgress = Progress.ERROR
    this.draftState.meta[id].fetchError = error
  }

  addRequested(_dto: UserDTO) {
    this.draftState.addProgress = Progress.WORK
    this.draftState.addError = null
    this.draftState.addErrorDetail = undefined
    this.draftState.addErrorFields = undefined
    this.draftState.lastAddedUserId = null
  }

  addSucceed(user: User) {
    this.draftState.addProgress = Progress.SUCCESS
    this.draftState.items[user.id] = user
    this.draftState.meta[user.id] = {
      id: user.id,
      fetchProgress: Progress.SUCCESS,
      fetchError: null,
      updateProgress: Progress.IDLE,
      updateError: null,
      removeProgress: Progress.IDLE,
      removeError: null,
    }
    this.draftState.lastAddedUserId = user.id
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  addFailed(error: AddError, errorDetail?: string, errorFields?: Record<string, string[]>) {
    this.draftState.addProgress = Progress.ERROR
    this.draftState.addError = error
    this.draftState.addErrorDetail = errorDetail
    const addressErrors = errorFields?.addresses?.length ? flatErrorObject({ addresses: errorFields.addresses[0] }) : {}
    this.draftState.addErrorFields = {
      ...errorFields,
      ...addressErrors,
    }
  }

  resetAddErrors() {
    this.draftState.addErrorDetail = undefined
    this.draftState.addErrorFields = undefined
    this.draftState.lastAddedUserId = null
  }

  updateRequested(id: string, _user: Partial<UserDTO>) {
    this.draftState.updateProgress = Progress.WORK
    this.draftState.meta[id].updateProgress = Progress.WORK
    this.draftState.meta[id].updateError = null
    this.draftState.meta[id].updateErrorDetail = undefined
    this.draftState.meta[id].updateErrorFields = undefined
  }

  updateSucceed(user: User) {
    this.draftState.items[user.id] = user
    this.draftState.meta[user.id].updateProgress = Progress.SUCCESS
    this.draftState.updateProgress = Progress.SUCCESS
    this.draftState.meta[user.id].updateError = null
    this.draftState.meta[user.id].updateErrorDetail = undefined
    this.draftState.meta[user.id].updateErrorFields = undefined
  }

  updateFailed(id: string, error: UpdateError, errorText?: string, errorFields?: Record<string, string[]>) {
    this.draftState.meta[id].updateProgress = Progress.ERROR
    this.draftState.meta[id].updateError = error
    this.draftState.updateProgress = Progress.ERROR
    this.draftState.meta[id].updateError = error
    this.draftState.meta[id].updateErrorDetail = errorText
    this.draftState.meta[id].updateErrorFields = errorFields
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  removeRequested(id: string) {
    this.draftState.removeProgress = Progress.WORK
    this.draftState.meta[id].removeProgress = Progress.WORK
    this.draftState.meta[id].removeError = null
  }

  removeSucceed(id: string) {
    this.draftState.removeProgress = Progress.SUCCESS
    delete this.draftState.items[id]
    delete this.draftState.meta[id]
    const i = this.draftState.ids.findIndex(item => item === id)
    if (i !== -1) {
      const { ids } = this.draftState
      this.draftState.ids = [...ids.slice(0, i), ...ids.slice(i + 1)]
    }
  }

  removeFailed(id: string, error: RemoveError) {
    this.draftState.removeProgress = Progress.ERROR
    this.draftState.meta[id].removeProgress = Progress.WORK
    this.draftState.meta[id].removeError = error
  }

  filterUpdated(filter: UserListRequestFilter) {
    this.draftState.filter = filter
    this.draftState.listFetchProgress = Progress.WORK
    this.draftState.page = 1
  }

  filterResetWithoutRequest() {
    this.draftState.filter = {}
    this.draftState.listFetchProgress = Progress.IDLE
    this.draftState.page = 1
  }

  sortingUpdated(sorting: UserListRequestSorting) {
    this.draftState.sorting = sorting
    this.draftState.listFetchProgress = Progress.WORK
  }

  filterHasBeenReset() {
    this.draftState.filter = {}
    this.draftState.listFetchProgress = Progress.WORK
  }

  sortingHasBeenReset() {
    this.draftState.sorting = {}
    this.draftState.listFetchProgress = Progress.WORK
  }

  userVerifyCoordinatorRequested(_id: string) {
    this.draftState.verifyCoordinatorProgress = Progress.WORK
  }

  userVerifyCoordinatorSucceed(id: string) {
    this.draftState.verifyCoordinatorProgress = Progress.SUCCESS
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.draftState.items[id].profile = {
      ...this.draftState.items[id].profile,
      is_verified_by_coordinator: true,
    }
  }

  userVerifyCoordinatorFailed() {
    this.draftState.verifyCoordinatorProgress = Progress.ERROR
  }

  userVerifySecurityRequested(_id: string) {
    this.draftState.verifySecurityProgress = Progress.WORK
  }

  userVerifySecuritySucceed(id: string) {
    this.draftState.verifySecurityProgress = Progress.SUCCESS
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.draftState.items[id].profile = {
      ...this.draftState.items[id].profile,
      is_verified_by_security: true,
    }
  }

  userVerifySecurityFailed() {
    this.draftState.verifySecurityProgress = Progress.ERROR
  }

  userBlockRequested(_id: string) {
    this.draftState.userBlockProgress = Progress.WORK
  }

  userBlockSucceed(id: string) {
    this.draftState.userBlockProgress = Progress.SUCCESS
    this.draftState.items[id] = {
      ...this.draftState.items[id],
      is_active: false,
    }
  }

  userBlockFailed() {
    this.draftState.userBlockProgress = Progress.ERROR
  }

  documentsRequested(id: string) {
    this.draftState.documents[id] = {
      ...DEFAULT_DOCUMENTS_STATE,
      ...this.draftState.documents[id],
      fetchProgress: Progress.WORK,
    }
  }

  documentsRequestSuccess(id: string, files: RespFile[]) {
    this.draftState.documents[id] = {
      ...DEFAULT_DOCUMENTS_STATE,
      ...this.draftState.documents[id],
      fetchProgress: Progress.SUCCESS,
      files,
    }
  }

  documentsRequestFailed(id: string) {
    this.draftState.documents[id] = {
      ...DEFAULT_DOCUMENTS_STATE,
      ...this.draftState.documents[id],
      fetchProgress: Progress.ERROR,
    }
  }

  uploadDocumentsRequested(id: string, _files: File[]) {
    this.draftState.documents[id] = {
      ...DEFAULT_DOCUMENTS_STATE,
      ...this.draftState.documents[id],
      uploadProgress: Progress.WORK,
    }
  }

  uploadDocumentsSucccess(id: string, files: RespFile[]) {
    this.draftState.documents[id] = {
      ...DEFAULT_DOCUMENTS_STATE,
      ...this.draftState.documents[id],
      uploadProgress: Progress.SUCCESS,
      files: [...files, ...(this.draftState.documents?.[id]?.files ?? [])],
    }
  }

  uploadDocumentsFailed(id: string) {
    this.draftState.documents[id] = {
      ...DEFAULT_DOCUMENTS_STATE,
      ...this.draftState.documents[id],
      uploadProgress: Progress.ERROR,
    }
  }

  deleteDocumentRequested(_userId: string, fileId: string) {
    this.draftState.deleteDocumentProgress[fileId] = Progress.WORK
  }

  deleteDocumentSucccess(userId: string, fileId: string) {
    this.draftState.documents[userId] = {
      ...this.draftState.documents[userId],
      files: this.draftState.documents[userId].files.filter(file => String(file.pk) !== String(fileId)),
    }
    this.draftState.deleteDocumentProgress[fileId] = Progress.SUCCESS
  }

  deleteDocumentFailed(fileId: string) {
    this.draftState.deleteDocumentProgress[fileId] = Progress.ERROR
  }

  getDocumentTypesRequested(id: string) {
    this.draftState.getTypedDocumentProgress[id] = Progress.WORK
    this.draftState.getTypedDocumentErrorDetail[id] = undefined
    this.draftState.getTypedDocumentError[id] = null
  }

  getDocumentTypesSucceed(id, list) {
    this.draftState.getTypedDocumentProgress[id] = Progress.SUCCESS
    this.draftState.getTypedDocumentErrorDetail[id] = undefined

    this.draftState.documentTypes[id] = list
  }

  getDocumentTypesFailed(id, error: FetchError, errorDetail?: string) {
    this.draftState.getTypedDocumentProgress[id] = Progress.ERROR
    this.draftState.getTypedDocumentError[id] = error
    this.draftState.getTypedDocumentErrorDetail[id] = errorDetail
  }

  uploadDocumentRequested(id: string, _document_type: string, _file: File) {
    this.draftState.uploadProgress[id] = Progress.WORK
  }

  uploadDocumentSucccess(id: string) {
    this.draftState.uploadProgress[id] = Progress.SUCCESS
  }

  uploadDocumentFailed(id: string, error: FetchError, errorDetail?: string) {
    this.draftState.uploadProgress[id] = Progress.ERROR
    this.draftState.uploadError[id] = error
    this.draftState.uploadErrorDetail[id] = errorDetail
  }

  getDocumentFilesRequested(id: string) {
    this.draftState.getDocumentFilesProgress[id] = Progress.WORK
    this.draftState.getDocumentFilesErrorDetail[id] = undefined
    this.draftState.getDocumentFilesError[id] = null
  }

  getDocumentFilesSucceed(id: string, list) {
    this.draftState.getDocumentFilesProgress[id] = Progress.SUCCESS
    this.draftState.getDocumentFilesErrorDetail[id] = undefined

    this.draftState.documentFiles[id] = list
  }

  getDocumentFilesFailed(id: string, error: FetchError, errorDetail?: string) {
    this.draftState.getDocumentFilesProgress[id] = Progress.ERROR
    this.draftState.getDocumentFilesError[id] = error
    this.draftState.getDocumentFilesErrorDetail[id] = errorDetail
  }

  deleteTypedDocumentRequested(id: string, _document_id: string) {
    this.draftState.deleteTypedDocumentProgress[id] = Progress.WORK
  }

  deleteTypedDocumentSucccess(id) {
    this.draftState.deleteTypedDocumentProgress[id] = Progress.SUCCESS
  }

  deleteTypedDocumentFailed(id) {
    this.draftState.deleteTypedDocumentProgress[id] = Progress.ERROR
  }
}

export const UserActions = createActionCreators(UsersReducer)
export default UserActions
export const reducer = createReducerFunction(UsersReducer, initialState)
