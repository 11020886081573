import { Progress } from '@agro-club/agroclub-shared'

import { AppGlobalState } from 'modules/types'

const getInitProgress = (state: AppGlobalState): Progress => state.auth.initProgress
const getStep = (state: AppGlobalState) => state.auth.step
const getPhone = (state: AppGlobalState) => state.auth.phone
const getCodeError = (state: AppGlobalState) => state.auth.codeError
const getError = (state: AppGlobalState): string => state.auth.error
const location = (state: AppGlobalState): string => `${state.router.location.pathname}`
const profile = (state: AppGlobalState) => state.auth.profile
const permissions = (state: AppGlobalState) => state.auth.profile?.permissions || []
const role = (state: AppGlobalState) => state.auth?.profile?.profile?.role
const profileUpdateProgress = (state: AppGlobalState) => state.auth.profileUpdateProgress
const tokenRequestProgress = (state: AppGlobalState) => state.auth.tokenRequestProgress
const codeRequestedProgress = (state: AppGlobalState) => state.auth.codeRequestedProgress
const isAuthenticated = (state: AppGlobalState) => state.auth.isAuthenticated
const team = (state: AppGlobalState) => state.auth?.profile?.profile?.team

const AuthSelectors = {
  getStep,
  getPhone,
  getCodeError,
  getInitProgress,
  getError,
  location,
  profile,
  profileUpdateProgress,
  tokenRequestProgress,
  codeRequestedProgress,
  role,
  isAuthenticated,
  team,
  permissions,
}

export default AuthSelectors
