import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useAction, useDidMount, usePageQuery, useQuery } from '@agro-club/agroclub-shared'
import { isEmpty } from 'ramda'

import { ResourceHook } from 'modules/types'

import CompanyActions, { ListRequestedParams } from './duck'
import CompanySelectors from './selectors'
import { Company, CompanyExtraData, CompanyListRequestFilter, CompanyListRequestSorting } from './types'

export const useFilterAndSortQuery = () => {
  const query = useQuery()
  const search = query.get('search')
  const created_before = query.get('created_before')
  const created_after = query.get('created_after')
  const sort_field: keyof Company = query.get('sort_field') as keyof Company
  const sort_reversed = query.get('sort_reversed')

  const filter: CompanyListRequestFilter = {
    ...(search && { search }),
    ...(created_before && { created_before }),
    ...(created_after && { created_after }),
  }

  const sorting: CompanyListRequestSorting = {
    ...(sort_field && { sort_field }),
    ...(sort_reversed && { sort_reversed: sort_reversed === 'true' }),
  }

  return { filter, sorting }
}

export const useCompanyList: ResourceHook<Company[], void[]> = () => {
  const progress = useSelector(CompanySelectors.listFetchProgress)
  const list = useSelector(CompanySelectors.list)
  const page = usePageQuery()
  const { filter, sorting } = useFilterAndSortQuery()

  const params: ListRequestedParams = {
    ...(!isEmpty(filter) && { filter }),
    ...(!isEmpty(sorting) && { sorting }),
    ...(page && { page }),
  }

  const fetchAction = useAction(CompanyActions.listRequested, params)
  useDidMount(fetchAction)

  return [progress, list]
}

export const useCompany: ResourceHook<Company, [string]> = (id: string) => {
  const meta = useSelector(state => CompanySelectors.meta(state, id))
  const item = useSelector(state => CompanySelectors.item(state, id))
  const fetchAction = useAction(CompanyActions.itemRequested, id)

  useDidMount(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  })

  return [meta.fetchProgress, item]
}

export const useCompanyById: ResourceHook<Company, [string]> = (id: string) => {
  const meta = useSelector(state => CompanySelectors.meta(state, id))
  const item = useSelector(state => CompanySelectors.item(state, id))
  const fetchAction = useAction(CompanyActions.itemRequested, id)

  useEffect(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  }, [item, id, fetchAction])

  return [meta.fetchProgress, item]
}

export const useExtraData: ResourceHook<CompanyExtraData, [string]> = (id: string) => {
  const meta = useSelector(state => CompanySelectors.extraDataMeta(state, id))
  const item = useSelector(state => CompanySelectors.extraData(state, id))
  const fetchAction = useAction(CompanyActions.extraDataRequested, id)

  useDidMount(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  })

  return [meta.fetchProgress, item]
}
