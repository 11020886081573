import { Input, Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const Label = styled.div`
  ${Typography.body2Default};
  color: ${({ theme }) => theme.color.onSurfaceMidEmphasys};
`

export const InputWrapper = styled.div`
  width: 80px;
`

export const TimeInput = styled(Input)`
  width: 80px;
  height: 32px;
  input {
    height: 32px;
    ${Typography.body2Default};
    padding-left: 3px;
    padding-right: 3px;
  }
`

export const InlineWrapper = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  span[class^='Label'] {
    ${Typography.body2Default};
    color: ${({ theme }) => theme.color.onSurfaceHighEmphasys};
  }
`

export const TimeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Dash = styled.div`
  width: 12px;
  height: 0;
  margin: 0 8px;
  border-top: 1px solid #000000;
`

export const WeekendWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const FieldWrapper = styled.div`
  display: grid;
  grid-template-columns: 128px 1fr;
  row-gap: 16px;
  column-gap: 16px;
  align-items: center;
  padding: 0 0 16px;
`
