import { Progress } from '@agro-club/agroclub-shared'
import { createSelector } from 'reselect'

import { Car } from 'modules/domain/car/types'
import { EntityMetadata } from 'modules/domain/types'
import { AppGlobalState } from 'modules/types'

export enum CarStateUnity {
  User = 'carriers',
  RelevantCarsByDeal = 'relCarsByDeal',
  InvolvedCarsByDeal = 'invCarsByDeals',
}

const dict = (state: AppGlobalState) => state.car.items
const ids = (state: AppGlobalState, unity?: CarStateUnity, id?: string) =>
  unity && id ? state.car[unity][id]?.carsIds ?? [] : state.car.ids
const item = (state: AppGlobalState, id: string): Car | undefined => state.car.items[id]
const meta = (state: AppGlobalState, id?: string): EntityMetadata<Car> =>
  (!!id && state.car.meta[id]) || {
    fetchError: null,
    fetchProgress: Progress.IDLE,
    id,
    removeError: null,
    removeProgress: Progress.IDLE,
    updateError: null,
    updateProgress: Progress.IDLE,
  }
const itemFetchProgress = (state: AppGlobalState) => state.car.itemFetchProgress
const listFetchProgress = (state: AppGlobalState, unity: CarStateUnity, id: string) =>
  state.car[unity][id]?.listFetchProgress
const listFetchError = (state: AppGlobalState, unity: CarStateUnity, id: string) => state.car[unity][id]?.listFetchError
const listFetchErrorDetail = (state: AppGlobalState, unity: CarStateUnity, id: string) =>
  state.car[unity][id]?.listFetchErrorDetail

const list = createSelector(dict, ids, listFetchProgress, (dict, ids, progress) => {
  if (progress === Progress.WORK) {
    return []
  }
  const result: Car[] = []
  for (const id of ids) {
    result.push(dict[id])
  }
  return result
})

const updateProgress = (state: AppGlobalState) => state.car.updateProgress
const removeProgress = (state: AppGlobalState) => state.car.removeProgress
const addProgress = (state: AppGlobalState) => state.car.addProgress

const addErrorDetail = (state: AppGlobalState) => state.car.addErrorDetail
const addErrorFields = (state: AppGlobalState) => state.car.addErrorFields

const files = (state: AppGlobalState, carId: string) => state.car.documents?.[carId]?.files || []
const filesFetchProgress = (state: AppGlobalState, carId: string) =>
  state.deal.documents?.[carId]?.fetchProgress || Progress.IDLE
const filesUploadProgress = (state: AppGlobalState, carId: string) =>
  state.deal.documents?.[carId]?.uploadProgress || Progress.IDLE
const deleteDocumentProgress = (state: AppGlobalState) => state.deal.deleteDocumentProgress

const carDeals = (state: AppGlobalState, carId?: string) => (carId ? state.car.carDeals[carId]?.deals ?? [] : [])
const carDealsProgress = (state: AppGlobalState, carId?: string) =>
  carId ? state.car.carDeals[carId]?.fetchProgress ?? Progress.IDLE : Progress.IDLE

const changeStatusProgress = (state: AppGlobalState, carId?: string) =>
  carId ? state.car[carId]?.changeStatusProgress ?? Progress.IDLE : Progress.IDLE

const CarSelectors = {
  updateProgress,
  removeProgress,
  addProgress,
  item,
  meta,
  itemFetchProgress,
  listFetchProgress,
  listFetchError,
  listFetchErrorDetail,
  list,
  dict,
  ids,

  addErrorDetail,
  addErrorFields,

  files,
  filesFetchProgress,
  filesUploadProgress,
  deleteDocumentProgress,

  carDeals,
  carDealsProgress,

  changeStatusProgress,
}

export default CarSelectors
