import { IconQuestion, Typography, defaultTheme } from '@agro-club/agroclub-shared'
import styled, { StyledProps } from 'styled-components'

import { MarginLevel } from 'modules/domain/deal/types'
import * as ColumnBoard from 'views/components/ColumnBoard/styled'
import { PADDING_LEFT, PADDING_TOP } from 'views/pages/Deal/DealBoard/constants'
import { Coordinates } from 'views/pages/Deal/DealBoard/types'

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`

export const MarginSumWrapper = styled.div`
  min-width: 248px;
  display: flex;
  flex-direction: row;
  color: ${defaultTheme.color.textPrimary}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${Typography.body2Bold}
`

export const Actual = styled.span`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: ${defaultTheme.color.textPrimary} ${Typography.body2Default};
`

export const LongMargin = styled.div<StyledProps<{ $coordinates: Coordinates; $isVisible: boolean }>>`
  position: absolute;
  padding: ${PADDING_TOP}px ${PADDING_LEFT}px;
  color: rgba(60, 60, 67, 0.6);
  background-color: #f1f3f5;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  ${Typography.body2Bold};
  z-index: ${({ $isVisible }) => ($isVisible ? 10 : -1)};
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  top: ${({ $coordinates }) => `${$coordinates.top}px`};
  left: ${({ $coordinates }) => `${$coordinates.left}px`};
`

export const QuestionStyled = styled(IconQuestion)`
  fill: ${({ theme }) => theme.color.secondary300};
  width: 20px;
  height: 20px;
  transition: fill 0.1s linear;

  &:hover {
    fill: ${({ theme }) => theme.color.secondary900};
  }
`

export const ToolTipText = styled.div`
  ${Typography.caption1Default};
`

const marginColors = {
  [MarginLevel.unknown]: '#000000',
  [MarginLevel.low]: '#20B02E',
  [MarginLevel.high]: '#E65200',
}

export const ContentWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
`

export const StyledContainer = styled(ColumnBoard.Container)`
  padding: 16px 24px;
  min-width: 100%;
  position: relative;
`

export const ItemWrapper = styled.div`
  display: grid;
  grid-gap: 8px;
`

export const ClosedReason = styled.div`
  ${Typography.body2Default};
  color: ${({ theme }) => theme.color.secondary300};
  padding-bottom: 1px;
`

export const Company = styled.div`
  ${Typography.footnoteDefault}
`

export const MarginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

export const Margin = styled.div<{ marginLevel: MarginLevel }>`
  ${Typography.headlineBold};
  color: ${({ marginLevel }) => marginColors[marginLevel] ?? MarginLevel.unknown};
`

export const ActualMargin = styled.div`
  display: flex;
  align-items: center;
  ${Typography.body2Bold};
  color: ${({ theme }) => theme.color.textPrimary};
`

export const MarginInTransit = styled.div`
  min-width: 248px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: ${defaultTheme.color.textSecondary};
  ${Typography.body2Bold}
`

export const QuestionWrapper = styled.div`
  display: flex;
  margin-left: 5px;
`

export const Product = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${Typography.body2Default}
`

export const Item = styled(ColumnBoard.Item)<StyledProps<{ $isActive?: boolean }>>`
  padding: 12px 14px 12px 12px;
  cursor: pointer;
  background-color: ${({ theme, $isActive }) => ($isActive ? theme.color.primary25 : theme.color.onPrimaryLight)};
  transition: background-color 0.1s;

  &:hover {
    background-color: ${({ theme }) => theme.color.primary25};
  }
`

export const Badges = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`

export const ColumnHeader = styled(ColumnBoard.Header)<StyledProps<{ locale: boolean }>>`
  min-height: ${({ locale }) => (locale ? '90px' : 'none')};
  align-content: center;
  flex-flow: row wrap;
`

export const HeaderTitle = styled.div`
  margin-bottom: 2px;
`
