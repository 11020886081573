import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { RangeDatePicker } from '@agro-club/agroclub-shared'
import { formatISO, isValid, parseISO } from 'date-fns'

import formatFilterDate from 'helpers/formatFilterDate'
import useDateFnsLocale from 'hooks/useDateFnsLocale'

import { Filter } from './TableFilters'

type TProps = {
  onChange: ([startDate, endDate]: [string | undefined, string | undefined]) => void
  start?: string
  end?: string
  label: string
}

const dateStringToIso = (date: string) => {
  const parsed = parseISO(date)
  if (isValid(parsed)) {
    return formatISO(parsed)
  }
  return ''
}

export const RangeFilter: React.FC<TProps> = props => {
  const { onChange, start: _start = '', end: _end = '', label } = props
  const { t } = useTranslation('common')
  const locale = useDateFnsLocale()

  const changeDateFormat = useCallback(
    ([startDate, endDate]: [string, string]) => {
      onChange([formatFilterDate(startDate), formatFilterDate(endDate)])
    },
    [onChange],
  )

  const start = useMemo(() => dateStringToIso(_start), [_start])
  const end = useMemo(() => dateStringToIso(_end), [_end])
  return (
    <Filter title={label}>
      <div style={{ width: 234 }}>
        <RangeDatePicker
          onChange={changeDateFormat}
          start={start}
          end={end}
          showCalendarIcon
          reactDatePickerProps={{
            dateFormat: 'P',
            placeholderText: t('rangeDatePlaceholder'),
            locale,
          }}
        />
      </div>
    </Filter>
  )
}
