import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Tabs } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import { CommentType } from 'modules/domain/comment/types'
import { endpoints } from 'modules/endpoints'
import { Comments } from 'views/components/Comments/Comments'
import { RightPanelComments } from 'views/components/Comments/styled'
import { Conditions } from 'views/pages/Logistics/LogisticsRightPanel/Conditions'
import LogisticsCarsPanel from 'views/pages/Logistics/LogisticsRightPanel/LogisticsCarsPanel'

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.color.onPrimaryLight};
  height: 100%;
  width: 700px;
`

const Content = styled.div`
  padding: 0 24px 16px;
  border-top: 1px solid ${props => props.theme.color.secondary50};
  height: calc(100% - 50px);
  overflow: auto;
`

export const LogisticsRightPanel: React.FC = () => {
  const { t } = useTranslation('logisticsRightPanel')
  const { id: dealId } = useParams<{ id: string }>()
  const options = useMemo<{ title: string; value: string }[]>(
    () => [
      { value: 'cars', title: t('cars') },
      { value: 'conditions', title: t('conditions') },
      { value: 'comments', title: t('common:comments') },
    ],
    [t],
  )
  const [activeTab, setActiveTab] = useState<string>(options[0].value)

  return (
    <Wrapper>
      <Tabs options={options} active={activeTab} onChange={val => setActiveTab(val)} />
      <Content>
        {
          {
            cars: <LogisticsCarsPanel />,
            conditions: <Conditions />,
            comments: (
              <RightPanelComments>
                <Comments id={dealId} type={CommentType.deal} url={endpoints.dealComment(dealId)} />
              </RightPanelComments>
            ),
          }[activeTab]
        }
      </Content>
    </Wrapper>
  )
}
