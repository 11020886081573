import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { StylesConfig } from 'react-select'

import {
  CheckboxSelect,
  ReactSelectOption,
  controlStyle,
  menuListStyle,
  menuStyle,
  optionStyle,
} from '@agro-club/agroclub-shared'

import { useAssignees } from 'views/components/AssigneeSelect/useAssignees'
import { Filter } from 'views/components/TableFilters/TableFilters'

export const customSelectStyles: StylesConfig<ReactSelectOption, boolean> = {
  control: (...args) => ({
    ...controlStyle(...args),
    height: 32,
    minHeight: 32,
    minWidth: 180,
    width: 180,
  }),
  menu: base => ({
    ...base,
    ...menuStyle,
    zIndex: 10,
    width: '100%',
    minWidth: 256,
  }),
  menuList: base => ({
    ...base,
    ...menuListStyle,
    maxHeight: '60vh',
    width: '100%',
  }),
  option: base => ({
    ...base,
    ...optionStyle,
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
  }),
}

type Option = {
  id: string
  title: string
}

type Props = {
  onAssigneeChange: (a: string) => void
  onClear: () => void
  values?: Array<Option['id']>
}

export const AssigneesFilter: React.FC<Props> = ({ onAssigneeChange, onClear, values = [] }) => {
  const { t } = useTranslation()
  const [progress, assignees] = useAssignees()

  const options = useMemo<Option[]>(() => {
    const list = assignees.map(assignee => ({
      id: assignee.id,
      title: assignee.short_name || assignee.phone,
    }))
    return [
      {
        id: 'null',
        title: t('common:withoutAssignee'),
      },
      ...list,
    ]
  }, [assignees, t])

  return (
    <Filter title={t('common:assignee')}>
      <CheckboxSelect
        isSearchable
        loadingMessage={t('common:loading')}
        progress={progress}
        options={options}
        selected={values}
        onChange={v => onAssigneeChange(v)}
        customStyles={customSelectStyles}
        onClear={onClear}
        placeholder={t('common:assignees')}
        singleSelected={values.length === 1 ? options?.find(team => team.id === values[0])?.title : undefined}
      />
    </Filter>
  )
}
