import { useCallback, useState } from 'react'

import { Progress } from '@agro-club/agroclub-shared'

import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'

type Callback = (id: string, payload) => Promise<void>

export const useExecutorsEdit = (): [Progress, Callback] => {
  const [progress, setProgress] = useState<Progress>(Progress.IDLE)

  const callback = useCallback((id, payload) => {
    setProgress(Progress.WORK)
    return apiClient
      .put(endpoints.executors(id), payload)
      .then(() => {
        setProgress(Progress.SUCCESS)
      })
      .catch(() => setProgress(Progress.ERROR))
  }, [])

  return [progress, callback]
}
