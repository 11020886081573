import React, { useMemo } from 'react'

import { Spinner } from '@agro-club/agroclub-shared'

import { DocumentFile, DocumentType } from 'modules/domain/types'
import FileItem from 'views/components/FilesByTypes/FileItem'
import FilesGroup from 'views/components/FilesByTypes/FilesGroup'
import { FilesLayout, Loading } from 'views/components/FilesByTypes/styled'

interface Props {
  types: DocumentType[]
  files: DocumentFile[]
  onUpload: (document_type: string, file: File) => void
  onDelete: (document_id: string) => void
  loading?: boolean
}

const FilesByTypes: React.FC<Props> = ({ types = [], files = [], onUpload, onDelete, loading }) => {
  const tree = useMemo(
    () =>
      types.map(type => ({
        ...type,
        files: files.filter(file => file.document_type === type.slug),
      })),
    [types, files],
  )

  const onUploadHandler = (document_type, file) => {
    if (file) {
      onUpload(document_type, file)
    }
  }

  return (
    <FilesLayout>
      {loading && (
        <Loading>
          <Spinner />
        </Loading>
      )}
      {tree.map(item => (
        <FilesGroup key={item.slug} header={item.label} onUpload={file => onUploadHandler(item.slug, file)}>
          {item.files.length > 0 && item.files.map(file => <FileItem key={file.id} file={file} onDelete={onDelete} />)}
        </FilesGroup>
      ))}
    </FilesLayout>
  )
}

export default FilesByTypes
