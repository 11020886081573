import { Progress } from '@agro-club/agroclub-shared'

import { Company } from 'modules/domain/company/types'
import { AddError, Address, EntityMetadata, FetchError, Product, RemoveError, UpdateError } from 'modules/domain/types'
import { User } from 'modules/domain/user/types'
import { Dict } from 'types/generics.d'

export type BidParameter = {
  parameter: {
    id?: string
    name?: string
  }
  condition: keyof typeof ParameterCondition
  condition_label?: string
  parameter_value?: number
  id?: string
}

export type BidState = {
  items: Dict<Bid>
  meta: Dict<EntityMetadata<Bid>>
  ids: string[]
  listFetchProgress: Progress
  listFetchError: FetchError | null
  listFetchErrorDetail?: string
  itemFetchProgress: Progress
  itemFetchError: FetchError | null
  addProgress: Progress
  addError: AddError | null
  addErrorDetail?: string
  addErrorFields?: Record<keyof Bid, string>
  updateProgress: Progress
  updateError: UpdateError | null
  removeProgress: Progress
  removeError: RemoveError | null
  removeErrorDetail?: string

  filter: BidListRequestFilter
  sorting: BidListRequestSorting
  page: number
  total: number
  pageSize: number

  lastAddedId: string | null

  changeStatusProgress: Dict<Progress>

  userBidFilter: UserBidListFilter
  userBidIds: Dict<string[]> // dict by user id
  userBidFetchProgress: Progress
  userBidListFetchError: FetchError | null
}

export enum BidStatus {
  published = 'published',
  archived = 'archived',
}

export type Bid = {
  id: string
  bid_type: 'sale' | 'purchase'
  product: Product
  parameters: BidParameter[]
  parameters_description: string
  status: BidStatus
  last_comment?: {
    text: string
    author: string
    created: string
  } | null
  quantity: number
  quantity_in_bags: number
  created: string
  modified: string
  price_exw: number
  price_cpt: number
  tax_from_exw?: number
  address: Address
  distance: number
  company: Company
  owner: User
  aground_imputs?: AgroundImputs
  tax_regime?: TaxRegimes
  bid_tag?: BidTags
  created_by: {
    id: number
    full_name: string
    phone: string
  }
}

export enum AgroundImputs {
  YES = 'yes',
  NO = 'no',
}

export enum TaxRegimes {
  ACTUAL_PROFIT = 'actual_profit',
  OTHER = 'other',
}

export enum BidTags {
  EXPORT = 'export',
  DEFERRED = 'deferred',
  TAXED = 'taxed',
}

export type BidListAddressFilter = {
  address?: string
  longitude?: number
  latitude?: number
  radius?: number
}

export type BidListRequestFilter = {
  search?: string
  bid_type?: string
  product?: string[]
  status?: string[]
  team?: string[]
  district?: string[]
  region?: string[]
  isPotential?: boolean
} & BidListAddressFilter

export type BidListRequestSorting = {
  sort_field?: keyof Bid
  sort_reversed?: boolean
}

export type UserBidListFilter = {
  product?: string[]
  status?: string[]
  address?: string
}

export enum ParameterCondition {
  lte = '≤',
  gte = '≥',
}

export type BidParameterDTO = {
  parameter?: string
  condition: keyof typeof ParameterCondition
  parameter_value?: number
}

export type BidDTO = {
  company?: string // companyId
  owner?: string // userId
  address?: string // addressId
  product?: string // productId
  price?: number
  quantity?: number
  parameters: BidParameterDTO[]
}
