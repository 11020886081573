import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useAction, useHelmet } from '@agro-club/agroclub-shared'

import CarsActions from 'modules/domain/cars/duck'
import { useCarsFilterAndSortQuery } from 'modules/domain/cars/hooks'
import CarsSelectors from 'modules/domain/cars/selectors'
import TableSearch from 'views/components/TableSearch/TableSearch'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import CarsTable from 'views/pages/Cars/CarsTable/CarsTable'
import * as Header from 'views/ui/Header/Header'

const CarsList: React.FC = () => {
  const { t } = useTranslation(['cars'])
  const { filter } = useCarsFilterAndSortQuery()
  useHelmet({ title: t('carsMetaTitle') })
  const filterUpdated = useAction(CarsActions.filterUpdated)
  const filterState = useSelector(CarsSelectors.filter)

  const onChangeSearch = useCallback(
    (query: string) => {
      filterUpdated({ ...filterState, query })
    },
    [filterUpdated, filterState],
  )

  return (
    <>
      <Layout.Header>
        <Header.Root>
          <Header.Title title={t('list.heading')} />
          <TableSearch
            value={filter.query || filterState.query || ''}
            onChangeSearch={onChangeSearch}
            placeholder={t('list.searchPlaceholder')}
          />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <CarsTable />
      </Layout.Content>
    </>
  )
}

export default CarsList
