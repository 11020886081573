import React from 'react'
import { useTranslation } from 'react-i18next'
import { CellProps } from 'react-table'

import { CallStatistics } from 'modules/domain/callStatistics/types'
import { TextAlignRight } from 'views/pages/CallStatistics/CallStatisticsTable/styled'

const AlignRight: React.FC<CellProps<CallStatistics>> = ({ cell }) => <TextAlignRight>{cell.value}</TextAlignRight>

const Incoming: React.FC<CellProps<CallStatistics>> = ({ cell }) => <TextAlignRight>{cell.value}%</TextAlignRight>
const Outgoing: React.FC<CellProps<CallStatistics>> = ({ cell }) => <TextAlignRight>{cell.value}%</TextAlignRight>

const useColumns = () => {
  const { t } = useTranslation('callStatistics')
  return React.useMemo(
    () => [
      {
        Header: t('columnsTitle.employee'),
        accessor: 'name',
        sortable: true,
        minWidth: 400,
        width: 424,
        headerHorizontalAlign: 'left',
      },
      {
        Header: t('columnsTitle.successful'),
        accessor: 'successful_calls_count',
        sortable: true,
        width: 100,
        headerHorizontalAlign: 'right',
        Cell: AlignRight,
      },
      {
        Header: t('columnsTitle.incoming'),
        accessor: 'incoming_calls_percent',
        sortable: true,
        width: 100,
        Cell: Incoming,
        headerHorizontalAlign: 'right',
      },
      {
        Header: t('columnsTitle.outgoing'),
        accessor: 'outgoing_calls_percent',
        sortable: true,
        width: 104,
        Cell: Outgoing,
        headerHorizontalAlign: 'right',
      },
      {
        Header: t('columnsTitle.per_day'),
        accessor: 'per_day_calls_count',
        sortable: true,
        width: 85,
        headerHorizontalAlign: 'right',
        Cell: AlignRight,
      },
      {
        Header: t('columnsTitle.per_hour'),
        accessor: 'per_hour_calls_count',
        sortable: true,
        width: 75,
        headerHorizontalAlign: 'right',
        Cell: AlignRight,
      },
      {
        Header: t('columnsTitle.average_duration'),
        accessor: 'average_successful_calls_duration',
        sortable: true,
        width: 180,
        headerHorizontalAlign: 'right',
        Cell: AlignRight,
      },
    ],
    [t],
  )
}

export default useColumns
