import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button, IconAdd, SectionTableBody, SectionTableHead, useAction } from '@agro-club/agroclub-shared'

import env from 'env'
import useFormatDate from 'hooks/useFormatDate'
import useFormatNumber from 'hooks/useFormatNumber'
import DealActions from 'modules/domain/deal/duck'
import { useDealShipmentList } from 'modules/domain/deal/hooks'
import DealSelectors from 'modules/domain/deal/selectors'
import { DealShipment } from 'modules/domain/deal/types'
import { ButtonWrapper } from 'views/components/UserRightPanel/UserContact/styled'
import {
  BodyCell,
  ButtonsWrapper,
  DrawerShippingWrapper,
  HeadCell,
  SecondRow,
  SectionTableHeadRowWithPadding,
  SectionTableWithoutBorder,
  Sure,
  TableDeleteButton,
  TableEditButton,
  TableRow,
  TableWrapper,
} from 'views/pages/Deal/DealEdit/styled'
import { ConfirmationTooltip } from 'views/ui/ConfirmationTooltip/ConfirmationTooltip'

import { ShippingItemForm } from './DrawerShippingForm'

export const DealShippingSummary: React.FC<{ dealId: string }> = ({ dealId }) => {
  const { t } = useTranslation(['user', 'common'])
  const list = useSelector(state => DealSelectors.dealShipmentList(state, dealId))
  return <div>{list.length || t('common:no')}</div>
}

export const DealShipping: React.FC<{ dealId: string }> = ({ dealId }) => {
  const { t } = useTranslation(['deal', 'common'])
  const [, list = []] = useDealShipmentList(dealId)
  const [shipmentEditData, setShipmentEditData] = useState<DealShipment>()
  const [isOpen, setOpen] = useState<boolean>(false)
  const [isConfirmVisible, setConfirmVisible] = useState<string>('')
  const deleteAction = useAction(DealActions.shipmentItemDeleteRequested)
  const formatNumber = useFormatNumber()
  const formatDate = useFormatDate()
  const currency = env.REACT_APP_CURRENCY_SYMBOL

  const edit = item => {
    setOpen(true)
    setShipmentEditData(item)
  }

  const close = () => {
    setOpen(false)
    setShipmentEditData(undefined)
  }

  const renderList = () =>
    list.map(item => (
      <>
        <TableRow key={item.id}>
          <BodyCell>{formatDate(item.delivered_at)}</BodyCell>
          <BodyCell textAlign="end">{formatNumber(item.quantity)}</BodyCell>
          <BodyCell textAlign="end">{formatNumber(item.price_logistics, true)}</BodyCell>
          <BodyCell textAlign="end">{formatNumber(item.price_exw, true)}</BodyCell>
          <BodyCell textAlign="end">
            {formatNumber(item.price_cpt, true)}
            <ButtonsWrapper>
              <TableEditButton onClick={() => edit(item)} />
              <ConfirmationTooltip
                visible={isConfirmVisible === item.id}
                placement="bottomRight"
                overlayProps={{
                  header: t('common:removeContactHeader'),
                  body: <Sure>{t('common:removeContact')}</Sure>,
                  okText: t('common:delete'),
                  okAction: () => {
                    deleteAction(dealId, item.id)
                  },
                  cancelText: t('common:cancel'),
                  close: () => {
                    setConfirmVisible('')
                  },
                }}
              >
                <TableDeleteButton onClick={() => setConfirmVisible(item.id)} />
              </ConfirmationTooltip>
            </ButtonsWrapper>
          </BodyCell>
        </TableRow>
        <SecondRow key={`${item.id}-postfix`}>
          <BodyCell colSpan={3} textAlign="end">
            {t('fields.gmv', { currency })}
            &nbsp;—&nbsp;
            {formatNumber(item.gmv)}
            &nbsp;{currency}
          </BodyCell>
          <BodyCell colSpan={2} textAlign="end">
            {t('fields.marginShort', { currency })}
            &nbsp;—&nbsp;
            {formatNumber(item.margin)}
            &nbsp;{currency}
          </BodyCell>
        </SecondRow>
      </>
    ))

  return (
    <DrawerShippingWrapper>
      {list.length > 0 && (
        <TableWrapper>
          <SectionTableWithoutBorder>
            <SectionTableHead>
              <SectionTableHeadRowWithPadding>
                <HeadCell width={90}>{t('fields.dateOfShipment')}</HeadCell>
                <HeadCell textAlign="end">{t('fields.quantityShipment')}</HeadCell>
                <HeadCell textAlign="end">{t('fields.price_logistics_per_bag', { currency })}</HeadCell>
                <HeadCell width={80} textAlign="end">
                  {t('fields.price_exw_short', { currency })}
                </HeadCell>
                <HeadCell width={80} textAlign="end">
                  {t('fields.price_cpt_short', { currency })}
                </HeadCell>
              </SectionTableHeadRowWithPadding>
            </SectionTableHead>
            <SectionTableBody>{renderList()}</SectionTableBody>
          </SectionTableWithoutBorder>
        </TableWrapper>
      )}
      <ButtonWrapper>
        <Button intent="action" filled onClick={() => setOpen(true)} size="small">
          <IconAdd />
          {t('common:addShipment')}
        </Button>
      </ButtonWrapper>
      <ShippingItemForm dealId={dealId} shipment={shipmentEditData} open={isOpen} onClose={close} />
    </DrawerShippingWrapper>
  )
}
