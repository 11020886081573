import { useCallback, useEffect, useRef, useState } from 'react'

export default function useHover(ref) {
  const [value, setValue] = useState(false)
  const innerRef = useRef<HTMLElement | null>(null)
  const refWrapper = ref || innerRef
  const handleMouseMove = useCallback(
    event => {
      const over = refWrapper.current?.contains(event.target as Node)
      setValue(over)
    },
    [refWrapper],
  )
  useEffect(() => {
    document.body.addEventListener('mousemove', handleMouseMove)
    return () => {
      document.body.removeEventListener('mousemove', handleMouseMove)
    }
  }, [handleMouseMove])
  return [refWrapper, value]
}
