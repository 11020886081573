import { IconPencil, Input, SimpleSelect, Typography } from '@agro-club/agroclub-shared'
import styled, { css } from 'styled-components'

import { box } from 'views/styled/common'
import { NumberInput } from 'views/ui/NumberInput/NumberInput'
import { Phone } from 'views/ui/Phone/Phone'

export const TwoColumnLayout = styled.div`
  margin: 16px 24px;
  display: grid;
  grid-template-columns: 500px minmax(480px, 600px);
  grid-gap: 16px;
`

export const FormWrapper = styled.div`
  ${box};
  padding: 16px 16px 12px;
  width: 684px;
`

export const FormGrid = styled.div`
  display: grid;
  grid-template-columns: min(147px) 1fr;
  grid-gap: 12px;
`
export const FormLabel = styled.div`
  display: flex;
  height: 32px;
  align-items: center;
  ${Typography.body2Default};
  color: ${props => props.theme.color.secondary300};
`
export const LicenceInput = styled(Input)`
  width: 115px;
`
export const SideHeightInput = styled(NumberInput)`
  width: 80px;
`
export const StyledSelect = styled(SimpleSelect)`
  width: auto;
  margin-right: 24px;
`

export const ButtonsWrapper = styled.div`
  & > :first-child {
    margin-right: 16px;
  }
`

export const DriverWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 16px;
  align-items: flex-start;
  &:first-child {
    margin-top: 0;
  }
`

export const DriversList = styled.div`
  position: relative;
  min-height: 300px;
`

export const DriverInfo = styled.div`
  flex-grow: 1;
`

export const DriverName = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px;
`

export const AddDriverInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 164px;
  grid-gap: 16px;
  margin: 24px 0 16px;
`

export const DriversFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  & > :first-child {
    min-height: 200px;
  }
`

export const TwoColumnLayoutFlex = styled.div`
  margin: 16px 12px;
  display: flex;
  align-items: flex-start;

  & > * {
    flex: 1 1 100%;
    margin: 0 12px;
  }
`

export const TabsContainer = styled.div`
  height: 72px;
  display: flex;
  align-items: flex-end;
`

export const CarLabel = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 16px 0 4px;
  color: ${props => props.theme.color.textSecondary};

  &:first-child {
    margin-top: 0;
  }
`
export const CarValue = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.textPrimary};
`

export const DriverPhone = styled(Phone)`
  font-size: 14px;
`

const buttons = css`
  fill: ${props => props.theme.color.secondary200};
  cursor: pointer;
  transition: fill 0.1s ease-in;

  &:hover {
    fill: ${props => props.theme.color.secondary300};
  }
`

export const DriverButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const RemoveDriverBtn = styled.div`
  & svg {
    ${buttons};
    height: 11.66px;
    width: 11.66px;
  }
`

export const UpdateDriverBtn = styled(IconPencil)`
  ${buttons};
  margin-right: 22px;

  width: 15px;
  height: 15px;
`

export const FormButtonsWrapper = styled.div`
  & > :first-child {
    margin-right: 16px;
  }
`

export const LoaderWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LicenseNumberWrapper = styled.div`
  display: grid;
  column-gap: 12px;
  grid-template-columns: 153px max-content;
  align-items: center;
`

export const TrailerWrapper = styled.div`
  width: 153px;
`
