import formatSortParam from 'helpers/formatSortParam'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { ListResponse } from 'types/api.d'

import {
  LogisticsDeal,
  LogisticsDealDTO,
  LogisticsDealListRequestFilter,
  LogisticsDealListRequestSorting,
} from './types'

export const getList = (
  filter: LogisticsDealListRequestFilter,
  sorting: LogisticsDealListRequestSorting,
  page: number,
  pageSize: number,
) =>
  apiClient.get<ListResponse<LogisticsDeal>>(endpoints.logisticsDeal(), {
    ...filter,
    sort: formatSortParam(sorting),
    page,
    page_size: pageSize,
  })

export const getItem = (id: string) => apiClient.get<LogisticsDeal>(endpoints.logisticsDeal(id))

export const addItem = (dto: LogisticsDealDTO) => apiClient.post<LogisticsDeal>(endpoints.logisticsDeal(), dto)

export const updateItem = (id: string, dto: Partial<LogisticsDealDTO>) =>
  apiClient.put<LogisticsDeal>(endpoints.logisticsDeal(id), dto)

export const removeItem = (id: string) => apiClient.delete<LogisticsDeal>(endpoints.logisticsDeal(id))

export const changeStatus = (id: string, new_logistics_status: string) =>
  apiClient.post<unknown>(endpoints.logisticChangeStatus(id), { new_logistics_status })
