import formatSortParam from 'helpers/formatSortParam'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { ListResponse } from 'types/api.d'

import {
  Company,
  CompanyDTO,
  CompanyExtraData,
  CompanyExtraDataDTO,
  CompanyListRequestFilter,
  CompanyListRequestSorting,
} from './types'

export const getList = (
  filter: CompanyListRequestFilter,
  sorting: CompanyListRequestSorting,
  page: number,
  pageSize: number,
) =>
  apiClient.get<ListResponse<Company>>(endpoints.company(), {
    ...filter,
    sort: formatSortParam(sorting),
    page,
    page_size: pageSize,
  })

export const getItem = (id: string) => apiClient.get<Company>(endpoints.company(id))

export const addItem = (dto: CompanyDTO) => apiClient.post<Company>(endpoints.company(), dto)

export const updateItem = (id: string, dto: Partial<CompanyDTO>) => apiClient.put<Company>(endpoints.company(id), dto)

export const removeItem = (id: string) => apiClient.delete<Company>(endpoints.company(id))

export const getExtraData = (id: string) => apiClient.get<CompanyExtraData>(endpoints.companyExtraData(id))

export const putExtraData = (companyId: string, id: string, dto: CompanyExtraDataDTO) =>
  apiClient.put<CompanyExtraData>(endpoints.companyExtraData(companyId, id), dto)
