import React, { VFC, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button, Input, Progress, SimpleSelect, useAction } from '@agro-club/agroclub-shared'
import { useFormik } from 'formik'

import { useNotificationProgress } from 'hooks/useNotificationProgress'
import usePrevious from 'hooks/usePrevious'
import CarrierActions from 'modules/domain/carrier/duck'
import CarrierSelectors from 'modules/domain/carrier/selectors'
import { UnloadingType } from 'modules/domain/types'
import { FieldWrapper, TwoFields } from 'views/components/AddTrailer/styled'
import { CancelButton } from 'views/styled/common'
import { FormItem } from 'views/ui/FormItem/FormItem'
import { ModalSmall } from 'views/ui/ModalSmall/ModalSmall'
import { NumberInput } from 'views/ui/NumberInput/NumberInput'

type EditableFields = {
  licence_number: string
  unloading_type: UnloadingType | null
  side_height: number | null
  brand_model: string
}

type Props = {
  isOpen: boolean
  onCancel: () => void
  owner: string
}

export const TrailerModal: VFC<Props> = ({ isOpen, owner, onCancel }) => {
  const { t } = useTranslation('vehicles')
  const createAction = useAction(CarrierActions.trailerAddRequested)
  const progress = useSelector(CarrierSelectors.trailerAddProgress)
  const errorFields = useSelector(CarrierSelectors.trailerAddErrorFields)
  const resetErrors = useAction(CarrierActions.trailerResetAddErrors)
  const prevProgress = usePrevious(progress)
  const prevIsOpen = usePrevious(isOpen)
  useNotificationProgress(progress, t('trailerAddSuccess'))

  const formik = useFormik<EditableFields>({
    initialValues: {
      licence_number: '',
      unloading_type: null,
      side_height: null,
      brand_model: '',
    },
    enableReinitialize: false,
    validateOnBlur: false,

    onSubmit: () => {
      const { licence_number, unloading_type, side_height, brand_model } = formik.values
      createAction({
        owner,
        licence_number,
        unloading_type,
        side_height,
        brand_model,
      })
    },
  })

  useEffect(() => {
    if (prevIsOpen && !isOpen) {
      formik.resetForm()
      resetErrors()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevIsOpen, isOpen])

  useEffect(() => {
    if (prevProgress === Progress.WORK && progress === Progress.SUCCESS) {
      formik.resetForm()
      resetErrors()
      onCancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress, prevProgress])

  const content = useMemo(
    () => (
      <FieldWrapper>
        <TwoFields>
          <label htmlFor="license_number">{t('form.licence_number')}</label>
          <FormItem
            fieldName="licence_number"
            errorFields={errorFields}
            render={additionalProps => (
              <Input
                inputSize="small"
                placeholder={t('form.trailerLicenceNumberPlaceholder')}
                onChange={(_event, value) => {
                  formik.setFieldValue('licence_number', value.replace(/\s/g, '').toUpperCase())
                }}
                value={formik.values.licence_number}
                {...additionalProps}
              />
            )}
          />
        </TwoFields>
        <TwoFields>
          <label htmlFor="brand_model">{t('form.trailer_model')}</label>
          <FormItem
            fieldName="brand_model"
            errorFields={errorFields}
            render={additionalProps => (
              <Input
                inputSize="small"
                value={formik.values.brand_model}
                onChange={(event, value) => formik.setFieldValue('brand_model', value)}
                {...additionalProps}
              />
            )}
          />
        </TwoFields>
        <TwoFields>
          <label htmlFor="unloading_type">{t('form.unloading_type')}</label>
          <FormItem
            fieldName="unloading_type"
            errorFields={errorFields}
            render={additionalProps => (
              <SimpleSelect
                size="small"
                value={formik.values.unloading_type}
                onChange={value => formik.setFieldValue('unloading_type', value)}
                options={Object.values(UnloadingType).map(v => ({
                  id: v,
                  title: t(`vehicles:unloadingType.${v}`),
                }))}
                {...additionalProps}
              />
            )}
          />
        </TwoFields>
        <TwoFields>
          <label htmlFor="side_height">{t('form.side_height')}</label>
          <FormItem
            fieldName="side_height"
            errorFields={errorFields}
            render={additionalProps => (
              <NumberInput
                inputSize="small"
                value={formik.values.side_height ?? undefined}
                onChange={value => formik.setFieldValue('side_height', value)}
                {...additionalProps}
              />
            )}
          />
        </TwoFields>
      </FieldWrapper>
    ),
    [errorFields, formik, t],
  )

  return (
    <ModalSmall
      isOpen={isOpen}
      close={onCancel}
      title={t('newTrailer')}
      content={content}
      footer={
        <>
          <Button
            onClick={() => {
              formik.dirty && formik.submitForm().catch()
            }}
            disabled={!formik.dirty}
            intent="primary"
            progress={progress}
            filled
          >
            {t('common:add')}
          </Button>
          <CancelButton onClick={onCancel} intent="minor-action">
            {t('common:cancel')}
          </CancelButton>
        </>
      }
    />
  )
}
