import { all, call, put, takeLatest } from 'redux-saga/effects'

import SpecificationActions from 'modules/domain/specification/duck'
import * as managers from 'modules/domain/specification/managers'
import { RequestError } from 'modules/errors'

export function* addSpecification({ payload: data }: ReturnType<typeof SpecificationActions.AddRequested>) {
  try {
    yield call(managers.addSpecification, data)
    yield put(SpecificationActions.AddSucceed())
  } catch (err) {
    const { type, detail, errors } = RequestError.parseError(err)
    yield put(SpecificationActions.AddError(type, detail, errors))
  }
}

export function* uploadDocument({ payload: file }: ReturnType<typeof SpecificationActions.uploadDocumentRequested>) {
  try {
    const response = yield call(managers.uploadFiles, file)
    yield put(SpecificationActions.uploadDocumentSucccess(response))
  } catch (err) {
    const { type } = RequestError.parseError(err)
    const detail = err instanceof RequestError ? err?.errors?.uploaded_file?.[0] : undefined
    yield put(SpecificationActions.uploadDocumentFailed(type, detail))
  }
}

function* SpecificationSaga() {
  yield all([takeLatest(SpecificationActions.AddRequested.type, addSpecification)])
  yield all([takeLatest(SpecificationActions.uploadDocumentRequested.type, uploadDocument)])
}

export default SpecificationSaga
