import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IconArrowLeftBold } from '@agro-club/agroclub-shared'

import { Bid } from 'modules/domain/bid/types'
import { DealStatus } from 'modules/domain/collection/types'
import { DealGroup } from 'modules/domain/userBid/types'
import { Deal } from 'views/pages/User/UserDetail/UserColumnBoard/Deal'
import { ToggleArchived } from 'views/styled/ToggleArchived'

type Props = {
  deals: DealGroup['deals']
  activeBid?: Bid
  statuses: DealStatus[]
}

export const ClosedDeals: React.FC<Props> = ({ deals, activeBid, statuses }) => {
  const [open, setOpen] = useState<boolean>(false)
  const closedDeals = useMemo(() => deals.filter(deal => !!deal.closed_at), [deals])

  const { t } = useTranslation('deal')

  if (!closedDeals || !closedDeals.length) {
    return null
  }

  return (
    <>
      <ToggleArchived
        $open={open}
        onClick={() => {
          setOpen(prev => !prev)
        }}
      >
        {open ? t('hideClosed') : t('showClosed')} <IconArrowLeftBold />
      </ToggleArchived>
      {open && closedDeals.map(deal => <Deal key={deal.id} {...{ deal, activeBid, statuses }} />)}
    </>
  )
}
