import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { generatePath } from 'react-router-dom'
import { ColumnInstance, Row, useTable } from 'react-table'

import {
  AdvancedHeadCell,
  Progress,
  Table,
  TableBody,
  TableBodyRow,
  TableHead,
  TableHeadCellProps,
  TableHeadRow,
  TableNoData,
  useAction,
} from '@agro-club/agroclub-shared'
import { isNil } from 'ramda'

import useLocationFrom from 'hooks/useLocationFrom'
import CarsActions from 'modules/domain/cars/duck'
import { useCarsList } from 'modules/domain/cars/hooks'
import CarsSelectors from 'modules/domain/cars/selectors'
import { Cars, CarsListRequestFilter } from 'modules/domain/cars/types'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import CarsFilter from 'views/pages/Cars/CarsTable/CarsFilter'
import useColumns from 'views/pages/Cars/CarsTable/useColumns'
import CarsRoutes from 'views/pages/Cars/routes'

const CarsSortableHeadCell = AdvancedHeadCell<keyof Cars>()

type CarsTableProps = {
  showTripsCount?: boolean
  dealIdForAddToTrip?: string
  defaultFilter?: CarsListRequestFilter
}

const getPathname = (row: Row<Cars>) => {
  const id = row?.original?.id
  return id ? generatePath(CarsRoutes.Detail, { id }) : ''
}

type ColumnProps = ColumnInstance<Cars> & TableHeadCellProps

const CarsTable: React.FC<CarsTableProps> = ({ showTripsCount, dealIdForAddToTrip, defaultFilter }) => {
  const { t } = useTranslation(['cars', 'common'])
  const [progress, data] = useCarsList()

  const sortingUpdated = useAction(CarsActions.sortingUpdated)
  const listRequested = useAction(CarsActions.listRequested)
  const filterUpdated = useAction(CarsActions.filterUpdated)
  const filterState = useSelector(CarsSelectors.filter)
  const total = useSelector(CarsSelectors.total)
  const pages = useSelector(CarsSelectors.pages)
  const page = useSelector(CarsSelectors.page)
  const pageSize = useSelector(CarsSelectors.pageSize)
  const { sort_field, sort_reversed } = useSelector(CarsSelectors.sorting)
  const visibleColumns = useColumns({ showTripsCount, dealIdForAddToTrip })
  const from = useLocationFrom()

  useEffect(() => {
    defaultFilter && filterUpdated(defaultFilter)
  }, [defaultFilter, filterUpdated])

  const { columns, rows, prepareRow } = useTable<Cars>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore weird issue with react-table typings — having more then 26 fields in type causes TS error
    columns: visibleColumns,
    data: data as Cars[],
  })

  const setPage = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  const handleClearFilters = useCallback(() => {
    filterUpdated({})
  }, [filterUpdated])

  const isFilterApplied = Object.values(filterState).some(value => !isNil(value))

  return (
    <TComponents.Wrapper>
      <CarsFilter />
      <Table total={total} pages={pages} pageSize={pageSize} currentPage={page} onSetPage={setPage}>
        <TableHead>
          <TableHeadRow>
            {columns.map((column: ColumnProps) => (
              <CarsSortableHeadCell
                zIndex={1}
                key={column.getHeaderProps().key}
                id={column.id as keyof Cars}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                sortable={column.sortable}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                hidden={column.hidden}
                sortField={sort_field}
                sortDesc={sort_reversed}
                onChange={sortingUpdated}
                headerHorizontalAlign={column.headerHorizontalAlign as TableHeadCellProps['headerHorizontalAlign']}
              >
                {column.render('Header')}
              </CarsSortableHeadCell>
            ))}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map(row => {
            prepareRow(row)
            const { key, ...props } = row.getRowProps()
            const pathname = getPathname(row)
            return (
              <TableBodyRow key={key} {...props}>
                {row.cells.map(cell => {
                  const { key, ...props } = cell.getCellProps()
                  return (
                    <TComponents.RelativeTableBodyCell key={key} {...props}>
                      {!!pathname && <TComponents.TableCellLink to={{ pathname, state: { from } }} />}
                      {cell.render('Cell')}
                    </TComponents.RelativeTableBodyCell>
                  )
                })}
              </TableBodyRow>
            )
          })}
          <TableNoData
            progress={progress}
            isEmpty={!rows.length}
            colSpan={visibleColumns.length}
            loading={<TComponents.Spinner />}
          >
            <div>{isFilterApplied ? t('list.emptyFilterMsg') : t('list.emptyMsg')}</div>
            {isFilterApplied && progress !== Progress.WORK && (
              <TComponents.ClearButton intent="cancel" size="small" onClick={handleClearFilters}>
                {t('common:resetAllFilters')}
              </TComponents.ClearButton>
            )}
          </TableNoData>
        </TableBody>
      </Table>
    </TComponents.Wrapper>
  )
}

export default CarsTable
