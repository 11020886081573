import { Bid } from 'modules/domain/bid/types'
import { Deal } from 'modules/domain/deal/types'
import { LogisticsDeal } from 'modules/domain/logisticsDeal/types'
import { Address } from 'modules/domain/types'

const normalizeBidAddress = (item: Deal | LogisticsDeal) => {
  const saleAddress = { ...((item.sale_bid as Bid).address as Address) }
  const purchaseAddress = { ...((item.purchase_bid as Bid).address as Address) }

  const normalizedItem = { ...item }

  normalizedItem.sale_bid = {
    ...normalizedItem.sale_bid,
    address: { id: saleAddress.id },
  }
  normalizedItem.purchase_bid = {
    ...normalizedItem.purchase_bid,
    address: { id: purchaseAddress.id },
  }

  return { saleAddress, purchaseAddress, normalizedItem }
}

export default normalizeBidAddress
