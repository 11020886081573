import React from 'react'
import { useTranslation } from 'react-i18next'

import { IconWrapper, ResetFilterWrapper, StyledCross, StyledResetButton } from 'views/components/TableFilters/styles'

export const ResetFilterButton: React.FC<{
  isFilterApplied: boolean
  handleClearFilters: () => void
}> = ({ isFilterApplied, handleClearFilters }) => {
  const { t } = useTranslation('common')
  return isFilterApplied ? (
    <ResetFilterWrapper>
      <StyledResetButton intent="minor-action" onClick={handleClearFilters}>
        <IconWrapper>
          <StyledCross />
        </IconWrapper>
        {t('resetAllFilters')}
      </StyledResetButton>
    </ResetFilterWrapper>
  ) : null
}
