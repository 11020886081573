import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Progress } from '@agro-club/agroclub-shared'

import * as S from 'views/pages/Carrier/CarrierDetail/UserCars/styled'

export const CarFormButtons: React.FC<{
  progress: Progress
  disableSubmitButton: boolean
  onSubmitClick: () => void
  goBack: () => void
}> = ({ progress, disableSubmitButton, onSubmitClick, goBack }) => {
  const { t } = useTranslation('vehicles')

  return (
    <S.ButtonsWrapper>
      <Button
        filled
        progress={progress}
        disabled={disableSubmitButton}
        size="small"
        intent="primary"
        onClick={onSubmitClick}
      >
        {t('common:save')}
      </Button>
      <Button size="small" intent="primary" onClick={goBack}>
        {t('common:cancel')}
      </Button>
    </S.ButtonsWrapper>
  )
}
