import { Link } from 'react-router-dom'
import { StylesConfig } from 'react-select'

import {
  ReactSelectOption,
  Typography,
  controlStyle,
  menuListStyle,
  menuStyle,
  optionStyle,
} from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import { Phone } from 'views/ui/Phone/Phone'

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.color.primary600};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

export const ClickableLink = styled.a`
  color: ${({ theme }) => theme.color.primary600};
  cursor: pointer;
  display: block;
  &:hover {
    text-decoration: underline;
  }
`

export const BoldText = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`

const selectStyles = () => ({
  menu: base => ({
    ...base,
    ...menuStyle,
    zIndex: 10,
    width: '100%',
  }),
  menuList: base => ({
    ...base,
    ...menuListStyle,
    maxHeight: '60vh',
    width: '100%',
    overflowX: 'hidden',
  }),
  option: base => ({
    ...base,
    ...optionStyle,
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
  }),
})

export const customSelectStyles: StylesConfig<ReactSelectOption, boolean> = {
  control: (...args) => ({
    ...controlStyle(...args),
    height: '32px',
    minHeight: '32px',
    minWidth: '180px',
  }),
  ...selectStyles(),
}

export const customSelectCoordinarorStyles: StylesConfig<ReactSelectOption, boolean> = {
  control: (...args) => ({
    ...controlStyle(...args),
    height: '32px',
    minHeight: '32px',
    minWidth: '280px',
  }),
  ...selectStyles(),
}

export const InputWrapper = styled.div`
  & > * > * {
    height: 32px;
  }
`

export const PhoneLink = styled(Phone)`
  display: block;
`

export const StyledText = styled.div`
  color: ${({ theme }) => theme.color.onSurfaceMidEmphasys};
  ${Typography.caption1Default};
`
