import { Button, IconBin, Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const AddFileButton = styled(Button)`
  width: 24px;
  min-width: 24px;
  height: 24px;
  padding: unset;
  margin-left: 8px;
  border-radius: 2px;

  & svg {
    fill: ${({ theme }) => theme.color.accentApprove600};
  }

  &,
  &:focus {
    background-color: ${({ theme }) => theme.color.accentApprove400}29;
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.accentApprove100};
  }

  &:active {
    background-color: ${({ theme }) => theme.color.accentApprove600};

    & svg {
      fill: ${({ theme }) => theme.color.onPrimaryLight};
    }
  }

  &:disabled {
    background-color: ${({ theme }) => theme.color.secondary50};

    & svg {
      fill: ${({ theme }) => theme.color.secondary200};
    }
  }
`

export const RemoveFile = styled(IconBin)`
  cursor: pointer;

  & > path {
    fill: ${({ theme }) => theme.color.secondary300};
  }

  &:hover > path {
    fill: ${({ theme }) => theme.color.outlinePrimary};
  }

  opacity: 0;
`

export const FilesHeader = styled.div`
  display: flex;
  align-items: center;
  ${Typography.body2Default};
`

export const FilesStyled = styled.div`
  & + & {
    margin-top: 20px;
  }
`

export const FilesContent = styled.div`
  margin-top: 4px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.color.secondary50};
`

export const FileItemStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px;
  ${Typography.body2Default};

  &:hover {
    background-color: rgba(3, 123, 255, 0.08);

    ${RemoveFile} {
      opacity: 1;
    }
  }

  & + & {
    border-top: 1px solid ${({ theme }) => theme.color.secondary50};
  }
`

export const FileInput = styled.input`
  display: none;
`

export const Link = styled.a`
  color: ${({ theme }) => theme.color.primary600};
  max-width: calc(100% - 20px);
`

export const FilesLayout = styled.div`
  position: relative;
`

export const Loading = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.9);
  z-index: 1;
`

export const SmallText = styled.div`
  ${Typography.caption2Default};
`

export const Sure = styled.div`
  ${Typography.body2Default};
`

export const Ellipsis = styled.div`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`
