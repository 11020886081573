import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Progress, useHelmet } from '@agro-club/agroclub-shared'

import useUserName from 'hooks/useUserName'
import { useUser } from 'modules/domain/user/hooks'
import { BidAdd } from 'views/pages/Bid/BidAdd/BidAdd'

export const UserBidAdd: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const [progress, user] = useUser(id)

  const { t } = useTranslation()

  const userName = useUserName(user)

  useHelmet({ title: `${t('bid:form.add')} - ${userName}` })

  if (progress === Progress.WORK) {
    return null
  }

  return <BidAdd user={user} />
}
