import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Button, Checkbox } from '@agro-club/agroclub-shared'

import usePrevious from 'hooks/usePrevious'
import UserAddressActions from 'modules/domain/userAddress/duck'
import UserAddressSelectors from 'modules/domain/userAddress/selectors'
import { UserAddress } from 'modules/domain/userAddress/types'
import { LegalTypeSelect } from 'views/components/LegalTypeSelect/LegalTypeSelect'
import AddressForm from 'views/components/Maps/AddressForm'
import { FormItem } from 'views/ui/FormItem/FormItem'
import { ModalCommon } from 'views/ui/ModalCommon/ModalCommon'

type Props = {
  isOpen: boolean
  close: () => void
  userId: string
}

export const AddressModal: React.FC<Props> = props => {
  const dispatch = useDispatch()
  const { isOpen, close, userId } = props
  const [address, setAddress] = useState<Partial<UserAddress> | undefined>()

  const prevOpen = usePrevious(isOpen)
  useEffect(() => {
    if (!isOpen && prevOpen) {
      setAddress(undefined)
    }
  }, [isOpen, prevOpen, setAddress])

  const progress = useSelector(state => UserAddressSelectors.addProgress(state, userId))
  const { t } = useTranslation()
  return (
    <ModalCommon
      isOpen={isOpen}
      close={close}
      title={t('common:address')}
      content={
        <>
          <FormItem
            errorFields={{}}
            // TODO fix error fild name
            fieldName="addresses"
            render={additionalProps => (
              <LegalTypeSelect
                onChange={val => {
                  setAddress(prev => ({
                    ...prev,
                    legal_type: val,
                  }))
                }}
                value={address?.legal_type}
                id={userId}
                {...additionalProps}
              />
            )}
          />
          <Checkbox
            isChecked={address?.is_main}
            onChange={(_value, isChecked) =>
              setAddress(prev => ({
                ...prev,
                is_main: isChecked,
              }))
            }
            label={t('address:main')}
          />
          <AddressForm
            placeholder={t('user:form.addressPlaceholder')}
            noOptionsMessage={() => t('user:form.noAddressOptionMessage')}
            focusOnMount
            onChange={({ coords, address, geoObject }) => {
              setAddress(prev => ({
                ...prev,
                latitude: coords?.[0],
                longitude: coords?.[1],
                address,
                geo_object: geoObject,
              }))
            }}
          />
        </>
      }
      footer={
        <>
          <Button
            onClick={() => {
              if (address) {
                dispatch(UserAddressActions.addRequested(userId, { ...address }))
                setTimeout(() => close(), 200)
              }
            }}
            disabled={!address}
            intent="primary"
            progress={progress}
            filled
          >
            {t('common:save')}
          </Button>
          <Button onClick={close} intent="minor-action" filled>
            {t('common:cancel')}
          </Button>
        </>
      }
    />
  )
}
