import React from 'react'

import { Button, ButtonFullProps, IconBin } from '@agro-club/agroclub-shared'
import styled, { useTheme } from 'styled-components'

const StyledButton = styled(Button)`
  padding: 0;
  width: 24px;
  height: 24px;
  & svg {
    fill: ${({ theme }) => theme.color.secondary300};
  }
  &:hover svg {
    fill: ${({ theme }) => theme.color.outlineSecondary};
  }
`
export const BinButton: React.FC<Partial<ButtonFullProps>> = props => {
  const theme = useTheme()
  return (
    <StyledButton size="small" intent="minor-action" {...props}>
      <IconBin fill={theme.color.secondary300} />
    </StyledButton>
  )
}
