import { Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const NoWrap = styled.span`
  white-space: nowrap;
`

export const Wrap = styled.span`
  word-break: break-word;
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  & > *:last-child {
    opacity: 0;
  }
  &:hover > *:last-child {
    opacity: 1;
  }
  &:hover {
    background: ${props => props.theme.color.primary8a};
  }
  border-left: 1px solid ${props => props.theme.color.secondary50};
  border-right: 1px solid ${props => props.theme.color.secondary50};
  border-top: 1px solid ${props => props.theme.color.secondary50};
  padding: 8px 12px;
  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom: 1px solid ${props => props.theme.color.secondary50};
    margin-bottom: 8px;
  }
  & button {
    background: transparent;
    &:hover {
      background: transparent;
      svg {
        fill: ${({ theme }) => theme.color.primary600};
      }
    }
  }
`

export const Address = styled.div`
  ${Typography.body2Default};
`

export const WarehouseWrapper = styled.div`
  ${Typography.body2Default};
  color: ${props => props.theme.color.textSecondary};
`
