import { Progress } from '@agro-club/agroclub-shared'
import { createSelector } from 'reselect'

import { LIST_PAGE_SIZE } from 'modules/constants'
import { CarTrip } from 'modules/domain/carTrip/types'
import { AppGlobalState } from 'modules/types'

const dict = (state: AppGlobalState) => state.carTrips.items
const ids = (state: AppGlobalState, carId?: string, dealId?: string) => {
  if (!carId) return []
  if (dealId) return state.carTrips.tripsByCarDeal[carId]?.[dealId] ?? []
  return state.carTrips.tripsByCar[carId] ?? []
}

const listFetchProgress = (state: AppGlobalState) => state.carTrips.listFetchProgress
const listFetchError = (state: AppGlobalState) => state.carTrips.listFetchError
const listFetchErrorDetail = (state: AppGlobalState) => state.carTrips.listFetchErrorDetail
const page = (state: AppGlobalState) => state.carTrips.page
const pages = (state: AppGlobalState) => Math.ceil(state.carTrips.total / LIST_PAGE_SIZE)
const total = (state: AppGlobalState) => state.carTrips.total

const list = createSelector(dict, ids, listFetchProgress, (dict, ids, progress) => {
  if (progress === Progress.WORK) {
    return []
  }
  const result: CarTrip[] = []
  for (const id of ids) {
    result.push(dict[id])
  }
  return result
})

const CarTripSelectors = {
  listFetchProgress,
  listFetchError,
  listFetchErrorDetail,
  list,
  dict,
  ids,
  page,
  pages,
  total,
}

export default CarTripSelectors
