import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { generatePath } from 'react-router-dom'
import { ColumnInstance, Row, useTable } from 'react-table'

import {
  Progress,
  Table,
  TableBody,
  TableBodyRow,
  TableHead,
  TableHeadCellProps,
  TableHeadRow,
  TableNoData,
  useAction,
  usePersistentScroll,
} from '@agro-club/agroclub-shared'
import { isNil } from 'ramda'

import useLocationFrom from 'hooks/useLocationFrom'
import BidActions from 'modules/domain/bid/duck'
import { useBidList } from 'modules/domain/bid/hooks'
import BidSelectors from 'modules/domain/bid/selectors'
import { Bid, BidListAddressFilter } from 'modules/domain/bid/types'
import { BidKind, PotentialBid } from 'modules/domain/potentialBid/types'
import { BidStatusFilter } from 'views/components/BidStatusFilter/BidStatusFilter'
import { BidsSortableHeadCell } from 'views/components/CommonBidTable/CommonBidTable'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import { ProductFilter } from 'views/components/ProductFilter/ProductFilter'
import { DistrictFilter } from 'views/components/TableFilters/RegionDistrict/DistrictFilter'
import { useOnChangeDistrict, useOnChangeRegion } from 'views/components/TableFilters/RegionDistrict/hooks'
import { RegionFilter } from 'views/components/TableFilters/RegionDistrict/RegionFilter'
import { ResetFilterButton } from 'views/components/TableFilters/ResetFilterButton'
import { TeamFilter } from 'views/components/TeamFilter/TeamFilter'
import { AddressFilter } from 'views/pages/Bid/BidTable/AddressFilter'
import { PotentialBidFilter } from 'views/pages/Bid/BidTable/PotentialBidFilter'
import useColumns from 'views/pages/Bid/BidTable/useColumns'
import BidRoutes from 'views/pages/Bid/routes'

const getPathname = (row: Row<Bid | PotentialBid>) => {
  const { id, status } = row.original
  const route = status === BidKind.potential ? BidRoutes.PotentialDetail : BidRoutes.Detail
  return generatePath(route, { id })
}

type ColumnProps = ColumnInstance<Bid | PotentialBid> & TableHeadCellProps

const BidTable: React.FC = () => {
  const { t } = useTranslation('bid')
  const [progress, data] = useBidList()
  const sortingUpdated = useAction(BidActions.sortingUpdated)
  const listRequested = useAction(BidActions.listRequested)
  const filterUpdated = useAction(BidActions.filterUpdated)
  const filterState = useSelector(BidSelectors.filter)
  const total = useSelector(BidSelectors.total)
  const pages = useSelector(BidSelectors.pages)
  const page = useSelector(BidSelectors.page)
  const pageSize = useSelector(BidSelectors.pageSize)
  const { sort_field, sort_reversed } = useSelector(BidSelectors.sorting)

  const onChangeDistrict = useOnChangeDistrict({ filterState, filterUpdated })
  const onChangeRegion = useOnChangeRegion({ filterState, filterUpdated })

  const { columnsAll, hiddenColumns, visibleColumnsLength } = useColumns()

  const { columns, rows, prepareRow } = useTable<Bid | PotentialBid>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore weird issue with react-table typings — having more then 26 fields in type causes TS error
    columns: columnsAll,
    data: data as Bid[] | PotentialBid[],
    initialState: { hiddenColumns },
  })

  const setPage = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  const { scrollRef } = usePersistentScroll('bidList')

  const bidType = filterState.bid_type

  const handleClearFilters = useCallback(() => {
    filterUpdated({ bid_type: bidType })
  }, [filterUpdated, bidType])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { bid_type: _bid, ...noBidTypeFilter } = filterState
  const isFilterApplied = Object.values(noBidTypeFilter).some(value => !isNil(value))

  const selectedTeams = filterState.team

  const onChangeTeam = (id: string) => {
    const set = new Set(selectedTeams)
    set.has(id) ? set.delete(id) : set.add(id)
    filterUpdated({ ...filterState, team: [...set] })
  }

  const onChangeProduct = (id: string) => {
    const set = new Set(filterState.product || [])
    set.has(id) ? set.delete(id) : set.add(id)
    filterUpdated({ ...filterState, product: [...set] })
  }

  const onClearProduct = () => {
    filterUpdated({ ...filterState, product: undefined })
  }

  const onChangeStatusFilter = (id: string) => {
    const set = new Set(filterState.status || [])
    set.has(id) ? set.delete(id) : set.add(id)
    filterUpdated({ ...filterState, status: [...set] })
  }

  const onChangeAddressFilter = (address: BidListAddressFilter) => {
    filterUpdated({ ...filterState, ...address })
  }

  const onClearStatusFilter = () => {
    filterUpdated({
      ...filterState,
      status: undefined,
      longitude: undefined,
      latitude: undefined,
      address: undefined,
    })
  }

  const onClearRegion = useCallback(() => {
    filterUpdated({ ...filterState, region: undefined, district: undefined })
  }, [filterState, filterUpdated])

  const from = useLocationFrom()

  return (
    <TComponents.Wrapper>
      <div>
        <TComponents.Filters>
          <ProductFilter onChange={onChangeProduct} onClear={onClearProduct} selectedIds={filterState.product} />
          <BidStatusFilter
            isDisabled={filterState.isPotential}
            onChange={onChangeStatusFilter}
            onClear={onClearStatusFilter}
            selectedIds={filterState.isPotential ? [] : filterState.status}
          />
          <TeamFilter
            selectedIds={selectedTeams}
            onChange={onChangeTeam}
            onClear={() => {
              filterUpdated({ ...filterState, team: undefined })
            }}
            isNoTeam
          />
          <RegionFilter onChange={onChangeRegion} onClear={onClearRegion} region={filterState.region} />
          {filterState.bid_type === 'sale' && <PotentialBidFilter />}
          <AddressFilter filter={filterState} onChange={onChangeAddressFilter}>
            <ResetFilterButton {...{ handleClearFilters, isFilterApplied }} />
          </AddressFilter>
        </TComponents.Filters>
        <DistrictFilter district={filterState.district} region={filterState.region} onChange={onChangeDistrict} />
      </div>

      <Table total={total} pages={pages} pageSize={pageSize} currentPage={page} onSetPage={setPage} ref={scrollRef}>
        <TableHead>
          <TableHeadRow>
            {columns.map((column: ColumnProps) => (
              <BidsSortableHeadCell
                zIndex={1}
                key={column.getHeaderProps().key}
                id={column.id as keyof Bid}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                sortable={column.sortable}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                hidden={column.hidden}
                sortField={sort_field}
                sortDesc={sort_reversed}
                onChange={sortingUpdated}
                headerHorizontalAlign={column.headerHorizontalAlign as TableHeadCellProps['headerHorizontalAlign']}
              >
                {column.render('Header')}
              </BidsSortableHeadCell>
            ))}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map(row => {
            prepareRow(row)
            const { key, ...props } = row.getRowProps()
            const pathname = getPathname(row)
            return (
              <TableBodyRow key={key} {...props}>
                {row.cells.map(cell => {
                  const { key, ...props } = cell.getCellProps()
                  const ignoreClick = cell.column.id === 'created_by'
                  return (
                    <TComponents.RelativeTableBodyCell key={key} {...props}>
                      {!ignoreClick && !!pathname && <TComponents.TableCellLink to={{ pathname, state: { from } }} />}
                      {cell.render('Cell')}
                    </TComponents.RelativeTableBodyCell>
                  )
                })}
              </TableBodyRow>
            )
          })}
          <TableNoData
            progress={progress}
            isEmpty={!rows.length}
            colSpan={visibleColumnsLength}
            loading={<TComponents.Spinner />}
          >
            <div>{isFilterApplied ? t('list.emptyFilterMsg') : t('list.emptyMsg')}</div>
            {isFilterApplied && progress !== Progress.WORK && (
              <TComponents.ClearButton intent="cancel" size="small" onClick={handleClearFilters}>
                {t('common:resetAllFilters')}
              </TComponents.ClearButton>
            )}
          </TableNoData>
        </TableBody>
      </Table>
    </TComponents.Wrapper>
  )
}

export default BidTable
