import React, { VFC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Progress, Segment } from '@agro-club/agroclub-shared'

import env from 'env'
import useTonsAndBags from 'hooks/useTonsAndBags'
import { ParameterCondition } from 'modules/domain/bid/types'
import { Product } from 'modules/domain/collection/types'
import { BidKind } from 'modules/domain/potentialBid/types'
import { ProfileType, User } from 'modules/domain/user/types'
import { CompanySelect } from 'views/components/CompanySelect/CompanySelect'
import { UserAddressSelect } from 'views/components/UserAddressSelect/UserAddressSelect'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import { EmployeeSelect } from 'views/pages/Bid/BidAdd/EmployeeSelect'
import { InitialMainParameter, useBidAddFormik } from 'views/pages/Bid/BidAdd/hooks'
import { MainParam } from 'views/pages/Bid/BidAdd/MainParam'
import { Params } from 'views/pages/Bid/BidAdd/Params'
import { ProductSelect } from 'views/pages/Bid/BidAdd/ProductSelect'
import * as Styled from 'views/pages/Bid/BidAdd/styled'
import { useBidGoBack } from 'views/pages/Bid/hooks'
import * as Header from 'views/ui/Header/Header'
import { NumberInput } from 'views/ui/NumberInput/NumberInput'

import { AgroundImputsSelect } from '../components/AgroundImputsSelect'
import { BidTagSelect } from '../components/BidTagSelect'
import { TaxesRegimeSelect } from '../components/TaxesRegimeSelect'

type Props = {
  user?: User
}

export const BidAdd: VFC<Props> = props => {
  const { user } = props
  const { t } = useTranslation('bid')
  const goBack = useBidGoBack()
  const [product, setProduct] = useState<Product>()
  const currency = env.REACT_APP_CURRENCY_SYMBOL
  const [bidKind, setBidKind] = useState<BidKind>(BidKind.regular)
  const isPotential = bidKind === BidKind.potential
  const [ownerProfileType, setOwnerProfileType] = React.useState<ProfileType>()
  const [progress, formik] = useBidAddFormik(isPotential, user)
  const [bags, tonsToBagsCallback, bagsToTonsCallback] = useTonsAndBags()
  const [selectedCompanyUsers, setSelectedCompanyUsers] = useState<User[]>([])
  const companySelectError = formik.touched.company && formik.errors.company ? formik.errors.company : null
  const employeeSelectError = formik.errors.owner ? formik.errors.owner : null
  const addressSelectError = formik.errors.address ? formik.errors.address : null
  const productSelectError = formik.touched.product && formik.errors.product ? formik.errors.product : null
  const parametersError = formik.touched.mainParameter?.parameter_value
    ? formik.errors.mainParameter?.parameter_value
    : null
  const priceLabel =
    ownerProfileType === ProfileType.seller
      ? 'form.priceExw'
      : ownerProfileType === ProfileType.customer
      ? 'form.priceCpt'
      : 'form.priceDefault'

  useEffect(() => {
    if (!product) {
      return
    }
    if (isPotential) {
      formik.setValues({
        ...formik.values,
        product: product.id,
        parameters: product.parameters.map(p => ({
          parameter: p.id,
          condition: Object.keys(ParameterCondition)[1] as keyof typeof ParameterCondition,
          parameter_value: undefined,
        })),
        mainParameter: InitialMainParameter,
      })
    } else {
      formik.setValues({
        ...formik.values,
        product: product.id,
        parameters: product.parameters
          .filter(p => p.id !== product?.main_parameter?.id)
          .map(p => ({
            parameter: p.id,
            condition: Object.keys(ParameterCondition)[1] as keyof typeof ParameterCondition,
            parameter_value: undefined,
          })),
        mainParameter: {
          parameter: product.main_parameter?.id,
          condition: Object.keys(ParameterCondition)[1] as keyof typeof ParameterCondition,
          parameter_value: undefined,
        },
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPotential, product?.id])

  useEffect(() => {
    formik.setErrors({})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPotential])

  useEffect(() => {
    if (!ownerProfileType && selectedCompanyUsers.length) {
      const user = selectedCompanyUsers.find(u => u.id === formik.values.owner)
      if (user?.profile) {
        setOwnerProfileType(user.profile.profile_type)
      }
    }
  }, [formik.values.owner, ownerProfileType, selectedCompanyUsers])

  return (
    <>
      <Layout.Header hasBottomBorder>
        <Header.Root
          rightBlock={
            <Header.ButtonWrapper>
              <Button
                disabled={!formik.dirty || progress === Progress.WORK}
                filled
                intent="primary"
                size="small"
                onClick={formik.submitForm}
                progress={progress}
              >
                {t('common:save')}
              </Button>
              <Button intent="primary" title={t('common:cancel')} size="small" onClick={goBack}>
                {t('common:cancel')}
              </Button>
            </Header.ButtonWrapper>
          }
        >
          <Header.Title title={t('form.add')} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <Styled.TwoColumn single={!product} column={650}>
          <Styled.Column>
            <Styled.Box>
              <Segment
                items={[
                  { id: BidKind.regular, title: t(`kind.${BidKind.regular}`) },
                  { id: BidKind.potential, title: t(`kind.${BidKind.potential}`) },
                ]}
                value={bidKind}
                onChange={value => {
                  setBidKind(value as BidKind)
                }}
                size="small"
              />
              <Styled.SelectWrapper>
                <CompanySelect
                  defaultValue={
                    user?.profile?.company
                      ? { value: user.profile.company.inn || '', label: user.profile.company.name || '' }
                      : undefined
                  }
                  invalid={!!companySelectError}
                  onCompanyChange={company => {
                    formik.setFieldValue('company', company.id)
                    formik.setFieldTouched('company')
                    formik.setFieldValue('owner', undefined)
                    formik.setFieldValue('address', undefined)
                  }}
                />
              </Styled.SelectWrapper>
              <Styled.SelectWrapper>
                <EmployeeSelect
                  defaultValue={user?.id}
                  invalid={!!employeeSelectError}
                  onUserChange={selectedUser => {
                    formik.setFieldValue('owner', selectedUser.id)
                    formik.setFieldTouched('owner')
                    formik.setFieldValue('address', undefined)
                    formik.setFieldValue('bid_tag', undefined)
                    setOwnerProfileType(selectedUser.profile?.profile_type)
                  }}
                  companyId={formik.values.company}
                  onUsersLoaded={(users: User[]) => {
                    setSelectedCompanyUsers(users)
                  }}
                />
              </Styled.SelectWrapper>

              <Styled.SelectWrapper>
                <UserAddressSelect
                  placeholder={t('form.userAddressPlaceholder')}
                  noOptionsMessage={() => t('form.noAddressMessage')}
                  label={t('form.userAddress')}
                  errorText={t('form.userAddressError')}
                  invalid={!!addressSelectError}
                  userId={formik.values.owner}
                  onChange={address => {
                    formik.setFieldValue('address', address.id)
                    formik.setFieldTouched('address')
                  }}
                />
              </Styled.SelectWrapper>

              <Styled.ProductFields>
                <ProductSelect
                  invalid={!!productSelectError}
                  selected={formik.values.product}
                  onProductChange={selectedProduct => {
                    setProduct(selectedProduct)
                  }}
                />
                {!isPotential && (
                  <NumberInput
                    invalid={formik.touched.price && !!formik.errors.price}
                    label={t(priceLabel, { currency })}
                    value={formik.values.price}
                    onChange={v => {
                      formik.setFieldValue('price', v)
                    }}
                    max={1000}
                  />
                )}
                <NumberInput
                  label={t('form.quantity_bag')}
                  value={bags}
                  onChange={bagsToTonsCallback(v => formik.setFieldValue('quantity', v))}
                />
                <NumberInput
                  invalid={formik.touched.quantity && !!formik.errors.quantity}
                  label={t('form.quantity')}
                  value={formik.values.quantity}
                  onChange={tonsToBagsCallback(v => formik.setFieldValue('quantity', v))}
                />
              </Styled.ProductFields>
              {!isPotential && (
                <>
                  <Styled.TaxesContainer>
                    <AgroundImputsSelect formik={formik} />
                    <TaxesRegimeSelect formik={formik} />
                  </Styled.TaxesContainer>
                  {ownerProfileType === ProfileType.customer && (
                    <Styled.TaxesContainer>
                      <BidTagSelect formik={formik} />
                    </Styled.TaxesContainer>
                  )}
                </>
              )}
              {!isPotential && product && product.main_parameter && (
                <MainParam
                  parameters={[product.main_parameter]}
                  parameter={formik.values.mainParameter}
                  isValid={!parametersError}
                  onChange={values => formik.setFieldValue('mainParameter', values)}
                />
              )}
            </Styled.Box>
          </Styled.Column>
          <Styled.Column>
            {product && (
              <Styled.Box>
                <Params
                  {...{
                    formik,
                    product,
                  }}
                />
              </Styled.Box>
            )}
          </Styled.Column>
        </Styled.TwoColumn>
      </Layout.Content>
    </>
  )
}
