import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { useAction, useHelmet } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import { CarStatuses } from 'modules/domain/car/types'
import CarsActions from 'modules/domain/cars/duck'
import { useCarsFilterAndSortQuery, useLogisticsCarListReload } from 'modules/domain/cars/hooks'
import CarsSelectors from 'modules/domain/cars/selectors'
import TripSelectors from 'modules/domain/trip/selectors'
import TableSearch from 'views/components/TableSearch/TableSearch'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import CarsTable from 'views/pages/Cars/CarsTable/CarsTable'
import { useLogisticsCarsBack } from 'views/pages/Logistics/hooks'
import CompanyNames from 'views/pages/Logistics/LogisticsCars/CompanyNames'
import * as Header from 'views/ui/Header/Header'

const TableSearchWrapper = styled.div`
  margin-top: 32px;
`

const LogisticsCars: React.FC = () => {
  const { t } = useTranslation(['cars'])
  useHelmet({ title: t('carsMetaTitle') })
  const { id: dealId } = useParams<{ id: string }>()
  const { filter } = useCarsFilterAndSortQuery()
  const filterUpdated = useAction(CarsActions.filterUpdated)
  const filterState = useSelector(CarsSelectors.filter)

  const goBack = useLogisticsCarsBack(dealId)

  const addProgress = useSelector(TripSelectors.addProgress)
  useLogisticsCarListReload(addProgress)

  const onChangeSearch = useCallback(
    (query: string) => {
      filterUpdated({ ...filterState, query })
    },
    [filterUpdated, filterState],
  )

  const defaultFilter = useMemo(() => ({ status: CarStatuses.active }), [])

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title compact title={t('list.heading')} />
          <Header.SubTitle>
            <CompanyNames dealId={dealId} />
          </Header.SubTitle>

          <TableSearchWrapper>
            <TableSearch
              value={filter.query || filterState.query || ''}
              onChangeSearch={onChangeSearch}
              placeholder={t('list.searchPlaceholder')}
            />
          </TableSearchWrapper>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <CarsTable defaultFilter={defaultFilter} showTripsCount dealIdForAddToTrip={dealId} />
      </Layout.Content>
    </>
  )
}

export default LogisticsCars
