import { Progress } from '@agro-club/agroclub-shared'

import filterDistricts from 'helpers/filterDistricts'
import { AppGlobalState } from 'modules/types'

const filter = (state: AppGlobalState) => state.dashboard.filter

const responsesIds = (state: AppGlobalState) => state.dashboard.responsesIds
const responsesFetchProgress = (state: AppGlobalState) => state.dashboard.responsesFetchProgress

const responsesList = (state: AppGlobalState) => {
  const progress = responsesFetchProgress(state)
  if (progress === Progress.WORK) {
    return []
  }
  const ids = responsesIds(state)
  return ids.map(id => state.dashboard.items[id])
}

const matchesIds = (state: AppGlobalState) => state.dashboard.matchesIds
const matchesFetchProgress = (state: AppGlobalState) => state.dashboard.matchesFetchProgress

const matchesList = (state: AppGlobalState) => {
  const progress = matchesFetchProgress(state)
  if (progress === Progress.WORK) {
    return []
  }
  const ids = matchesIds(state)
  return ids.map(id => state.dashboard.items[id])
}

const newIds = (state: AppGlobalState) => state.dashboard.newIds
const newUsersIds = (state: AppGlobalState) => state.dashboard.newUsersIds
const newFetchProgress = (state: AppGlobalState) => state.dashboard.newFetchProgress
const newUsersFetchProgress = (state: AppGlobalState) => state.dashboard.newUsersFetchProgress

const newList = (state: AppGlobalState) => {
  const progress = newFetchProgress(state)
  if (progress === Progress.WORK) {
    return []
  }
  const ids = newIds(state)
  return ids.map(id => state.dashboard.items[id])
}

const newUsers = (state: AppGlobalState) => {
  const progress = newUsersFetchProgress(state)
  if (progress === Progress.WORK) {
    return []
  }
  const ids = newUsersIds(state)
  return ids.map(id => state.dashboard.users[id])
}

const filterForRequestManager = (state: AppGlobalState) => {
  const { filter } = state.dashboard
  return filterDistricts(state, filter)
}

const newUsersPage = (state: AppGlobalState) => state.dashboard.newUsersPage || 1
const newUsersCount = (state: AppGlobalState) => state.dashboard.newUsersCount || 0

const DashboardSelectors = {
  filter,
  filterForRequestManager,
  matchesList,
  matchesFetchProgress,
  responsesList,
  responsesFetchProgress,
  newList,
  newFetchProgress,
  newUsers,
  newUsersFetchProgress,
  newUsersPage,
  newUsersCount,
}

export default DashboardSelectors
