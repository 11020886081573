import { Progress } from '@agro-club/agroclub-shared'

import { FetchError } from 'modules/domain/types'
import { Dict } from 'types/generics.d'

export type UserCallItem = {
  calls: UserCall[]
  listFetchProgress: Progress
  listFetchNextProgress: Progress
  listFetchNextError: FetchError | null
  page: number
  total: number
  count: number
  pageSize: number
}

export type UserCallsState = {
  items: Dict<UserCallItem>
}

export enum CallType {
  in = 'in',
  out = 'out',
}

export type UserCall = {
  id: string
  call_type: CallType
  call_start: string
  conversation_duration: number
  record_link: string
  coordinator_id: number
  coordinator_name: string
  coordinator_login: string
}
