import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { SimpleSelect, useAction } from '@agro-club/agroclub-shared'
import { addMonths, format, isBefore } from 'date-fns'

import useDateFnsLocale from 'hooks/useDateFnsLocale'
import { MONTH_NAME_YEAR, YEAR_MONTH } from 'modules/constants'
import DealActions from 'modules/domain/deal/duck'
import DealSelectors from 'modules/domain/deal/selectors'
import { Filter } from 'views/components/TableFilters/TableFilters'

const getMonthList = () => {
  let startDate = new Date('2021-09-01')
  const endDate = Date.now()
  const result: Date[] = []

  while (isBefore(startDate, endDate)) {
    result.push(startDate)
    startDate = addMonths(startDate, 1)
  }
  return result.reverse()
}

export const MonthFilter: React.FC = () => {
  const { t } = useTranslation()
  const filterState = useSelector(DealSelectors.filter)
  const filterUpdated = useAction(DealActions.filterUpdated)
  const locale = useDateFnsLocale()
  const options = useMemo(
    () =>
      getMonthList().map(date => ({
        id: format(date, YEAR_MONTH),
        title: format(date, MONTH_NAME_YEAR, { locale }),
      })),
    [locale],
  )

  return (
    <Filter title={t('common:month')}>
      <SimpleSelect
        placeholder={t('common:forEntirePeriod')}
        options={options}
        value={filterState.month}
        onChange={(value: string) => {
          filterUpdated({ ...filterState, month: value })
        }}
        additionalStyles={{
          control: {
            minWidth: 180,
          },
        }}
        size="small"
        onClear={() => {
          filterUpdated({ ...filterState, month: undefined })
        }}
        isClearable
      />
    </Filter>
  )
}
