import React, { VFC } from 'react'
import { useTranslation } from 'react-i18next'

import { Address } from 'modules/domain/types'
import { Key, Value } from 'views/components/WarehouseProps/styled'

type Props = {
  car_delivery: Address['car_delivery']
  railway_delivery: Address['railway_delivery']
}

export const DeliveryMethod: VFC<Props> = ({ car_delivery, railway_delivery }) => {
  const { t } = useTranslation('warehouse')
  return (
    <>
      <Key>{t('fields.delivery_method')}</Key>
      <Value>
        <div>
          {t('fields.car_delivery')}: {t(`yesNoUnknown.${car_delivery}`)}
        </div>
        <div>
          {t('fields.railway_delivery')}: {t(`yesNoUnknown.${railway_delivery}`)}
        </div>
      </Value>
    </>
  )
}
