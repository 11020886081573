import { Button, IconCheck, Input, Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const UserContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const ButtonWrapper = styled.div`
  & > button {
    border-radius: 8px;
    color: ${({ theme }) => theme.color.accentApprove600};
    border: unset;
    &,
    &:active,
    &:focus {
      background-color: ${({ theme }) => theme.color.accentApprove16};
      box-shadow: unset;
    }
    &:hover {
      background-color: ${({ theme }) => theme.color.accentApprove100};
    }
    & svg {
      fill: ${({ theme }) => theme.color.accentApprove600};
      width: 22px;
      height: 22px;
      margin: 0 10px 0 -3px;
    }
  }
`

export const StyledPhoneInput = styled(Input)`
  width: 140px;
  position: relative;
  & input::placeholder {
    color: ${({ theme }) => theme.color.textSecondary};
  }
`

export const Label = styled.div`
  ${Typography.body2Default};
  color: ${({ theme }) => theme.color.textSecondary};
  &:not(:first-child) {
    margin-top: 16px;
  }
`

export const RowButton = styled(Button)`
  border: 0;
  margin: 0 0 0 6px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  & svg {
    fill: ${({ theme }) => theme.color.secondary300};
  }
  &:hover svg {
    fill: ${({ theme }) => theme.color.outlineSecondary};
  }
`

export const RowButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  & > * + * {
    margin-top: 1px;
  }
`

export const SubmitIcon = styled(IconCheck)`
  fill: ${props => props.theme.color.secondary200};
  transition: fill 0.1s ease-in;

  &:hover {
    fill: ${props => props.theme.color.primary100};
  }

  width: 25px;
  height: 15px;
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  &:hover {
    background: ${props => props.theme.color.primary8a};
  }
  & button {
    background: transparent;
    &:hover svg {
      fill: ${props => props.theme.color.primary600};
    }
  }
  border-left: 1px solid ${props => props.theme.color.secondary50};
  border-right: 1px solid ${props => props.theme.color.secondary50};
  border-top: 1px solid ${props => props.theme.color.secondary50};
  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom: 1px solid ${props => props.theme.color.secondary50};
    margin-bottom: 8px;
  }
  & > *:last-child {
    opacity: 0;
  }
  &:hover > *:last-child {
    opacity: 1;
  }
`
export const Cell = styled.div`
  & * {
    padding: 0;
  }
`

export const ContactTitle = styled.div`
  ${Typography.body2Default};
`

export const Sure = styled.div`
  ${Typography.body2Default};
`
