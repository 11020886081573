import formatDateWithoutLocale from 'helpers/formatDateWithoutLocale'
import { SERVER_DATE_FORMAT, SERVER_DATE_TIME_FORMAT } from 'modules/constants'

export default function formatFilterDate(date?: string) {
  return formatDateWithoutLocale(date, SERVER_DATE_FORMAT) || undefined
}

export function formatFilterDateTime(date?: string) {
  return formatDateWithoutLocale(date, SERVER_DATE_TIME_FORMAT) || undefined
}
