import { useSelector } from 'react-redux'
import { generatePath, useHistory, useLocation } from 'react-router-dom'

import { stringifyUrl } from 'helpers/stringifyUrl'
import UserSelectors from 'modules/domain/user/selectors'
import { LocationStateFrom } from 'types/location'
import UserRoutes from 'views/pages/User/routes'

export const useUserListLocation = () => {
  const page = useSelector(UserSelectors.page)
  const filter = useSelector(UserSelectors.filter)
  const sorting = useSelector(UserSelectors.sorting)

  const search = stringifyUrl({ url: '', query: { ...filter, ...sorting, page } })

  return {
    pathname: UserRoutes.List,
    search,
  }
}

export const useUserGoBack = () => {
  const location = useLocation<LocationStateFrom>()
  const defaultBackLocation = useUserListLocation()
  const from = location.state?.from
  const history = useHistory()

  return () => {
    history.push({
      pathname: from?.pathname || defaultBackLocation.pathname,
      search: from?.pathname ? from?.search : defaultBackLocation.search,
    })
  }
}

export const useUserEditGoBack = (id: string) => {
  const location = useLocation<LocationStateFrom>()
  const history = useHistory()

  return () => {
    history.push({
      pathname: generatePath(UserRoutes.Detail, { id }),
      state: location.state,
    })
  }
}
