import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { generatePath } from 'react-router'

import { Icons, Progress, Typography, defaultTheme } from '@agro-club/agroclub-shared'
import { useFormik } from 'formik'
import styled from 'styled-components'

import env from 'env'
import { getUserPhone } from 'helpers/getUserData'
import { noop } from 'helpers/noop'
import useFormatDate from 'hooks/useFormatDate'
import useFormatNumber from 'hooks/useFormatNumber'
import useLocationFrom from 'hooks/useLocationFrom'
import useTonsAndBags, { tonsToBags } from 'hooks/useTonsAndBags'
import useUserName from 'hooks/useUserName'
import { BidParameter } from 'modules/domain/bid/types'
import { CommentType } from 'modules/domain/comment/types'
import { DealBid } from 'modules/domain/deal/types'
import UserAddressSelectors from 'modules/domain/userAddress/selectors'
import { endpoints } from 'modules/endpoints'
import { Comments } from 'views/components/Comments/Comments'
import CompanyRoutes from 'views/pages/Company/routes'
import * as S from 'views/pages/Deal/DealEdit/styled'
import { WAREHOUSE_MODAL_OPEN } from 'views/pages/Deal/DealEdit/Warehouse'
import UserRoutes from 'views/pages/User/routes'
import { EditButton } from 'views/ui/EditButton/EditButton'
import { Phone } from 'views/ui/Phone/Phone'

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`

const Address = styled.div`
  margin-top: 16px;
`

const UserName = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-top: 16px;

  & > div {
    height: 16px;
    align-items: center;
  }

  & > div > svg {
    height: 100%;
    padding-left: 4px;

    path {
      fill: ${defaultTheme.color.primary600};
    }
  }
`

const PhoneWrpper = styled.span`
  ${Typography.body2Default};
  margin-top: 4px;
`

const RestParamsButton = styled.button`
  border: 0;
  outline: none;
  background-color: transparent;
  color: ${props => props.theme.color.primary600};
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  cursor: pointer;
  padding: 0;
  text-align: left;
`

const TeamWrapper = styled.div`
  display: grid;
  width: 100%;
  height: 50px;
  grid-template-columns: 80px max-content max-content;
  grid-gap: 48px;
  align-items: flex-start;
  margin: 16px 0;
`

const TeamItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const TeamItemLabel = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
`

const TeamItemInner = styled.div<{ bold?: boolean }>`
  font-weight: ${props => (props.bold ? '600' : '500')};
  font-size: 14px;
  line-height: 20px;
`

const TeamItem: React.FC<{ label: string; bold?: boolean }> = ({ label, bold, children }) => (
  <TeamItemWrapper>
    <TeamItemLabel>{label}</TeamItemLabel>
    <TeamItemInner bold={bold}>{children}</TeamItemInner>
  </TeamItemWrapper>
)

const Team: React.FC<{
  created: string
  modified: string
  team?: string
}> = ({ created, modified, team }) => {
  const { t } = useTranslation()
  const formatDate = useFormatDate()
  return (
    <TeamWrapper>
      <TeamItem label={t('common:team')}>{team || ''}</TeamItem>
      <TeamItem label={t('bid:list.tableHeaders.created')}>{formatDate(created)}</TeamItem>
      <TeamItem label={t('bid:list.tableHeaders.modified')}>{formatDate(modified)}</TeamItem>
    </TeamWrapper>
  )
}

const CommentsWrapper = styled.div`
  width: 100%;
  height: 500px;
`

const CommentHeader = styled.div`
  padding-top: 16px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.secondary300};
  ${Typography.body2Bold};
  //border-top: ${({ theme }) => `1px solid ${theme.color.secondary50}`};
`

export type BidDetailFormProps = {
  price: number
  volume: number
}

export const BidDetailSection: React.FC<{
  isClosed?: boolean
  bid: DealBid
  mode: 'edit' | 'view'
  onStartEdit: () => void
  onSubmit: (form: BidDetailFormProps) => void
  onCancel: () => void
  progress?: Progress
}> = ({ isClosed, bid, onStartEdit, onSubmit = noop, onCancel = noop, mode, progress }) => {
  const { t } = useTranslation(['deal', 'common', 'bid'])
  const [showRest, setShowRest] = useState(false)
  const formatNumber = useFormatNumber()
  const currency = env.REACT_APP_CURRENCY_SYMBOL

  const priceTitle = bid.bid_type === 'purchase' ? t('common:price.cpt') : t('common:price.exw')
  const price = bid.bid_type === 'purchase' ? bid.price_cpt : bid.price_exw

  const [headParam, ...restParams] = bid?.parameters || []

  const address = useSelector(state => UserAddressSelectors.addressById(state, bid?.address?.id))

  const [bags, tonsToBagsCallback, bagsToTonsCallback, setBags] = useTonsAndBags(bid.quantity)

  useEffect(() => {
    if (mode === 'view') {
      setBags(tonsToBags(bid.quantity))
    }
  }, [mode, setBags, bid])

  const renderParameter = (param: BidParameter) => {
    const value = [param.condition_label, formatNumber(param.parameter_value)].filter(Boolean).join(' ')
    return <S.CardKeyValueGroup key={param.id} title={param.parameter.name} value={value} />
  }
  const renderFirstParam = () => (headParam ? renderParameter(headParam) : null)
  const renderRestParams = () => restParams?.map(renderParameter) || null

  const formik = useFormik<BidDetailFormProps>({
    initialValues: {
      price,
      volume: bid.quantity,
      // team: '1',
    },
    onSubmit: values => {
      onSubmit(values)
    },
  })

  const renderEditableKV = (options: {
    title: string
    field: keyof BidDetailFormProps
    inputType?: string
    unit?: string
    isInteger?: boolean
    max?: number
    isPrice?: boolean
    onChange?: (v) => void
    value?: string | number
  }) => {
    if (mode === 'view') {
      return (
        <S.CardKeyValueGroup
          isPrice={options.isPrice}
          title={options.title}
          value={options.value ?? formik.values[options.field]}
          unit={options.unit}
        />
      )
    }
    return (
      <S.CardKeyValueGroup
        title={options.title}
        value={
          <S.FieldUnitWrapper>
            <S.StyledNumberInput
              isInteger={options.isInteger}
              value={typeof options.value === 'undefined' ? formik.values[options.field] : options.value}
              onChange={v => {
                if (options.onChange) {
                  options.onChange(v)
                } else {
                  formik.setFieldValue(options.field, v)
                }
              }}
              invalid={formik.touched[options.field] && !!formik.errors[options.field]}
              data-test-id={options.field}
              inputSize="thin"
              max={options.max}
            />
            <S.CardPairValue>{options.unit}</S.CardPairValue>
          </S.FieldUnitWrapper>
        }
      />
    )
  }

  const handleCancelEdit = useCallback(() => {
    formik.resetForm()
    onCancel()
  }, [onCancel, formik])

  useEffect(() => {
    document.addEventListener(WAREHOUSE_MODAL_OPEN, handleCancelEdit)
    return () => {
      document.removeEventListener(WAREHOUSE_MODAL_OPEN, handleCancelEdit)
    }
  }, [handleCancelEdit])

  const renderHeaderIconGroup = () => {
    if (isClosed) {
      return null
    }

    return (
      <S.BidCardHeaderGroup>
        {mode === 'view' ? (
          <EditButton onClick={onStartEdit} />
        ) : (
          <S.CardEditModeIcons progress={progress} onCancel={handleCancelEdit} onSubmit={formik.submitForm} />
        )}
      </S.BidCardHeaderGroup>
    )
  }

  const from = useLocationFrom()

  const name = useUserName(bid.owner)
  const verifyIcon = bid?.owner?.profile?.is_verified ? <Icons.IconCheckMark /> : null

  return (
    <S.CardWrapper noDivider>
      <S.CardHeaderWrapper>
        <S.CardHeaderGroup>
          <div>
            <S.CardHeaderTitle>
              {bid?.company?.name}
              {!!bid?.company?.id && (
                <S.StyledLink
                  to={{
                    pathname: generatePath(CompanyRoutes.Detail, { id: bid.company.id }),
                    state: { from },
                  }}
                >
                  <S.CardHeaderLinkIcon />
                </S.StyledLink>
              )}
            </S.CardHeaderTitle>
            <S.CardHeaderSubTitle>
              {t('company:form.fields.inn')} {bid?.company?.inn}
              {!!bid?.company?.payment_delay_days &&
                ` ${t('company:form.fields.payment_delay_days')} ${bid?.company?.payment_delay_days}`}
            </S.CardHeaderSubTitle>
          </div>
        </S.CardHeaderGroup>
        {renderHeaderIconGroup()}
      </S.CardHeaderWrapper>
      <S.CardBody>
        <BodyWrapper>
          <S.CardKeyList>
            {renderEditableKV({
              title: priceTitle,
              field: 'price',
              isPrice: true,
              max: 1000,
              value: mode === 'view' ? `${currency} ${formik.values.price} ${t('common:per_bag')}` : undefined,
            })}
            <S.CardKeyValueGroup title={t('common:exw_tax')} customValue={`${formatNumber(bid?.tax_from_exw, true)}`} />
            {renderEditableKV({
              title: t('common:quantity'),
              field: 'volume',
              unit: t('common:bags'),
              value: bags,
              onChange: bagsToTonsCallback(v => formik.setFieldValue('volume', v)),
            })}
            {renderEditableKV({
              title: t('common:quantity'),
              field: 'volume',
              unit: t('common:ton'),
              isInteger: true,
              onChange: tonsToBagsCallback(v => formik.setFieldValue('volume', v)),
            })}
            {bid.bid_tag && (
              <S.CardKeyValueGroup title={t('bid:bidTag.label')} customValue={t(`bid:bidTag.${bid.bid_tag}`)} />
            )}
            {renderFirstParam()}
            {restParams.length && !showRest ? (
              <RestParamsButton onClick={() => setShowRest(!showRest)}>{t('showAllParameters')}</RestParamsButton>
            ) : null}
            {showRest && restParams.length ? renderRestParams() : null}
          </S.CardKeyList>
          <Address>{address?.address || ''}</Address>
          <UserName>
            {name}
            <div>{verifyIcon}</div>
            {!!bid?.owner?.id && (
              <S.StyledLink
                to={{
                  pathname: generatePath(UserRoutes.Detail, { id: bid.owner.id }),
                  state: { from },
                }}
              >
                <S.CardHeaderLinkIcon />
              </S.StyledLink>
            )}
          </UserName>
          <PhoneWrpper>
            <Phone phone={getUserPhone(bid.owner)} />
          </PhoneWrpper>
        </BodyWrapper>
        <Team modified={bid.modified} created={bid.created} team={bid?.owner?.profile?.team?.name} />
        <CommentHeader>{t('commentHeader')}</CommentHeader>
        <CommentsWrapper>
          <Comments
            isClosed={isClosed}
            id={bid?.owner?.id}
            type={CommentType.user}
            url={endpoints.userComments(bid?.owner?.id)}
          />
        </CommentsWrapper>
      </S.CardBody>
    </S.CardWrapper>
  )
}
