import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { CellProps, useTable } from 'react-table'

import {
  Progress,
  Switch,
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHead,
  TableHeadRow,
  TableNoData,
  useAction,
} from '@agro-club/agroclub-shared'
import { isNil } from 'ramda'
import styled from 'styled-components'

import { noop } from 'helpers/noop'
import { useNotificationProgress } from 'hooks/useNotificationProgress'
import BidActions from 'modules/domain/bid/duck'
import { useUserBidList } from 'modules/domain/bid/hooks'
import BidSelectors from 'modules/domain/bid/selectors'
import { Bid, BidStatus } from 'modules/domain/bid/types'
import { ProfileType, User } from 'modules/domain/user/types'
import { BidStatusFilter } from 'views/components/BidStatusFilter/BidStatusFilter'
import {
  BidsSortableHeadCell,
  CptHeader,
  ExwHeader,
  ProductCell,
  QuantityCell,
  QuantityHeader,
} from 'views/components/CommonBidTable/CommonBidTable'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import { ProductFilter } from 'views/components/ProductFilter/ProductFilter'
import { ResetFilterButton } from 'views/components/TableFilters/ResetFilterButton'
import { UserAddressFilter } from 'views/pages/User/UserBidPrice/UserAddressFilter'
import { NumberInput } from 'views/ui/NumberInput/NumberInput'

type Props = {
  user?: User
}

type EditableCellProps = CellProps<Bid> & {
  // eslint-disable-next-line react/no-unused-prop-types
  updateBidPrice: (bid: Bid, price: number) => void // TODO: why?
  // eslint-disable-next-line react/no-unused-prop-types
  updateBidStatus: (bidId: string, status: BidStatus) => void // TODO: why?
}

const PriceInput = styled(NumberInput)`
  width: 80px;
`
const EndWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const EditablePriceCell: React.FC<EditableCellProps> = ({
  value: initialValue,
  row: { original },
  updateBidPrice, // This is a custom function that we supplied to our table instance
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue)
  const meta = useSelector(state => BidSelectors.meta(state, original.id))
  const { t } = useTranslation()
  useNotificationProgress(meta.updateProgress, t('bid:form.notifyEditSuccess'))
  const onChange = val => {
    setValue(val)
  }

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    const numValue = Number(value)
    if (!Number.isNaN(numValue)) {
      setValue(numValue)
      Number(initialValue) !== numValue && updateBidPrice(original, numValue)
    }
  }

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return (
    <EndWrapper>
      <PriceInput value={value} onChange={onChange} onBlur={onBlur} />
    </EndWrapper>
  )
}

const EditableStatusCell: React.FC<EditableCellProps> = ({
  value: initialValue,
  updateBidStatus,
  row: { original },
}) => {
  const progress = useSelector(state => BidSelectors.changeStatusProgress(state, original.id))
  const { t } = useTranslation()
  useNotificationProgress(progress, t('bid:form.notifyEditSuccess'))
  const [isOn, setIsOn] = useState<boolean>(initialValue === BidStatus.published)
  return (
    <EndWrapper>
      <Switch
        size="small"
        on={isOn}
        onClick={val => {
          setIsOn(val)
          updateBidStatus(original.id, val ? BidStatus.published : BidStatus.archived)
        }}
      />
    </EndWrapper>
  )
}

export const UserBidTable: React.FC<Props> = props => {
  const { user } = props
  const { id: userId } = useParams<{ id: string }>()
  const { sort_field, sort_reversed } = useSelector(BidSelectors.sorting)
  const [progress, data] = useUserBidList(userId)
  const filter = useSelector(BidSelectors.userBidFilter)
  const setFilters = useAction(BidActions.userBidFilterUpdated)
  const resetFilterWithoutRequest = useAction(BidActions.userBidResetFilterWithoutRequest)
  const total = data.length
  const pages = 1
  const page = 1
  const pageSize = data.length
  const changeStatus = useAction(BidActions.changeStatusRequested)
  const updateAction = useAction(BidActions.updateRequested)
  const handleClearFilters = () => {
    setFilters({ userId, filter: {} })
  }

  useEffect(
    () => () => {
      resetFilterWithoutRequest()
    },
    [resetFilterWithoutRequest],
  )

  const { t } = useTranslation('bid')

  const profileType = user?.profile?.profile_type

  const visibleColumns = React.useMemo(
    () => [
      {
        Header: t('list.tableHeaders.product'),
        accessor: 'product' as const,
        Cell: ProductCell,
      },
      {
        Header: t('list.tableHeaders.created'),
        Cell: TComponents.DateCell,
        accessor: 'created' as const,
      },
      {
        Header: t('list.tableHeaders.modified'),
        Cell: TComponents.DateCell,
        accessor: 'modified' as const,
      },
      {
        Header: QuantityHeader,
        Cell: QuantityCell,
        accessor: 'quantity' as const,
      },
      profileType === ProfileType.seller
        ? {
            Header: ExwHeader,
            accessor: 'price_exw' as const,
            Cell: EditablePriceCell,
          }
        : {
            Header: CptHeader,
            accessor: 'price_cpt' as const,
            Cell: EditablePriceCell,
          },
      {
        Header: t('common:address'),
        accessor: 'address.address' as const,
      },
      {
        Header: t('bidStatuses.published'),
        accessor: 'status',
        Cell: EditableStatusCell,
      },
    ],
    [t, profileType],
  )
  const hiddenColumns: (keyof Bid)[] = useMemo(() => ['id'], [])

  const columnsAll = useMemo(
    () => [
      ...visibleColumns,
      ...hiddenColumns.map(col => ({
        Header: col,
        accessor: col,
        hidden: true,
      })),
    ],
    [hiddenColumns, visibleColumns],
  )

  const updateBidPrice: EditableCellProps['updateBidPrice'] = (bid, price) => {
    updateAction(bid.id, {
      price,
      parameters: bid.parameters.map(param => ({
        parameter: param.parameter.id,
        condition: param.condition,
        parameter_value: param.parameter_value,
      })),
      quantity: bid.quantity,
    })
  }

  const updateBidStatus: EditableCellProps['updateBidStatus'] = (bidId, status) => {
    changeStatus(bidId, status)
  }

  const { columns, rows, prepareRow } = useTable<Bid>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore weird issue with react-table typings — having more then 26 fields in type causes TS error
    columns: columnsAll,
    data: data as Bid[],
    initialState: { hiddenColumns },
    autoResetPage: false,
    updateBidPrice,
    updateBidStatus,
  })

  const isFilterApplied = Object.values(filter).some(value => !isNil(value))

  const onChangeProduct = (id: string) => {
    const set = new Set(filter.product || [])
    set.has(id) ? set.delete(id) : set.add(id)
    setFilters({ userId, filter: { ...filter, product: [...set] } })
  }

  const onClearProduct = () => {
    setFilters({ userId, filter: { ...filter, product: undefined } })
  }

  const onChangeStatusFilter = (id: string) => {
    const set = new Set(filter.status || [])
    set.has(id) ? set.delete(id) : set.add(id)
    setFilters({ userId, filter: { ...filter, status: [...set] } })
  }

  const onClearStatusFilter = () => {
    setFilters({ userId, filter: { ...filter, status: undefined } })
  }

  return (
    <TComponents.Wrapper>
      <TComponents.Filters>
        <ProductFilter onChange={onChangeProduct} onClear={onClearProduct} selectedIds={filter.product || []} />
        <BidStatusFilter onChange={onChangeStatusFilter} onClear={onClearStatusFilter} selectedIds={filter.status} />
        <UserAddressFilter userId={userId} />
        <ResetFilterButton {...{ handleClearFilters, isFilterApplied }} />
      </TComponents.Filters>
      <Table total={total} pages={pages} pageSize={pageSize} currentPage={page} onSetPage={noop}>
        <TableHead>
          <TableHeadRow>
            {columns.map(column => (
              <BidsSortableHeadCell
                zIndex={1}
                key={column.getHeaderProps().key}
                id={column.id as keyof Bid}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                sortable={column.sortable}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                hidden={column.hidden}
                sortField={sort_field}
                sortDesc={sort_reversed}
                onChange={noop}
              >
                {column.render('Header')}
              </BidsSortableHeadCell>
            ))}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map(row => {
            prepareRow(row)
            const { key, ...props } = row.getRowProps()
            return (
              <TableBodyRow key={key} {...props}>
                {row.cells.map(cell => {
                  const { key, ...props } = cell.getCellProps()
                  return (
                    <TableBodyCell key={key} {...props}>
                      {cell.render('Cell')}
                    </TableBodyCell>
                  )
                })}
              </TableBodyRow>
            )
          })}
          <TableNoData
            progress={progress}
            isEmpty={!rows.length}
            colSpan={visibleColumns.length}
            loading={<TComponents.Spinner />}
          >
            <div>{isFilterApplied ? t('list.emptyFilterMsg') : t('list.emptyMsg')}</div>
            {isFilterApplied && progress !== Progress.WORK && (
              <TComponents.ClearButton intent="cancel" size="small" onClick={handleClearFilters}>
                {t('common:resetAllFilters')}
              </TComponents.ClearButton>
            )}
          </TableNoData>
        </TableBody>
      </Table>
    </TComponents.Wrapper>
  )
}
