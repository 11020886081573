import React from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useParams } from 'react-router-dom'

import { IconCheckmark } from '@agro-club/agroclub-shared'

import useFormatNumber from 'hooks/useFormatNumber'
import useLocationFrom from 'hooks/useLocationFrom'
import { Car, CarStatuses } from 'modules/domain/car/types'
import * as S from 'views/pages/Carrier/CarrierDetail/UserVehiclesBoard/styled'
import CarrierRoutes from 'views/pages/Carrier/routes'
import { StyledIconLinkOpener } from 'views/pages/User/UserDetail/UserColumnBoard/styled'

type Props = {
  car: Car
  isSelected: boolean
  onClick: (carId: string) => void
}

export const VehicleItem: React.FC<Props> = props => {
  const { t } = useTranslation('vehicles')
  const { id: userId } = useParams<{ id: string }>()
  const formatNumber = useFormatNumber()
  const {
    id,
    licence_number,
    default_trailer,
    car_type,
    is_dump_truck,
    unloading_type,
    side_height,
    is_in_charter,
    drivers_count,
    status,
  } = props.car
  const isArchived = status === CarStatuses.archived
  const from = useLocationFrom()

  return (
    <S.VehicleItem isArchived={isArchived} isSelected={props.isSelected} onClick={() => props.onClick(id)}>
      <S.CarDetailButton
        to={{
          pathname: generatePath(CarrierRoutes.CarDetail, { id: userId, car_id: id }),
          state: { from },
        }}
      >
        <StyledIconLinkOpener />
      </S.CarDetailButton>

      <S.StateNumbers>
        {licence_number}
        {default_trailer?.licence_number && (
          <>
            {' / '}
            <S.TrailerStateNumber>{default_trailer?.licence_number}</S.TrailerStateNumber>
          </>
        )}
      </S.StateNumbers>

      <S.TypeOfCar>
        {!!car_type && t(`vehicleTypes.${car_type}`)}
        {is_dump_truck && `, ${t('is_dump_truck')}`}
      </S.TypeOfCar>

      <S.AdditionalInfo isSelected={props.isSelected}>
        <S.Unloading>
          {t('card.unloading')}
          {': '}
          {unloading_type ? t(`unloadingType.${unloading_type}`) : ''}
        </S.Unloading>

        <S.SideHeight>
          {t('card.side_height')}
          {': '}
          {formatNumber(side_height)} {t('side_height_units')}
        </S.SideHeight>

        <S.NumOfDrivers>
          {t('driversCount')}: {drivers_count}
        </S.NumOfDrivers>

        {is_in_charter && (
          <S.CharterLabel>
            {t('charter')} <IconCheckmark />
          </S.CharterLabel>
        )}
      </S.AdditionalInfo>
    </S.VehicleItem>
  )
}
