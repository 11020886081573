import React from 'react'
import { useTranslation } from 'react-i18next'

import { Progress } from '@agro-club/agroclub-shared'

import { useCallStatisticsAverage } from 'modules/domain/callStatistics/hooks'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import {
  AverageInner,
  Column,
  HeaderTitle,
  Row,
  Title,
  Value,
} from 'views/pages/CallStatistics/CallStatisticsTable/styled'

const AverageStatisticsTable = () => {
  const { t } = useTranslation('callStatistics')
  const [progress, data] = useCallStatisticsAverage()

  if (progress !== Progress.SUCCESS) {
    return <TComponents.Spinner />
  }

  return (
    <AverageInner>
      <HeaderTitle>{t('columnsTitle.average_employee')}</HeaderTitle>
      <Row>
        <div>
          <Title>{t('columnsTitle.calls_successful')}</Title>
          <Value>{data.avg_successful_calls_count}</Value>
        </div>
      </Row>
      <Row>
        <Column>
          <Title>{t('columnsTitle.incoming')}</Title>
          <Value>{data.avg_incoming_calls_percent}%</Value>
        </Column>
        <Column>
          <Title>{t('columnsTitle.outgoing')}</Title>
          <Value>{data.avg_outgoing_calls_percent}%</Value>
        </Column>
        <Column>
          <Title>{t('columnsTitle.per_day')}</Title>
          <Value>{data.avg_per_day_calls_count}</Value>
        </Column>
        <Column>
          <Title>{t('columnsTitle.per_hour')}</Title>
          <Value>{data.avg_per_hour_calls_count}</Value>
        </Column>
      </Row>
      <Row>
        <Column>
          <Title>{t('columnsTitle.average_duration')}</Title>
          <Value>{data.avg_calls_duration}</Value>
        </Column>
      </Row>
    </AverageInner>
  )
}

export default AverageStatisticsTable
