import { Progress } from '@agro-club/agroclub-shared'
import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer'

import { CarStateUnity } from 'modules/domain/car/selectors'
import { Car, CarDTO, CarLogisticsDeal, CarState, CarStatuses, UnityState } from 'modules/domain/car/types'
import { AddError, CarDeals, Documents, FetchError, RemoveError, RespFile, UpdateError } from 'modules/domain/types'
import { arrToDict, getIds } from 'modules/utils/helpers'
import { Dict } from 'types/generics.d'

const DEFAULT_DOCUMENTS_STATE: Documents = {
  fetchProgress: Progress.IDLE,
  uploadProgress: Progress.IDLE,
  files: [],
}

const DEFAULT_CAR_DEALS_STATE: CarDeals = {
  fetchProgress: Progress.IDLE,
  deals: [],
}

const initialState: CarState = {
  [CarStateUnity.User]: {},
  [CarStateUnity.RelevantCarsByDeal]: {},
  [CarStateUnity.InvolvedCarsByDeal]: {},
  carDeals: {},

  items: {},
  meta: {},
  ids: [],

  itemFetchProgress: Progress.IDLE,
  itemFetchError: null,
  addProgress: Progress.IDLE,
  addError: null,
  updateProgress: Progress.IDLE,
  updateError: null,
  removeProgress: Progress.IDLE,
  removeError: null,

  documents: {},
  deleteDocumentProgress: {},

  changeStatusProgress: {},
}

const removeIds = (linkedCars: Dict<UnityState>, id: string) =>
  Object.keys(linkedCars).reduce((acc: Dict<UnityState>, key) => {
    acc[key] = {
      ...linkedCars[key],
      carsIds: linkedCars[key].carsIds.filter(item => String(item) !== id),
    }
    return acc
  }, {})

class CarReducer extends ImmerReducer<CarState> {
  listRequested(unity: CarStateUnity, id: string) {
    this.draftState[unity][id] = {
      carsIds: [],
      listFetchProgress: Progress.WORK,
      listFetchErrorDetail: undefined,
      listFetchError: null,
    }
  }

  listRequestSucceed(unity: CarStateUnity, id: string, list: Car[]) {
    this.draftState[unity][id] = {
      carsIds: getIds(list),
      listFetchProgress: Progress.SUCCESS,
      listFetchErrorDetail: undefined,
      listFetchError: null,
    }

    this.draftState.items = { ...this.draftState.items, ...arrToDict(list) }
    this.draftState.meta = {
      ...this.draftState.meta,
      ...arrToDict(
        list.map(item => ({
          id: item.id,
          fetchProgress: Progress.SUCCESS,
          fetchError: null,
          removeProgress: Progress.IDLE,
          removeError: null,
          updateProgress: Progress.IDLE,
          updateError: null,
        })),
      ),
    }
    this.draftState.ids = Object.keys(this.draftState.items)
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  listRequestFailed(unity: CarStateUnity, id: string, error: FetchError, errorDetail?: string) {
    this.draftState[unity][id] = {
      ...this.draftState[unity][id],
      listFetchProgress: Progress.ERROR,
      listFetchError: error,
      listFetchErrorDetail: errorDetail,
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  itemRequested(id: string) {
    this.draftState.itemFetchProgress = Progress.WORK

    const meta = {
      id,
      updateProgress: Progress.IDLE,
      updateError: null,
      removeProgress: Progress.IDLE,
      removeError: null,
    }

    this.draftState.meta[id] = {
      ...meta,
      ...this.draftState.meta[id],
      fetchProgress: Progress.WORK,
      fetchError: null,
    }
  }

  itemRequestSucceed(item: Car) {
    this.draftState.itemFetchProgress = Progress.SUCCESS
    this.draftState.meta[item.id].fetchProgress = Progress.SUCCESS
    this.draftState.meta[item.id].fetchError = null
    this.draftState.meta[item.id].fetchErrorDetail = undefined
    this.draftState.items[item.id] = item
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  itemRequestFailed(id: string, error: FetchError, errorDetail?: string) {
    this.draftState.itemFetchProgress = Progress.ERROR
    this.draftState.meta[id].fetchProgress = Progress.ERROR
    this.draftState.meta[id].fetchError = error
    this.draftState.meta[id].fetchErrorDetail = errorDetail
  }

  addRequested(_dto: CarDTO) {
    this.draftState.addProgress = Progress.WORK
    this.draftState.addError = null
    this.draftState.addErrorDetail = undefined
    this.draftState.addErrorFields = undefined
  }

  addSucceed(item: Car) {
    this.draftState.addProgress = Progress.SUCCESS
    this.draftState.items[item.id] = item
    this.draftState.meta[item.id] = {
      id: item.id,
      fetchProgress: Progress.SUCCESS,
      fetchError: null,
      updateProgress: Progress.IDLE,
      updateError: null,
      removeProgress: Progress.IDLE,
      removeError: null,
    }
    this.draftState.addErrorDetail = undefined
    this.draftState.addErrorFields = undefined
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  addFailed(error: AddError, errorDetail?: string, errorFields?: Record<string, string[]>) {
    this.draftState.addProgress = Progress.ERROR
    this.draftState.addError = error
    this.draftState.addErrorDetail = errorDetail
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.draftState.addErrorFields = errorFields
  }

  updateRequested(id: string, _item: Partial<CarDTO>) {
    this.draftState.updateProgress = Progress.WORK
    this.draftState.meta[id].updateProgress = Progress.WORK
    this.draftState.meta[id].updateError = null
    this.draftState.meta[id].updateErrorDetail = undefined
    this.draftState.meta[id].updateErrorFields = undefined
  }

  updateSucceed(item: Car) {
    this.draftState.items[item.id] = item
    this.draftState.updateProgress = Progress.SUCCESS
    this.draftState.meta[item.id].updateProgress = Progress.SUCCESS
    this.draftState.meta[item.id].updateError = null
    this.draftState.meta[item.id].updateErrorDetail = undefined
    this.draftState.meta[item.id].updateErrorFields = undefined
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  updateFailed(id: string, error: UpdateError, errorText?: string, errorFields?: Record<string, string[]>) {
    this.draftState.updateProgress = Progress.ERROR
    this.draftState.meta[id].updateProgress = Progress.ERROR
    this.draftState.meta[id].updateError = error
    this.draftState.meta[id].updateErrorDetail = errorText
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.draftState.meta[id].updateErrorFields = errorFields
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  removeRequested(id: string) {
    this.draftState.removeProgress = Progress.WORK
    this.draftState.meta[id].removeProgress = Progress.WORK
    this.draftState.meta[id].removeError = null
    this.draftState.meta[id].removeErrorDetail = undefined
  }

  removeSucceed(id: string) {
    this.draftState.removeProgress = Progress.SUCCESS
    delete this.draftState.items[id]
    delete this.draftState.meta[id]
    this.draftState.ids = this.draftState.ids.filter(vId => vId !== id)

    this.draftState[CarStateUnity.User] = removeIds(this.draftState[CarStateUnity.User], id)
    this.draftState[CarStateUnity.RelevantCarsByDeal] = removeIds(this.draftState[CarStateUnity.RelevantCarsByDeal], id)
    this.draftState[CarStateUnity.InvolvedCarsByDeal] = removeIds(this.draftState[CarStateUnity.InvolvedCarsByDeal], id)
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  removeFailed(id: string, error: RemoveError, errorDetail?: string) {
    this.draftState.removeProgress = Progress.ERROR
    this.draftState.meta[id].removeProgress = Progress.ERROR
    this.draftState.meta[id].removeError = error
    this.draftState.meta[id].removeErrorDetail = errorDetail
  }

  documentsRequested(id: string) {
    this.draftState.documents[id] = {
      ...DEFAULT_DOCUMENTS_STATE,
      ...this.draftState.documents[id],
      fetchProgress: Progress.WORK,
    }
  }

  documentsRequestSuccess(id: string, files: RespFile[]) {
    this.draftState.documents[id] = {
      ...DEFAULT_DOCUMENTS_STATE,
      ...this.draftState.documents[id],
      fetchProgress: Progress.SUCCESS,
      files,
    }
  }

  documentsRequestFailed(id: string) {
    this.draftState.documents[id] = {
      ...DEFAULT_DOCUMENTS_STATE,
      ...this.draftState.documents[id],
      fetchProgress: Progress.ERROR,
    }
  }

  uploadDocumentsRequested(id: string, _files: File[]) {
    this.draftState.documents[id] = {
      ...DEFAULT_DOCUMENTS_STATE,
      ...this.draftState.documents[id],
      uploadProgress: Progress.WORK,
    }
  }

  uploadDocumentsSuccess(id: string, files: RespFile[]) {
    this.draftState.documents[id] = {
      ...DEFAULT_DOCUMENTS_STATE,
      ...this.draftState.documents[id],
      uploadProgress: Progress.SUCCESS,
      files: [...files, ...(this.draftState.documents?.[id]?.files ?? [])],
    }
  }

  uploadDocumentsFailed(id: string) {
    this.draftState.documents[id] = {
      ...DEFAULT_DOCUMENTS_STATE,
      ...this.draftState.documents[id],
      uploadProgress: Progress.ERROR,
    }
  }

  deleteDocumentRequested(_carId: string, fileId: string) {
    this.draftState.deleteDocumentProgress[fileId] = Progress.WORK
  }

  deleteDocumentSucccess(carId: string, fileId: string) {
    this.draftState.documents[carId] = {
      ...this.draftState.documents[carId],
      files: this.draftState.documents[carId].files.filter(file => String(file.pk) !== String(fileId)),
    }
    this.draftState.deleteDocumentProgress[fileId] = Progress.SUCCESS
  }

  deleteDocumentFailed(fileId: string) {
    this.draftState.deleteDocumentProgress[fileId] = Progress.ERROR
  }

  carDealsRequested(carId: string) {
    this.draftState.carDeals[carId] = {
      ...DEFAULT_CAR_DEALS_STATE,
      ...this.draftState.documents[carId],
      fetchProgress: Progress.WORK,
    }
  }

  carDealsRequestSuccess(carId: string, deals: CarLogisticsDeal[]) {
    this.draftState.carDeals[carId] = {
      ...DEFAULT_CAR_DEALS_STATE,
      ...this.draftState.carDeals[carId],
      fetchProgress: Progress.SUCCESS,
      deals,
    }
  }

  carDealsRequestFailed(carId: string) {
    this.draftState.carDeals[carId] = {
      ...DEFAULT_CAR_DEALS_STATE,
      ...this.draftState.carDeals[carId],
      fetchProgress: Progress.ERROR,
    }
  }

  changeStatusRequested(id: string, _status: CarStatuses) {
    this.draftState.changeStatusProgress[id] = Progress.WORK
  }

  changeStatusSuccess(id: string, status: CarStatuses) {
    this.draftState.changeStatusProgress[id] = Progress.SUCCESS
    this.draftState.items[id] = {
      ...this.draftState.items[id],
      status,
    }
  }

  changeStatusError(id) {
    this.draftState.changeStatusProgress[id] = Progress.ERROR
  }
}

export const CarActions = createActionCreators(CarReducer)
export default CarActions
export const reducer = createReducerFunction(CarReducer, initialState)
