import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Checkbox, Input, SectionBody } from '@agro-club/agroclub-shared'
import { FormikContextType } from 'formik'

import CompanySelectors from 'modules/domain/company/selectors'
import { FormSection, LabeledContainer } from 'views/components/FormComponents/FormComponents'
import { Agreement, Container, DetailHeader } from 'views/pages/Company/CompanyDetail/styled'
import { CompanyEditableFields } from 'views/pages/Company/CompanyDetail/types'
import { FormItem } from 'views/ui/FormItem/FormItem'
import { NumberInput } from 'views/ui/NumberInput/NumberInput'

type Props = {
  mode: 'edit' | 'create'
  formik: FormikContextType<CompanyEditableFields>
  id?: string
}

export const CompanyForm: React.FC<Props> = props => {
  const { mode, formik, id } = props
  const isEdit = mode === 'edit'
  const { t } = useTranslation('company')
  const addErrorFields = useSelector(CompanySelectors.addErrorFields)
  const meta = useSelector(state => CompanySelectors.meta(state, id))

  const errorFields = isEdit ? meta.updateErrorFields : addErrorFields

  return (
    <Container>
      <DetailHeader>{t('commonInfo')}</DetailHeader>
      <SectionBody>
        <FormSection>
          <FormItem
            fieldName="inn"
            errorFields={errorFields}
            render={additionalProps => (
              <Input label={t('form.fields.inn')} {...formik.getFieldProps('inn')} {...additionalProps} />
            )}
          />
          <FormItem
            fieldName="state_reg_number"
            errorFields={errorFields}
            render={additionalProps => (
              <Input
                label={t('form.fields.state_reg_number')}
                {...formik.getFieldProps('state_reg_number')}
                {...additionalProps}
              />
            )}
          />

          {!isEdit && (
            <FormItem
              fieldName="name"
              errorFields={errorFields}
              render={additionalProps => (
                <Input label={t('form.fields.name')} {...formik.getFieldProps('name')} {...additionalProps} />
              )}
            />
          )}

          <NumberInput
            isInteger
            label={t('form.fields.payment_delay_days')}
            onChange={value => formik.setFieldValue('payment_delay_days', value)}
            value={formik.values.payment_delay_days || '0'}
            max={365}
          />
          <LabeledContainer label={t('form.fields.agreementType')}>
            <Agreement>
              <Checkbox
                isChecked={formik.values.cooperation_type_agent}
                onChange={(_value, isChecked) => {
                  formik.setFieldValue('cooperation_type_agent', isChecked)
                }}
                label={t('form.fields.cooperation_type_agent')}
              />
              <Checkbox
                isChecked={formik.values.cooperation_type_trader}
                onChange={(_value, isChecked) => {
                  formik.setFieldValue('cooperation_type_trader', isChecked)
                }}
                label={t('form.fields.cooperation_type_trader')}
              />
            </Agreement>
          </LabeledContainer>
        </FormSection>
      </SectionBody>
    </Container>
  )
}
