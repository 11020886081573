import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Progress, useAction } from '@agro-club/agroclub-shared'

import UserAddressActions from 'modules/domain/userAddress/duck'
import UserAddressSelectors from 'modules/domain/userAddress/selectors'
import { UserAddress } from 'modules/domain/userAddress/types'

export const useUserAddress = (userId?: string): [Progress, UserAddress[]] => {
  const progress = useSelector(state => UserAddressSelectors.listFetchProgress(state, userId))
  const list = useSelector(state => UserAddressSelectors.addressesByUserId(state, userId))
  const fetchAction = useAction(UserAddressActions.listRequested, userId)

  useEffect(() => {
    userId && fetchAction()
  }, [userId, fetchAction])

  return [progress, list]
}
