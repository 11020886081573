import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useTable } from 'react-table'

import {
  Progress,
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHead,
  TableHeadRow,
  TableNoData,
  useAction,
  usePersistentScroll,
} from '@agro-club/agroclub-shared'

import TaskActions from 'modules/domain/task/duck'
import { useTaskList } from 'modules/domain/task/hooks'
import TaskSelectors from 'modules/domain/task/selectors'
import { Task } from 'modules/domain/task/types'
import { AssigneesFilter } from 'views/components/AssigneesFilter/AssigneesFilter'
import { useOnChangeAssignee } from 'views/components/AssigneesFilter/hooks/useOnChangeAssignee'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import { RangeFilter } from 'views/components/TableFilters/RangeFilter'
import { ResetFilterButton } from 'views/components/TableFilters/ResetFilterButton'
import {
  LinkedBidCell,
  LinkedDealCell,
  LinkedUserCell,
  TaskSortableHeadCell,
  TaskStatusCell,
} from 'views/components/TaskCells/TaskCells'
import { TaskModalDetail } from 'views/components/TaskModalDetail/TaskModalDetail'
import { TeamFilter } from 'views/components/TeamFilter/TeamFilter'

export const TaskTable: React.FC = () => {
  const { t } = useTranslation('task')
  const [progress, data] = useTaskList()
  const sortingUpdated = useAction(TaskActions.sortingUpdated)
  const listRequested = useAction(TaskActions.listRequested)
  const filterUpdated = useAction(TaskActions.filterUpdated)
  const filterState = useSelector(TaskSelectors.filter)
  const total = useSelector(TaskSelectors.total)
  const pages = useSelector(TaskSelectors.pages)
  const page = useSelector(TaskSelectors.page)
  const pageSize = useSelector(TaskSelectors.pageSize)
  const [openTask, setOpenTask] = useState<string | undefined>()

  const visibleColumns = React.useMemo(
    () => [
      {
        Header: t('list.tableHeaders.deadline'),
        accessor: 'deadline' as const,
        sortable: false,
        Cell: TComponents.DateTimeCell,
      },
      {
        Header: t('list.tableHeaders.subject'),
        accessor: 'subject' as const,
        sortable: false,
      },
      {
        Header: t('common:team'),
        accessor: 'assigned_team.name' as const,
      },
      {
        Header: t('common:user'),
        accessor: 'linked_user.short_name',
        Cell: LinkedUserCell,
      },
      {
        Header: t('common:deal'),
        accessor: 'linked_deal.id',
        Cell: LinkedDealCell,
      },
      {
        Header: t('common:bid'),
        accessor: 'linked_bid.id',
        Cell: LinkedBidCell,
      },
      {
        Header: t('list.tableHeaders.assignee'),
        accessor: 'assignee.short_name' as const,
      },
      {
        Header: t('list.tableHeaders.status'),
        accessor: 'is_completed' as const,
        Cell: TaskStatusCell,
      },
    ],
    [t],
  )

  const hiddenColumns: (keyof Task)[] = useMemo(() => ['id'], [])

  const columnsAll = useMemo(
    () => [
      ...visibleColumns,
      ...hiddenColumns.map(col => ({
        Header: col,
        accessor: col,
        hidden: true,
      })),
    ],
    [hiddenColumns, visibleColumns],
  )

  const { columns, rows, prepareRow } = useTable<Task>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore weird issue with react-table typings — having more then 26 fields in type causes TS error
    columns: columnsAll,
    data: data as Task[],
    initialState: { hiddenColumns },
  })

  const setPage = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  const { scrollRef } = usePersistentScroll('taskList')

  const handleClearFilters = useCallback(() => {
    filterUpdated({})
  }, [filterUpdated])

  const isFilterApplied = Object.values(filterState).some(Boolean)
  const selectedTeams = filterState.team

  const onChangeTeam = (id: string) => {
    const set = new Set(selectedTeams)
    if (set.has(id)) {
      set.delete(id)
    } else {
      set.add(id)
    }
    filterUpdated({ ...filterState, team: [...set] })
  }

  const onChangeDeadline = React.useCallback(
    ([start, end]) => {
      filterUpdated({ ...filterState, deadline_after: start, deadline_before: end })
    },
    [filterState, filterUpdated],
  )

  const onClearAssignee = useCallback(() => {
    filterUpdated({ ...filterState, assignee: [] })
  }, [filterState, filterUpdated])

  const onAssigneeChange = useOnChangeAssignee({ filterUpdated, filterState })

  return (
    <TComponents.Wrapper>
      <TComponents.Filters>
        <TeamFilter
          selectedIds={selectedTeams}
          onChange={onChangeTeam}
          onClear={() => {
            filterUpdated({ ...filterState, team: undefined })
          }}
        />
        <RangeFilter
          label={t('form.deadline')}
          onChange={onChangeDeadline}
          start={filterState.deadline_after}
          end={filterState.deadline_before}
        />
        <AssigneesFilter onAssigneeChange={onAssigneeChange} onClear={onClearAssignee} values={filterState.assignee} />
        <ResetFilterButton {...{ handleClearFilters, isFilterApplied }} />
      </TComponents.Filters>

      <Table total={total} pages={pages} pageSize={pageSize} currentPage={page} onSetPage={setPage} ref={scrollRef}>
        <TableHead>
          <TableHeadRow>
            {columns.map(column => (
              <TaskSortableHeadCell
                zIndex={1}
                key={column.getHeaderProps().key}
                id={column.id as keyof Task}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                sortable={column.sortable}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                hidden={column.hidden}
                onChange={sortingUpdated}
              >
                {column.render('Header')}
              </TaskSortableHeadCell>
            ))}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map(row => {
            prepareRow(row)
            const { key, ...props } = row.getRowProps()
            return (
              <TableBodyRow
                key={key}
                {...props}
                onClick={() => {
                  setOpenTask(row.original.id)
                }}
              >
                {row.cells.map(cell => {
                  const { key, ...props } = cell.getCellProps()
                  return (
                    <TableBodyCell key={key} {...props}>
                      {cell.render('Cell')}
                    </TableBodyCell>
                  )
                })}
              </TableBodyRow>
            )
          })}
          <TableNoData
            progress={progress}
            isEmpty={!rows.length}
            colSpan={visibleColumns.length}
            loading={<TComponents.Spinner />}
          >
            <div>{isFilterApplied ? t('list.emptyFilterMsg') : t('list.emptyMsg')}</div>
            {isFilterApplied && progress !== Progress.WORK && (
              <TComponents.ClearButton intent="cancel" size="small" onClick={handleClearFilters}>
                {t('common:resetAllFilters')}
              </TComponents.ClearButton>
            )}
          </TableNoData>
        </TableBody>
      </Table>
      <TaskModalDetail taskId={openTask} isOpen={!!openTask} close={() => setOpenTask(undefined)} />
    </TComponents.Wrapper>
  )
}
