import { Progress } from '@agro-club/agroclub-shared'

import { Address } from 'modules/domain/types'
import { AppGlobalState } from 'modules/types'

const listFetchProgress = (state: AppGlobalState, userId?: string) =>
  userId ? state.userAddress.items?.[userId]?.listFetchProgress || Progress.IDLE : Progress.IDLE
const addProgress = (state: AppGlobalState, userId?: string) =>
  userId ? state.userAddress.items?.[userId]?.addProgress || Progress.IDLE : Progress.IDLE
const addError = (state: AppGlobalState) => state.userAddress.addError
const addErrorDetail = (state: AppGlobalState) => state.userAddress.addErrorDetail
const addressesByUserId = (state: AppGlobalState, userId?: string) => {
  if (!userId) {
    return []
  }

  return state.userAddress.items?.[userId]?.addressIds?.map(id => state.userAddress.addressDict[id]) || []
}
const deleteProgress = (state: AppGlobalState, addressId: string) =>
  state.userAddress.deleteProgress?.[addressId] || Progress.IDLE
const deleteError = (state: AppGlobalState, addressId: string) => state.userAddress.deleteError?.[addressId]
const deleteErrorDetail = (state: AppGlobalState, addressId: string) => state.userAddress.deleteErrorDetail?.[addressId]
const editProgress = (state: AppGlobalState, addressId: string) =>
  state.userAddress.editProgress?.[addressId] || Progress.IDLE

const addressById = (state: AppGlobalState, id?: string) =>
  (!!id && state.userAddress.addressDict[id]) || ({} as Address)

const UserAddressSelectors = {
  addProgress,
  listFetchProgress,
  addressesByUserId,
  deleteProgress,
  deleteError,
  deleteErrorDetail,
  editProgress,
  addError,
  addErrorDetail,
  addressById,
}

export default UserAddressSelectors
