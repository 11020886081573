import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Button, Progress, useAction, useHelmet, useHistoryPush } from '@agro-club/agroclub-shared'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import env from 'env'
import { useNotificationProgress } from 'hooks/useNotificationProgress'
import usePrevious from 'hooks/usePrevious'
import useTonsAndBags, { tonsToBags } from 'hooks/useTonsAndBags'
import BidActions from 'modules/domain/bid/duck'
import { useBid } from 'modules/domain/bid/hooks'
import BidSelectors from 'modules/domain/bid/selectors'
import { ParameterCondition } from 'modules/domain/bid/types'
import { useProducts } from 'modules/domain/collection/hooks'
import { ProfileType } from 'modules/domain/user/types'
import Item404 from 'views/layouts/Item404/Item404'
import ItemGenericError from 'views/layouts/ItemGenericError/ItemGenericError'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import { MainParam } from 'views/pages/Bid/BidAdd/MainParam'
import { Params } from 'views/pages/Bid/BidAdd/Params'
import * as Styled from 'views/pages/Bid/BidAdd/styled'
import { BidEditableFields } from 'views/pages/Bid/BidAdd/types'
import { useBidGoBack } from 'views/pages/Bid/hooks'
import BidRoutes from 'views/pages/Bid/routes'
import * as Header from 'views/ui/Header/Header'
import { NumberInput } from 'views/ui/NumberInput/NumberInput'

import { AgroundImputsSelect } from '../components/AgroundImputsSelect'
import { BidTagSelect } from '../components/BidTagSelect'
import { TaxesRegimeSelect } from '../components/TaxesRegimeSelect'

export const BidEdit: React.FC = () => {
  const { t } = useTranslation('bid')
  const { id } = useParams<{ id: string }>()
  const [fetchProgress, bid] = useBid(id)
  const [, products] = useProducts()
  const product = products?.find(p => p.id === bid?.product.id)
  const updateAction = useAction(BidActions.updateRequested)
  const progress = useSelector(BidSelectors.updateProgress)
  const currency = env.REACT_APP_CURRENCY_SYMBOL
  const [bags, tonsToBagsCallback, bagsToTonsCallback, setBags] = useTonsAndBags(bid?.quantity)

  useNotificationProgress(progress, t('form.notifyEditSuccess'))

  useHelmet({ title: `${t('form.edit')} - ${product?.title}` })

  const push = useHistoryPush()
  const prevProgress = usePrevious(progress)
  useEffect(() => {
    if (progress === Progress.SUCCESS && prevProgress === Progress.WORK) {
      push({ route: BidRoutes.Detail, params: { id } })
    }
  }, [id, prevProgress, progress, push])

  useEffect(() => {
    if (bid?.quantity) {
      setBags(tonsToBags(bid?.quantity))
    }
  }, [bid, setBags])

  const validationSchema = useMemo(
    () =>
      Yup.object({
        price: Yup.string().required(),
        quantity: Yup.string().required(),
      }),
    [],
  )

  const price = bid?.bid_type === 'purchase' ? bid?.price_cpt : bid?.price_exw

  const mainParam = bid?.parameters.find(p => p.parameter.id === product?.main_parameter?.id)

  const formik = useFormik<BidEditableFields>({
    validationSchema,
    initialValues: {
      price,
      quantity: bid?.quantity,
      parameters:
        bid?.parameters
          .filter(p => p.parameter.id !== product?.main_parameter?.id)
          .map(p => ({ parameter: p.parameter.id, condition: p.condition, parameter_value: p.parameter_value })) || [],
      mainParameter: {
        parameter: mainParam?.parameter.id,
        condition: mainParam?.condition as keyof typeof ParameterCondition,
        parameter_value: mainParam?.parameter_value,
      },
      aground_imputs: bid?.aground_imputs,
      tax_regime: bid?.tax_regime,
      bid_tag: bid?.bid_tag,
    },
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: () => {
      if (!formik.isValid) {
        return
      }
      const { quantity, price, mainParameter, parameters, tax_regime, aground_imputs, bid_tag } = formik.values
      const dto = {
        quantity,
        price,
        tax_regime,
        aground_imputs,
        bid_tag,
        parameters: [...parameters, mainParameter].filter(p => typeof p.parameter_value !== 'undefined'),
      }
      updateAction(id, dto)
    },
  })

  const meta = useSelector(state => BidSelectors.meta(state, id))
  const goBack = useBidGoBack()
  const loading = () => <ItemLoadingLayout id={id} onBack={goBack} />
  const error404 = () => <Item404 id={id} onBack={goBack} title={t('errors.notFoundTitle')} />
  const errorUnknown = () => <ItemGenericError id={id} onBack={goBack} title={t('errors.unknownErrorTitle')} />

  if (fetchProgress === Progress.ERROR) {
    if (meta.fetchError === 'not_found') {
      return error404()
    }
    return errorUnknown()
  }

  if (fetchProgress === Progress.WORK || !bid) {
    return loading()
  }

  const priceLabel =
    bid.owner.profile?.profile_type === ProfileType.seller
      ? 'form.priceExw'
      : bid.owner.profile?.profile_type === ProfileType.customer
      ? 'form.priceCpt'
      : 'form.priceDefault'

  return (
    <>
      <Layout.Header hasBottomBorder>
        <Header.Root
          rightBlock={
            <Header.ButtonWrapper>
              <Button
                disabled={!formik.dirty}
                filled
                intent="primary"
                size="small"
                onClick={formik.submitForm}
                progress={progress}
              >
                {t('common:save')}
              </Button>
              <Button intent="primary" title={t('common:cancel')} size="small" onClick={goBack}>
                {t('common:cancel')}
              </Button>
            </Header.ButtonWrapper>
          }
        >
          <Header.Title title={t('form.edit')} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <Styled.TwoColumn>
          <Styled.Column>
            <Styled.Box>
              <Styled.PriceQuantity>
                <NumberInput
                  invalid={formik.touched.price && !!formik.errors.price}
                  label={t(priceLabel, { currency })}
                  value={formik.values.price}
                  onChange={v => {
                    formik.setFieldValue('price', v)
                  }}
                  max={1000}
                />
                <NumberInput
                  invalid={formik.touched.quantity && !!formik.errors.quantity}
                  label={t('form.quantity_bag')}
                  value={bags}
                  onChange={bagsToTonsCallback(v => formik.setFieldValue('quantity', v))}
                />
                <NumberInput
                  invalid={formik.touched.quantity && !!formik.errors.quantity}
                  label={t('form.quantity')}
                  value={formik.values.quantity}
                  onChange={tonsToBagsCallback(v => formik.setFieldValue('quantity', v))}
                />
              </Styled.PriceQuantity>
              <Styled.TaxesContainer>
                <AgroundImputsSelect formik={formik} />
                <TaxesRegimeSelect formik={formik} />
              </Styled.TaxesContainer>
              {bid.owner.profile?.profile_type === ProfileType.customer && (
                <Styled.TaxesContainer>
                  <BidTagSelect formik={formik} />
                </Styled.TaxesContainer>
              )}
              {product && product.main_parameter && (
                <MainParam
                  parameters={[product.main_parameter]}
                  parameter={formik.values.mainParameter}
                  onChange={values => formik.setFieldValue('mainParameter', values)}
                />
              )}
              {product && (
                <Params
                  {...{
                    formik,
                    product,
                  }}
                />
              )}
            </Styled.Box>
          </Styled.Column>
        </Styled.TwoColumn>
      </Layout.Content>
    </>
  )
}
