import { LIST_PAGE_SIZE } from 'modules/constants'
import { DashboardFilter, DashboardTask } from 'modules/domain/dashboard/types'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { ListResponse } from 'types/api.d'

export const getResponses = (filter: DashboardFilter) =>
  apiClient.get<ListResponse<DashboardTask>>(endpoints.dashboardResponses(), { ...filter, page_size: 10000 })

export const getMatches = (filter: DashboardFilter) =>
  apiClient.get<ListResponse<DashboardTask>>(endpoints.dashboardMatches(), { ...filter, page_size: 10000 })

export const getNew = (filter: DashboardFilter) =>
  apiClient.get<ListResponse<DashboardTask>>(endpoints.dashboardNew(), { ...filter, page_size: 10000 })

export const getNewUsers = (filter: DashboardFilter, page?: number) =>
  apiClient.get<ListResponse<DashboardTask>>(endpoints.dashboardNewUsers(), {
    ...filter,
    page_size: LIST_PAGE_SIZE,
    page: page || 1,
  })
