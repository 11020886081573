import formatSortParam from 'helpers/formatSortParam'
import { DocumentWithTypeFile } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { ListResponse } from 'types/api.d'

import {
  Carrier,
  CarrierDTO,
  CarrierListRequestFilter,
  CarrierListRequestSorting,
  Trailer,
  TrailerDTO,
  UpdateTrailerDTO,
} from './types'

export const getList = (
  filter: CarrierListRequestFilter,
  sorting: CarrierListRequestSorting,
  page: number,
  pageSize: number,
) =>
  apiClient.get<ListResponse<Carrier>>(endpoints.carrier(), {
    ...filter,
    ...(filter.region && filter.region.length && { region: filter.region.join(',') }),
    sort: formatSortParam(sorting),
    page,
    page_size: pageSize,
  })

export const getItem = (id: string) => apiClient.get<Carrier>(endpoints.carrier(id))

export const addItem = (dto: CarrierDTO) => apiClient.post<Carrier>(endpoints.carrier(), dto)

export const updateItem = (id: string, dto: Partial<CarrierDTO>) => apiClient.put<Carrier>(endpoints.carrier(id), dto)

export const removeItem = (id: string) => apiClient.delete<Carrier>(endpoints.carrier(id))

export const getDocumentTypes = (id: string) =>
  apiClient.get<ListResponse<DocumentType>>(endpoints.carrierDocumentTypes(id))

export const uploadFile = (id: string, document_type: string, file: File) => {
  const form = new FormData()
  form.append('uploaded_file', file)
  form.append('document_type', document_type)
  return apiClient.post<DocumentWithTypeFile>(endpoints.carriersDocuments(id), form)
}

export const getDocumentFiles = (id: string) =>
  apiClient.get<ListResponse<DocumentType>>(endpoints.carrierDocumentFiles(id))

export const deleteDocument = (userId: string, fileId: string) => apiClient.delete(endpoints.userFiles(userId, fileId))

export const deleteDocumentFile = (id: string, document_id: string) =>
  apiClient.delete(endpoints.carrierDeleteDocumentFile(id, document_id))

export const getCarrierTrailers = (id: string) => apiClient.get<Trailer[]>(endpoints.carrierTrailers(id))

export const addTrailer = (dto: TrailerDTO) => apiClient.post(endpoints.trailers(), dto)

export const updateTrailer = (id: string, dto: UpdateTrailerDTO) => apiClient.put(endpoints.trailers(id), dto)

export const archiveTrailer = (id: string) => apiClient.post(endpoints.archiveTrailers(id), { new_status: 'archived' })
