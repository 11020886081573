import { Link } from 'react-router-dom'

import { Table, Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import { smallBox } from 'views/styled/common'
import { Phone } from 'views/ui/Phone/Phone'

export const MultiColumnsHeader = styled.div`
  display: flex;
  align-items: center;

  & > div {
    margin-right: 20px;
  }

  svg {
    fill: ${({ theme }) => theme.color.secondary300};
  }
`

// TripBlocksList

export const TripsDay = styled.div`
  margin-bottom: calc(40px - 16px);
`
export const TripDate = styled.div`
  ${Typography.body1Bold};
  display: flex;
  margin: 32px 12px 8px 12px;
  text-transform: uppercase;
`

export const TripTable = styled.div`
  width: 896px;
`

// TripCar

export const TripTableRow = styled.div`
  display: flex;
  position: relative;
  min-height: 60px;
  margin: 0 16px 8px 0;
  padding: 10px 28px 10px 12px;

  ${smallBox};
  ${Typography.body2Default}
`

export const TripTableCell = styled.div`
  min-width: 100px;
  margin-right: 12px;
`

export const StateNumbers = styled.div`
  ${Typography.body2Bold};
  margin-bottom: 4px;
`
export const TrailerStateNumber = styled.span`
  ${Typography.body2Default};
`

export const TypeOfCar = styled.div`
  ${Typography.body2Default};
  margin-bottom: 4px;
  color: ${props => props.theme.color.textSecondary};
`
export const TripPrice = styled.div`
  ${Typography.body1Bold};
`

export const ActionsCell = styled.div`
  text-align: right;
`

export const TripEditButton = styled.div`
  width: 32px;
  height: 32px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  border-radius: 8px;
  border: 0;
  padding: 0;
  transition: 0.1s background-color ease-out;
  cursor: pointer;

  background-color: ${({ theme }) => theme.color.primary50};

  svg {
    width: 15px;
    height: 15px;
    fill: ${({ theme }) => theme.color.primary600};
    transition: 0.1s fill ease-out;
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.primary600};

    svg {
      fill: ${({ theme }) => theme.color.onPrimaryLight};
    }
  }
`

export const StyledTable = styled(Table)`
  min-width: unset;
`

export const LogisticsCellWrapper = styled.div`
  text-align: right;
  ${Typography.body2Bold}
`

export const QuantityCellWrapper = styled.div`
  text-align: right;
`

export const BrandName = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
`

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.color.primary600};
  cursor: pointer;
  position: relative;
  &:hover {
    text-decoration: underline;
  }
`

export const RelativeCell = styled.div`
  & a {
    position: relative;
  }
`

export const NoWrap = styled.div`
  white-space: nowrap;
`

export const PhoneUserLink = styled(Phone)`
  position: relative;
`

export const StyledUserLink = styled.a`
  position: relative;
  color: ${({ theme }) => theme.color.primary600};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`
