import React from 'react'
import { useTranslation } from 'react-i18next'

import useFormatDate from 'hooks/useFormatDate'
import { Company } from 'modules/domain/company/types'
import * as Header from 'views/ui/Header/Header'

type Props = {
  company: Company
}

export const CompanySubTitle: React.FC<Props> = props => {
  const { company } = props
  const { t } = useTranslation('company')
  const formatDate = useFormatDate()

  const inn = `${t('form.fields.inn')}: ${company.inn}`
  const ie = `${t('form.fields.state_reg_number')}: ${company.state_reg_number || '-'}`

  const date = `${t('form.fields.created')}: ${formatDate(company.created, true)}`

  return <Header.SubTitle>{[inn, ie, date].filter(Boolean).join(' | ')}</Header.SubTitle>
}
