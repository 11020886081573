import React, { FC, useRef } from 'react'

import { Typography, defaultTheme } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

const SelectableWrapper = styled.span`
  ${Typography.body2Default}
  display: flex;
  align-items: center;
  user-select: text;
  cursor: default;
  position: relative;
  padding: 0 12px;
  pointer-events: none;
  white-space: nowrap;

  & div {
    padding-left: 4px;
  }

  svg {
    width: 20px;
    padding-left: 4px;

    path {
      fill: ${defaultTheme.color.primary600};
    }
  }
`

export const SelectableText: FC = ({ children }) => {
  const drag = useRef<boolean>(false)

  return (
    <SelectableWrapper
      onMouseDown={() => {
        drag.current = false
      }}
      onMouseMove={() => {
        drag.current = true
      }}
      onMouseUp={e => {
        if (drag.current) {
          e.stopPropagation()
          e.preventDefault()
        }
      }}
      onClick={e => {
        if (drag.current) {
          e.stopPropagation()
          e.preventDefault()
        }
      }}
    >
      {children}
    </SelectableWrapper>
  )
}
