import { Link } from 'react-router-dom'
import { StylesConfig } from 'react-select'

import { ReactSelectOption, controlStyle, menuListStyle, menuStyle, optionStyle } from '@agro-club/agroclub-shared'
import styled, { StyledProps } from 'styled-components'

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.color.primary600};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

export const customSelectStyles: StylesConfig<ReactSelectOption, boolean> = {
  control: (...args) => ({
    ...controlStyle(...args),
    height: '32px',
    minHeight: '32px',
    minWidth: '180px',
  }),
  menu: base => ({
    ...base,
    ...menuStyle,
    zIndex: 10,
    width: '100%',
  }),
  menuList: base => ({
    ...base,
    ...menuListStyle,
    maxHeight: '60vh',
    width: '100%',
  }),
  option: base => ({
    ...base,
    ...optionStyle,
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
  }),
}

export const InputWrapper = styled.div`
  & > * > * {
    height: 32px;
  }
`

export const OwnerWrapper = styled.div`
  display: grid;
`

export const LinkWrapper = styled.div`
  z-index: 0;
`

export const StatusWrapper = styled.div<StyledProps<{ $isArchived: boolean }>>`
  color: ${({ theme, $isArchived }) => ($isArchived ? theme.color.textSecondary : 'initial')};
`
