import { Button, IconPencil } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const IconContainer = styled(Button)`
  border: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  transition: background-color 0.1s ease-in;
  &:hover {
    background-color: ${({ theme }) => theme.color.primary50};
    svg {
      fill: ${({ theme }) => theme.color.primary600};
    }
  }
`

export const CardEditIcon = styled(IconPencil)`
  fill: ${props => props.theme.color.secondary200};
  cursor: pointer;
  transition: fill 0.1s ease-in;
`
