import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import normalizeBidAddress from 'helpers/normalizeBidAddress'
import LogisticsDealActions from 'modules/domain/logisticsDeal/duck'
import * as managers from 'modules/domain/logisticsDeal/managers'
import LogisticsDealSelectors from 'modules/domain/logisticsDeal/selectors'
import { LogisticsDeal } from 'modules/domain/logisticsDeal/types'
import UserAddressActions from 'modules/domain/userAddress/duck'
import { RequestError } from 'modules/errors'
import { updateLocationQuery } from 'modules/sagaHelpers'
import { ListResponse } from 'types/api.d'
import LogisticsRoutes from 'views/pages/Logistics/routes'

export const fetchList = function* () {
  try {
    let currentPage = yield select(LogisticsDealSelectors.page)
    const filter = yield select(LogisticsDealSelectors.filter)
    const sorting = yield select(LogisticsDealSelectors.sorting)
    const pageSize = yield select(LogisticsDealSelectors.pageSize)

    let response: ListResponse<LogisticsDeal> = yield call(managers.getList, filter, sorting, currentPage, pageSize)
    const pages = Math.ceil(response.count / pageSize)

    if (pages !== 0 && pages < currentPage) {
      response = yield call(managers.getList, filter, sorting, pages, pageSize)
      currentPage = pages
    }

    const { results, count, current } = response
    yield put(LogisticsDealActions.listRequestSucceed(results, count, current))

    yield call(updateLocationQuery, LogisticsRoutes.List, { page: currentPage, ...filter, ...sorting })
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(LogisticsDealActions.listRequestFailed(type, detail))
  }
}

export const fetchItem = function* ({ payload: id }: ReturnType<typeof LogisticsDealActions.itemRequested>) {
  try {
    const item: LogisticsDeal = yield call(managers.getItem, id)

    const { saleAddress, purchaseAddress, normalizedItem } = normalizeBidAddress(item)
    yield put(UserAddressActions.itemRequestSucceed(saleAddress))
    yield put(UserAddressActions.itemRequestSucceed(purchaseAddress))

    yield put(LogisticsDealActions.itemRequestSucceed(normalizedItem as LogisticsDeal))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(LogisticsDealActions.itemRequestFailed(id, type, detail))
  }
}

export const addItem = function* ({ payload: dto }: ReturnType<typeof LogisticsDealActions.addRequested>) {
  try {
    const item: LogisticsDeal = yield call(managers.addItem, dto)
    yield put(LogisticsDealActions.addSucceed(item))
  } catch (err) {
    const { type, detail, errors } = RequestError.parseError(err)
    yield put(LogisticsDealActions.addFailed(type, detail, errors))
  }
}
export const updateItem = function* ({ payload: [id, dto] }: ReturnType<typeof LogisticsDealActions.updateRequested>) {
  try {
    const item: LogisticsDeal = yield call(managers.updateItem, id, dto)
    yield put(LogisticsDealActions.updateSucceed(item))
  } catch (err) {
    const { type, detail, errors } = RequestError.parseError(err)
    yield put(LogisticsDealActions.updateFailed(id, type, detail, errors))
  }
}

export const removeItem = function* ({ payload }: ReturnType<typeof LogisticsDealActions.removeRequested>) {
  try {
    yield call(managers.removeItem, payload)
    yield put(LogisticsDealActions.removeSucceed(payload))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(LogisticsDealActions.removeFailed(payload, type, detail))
  }
}

export const changeStatus = function* ({
  payload: [dealId, logistics_status],
}: ReturnType<typeof LogisticsDealActions.changeStatusRequested>) {
  try {
    yield call(managers.changeStatus, dealId, logistics_status)
    const deal: LogisticsDeal = yield select(state => LogisticsDealSelectors.item(state, dealId))
    yield put(
      LogisticsDealActions.changeStatusSuccess({
        ...deal,
        logistics_status,
        id: dealId,
      }),
    )
  } catch (e) {
    yield put(LogisticsDealActions.changeStatusError(dealId))
  }
}

const LogisticsDealSaga = function* () {
  yield all([
    takeLatest(LogisticsDealActions.itemRequested.type, fetchItem),
    takeLatest(LogisticsDealActions.listRequested.type, fetchList),
    takeLatest(LogisticsDealActions.filterUpdated.type, fetchList),
    takeLatest(LogisticsDealActions.sortingUpdated.type, fetchList),
    takeLatest(LogisticsDealActions.filterHasBeenReset.type, fetchList),
    takeLatest(LogisticsDealActions.sortingHasBeenReset.type, fetchList),

    takeLatest(LogisticsDealActions.addRequested.type, addItem),
    takeLatest(LogisticsDealActions.updateRequested.type, updateItem),
    takeLatest(LogisticsDealActions.removeRequested.type, removeItem),

    takeLatest(LogisticsDealActions.changeStatusRequested.type, changeStatus),
  ])
}

export default LogisticsDealSaga
