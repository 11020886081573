import { all, call, put, race, select, take, takeEvery, takeLatest } from 'redux-saga/effects'

import DashboardActions from 'modules/domain/dashboard/duck'
import * as managers from 'modules/domain/dashboard/managers'
import DashboardSelectors from 'modules/domain/dashboard/selectors'
import TaskActions from 'modules/domain/task/duck'
import { updateLocationQuery } from 'modules/sagaHelpers'
import { cancel as cancelRequest } from 'modules/utils/httpClient'
import DashboardRoutes from 'views/pages/Dashboard/routes'

export const fetchMatches = function* () {
  try {
    yield put(DashboardActions.matchesRequested())
    const filter = yield select(DashboardSelectors.filter)
    const filterForRequestManager = yield select(DashboardSelectors.filterForRequestManager)

    let request: Promise<unknown> | null = null
    const { response, cancel } = yield race({
      response: call(() => {
        request = managers.getMatches(filterForRequestManager)
        return request
      }),
      cancel: take(DashboardActions.cancelDashboardRequest.type),
    })

    if (cancel) {
      cancelRequest(request)
      return
    }

    yield put(DashboardActions.matchesRequestedSucceed(response.results))
    yield call(updateLocationQuery, DashboardRoutes.Dashboard, { ...filter })
  } catch (err) {
    yield put(DashboardActions.matchesRequestedFailed())
  }
}

export const fetchResponses = function* () {
  try {
    yield put(DashboardActions.responsesRequested())
    const filter = yield select(DashboardSelectors.filter)
    const filterForRequestManager = yield select(DashboardSelectors.filterForRequestManager)

    let request: Promise<unknown> | null = null
    const { response, cancel } = yield race({
      response: call(() => {
        request = managers.getResponses(filterForRequestManager)
        return request
      }),
      cancel: take(DashboardActions.cancelDashboardRequest.type),
    })

    if (cancel) {
      cancelRequest(request)
      return
    }

    yield put(DashboardActions.responsesRequestedSucceed(response.results))
    yield call(updateLocationQuery, DashboardRoutes.Dashboard, { ...filter })
  } catch (err) {
    yield put(DashboardActions.responsesRequestedFailed())
  }
}

export const fetchNew = function* () {
  try {
    yield put(DashboardActions.newRequested())
    const filter = yield select(DashboardSelectors.filter)
    const filterForRequestManager = yield select(DashboardSelectors.filterForRequestManager)

    let request: Promise<unknown> | null = null
    const { response, cancel } = yield race({
      response: call(() => {
        request = managers.getNew(filterForRequestManager)
        return request
      }),
      cancel: take(DashboardActions.cancelDashboardRequest.type),
    })

    if (cancel) {
      cancelRequest(request)
      return
    }

    yield put(DashboardActions.newRequestedSucceed(response.results))
    yield call(updateLocationQuery, DashboardRoutes.Dashboard, { ...filter })
  } catch (err) {
    yield put(DashboardActions.newRequestedFailed())
  }
}

export const fetchNewUsers = function* () {
  try {
    yield put(DashboardActions.newUsersRequested())
    const filter = yield select(DashboardSelectors.filter)
    const page = yield select(DashboardSelectors.newUsersPage)
    const filterForRequestManager = yield select(DashboardSelectors.filterForRequestManager)

    let request: Promise<unknown> | null = null
    const { response, cancel } = yield race({
      response: call(() => {
        request = managers.getNewUsers(filterForRequestManager, page)
        return request
      }),
      cancel: take(DashboardActions.cancelDashboardRequest.type),
    })

    if (cancel) {
      cancelRequest(request)
      return
    }

    yield put(DashboardActions.newUsersRequestedSucceed(response.results, response.count))
    yield call(updateLocationQuery, DashboardRoutes.Dashboard, { ...filter })
  } catch (err) {
    yield put(DashboardActions.newUsersRequestedFailed())
  }
}

export const removeTask = function* ({ payload: id }: ReturnType<typeof TaskActions.completeTaskSuccess>) {
  yield put(DashboardActions.taskRemoved(id))
}

const DashboardSaga = function* () {
  yield all([
    takeLatest(DashboardActions.filterUpdate.type, fetchResponses),
    takeLatest(DashboardActions.filterUpdate.type, fetchMatches),
    takeLatest(DashboardActions.filterUpdate.type, fetchNew),
    takeLatest(DashboardActions.filterUpdate.type, fetchNewUsers),
    takeEvery(TaskActions.completeTaskSuccess.type, removeTask),
  ])
}

export default DashboardSaga
