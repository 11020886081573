import { initReactI18next } from 'react-i18next'

import { i18nComposeWithUIComponents } from '@agro-club/agroclub-shared'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import { REACT_APP_LOCALE } from 'env'
import en from 'translations/en.json'
import pt_BR from 'translations/pt_BR.json'
import ru from 'translations/ru.json'
import { AvailableLanguages } from 'types/entities'

const locale = REACT_APP_LOCALE

const getAvailableLanguages = locale => {
  switch (locale) {
    case AvailableLanguages.pt_BR:
      return {
        [AvailableLanguages.pt_BR]: pt_BR,
        [AvailableLanguages.en]: en,
      }
    default:
      return {
        [AvailableLanguages.ru]: ru,
      }
  }
}

const resources = i18nComposeWithUIComponents(getAvailableLanguages(locale))

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: locale,
    supportedLngs: Object.keys(resources),
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    detection: {
      order: ['cookie', 'htmlTag'],
      caches: ['cookie'],
    },
  })

export default i18n
