import { Progress } from '@agro-club/agroclub-shared'
import { createSelector } from 'reselect'

import filterDistricts from 'helpers/filterDistricts'
import { EntityMetadata } from 'modules/domain/types'
import { User } from 'modules/domain/user/types'
import { AppGlobalState } from 'modules/types'

const usersDict = (state: AppGlobalState) => state.user.items
const ids = (state: AppGlobalState) => state.user.ids
const user = (state: AppGlobalState, id: string): User | undefined => state.user.items[id]
const userByPhone = (state: AppGlobalState, phone: string): User | undefined =>
  Object.values(state.user.items).find(u => u.phone === phone)
const meta = (state: AppGlobalState, id?: string): EntityMetadata<User> =>
  (id && state.user.meta[id]) || {
    fetchError: null,
    fetchProgress: Progress.IDLE,
    id,
    removeError: null,
    removeProgress: Progress.IDLE,
    updateError: null,
    updateProgress: Progress.IDLE,
  }
const itemFetchProgress = (state: AppGlobalState) => state.user.itemFetchProgress
const listFetchProgress = (state: AppGlobalState) => state.user.listFetchProgress
const listFetchError = (state: AppGlobalState) => state.user.listFetchError
const userList = createSelector(usersDict, ids, listFetchProgress, (dict, ids, progress) => {
  if (progress === Progress.WORK) {
    return []
  }
  const result: User[] = []
  for (const id of ids) {
    result.push(dict[id])
  }
  return result
})

const filter = (state: AppGlobalState) => state.user.filter
const sorting = (state: AppGlobalState) => state.user.sorting
const page = (state: AppGlobalState) => state.user.page
const total = (state: AppGlobalState) => state.user.total
const updateProgress = (state: AppGlobalState) => state.user.updateProgress
const removeProgress = (state: AppGlobalState) => state.user.removeProgress
const addProgress = (state: AppGlobalState) => state.user.addProgress
const addError = (state: AppGlobalState) => state.user.addError
const addErrorDetail = (state: AppGlobalState) => state.user.addErrorDetail
const addErrorFields = (state: AppGlobalState) => state.user.addErrorFields
const hasNext = (state: AppGlobalState) => state.user.total > state.user.ids.length
const pageSize = (state: AppGlobalState) => state.user.pageSize
const pages = (state: AppGlobalState) => Math.ceil(state.user.total / state.user.pageSize)
const userBlockProgress = (state: AppGlobalState) => state.user.userBlockProgress
const lastAddedUserId = (state: AppGlobalState) => state.user.lastAddedUserId

const files = (state: AppGlobalState, id?: string) => (id ? state.user.documents?.[id]?.files || [] : [])
const filesFetchProgress = (state: AppGlobalState, id?: string) =>
  id ? state.user.documents?.[id]?.fetchProgress || Progress.IDLE : Progress.IDLE
const filesUploadProgress = (state: AppGlobalState, id?: string) =>
  id ? state.user.documents?.[id]?.uploadProgress || Progress.IDLE : Progress.IDLE

const deleteDocumentProgress = (state: AppGlobalState) => state.user.deleteDocumentProgress

const filterForRequestManager = (state: AppGlobalState) => {
  const { filter } = state.user
  return filterDistricts(state, filter)
}

const documentTypes = (state: AppGlobalState, id: string) => state.user.documentTypes[id]
const documentProgress = (state: AppGlobalState, id: string) => state.user.getTypedDocumentProgress[id]
const uploadProgress = (state: AppGlobalState, id: string) => state.user.uploadProgress[id]
const uploadError = (state: AppGlobalState, id: string) => state.user.uploadError[id]
const uploadErrorDetail = (state: AppGlobalState, id: string) => state.user.uploadErrorDetail?.[id]
const documentFiles = (state: AppGlobalState, id: string) => state.user.documentFiles[id]
const deleteTypedDocumentProgress = (state: AppGlobalState, id: string) => state.user.deleteTypedDocumentProgress[id]

const UserSelectors = {
  filter,
  filterForRequestManager,
  sorting,
  page,
  total,
  updateProgress,
  removeProgress,
  addProgress,
  addError,
  addErrorDetail,
  addErrorFields,
  usersDict,
  ids,
  user,
  userByPhone,
  meta,
  itemFetchProgress,
  listFetchProgress,
  listFetchError,
  userList,
  hasNext,
  pageSize,
  pages,
  userBlockProgress,
  files,
  filesFetchProgress,
  filesUploadProgress,
  deleteDocumentProgress,
  lastAddedUserId,
  documentTypes,
  documentProgress,
  uploadProgress,
  uploadError,
  uploadErrorDetail,
  documentFiles,
  deleteTypedDocumentProgress,
}

export default UserSelectors
