import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import CallsActions from 'modules/domain/calls/duck'
import { Calls } from 'modules/domain/calls/types'
import { RequestError } from 'modules/errors'
import { updateLocationQuery } from 'modules/sagaHelpers'
import { ListResponse } from 'types/api.d'
import CallsRoutes from 'views/pages/Calls/routes'

import * as managers from './managers'
import CallsSelectors from './selectors'

export const fetchList = function* () {
  try {
    let currentPage = yield select(CallsSelectors.page)
    const filter = yield select(CallsSelectors.filter)
    const filterForRequestManager = yield select(CallsSelectors.filterForRequestManager)
    const sorting = yield select(CallsSelectors.sorting)
    const pageSize = yield select(CallsSelectors.pageSize)

    let response: ListResponse<Calls> = yield call(
      managers.getCallsList,
      filterForRequestManager,
      sorting,
      currentPage,
      pageSize,
    )
    const pages = Math.ceil(response.count / pageSize)
    if (pages !== 0 && pages < currentPage) {
      response = yield call(managers.getCallsList, filterForRequestManager, sorting, pages, pageSize)
      currentPage = pages
    }

    const { results, count, current } = response
    yield put(CallsActions.listRequestSucceed(results, count, current))
    yield call(updateLocationQuery, CallsRoutes.List, { page: currentPage, ...filter, ...sorting })
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(CallsActions.listRequestFailed(errType))
  }
}

export const fetchAccounts = function* () {
  try {
    const calls = yield call(managers.getAccounts)

    yield put(CallsActions.accountsRequestSuccess(calls?.results))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(CallsActions.accountsRequestFailed(errType))
  }
}

export const makeCall = function* () {
  try {
    const phone = yield select(CallsSelectors.phone)
    yield call(managers.makeCall, phone)
  } catch (err) {}
}

const CallsSaga = function* () {
  yield all([
    takeLatest(CallsActions.listRequested.type, fetchList),
    takeLatest(CallsActions.filterUpdated.type, fetchList),

    takeLatest(CallsActions.sortingUpdated.type, fetchList),
    takeLatest(CallsActions.filterHasBeenReset.type, fetchList),
    takeLatest(CallsActions.sortingHasBeenReset.type, fetchList),

    takeLatest(CallsActions.accountsRequested.type, fetchAccounts),

    takeLatest(CallsActions.makeCall.type, makeCall),
  ])
}

export default CallsSaga
