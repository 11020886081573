import React, { VFC } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'
import { CellProps } from 'react-table'

import env from 'env'
import useFormatNumber from 'hooks/useFormatNumber'
import useLocationFrom from 'hooks/useLocationFrom'
import useUserName from 'hooks/useUserName'
import { Trip } from 'modules/domain/trip/types'
import { User } from 'modules/domain/user/types'
import { Checked } from 'views/components/Checked/Checked'
import { DateCell } from 'views/components/CommonTableComponents/CommonTableComponents'
import CarrierRoutes from 'views/pages/Carrier/routes'
import CarsRoutes from 'views/pages/Cars/routes'
import * as S from 'views/pages/Logistics/LogisticsDetail/styled'
import { BlueLink } from 'views/styled/common'
import { DangerousHtml } from 'views/ui/DangerousHtml/DangerousHtml'

function VehicleCell({ row }: CellProps<Trip>) {
  const { licence_number, brand_model, default_trailer } = row.original.car
  return (
    <>
      <S.BrandName>{brand_model}</S.BrandName>
      <S.StyledLink to={generatePath(CarsRoutes.Detail, { id: row.original.car.id })} style={{ whiteSpace: 'nowrap' }}>
        {licence_number}
        {default_trailer?.licence_number && (
          <>
            {' / '}
            <S.TrailerStateNumber>{default_trailer?.licence_number}</S.TrailerStateNumber>
          </>
        )}
      </S.StyledLink>
    </>
  )
}

const TrailerCell: VFC<CellProps<Trip>> = ({ row }) => {
  const { trailer } = row.original
  return (
    <>
      <div>{trailer?.brand_model || ''}</div>
      <div>{trailer?.licence_number || ''}</div>
    </>
  )
}

function VehicleTypeCell({ row }: CellProps<Trip>) {
  const { t } = useTranslation('vehicles')
  const { car_type, is_dump_truck, unloading_type, side_height } = row.original.car
  const firstLine: string[] = []
  const secondLine: string[] = []
  const result: string[] = []
  const formatNumber = useFormatNumber()

  if (car_type) firstLine.push(`${t(`vehicleTypes.${car_type}`)}`)
  if (unloading_type) firstLine.push(`${t(`vehicles:unloadingType.${unloading_type}`)}`)

  if (is_dump_truck) secondLine.push(`${t('is_dump_truck')}`)
  if (side_height) secondLine.push(`${formatNumber(side_height)} ${t('common:m')}`)

  if (firstLine.length) result.push(firstLine.join(', '))
  if (secondLine.length) result.push(secondLine.join(', '))

  return (
    <S.NoWrap>
      <DangerousHtml html={result.join(', <br/>')} />
    </S.NoWrap>
  )
}

function OwnerCell({ row }: CellProps<Trip>) {
  const { owner } = row.original.car
  const userName = useUserName(owner as User)
  const from = useLocationFrom()

  return (
    <S.NoWrap>
      <BlueLink
        to={{
          pathname: generatePath(CarrierRoutes.Detail, { id: owner.id }),
          state: { from },
        }}
      >
        {userName}
      </BlueLink>
      <S.PhoneUserLink phone={owner.phone} />
    </S.NoWrap>
  )
}

function LogisticsCell({ value }: CellProps<Trip>) {
  const formatNumber = useFormatNumber()
  return <S.LogisticsCellWrapper>{formatNumber(value, true)}</S.LogisticsCellWrapper>
}

function QuantityCell({ value }: CellProps<Trip>) {
  const formatNumber = useFormatNumber()
  return <S.QuantityCellWrapper>{formatNumber(value, true, 3)}</S.QuantityCellWrapper>
}

function TtnCell({ value }: CellProps<Trip>) {
  return <Checked value={value} />
}

export const useTripsColumns = () => {
  const { t } = useTranslation(['logisticsDetail', 'common'])
  const currency = env.REACT_APP_CURRENCY_SYMBOL

  return React.useMemo(
    () => [
      {
        Header: t('list.tableHeaders.loadDate'),
        accessor: 'load_date',
        Cell: DateCell,
      },
      {
        Header: t('list.tableHeaders.unloadDate'),
        accessor: 'unload_date',
        Cell: DateCell,
      },
      {
        Header: t('list.tableHeaders.car'),
        Cell: VehicleCell,
      },
      {
        Header: t('list.tableHeaders.trailer'),
        Cell: TrailerCell,
      },
      {
        Header: t('list.tableHeaders.carType'),
        Cell: VehicleTypeCell,
      },
      {
        Header: t('list.tableHeaders.owner'),
        accessor: 'owner' as const,
        Cell: OwnerCell,
      },
      {
        Header: t('list.tableHeaders.priceLogistics', { currency }),
        accessor: 'price_logistics' as const,
        Cell: LogisticsCell,
        width: '100px',
      },
      {
        Header: t('list.tableHeaders.loadQuantity'),
        accessor: 'load_quantity' as const,
        Cell: QuantityCell,
        width: '150px',
      },
      {
        Header: t('list.tableHeaders.unloadQuantity'),
        accessor: 'unload_quantity' as const,
        Cell: QuantityCell,
        width: '150px',
      },
      {
        Header: t('list.tableHeaders.ttn1'),
        accessor: 'has_ttn_1' as const,
        Cell: TtnCell,
      },
      {
        Header: t('list.tableHeaders.ttn2'),
        accessor: 'has_ttn_2' as const,
        Cell: TtnCell,
      },
    ],
    [currency, t],
  )
}
