import React from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'

import { useAction } from '@agro-club/agroclub-shared'

import env from 'env'
import useFormatNumber from 'hooks/useFormatNumber'
import useLocationFrom from 'hooks/useLocationFrom'
import useMainParameter from 'hooks/useMainParameter'
import { Bid } from 'modules/domain/bid/types'
import UserBidActions from 'modules/domain/userBid/duck'
import BidRoutes from 'views/pages/Bid/routes'
import * as S from 'views/pages/User/UserDetail/UserColumnBoard/styled'

type Props = {
  bid: Bid
  userId: string
  activeBidId?: string
}

export const BidItem: React.FC<Props> = props => {
  const { t } = useTranslation()
  const { bid, activeBidId, userId } = props
  const price = bid.bid_type === 'purchase' ? bid.price_cpt : bid.price_exw
  const parameter = useMainParameter(bid.parameters)
  const setActiveBid = useAction(UserBidActions.setActiveBidId)
  const from = useLocationFrom()
  const currency = env.REACT_APP_CURRENCY_SYMBOL
  const formatNumber = useFormatNumber()

  return (
    <S.BidItem
      $isActive={bid.id === activeBidId}
      onClick={() => bid.id !== activeBidId && setActiveBid(userId, bid.id)}
    >
      <S.BidItemStatus>{bid.status ? t(`bid:bidStatuses.${bid.status}`) : ''}</S.BidItemStatus>
      <S.BidItemProduct>{bid?.product?.title}</S.BidItemProduct>
      <S.BidItemDescription>
        {formatNumber(bid.quantity_in_bags)} {t('common:bag')} ({formatNumber(bid.quantity)}
        &nbsp;
        {t('common:ton')}) / {parameter}
      </S.BidItemDescription>
      <S.BidItemPrice>{t('common:rubKg', { price, currency })}</S.BidItemPrice>
      <S.BidItemAddress $isActive={bid.id === activeBidId}>{bid?.address?.address || ''}</S.BidItemAddress>

      <S.DealDetailsLinkButton
        to={{
          pathname: generatePath(BidRoutes.Detail, { id: bid.id }),
          state: { from },
        }}
      >
        <S.StyledIconLinkOpener />
      </S.DealDetailsLinkButton>
    </S.BidItem>
  )
}
