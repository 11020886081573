import React, { useContext } from 'react'

import { AccordionItemContext } from '@agro-club/agroclub-shared'

import { Flex, Summary } from 'views/components/UserRightPanel/AccordionHeader/styled'

interface HeaderProps {
  summary?: string | React.ReactElement
}

export const Header: React.FC<HeaderProps> = ({ children, summary }) => {
  const { open } = useContext(AccordionItemContext)

  return (
    <Flex>
      <span>{children}</span>
      {!open && <Summary>{summary}</Summary>}
    </Flex>
  )
}
