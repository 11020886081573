import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useAction, useHelmet } from '@agro-club/agroclub-shared'

import CallsActions from 'modules/domain/calls/duck'
import { useCallsFilterAndSortQuery } from 'modules/domain/calls/hooks'
import CallsSelectors from 'modules/domain/calls/selectors'
import TableSearch from 'views/components/TableSearch/TableSearch'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import CallsTable from 'views/pages/Calls/CallsTable/CallsTable'
import * as Header from 'views/ui/Header/Header'

const CallsList: React.FC = () => {
  const { t } = useTranslation(['calls'])
  const { filter } = useCallsFilterAndSortQuery()
  useHelmet({ title: t('callsMetaTitle') })
  const filterUpdated = useAction(CallsActions.filterUpdated)
  const filterState = useSelector(CallsSelectors.filter)

  const onChangeSearch = useCallback(
    (search: string) => {
      filterUpdated({ ...filterState, search })
    },
    [filterUpdated, filterState],
  )

  return (
    <>
      <Layout.Header>
        <Header.Root>
          <Header.Title title={t('list.heading')} />
          <TableSearch
            value={filter.search || filterState.search || ''}
            onChangeSearch={onChangeSearch}
            placeholder={t('list.searchPlaceholder')}
          />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <CallsTable />
      </Layout.Content>
    </>
  )
}

export default CallsList
