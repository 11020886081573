import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Button, IconAdd } from '@agro-club/agroclub-shared'

import { useUserContact } from 'modules/domain/userContact/hooks'
import UserContactSelectors from 'modules/domain/userContact/selectors'
import { ButtonWrapper, UserContactWrapper } from 'views/components/UserRightPanel/UserContact/styled'
import { UserContactForm } from 'views/components/UserRightPanel/UserContact/UserContactBlockForm'
import { UserContactRow } from 'views/components/UserRightPanel/UserContact/UserContactBlockRow'

export const UserContactSummary: React.FC = () => {
  const { t } = useTranslation(['user', 'common'])
  const { id } = useParams<{ id: string }>()
  const list = useSelector(state => UserContactSelectors.contactsByUserId(state, id))
  return <div>{list.length || t('common:no')}</div>
}

export const UserContactList: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const [, items] = useUserContact(id)
  return (
    <div>
      {items.map(item => (
        <UserContactRow key={item.id} item={item} />
      ))}
    </div>
  )
}

export const UserContact: React.FC = () => {
  const { t } = useTranslation('userContact')
  const [isOpen, setOpen] = useState<boolean>(false)

  return (
    <UserContactWrapper>
      <UserContactList />
      <ButtonWrapper>
        <Button intent="action" filled onClick={() => setOpen(true)} size="small">
          <IconAdd />
          {t('common:addContact')}
        </Button>
      </ButtonWrapper>
      <UserContactForm open={isOpen} onClose={() => setOpen(false)} />
    </UserContactWrapper>
  )
}
