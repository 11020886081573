import React, { VFC, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Progress, SimpleSelect, SimpleSelectProps, useAction, useDidMount } from '@agro-club/agroclub-shared'

import usePrevious from 'hooks/usePrevious'
import CarrierActions from 'modules/domain/carrier/duck'
import CarrierSelectors from 'modules/domain/carrier/selectors'

type Props = {
  selectedId: string | null
  onChange: (selectedId: string | null) => void
  owner: string
  invalid: boolean
  size?: SimpleSelectProps['size']
  label?: string
}

export const TrailerSelect: VFC<Props> = ({ selectedId, onChange, owner, invalid, size, label }) => {
  const { t } = useTranslation('vehicles')
  const fetchAction = useAction(CarrierActions.trailersRequested, owner)
  const trailers = useSelector(state => CarrierSelectors.activeTrailers(state, owner))
  const progress = useSelector(state => CarrierSelectors.trailersFetchProgress(state, owner))
  const options = useMemo(() => trailers.map(t => ({ id: t.id, title: t.licence_number })), [trailers])
  const addProgress = useSelector(CarrierSelectors.trailerAddProgress)
  const prevAddProgress = usePrevious(addProgress)

  useEffect(() => {
    if (addProgress === Progress.SUCCESS && prevAddProgress === Progress.WORK) {
      fetchAction() // reload trailers when new is added
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addProgress, prevAddProgress])

  useDidMount(fetchAction)

  return (
    <SimpleSelect
      placeholder={t('form.trailerLicenceNumberPlaceholder')}
      noOptionsMessage={() => t('form.noTrailers')}
      options={options}
      value={selectedId}
      progress={progress}
      size={size}
      isClearable
      invalid={invalid}
      onChange={(value: string) => {
        onChange(value)
      }}
      onClear={() => {
        onChange(null)
      }}
      label={label}
    />
  )
}
