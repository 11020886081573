import { Progress } from '@agro-club/agroclub-shared'

import { AppGlobalState } from 'modules/types'

const listFetchProgress = (state: AppGlobalState, userId: string) =>
  state.userCalls.items?.[userId]?.listFetchProgress || Progress.IDLE
const callsByUserId = (state: AppGlobalState, userId?: string) =>
  userId ? state.userCalls.items?.[userId]?.calls || [] : []
const page = (state: AppGlobalState, userId: string) => state.userCalls.items[userId]?.page
const pageSize = (state: AppGlobalState, userId: string) => state.userCalls.items[userId]?.pageSize
const total = (state: AppGlobalState, userId: string) => state.userCalls.items[userId]?.total
const count = (state: AppGlobalState, userId: string) => state.userCalls.items[userId]?.count
const UserCallsSelectors = {
  listFetchProgress,
  callsByUserId,
  page,
  pageSize,
  total,
  count,
}

export default UserCallsSelectors
