import React from 'react'
import { useTranslation } from 'react-i18next'

import { FormikContextType } from 'formik'

import { WarehouseDTO } from 'modules/domain/types'
import * as S from 'views/components/WarehouseModal/styled'
import { YesNoUnknownRadio } from 'views/components/WarehouseModal/YesNoUnknownRadio'

type Props = {
  formik: FormikContextType<WarehouseDTO>
  label: string
}

export const ScheduleControls: React.FC<Props> = ({ formik, label }) => {
  const { t } = useTranslation('address')
  return (
    <>
      <S.Label>{label}</S.Label>
      <S.InlineWrapper>
        <S.TimeWrapper>
          <S.TimeInput
            type="time"
            value={formik.values.schedule_from ?? ''}
            onChange={e => {
              const { value } = e.target
              formik.setFieldValue('schedule_from', value === '' ? null : value)
            }}
          />
          <S.Dash />
          <S.TimeInput
            type="time"
            value={formik.values.schedule_to ?? ''}
            onChange={e => {
              const { value } = e.target
              formik.setFieldValue('schedule_to', value === '' ? null : value)
            }}
          />
        </S.TimeWrapper>
      </S.InlineWrapper>
      <S.Label>{t('fields.works_on_weekend')}</S.Label>
      <S.WeekendWrapper>
        <YesNoUnknownRadio
          value={formik.values.works_on_weekend}
          onChange={val => {
            formik.setFieldValue('works_on_weekend', val)
          }}
        />
      </S.WeekendWrapper>
    </>
  )
}
