import { useSelector } from 'react-redux'

import { intersection } from 'ramda'

import AuthSelectors from 'modules/domain/auth/selectors'

export const usePermissions = (scope: string[]): boolean => {
  const permissions = useSelector(AuthSelectors.permissions)
  const available = intersection(permissions, scope).length > 0

  return available
}
