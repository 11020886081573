import { CommentType } from 'modules/domain/comment/types'
import { AppGlobalState } from 'modules/types'

const inputComment = (state: AppGlobalState, id: string | undefined, type: CommentType) => {
  switch (type) {
    case CommentType.user:
      return (id && state.comment.inputCommentUser[id]) || ''
    case CommentType.deal:
      return (id && state.comment.inputCommentDeal[id]) || ''
    case CommentType.trip:
      return (id && state.comment.inputCommentTrip[id]) || ''
  }
}

const CommentSelectors = {
  inputComment,
}

export default CommentSelectors
