import { Dict } from 'types/generics.d'

type StringFields<T extends { id: string }> = keyof {
  [P in 'id']: string extends T[P] ? P : never
}

export function arrToDict<T extends { id: string }>(arr: T[], prop: StringFields<T> = 'id'): Dict<T> {
  const result: Dict<T> = {}
  for (const item of arr) {
    result[item[prop]] = item
  }
  return result
}

export function getIds<T extends { id: string }>(arr: T[]): string[] {
  return arr.map(item => item.id)
}
