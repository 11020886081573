import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@agro-club/agroclub-shared'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { useNotificationProgress } from 'hooks/useNotificationProgress'
import { Executors } from 'modules/domain/deal/types'
import { ExecutorItem } from 'views/pages/Deal/DealEdit/ExecutorsEditModal'
import { Footer, FormWrapper } from 'views/pages/Deal/DealEdit/styled'
import { useExecutorsEdit } from 'views/pages/Deal/DealEdit/useExecutorsEdit'
import { CancelButton } from 'views/styled/common'
import { ModalCommon } from 'views/ui/ModalCommon/ModalCommon'

import { FORM_ITEMS } from './DealStatusModal'

type ExecutorsForm = {
  user_coordinator: string | number | null
  deal_coordinator: string | number | null
}

interface Props {
  id: string
  executors: Omit<Executors, 'logistician'> | undefined
  onClose: () => void
  onChange: () => void
}

export const DealStatusExecutorsModal: React.FC<Props> = ({ id, executors, onClose, onChange }) => {
  const { t } = useTranslation(['common', 'logistics'])
  const [updateProgress, updateExecutors] = useExecutorsEdit()

  const validationSchema = Yup.object({
    user_coordinator: Yup.string().required(t('errors.required_field')).nullable(t('errors.required_field')),
    deal_coordinator: Yup.string().required(t('errors.required_field')).nullable(t('errors.required_field')),
  })

  const formik = useFormik<Omit<ExecutorsForm, 'logistician'>>({
    initialValues: {
      user_coordinator: executors?.user_coordinator?.id || '',
      deal_coordinator: executors?.deal_coordinator?.id || '',
    },
    enableReinitialize: true,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: () => {
      if (!formik.isValid) {
        return
      }
      updateExecutors(id, {
        ...{
          user_coordinator: null,
          deal_coordinator: null,
        },
        ...formik.values,
      }).then(() => {
        formik.resetForm()
        onChange()
        onClose()
      })
    },
  })

  const onCancel = useCallback(() => {
    formik.resetForm()
    onClose()
  }, [formik, onClose])

  useNotificationProgress(updateProgress)

  return (
    <ModalCommon
      width={660}
      isOpen
      close={onCancel}
      title={t('logistics:statusChanging')}
      content={
        <FormWrapper>
          <div>
            <div>{t('logistics:statusChangeForbiddenExecutors')}</div>
            {FORM_ITEMS.map(item => (
              <ExecutorItem key={item.type} formik={formik} {...item} />
            ))}
          </div>
        </FormWrapper>
      }
      footer={
        <Footer>
          <Button
            onClick={() => {
              formik.dirty && formik.submitForm().catch()
            }}
            disabled={!formik.dirty}
            intent="primary"
            progress={updateProgress}
            filled
          >
            {t('common:save')}
          </Button>
          <CancelButton onClick={onCancel} intent="minor-action">
            {t('common:cancel')}
          </CancelButton>
        </Footer>
      }
    />
  )
}
