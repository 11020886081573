import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { Progress, Spinner, useAction } from '@agro-club/agroclub-shared'

import useAnalyticsSafe from 'hooks/useAnalyticsSafe'
import AuthActions from 'modules/domain/auth/duck'
import AuthSelectors from 'modules/domain/auth/selectors'
import Tooltip from 'views/ui/Tooltip/Tooltip'

import * as S from './styled'

type Props = {
  collapsed: boolean
}

export const CurrentUser: React.FC<Props> = props => {
  const { collapsed } = props
  const { track } = useAnalyticsSafe()
  const profileFetchProgress = useSelector(AuthSelectors.getInitProgress)
  const profile = useSelector(AuthSelectors.profile)
  const logoutAction = useAction(AuthActions.signOutRequested)
  const first_name = profile?.profile?.first_name || ''
  const last_name = profile?.profile?.last_name || ''
  const phone = profile?.phone
  const userName = [first_name, last_name].filter(Boolean).join(' ') || phone

  const tooltipContent = useMemo(
    () => (
      <S.UserInfo>
        <S.Name>{userName}</S.Name>
        <S.LogoutButton
          onClick={() => {
            track('LogOut')
            logoutAction()
          }}
        >
          <S.StyledExit />
        </S.LogoutButton>
      </S.UserInfo>
    ),
    [userName, track, logoutAction],
  )

  if (profileFetchProgress === Progress.WORK) {
    return (
      <div style={{ margin: 'auto', width: 'fit-content', padding: '10px' }}>
        <Spinner />
      </div>
    )
  }

  if (collapsed) {
    return (
      <S.Wrapper>
        <Tooltip placement="right" trigger={['hover']} overlay={tooltipContent}>
          <span>
            <S.StyledIconUser />
          </span>
        </Tooltip>
      </S.Wrapper>
    )
  }

  return (
    <S.Wrapper>
      <S.StyledIconUser />
      {tooltipContent}
    </S.Wrapper>
  )
}
