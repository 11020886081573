import React, { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { matchPath, useLocation } from 'react-router-dom'

import { Button, DatePicker, Input, Progress, TextArea, useAction } from '@agro-club/agroclub-shared'
import { addBusinessDays } from 'date-fns'
import { useFormik } from 'formik'
import styled from 'styled-components'
import * as Yup from 'yup'

import useDateFnsLocale from 'hooks/useDateFnsLocale'
import { useNotificationProgress } from 'hooks/useNotificationProgress'
import usePrevious from 'hooks/usePrevious'
import useUserName from 'hooks/useUserName'
import AuthSelectors from 'modules/domain/auth/selectors'
import { Bid } from 'modules/domain/bid/types'
import { Deal } from 'modules/domain/deal/types'
import { PotentialBid } from 'modules/domain/potentialBid/types'
import TaskActions from 'modules/domain/task/duck'
import TaskSelectors from 'modules/domain/task/selectors'
import { Task, TaskType } from 'modules/domain/task/types'
import { User } from 'modules/domain/user/types'
import { LocationStateFrom } from 'types/location'
import { AssigneeSelect } from 'views/components/AssigneeSelect/AssigneeSelect'
import { FormSection, LabeledContainer } from 'views/components/FormComponents/FormComponents'
import { SwitchType } from 'views/components/TaskModal/SwitchType'
import { TeamsSelect } from 'views/components/TeamsSelect/TeamsSelect'
import DashboardRoutes from 'views/pages/Dashboard/routes'
import { ModalCommon } from 'views/ui/ModalCommon/ModalCommon'
import { UserLink } from 'views/ui/UserLink/UserLink'

const LongTextButton = styled(Button)`
  line-height: 16px;
`

const StyledTextArea = styled(TextArea)`
  height: 80px;
  min-height: 80px;
`

type EditableFields = {
  subject?: string
  task_type: Task['task_type']
  deadline: string
  assignee?: string
  comment?: string
  linked_deal?: string
  linked_user?: string
  linked_bid?: string
  assigned_team?: string
}

type Props = {
  isOpen: boolean
  close: () => void
  user?: User
  deal?: Deal
  bid?: Bid | PotentialBid
  goBack?: () => void
}

export const TaskModal: React.FC<Props> = props => {
  const { isOpen, close, user, deal, bid, goBack } = props
  const { t } = useTranslation('task')
  const createAction = useAction(TaskActions.addRequested)
  const progress = useSelector(TaskSelectors.addProgress)
  const location = useLocation<LocationStateFrom>()
  const goToDashboard = useRef<boolean>(false)
  const locale = useDateFnsLocale()

  const isFromDashboard =
    !!location.state?.from?.pathname && !!matchPath(location.state.from.pathname, DashboardRoutes.Dashboard)

  useNotificationProgress(progress, t('notifySuccess'))

  const prevOpen = usePrevious(isOpen)

  const currentUser = useSelector(AuthSelectors.profile)

  const validationSchema = useMemo(
    () =>
      Yup.object({
        subject: Yup.string().required(),
        deadline: Yup.string().required(),
        assigned_team: Yup.string().required(),
      }),
    [],
  )
  const nextBusinessDay = useMemo(() => addBusinessDays(new Date(), 1).toISOString(), [])
  const defaultTeamId = currentUser?.profile?.team?.id ? String(currentUser?.profile?.team?.id) : undefined
  const linkedUserName = useUserName(user)

  const formik = useFormik<EditableFields>({
    validationSchema,
    initialValues: {
      subject: '',
      task_type: TaskType.call,
      deadline: nextBusinessDay,
      assigned_team: defaultTeamId,
      assignee: currentUser?.id,
      comment: undefined,
      linked_deal: deal?.id,
      linked_user: user?.id,
      linked_bid: bid?.id,
    },
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,

    onSubmit: () => {
      if (!formik.isValid) {
        return
      }

      createAction({ ...formik.values })
    },
  })

  useEffect(() => {
    if (!isOpen && prevOpen) {
      goToDashboard.current = false
      formik.resetForm()
    }
  }, [formik, isOpen, prevOpen])

  const prevProgress = usePrevious(progress)

  useEffect(() => {
    if (progress === Progress.SUCCESS && prevProgress === Progress.WORK) {
      if (goToDashboard.current) {
        !!goBack && goBack()
      } else {
        close()
      }
    }
  }, [close, goBack, prevProgress, progress])

  useEffect(() => {
    switch (formik.values.task_type) {
      case 'call':
        formik.setFieldValue('subject', `${t('form.call')} ${linkedUserName}`)
        formik.setFieldTouched('subject')
        return
      case 'meeting':
        formik.setFieldValue('subject', `${t('form.meetWith')} ${linkedUserName}`)
        formik.setFieldTouched('subject')
        return
      default:
        formik.setFieldValue('subject', '')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.task_type, linkedUserName, isOpen, t])

  const assignedTeamError =
    formik.touched.assigned_team && formik.errors.assigned_team ? formik.errors.assigned_team : null

  return (
    <ModalCommon
      title={t('common:setTask')}
      content={
        <>
          <SwitchType
            type={formik.values.task_type}
            onChangeType={type => {
              formik.setFieldValue('task_type', type)
              formik.setFieldTouched('task_type')
            }}
          />
          <Input
            {...formik.getFieldProps('subject')}
            label={t('form.subject')}
            invalid={formik.touched.subject && !!formik.errors.subject}
            errorText={t('form.subjectError')}
            limit={55}
            required
          />
          <DatePicker
            onChange={value => {
              formik.setFieldValue('deadline', value)
              formik.setFieldTouched('deadline')
            }}
            date={formik.values.deadline}
            invalid={formik.touched.deadline && !!formik.errors.deadline}
            errorText={t('form.deadlineError')}
            label={t('form.deadline')}
            reactDatePickerProps={{
              locale,
              showTimeSelect: true,
              dateFormat: 'Pp',
              timeFormat: 'p',
              placeholderText: t('common:dateTimePlaceholder'),
            }}
          />
          <TeamsSelect
            defaultTeamId={defaultTeamId}
            onChangeTeam={teamId => {
              formik.setFieldValue('assigned_team', teamId)
              formik.setFieldValue('assignee', undefined)
            }}
            invalid={!!assignedTeamError}
            errorText={t('form.assignedTeamError')}
          />
          {!!formik.values.assigned_team && (
            <AssigneeSelect
              placeholder={t('form.assigneePlaceholder')}
              label={t('form.assignee')}
              onAssigneeChange={assignee => {
                formik.setFieldValue('assignee', assignee.id)
              }}
              initialValue={formik.values.assignee}
              noOptionsMessage={() => t('form.noOptionAssignee')}
              teamId={formik.values.assigned_team}
            />
          )}
          <StyledTextArea
            className="comment-area"
            {...formik.getFieldProps('comment')}
            invalid={formik.touched.comment && !!formik.errors.comment}
            errorText={formik.errors.comment}
            label={t('form.comment')}
            limit={255}
          />

          {(!!user || !!deal || !!bid) && (
            <FormSection title={t('form.linkedObject')}>
              {!!user && (
                <LabeledContainer label={t('common:user')}>
                  <UserLink user={user} />
                </LabeledContainer>
              )}

              {!!deal && (
                <LabeledContainer label={t('common:deal')}>
                  {`${deal?.sale_bid.company.name} | ${deal?.purchase_bid.company.name}`}
                </LabeledContainer>
              )}

              {!!bid && <LabeledContainer label={t('common:bid')}>{bid.company.name}</LabeledContainer>}
            </FormSection>
          )}
        </>
      }
      footer={
        <>
          {isFromDashboard && (
            <LongTextButton
              filled
              progress={progress}
              intent="primary"
              onClick={() => {
                goToDashboard.current = true
                formik.submitForm().catch()
              }}
            >
              {t('form.saveAndGoToDashboard')}
            </LongTextButton>
          )}
          <Button filled progress={progress} intent="primary" onClick={formik.submitForm}>
            {t('common:save')}
          </Button>
          <Button intent="primary" onClick={close}>
            {t('common:cancel')}
          </Button>
        </>
      }
      isOpen={isOpen}
      close={close}
    />
  )
}
