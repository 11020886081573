import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { SimpleSelect, useAction } from '@agro-club/agroclub-shared'

import CallsActions from 'modules/domain/calls/duck'
import CallsSelectors from 'modules/domain/calls/selectors'
import { CallType } from 'modules/domain/userCalls/types'
import * as Styled from 'views/pages/Calls/CallsTable/styled'

export function CallTypeFilter() {
  const { t } = useTranslation('calls')
  const filterUpdated = useAction(CallsActions.filterUpdated)
  const { call_type, ...restFilter } = useSelector(CallsSelectors.filter)
  const options = useMemo(
    () =>
      Object.keys(CallType).map(pType => ({
        id: pType,
        title: t(`callTypeFilter.${pType}`),
      })),
    [t],
  )

  const handleChange = (id: string) => {
    filterUpdated({ ...restFilter, call_type: id })
  }

  const handleClear = () => {
    filterUpdated({ ...restFilter, call_type: undefined })
  }

  return (
    <SimpleSelect
      placeholder={t('any')}
      isClearable
      options={options}
      onChange={handleChange}
      customStyles={Styled.customSelectStyles}
      value={call_type}
      onClear={handleClear}
    />
  )
}
