import { Progress } from '@agro-club/agroclub-shared'
import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer'

import { Bid } from 'modules/domain/bid/types'

import { DealGroup, UserBidState } from './types'

const initialState: UserBidState = {}

class UserBidReducer extends ImmerReducer<UserBidState> {
  bidListRequested(userId: string) {
    const nextState: UserBidState = {
      bidList: [],
      fetchBidListProgress: Progress.WORK,
      dealGroupList: [],
      fetchDealGroupListProgress: Progress.IDLE,
    }
    this.draftState[userId] = nextState
  }

  bidListRequestSucceed(userId: string, list: Bid[]) {
    this.draftState[userId].bidList = list
    this.draftState[userId].fetchBidListProgress = Progress.SUCCESS
  }

  bidListRequestError(userId: string) {
    this.draftState[userId].fetchBidListProgress = Progress.ERROR
  }

  setActiveBidId(userId: string, bidId: string) {
    this.draftState[userId].activeBidId = bidId
    this.draftState[userId].fetchDealGroupListProgress = Progress.WORK
    this.draftState[userId].dealGroupList = []
  }

  dealGroupListRequestSucceed(userId: string, list: DealGroup[]) {
    this.draftState[userId].dealGroupList = list
    this.draftState[userId].fetchDealGroupListProgress = Progress.SUCCESS
  }

  dealGroupListRequestError(userId: string) {
    this.draftState[userId].dealGroupList = []
    this.draftState[userId].fetchDealGroupListProgress = Progress.ERROR
  }
}

export const UserBidActions = createActionCreators(UserBidReducer)
export default UserBidActions
export const reducer = createReducerFunction(UserBidReducer, initialState)
