import React from 'react'
import { Link, generatePath } from 'react-router-dom'

import styled from 'styled-components'

import useLocationFrom from 'hooks/useLocationFrom'
import useUserName from 'hooks/useUserName'
import { CarOwner } from 'modules/domain/car/types'
import { User } from 'modules/domain/user/types'
import UserRoutes from 'views/pages/User/routes'

type Props = {
  user: User | CarOwner
}

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.color.primary600};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

export const UserLink: React.FC<Props> = ({ user }) => {
  const ownerName = (user as CarOwner).full_name
  const userName = useUserName(user as User)
  const name = ownerName ?? userName
  const from = useLocationFrom()

  return (
    <StyledLink
      to={{
        pathname: generatePath(UserRoutes.Detail, { id: user.id || 0 }),
        state: { from },
      }}
    >
      {name}
    </StyledLink>
  )
}
