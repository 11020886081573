import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useTable } from 'react-table'

import {
  AdvancedHeadCell,
  Progress,
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHead,
  TableHeadRow,
  TableNoData,
  useAction,
} from '@agro-club/agroclub-shared'
import { isNil } from 'ramda'

import CallsActions from 'modules/domain/calls/duck'
import { useCallsList } from 'modules/domain/calls/hooks'
import CallsSelectors from 'modules/domain/calls/selectors'
import { Calls } from 'modules/domain/calls/types'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import PlayerProvider from 'views/components/Player/PlayerProvider'
import CallsFilter from 'views/pages/Calls/CallsTable/CallsFilter'
import useColumns from 'views/pages/Calls/CallsTable/useColumns'

const CallsSortableHeadCell = AdvancedHeadCell<keyof Calls>()

const CallsTable: React.FC = () => {
  const { t } = useTranslation(['calls', 'common'])
  const [progress, data] = useCallsList()

  const sortingUpdated = useAction(CallsActions.sortingUpdated)
  const listRequested = useAction(CallsActions.listRequested)
  const filterUpdated = useAction(CallsActions.filterUpdated)
  const filterState = useSelector(CallsSelectors.filter)
  const total = useSelector(CallsSelectors.total)
  const pages = useSelector(CallsSelectors.pages)
  const page = useSelector(CallsSelectors.page)
  const pageSize = useSelector(CallsSelectors.pageSize)
  const { sort_field, sort_reversed } = useSelector(CallsSelectors.sorting)
  const visibleColumns = useColumns()

  const { columns, rows, prepareRow } = useTable<Calls>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore weird issue with react-table typings — having more then 26 fields in type causes TS error
    columns: visibleColumns,
    data: data as Calls[],
  })

  const setPage = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  const handleClearFilters = useCallback(() => {
    filterUpdated({})
  }, [filterUpdated])

  const isFilterApplied = Object.values(filterState).some(value => !isNil(value))

  return (
    <TComponents.Wrapper>
      <CallsFilter />
      <Table total={total} pages={pages} pageSize={pageSize} currentPage={page} onSetPage={setPage}>
        <TableHead>
          <TableHeadRow>
            {columns.map(column => (
              <CallsSortableHeadCell
                zIndex={1}
                key={column.getHeaderProps().key}
                id={column.id as keyof Calls}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                sortable={column.sortable}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                hidden={column.hidden}
                sortField={sort_field}
                sortDesc={sort_reversed}
                onChange={sortingUpdated}
              >
                {column.render('Header')}
              </CallsSortableHeadCell>
            ))}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          <PlayerProvider>
            {rows.map(row => {
              prepareRow(row)
              const { key, ...props } = row.getRowProps()

              return (
                <TableBodyRow key={key} {...props}>
                  {row.cells.map(cell => {
                    const { key, ...props } = cell.getCellProps()
                    return (
                      <TableBodyCell key={key} {...props} cellValign="middle">
                        {cell.render('Cell')}
                      </TableBodyCell>
                    )
                  })}
                </TableBodyRow>
              )
            })}
          </PlayerProvider>
          <TableNoData
            progress={progress}
            isEmpty={!rows.length}
            colSpan={visibleColumns.length}
            loading={<TComponents.Spinner />}
          >
            <div>{isFilterApplied ? t('list.emptyFilterMsg') : t('list.emptyMsg')}</div>
            {isFilterApplied && progress !== Progress.WORK && (
              <TComponents.ClearButton intent="cancel" size="small" onClick={handleClearFilters}>
                {t('common:resetAllFilters')}
              </TComponents.ClearButton>
            )}
          </TableNoData>
        </TableBody>
      </Table>
    </TComponents.Wrapper>
  )
}

export default CallsTable
