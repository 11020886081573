import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Typography, useAction, useHelmet } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import logo from 'assets/images/logo.svg'
import useUserName from 'hooks/useUserName'
import AuthActions from 'modules/domain/auth/duck'
import AuthSelectors from 'modules/domain/auth/selectors'
import * as S from 'views/pages/Auth/styled'

const Text = styled.div`
  text-align: center;
  margin-bottom: 16px;
  ${Typography.caption1Bold}
`

export const NotPermitted: React.FC = () => {
  const { t } = useTranslation('auth')
  useHelmet({ title: t('metaTitle') })
  const current = useSelector(AuthSelectors.profile)
  const name = useUserName(current)
  const logoutAction = useAction(AuthActions.signOutRequested)
  return (
    <S.Wrapper>
      <S.ContentWrapper>
        <S.LogoStyled src={logo} alt="logo" />
        <Text>{t('notPermitted', { name })}</Text>
        <S.SubmitButton filled size="big" intent="primary" onClick={logoutAction}>
          {t('logout')}
        </S.SubmitButton>
      </S.ContentWrapper>
    </S.Wrapper>
  )
}
