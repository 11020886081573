import { Progress } from '@agro-club/agroclub-shared'

import { Bid } from 'modules/domain/bid/types'
import { Company } from 'modules/domain/company/types'
import {
  AddError,
  Address,
  CloseReason,
  DocumentFile,
  DocumentType,
  Documents,
  EntityMetadata,
  FetchError,
  NetworkError,
  Product,
  RemoveError,
  UpdateError,
} from 'modules/domain/types'
import { Dict } from 'types/generics.d'

export type DealState = {
  items: Dict<Deal>
  meta: Dict<EntityMetadata<Deal>>
  ids: string[]
  listFetchProgress: Progress
  listFetchError: FetchError | null
  listFetchErrorDetail?: string
  itemFetchProgress: Progress
  itemFetchError: FetchError | null
  addProgress: Progress
  addError: AddError | null
  addErrorDetail?: string
  addErrorFields?: Record<keyof Deal, string>
  updateProgress: Progress
  updateError: UpdateError | null
  removeProgress: Progress
  removeError: RemoveError | null
  removeErrorDetail?: string

  filter: DealListRequestFilter
  sorting: DealListRequestSorting
  page: number
  total: number
  pageSize: number

  // mapped by shipment id
  shipmentItems: Dict<DealShipment>
  // mapped by deal id
  shipmentListFetchProgress: Dict<Progress>
  // mapped by deal id
  shipmentAddProgress: Dict<Progress>
  shipmentEditProgress: Dict<Progress>
  shipmentDeleteProgress: Dict<Progress>
  // mapped by deal id
  shipmentMeta: Dict<EntityMetadata<DealShipment>>
  // mapped by DealID => DealShipmentID[]
  shipmentIds: Dict<string[]>

  changeStatusProgress: Dict<Progress>
  changePurchaseBidProgress: Dict<Progress>
  changeSaLeBidProgress: Dict<Progress>

  documents: Dict<Documents> // dict by deal id
  deleteDocumentProgress: Dict<Progress> // dict by file pk

  closeDealProgress: Dict<Progress> // dict by deal id

  closeReasonsProgress: Progress
  closeReasons: CloseReason[]

  getTypedDocumentProgress: Dict<Progress>
  getTypedDocumentError: Dict<FetchError | null>
  getTypedDocumentErrorDetail: Dict<string | undefined>
  documentTypes: Dict<DocumentType[]>
  getDocumentFilesProgress: Dict<Progress>
  getDocumentFilesError: Dict<FetchError | null>
  getDocumentFilesErrorDetail: Dict<string | undefined>
  documentFiles: Dict<DocumentFile[]>
  uploadProgress: Dict<Progress>
  uploadError: Dict<FetchError | null>
  uploadErrorDetail: Dict<string | undefined>
  deleteTypedDocumentProgress: Dict<Progress>
}

export enum DealType {
  agent = 'agent',
  trade = 'trade',
}

export enum MarginLevel {
  unknown = 'unknown',
  low = 'low',
  high = 'high',
}

export enum ClosedReason {
  price_ss = 'price_ss',
  price_zz = 'price_zz',
  logistics = 'logistics',
  slow_documents = 'slow_documents',
  no_documents = 'no_documents',
  quality = 'quality',
  small_margin = 'small_margin',
  outer_factors = 'outer_factors',
  better_matches = 'better_matches',
  security_check = 'security_check',
  not_actual_bid = 'not_actual_bid',
}

export enum WhoResponded {
  purchase = 'purchase',
  sale = 'sale',
}

export enum DealSource {
  response = 'response',
  matching = 'matching',
}

export enum HighlightPrice {
  cpt = 'cpt',
  exw = 'exw',
}

export type DealBid = Omit<Bid, 'address'> & { address: { id: string } }

export type Owner = {
  id: string
  date_joined: string
  signup_step: string
  phone: string
  email: string
  is_active: boolean
}

export type Deal = {
  id: string
  deal_type: DealType
  status: string
  status_label?: string
  closed_at: string | null
  closed_reason: ClosedReason | null
  quantity: number
  quantity_in_bags?: number
  margin: number
  margin_level: MarginLevel
  distance: number
  price_exw: number
  price_cpt: number
  tax_from_exw?: number
  price_logistics: number
  price_logistics_per_ton?: number
  product: Product
  sale_bid:
    | DealBid
    | {
        id: string
        owner: string
        company: {
          id: string
          name: string
        }
      }
  purchase_bid:
    | DealBid
    | {
        id: string
        owner: Owner
        company: {
          id: string
          name: string
        }
        address: { id: string }
      }
  purchase_specification: PurchaseSpecification
  specifications_for_purchase: SpecificationsForPurchase[]
  fetchPurchaseSpecificationProgress: Progress
  fetchPurchaseSpecificationError: NetworkError | null
  bindingSpecificationProgress: Progress
  bindSpecificationError?: NetworkError | null
  created?: string
  modified?: string
  gmv?: number
  price_with_logistics?: number
  price_with_logistics_per_ton?: number
  tax_from_logistics_price?: number
  paid_sum: number
  delivered_quantity?: number
  delivered_quantity_in_bags?: number
  margin_percent?: number
  margin_per_kg?: number
  is_private: boolean
  actual_margin: number
  margin_on_way: number
  actual_margin_per_kg: number
  actual_margin_percent: number
  source: DealSource
  who_responded: WhoResponded | null
  highlight_price?: HighlightPrice | null
  commission?: number
  price_logistics_ton_per_km?: number
  price_logistics_bag_per_km?: number
  available_statuses: string[]
}

export type DealListRequestFilter = {
  search?: string
  team?: string[]
  month?: string // '2021-09'
  dealCondition: DealCondition
  product?: string[]
}

export type DealListRequestSorting = {
  sort_field?: keyof Deal
  sort_reversed?: boolean
}

export type DealDTO = {
  quantity: number
  price_logistics: number
  distance: number
  deal_type: DealType
  price_exw: number
  price_cpt: number
}

export type DealShipment = {
  id: string
  delivered_at: string
  quantity: number
  quantity_in_bags?: number
  price_exw: number
  price_cpt: number
  price_logistics: number
  gmv: number
  margin: number
  margin_percent: number
}

export enum DealCondition {
  open = 'open',
  closed = 'closed',
}

export type DealsBidDto = {
  quantity: number
  price: number
}

export type SpecificationsForPurchase = {
  id: string
  number: string
  signed_date: string
  company: Company
  contract: null
  owner: Owner
  address: Address
  product: Product
  price: number
  quantity: number
  quantity_shipped: number
  quantity_left: number
  deadline: string
  status: string
}

export type PurchaseSpecification = {
  contract?: string | null
  id: string
  number: string
  signed_date: string
  status: string
}

export type Executors = {
  user_coordinator: Executor
  deal_coordinator: Executor
  logistician: Executor
}

type Executor = {
  id: number
  full_name: string
  is_active?: boolean
}
export type DealShipmentDTO = Omit<DealShipment, 'id'>
