import { useLocation } from 'react-router-dom'

import { LocationStateFrom } from 'types/location'

const useLocationFrom = (): LocationStateFrom['from'] => {
  const { pathname, search } = useLocation()

  return {
    pathname,
    search,
  }
}

export default useLocationFrom
