import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { useAction } from '@agro-club/agroclub-shared'
import { isNil } from 'ramda'
import styled from 'styled-components'

import DashboardActions from 'modules/domain/dashboard/duck'
import { useFilterUpdateOnMount } from 'modules/domain/dashboard/hooks'
import DashboardSelectors from 'modules/domain/dashboard/selectors'
import { AssigneesFilter } from 'views/components/AssigneesFilter/AssigneesFilter'
import { useOnChangeAssignee } from 'views/components/AssigneesFilter/hooks/useOnChangeAssignee'
import { Filters } from 'views/components/CommonTableComponents/CommonTableComponents'
import { DistrictFilter } from 'views/components/TableFilters/RegionDistrict/DistrictFilter'
import { useOnChangeDistrict, useOnChangeRegion } from 'views/components/TableFilters/RegionDistrict/hooks'
import { RegionFilter } from 'views/components/TableFilters/RegionDistrict/RegionFilter'
import { ResetFilterButton } from 'views/components/TableFilters/ResetFilterButton'
import { TeamFilter } from 'views/components/TeamFilter/TeamFilter'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.backgroundSecondary};
`

export const DashboardFilters: React.FC = () => {
  const filterState = useSelector(DashboardSelectors.filter)
  const filterUpdated = useAction(DashboardActions.filterUpdate)
  useFilterUpdateOnMount()
  const selectedTeams = filterState.team

  const onChangeTeam = (id: string) => {
    const set = new Set(selectedTeams)
    set.has(id) ? set.delete(id) : set.add(id)
    filterUpdated({ ...filterState, team: [...set] })
  }

  const onClearRegion = useCallback(() => {
    filterUpdated({ ...filterState, region: undefined, district: undefined })
  }, [filterState, filterUpdated])
  const onClearAssignee = useCallback(() => {
    filterUpdated({ ...filterState, assignee: [] })
  }, [filterState, filterUpdated])
  const onChangeRegion = useOnChangeRegion({ filterState, filterUpdated })
  const onChangeDistrict = useOnChangeDistrict({ filterState, filterUpdated })
  const handleClearFilters = useCallback(() => {
    filterUpdated({})
  }, [filterUpdated])
  const onAssigneeChange = useOnChangeAssignee({ filterUpdated, filterState })
  const isFilterApplied = Object.values(filterState).some(value => !isNil(value))

  return (
    <Wrapper>
      <Filters>
        <TeamFilter
          selectedIds={selectedTeams}
          onChange={onChangeTeam}
          onClear={() => {
            filterUpdated({ ...filterState, team: undefined })
          }}
        />
        <RegionFilter onChange={onChangeRegion} onClear={onClearRegion} region={filterState.region} />
        <AssigneesFilter onAssigneeChange={onAssigneeChange} onClear={onClearAssignee} values={filterState.assignee} />
        <ResetFilterButton {...{ handleClearFilters, isFilterApplied }} />
      </Filters>
      <DistrictFilter district={filterState.district} region={filterState.region} onChange={onChangeDistrict} />
    </Wrapper>
  )
}
