import qs from 'query-string'

type Param = string | number | boolean | undefined

const fixParamType = (param: Param) => String(param)

const isNonEmpty = (v: unknown): boolean => typeof v !== 'undefined'

export const stringifyQueryParams = (record: Record<string, Param | Param[]>): Record<string, string | string[]> => {
  const result: Record<string, string | string[]> = {}
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, val] of Object.entries(record)) {
    if (Array.isArray(val)) {
      result[key] = val.filter(isNonEmpty).map(fixParamType)
    } else if (isNonEmpty(val)) {
      result[key] = fixParamType(val)
    }
  }
  return result
}

export const stringifyUrl = (options: { url: string; query?: Record<string, Param | Param[]> }): string =>
  qs.stringifyUrl(
    { url: options.url, query: options.query ? stringifyQueryParams(options.query) : {} },
    { arrayFormat: 'comma' },
  )
