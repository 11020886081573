import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Progress, Spinner } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import { useCarList, useCarListsReload } from 'modules/domain/car/hooks'
import { CarStateUnity } from 'modules/domain/car/selectors'
import TripSelectors from 'modules/domain/trip/selectors'
import { LogisticsCarsList } from 'views/pages/Logistics/LogisticsRightPanel/LogisticsCarsPanel/LogisticsCarsList'

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

export const LogisticsCarsPanel: React.FC = () => {
  const { id: dealId } = useParams<{ id: string }>()
  const tripCounters = useSelector(state => TripSelectors.carCounter(state, dealId))

  const [invCarsProgress, invCars] = useCarList(CarStateUnity.InvolvedCarsByDeal, dealId)

  const addProgress = useSelector(TripSelectors.addProgress)
  useCarListsReload(dealId, addProgress)
  const deleteProgress = useSelector(TripSelectors.removeProgress)
  useCarListsReload(dealId, deleteProgress)

  if (invCarsProgress === Progress.WORK) {
    return (
      <LoadingWrapper>
        <Spinner />
      </LoadingWrapper>
    )
  }

  return <LogisticsCarsList cars={invCars} dealId={dealId} tripCounters={tripCounters} />
}
