import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Progress, useAction, useDidMount, usePageQuery, useQuery } from '@agro-club/agroclub-shared'
import { isEmpty } from 'ramda'

import AuthSelectors from 'modules/domain/auth/selectors'
import BidActions, { DEFAULT_BID_TYPE, ListRequestedParams } from 'modules/domain/bid/duck'
import BidSelectors from 'modules/domain/bid/selectors'
import { Bid, BidListRequestFilter, BidListRequestSorting } from 'modules/domain/bid/types'
import { ResourceHook } from 'modules/types'

export const useBidFilterAndSortQuery = () => {
  const query = useQuery()
  const search = query.get('search')
  const bid_type = query.get('bid_type')
  const sort_field = query.get('sort_field') as keyof Bid
  const sort_reversed = query.get('sort_reversed')

  const productStr = query.get('product')
  const productArr = productStr ? productStr.split(',') : undefined

  const statusStr = query.get('status')
  const statusArr = statusStr ? statusStr.split(',') : undefined

  const teamStr = query.get('team')
  const teamArr = teamStr ? teamStr.split(',') : undefined

  const longitude = query.get('longitude') ?? undefined
  const latitude = query.get('latitude') ?? undefined
  const radius = query.get('radius') ?? undefined
  const address = query.get('address')

  const regionStr = query.get('region')
  const regionArr = regionStr ? regionStr.split(',') : undefined

  const districtStr = query.get('district')
  const districtArr = districtStr ? districtStr.split(',') : undefined

  const isPotential: string | null = query.get('isPotential')

  const filter: BidListRequestFilter = {
    ...(search && { search }),
    ...(bid_type && { bid_type }),
    ...(productArr && productArr.length && { product: productArr }),
    ...(statusArr && statusArr.length && { status: statusArr }),
    ...(teamArr && teamArr.length && { team: teamArr }),
    ...(longitude && { longitude: parseFloat(longitude) }),
    ...(latitude && { latitude: parseFloat(latitude) }),
    ...(radius && { radius: parseInt(radius, 10) }),
    ...(address && { address }),
    ...(regionArr && regionArr.length && { region: regionArr }),
    ...(districtArr && districtArr.length && { district: districtArr }),
    ...(isPotential && {
      isPotential: isPotential === 'true' ? true : undefined,
    }),
  }

  const sorting: BidListRequestSorting = {
    ...(sort_field && { sort_field }),
    ...(sort_reversed && { sort_reversed: sort_reversed === 'true' }),
  }

  return { filter, sorting }
}

export const useBidList: ResourceHook<Bid[], void[]> = () => {
  const progress = useSelector(BidSelectors.listFetchProgress)
  const list = useSelector(BidSelectors.list)
  const page = usePageQuery()
  const currentTeam = useSelector(AuthSelectors.team)
  let { filter } = useBidFilterAndSortQuery()
  const { sorting } = useBidFilterAndSortQuery()

  if (!filter?.team?.length && currentTeam?.id) {
    filter = {
      ...DEFAULT_BID_TYPE,
      ...filter,
      team: [String(currentTeam.id)],
    }
  }

  const params: ListRequestedParams = {
    ...(!isEmpty(filter) && { filter }),
    ...(!isEmpty(sorting) && { sorting }),
    ...(page && { page }),
  }

  const fetchAction = useAction(BidActions.listRequested, params)

  useDidMount(fetchAction)

  return [progress, list]
}

export const useBid: ResourceHook<Bid, [string]> = (id: string) => {
  const meta = useSelector(state => BidSelectors.meta(state, id))
  const item = useSelector(state => BidSelectors.item(state, id))
  const fetchAction = useAction(BidActions.itemRequested, id)

  useDidMount(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  })

  return [meta.fetchProgress, item]
}

export const useBidById: ResourceHook<Bid, [string]> = (id: string) => {
  const meta = useSelector(state => BidSelectors.meta(state, id))
  const item = useSelector(state => BidSelectors.item(state, id))
  const fetchAction = useAction(BidActions.itemRequested, id)

  useEffect(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  }, [item, id, fetchAction])

  return [meta.fetchProgress, item]
}

export const useUserBidList = (userId: string): [Progress, Bid[]] => {
  const progress = useSelector(BidSelectors.userBidFetchProgress)
  const fetchAction = useAction(BidActions.userBidListRequested, { userId })
  const list = useSelector(state => BidSelectors.userBidList(state, userId))
  useDidMount(fetchAction)
  return [progress, list]
}
