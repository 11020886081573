import { Progress } from '@agro-club/agroclub-shared'
import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer'

import {
  AddError,
  CloseReason,
  Documents,
  FetchError,
  NetworkError,
  RemoveError,
  RespFile,
  UpdateError,
} from 'modules/domain/types'
import { arrToDict, getIds } from 'modules/utils/helpers'

import {
  ClosedReason,
  Deal,
  DealCondition,
  DealDTO,
  DealListRequestFilter,
  DealListRequestSorting,
  DealShipment,
  DealShipmentDTO,
  DealState,
  DealsBidDto,
  SpecificationsForPurchase,
} from './types'

export const DEFAULT_DEAL_FILTER: DealListRequestFilter = {
  dealCondition: DealCondition.open,
}

const initialState: DealState = {
  items: {},
  meta: {},
  ids: [],

  listFetchProgress: Progress.IDLE,
  listFetchError: null,
  itemFetchProgress: Progress.IDLE,
  itemFetchError: null,
  addProgress: Progress.IDLE,
  addError: null,
  updateProgress: Progress.IDLE,
  updateError: null,
  removeProgress: Progress.IDLE,
  removeError: null,

  filter: DEFAULT_DEAL_FILTER,
  sorting: {},
  page: 1,
  total: 0,
  pageSize: 10000,

  shipmentItems: {},
  shipmentMeta: {},
  shipmentIds: {},
  shipmentListFetchProgress: {},
  shipmentAddProgress: {},
  shipmentEditProgress: {},
  shipmentDeleteProgress: {},

  changeStatusProgress: {},

  changePurchaseBidProgress: {},
  changeSaLeBidProgress: {},

  documents: {},
  deleteDocumentProgress: {},

  closeDealProgress: {},

  closeReasons: [],
  closeReasonsProgress: Progress.IDLE,

  getTypedDocumentProgress: {},
  getTypedDocumentError: {},
  getTypedDocumentErrorDetail: {},
  documentTypes: {},
  uploadProgress: {},
  uploadError: {},
  uploadErrorDetail: {},
  getDocumentFilesProgress: {},
  getDocumentFilesError: {},
  getDocumentFilesErrorDetail: {},
  documentFiles: {},
  deleteTypedDocumentProgress: {},
}

const DEFAULT_DOCUMENTS_STATE: Documents = {
  fetchProgress: Progress.IDLE,
  uploadProgress: Progress.IDLE,
  files: [],
}

export type DealListRequestedParams = {
  filter?: DealListRequestFilter
  sorting?: DealListRequestSorting
  page?: number
}

class DealReducer extends ImmerReducer<DealState> {
  listRequested(params: DealListRequestedParams) {
    this.draftState.listFetchProgress = Progress.WORK
    this.draftState.listFetchErrorDetail = undefined
    this.draftState.listFetchError = null
    this.draftState.filter = params.filter || this.draftState.filter
    this.draftState.sorting = params.sorting || this.draftState.sorting
    this.draftState.page = typeof params.page === 'undefined' ? this.draftState.page : params.page
  }

  listRequestSucceed(list: Deal[], total: number, page: number) {
    this.draftState.listFetchProgress = Progress.SUCCESS
    this.draftState.listFetchErrorDetail = undefined
    this.draftState.items = { ...this.draftState.items, ...arrToDict(list) }
    this.draftState.meta = {
      ...this.draftState.meta,
      ...arrToDict(
        list.map(item => ({
          id: item.id,
          fetchProgress: Progress.SUCCESS,
          fetchError: null,
          removeProgress: Progress.IDLE,
          removeError: null,
          updateProgress: Progress.IDLE,
          updateError: null,
        })),
      ),
    }
    this.draftState.ids = getIds(list)
    this.draftState.total = total
    this.draftState.page = page
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  listRequestFailed(error: FetchError, errorDetail?: string) {
    this.draftState.listFetchProgress = Progress.ERROR
    this.draftState.listFetchError = error
    this.draftState.listFetchErrorDetail = errorDetail
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  itemRequested(id: string) {
    this.draftState.itemFetchProgress = Progress.WORK

    const meta = {
      id,
      updateProgress: Progress.IDLE,
      updateError: null,
      removeProgress: Progress.IDLE,
      removeError: null,
    }

    this.draftState.meta[id] = {
      ...meta,
      ...this.draftState.meta[id],
      fetchProgress: Progress.WORK,
      fetchError: null,
    }
  }

  itemRequestSucceed(item: Deal) {
    this.draftState.itemFetchProgress = Progress.SUCCESS
    this.draftState.meta[item.id].fetchProgress = Progress.SUCCESS
    this.draftState.meta[item.id].fetchError = null
    this.draftState.meta[item.id].fetchErrorDetail = undefined
    this.draftState.items[item.id] = item
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  itemRequestFailed(id: string, error: FetchError, errorDetail?: string) {
    this.draftState.itemFetchProgress = Progress.ERROR
    this.draftState.meta[id].fetchProgress = Progress.ERROR
    this.draftState.meta[id].fetchError = error
    this.draftState.meta[id].fetchErrorDetail = errorDetail
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  addRequested(_dto: DealDTO) {
    this.draftState.addProgress = Progress.WORK
    this.draftState.addError = null
    this.draftState.addErrorDetail = undefined
    this.draftState.addErrorFields = undefined
  }

  addSucceed(item: Deal) {
    this.draftState.addProgress = Progress.SUCCESS
    this.draftState.items[item.id] = item
    this.draftState.meta[item.id] = {
      id: item.id,
      fetchProgress: Progress.SUCCESS,
      fetchError: null,
      updateProgress: Progress.IDLE,
      updateError: null,
      removeProgress: Progress.IDLE,
      removeError: null,
    }
    this.draftState.addErrorDetail = undefined
    this.draftState.addErrorFields = undefined
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  addFailed(error: AddError, errorDetail?: string, errorFields?: Record<string, string[]>) {
    this.draftState.addProgress = Progress.ERROR
    this.draftState.addError = error
    this.draftState.addErrorDetail = errorDetail
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.draftState.addErrorFields = errorFields
  }

  changeStatusRequested(id: string, _status: string) {
    this.draftState.changeStatusProgress[id] = Progress.WORK
  }

  changeStatusSuccess(item: Deal) {
    this.draftState.changeStatusProgress[item.id] = Progress.SUCCESS
    this.draftState.items[item.id] = item
  }

  changeStatusError(id) {
    this.draftState.changeStatusProgress[id] = Progress.ERROR
  }

  changePurchaseBidRequested(id: string, _dto: DealsBidDto) {
    this.draftState.changePurchaseBidProgress[id] = Progress.WORK
  }

  changePurchaseBidSuccess(item: Deal) {
    this.draftState.changePurchaseBidProgress[item.id] = Progress.SUCCESS
    this.draftState.items[item.id] = item
  }

  changePurchaseBidError(id) {
    this.draftState.changePurchaseBidProgress[id] = Progress.ERROR
  }

  changeSaleBidRequested(id: string, _dto: DealsBidDto) {
    this.draftState.changeSaLeBidProgress[id] = Progress.WORK
  }

  changeSaleBidSuccess(item: Deal) {
    this.draftState.changeSaLeBidProgress[item.id] = Progress.SUCCESS
    this.draftState.items[item.id] = item
  }

  changeSaleBidError(id) {
    this.draftState.changeSaLeBidProgress[id] = Progress.ERROR
  }

  updateRequested(id: string, _dto: DealDTO) {
    this.draftState.updateProgress = Progress.WORK
    this.draftState.meta[id].updateProgress = Progress.WORK
    this.draftState.meta[id].updateError = null
    this.draftState.meta[id].updateErrorDetail = undefined
    this.draftState.meta[id].updateErrorFields = undefined
  }

  updateSucceed(id: string, deal: Deal) {
    this.draftState.items[id] = {
      ...deal,
    }
    this.draftState.updateProgress = Progress.SUCCESS
    this.draftState.meta[id].updateProgress = Progress.SUCCESS
    this.draftState.meta[id].updateError = null
    this.draftState.meta[id].updateErrorDetail = undefined
    this.draftState.meta[id].updateErrorFields = undefined
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  updateFailed(id: string, error: UpdateError, errorText?: string, errorFields?: Record<string, string[]>) {
    this.draftState.updateProgress = Progress.ERROR
    this.draftState.meta[id].updateProgress = Progress.ERROR
    this.draftState.meta[id].updateError = error
    this.draftState.meta[id].updateErrorDetail = errorText
    this.draftState.meta[id].updateErrorFields = errorFields
  }

  resetMetaErrors(id: string) {
    this.draftState.updateProgress = Progress.SUCCESS
    this.draftState.meta[id].updateProgress = Progress.SUCCESS
    this.draftState.meta[id].updateError = null
    this.draftState.meta[id].updateErrorDetail = undefined
    this.draftState.meta[id].updateErrorFields = undefined
  }

  filterUpdated(filter: DealListRequestFilter) {
    this.draftState.filter = filter
    this.draftState.listFetchProgress = Progress.WORK
    this.draftState.page = 1
  }

  filterResetWithoutRequest() {
    this.draftState.filter = { ...DEFAULT_DEAL_FILTER }
    this.draftState.listFetchProgress = Progress.IDLE
    this.draftState.page = 1
  }

  sortingUpdated(sorting: DealListRequestSorting) {
    this.draftState.sorting = sorting
    this.draftState.listFetchProgress = Progress.WORK
  }

  filterHasBeenReset() {
    this.draftState.filter = { ...DEFAULT_DEAL_FILTER }
    this.draftState.listFetchProgress = Progress.WORK
  }

  sortingHasBeenReset() {
    this.draftState.sorting = {}
    this.draftState.listFetchProgress = Progress.WORK
  }

  removeRequested(id: string) {
    this.draftState.removeProgress = Progress.WORK
    this.draftState.meta[id].removeProgress = Progress.WORK
    this.draftState.meta[id].removeError = null
    this.draftState.meta[id].removeErrorDetail = undefined
  }

  removeSucceed(id: string) {
    this.draftState.removeProgress = Progress.SUCCESS
    delete this.draftState.items[id]
    delete this.draftState.meta[id]
    delete this.draftState.shipmentItems[id]
    delete this.draftState.shipmentIds[id]
    delete this.draftState.shipmentMeta[id]
    const i = this.draftState.ids.findIndex(item => item === id)
    if (i !== -1) {
      const { ids } = this.draftState
      this.draftState.ids = [...ids.slice(0, i), ...ids.slice(i + 1)]
    }
  }

  removeFailed(id: string, error: RemoveError, errorDetail?: string) {
    this.draftState.removeProgress = Progress.ERROR
    this.draftState.meta[id].removeProgress = Progress.ERROR
    this.draftState.meta[id].removeError = error
    this.draftState.meta[id].removeErrorDetail = errorDetail
  }

  specificationsForPurchaseRequested(id: string, _ownerId: string, _productSlug: string) {
    this.draftState.items[id].fetchPurchaseSpecificationProgress = Progress.WORK
  }

  specificationsForPurchaseSucceed(id: string, list: SpecificationsForPurchase[]) {
    this.draftState.items[id].specifications_for_purchase = list
    this.draftState.items[id].fetchPurchaseSpecificationProgress = Progress.SUCCESS
  }

  specificationsForPurchaseFailed(id: string, error: NetworkError) {
    this.draftState.items[id].fetchPurchaseSpecificationProgress = Progress.ERROR
    this.draftState.items[id].fetchPurchaseSpecificationError = error
  }

  bindSpecificationRequested(dealId: string, _specificationId: string) {
    this.draftState.items[dealId].bindingSpecificationProgress = Progress.WORK
  }

  bindSpecificationSucceed(dealId: string) {
    this.draftState.items[dealId].bindingSpecificationProgress = Progress.SUCCESS
  }

  bindSpecificationFailed(id: string, error: NetworkError) {
    this.draftState.items[id].bindingSpecificationProgress = Progress.ERROR
    this.draftState.items[id].bindSpecificationError = error
  }

  shipmentListRequested(dealId: string) {
    this.draftState.shipmentListFetchProgress[dealId] = Progress.WORK
  }

  shipmentListRequestSucceed(dealId: string, list: DealShipment[]) {
    this.draftState.shipmentListFetchProgress[dealId] = Progress.SUCCESS
    this.draftState.shipmentMeta = arrToDict(
      list.map(item => ({
        id: item.id,
        fetchProgress: Progress.SUCCESS,
        fetchError: null,
        removeProgress: Progress.IDLE,
        removeError: null,
        updateProgress: Progress.IDLE,
        updateError: null,
      })),
    )
    this.draftState.shipmentIds[dealId] = getIds(list)
    this.draftState.shipmentItems = {
      ...this.draftState.shipmentItems,
      ...arrToDict(list),
    }
  }

  shipmentListRequestFailed(dealId: string) {
    this.draftState.shipmentListFetchProgress[dealId] = Progress.ERROR
  }

  shipmentItemAddRequested(dealId: string, _dto: DealShipmentDTO) {
    this.draftState.shipmentAddProgress[dealId] = Progress.WORK
  }

  shipmentItemAddSucceed(dealId: string) {
    this.draftState.shipmentAddProgress[dealId] = Progress.SUCCESS
  }

  shipmentItemAddFailed(dealId: string) {
    this.draftState.shipmentAddProgress[dealId] = Progress.ERROR
  }

  shipmentItemEditRequested(dealId: string, _shipmentId: string, _dto: DealShipmentDTO) {
    this.draftState.shipmentEditProgress[dealId] = Progress.WORK
  }

  shipmentItemEditSucceed(dealId: string) {
    this.draftState.shipmentEditProgress[dealId] = Progress.SUCCESS
  }

  shipmentItemEditFailed(dealId: string) {
    this.draftState.shipmentEditProgress[dealId] = Progress.ERROR
  }

  shipmentItemDeleteRequested(dealId: string, _shipmentId: string) {
    this.draftState.shipmentDeleteProgress[dealId] = Progress.WORK
  }

  shipmentItemDeleteSucceed(dealId: string) {
    this.draftState.shipmentDeleteProgress[dealId] = Progress.SUCCESS
  }

  shipmentItemDeleteFailed(dealId: string) {
    this.draftState.shipmentDeleteProgress[dealId] = Progress.ERROR
  }

  documentsRequested(id: string) {
    this.draftState.documents[id] = {
      ...DEFAULT_DOCUMENTS_STATE,
      ...this.draftState.documents[id],
      fetchProgress: Progress.WORK,
    }
  }

  documentsRequestSuccess(id: string, files: RespFile[]) {
    this.draftState.documents[id] = {
      ...DEFAULT_DOCUMENTS_STATE,
      ...this.draftState.documents[id],
      fetchProgress: Progress.SUCCESS,
      files,
    }
  }

  documentsRequestFailed(id: string) {
    this.draftState.documents[id] = {
      ...DEFAULT_DOCUMENTS_STATE,
      ...this.draftState.documents[id],
      fetchProgress: Progress.ERROR,
    }
  }

  uploadDocumentsRequested(id: string, _files: File[]) {
    this.draftState.documents[id] = {
      ...DEFAULT_DOCUMENTS_STATE,
      ...this.draftState.documents[id],
      uploadProgress: Progress.WORK,
    }
  }

  uploadDocumentsSucccess(id: string, files: RespFile[]) {
    this.draftState.documents[id] = {
      ...DEFAULT_DOCUMENTS_STATE,
      ...this.draftState.documents[id],
      uploadProgress: Progress.SUCCESS,
      files: [...files, ...(this.draftState.documents?.[id]?.files ?? [])],
    }
  }

  uploadDocumentsFailed(id: string) {
    this.draftState.documents[id] = {
      ...DEFAULT_DOCUMENTS_STATE,
      ...this.draftState.documents[id],
      uploadProgress: Progress.ERROR,
    }
  }

  deleteDocumentRequested(_dealId: string, fileId: string) {
    this.draftState.deleteDocumentProgress[fileId] = Progress.WORK
  }

  deleteDocumentSucccess(dealId: string, fileId: string) {
    this.draftState.documents[dealId] = {
      ...this.draftState.documents[dealId],
      files: this.draftState.documents[dealId].files.filter(file => String(file.pk) !== String(fileId)),
    }
    this.draftState.deleteDocumentProgress[fileId] = Progress.SUCCESS
  }

  deleteDocumentFailed(fileId: string) {
    this.draftState.deleteDocumentProgress[fileId] = Progress.ERROR
  }

  closeDealRequested(dealId: string, _closed_reason: ClosedReason) {
    this.draftState.closeDealProgress[dealId] = Progress.WORK
  }

  closeDealSuccess(dealId: string) {
    this.draftState.closeDealProgress[dealId] = Progress.SUCCESS
  }

  closeDealError(dealId: string) {
    this.draftState.closeDealProgress[dealId] = Progress.ERROR
  }

  closeReasonsRequested() {
    this.draftState.closeReasonsProgress = Progress.WORK
  }

  closeReasonsSuccess(closeReasons: CloseReason[]) {
    this.draftState.closeReasons = closeReasons
    this.draftState.closeReasonsProgress = Progress.SUCCESS
  }

  closeReasonsFailed() {
    this.draftState.closeReasonsProgress = Progress.ERROR
  }

  getDocumentTypesRequested(id: string) {
    this.draftState.getTypedDocumentProgress[id] = Progress.WORK
    this.draftState.getTypedDocumentErrorDetail[id] = undefined
    this.draftState.getTypedDocumentError[id] = null
  }

  getDocumentTypesSucceed(id, list) {
    this.draftState.getTypedDocumentProgress[id] = Progress.SUCCESS
    this.draftState.getTypedDocumentErrorDetail[id] = undefined

    this.draftState.documentTypes[id] = list
  }

  getDocumentTypesFailed(id, error: FetchError, errorDetail?: string) {
    this.draftState.getTypedDocumentProgress[id] = Progress.ERROR
    this.draftState.getTypedDocumentError[id] = error
    this.draftState.getTypedDocumentErrorDetail[id] = errorDetail
  }

  uploadDocumentRequested(id: string, _document_type: string, _file: File) {
    this.draftState.uploadProgress[id] = Progress.WORK
  }

  uploadDocumentSucccess(id: string) {
    this.draftState.uploadProgress[id] = Progress.SUCCESS
  }

  uploadDocumentFailed(id: string, error: FetchError, errorDetail?: string) {
    this.draftState.uploadProgress[id] = Progress.ERROR
    this.draftState.uploadError[id] = error
    this.draftState.uploadErrorDetail[id] = errorDetail
  }

  getDocumentFilesRequested(id: string) {
    this.draftState.getDocumentFilesProgress[id] = Progress.WORK
    this.draftState.getDocumentFilesErrorDetail[id] = undefined
    this.draftState.getDocumentFilesError[id] = null
  }

  getDocumentFilesSucceed(id: string, list) {
    this.draftState.getDocumentFilesProgress[id] = Progress.SUCCESS
    this.draftState.getDocumentFilesErrorDetail[id] = undefined

    this.draftState.documentFiles[id] = list
  }

  getDocumentFilesFailed(id: string, error: FetchError, errorDetail?: string) {
    this.draftState.getDocumentFilesProgress[id] = Progress.ERROR
    this.draftState.getDocumentFilesError[id] = error
    this.draftState.getDocumentFilesErrorDetail[id] = errorDetail
  }

  deleteTypedDocumentRequested(id: string, _document_id: string) {
    this.draftState.deleteTypedDocumentProgress[id] = Progress.WORK
  }

  deleteTypedDocumentSucccess(id) {
    this.draftState.deleteTypedDocumentProgress[id] = Progress.SUCCESS
  }

  deleteTypedDocumentFailed(id) {
    this.draftState.deleteTypedDocumentProgress[id] = Progress.ERROR
  }
}

export const DealActions = createActionCreators(DealReducer)
export default DealActions
export const reducer = createReducerFunction(DealReducer, initialState)
