import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer'

import { CommentState, CommentType } from 'modules/domain/comment/types'

const initialState: CommentState = {
  inputCommentUser: {},
  inputCommentDeal: {},
  inputCommentTrip: {},
}

class CommentReducer extends ImmerReducer<CommentState> {
  changeInputComment(id: string, comment: string, type: CommentType) {
    switch (type) {
      case CommentType.user:
        this.draftState.inputCommentUser[id] = comment
        break
      case CommentType.deal:
        this.draftState.inputCommentDeal[id] = comment
        break
      case CommentType.trip:
        this.draftState.inputCommentTrip[id] = comment
        break
    }
  }
}

export const CommentActions = createActionCreators(CommentReducer)
export default CommentActions
export const reducer = createReducerFunction(CommentReducer, initialState)
