import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { stringifyUrl } from 'helpers/stringifyUrl'
import DealSelectors from 'modules/domain/deal/selectors'
import { LocationStateFrom } from 'types/location'
import DealRoutes from 'views/pages/Deal/routes'

export const useDealGoBackLocation = () => {
  const page = useSelector(DealSelectors.page)
  const filter = useSelector(DealSelectors.filter)
  const sorting = useSelector(DealSelectors.sorting)

  const search = stringifyUrl({ url: '', query: { ...filter, ...sorting, page } })

  return {
    pathname: DealRoutes.List,
    search,
  }
}

export const useDealGoBack = (id?: string) => {
  const location = useLocation<LocationStateFrom>()
  const defaultBackLocation = useDealGoBackLocation()
  const from = location.state?.from
  const history = useHistory()

  return () => {
    history.push({
      pathname: from?.pathname || defaultBackLocation.pathname,
      search: from?.pathname ? from?.search : defaultBackLocation.search,
      ...(!from && id && { state: { dealToScroll: id } }),
    })
  }
}
