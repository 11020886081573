import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import CarsActions from 'modules/domain/cars/duck'
import { Cars } from 'modules/domain/cars/types'
import { RequestError } from 'modules/errors'
import { updateLocationQuery } from 'modules/sagaHelpers'
import { ListResponse } from 'types/api.d'
import CarsRoutes from 'views/pages/Cars/routes'

import * as managers from './managers'
import CarsSelectors from './selectors'

export const fetchList = function* () {
  try {
    let currentPage = yield select(CarsSelectors.page)
    const filter = yield select(CarsSelectors.filter)
    const filterForRequestManager = yield select(CarsSelectors.filterForRequestManager)
    const sorting = yield select(CarsSelectors.sorting)
    const pageSize = yield select(CarsSelectors.pageSize)

    let response: ListResponse<Cars> = yield call(
      managers.getCarsList,
      filterForRequestManager,
      sorting,
      currentPage,
      pageSize,
    )
    const pages = Math.ceil(response.count / pageSize)
    if (pages !== 0 && pages < currentPage) {
      response = yield call(managers.getCarsList, filterForRequestManager, sorting, pages, pageSize)
      currentPage = pages
    }

    const { results, count, current } = response
    yield put(CarsActions.listRequestSucceed(results, count, current))
    yield call(updateLocationQuery, CarsRoutes.List, { page: currentPage, ...filter, ...sorting })
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(CarsActions.listRequestFailed(errType))
  }
}

export const fetchCars = function* ({ payload: id }: ReturnType<typeof CarsActions.itemRequested>) {
  try {
    const cars: Cars = yield call(managers.getCars, id)
    yield put(CarsActions.itemRequestSucceed(cars))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(CarsActions.itemRequestFailed(id, errType))
  }
}

const CarsSaga = function* () {
  yield all([
    takeLatest(CarsActions.itemRequested.type, fetchCars),

    takeLatest(CarsActions.listRequested.type, fetchList),
    takeLatest(CarsActions.filterUpdated.type, fetchList),

    takeLatest(CarsActions.sortingUpdated.type, fetchList),
    takeLatest(CarsActions.filterHasBeenReset.type, fetchList),
    takeLatest(CarsActions.sortingHasBeenReset.type, fetchList),
  ])
}

export default CarsSaga
