import { Progress } from '@agro-club/agroclub-shared'
import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer'

import { DashboardFilter, DashboardState, DashboardTask, NewUser } from 'modules/domain/dashboard/types'
import { arrToDict, getIds } from 'modules/utils/helpers'

const initialState: DashboardState = {
  filter: {},
  items: {},

  responsesIds: [],
  responsesFetchProgress: Progress.IDLE,

  matchesIds: [],
  matchesFetchProgress: Progress.IDLE,

  newIds: [],
  newFetchProgress: Progress.IDLE,

  newUsersIds: [],
  newUsersFetchProgress: Progress.IDLE,
  users: {},
}

class DashboardReducer extends ImmerReducer<DashboardState> {
  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  filterUpdate(filter?: DashboardFilter, page?: number) {
    this.draftState.filter = filter || this.draftState.filter || {}
    this.draftState.newUsersPage = page || 1
  }

  cancelDashboardRequest() {
    this.draftState.responsesFetchProgress = Progress.IDLE
    this.draftState.matchesFetchProgress = Progress.IDLE
    this.draftState.newFetchProgress = Progress.IDLE
  }

  matchesRequested() {
    this.draftState.matchesFetchProgress = Progress.WORK
  }

  matchesRequestedSucceed(list: DashboardTask[]) {
    this.draftState.matchesFetchProgress = Progress.SUCCESS
    this.draftState.items = { ...this.draftState.items, ...arrToDict(list) }
    this.draftState.matchesIds = getIds(list)
  }

  matchesRequestedFailed() {
    this.draftState.matchesFetchProgress = Progress.ERROR
  }

  responsesRequested() {
    this.draftState.responsesFetchProgress = Progress.WORK
  }

  responsesRequestedSucceed(list: DashboardTask[]) {
    this.draftState.responsesFetchProgress = Progress.SUCCESS
    this.draftState.items = { ...this.draftState.items, ...arrToDict(list) }
    this.draftState.responsesIds = getIds(list)
  }

  responsesRequestedFailed() {
    this.draftState.responsesFetchProgress = Progress.ERROR
  }

  newRequested() {
    this.draftState.newFetchProgress = Progress.WORK
  }

  newUsersRequested() {
    this.draftState.newUsersFetchProgress = Progress.WORK
  }

  newRequestedSucceed(list: DashboardTask[]) {
    this.draftState.newFetchProgress = Progress.SUCCESS
    this.draftState.items = { ...this.draftState.items, ...arrToDict(list) }
    this.draftState.newIds = getIds(list)
  }

  newUsersRequestedSucceed(list: NewUser[], count) {
    this.draftState.newUsersFetchProgress = Progress.SUCCESS
    this.draftState.users = { ...this.draftState.users, ...arrToDict(list) }
    this.draftState.newUsersIds = getIds(list)
    this.draftState.newUsersCount = count || 0
  }

  newRequestedFailed() {
    this.draftState.newFetchProgress = Progress.ERROR
  }

  newUsersRequestedFailed() {
    this.draftState.newUsersFetchProgress = Progress.ERROR
  }

  taskRemoved(id) {
    const callback = (taskId: string) => String(taskId) !== String(id)
    this.draftState.newIds = this.draftState.newIds.filter(callback)
    this.draftState.responsesIds = this.draftState.responsesIds.filter(callback)
    this.draftState.matchesIds = this.draftState.matchesIds.filter(callback)
  }
}

export const DashboardActions = createActionCreators(DashboardReducer)
export default DashboardActions
export const reducer = createReducerFunction(DashboardReducer, initialState)
