enum BidRoutes {
  List = '/bids',
  Add = '/bids/add',
  Detail = '/bids/:id(\\d+)',
  Edit = '/bids/:id(\\d+)/edit',
  Potential = '/potential-bids',
  PotentialDetail = '/potential-bids/:id(\\d+)',
  PotentialEdit = '/potential-bids/:id(\\d+)/edit',
}

export default BidRoutes
