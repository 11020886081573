import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Progress, useAction } from '@agro-club/agroclub-shared'

import UserContactActions from './duck'
import UserContactSelectors from './selectors'
import { UserContact } from './types'

export const useUserContact = (userId: string): [Progress, UserContact[]] => {
  const progress = useSelector(state => UserContactSelectors.listFetchProgress(state, userId))
  const list = useSelector(state => UserContactSelectors.contactsByUserId(state, userId))
  const fetchAction = useAction(UserContactActions.listRequested, userId)

  useEffect(() => {
    userId && fetchAction()
  }, [userId, fetchAction])

  return [progress, list]
}
