import { Dict } from 'types/generics.d'

export type CommentState = {
  // dict by user id
  inputCommentUser: Dict<string>
  // dict by deal id
  inputCommentDeal: Dict<string>
  // dict by trip id
  inputCommentTrip: Dict<string>
}

export enum CommentType {
  deal = 'deal',
  user = 'user',
  trip = 'trip',
}
