import React from 'react'

import { Typography } from '@agro-club/agroclub-shared'
import styled, { StyledProps } from 'styled-components'

import { CrossButton } from 'views/ui/CrossButton/CrossButton'
import { Modal } from 'views/ui/Modal/Modal'

const ModalContent = styled.div<StyledProps<{ $width: number }>>`
  position: relative;
  width: ${({ $width }) => `${$width}px`};
  padding: 24px 24px 16px;
  background-color: ${({ theme }) => theme.color.backgroundSecondary};
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04);
`

const Header = styled.div`
  ${Typography.title2Bold}
`

const Content = styled.div`
  display: grid;
  grid-gap: 16px;
  padding: 16px 0;
`

const Footer = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  grid-gap: 16px;
`

const Cross = styled(CrossButton)`
  position: absolute;
  top: 16px;
  right: 16px;
`

type Props = {
  isOpen: boolean
  close: () => void
  content: React.ReactNode
  footer?: React.ReactNode
  title: string
  width?: number
}

export const ModalCommon: React.FC<Props> = props => {
  const { isOpen, close, title, footer, content, width = 560 } = props
  return (
    <Modal isOpen={isOpen}>
      <ModalContent $width={width}>
        <Cross onClick={close} />
        <Header>{title}</Header>
        <Content>{content}</Content>
        {footer && <Footer>{footer}</Footer>}
      </ModalContent>
    </Modal>
  )
}
