import React from 'react'
import { useTranslation } from 'react-i18next'

import { Progress, Spinner } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import { useSingleEntity } from 'modules/domain/common/useSingleEntity'
import { endpoints } from 'modules/endpoints'
import * as S from 'views/pages/Deal/DealEdit/styled'

type TaxItem = {
  label: string
  value: number
  highlight: boolean
}

type TaxesResp = {
  seller_side: TaxItem[]
  purchase_side: TaxItem[]
}

// it really bad copy-paste. I hope we bury this soon

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
`

const Wrapper = styled.div`
  padding: 24px 24px 12px 24px;
`

const Columns = styled.div`
  padding-top: 12px;
  display: flex;
`

const TaxColumn = styled.div`
  margin-right: 80px;
`

export const Taxes: React.FC<{ dealId: string }> = ({ dealId }) => {
  const { t } = useTranslation(['deal'])
  const [progress, data] = useSingleEntity<TaxesResp>(endpoints.taxesCalculation(dealId), null, false)

  if (progress === Progress.WORK || !data) {
    return (
      <LoadingWrapper>
        <Spinner />
      </LoadingWrapper>
    )
  }

  return (
    <Wrapper>
      <S.CardHeaderTitleLarge> {t('commonSellerHeading')} </S.CardHeaderTitleLarge>
      <Columns>
        <TaxColumn>
          <S.CardHeaderTitle>{t('seller_sideTaxesHeading')}</S.CardHeaderTitle>
          <S.CardKeyList>
            {data.seller_side.map(item => (
              <S.CardKeyValueGroup
                isPrice
                title={item.label}
                value={item.value}
                isBold={item.highlight}
                align="right"
              />
            ))}
          </S.CardKeyList>
        </TaxColumn>
        <TaxColumn>
          <S.CardHeaderTitle>{t('purchase_sideTaxesHeading')}</S.CardHeaderTitle>
          <S.CardKeyList>
            {data.purchase_side.map(item => (
              <S.CardKeyValueGroup
                isPrice
                title={item.label}
                value={item.value}
                isBold={item.highlight}
                align="right"
              />
            ))}
          </S.CardKeyList>
        </TaxColumn>
      </Columns>
    </Wrapper>
  )
}
