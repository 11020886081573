import { dissoc } from 'ramda'

import formatSortParam from 'helpers/formatSortParam'
import { DashboardFilter } from 'modules/domain/dashboard/types'
import { Specification } from 'modules/domain/specification/types'
import { RespFile } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { ListResponse } from 'types/api.d'

import {
  ClosedReason,
  Deal,
  DealCondition,
  DealDTO,
  DealListRequestFilter,
  DealListRequestSorting,
  DealShipment,
  DealShipmentDTO,
  DealsBidDto,
} from './types'

export const getList = (
  filter: DealListRequestFilter,
  sorting: DealListRequestSorting,
  page: number,
  pageSize: number,
) => {
  const endpoint = filter.dealCondition === DealCondition.open ? endpoints.dealActual() : endpoints.dealClosed()
  const cleanFilter = dissoc('dealCondition', filter)

  return apiClient.get<ListResponse<Deal>>(endpoint, {
    ...cleanFilter,
    sort: formatSortParam(sorting),
    page,
    page_size: pageSize,
  })
}

export const getItem = (id: string) => apiClient.get<Deal>(endpoints.deal(id))

export const addItem = (dto: DealDTO) => apiClient.post<Deal>(endpoints.deal(), dto)

export const updateItem = (id: string, dto: DealDTO) => apiClient.put<DealDTO>(endpoints.deal(id), dto)

export const changeStatus = (id: string, new_status: string) =>
  apiClient.post<Deal>(endpoints.dealStatus(id), { new_status })

export const removeItem = (id: string) => apiClient.delete<Deal>(endpoints.deal(id))

export const getShipmentList = (dealId: string) => apiClient.get<DealShipment[]>(endpoints.dealShipment(dealId))

export const addShipmentItem = (dealId: string, dto: DealShipmentDTO) =>
  apiClient.post<DealShipment>(endpoints.dealShipment(dealId), dto)

export const editShipmentItem = (dealId: string, shipmentId: string, dto: DealShipmentDTO) =>
  apiClient.put<DealShipment>(endpoints.dealShipment(dealId, shipmentId), dto)

export const deleteShipmentItem = (dealId: string, shipmentId: string) =>
  apiClient.delete<DealShipment>(endpoints.dealShipment(dealId, shipmentId))

export const getResponses = (filter: DashboardFilter) =>
  apiClient.get<ListResponse<Deal>>(endpoints.dashboardResponses(), { ...filter, page_size: 10000 })

export const getMatches = (filter: DashboardFilter) =>
  apiClient.get<ListResponse<Deal>>(endpoints.dashboardMatches(), { ...filter, page_size: 10000 })

export const updatePurchaseBid = (dealId: string, dto: DealsBidDto) =>
  apiClient.put(endpoints.dealUpdatePurchaseBid(dealId), dto)

export const updateSaleBid = (dealId: string, dto: DealsBidDto) =>
  apiClient.put(endpoints.dealUpdateSaleBid(dealId), dto)

export const fetchDocuments = (dealId: string) => apiClient.get<RespFile[]>(endpoints.dealFiles(dealId))

export const uploadFiles = (dealId: string, files: File[]) => {
  const promises = files.map(file => {
    const form = new FormData()
    form.append('uploaded_file', file)
    return apiClient
      .post<RespFile>(endpoints.dealFiles(dealId), form)
      .then(results => ({
        status: 'fulfilled',
        results,
      }))
      .catch(err => ({ status: 'rejected', err }))
  })

  return promises
}

export const deleteDocument = (dealId: string, fileId: string) => apiClient.delete(endpoints.dealFiles(dealId, fileId))

export const closeDeal = (id: string, closed_reason: ClosedReason) =>
  apiClient.post<unknown>(endpoints.dealClose(id), { closed_reason })

export const fetchCloseReasons = () => apiClient.get<RespFile[]>(endpoints.dealsCloseReasons())

export const getSpecificationForPurchase = (ownerId: string, productSlug: string) =>
  apiClient.get<Specification>(endpoints.specificationForPurchase(ownerId, productSlug))

export const bindSpecificationForDeal = (dealId: string, specificationId: string) =>
  apiClient.put(endpoints.specificationDealBinding(dealId), { purchase_specification: specificationId })
