import React, { useEffect, useRef, useState } from 'react'

import { SearchInput } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import usePrevious from 'hooks/usePrevious'

const StyledSearch = styled(SearchInput)`
  flex: 1;
`

const SearchInputWrapper = styled.div`
  margin-top: 12px;
  margin-bottom: 4px;
  width: 100%;
  display: flex;
  align-items: center;
`

type Props = {
  placeholder?: string
  onChangeSearch: (val: string) => void
  value?: string
}

const TableSearch: React.FC<Props> = props => {
  const { placeholder = '', onChangeSearch, value = '' } = props
  const inputRef = useRef<HTMLInputElement>(null)
  const [resetFilter, setResetFilter] = useState<number>(0)

  const prevValue = usePrevious(value)
  useEffect(() => {
    if (!value && prevValue) {
      setResetFilter(v => v + 1)
      setTimeout(() => inputRef?.current?.focus())
    }
  }, [value, setResetFilter, prevValue])

  return (
    <SearchInputWrapper>
      {props.children}
      <StyledSearch
        key={resetFilter}
        onChange={onChangeSearch}
        placeholder={placeholder}
        inputRef={inputRef}
        value={value}
      />
    </SearchInputWrapper>
  )
}

export default TableSearch
