import { Progress } from '@agro-club/agroclub-shared'
import { createSelector } from 'reselect'

import { Cars } from 'modules/domain/cars/types'
import { EntityMetadata } from 'modules/domain/types'
import { AppGlobalState } from 'modules/types'

const carsDict = (state: AppGlobalState) => state.cars.items
const ids = (state: AppGlobalState) => state.cars.ids
const cars = (state: AppGlobalState, id: string): Cars | undefined => state.cars.items[id]
const meta = (state: AppGlobalState, id: string): EntityMetadata<Cars> =>
  state.cars.meta[id] || {
    fetchError: null,
    fetchProgress: Progress.IDLE,
    id,
    removeError: null,
    removeProgress: Progress.IDLE,
    updateError: null,
    updateProgress: Progress.IDLE,
  }
const itemFetchProgress = (state: AppGlobalState) => state.cars.itemFetchProgress
const listFetchProgress = (state: AppGlobalState) => state.cars.listFetchProgress
const listFetchError = (state: AppGlobalState) => state.cars.listFetchError
const carsList = createSelector(carsDict, ids, listFetchProgress, (dict, ids, progress) => {
  if (progress === Progress.WORK) {
    return []
  }
  const result: Cars[] = []
  for (const id of ids) {
    result.push(dict[id])
  }
  return result
})

const filter = (state: AppGlobalState) => state.cars.filter
const sorting = (state: AppGlobalState) => state.cars.sorting
const page = (state: AppGlobalState) => state.cars.page
const total = (state: AppGlobalState) => state.cars.total
const hasNext = (state: AppGlobalState) => state.cars.total > state.cars.ids.length
const pageSize = (state: AppGlobalState) => state.cars.pageSize
const pages = (state: AppGlobalState) => Math.ceil(state.cars.total / state.cars.pageSize)
const carsBlockProgress = (state: AppGlobalState) => state.cars.carsBlockProgress

const files = (state: AppGlobalState, id?: string) => (id ? state.cars.documents?.[id]?.files || [] : [])
const filesFetchProgress = (state: AppGlobalState, id?: string) =>
  id ? state.cars.documents?.[id]?.fetchProgress || Progress.IDLE : Progress.IDLE
const filesUploadProgress = (state: AppGlobalState, id?: string) =>
  id ? state.cars.documents?.[id]?.uploadProgress || Progress.IDLE : Progress.IDLE

const deleteDocumentProgress = (state: AppGlobalState) => state.cars.deleteDocumentProgress

const filterForRequestManager = (state: AppGlobalState) => {
  const { filter } = state.cars
  return filter
}

const CarsSelectors = {
  filter,
  filterForRequestManager,
  sorting,
  page,
  total,
  carsDict,
  ids,
  cars,
  meta,
  itemFetchProgress,
  listFetchProgress,
  listFetchError,
  carsList,
  hasNext,
  pageSize,
  pages,
  carsBlockProgress,
  files,
  filesFetchProgress,
  filesUploadProgress,
  deleteDocumentProgress,
}

export default CarsSelectors
