import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { stringifyUrl } from 'helpers/stringifyUrl'
import CarsSelectors from 'modules/domain/cars/selectors'
import { LocationStateFrom } from 'types/location'
import CarsRoutes from 'views/pages/Cars/routes'

export const useCarsListLocation = () => {
  const page = useSelector(CarsSelectors.page)
  const filter = useSelector(CarsSelectors.filter)
  const sorting = useSelector(CarsSelectors.sorting)

  const search = stringifyUrl({ url: '', query: { ...filter, ...sorting, page } })

  return {
    pathname: CarsRoutes.List,
    search,
  }
}

export const useCarsGoBack = () => {
  const location = useLocation<LocationStateFrom>()
  const defaultBackLocation = useCarsListLocation()
  const from = location.state?.from
  const history = useHistory()

  return () => {
    history.push({
      pathname: from?.pathname || defaultBackLocation.pathname,
    })
  }
}
