import { useEffect, useState } from 'react'

import { Progress } from '@agro-club/agroclub-shared'

import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { LegalType } from 'views/components/LegalTypeSelect/types'

export const useLegalTypes = (id?: string): [Progress, LegalType[]] => {
  const [items, setItems] = useState<LegalType[]>([])
  const [progress, setProgress] = useState<Progress>(Progress.IDLE)

  useEffect(() => {
    setProgress(Progress.WORK)
    apiClient
      .get<LegalType[]>(id ? endpoints.userLegalTypes(id) : endpoints.legalTypes())
      .then(data => {
        setItems(data)
        setProgress(Progress.SUCCESS)
      })
      .catch(() => setProgress(Progress.ERROR))
  }, [id])

  return [progress, items]
}
