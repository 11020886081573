import React from 'react'

import { Radio, RadioItem } from '@agro-club/agroclub-shared'

import { LabeledContainer } from 'views/components/FormComponents/FormComponents'

type Option = {
  label: string | React.ReactElement
  value: string | number
}

type Props = {
  label: string
  value?: string | number
  options: Option[]
  onChange(value: string | number): void
}

export const ExtraRadio: React.FC<Props> = ({ label, value, options, onChange }) => (
  <LabeledContainer label={label}>
    <Radio onChange={onChange} value={value}>
      {options.map(option => (
        <RadioItem key={option.value} value={option.value} label={option.label} />
      ))}
    </Radio>
  </LabeledContainer>
)
