import React from 'react'
import { useTranslation } from 'react-i18next'

import { Progress } from '@agro-club/agroclub-shared'

import { useCallStatisticsGeneral } from 'modules/domain/callStatistics/hooks'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import {
  Column,
  GeneralInner,
  HeaderTitle,
  Row,
  Title,
  Value,
} from 'views/pages/CallStatistics/CallStatisticsTable/styled'

const GeneralStatisticsTable = () => {
  const [progress, data] = useCallStatisticsGeneral()

  const { t } = useTranslation('callStatistics')

  if (progress !== Progress.SUCCESS) {
    return <TComponents.Spinner />
  }

  return (
    <GeneralInner>
      <HeaderTitle>{t('columnsTitle.all_employees')}</HeaderTitle>
      <Row>
        <div>
          <Title>{t('columnsTitle.calls_successful')}</Title>
          <Value>{data.successful}</Value>
        </div>
      </Row>
      <Row>
        <Column>
          <Title>{t('columnsTitle.incoming')}</Title>
          <Value>{data.incoming}</Value>
        </Column>
        <Column>
          <Title>{t('columnsTitle.outgoing')}</Title>
          <Value>{data.outgoing}</Value>
        </Column>
        <Column>
          <Title>{t('columnsTitle.per_day')}</Title>
          <Value>{data.per_day}</Value>
        </Column>
        <Column>
          <Title>{t('columnsTitle.per_hour')}</Title>
          <Value>{data.per_hour}</Value>
        </Column>
      </Row>
    </GeneralInner>
  )
}

export default GeneralStatisticsTable
