import React from 'react'
import ReactModal, { Props } from 'react-modal'

import { useTheme } from 'styled-components'

export const Modal: React.FC<Props> = props => {
  const theme = useTheme()
  return (
    <ReactModal
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.48)',
          zIndex: theme.zIndex.modal,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
        content: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          border: 'none',
          background: 'none',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: 0,
          outline: 'none',
          padding: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      }}
      ariaHideApp={false}
      shouldCloseOnEsc
      {...props}
    />
  )
}
