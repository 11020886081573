import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Progress, useAction, useHelmet } from '@agro-club/agroclub-shared'

import { useNotificationProgress } from 'hooks/useNotificationProgress'
import usePrevious from 'hooks/usePrevious'
import CompanyActions from 'modules/domain/company/duck'
import { useCompany, useExtraData } from 'modules/domain/company/hooks'
import CompanySelectors from 'modules/domain/company/selectors'
import Item404 from 'views/layouts/Item404/Item404'
import ItemGenericError from 'views/layouts/ItemGenericError/ItemGenericError'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import { CompanyForm } from 'views/pages/Company/CompanyDetail/CompanyForm'
import { CompanySubTitle } from 'views/pages/Company/CompanyDetail/CompanySubTitle'
import { ExtraDataEdit } from 'views/pages/Company/CompanyDetail/ExtraDataEdit/ExtraDataEdit'
import {
  useCompanyButtons,
  useCompanyFormik,
  useCompanyGoBack,
  useExtraDataFormik,
} from 'views/pages/Company/CompanyDetail/hooks'
import { TwoColumn } from 'views/pages/Company/CompanyDetail/styled'
import * as Header from 'views/ui/Header/Header'

export function CompanyEdit() {
  const { t } = useTranslation('company')
  const { id } = useParams<{ id: string }>()
  const progress = useSelector(CompanySelectors.updateProgress)
  const editAction = useAction(CompanyActions.updateRequested)
  const [fetchProgress, company] = useCompany(id)
  const meta = useSelector(state => CompanySelectors.meta(state, id))
  const goBack = useCompanyGoBack()
  const loading = () => <ItemLoadingLayout id={id} onBack={goBack} />
  const error404 = () => <Item404 id={id} onBack={goBack} title={t('errors.notFoundTitle')} />
  const errorUnknown = () => <ItemGenericError id={id} onBack={goBack} title={t('errors.unknownErrorTitle')} />
  const prevProgress = usePrevious(progress)
  const formik = useCompanyFormik({ initialValues: company, onSubmit: dto => editAction(id, dto) })

  const editExtraAction = useAction(CompanyActions.updateExtraRequested)
  const [fetchExtraProgress, extraData] = useExtraData(id)
  const extraMeta = useSelector(state => CompanySelectors.extraDataMeta(state, id))
  const extraProgress = extraMeta.updateProgress
  const extraFormik = useExtraDataFormik({
    initialValues: extraData,
    onSubmit: dto => editExtraAction(id, extraData?.id, dto),
  })

  const buttons = useCompanyButtons({ formik, progress, extraFormik, extraProgress })

  useNotificationProgress(progress, t('notifySuccessEdit'))
  useNotificationProgress(extraProgress, t('notifySuccessEditExtra'))

  useEffect(() => {
    if (progress === Progress.SUCCESS && prevProgress === Progress.WORK) {
      goBack()
    }
  }, [goBack, prevProgress, progress])

  useHelmet({ title: t('form.edit') })

  if (fetchProgress === Progress.ERROR) {
    if (meta.fetchError === 'not_found') {
      return error404()
    }
    return errorUnknown()
  }

  if (fetchProgress === Progress.WORK || !company) {
    return loading()
  }

  return (
    <>
      <Layout.Header hasBottomBorder>
        <Header.Root rightBlock={buttons}>
          <Header.Title compact title={company.name} />
          <CompanySubTitle company={company} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <TwoColumn>
          <div>
            <CompanyForm id={id} formik={formik} mode="edit" />
          </div>
          <div>
            <ExtraDataEdit
              fetchProgress={fetchExtraProgress}
              errorFields={extraMeta.updateErrorFields}
              formik={extraFormik}
            />
          </div>
        </TwoColumn>
      </Layout.Content>
    </>
  )
}
