import React, { useMemo } from 'react'

import { Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import formatPhone from 'helpers/formatPhone'

type Props = {
  phone: string
  className?: string
}

const StyledPhone = styled.a`
  color: ${({ theme }) => theme.color.primary600};
  cursor: pointer;
  ${Typography.body2Default};
  &:hover {
    text-decoration: underline;
  }
`

export const Phone: React.FC<Props> = props => {
  const { phone, className } = props
  const formatted = useMemo(() => formatPhone(phone), [phone])
  return (
    <StyledPhone
      className={className}
      href={`tel:${phone}`}
      onClick={e => {
        e.stopPropagation()
      }}
    >
      {formatted}
    </StyledPhone>
  )
}
