import { useCallback } from 'react'
import { useLocation } from 'react-router-dom'

const useNavigation = () => {
  const location = useLocation()

  const isCurrent = useCallback(
    (path?: string) => (path ? location.pathname.startsWith(path) : false),
    [location.pathname],
  )

  return {
    isCurrent,
  }
}

export default useNavigation
