import React, { VFC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { CheckboxSelect, SimpleSelectProps, useAction } from '@agro-club/agroclub-shared'

import capitalizeFirstLetter from 'helpers/capitalizeFirstLetter'
import { useLogisticStatuses } from 'modules/domain/collection/hooks'
import LogisticsDealActions from 'modules/domain/logisticsDeal/duck'
import LogisticsDealSelectors from 'modules/domain/logisticsDeal/selectors'
import { Filter } from 'views/components/TableFilters/TableFilters'

export const StatusFilter: VFC = () => {
  const filterState = useSelector(LogisticsDealSelectors.filter)
  const filterUpdated = useAction(LogisticsDealActions.filterUpdated)
  const [progress, statuses] = useLogisticStatuses()
  const { t } = useTranslation()
  const options = useMemo<SimpleSelectProps['options']>(
    () => statuses.map(status => ({ id: status.slug, title: capitalizeFirstLetter(status.label) })),
    [statuses],
  )
  const selectedIds = filterState.logistics_status || []

  const onClearStatus = useCallback(() => {
    filterUpdated({ ...filterState, logistics_status: undefined })
  }, [filterUpdated, filterState])

  const isSome = selectedIds.length > 1

  const onChange = useCallback(
    (id: string) => {
      const set = new Set(filterState.logistics_status || [])
      set.has(id) ? set.delete(id) : set.add(id)
      filterUpdated({ ...filterState, logistics_status: [...set] })
    },
    [filterState, filterUpdated],
  )

  const single =
    selectedIds.length === 1 ? capitalizeFirstLetter(statuses.find(s => s.slug === selectedIds[0])?.label) : undefined

  return (
    <Filter title={t('common:statuses')}>
      <CheckboxSelect
        progress={progress}
        options={options}
        placeholder={isSome ? t('bid:someStatuses') : t('bid:allStatuses')}
        selected={selectedIds}
        onChange={onChange}
        additionalStyles={{
          control: {
            width: 225,
          },
        }}
        onClear={onClearStatus}
        singleSelected={single}
        isSearchable
        size="small"
      />
    </Filter>
  )
}
