import { useCallback, useEffect, useState } from 'react'

import { Progress } from '@agro-club/agroclub-shared'

import { apiClient } from 'modules/utils/httpClient'

export type Comment = {
  id: string
  comment: string
  author: string
  created: string
}

export const useComments = (url: string, onSuccess: () => void) => {
  const [loading, setLoading] = useState<Progress>(Progress.IDLE)
  const [adding, setAdding] = useState<Progress>(Progress.IDLE)
  const [comments, setComments] = useState<Comment[]>([])

  const addComment = useCallback(
    async (comment: string) => {
      try {
        setAdding(Progress.WORK)
        const resp = await apiClient.post<Comment>(url, { comment })
        setComments(prev => [...prev, resp])
        setAdding(Progress.SUCCESS)
        onSuccess()
      } catch (e) {
        setAdding(Progress.ERROR)
      }
    },
    [onSuccess, setAdding, url],
  )

  useEffect(() => {
    async function loadComments() {
      const resp = await apiClient.get<Comment[]>(url)
      setComments(resp)
    }
    setLoading(Progress.WORK)
    loadComments()
      .then(() => setLoading(Progress.SUCCESS))
      .catch(() => setLoading(Progress.ERROR))
  }, [url, setComments, setLoading])

  return {
    addComment,
    comments,
    loading,
    adding,
  }
}
