import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import CarrierActions from 'modules/domain/carrier/duck'
import * as managers from 'modules/domain/carrier/managers'
import CarrierSelectors from 'modules/domain/carrier/selectors'
import { Carrier, Trailer } from 'modules/domain/carrier/types'
import { RequestError } from 'modules/errors'
import { updateLocationQuery } from 'modules/sagaHelpers'
import { ListResponse } from 'types/api.d'
import CarrierRoutes from 'views/pages/Carrier/routes'

export const fetchList = function* () {
  try {
    let currentPage = yield select(CarrierSelectors.page)
    const filter = yield select(CarrierSelectors.filter)
    const sorting = yield select(CarrierSelectors.sorting)
    const pageSize = yield select(CarrierSelectors.pageSize)

    let response: ListResponse<Carrier> = yield call(managers.getList, filter, sorting, currentPage, pageSize)
    const pages = Math.ceil(response.count / pageSize)
    if (pages !== 0 && pages < currentPage) {
      response = yield call(managers.getList, filter, sorting, pages, pageSize)
      currentPage = pages
    }

    const { results, count, current } = response
    yield put(CarrierActions.listRequestSucceed(results, count, current))
    yield call(updateLocationQuery, CarrierRoutes.List, { page: currentPage, ...filter, ...sorting })
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(CarrierActions.listRequestFailed(type, detail))
  }
}

export const fetchItem = function* ({ payload: id }: ReturnType<typeof CarrierActions.itemRequested>) {
  try {
    const item: Carrier = yield call(managers.getItem, id)
    yield put(CarrierActions.itemRequestSucceed(item))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(CarrierActions.itemRequestFailed(id, type, detail))
  }
}

export const getDocumentTypes = function* ({
  payload: id,
}: ReturnType<typeof CarrierActions.getDocumentTypesRequested>) {
  try {
    const response = yield call(managers.getDocumentTypes, id)
    yield put(CarrierActions.getDocumentTypesSucceed(id, response))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(CarrierActions.getDocumentTypesFailed(id, errType))
  }
}

export const uploadDocument = function* ({
  payload: [id, document_type, file],
}: ReturnType<typeof CarrierActions.uploadDocumentRequested>) {
  try {
    yield call(managers.uploadFile, id, document_type, file)
    yield put(CarrierActions.uploadDocumentSucccess(id))
  } catch (err) {
    const { type } = RequestError.parseError(err)
    const detail = err instanceof RequestError ? err?.errors?.uploaded_file?.[0] : undefined
    yield put(CarrierActions.uploadDocumentFailed(id, type, detail))
  }
}

export const getDocumentFiles = function* ({
  payload: id,
}: ReturnType<typeof CarrierActions.getDocumentFilesRequested>) {
  try {
    const response = yield call(managers.getDocumentFiles, id)
    yield put(CarrierActions.getDocumentFilesSucceed(id, response))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(CarrierActions.getDocumentFilesFailed(id, errType))
  }
}

export const deleteDocument = function* ({
  payload: [id, document_id],
}: ReturnType<typeof CarrierActions.deleteDocumentRequested>) {
  try {
    yield call(managers.deleteDocument, id, document_id)
    yield put(CarrierActions.deleteDocumentSucccess())
  } catch (err) {
    yield put(CarrierActions.deleteDocumentFailed())
  }
}

export const deleteTypedDocument = function* ({
  payload: [id, document_id],
}: ReturnType<typeof CarrierActions.deleteTypedDocumentRequested>) {
  try {
    yield call(managers.deleteDocumentFile, id, document_id)
    yield put(CarrierActions.deleteTypedDocumentSucccess(id))
  } catch (err) {
    yield put(CarrierActions.deleteTypedDocumentFailed(id))
  }
}

export const getTrailers = function* ({ payload: id }: ReturnType<typeof CarrierActions.trailersRequested>) {
  try {
    const list: Trailer[] = yield call(managers.getCarrierTrailers, id)
    yield put(CarrierActions.trailersRequestSuccess(id, list))
  } catch (_e) {
    yield put(CarrierActions.trailersRequestError(id))
  }
}

export const addTrailer = function* ({ payload: dto }: ReturnType<typeof CarrierActions.trailerAddRequested>) {
  try {
    yield call(managers.addTrailer, dto)
    yield put(CarrierActions.trailerAddSuccess())
  } catch (err) {
    const { type, detail, errors } = RequestError.parseError(err)
    yield put(CarrierActions.trailerAddError(type, detail, errors))
  }
}

export const updateTrailer = function* ({
  payload: [id, dto],
}: ReturnType<typeof CarrierActions.trailerUpdateRequested>) {
  try {
    yield call(managers.updateTrailer, id, dto)
    yield put(CarrierActions.trailerUpdateSuccess())
  } catch (err) {
    const { type, detail, errors } = RequestError.parseError(err)
    yield put(CarrierActions.trailerUpdateError(type, detail, errors))
  }
}

export const archiveTrailer = function* ({ payload: id }: ReturnType<typeof CarrierActions.trailerArchiveRequested>) {
  try {
    yield call(managers.archiveTrailer, id)
    yield put(CarrierActions.trailerArchiveSuccess())
  } catch (err) {
    const { type, detail, errors } = RequestError.parseError(err)
    yield put(CarrierActions.trailerArchiveError(type, detail, errors))
  }
}

const CarrierSaga = function* () {
  yield all([
    takeLatest(CarrierActions.itemRequested.type, fetchItem),
    takeLatest(CarrierActions.listRequested.type, fetchList),
    takeLatest(CarrierActions.filterUpdated.type, fetchList),
    takeLatest(CarrierActions.sortingUpdated.type, fetchList),
    takeLatest(CarrierActions.filterHasBeenReset.type, fetchList),
    takeLatest(CarrierActions.sortingHasBeenReset.type, fetchList),
    takeLatest(CarrierActions.getDocumentTypesRequested.type, getDocumentTypes),
    takeLatest(CarrierActions.uploadDocumentRequested.type, uploadDocument),
    takeLatest(CarrierActions.getDocumentFilesRequested.type, getDocumentFiles),
    takeLatest(CarrierActions.deleteTypedDocumentRequested.type, deleteTypedDocument),
    takeLatest(CarrierActions.deleteDocumentRequested.type, deleteDocument),
    takeLatest(CarrierActions.trailersRequested.type, getTrailers),
    takeLatest(CarrierActions.trailerAddRequested.type, addTrailer),
    takeLatest(CarrierActions.trailerUpdateRequested.type, updateTrailer),
    takeLatest(CarrierActions.trailerArchiveRequested.type, archiveTrailer),
  ])
}

export default CarrierSaga
