import React from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'
import { Row, useTable } from 'react-table'

import {
  AdvancedHeadCell,
  TableBody,
  TableBodyRow,
  TableHead,
  TableHeadRow,
  TableNoData,
} from '@agro-club/agroclub-shared'

import { noop } from 'helpers/noop'
import useLocationFrom from 'hooks/useLocationFrom'
import { Trip } from 'modules/domain/trip/types'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import { TripItemCallbacks } from 'views/pages/Logistics/LogisticsDetail/helpers'
import { StyledTable } from 'views/pages/Logistics/LogisticsDetail/styled'
import { useTripsColumns } from 'views/pages/Logistics/LogisticsDetail/useTripsColumns'
import LogisticsRoutes from 'views/pages/Logistics/routes'

const HeadCell = AdvancedHeadCell<keyof Trip>()

type Props = {
  trips: Trip[]
} & TripItemCallbacks

const getPathname = (row: Row<Trip>) => {
  const tripId = row?.original.id
  const dealId = row?.original.deal
  return tripId ? generatePath(LogisticsRoutes.TripDetails, { tripId, dealId }) : ''
}

const LogisticsTripsBoard: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation(['logisticsDetail', 'common'])
  const from = useLocationFrom()
  const visibleColumns = useTripsColumns()
  const { columns, rows, prepareRow } = useTable<Trip>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore weird issue with react-table typings — having more then 26 fields in type causes TS error
    columns: visibleColumns,
    data: props.trips,
  })

  return (
    <TComponents.Wrapper>
      <StyledTable total={props.trips.length} pages={1} pageSize={props.trips.length} currentPage={1} onSetPage={noop}>
        <TableHead>
          <TableHeadRow>
            {columns.map(column => (
              <HeadCell
                zIndex={1}
                key={column.getHeaderProps().key}
                id={column.id as keyof Trip}
                onChange={() => undefined}
                width={column.width}
              >
                {column.render('Header')}
              </HeadCell>
            ))}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map(row => {
            prepareRow(row)
            const { key, ...props } = row.getRowProps()
            const pathname = getPathname(row)

            return (
              <TableBodyRow key={key} {...props}>
                {row.cells.map(cell => (
                  <TComponents.RelativeTableBodyCell {...cell.getCellProps({ style: { width: cell.column.width } })}>
                    {!!pathname && <TComponents.TableCellLink to={{ pathname, state: { from } }} />}
                    {cell.render('Cell')}
                  </TComponents.RelativeTableBodyCell>
                ))}
              </TableBodyRow>
            )
          })}
          <TableNoData
            progress={props.progress}
            isEmpty={!rows.length}
            colSpan={visibleColumns.length}
            loading={<TComponents.Spinner />}
          >
            <div>{t('list.emptyMsg')}</div>
          </TableNoData>
        </TableBody>
      </StyledTable>
    </TComponents.Wrapper>
  )
}

export default LogisticsTripsBoard
