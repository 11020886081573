import formatSortParam from 'helpers/formatSortParam'
import {
  Bid,
  BidDTO,
  BidListRequestFilter,
  BidListRequestSorting,
  BidStatus,
  UserBidListFilter,
} from 'modules/domain/bid/types'
import { PotentialBid } from 'modules/domain/potentialBid/types'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { ListResponse } from 'types/api.d'

export const getList = (
  filter: BidListRequestFilter,
  sorting: BidListRequestSorting,
  page: number,
  pageSize: number,
) => {
  const reqFilter = { ...filter }
  delete reqFilter.address

  return apiClient.get<ListResponse<Bid>>(endpoints.bid(), {
    ...reqFilter,
    sort: formatSortParam(sorting),
    page,
    page_size: pageSize,
  })
}

export const getPotentialList = (
  filter: BidListRequestFilter,
  sorting: BidListRequestSorting,
  page: number,
  pageSize: number,
) => {
  const reqFilter = { ...filter }
  delete reqFilter.address
  delete reqFilter.bid_type
  delete reqFilter.status
  delete reqFilter.isPotential

  return apiClient.get<ListResponse<PotentialBid>>(endpoints.potentialBid(), {
    ...reqFilter,
    sort: formatSortParam(sorting),
    page,
    page_size: pageSize,
  })
}

export const getItem = (id: string) => apiClient.get<Bid>(endpoints.bid(id))

export const addItem = (dto: BidDTO) => apiClient.post<{ id: string }>(endpoints.bid(), dto)

export const updateItem = (id: string, dto: Partial<BidDTO>) => apiClient.put<BidDTO>(endpoints.bid(id), dto)

export const removeItem = (id: string) => apiClient.delete<Bid>(endpoints.bid(id))

export const changeStatus = (id: string, new_status: BidStatus) =>
  apiClient.post<unknown>(endpoints.bidChangeStatus(id), { new_status })

export const getUserBidList = (userId: string, filter: UserBidListFilter) =>
  apiClient.get<Bid[]>(endpoints.userBidList(userId), filter)
