import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Checkbox } from '@agro-club/agroclub-shared'
import { FormikContextType } from 'formik'

import useTonsAndBags from 'hooks/useTonsAndBags'
import { LoaderType, WarehouseDTO } from 'modules/domain/types'
import { LegalTypeSelect } from 'views/components/LegalTypeSelect/LegalTypeSelect'
import { ScheduleControls } from 'views/components/WarehouseModal/ScheduleControls'
import * as S from 'views/components/WarehouseModal/styled'
import { VehicleTypeField } from 'views/components/WarehouseModal/VehicleTypeField'
import { YesNoUnknownRadio } from 'views/components/WarehouseModal/YesNoUnknownRadio'
import { NumberInput } from 'views/ui/NumberInput/NumberInput'

type Props = {
  formik: FormikContextType<WarehouseDTO>
}

export const SellerFields: React.FC<Props> = ({ formik }) => {
  const { t } = useTranslation(['address', 'legalType'])
  const [volume_bags, volume_tonsToBags, volume_bagsToTons] = useTonsAndBags(formik.values.volume_per_day || '')
  const [scales_bags, scales_tonsToBags, scales_bagsToTons] = useTonsAndBags(formik.values.scales_capacity || '')
  const { id } = useParams<{ id: string }>()

  return (
    <>
      <S.Label>{t('fields.gates_height')}</S.Label>
      <div>
        <S.InputWrapper>
          <NumberInput
            inputSize="small"
            value={formik.values.gates_height ?? undefined}
            onChange={value => {
              formik.setFieldValue('gates_height', value)
            }}
          />
        </S.InputWrapper>
      </div>

      <S.Label>{t('fields.loader_type')}</S.Label>
      <S.InlineWrapper>
        {Object.keys(LoaderType).map(loader => (
          <Checkbox
            key={loader}
            onChange={(_value, isChecked) => {
              const set = new Set(formik.values.loader_type)
              if (isChecked) {
                if (!set.has(loader as LoaderType)) set.add(loader as LoaderType)
              } else if (set.has(loader as LoaderType)) set.delete(loader as LoaderType)
              formik.setFieldValue('loader_type', [...set])
            }}
            isChecked={formik.values.loader_type?.includes(loader as LoaderType)}
            label={t(`loaderTypes.${loader}`)}
          />
        ))}
      </S.InlineWrapper>
      <S.Label>{t('fields.scales_capacity_bag')}</S.Label>
      <div>
        <S.InputWrapper>
          <NumberInput
            inputSize="small"
            value={scales_bags}
            onChange={scales_bagsToTons(v => formik.setFieldValue('scales_capacity', v))}
          />
        </S.InputWrapper>
      </div>
      <S.Label>{t('fields.scales_capacity')}</S.Label>
      <div>
        <S.InputWrapper>
          <NumberInput
            inputSize="small"
            value={formik.values.scales_capacity ?? ''}
            onChange={scales_tonsToBags(v => formik.setFieldValue('scales_capacity', v))}
          />
        </S.InputWrapper>
      </div>

      <S.Label>{t('fields.scales_remoteness')}</S.Label>
      <div>
        <S.InputWrapper>
          <NumberInput
            inputSize="small"
            isInteger
            value={formik.values.scales_remoteness ?? ''}
            onChange={value => {
              formik.setFieldValue('scales_remoteness', value)
            }}
          />
        </S.InputWrapper>
      </div>

      <S.Label>{t('fields.scales_length')}</S.Label>
      <div>
        <S.InputWrapper>
          <NumberInput
            inputSize="small"
            isInteger
            value={formik.values.scales_length ?? ''}
            onChange={value => {
              formik.setFieldValue('scales_length', value)
            }}
          />
        </S.InputWrapper>
      </div>

      <S.Label>{t('fields.volume_per_day_bag.seller')}</S.Label>
      <div>
        <S.InputWrapper>
          <NumberInput
            inputSize="small"
            value={volume_bags}
            onChange={volume_bagsToTons(v => formik.setFieldValue('volume_per_day', v))}
          />
        </S.InputWrapper>
      </div>
      <S.Label>{t('fields.volume_per_day.seller')}</S.Label>
      <div>
        <S.InputWrapper>
          <NumberInput
            inputSize="small"
            value={formik.values.volume_per_day ?? ''}
            onChange={volume_tonsToBags(v => formik.setFieldValue('volume_per_day', v))}
          />
        </S.InputWrapper>
      </div>
      <VehicleTypeField formik={formik} />
      <ScheduleControls label={t('fields.schedule.seller')} formik={formik} />
      <S.Label>
        {t('warehouse:fields.delivery_method')} {t('warehouse:fields.car_delivery')}
      </S.Label>
      <YesNoUnknownRadio
        onChange={value => {
          formik.setFieldValue('car_delivery', value)
        }}
        value={formik.values.car_delivery}
      />
      <S.Label>
        {t('warehouse:fields.delivery_method')} {t('warehouse:fields.railway_delivery')}
      </S.Label>
      <YesNoUnknownRadio
        onChange={value => {
          formik.setFieldValue('railway_delivery', value)
        }}
        value={formik.values.railway_delivery}
      />

      <S.Label>{t('legalType:label')}</S.Label>
      <div>
        <LegalTypeSelect
          id={id}
          showLabel={false}
          onChange={val => {
            formik.setFieldValue('legal_type', val)
          }}
          value={formik.values?.legal_type}
        />
      </div>
    </>
  )
}
