import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { IconBin, useAction } from '@agro-club/agroclub-shared'

import UserContactActions from 'modules/domain/userContact/duck'
import UserContactSelectors from 'modules/domain/userContact/selectors'
import { UserContact } from 'modules/domain/userContact/types'
import {
  Cell,
  ContactTitle,
  Row,
  RowButton,
  RowButtonWrapper,
  SubmitIcon,
  Sure,
} from 'views/components/UserRightPanel/UserContact/styled'
import { UserContactForm } from 'views/components/UserRightPanel/UserContact/UserContactBlockForm'
import { EditIcon } from 'views/styled/common'
import { ConfirmationTooltip } from 'views/ui/ConfirmationTooltip/ConfirmationTooltip'
import { Phone } from 'views/ui/Phone/Phone'

type Props = {
  item: UserContact
}

export const UserContactRow: React.FC<Props> = ({ item }) => {
  const { t } = useTranslation('common')
  const [isOpen, setIsOpen] = useState<boolean>()
  const { id: userId } = useParams<{ id: string }>()
  const editProgress = useSelector(state => UserContactSelectors.editProgress(state, userId, item.id))
  const deleteProgress = useSelector(state => UserContactSelectors.deleteProgress(state, userId, item.id))
  const deleteAction = useAction(UserContactActions.deleteRequested, userId, item.id)
  const [isConfirmVisible, setConfirmVisible] = useState<boolean>(false)

  return (
    <>
      <Row key={item.id}>
        <Cell>
          <ContactTitle>
            {item.full_name}, {item.position},
          </ContactTitle>
          <Phone phone={item.phone} />
        </Cell>
        <Cell>
          <RowButtonWrapper>
            <RowButton
              disabled={isOpen}
              size="small"
              progress={editProgress}
              intent="minor-action"
              onClick={() => {
                if (!isOpen) {
                  setIsOpen(true)
                }
              }}
            >
              {isOpen ? <SubmitIcon /> : <EditIcon />}
            </RowButton>
            <ConfirmationTooltip
              hideArrow
              visible={isConfirmVisible}
              placement="bottomRight"
              overlayProps={{
                header: t('removeContactHeader'),
                body: <Sure>{t('removeContact')}</Sure>,
                okText: t('common:delete'),
                okAction: () => {
                  deleteAction()
                },
                cancelText: t('common:cancel'),
                close: () => {
                  setConfirmVisible(false)
                },
              }}
            >
              <RowButton
                size="small"
                progress={deleteProgress}
                intent="minor-action"
                onClick={() => setConfirmVisible(true)}
              >
                <IconBin />
              </RowButton>
            </ConfirmationTooltip>
          </RowButtonWrapper>
        </Cell>
      </Row>
      <UserContactForm onClose={() => setIsOpen(false)} open={Boolean(isOpen)} isEdit item={item} />
    </>
  )
}
