import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import env, { REACT_APP_LOCALE } from 'env'
import useFormatNumber from 'hooks/useFormatNumber'
import { Deal } from 'modules/domain/deal/types'
import { AvailableLanguages } from 'types/entities'
import {
  Actual,
  Column,
  LongMargin,
  MarginInTransit,
  MarginSumWrapper,
  QuestionStyled,
  QuestionWrapper,
  ToolTipText,
} from 'views/pages/Deal/DealBoard/styled'
import { Coordinates } from 'views/pages/Deal/DealBoard/types'
import { FlexBr } from 'views/styled/common'
import { Portal } from 'views/ui/Portal/Portal'
import Tooltip from 'views/ui/Tooltip/Tooltip'

import { PADDING_LEFT, PADDING_TOP } from './constants'

export const MarginSum: React.FC<{ deals: Deal[] }> = ({ deals }) => {
  const { t } = useTranslation()
  const [isVisible, setIsVisible] = useState(false)
  const [coordinates, setCoordinates] = useState<Coordinates>({ left: 0, top: 0 })
  const formatNumber = useFormatNumber()
  const currency = env.REACT_APP_CURRENCY_SYMBOL
  const isRussia = REACT_APP_LOCALE === AvailableLanguages.ru

  const marginEl = useRef<HTMLDivElement>(null)
  const longMarginEl = useRef<HTMLDivElement>(null)
  const showLongMargin = useRef<boolean>(false)

  const showTooltip = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (!showLongMargin.current) {
        return false
      }
      const rect = event.currentTarget.getBoundingClientRect()
      setCoordinates({ top: rect.top - PADDING_TOP, left: rect.left - PADDING_LEFT })
      setIsVisible(true)
    },
    [setCoordinates],
  )

  const hideTooltip = useCallback((_event: React.MouseEvent<HTMLElement>) => {
    setIsVisible(false)
  }, [])

  const margin = deals.reduce((summ, deal) => summ + deal.margin, 0)
  const actualMargin = deals.reduce((summ, deal) => summ + deal.actual_margin, 0)
  const marginOnWay = deals.reduce((summ, deal) => summ + deal.margin_on_way, 0)
  const tooltipOverlay = useMemo(
    () => (
      <>
        <ToolTipText>{t('deal:margin')}</ToolTipText>
        <ToolTipText>{t('deal:actual_margin')}</ToolTipText>
        {isRussia && <ToolTipText>{t('deal:margin_on_way')}</ToolTipText>}
      </>
    ),
    [isRussia, t],
  )

  if (!deals.length) return null

  return (
    <Column>
      <FlexBr />
      <MarginSumWrapper ref={marginEl} onMouseOver={showTooltip}>
        {currency} {formatNumber(margin)}
        <Actual>
          &nbsp;/&nbsp;
          {currency} {formatNumber(actualMargin)}
          {!isRussia && (
            <Tooltip overlay={tooltipOverlay} placement="top" trigger={['hover', 'click']}>
              <QuestionWrapper>
                <QuestionStyled />
              </QuestionWrapper>
            </Tooltip>
          )}
        </Actual>
        <Portal id="long-margin-tooltip">
          <LongMargin ref={longMarginEl} $isVisible={isVisible} $coordinates={coordinates} onMouseOut={hideTooltip}>
            {formatNumber(margin)} {t('common:rub', { currency })}
            <Actual>
              / {formatNumber(actualMargin)} {t('common:rub', { currency })}
            </Actual>
          </LongMargin>
        </Portal>
      </MarginSumWrapper>
      {isRussia && (
        <MarginInTransit>
          <>
            {formatNumber(marginOnWay)} {t('common:rub', { currency })}
          </>

          <Tooltip overlay={tooltipOverlay} placement="top" trigger={['hover', 'click']}>
            <QuestionWrapper>
              <QuestionStyled />
            </QuestionWrapper>
          </Tooltip>
        </MarginInTransit>
      )}
    </Column>
  )
}
