import React, { VFC } from 'react'
import { useParams } from 'react-router-dom'

import { useBid } from 'modules/domain/bid/hooks'
import { BidDetailCommon } from 'views/pages/Bid/BidDetail/BidDetailCommon'

export const BidDetail: VFC = () => {
  const { id } = useParams<{ id: string }>()
  const [fetchProgress, bid] = useBid(id)
  return <BidDetailCommon {...{ fetchProgress, bid }} />
}
