import React from 'react'

import { Spinner } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

const SpinnerContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SpinnerLayout: React.FC = () => (
  <SpinnerContainer>
    <Spinner />
  </SpinnerContainer>
)

export default SpinnerLayout
