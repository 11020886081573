import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useAction } from '@agro-club/agroclub-shared'

import CarrierActions from 'modules/domain/carrier/duck'
import { useCarrierFilterAndSortQuery } from 'modules/domain/carrier/hooks'
import CarrierSelectors from 'modules/domain/carrier/selectors'
import { Filters } from 'views/components/CommonTableComponents/CommonTableComponents'
import { ActiveFilter } from 'views/components/TableFilters/ActiveFilter'
import { RangeFilter } from 'views/components/TableFilters/RangeFilter'
import { RegionFilter } from 'views/components/TableFilters/RegionDistrict/RegionFilter'
import { ResetFilterButton } from 'views/components/TableFilters/ResetFilterButton'
import { TeamFilter } from 'views/components/TeamFilter/TeamFilter'

type Props = {
  handleClearFilters: () => void
  isFilterApplied: boolean
}

export const CarrierFilters: React.FC<Props> = ({ handleClearFilters, isFilterApplied }) => {
  const { t } = useTranslation('carrier')
  const filterUpdated = useAction(CarrierActions.filterUpdated)
  const filterState = useSelector(CarrierSelectors.filter)

  const { filter } = useCarrierFilterAndSortQuery()
  const start = filter.date_joined_after || filterState.date_joined_after
  const end = filter.date_joined_before || filterState.date_joined_before

  const onChangeRange = React.useCallback(
    ([start, end]) => {
      filterUpdated({ ...filterState, date_joined_after: start, date_joined_before: end })
    },
    [filterState, filterUpdated],
  )

  const selectedTeams = filterState.team
  const onChangeTeam = (id: string) => {
    const set = new Set(selectedTeams)
    set.has(id) ? set.delete(id) : set.add(id)
    filterUpdated({ ...filterState, team: [...set] })
  }

  const onChangeRegion = useCallback(
    (regionId: string) => {
      const { region = [] } = filterState
      const set = new Set(region)

      if (set.has(regionId)) {
        set.delete(regionId)
      } else {
        set.add(regionId)
      }

      filterUpdated({ ...filterState, region: [...set] })
    },
    [filterState, filterUpdated],
  )

  const onClearRegion = useCallback(() => {
    filterUpdated({ ...filterState, region: undefined })
  }, [filterState, filterUpdated])

  return (
    <Filters>
      <TeamFilter
        isNoTeam
        selectedIds={selectedTeams}
        onChange={onChangeTeam}
        onClear={() => {
          filterUpdated({ ...filterState, team: undefined })
        }}
      />
      <RangeFilter label={t('list.tableHeaders.date_joined')} onChange={onChangeRange} start={start} end={end} />
      <ActiveFilter
        title={t('list.tableHeaders.is_active')}
        is_active={filterState.is_active}
        handleChange={(value?: string) => {
          const is_active = value === 'true' ? true : value === 'false' ? false : undefined
          filterUpdated({ ...filterState, is_active })
        }}
        handleClear={() => {
          filterUpdated({ ...filterState, is_active: undefined })
        }}
      />
      <RegionFilter onChange={onChangeRegion} onClear={onClearRegion} region={filterState.region} />
      <ResetFilterButton {...{ handleClearFilters, isFilterApplied }} />
    </Filters>
  )
}
