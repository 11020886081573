import { all, call, fork } from 'redux-saga/effects'

import AuthSaga from 'modules/domain/auth/sagas'
import BidSaga from 'modules/domain/bid/sagas'
import CallsSaga from 'modules/domain/calls/sagas'
import CarSaga from 'modules/domain/car/sagas'
import CarrierSaga from 'modules/domain/carrier/sagas'
import CarsSaga from 'modules/domain/cars/sagas'
import CarTripSaga from 'modules/domain/carTrip/sagas'
import CollectionSaga from 'modules/domain/collection/sagas'
import CompanySaga from 'modules/domain/company/sagas'
import DashboardSaga from 'modules/domain/dashboard/sagas'
import DealSaga from 'modules/domain/deal/sagas'
import DriverSaga from 'modules/domain/driver/sagas'
import LogisticsDealSaga from 'modules/domain/logisticsDeal/sagas'
import PotentialBidSaga from 'modules/domain/potentialBid/sagas'
import TaskSaga from 'modules/domain/task/sagas'
import TripSaga from 'modules/domain/trip/sagas'
import UsersSaga from 'modules/domain/user/sagas'
import UserAddressSaga from 'modules/domain/userAddress/sagas'
import UserBidSaga from 'modules/domain/userBid/sagas'
import UserCallsSaga from 'modules/domain/userCalls/sagas'
import UserContactSaga from 'modules/domain/userContact/sagas'
import { locationChangeWatcher } from 'modules/sagaHelpers'

import CallStatisticsSaga from './domain/callStatistics/sagas'
import SpecificationSaga from './domain/specification/sagas'

const rootSaga = function* (): Generator {
  yield all([
    call(AuthSaga),
    call(UsersSaga),
    call(CompanySaga),
    call(BidSaga),
    call(CollectionSaga),
    call(UserBidSaga),
    fork(locationChangeWatcher),
    call(DealSaga),
    call(TaskSaga),
    call(UserAddressSaga),
    call(DashboardSaga),
    call(UserContactSaga),
    call(CarSaga),
    call(LogisticsDealSaga),
    call(DriverSaga),
    call(TripSaga),
    call(CarsSaga),
    call(CarrierSaga),
    call(UserCallsSaga),
    call(CallsSaga),
    call(CallStatisticsSaga),
    call(CarTripSaga),
    call(PotentialBidSaga),
    call(SpecificationSaga),
  ])
}

export default rootSaga
