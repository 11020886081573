import { Progress } from '@agro-club/agroclub-shared'
import { createSelector } from 'reselect'

import { Carrier, TrailerStatuses } from 'modules/domain/carrier/types'
import { EntityMetadata } from 'modules/domain/types'
import { AppGlobalState } from 'modules/types'

const dict = (state: AppGlobalState) => state.carrier.items
const ids = (state: AppGlobalState) => state.carrier.ids
const item = (state: AppGlobalState, id: string): Carrier | undefined => state.carrier.items[id]
const meta = (state: AppGlobalState, id: string): EntityMetadata<Carrier> =>
  state.carrier.meta[id] || {
    fetchError: null,
    fetchProgress: Progress.IDLE,
    id,
    removeError: null,
    removeProgress: Progress.IDLE,
    updateError: null,
    updateProgress: Progress.IDLE,
  }
const itemFetchProgress = (state: AppGlobalState) => state.carrier.itemFetchProgress
const listFetchProgress = (state: AppGlobalState) => state.carrier.listFetchProgress
const listFetchError = (state: AppGlobalState) => state.carrier.listFetchError
const listFetchErrorDetail = (state: AppGlobalState) => state.carrier.listFetchErrorDetail
const listFetchNextProgress = (state: AppGlobalState) => state.carrier.listFetchNextProgress
const listFetchNextError = (state: AppGlobalState) => state.carrier.listFetchNextError
const listFetchNextErrorDetail = (state: AppGlobalState) => state.carrier.listFetchNextErrorDetail
const list = createSelector(dict, ids, listFetchProgress, (dict, ids, progress) => {
  if (progress === Progress.WORK) {
    return []
  }
  const result: Carrier[] = []
  for (const id of ids) {
    result.push(dict[id])
  }
  return result
})

const filter = (state: AppGlobalState) => state.carrier.filter
const sorting = (state: AppGlobalState) => state.carrier.sorting
const page = (state: AppGlobalState) => state.carrier.page
const total = (state: AppGlobalState) => state.carrier.total
const updateProgress = (state: AppGlobalState) => state.carrier.updateProgress
const removeProgress = (state: AppGlobalState) => state.carrier.removeProgress
const addProgress = (state: AppGlobalState) => state.carrier.addProgress
const hasNext = (state: AppGlobalState) => state.carrier.total > state.carrier.ids.length
const pageSize = (state: AppGlobalState) => state.carrier.pageSize
const pages = (state: AppGlobalState) => Math.ceil(state.carrier.total / state.carrier.pageSize)

const documentTypes = (state: AppGlobalState, id: string) => state.carrier.documentTypes[id]
const documentProgress = (state: AppGlobalState, id: string) => state.carrier.getDocumentProgress[id]
const uploadProgress = (state: AppGlobalState, id: string) => state.carrier.uploadProgress[id]
const uploadError = (state: AppGlobalState, id: string) => state.user.uploadError[id]
const uploadErrorDetail = (state: AppGlobalState, id: string) => state.user.uploadErrorDetail?.[id]
const documentFiles = (state: AppGlobalState, id: string) => state.carrier.documentFiles[id]
const deleteTypedDocumentProgress = (state: AppGlobalState, id: string) => state.carrier.deleteTypedDocumentProgress[id]
const deleteDocumentProgress = (state: AppGlobalState) => state.carrier.deleteDocumentProgress
const trailers = (state: AppGlobalState, id?: string) =>
  (id &&
    state.carrier.trailers?.[id]?.list
      .filter(Boolean)
      .sort(prev => (prev.status === TrailerStatuses.archived ? 1 : -1))) ||
  []
const activeTrailers = (state: AppGlobalState, id?: string) =>
  (id && state.carrier.trailers?.[id]?.list?.filter(t => t.status !== TrailerStatuses.archived)) || []
const trailersFetchProgress = (state: AppGlobalState, id?: string) =>
  (id && state.carrier.trailers?.[id]?.fetchProgress) || Progress.IDLE
const trailerLicenceNumber = state => state.carrier.trailerLicenceNumber
const trailerAddProgress = state => state.carrier.trailerAddProgress
const trailerAddErrorFields = state => state.carrier.trailerAddErrorFields || []
const trailerUpdateProgress = state => state.carrier.trailerUpdateProgress
const trailerUpdateErrorFields = state => state.carrier.trailerUpdateErrorFields || []
const trailerArchiveProgress = state => state.carrier.trailerArchiveProgress
const trailerArchiveErrorFields = state => state.carrier.trailerArchiveErrorFields || []

const CarrierSelectors = {
  filter,
  sorting,
  page,
  total,
  updateProgress,
  removeProgress,
  addProgress,
  item,
  meta,
  itemFetchProgress,
  listFetchProgress,
  listFetchError,
  listFetchErrorDetail,
  list,
  dict,
  ids,
  hasNext,
  pageSize,
  listFetchNextProgress,
  listFetchNextError,
  listFetchNextErrorDetail,
  pages,
  documentTypes,
  documentProgress,
  uploadProgress,
  uploadError,
  uploadErrorDetail,
  documentFiles,
  deleteTypedDocumentProgress,
  deleteDocumentProgress,
  trailers,
  activeTrailers,
  trailersFetchProgress,
  trailerLicenceNumber,
  trailerAddProgress,
  trailerAddErrorFields,
  trailerUpdateProgress,
  trailerUpdateErrorFields,
  trailerArchiveProgress,
  trailerArchiveErrorFields,
}

export default CarrierSelectors
