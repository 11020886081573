import formatSortParam from 'helpers/formatSortParam'
import { Calls, CallsListRequestFilter, CallsListRequestSorting } from 'modules/domain/calls/types'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { ListResponse } from 'types/api.d'

export const getCallsList = (
  filter: CallsListRequestFilter,
  sorting: CallsListRequestSorting,
  page: number,
  pageSize?: number,
) => {
  try {
    return apiClient.get<ListResponse<Calls>>(endpoints.calls(), {
      ...filter,
      sort: formatSortParam(sorting),
      page,
      page_size: pageSize,
    })
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Calls list fetch error!', err)
    throw err
  }
}

export const getAccounts = () => {
  try {
    return apiClient.get(endpoints.accountsTwilio(), {
      page_size: 9999,
    })
  } catch (err) {
    console.error('Accounts list fetch error!', err)
    throw err
  }
}

export const makeCall = phone => {
  try {
    return apiClient.post(endpoints.makeCall(), {
      client_phone: phone,
    })
  } catch (err) {
    console.error('Make call post error!', err)
    throw err
  }
}
