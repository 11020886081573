import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Progress } from '@agro-club/agroclub-shared'

import useFormatDate from 'hooks/useFormatDate'
import useFormatNumber from 'hooks/useFormatNumber'
import { useExtraData } from 'modules/domain/company/hooks'
import { Spinner } from 'views/components/CommonTableComponents/CommonTableComponents'
import { useThereIsNo, useYesNo } from 'views/pages/Company/CompanyDetail/hooks'
import {
  CommonWrapper,
  Container,
  DetailHeader,
  ExtraContent,
  ExtraData,
  ExtraHeader,
  Key,
  OkvedCode,
  OkvedText,
  Value,
} from 'views/pages/Company/CompanyDetail/styled'

export const CompanyExtraData: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const [progress, extraData] = useExtraData(id)
  const { t } = useTranslation('company')
  const formatNumber = useFormatNumber()
  const formatDate = useFormatDate()

  const yesNo = useYesNo()
  const thereIsNo = useThereIsNo()

  const unknown = t('common:unknown')

  return (
    <Container>
      <DetailHeader>{t('companyExtraData')}</DetailHeader>
      <ExtraContent>
        {progress === Progress.WORK ? (
          <Spinner />
        ) : progress === Progress.ERROR ? (
          <p>{t('extraDataNotFound')}</p>
        ) : (
          <>
            <div>
              <ExtraHeader>{t('okved')}</ExtraHeader>
              <OkvedCode>
                {extraData?.okved_code || unknown}
                <OkvedText>{extraData?.okved_text}</OkvedText>
              </OkvedCode>
            </div>
            <CommonWrapper>
              <ExtraHeader>{t('common')}</ExtraHeader>
              <ExtraData>
                <Key>{t('extra.activity_type')}</Key>
                <Value>{extraData?.activity_type ? t(`activityTypes.${extraData.activity_type}`) : unknown}</Value>
                <Key>{t('extra.employees_count')}</Key>
                <Value>{formatNumber(extraData?.employees_count) || unknown}</Value>
                <Key>{t('extra.established_at')}</Key>
                <Value>{formatDate(extraData?.established_at) || unknown}</Value>
                <Key>{t('extra.has_all_documents')}</Key>
                <Value>{yesNo(extraData?.has_all_documents)}</Value>
                <Key>{t('extra.directors_companies_have_problems')}</Key>
                <Value>{thereIsNo(extraData?.directors_companies_have_problems)}</Value>
                <Key>{t('extra.connected_companies_have_problems')}</Key>
                <Value>{thereIsNo(extraData?.connected_companies_have_problems)}</Value>
              </ExtraData>
            </CommonWrapper>
            <div>
              <ExtraHeader>{t('arbitrage')}</ExtraHeader>
              <ExtraData>
                <Key>{t('extra.has_agro_arbitration_cases')}</Key>
                <Value>{thereIsNo(extraData?.has_agro_arbitration_cases)}</Value>
                <Key>{t('extra.arbitration_claims_sum')}</Key>
                <Value>{formatNumber(extraData?.arbitration_claims_sum) || unknown}</Value>
                <Key>{t('extra.has_enforcement_proceeding')}</Key>
                <Value>{thereIsNo(extraData?.has_enforcement_proceeding)}</Value>
                <Key>{t('extra.enforcement_proceeding_sum')}</Key>
                <Value>{formatNumber(extraData?.enforcement_proceeding_sum) || unknown}</Value>
              </ExtraData>
            </div>
            <div>
              <ExtraHeader>{t('finance')}</ExtraHeader>
              <ExtraData>
                <Key>{t('extra.last_accounting_statements_date')}</Key>
                <Value>{formatDate(extraData?.last_accounting_statements_date) || unknown}</Value>
                <Key>{t('extra.has_blocked_bank_accounts')}</Key>
                <Value>{thereIsNo(extraData?.has_blocked_bank_accounts)}</Value>
                <Key>{t('extra.paid_us_without_delay_amount')}</Key>
                <Value $isNumber>{formatNumber(extraData?.paid_us_without_delay_amount) || unknown}</Value>
                <Key>{t('extra.revenue')}</Key>
                <Value $isNumber>{formatNumber(extraData?.revenue) || unknown}</Value>
                <Key>{t('extra.profit')}</Key>
                <Value $isNumber>{formatNumber(extraData?.profit) || unknown}</Value>
                <Key>{t('extra.current_assets')}</Key>
                <Value $isNumber>{formatNumber(extraData?.current_assets) || unknown}</Value>
                <Key>{t('extra.equity')}</Key>
                <Value $isNumber>{formatNumber(extraData?.equity) || unknown}</Value>
                <Key>{t('extra.fixed_assets')}</Key>
                <Value $isNumber>{formatNumber(extraData?.fixed_assets) || unknown}</Value>
                <Key>{t('extra.current_liabilities')}</Key>
                <Value $isNumber>{formatNumber(extraData?.current_liabilities) || unknown}</Value>
              </ExtraData>
            </div>
          </>
        )}
      </ExtraContent>
    </Container>
  )
}
