import { Progress } from '@agro-club/agroclub-shared'
import { createSelector } from 'reselect'

import { Calls } from 'modules/domain/calls/types'
import { EntityMetadata } from 'modules/domain/types'
import { AppGlobalState } from 'modules/types'

const callsDict = (state: AppGlobalState) => state.calls.items
const ids = (state: AppGlobalState) => state.calls.ids
const calls = (state: AppGlobalState, id: string): Calls | undefined => state.calls.items[id]
const meta = (state: AppGlobalState, id: string): EntityMetadata<Calls> =>
  state.calls.meta[id] || {
    fetchError: null,
    fetchProgress: Progress.IDLE,
    id,
  }
const listFetchProgress = (state: AppGlobalState) => state.calls.listFetchProgress
const listFetchError = (state: AppGlobalState) => state.calls.listFetchError
const callsList = createSelector(callsDict, ids, listFetchProgress, (dict, ids, progress) => {
  if (progress === Progress.WORK) {
    return []
  }
  const result: Calls[] = []
  for (const id of ids) {
    result.push(dict[id])
  }
  return result
})

const filter = (state: AppGlobalState) => state.calls.filter
const sorting = (state: AppGlobalState) => state.calls.sorting
const page = (state: AppGlobalState) => state.calls.page
const total = (state: AppGlobalState) => state.calls.total
const hasNext = (state: AppGlobalState) => state.calls.total > state.calls.ids.length
const pageSize = (state: AppGlobalState) => state.calls.pageSize
const pages = (state: AppGlobalState) => Math.ceil(state.calls.total / state.calls.pageSize)
const callsBlockProgress = (state: AppGlobalState) => state.calls.callsBlockProgress

const filterForRequestManager = (state: AppGlobalState) => {
  const { filter } = state.calls
  return filter
}

const accounts = (state: AppGlobalState) => state.calls.accounts
const accountsProgress = (state: AppGlobalState) => state.calls.accountsFetchProgress

const phone = (state: AppGlobalState) => state.calls.makeCall

const CallsSelectors = {
  filter,
  filterForRequestManager,
  sorting,
  page,
  total,
  callsDict,
  ids,
  calls,
  meta,
  listFetchProgress,
  listFetchError,
  callsList,
  hasNext,
  pageSize,
  pages,
  callsBlockProgress,
  accounts,
  accountsProgress,
  phone,
}

export default CallsSelectors
