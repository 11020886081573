import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Radio, RadioItem, SimpleSelect } from '@agro-club/agroclub-shared'
import { FormikContextType } from 'formik'
import styled from 'styled-components'

import useTonsAndBags from 'hooks/useTonsAndBags'
import { UnloadingType, WarehouseDTO } from 'modules/domain/types'
import { LegalTypeSelect } from 'views/components/LegalTypeSelect/LegalTypeSelect'
import { ScheduleControls } from 'views/components/WarehouseModal/ScheduleControls'
import * as S from 'views/components/WarehouseModal/styled'
import { VehicleTypeField } from 'views/components/WarehouseModal/VehicleTypeField'
import { YesNoUnknownRadio } from 'views/components/WarehouseModal/YesNoUnknownRadio'
import { NumberInput } from 'views/ui/NumberInput/NumberInput'

type Props = {
  formik: FormikContextType<WarehouseDTO>
}

const UnloadingTypeWrapper = styled.div`
  width: 200px;
`

export const CustomerFields: React.FC<Props> = ({ formik }) => {
  const { t } = useTranslation(['address', 'legalType'])
  const [bags, tonsToBagsCallback, bagsToTonsCallback] = useTonsAndBags(formik.values.volume_per_day || '')
  const { id } = useParams<{ id: string }>()

  return (
    <>
      <VehicleTypeField formik={formik} />

      <S.Label>{t('fields.is_dump_truck')}</S.Label>
      <div>
        <Radio
          onChange={val => {
            formik.setFieldValue('is_dump_truck', !!val)
          }}
          value={formik.values.is_dump_truck ? 1 : 0}
        >
          <RadioItem value={1} label={t('common:yes')} />
          <RadioItem value={0} label={t('common:no')} />
        </Radio>
      </div>

      <S.Label>{t('fields.unloading_type')}</S.Label>
      <UnloadingTypeWrapper>
        <SimpleSelect
          size="small"
          onChange={val => {
            formik.setFieldValue('unloading_type', val)
          }}
          options={Object.keys(UnloadingType).map(val => ({ id: val, title: t(`vehicles:unloadingType.${val}`) }))}
          value={formik.values.unloading_type}
        />
      </UnloadingTypeWrapper>

      <S.Label>{t('fields.volume_per_day_bag.customer')}</S.Label>
      <S.InputWrapper>
        <NumberInput
          inputSize="small"
          value={bags}
          onChange={bagsToTonsCallback(v => formik.setFieldValue('volume_per_day', v))}
        />
      </S.InputWrapper>
      <S.Label>{t('fields.volume_per_day.customer')}</S.Label>
      <div>
        <S.InputWrapper>
          <NumberInput
            inputSize="small"
            value={formik.values.volume_per_day ?? ''}
            onChange={tonsToBagsCallback(v => formik.setFieldValue('volume_per_day', v))}
          />
        </S.InputWrapper>
      </div>

      <ScheduleControls label={t('fields.schedule.customer')} formik={formik} />

      <S.Label>
        {t('warehouse:fields.delivery_method')} {t('warehouse:fields.car_delivery')}
      </S.Label>
      <YesNoUnknownRadio
        onChange={value => {
          formik.setFieldValue('car_delivery', value)
        }}
        value={formik.values.car_delivery}
      />
      <S.Label>
        {t('warehouse:fields.delivery_method')} {t('warehouse:fields.railway_delivery')}
      </S.Label>
      <YesNoUnknownRadio
        onChange={value => {
          formik.setFieldValue('railway_delivery', value)
        }}
        value={formik.values.railway_delivery}
      />

      <S.Label>{t('legalType:label')}</S.Label>
      <div>
        <LegalTypeSelect
          id={id}
          showLabel={false}
          onChange={val => {
            formik.setFieldValue('legal_type', val)
          }}
          value={formik.values?.legal_type}
        />
      </div>
    </>
  )
}
