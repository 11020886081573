import { Progress } from '@agro-club/agroclub-shared'

import { CarStateUnity } from 'modules/domain/car/selectors'
import { DealType } from 'modules/domain/deal/types'
import {
  AddError,
  CarDeals,
  Documents,
  EntityMetadata,
  FetchError,
  NonFieldErrors,
  RemoveError,
  UnloadingType,
  UpdateError,
  VehicleType,
} from 'modules/domain/types'
import { User } from 'modules/domain/user/types'
import { Dict } from 'types/generics.d'

export type UnityState = {
  carsIds: string[]
  listFetchProgress: Progress
  listFetchErrorDetail?: string
  listFetchError: FetchError | null
}

export type CarState = {
  [CarStateUnity.User]: Dict<UnityState>
  [CarStateUnity.RelevantCarsByDeal]: Dict<UnityState>
  [CarStateUnity.InvolvedCarsByDeal]: Dict<UnityState>
  carDeals: Dict<CarDeals>

  items: Dict<Car>
  meta: Dict<EntityMetadata<Car>>
  ids: string[]

  itemFetchProgress: Progress
  itemFetchError: FetchError | null
  addProgress: Progress
  addError: AddError | null
  addErrorDetail?: string
  addErrorFields?: Record<keyof Car & NonFieldErrors, string[]>
  updateProgress: Progress
  updateError: UpdateError | null
  removeProgress: Progress
  removeError: RemoveError | null
  removeErrorDetail?: string

  documents: Dict<Documents> // dict by car id
  deleteDocumentProgress: Dict<Progress> // dict by file pk

  changeStatusProgress: Dict<Progress> // dict by car id
}

export type CarOwner = {
  id: string
  phone: string
  full_name: string
}

export enum CarStatuses {
  active = 'active',
  archived = 'archived',
}

export type Car = {
  id: string
  brand_model: string
  owner: CarOwner | User
  licence_number: string
  default_trailer?: {
    id: string
    licence_number: string
    brand_model: string | null
  }
  car_type?: VehicleType
  unloading_type?: UnloadingType
  side_height?: number
  is_in_charter: boolean | null
  is_dump_truck: boolean | null
  drivers_count: number
  status: CarStatuses
}

export type CarDTO = Omit<Car, 'id' | 'drivers_count' | 'owner' | 'status' | 'default_trailer'> & {
  id?: string
  owner: string
  default_trailer: string | null
}

export type CarLogisticsDeal = {
  id: string
  created: string
  closed_at?: string
  sale_company: string // name of company
  from_address?: string
  purchase_company: string // name of company
  to_address?: string
  quantity?: string
  quantity_in_bags?: string
  margin: number
  product: string // product name
  deal_type: DealType
}
