import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Button, Input, Progress, useAction } from '@agro-club/agroclub-shared'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { phoneValidationRegExp } from 'helpers/validation'
import { useNotificationProgress } from 'hooks/useNotificationProgress'
import usePrevious from 'hooks/usePrevious'
import UserContactActions from 'modules/domain/userContact/duck'
import UserContactSelectors from 'modules/domain/userContact/selectors'
import { UserContact, UserContactDTO } from 'modules/domain/userContact/types'
import { Label, StyledPhoneInput } from 'views/components/UserRightPanel/UserContact/styled'
import { ModalCommon } from 'views/ui/ModalCommon/ModalCommon'

type Props = {
  item?: UserContact
  isEdit?: boolean
  open: boolean
  onClose: () => void
}

export const UserContactForm: React.FC<Props> = ({ item = {}, isEdit, open, onClose }) => {
  const { id: userId } = useParams<{ id: string }>()
  const editProgress = useSelector(state => UserContactSelectors.editProgress(state, userId, item.id as string))
  const addProgress = useSelector(state => UserContactSelectors.addProgress(state, userId))
  const progress = isEdit ? editProgress : addProgress
  const edit = useAction(UserContactActions.editRequested)
  const addContact = useAction(UserContactActions.addRequested)
  const prevProgress = usePrevious(progress)
  const { t } = useTranslation('userContact')

  const validationSchema = useMemo(
    () =>
      Yup.object({
        full_name: Yup.string().required(),
        phone: Yup.string().matches(phoneValidationRegExp).required(),
        position: Yup.string().required(),
      }),
    [],
  )

  const formik = useFormik<UserContactDTO>({
    validationSchema,
    initialValues: {
      full_name: item.full_name || '',
      phone: item.phone || '',
      position: item.position || '',
    },
    enableReinitialize: true,
    validateOnBlur: false,
    onSubmit: () => {
      if (!formik.isValid) {
        return
      }
      if (isEdit) {
        edit(userId, item.id, formik.values)
      } else {
        addContact(userId, formik.values)
      }
    },
  })

  const onCancel = useCallback(() => {
    formik.resetForm()
    onClose()
  }, [formik, onClose])

  useEffect(() => {
    if (prevProgress === Progress.WORK && progress === Progress.SUCCESS) {
      formik.resetForm()
      onClose()
    }
  }, [progress, prevProgress, formik, onClose])

  useNotificationProgress(isEdit ? editProgress : addProgress)

  return (
    <ModalCommon
      width={448}
      isOpen={Boolean(open)}
      close={onCancel}
      title={t(isEdit ? 'common:editing' : 'common:newContact')}
      content={
        <div>
          <Label>{t('full_name')}</Label>
          <Input
            maxLength={450}
            {...formik.getFieldProps('full_name')}
            invalid={formik.touched.full_name && !!formik.errors.full_name}
          />
          <Label>{t('position')}</Label>
          <Input
            maxLength={128}
            {...formik.getFieldProps('position')}
            invalid={formik.touched.position && !!formik.errors.position}
          />
          <Label>{t('common:phoneNumber')}</Label>
          <StyledPhoneInput
            maxLength={100}
            {...formik.getFieldProps('phone')}
            invalid={formik.touched.phone && !!formik.errors.phone}
          />
        </div>
      }
      footer={
        <>
          <Button
            onClick={() => {
              formik.dirty && formik.submitForm().catch()
            }}
            disabled={!formik.dirty}
            intent="primary"
            progress={editProgress}
            filled
          >
            {t('common:save')}
          </Button>
          <Button onClick={onCancel} intent="minor-action" filled>
            {t('common:cancel')}
          </Button>
        </>
      }
    />
  )
}
