import React from 'react'

import { Typography } from '@agro-club/agroclub-shared'
import styled, { StyledProps } from 'styled-components'

type Props = {
  color: 'red' | 'gray'
}

const BadgeWrapper = styled.div<StyledProps<{ $color: Props['color'] }>>`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 12px;
  border-radius: 8px;
  background-color: ${({ theme, $color }) => {
    switch ($color) {
      case 'gray':
        return theme.color.secondary50
      case 'red':
        return theme.color.accentDestructive50
      default:
        return theme.color.secondary50
    }
  }};
  ${Typography.caption1Default};
`

export const Badge: React.FC<Props> = props => {
  const { color } = props
  return <BadgeWrapper $color={color}>{props.children}</BadgeWrapper>
}
