import React, { useMemo } from 'react'

import { Segment, SimpleSelect, Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import { BidParameterDTO, ParameterCondition } from 'modules/domain/bid/types'
import { ProductParameter } from 'modules/domain/collection/types'
import { CrossButton } from 'views/ui/CrossButton/CrossButton'
import { NumberInput } from 'views/ui/NumberInput/NumberInput'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 316px 74px 72px 24px;
  grid-gap: 8px;
  align-items: center;
  ${Typography.body2Default}
`

type Props = {
  parameters: ProductParameter[]
  parameter: BidParameterDTO
  onRemove?: (index?: number) => void
  index?: number
  onChange: (index: number | undefined, values: BidParameterDTO) => void
}

export const CropsParam: React.FC<Props> = props => {
  const { parameter, parameters, onRemove, index, onChange } = props

  const options = useMemo(() => parameters.map(p => ({ id: p.id, title: p.name })), [parameters])

  return (
    <Wrapper>
      <SimpleSelect
        options={options}
        value={parameter.parameter}
        onChange={(v: unknown) => onChange && onChange(index, { ...parameter, parameter: v as string })}
      />
      <Segment
        items={Object.entries(ParameterCondition).map(([key, value]) => ({
          id: key,
          title: value,
        }))}
        value={parameter.condition}
        onChange={(v: string) =>
          onChange && onChange(index, { ...parameter, condition: v as BidParameterDTO['condition'] })
        }
      />
      <NumberInput
        onChange={v => {
          onChange && onChange(index, { ...parameter, parameter_value: v as BidParameterDTO['parameter_value'] })
        }}
        value={parameter.parameter_value}
      />
      <CrossButton onClick={() => onRemove && onRemove(index)} />
    </Wrapper>
  )
}
