import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { useAction } from '@agro-club/agroclub-shared'

import env from 'env'
import useFormatNumber from 'hooks/useFormatNumber'
import useUserName from 'hooks/useUserName'
import UserActions from 'modules/domain/user/duck'
import UserSelectors from 'modules/domain/user/selectors'
import { ProfileType } from 'modules/domain/user/types'
import { FormText, TextList, UserDataShort } from 'views/components/UserRightPanel/styled'
import VerifyStatusList from 'views/components/UserRightPanel/VerifyStatusList'
import { Phone } from 'views/ui/Phone/Phone'

function joinItems(list, prefix) {
  return list
    .filter(Boolean)
    .reduce((list: React.ReactNode[], item) => [...list, item, <>,&nbsp;</>], [])
    .slice(0, -1)
    .map((item, index) => ({ key: `${prefix}-${index}`, item }))
    .map(({ key, item }) => <span key={key}>{item}</span>)
}

export function UserSummary() {
  const { t } = useTranslation(['user', 'common'])
  const { id } = useParams<{ id: string }>()
  const user = useSelector(state => UserSelectors.user(state, id))
  const name = useUserName(user)
  const summary = joinItems(
    [user?.profile?.profile_type && t(`profile_type.${user?.profile?.profile_type}`), name],
    'summary',
  )

  return <div>{summary}</div>
}

export function UserData() {
  const { t } = useTranslation(['user'])
  const { id } = useParams<{ id: string }>()
  const verifyCoordinatorAction = useAction(UserActions.userVerifyCoordinatorRequested, id)
  const verifySecurityAction = useAction(UserActions.userVerifySecurityRequested, id)
  const blockUserAction = useAction(UserActions.userBlockRequested, id)

  const user = useSelector(state => UserSelectors.user(state, id))
  const isVerifyCoordinator = user?.profile?.is_verified_by_coordinator
  const isVerifySecurity = user?.profile?.is_verified_by_security
  const isVerified = user?.profile?.is_verified
  const isActive = user?.is_active
  const isCarrier = user?.profile?.profile_type === ProfileType.carrier
  const isSeller = user?.profile?.profile_type === ProfileType.seller
  const isCustomer = user?.profile?.profile_type === ProfileType.customer
  const showVerifyList = isSeller || isCustomer
  const formatNumber = useFormatNumber()
  const currency = env.REACT_APP_CURRENCY_SYMBOL

  const name = useUserName(user)

  const commonInfo = joinItems(
    [
      user?.profile?.profile_type && t(`profile_type.${user?.profile?.profile_type}`),
      name,
      user?.profile?.company?.name,
      user?.phone && <Phone phone={user?.phone} />,
      user?.profile?.team?.name,
    ],
    'common',
  )

  const showRecommendations =
    user?.profile?.recommended_margin_percent || user?.profile?.forecast_credit_limit || user?.profile?.credit_limit

  return (
    <UserDataShort>
      <FormText label={t('common:status')}>
        <VerifyStatusList
          isVerified={isVerified}
          isVerifySecurity={isVerifySecurity}
          isVerifyCoordinator={isVerifyCoordinator}
          isActive={isActive}
          verifyCoordinatorAction={verifyCoordinatorAction}
          verifySecurityAction={verifySecurityAction}
          blockUserAction={blockUserAction}
          showVerifyList={showVerifyList}
        />
      </FormText>

      <FormText label={t('company:commonInfo')}>
        <TextList>{commonInfo}</TextList>
      </FormText>

      {!isCarrier && showRecommendations && (
        <FormText label={t('recommendations')}>
          {user?.profile?.recommended_margin_percent && (
            <div>{t('form.fields.margin', { percent: user?.profile?.recommended_margin_percent })}</div>
          )}
          {user?.profile?.forecast_credit_limit && (
            <div>
              {t('form.fields.forecastCreditLimitShort', {
                credit: formatNumber(user?.profile?.forecast_credit_limit),
                currency,
              })}
            </div>
          )}
          {user?.profile?.credit_limit && (
            <div>
              {t('form.fields.creditLimitShort', {
                credit: formatNumber(user?.profile?.credit_limit),
                currency,
              })}
            </div>
          )}
        </FormText>
      )}
    </UserDataShort>
  )
}
