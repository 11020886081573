import React, { useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { useAction } from '@agro-club/agroclub-shared'

import CallsActions from 'modules/domain/calls/duck'
import PageWrapper from 'views/components/PageWrapper/PageWrapper'
import CallsList from 'views/pages/Calls/CallsList'
import Routes from 'views/pages/Calls/routes'

function CallsPage() {
  const resetFilter = useAction(CallsActions.filterResetWithoutRequest)

  useEffect(
    () => () => {
      resetFilter()
    },
    [resetFilter],
  )

  return (
    <PageWrapper>
      <Switch>
        <Route path={Routes.List} exact>
          <CallsList />
        </Route>
        <Redirect to={Routes.List} />
      </Switch>
    </PageWrapper>
  )
}

export default CallsPage
