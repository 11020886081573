import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import useFormatDate from 'hooks/useFormatDate'
import Tooltip from 'views/ui/Tooltip/Tooltip'

type Props = {
  text?: string
  author?: string
  created?: string
}

const StyledComment = styled.div<{ enabled: boolean }>`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  max-width: 200px;
  letter-spacing: 0;
  color: ${({ enabled, theme }) => (enabled ? theme.color.primary600 : theme.color.secondary200)};
`

const CommentText = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
`
const CommentAuthor = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
  color: ${({ theme }) => theme.color.onSurfaceMidEmphasys};
`

const CommentWrapper = styled.div`
  display: inline-block;
`

export const Comment: React.FC<Props> = props => {
  const { t } = useTranslation('common')
  const { text, author, created } = props
  const enabled = !!text && !!author && !!created
  const formatDate = useFormatDate()
  const content = useMemo(
    () => (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <div
        onClick={e => {
          e.stopPropagation()
          e.preventDefault()
        }}
      >
        <CommentText>{text}</CommentText>
        <CommentAuthor>
          {formatDate(created)} /{author}
        </CommentAuthor>
      </div>
    ),
    [text, author, created, formatDate],
  )
  return (
    <CommentWrapper>
      <Tooltip destroyTooltipOnHide placement="topLeft" trigger={['hover']} overlay={content}>
        <StyledComment enabled={enabled}>{t('comment')}</StyledComment>
      </Tooltip>
    </CommentWrapper>
  )
}
