import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { AddButton, useAction, useHelmet } from '@agro-club/agroclub-shared'

import UserActions from 'modules/domain/user/duck'
import { useUserFilterAndSortQuery } from 'modules/domain/user/hooks'
import UserSelectors from 'modules/domain/user/selectors'
import TableSearch from 'views/components/TableSearch/TableSearch'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import Routes from 'views/pages/User/routes'
import UserTable from 'views/pages/User/UserTable/UserTable'
import * as Header from 'views/ui/Header/Header'

const UserList: React.FC = () => {
  const { t } = useTranslation(['user'])
  const { filter } = useUserFilterAndSortQuery()
  useHelmet({ title: t('usersMetaTitle') })
  const filterUpdated = useAction(UserActions.filterUpdated)
  const filterState = useSelector(UserSelectors.filter)

  const onChangeSearch = useCallback(
    (search: string) => {
      filterUpdated({ ...filterState, search })
    },
    [filterUpdated, filterState],
  )

  return (
    <>
      <Layout.Header>
        <Header.Root>
          <Header.Title title={t('list.heading')}>
            <AddButton to={Routes.Add} />
          </Header.Title>
          <TableSearch
            value={filter.search || filterState.search || ''}
            onChangeSearch={onChangeSearch}
            placeholder={t('list.searchPlaceholder')}
          />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <UserTable />
      </Layout.Content>
    </>
  )
}

export default UserList
