import { Typography, defaultTheme } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

const defaultStyle = `
  max-width: 150px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 8px;
  cursor: default;
  color: ${defaultTheme.color.textSecondary};
  ${Typography.caption2Default}

  svg,
   svg > path {
    width: 32px;
    height: 100%;
    margin-right: 6px;
  }

`

export const VerifiedUser = styled.div`
  ${defaultStyle}
  svg {
    path {
      fill: ${defaultTheme.color.primary600};
    }
  }
`

export const VerifyCoordinator = styled.div`
  ${defaultStyle}
  svg {
    width: 30px;

    path {
      fill: ${defaultTheme.color.accentNotify600};
    }
  }
`

export const VerifySecurity = styled.div`
  ${defaultStyle}
  svg {
    path {
      fill: ${defaultTheme.color.accentNotify600};
    }
  }
`

export const ActiveUser = styled.div`
  ${defaultStyle}
  svg {
    path {
      fill: ${defaultTheme.color.accentDestruct800};
    }
  }
`
export const NotVerified = styled.div`
  ${defaultStyle}
  svg {
    fill: ${defaultTheme.color.secondary300};
  }
`
