import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
  Progress,
  useAction,
  useDidMount,
  useDidMountWithProgress,
  usePageQuery,
  useQuery,
} from '@agro-club/agroclub-shared'
import { format } from 'date-fns'
import { isEmpty } from 'ramda'

import { YEAR_MONTH } from 'modules/constants'
import AuthSelectors from 'modules/domain/auth/selectors'
import { CloseReason } from 'modules/domain/types'
import { ResourceHook } from 'modules/types'

import DealActions, { DealListRequestedParams } from './duck'
import DealSelectors from './selectors'
import { Deal, DealCondition, DealListRequestFilter, DealShipment, SpecificationsForPurchase } from './types'

export const useDealFilterAndSortQuery = () => {
  const query = useQuery()
  const search = query.get('search')
  const month = query.get('month')
  const teamStr = query.get('team')
  const teamArr = teamStr ? teamStr.split(',') : undefined
  const dealCondition = query.get('dealCondition') || DealCondition.open
  const productStr = query.get('product')
  const productArr = productStr ? productStr.split(',') : undefined

  const filter: DealListRequestFilter = {
    ...(search && { search }),
    ...(month && { month }),
    ...(teamArr && teamArr.length && { team: teamArr }),
    ...(productArr && productArr.length && { product: productArr }),
    dealCondition: dealCondition as DealCondition,
  }

  return { filter }
}

export const useDealList = (): [Progress, Deal[]] => {
  const fetchProgress = useSelector(DealSelectors.listFetchProgress)
  const list = useSelector(DealSelectors.list)
  const page = usePageQuery()
  const currentTeam = useSelector(AuthSelectors.team)
  let { filter } = useDealFilterAndSortQuery()

  if (!filter?.team?.length && currentTeam?.id) {
    filter = { ...filter, team: [String(currentTeam.id)] }
  }

  if (!filter?.month) {
    filter = { ...filter, month: format(Date.now(), YEAR_MONTH) }
  }

  const params: DealListRequestedParams = {
    ...(!isEmpty(filter) && { filter }),
    ...(page && { page }),
  }

  const fetchAction = useAction(DealActions.listRequested, params)
  const progress = useDidMountWithProgress(fetchAction, fetchProgress)

  return [progress, list]
}

export const useDeal: ResourceHook<Deal, [string]> = (id: string) => {
  const meta = useSelector(state => DealSelectors.meta(state, id))
  const item = useSelector(state => DealSelectors.item(state, id))
  const fetchAction = useAction(DealActions.itemRequested, id)

  const progress = useDidMountWithProgress(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  }, meta.fetchProgress)

  return [progress, item]
}

export const useDealById: ResourceHook<Deal, [string | undefined]> = (id = '') => {
  const meta = useSelector(state => DealSelectors.meta(state, id))
  const item = useSelector(state => DealSelectors.item(state, id))
  const fetchAction = useAction(DealActions.itemRequested, id)

  useEffect(() => {
    if (id && (!item || item.id !== id)) {
      fetchAction()
    }
  }, [item, id, fetchAction])

  return [meta.fetchProgress, item]
}

export const useDealShipmentList: ResourceHook<DealShipment[], [string]> = dealId => {
  const fetchProgress = useSelector(state => DealSelectors.dealShipmentFetchProgress(state, dealId))
  const list = useSelector(state => DealSelectors.dealShipmentList(state, dealId))
  const fetchAction = useAction(DealActions.shipmentListRequested, dealId)
  const progress = useDidMountWithProgress(fetchAction, fetchProgress)

  return [progress, list]
}

export const useCloseReasons = (): [Progress, CloseReason[]] => {
  const fetchAction = useAction(DealActions.closeReasonsRequested)
  const items = useSelector(DealSelectors.closeReasons)
  const progress = useSelector(DealSelectors.closeReasonsProgress)

  useDidMount(fetchAction)

  return [progress, items]
}

export const useReasonBySlug = (slug?: string | null): string => {
  const items = useSelector(DealSelectors.closeReasons)
  const reason = useMemo(() => (slug && items.find(item => item.slug === slug)?.label) || '', [items, slug])

  return reason
}

export const usePurchaseSpecification: ResourceHook<SpecificationsForPurchase[]> = (
  id: string,
  ownerId: string,
  productSlug: string,
) => {
  const fetchAction = useAction(DealActions.specificationsForPurchaseRequested, id, ownerId, productSlug)
  const list = useSelector(state => DealSelectors.specificationsForPurchase(state, id))
  const meta = useSelector(state => DealSelectors.fetchPurchaseSpecificationProgress(state, id))
  const progress = useDidMountWithProgress(fetchAction, meta)
  return [progress, list]
}
