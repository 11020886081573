import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import { FormContentBody, FormContentWrapper } from 'views/components/FormComponents/FormComponents'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'

const Id = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.onPrimaryDark};
  opacity: 0.48;
  margin-top: 4px;
`

const Item404: React.FC<{
  id: string
  onBack?(): void
  title: string
}> = ({ id, onBack, title }) => {
  const { t } = useTranslation('layouts')
  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={onBack}>
          <Header.Title title={title} />
          <Id>{`ID ${id}`}</Id>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <FormContentWrapper>
          <FormContentBody>
            <div>{t('item404.description')}</div>
            <div>
              <Button onClick={onBack} intent="primary">
                {t('item404.goBackButtonText')}
              </Button>
            </div>
          </FormContentBody>
        </FormContentWrapper>
      </Layout.Content>
    </>
  )
}

export default Item404
