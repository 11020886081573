import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useAction, useDidMount, usePageQuery, useQuery } from '@agro-club/agroclub-shared'
import { isEmpty } from 'ramda'

import AuthSelectors from 'modules/domain/auth/selectors'
import CarrierActions, { ListRequestedParams } from 'modules/domain/carrier/duck'
import CarrierSelectors from 'modules/domain/carrier/selectors'
import { Carrier, CarrierListRequestFilter, CarrierListRequestSorting } from 'modules/domain/carrier/types'
import { ResourceHook } from 'modules/types'

export const useCarrierFilterAndSortQuery = () => {
  const query = useQuery()
  const search = query.get('search')
  const date_joined_before = query.get('date_joined_before')
  const date_joined_after = query.get('date_joined_after')
  const sort_field: keyof Carrier = query.get('sort_field') as keyof Carrier
  const sort_reversed = query.get('sort_reversed')
  const is_active: string | null = query.get('is_active')

  const teamStr = query.get('team')
  const teamArr = teamStr ? teamStr.split(',') : undefined

  const regionStr = query.get('region')
  const regionArr = regionStr ? regionStr.split(',') : undefined

  const filter: CarrierListRequestFilter = {
    ...(search && { search }),
    ...(date_joined_before && { date_joined_before }),
    ...(date_joined_after && { date_joined_after }),
    ...(teamArr && teamArr.length && { team: teamArr }),

    ...(is_active && { is_active: is_active === 'true' }),
    ...(regionArr && regionArr.length && { region: regionArr }),
  }

  const sorting: CarrierListRequestSorting = {
    ...(sort_field && { sort_field }),
    ...(sort_reversed && { sort_reversed: sort_reversed === 'true' }),
  }

  return { filter, sorting }
}

export const useCarrierList: ResourceHook<Carrier[], void[]> = () => {
  const progress = useSelector(CarrierSelectors.listFetchProgress)
  const list = useSelector(CarrierSelectors.list)
  const page = usePageQuery()
  const currentTeam = useSelector(AuthSelectors.team)
  let { filter } = useCarrierFilterAndSortQuery()
  const { sorting } = useCarrierFilterAndSortQuery()

  if (!filter?.team?.length && currentTeam?.id) {
    filter = { ...filter, team: [String(currentTeam.id)] }
  }

  const params: ListRequestedParams = {
    ...(!isEmpty(filter) && { filter }),
    ...(!isEmpty(sorting) && { sorting }),
    ...(page && { page }),
  }

  const fetchAction = useAction(CarrierActions.listRequested, params)

  useDidMount(fetchAction)

  return [progress, list]
}

export const useCarrier: ResourceHook<Carrier, [string]> = (id: string) => {
  const meta = useSelector(state => CarrierSelectors.meta(state, id))
  const item = useSelector(state => CarrierSelectors.item(state, id))
  const fetchAction = useAction(CarrierActions.itemRequested, id)

  useDidMount(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  })

  return [meta.fetchProgress, item]
}

export const useCarrierById: ResourceHook<Carrier, [string]> = (id: string) => {
  const meta = useSelector(state => CarrierSelectors.meta(state, id))
  const item = useSelector(state => CarrierSelectors.item(state, id))
  const fetchAction = useAction(CarrierActions.itemRequested, id)

  useEffect(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  }, [item, id, fetchAction])

  return [meta.fetchProgress, item]
}
