import React, { VFC } from 'react'
import { generatePath } from 'react-router-dom'
import { CellProps } from 'react-table'

import { Typography } from '@agro-club/agroclub-shared'
import styled, { StyledProps } from 'styled-components'

import useLocationFrom from 'hooks/useLocationFrom'
import useUserName from 'hooks/useUserName'
import { useLogisticStatuses } from 'modules/domain/collection/hooks'
import { LogisticsBid, LogisticsDeal } from 'modules/domain/logisticsDeal/types'
import { Address as AddressType } from 'modules/domain/types'
import DealRoutes from 'views/pages/Deal/routes'
import { BlueLink } from 'views/styled/common'
import { Phone } from 'views/ui/Phone/Phone'

const ProductWrapper = styled.div`
  display: grid;
  grid-gap: 2px;
`

const Product = styled.div`
  ${Typography.body2Bold};
`

const SecondaryText = styled.div`
  ${Typography.caption1Default};
  color: ${({ theme }) => theme.color.textSecondary};
`

const Address = styled.div`
  margin-bottom: 4px;
  ${Typography.body2Default};
`

const UserWrapper = styled.div`
  display: grid;
  grid-gap: 2px;
`

const TeamWrapper = styled.div`
  ${Typography.body2Default};
`

const LinkWrapper = styled.div`
  z-index: 0;
`

const BidCommon: React.FC<{ bid: LogisticsBid }> = ({ bid }) => {
  const address = bid.address as AddressType
  const name = useUserName(bid?.owner)
  return (
    <>
      <Address>{address?.address || ''}</Address>
      <UserWrapper>
        <SecondaryText>{bid?.owner?.profile?.company?.name || ''}</SecondaryText>
        <SecondaryText>{name}</SecondaryText>
        <LinkWrapper>
          <Phone phone={bid?.owner?.phone} />
        </LinkWrapper>
      </UserWrapper>
    </>
  )
}

const StatusLabel = styled.div<StyledProps<{ status?: string }>>`
  ${Typography.caption1Default};
  color: ${({ theme, status }) => {
    switch (status) {
      case 'new':
        return theme.color.accentNotify600
      case 'car_search':
        return theme.color.accentDestruct500
      case 'shipping':
        return theme.color.textPrimary
      case 'shipment_complete':
        return theme.color.accentApprove400
      default:
        return theme.color.textPrimary
    }
  }};
  text-transform: capitalize;
`

export const Status: VFC<CellProps<LogisticsDeal>> = ({ value }) => {
  const [, statuses] = useLogisticStatuses()
  const label = statuses.find(status => status.slug === value)?.label

  return <StatusLabel status={value}>{label || ''}</StatusLabel>
}

export const Culture: VFC<CellProps<LogisticsDeal>> = ({ row }) => {
  const {
    original: { product, id },
  } = row
  const from = useLocationFrom()

  return (
    <ProductWrapper>
      <Product>{product?.title || ''}</Product>
      <LinkWrapper>
        <BlueLink
          to={{
            pathname: generatePath(DealRoutes.Edit, { id }),
            state: { from },
          }}
        >
          ID {id}
        </BlueLink>
      </LinkWrapper>
    </ProductWrapper>
  )
}

export const Seller: React.FC<CellProps<LogisticsDeal>> = ({ row }) => {
  const {
    original: { sale_bid },
  } = row

  return <BidCommon bid={sale_bid} />
}

export const Customer: React.FC<CellProps<LogisticsDeal>> = ({ row }) => {
  const {
    original: { purchase_bid },
  } = row
  return <BidCommon bid={purchase_bid} />
}

export const Team: React.FC<CellProps<LogisticsDeal>> = ({ row }) => {
  const {
    original: { sale_bid },
  } = row

  const team = sale_bid?.owner?.profile?.team?.name

  return <TeamWrapper>{team || '-'}</TeamWrapper>
}
