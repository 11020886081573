import React, { useEffect } from 'react'

type Handler = (event: MouseEvent | TouchEvent) => void | (() => void | undefined)
type Ref<T> = T extends HTMLElement ? React.MutableRefObject<T> : React.MutableRefObject<null>

export function useOnClickOutside<T>(ref: Ref<T>, handler: Handler, openerRef?: Ref<HTMLElement | null>): void {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (openerRef && openerRef.current) {
        const el: HTMLElement = event.target as HTMLElement
        if (el === openerRef.current || openerRef.current.contains(el)) {
          return null
        }
      }
      return !ref.current || ref.current.contains(event.target as Node) ? null : handler(event)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler, openerRef])
}

export default useOnClickOutside
