import { Progress } from '@agro-club/agroclub-shared'

import { Bid, BidDTO } from 'modules/domain/bid/types'
import { AddError, EntityMetadata, FetchError, RemoveError, UpdateError } from 'modules/domain/types'
import { Dict } from 'types/generics.d'

export type PotentialBidState = {
  items: Dict<PotentialBid>
  meta: Dict<EntityMetadata<PotentialBid>>
  ids: string[]
  listFetchProgress: Progress
  listFetchError: FetchError | null
  listFetchErrorDetail?: string
  itemFetchProgress: Progress
  itemFetchError: FetchError | null
  addProgress: Progress
  addError: AddError | null
  addErrorDetail?: string
  addErrorFields?: Record<keyof PotentialBid, string>
  updateProgress: Progress
  updateError: UpdateError | null
  removeProgress: Progress
  removeError: RemoveError | null
  removeErrorDetail?: string

  filter: PotentialBidListRequestFilter
  sorting: PotentialBidListRequestSorting
  page: number
  total: number
  pageSize: number

  lastAddedId: string | null

  userBidIds: Dict<string[]>
  userBidFetchProgress: Progress
  userBidListFetchError: FetchError | null
}

export enum BidKind {
  potential = 'potential',
  regular = 'regular',
}

export type PotentialBid = {
  status: BidKind.potential
} & Omit<Bid, 'status'>

export type PotentialBidListAddressFilter = {
  address?: string
  longitude?: number
  latitude?: number
  radius?: number
}

export type PotentialBidListRequestFilter = {
  search?: string
  potentialBid_type?: string
  product?: string[]
  status?: string[]
  team?: string[]
  district?: string[]
  region?: string[]
} & PotentialBidListAddressFilter

export type PotentialBidListRequestSorting = {
  sort_field?: keyof PotentialBid
  sort_reversed?: boolean
}

export type PotentialBidDTO = BidDTO
