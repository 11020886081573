import { ItemLinkProps } from '@agro-club/agroclub-shared'

import { CAPABILITY } from 'modules/permissions/permissions'

export enum OPTION {
  DASHBOARD = 'dashboard',
  USERS = 'users',
  COMPANIES = 'companies',
  BIDS = 'bids',
  DEALS = 'deals',
  TASKS = 'tasks',
  CARRIERS = 'carriers',
  LOGISTICS = 'logistics',
  LOGISTICS_LIST = 'logisticsList',
  CARS = 'cars',
  CALLS = 'calls',
  CALL_STATISTICS = 'callStatistics',
  SPECIFICATIONS = 'specifications',
}

export type OptionItem = {
  path?: string
  value: OPTION
  children?: OptionItem[]
  icon?: React.FC
  capability?: CAPABILITY
  component?: React.FC<ItemLinkProps>
}
