import React from 'react'
import { useTranslation } from 'react-i18next'

import { Input } from '@agro-club/agroclub-shared'
import { FormikContextType } from 'formik'

import useLanguage from 'hooks/useLanguage'
import { AvailableLanguages } from 'types/entities'
import { EditableFields } from 'views/components/UserForm/UserForm'
import { FormItem } from 'views/ui/FormItem/FormItem'

export const useFirstNameLastName = (
  formik: FormikContextType<EditableFields>,
  errorFields: Record<string, string[]> | undefined,
) => {
  const { t } = useTranslation(['user'])
  const lang = useLanguage()

  const fields = [
    <FormItem
      width={306}
      key="first_name"
      fieldName="profile.first_name"
      errorFields={errorFields}
      formikInstance={formik}
      render={additionalProps => (
        <Input
          {...formik.getFieldProps('profile.first_name')}
          label={t('form.fields.first_name')}
          {...additionalProps}
        />
      )}
    />,
    <FormItem
      width={306}
      key="last_name"
      fieldName="profile.last_name"
      errorFields={errorFields}
      formikInstance={formik}
      render={additionalProps => (
        <Input {...formik.getFieldProps('profile.last_name')} label={t('form.fields.last_name')} {...additionalProps} />
      )}
    />,
  ]

  return lang === AvailableLanguages.ru ? fields : fields.reverse()
}
