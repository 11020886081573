import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Checkbox, Typography, useAction } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import BidActions from 'modules/domain/bid/duck'
import BidSelectors from 'modules/domain/bid/selectors'

const cbClassName = 'potential-bid-filter-cb'
const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  span {
    ${Typography.body2Default};
  }

  & .${cbClassName} {
    height: 32px;
  }
`

export function PotentialBidFilter() {
  const { t } = useTranslation('bid')
  const filterUpdated = useAction(BidActions.filterUpdated)
  const { isPotential, ...restFilter } = useSelector(BidSelectors.filter)

  const handleChange = (_, isChecked) => {
    filterUpdated({ ...restFilter, isPotential: isChecked || undefined })
  }

  return (
    <Wrapper>
      <Checkbox onChange={handleChange} label={t('filterPotential')} className={cbClassName} isChecked={isPotential} />
    </Wrapper>
  )
}
