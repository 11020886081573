import React, { useCallback, useMemo } from 'react'

import styled, { StyledProps, css } from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-end;
  margin: 24px 24px 24px 0;
  user-select: none;
`

const buttonCss = (props: StyledProps<{ isActive: boolean }>) => css`
  color: ${props.isActive ? props.theme.color.onPrimaryLight : props.theme.color.primary600};
  background-color: ${props.isActive ? props.theme.color.primary600 : 'transparent'};
  pointer-events: ${props.isActive ? 'none' : 'initial'};
`

const Button = styled.button`
  font-size: 14px;
  font-weight: 500;
  margin: 0 3px;
  min-width: 24px;
  height: 24px;

  cursor: pointer;
  border: 0;
  outline: none;
  border-radius: 4px;
  ${buttonCss}
  &:hover {
    background-color: ${props => props.theme.color.primary50};
  }
`

const Spacer = styled.div`
  min-width: 24px;
  height: 24px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  &:hover {
    background-color: ${props => props.theme.color.primary50};
    cursor: pointer;
  }
  &::before {
    content: '...';
    color: ${props => props.theme.color.secondary200};
  }
`

type PaginationProps = {
  total: number
  current: number
  max?: number
  onClick(num: number): void
}

export const Pagination: React.FC<PaginationProps> = ({ total, current, max = 10, onClick }) => {
  const handleClick = useCallback(
    pageNumber => () => {
      onClick(pageNumber)
    },
    [onClick],
  )

  const mid = Math.ceil(max / 2)
  const showFirst = current > mid + 1
  const showLast = total > current + mid

  const knobs = useMemo(() => {
    const result: number[] = []
    let end = current + mid
    const start = current - mid - Math.floor(Math.max(0, end - total))
    for (let i = start; i <= end; ++i) {
      if (i < 1) {
        end += 1
        // eslint-disable-next-line no-continue
        continue
      }
      if (i > total) {
        break
      }
      result.push(i)
    }
    return result
  }, [total, current, mid])

  const start = showFirst ? (
    <>
      <Button onClick={handleClick(1)} isActive={false}>
        1
      </Button>
      {knobs[0] === 2 ? null : <Spacer onClick={handleClick(current - mid - 1)} />}
    </>
  ) : null

  const isLast = knobs[knobs.length - 1] === total - 1
  const end = showLast ? (
    <>
      {isLast ? null : <Spacer onClick={handleClick(current + mid + 1)} />}
      <Button onClick={handleClick(total)} isActive={false}>
        {total}
      </Button>
    </>
  ) : null

  return (
    <Wrapper>
      {start}
      {knobs.map(pageNumber => (
        <Button onClick={handleClick(pageNumber)} key={pageNumber} isActive={current === pageNumber}>
          {pageNumber}
        </Button>
      ))}
      {end}
    </Wrapper>
  )
}
