import { Progress } from '@agro-club/agroclub-shared'
import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer'
import { isEmpty } from 'ramda'

import { LIST_PAGE_SIZE } from 'modules/constants'
import {
  PotentialBid,
  PotentialBidDTO,
  PotentialBidListRequestFilter,
  PotentialBidListRequestSorting,
  PotentialBidState,
} from 'modules/domain/potentialBid/types'
import { AddError, FetchError, RemoveError, UpdateError } from 'modules/domain/types'
import { arrToDict, getIds } from 'modules/utils/helpers'

export const DEFAULT_BID_TYPE = { potentialBid_type: 'purchase' }

const initialState: PotentialBidState = {
  items: {},
  meta: {},
  ids: [],

  listFetchProgress: Progress.IDLE,
  listFetchError: null,
  itemFetchProgress: Progress.IDLE,
  itemFetchError: null,
  addProgress: Progress.IDLE,
  addError: null,
  updateProgress: Progress.IDLE,
  updateError: null,
  removeProgress: Progress.IDLE,
  removeError: null,
  filter: { ...DEFAULT_BID_TYPE },
  sorting: {},
  page: 1,
  total: 0,
  pageSize: LIST_PAGE_SIZE,

  lastAddedId: null,

  userBidIds: {},
  userBidFetchProgress: Progress.IDLE,
  userBidListFetchError: null,
}

export type ListRequestedParams = {
  filter?: PotentialBidListRequestFilter
  sorting?: PotentialBidListRequestSorting
  page?: number
}

class PotentialBidReducer extends ImmerReducer<PotentialBidState> {
  listRequested(params: ListRequestedParams) {
    this.draftState.listFetchProgress = Progress.WORK
    this.draftState.listFetchErrorDetail = undefined
    this.draftState.listFetchError = null
    this.draftState.filter = (!isEmpty(params.filter) && params.filter) || this.draftState.filter
    this.draftState.sorting = params.sorting || this.draftState.sorting
    this.draftState.page = typeof params.page === 'undefined' ? this.draftState.page : params.page
  }

  listRequestSucceed(list: PotentialBid[], total: number, page: number) {
    this.draftState.listFetchProgress = Progress.SUCCESS
    this.draftState.listFetchErrorDetail = undefined
    this.draftState.items = { ...this.draftState.items, ...arrToDict(list) }
    this.draftState.meta = {
      ...this.draftState.meta,
      ...arrToDict(
        list.map(item => ({
          id: item.id,
          fetchProgress: Progress.SUCCESS,
          fetchError: null,
          removeProgress: Progress.IDLE,
          removeError: null,
          updateProgress: Progress.IDLE,
          updateError: null,
        })),
      ),
    }
    this.draftState.ids = getIds(list)
    this.draftState.total = total
    this.draftState.page = page
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  listRequestFailed(error: FetchError, errorDetail?: string) {
    this.draftState.listFetchProgress = Progress.ERROR
    this.draftState.listFetchError = error
    this.draftState.listFetchErrorDetail = errorDetail
  }

  itemRequested(id: string) {
    this.draftState.itemFetchProgress = Progress.WORK

    const meta = {
      id,
      updateProgress: Progress.IDLE,
      updateError: null,
      removeProgress: Progress.IDLE,
      removeError: null,
    }

    this.draftState.meta[id] = {
      ...meta,
      ...this.draftState.meta[id],
      fetchProgress: Progress.WORK,
      fetchError: null,
    }
  }

  itemRequestSucceed(item: PotentialBid) {
    this.draftState.itemFetchProgress = Progress.SUCCESS
    this.draftState.meta[item.id].fetchProgress = Progress.SUCCESS
    this.draftState.meta[item.id].fetchError = null
    this.draftState.meta[item.id].fetchErrorDetail = undefined
    this.draftState.items[item.id] = item
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  itemRequestFailed(id: string, error: FetchError, errorDetail?: string) {
    this.draftState.itemFetchProgress = Progress.ERROR
    this.draftState.meta[id].fetchProgress = Progress.ERROR
    this.draftState.meta[id].fetchError = error
    this.draftState.meta[id].fetchErrorDetail = errorDetail
  }

  addRequested({ dto: _dto }: { dto: PotentialBidDTO }) {
    this.draftState.addProgress = Progress.WORK
    this.draftState.addError = null
    this.draftState.addErrorDetail = undefined
    this.draftState.addErrorFields = undefined
  }

  addSucceed(id: string) {
    this.draftState.lastAddedId = id
    this.draftState.addProgress = Progress.SUCCESS
    this.draftState.addErrorDetail = undefined
    this.draftState.addErrorFields = undefined
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  addFailed(error: AddError, errorDetail?: string, errorFields?: Record<string, string[]>) {
    this.draftState.addProgress = Progress.ERROR
    this.draftState.addError = error
    this.draftState.addErrorDetail = errorDetail
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.draftState.addErrorFields = errorFields
  }

  updateRequested(id: string, _item: Partial<PotentialBidDTO>) {
    this.draftState.updateProgress = Progress.WORK
    this.draftState.meta[id].updateProgress = Progress.WORK
    this.draftState.meta[id].updateError = null
    this.draftState.meta[id].updateErrorDetail = undefined
    this.draftState.meta[id].updateErrorFields = undefined
  }

  updateSucceed(item: PotentialBid) {
    this.draftState.items[item.id] = item
    this.draftState.updateProgress = Progress.SUCCESS
    this.draftState.meta[item.id].updateProgress = Progress.SUCCESS
    this.draftState.meta[item.id].updateError = null
    this.draftState.meta[item.id].updateErrorDetail = undefined
    this.draftState.meta[item.id].updateErrorFields = undefined
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  updateFailed(id: string, error: UpdateError, errorText?: string, errorFields?: Record<string, string[]>) {
    this.draftState.updateProgress = Progress.ERROR
    this.draftState.meta[id].updateProgress = Progress.ERROR
    this.draftState.meta[id].updateError = error
    this.draftState.meta[id].updateErrorDetail = errorText
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.draftState.meta[id].updateErrorFields = errorFields
  }

  filterUpdated(filter: PotentialBidListRequestFilter) {
    this.draftState.filter = filter
    this.draftState.listFetchProgress = Progress.WORK
    this.draftState.page = 1
  }

  filterResetWithoutRequest() {
    this.draftState.filter = { ...DEFAULT_BID_TYPE }
    this.draftState.listFetchProgress = Progress.IDLE
    this.draftState.page = 1
  }

  sortingUpdated(sorting: PotentialBidListRequestSorting) {
    this.draftState.sorting = sorting
    this.draftState.listFetchProgress = Progress.WORK
  }

  filterHasBeenReset() {
    this.draftState.filter = {}
    this.draftState.listFetchProgress = Progress.WORK
  }

  sortingHasBeenReset() {
    this.draftState.sorting = {}
    this.draftState.listFetchProgress = Progress.WORK
  }

  removeRequested(id: string) {
    this.draftState.removeProgress = Progress.WORK
    this.draftState.meta[id].removeProgress = Progress.WORK
    this.draftState.meta[id].removeError = null
    this.draftState.meta[id].removeErrorDetail = undefined
  }

  removeSucceed(id: string) {
    this.draftState.removeProgress = Progress.SUCCESS
    delete this.draftState.items[id]
    delete this.draftState.meta[id]
    const i = this.draftState.ids.findIndex(item => item === id)
    if (i !== -1) {
      const { ids } = this.draftState
      this.draftState.ids = [...ids.slice(0, i), ...ids.slice(i + 1)]
    }
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  removeFailed(id: string, error: RemoveError, errorDetail?: string) {
    this.draftState.removeProgress = Progress.ERROR
    this.draftState.meta[id].removeProgress = Progress.ERROR
    this.draftState.meta[id].removeError = error
    this.draftState.meta[id].removeErrorDetail = errorDetail
  }

  userBidListRequested(_userId: string) {
    this.draftState.userBidFetchProgress = Progress.WORK
    this.draftState.userBidListFetchError = null
  }

  userBidListRequestSucceed(userId: string, list: PotentialBid[]) {
    this.draftState.userBidFetchProgress = Progress.SUCCESS
    this.draftState.items = { ...this.draftState.items, ...arrToDict(list) }
    this.draftState.meta = {
      ...this.draftState.meta,
      ...arrToDict(
        list.map(item => ({
          id: item.id,
          fetchProgress: Progress.SUCCESS,
          fetchError: null,
          removeProgress: Progress.IDLE,
          removeError: null,
          updateProgress: Progress.IDLE,
          updateError: null,
        })),
      ),
    }
    this.draftState.userBidIds[userId] = getIds(list)
  }

  userBidListRequestFailed(error: FetchError) {
    this.draftState.userBidFetchProgress = Progress.ERROR
    this.draftState.userBidListFetchError = error
  }
}

export const PotentialBidActions = createActionCreators(PotentialBidReducer)
export default PotentialBidActions
export const reducer = createReducerFunction(PotentialBidReducer, initialState)
