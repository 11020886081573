import { AxiosError } from 'axios'
import HttpStatuses from 'http-status-codes'

import { BadRequestError, ClientError, ForbiddenError, NotFoundError, UnauthorizedError } from 'modules/errors'

export const handleApiErrors = (error: AxiosError) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line no-underscore-dangle
  if (error.__CANCEL__) {
    return
  }

  const status = error.response?.status

  if (status && status >= 400 && status < 500) {
    switch (status) {
      case HttpStatuses.NOT_FOUND:
        throw new NotFoundError(error)

      case HttpStatuses.BAD_REQUEST:
        throw new BadRequestError(error)

      case HttpStatuses.UNAUTHORIZED:
        throw new UnauthorizedError(error)

      case HttpStatuses.FORBIDDEN:
        throw new ForbiddenError(error)

      default:
        throw new ClientError(error)
    }
  }

  throw new Error('Server error.')
}
