import { IconArrowRight, Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const ChangeStatusWrapper = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-auto-flow: column;
  align-items: center;
`

export const Sure = styled.div`
  ${Typography.body2Default}
`
export const CurrentStatus = styled.div`
  ${Typography.body2Default};
  color: ${({ theme }) => theme.color.secondary300};
`

export const NextArrow = styled(IconArrowRight)`
  fill: ${({ theme }) => theme.color.secondary300};
  width: 10px;
  height: 10px;
`

export const NextElement = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`
