import React from 'react'
import { useTranslation } from 'react-i18next'

import { SimpleSelect } from '@agro-club/agroclub-shared'
import { FormikHelpers, FormikState } from 'formik'

import { BidTags } from 'modules/domain/bid/types'

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: FormikState<any> & FormikHelpers<any>
}

export const BidTagSelect: React.FC<Props> = ({ formik }) => {
  const { t } = useTranslation('bid')
  return (
    <div style={{ width: '252px' }}>
      <SimpleSelect
        invalid={formik.touched.bid_tag && !!formik.errors.bid_tag}
        onChange={val => formik.setFieldValue('bid_tag', val)}
        label={t('bidTag.label')}
        options={[
          { id: BidTags.EXPORT, title: t('bidTag.export') },
          { id: BidTags.DEFERRED, title: t('bidTag.deferred') },
          { id: BidTags.TAXED, title: t('bidTag.taxed') },
        ]}
        value={formik.values.bid_tag}
      />
    </div>
  )
}
