import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Progress, useAction, useDidMount } from '@agro-club/agroclub-shared'

import DriverActions from 'modules/domain/driver/duck'
import DriverSelectors from 'modules/domain/driver/selectors'
import { Driver } from 'modules/domain/types'

export const useDriversList = (carId: string): [Progress, Driver[]] => {
  const progress = useSelector(DriverSelectors.driversFetchProgress)
  const list = useSelector(state => DriverSelectors.driversByCarList(state, carId))
  const fetchAction = useAction(DriverActions.listRequested, carId)

  useDidMount(fetchAction)

  return [progress, list]
}

export const useDriversResetErrors = () => {
  const dispatch = useDispatch()
  return useCallback(() => dispatch(DriverActions.resetErrors()), [dispatch])
}

export const useDrivers = (carId: string, fetchOnMount?: boolean) => {
  const fetchList = useAction(DriverActions.listRequested, carId)

  useDidMount(() => {
    if (fetchOnMount) fetchList()
  })

  return {
    list: useSelector(state => DriverSelectors.driversByCarList(state, carId)),

    fetchList,
    add: useAction(DriverActions.addDriverRequested),
    update: useAction(DriverActions.updateDriverRequested),
    detach: useAction(DriverActions.detachDriverRequest),

    fetchProgress: useSelector(DriverSelectors.driversFetchProgress),
    addProgress: useSelector(DriverSelectors.addDriverProgress),
    updateProgress: useSelector(DriverSelectors.updateDriverProgress),
    detachProgress: useSelector(DriverSelectors.detachDriverProgress),

    resetErrors: useAction(DriverActions.resetErrors),
  }
}
