import React, { useCallback, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Progress, useAction, useHelmet } from '@agro-club/agroclub-shared'

import { REACT_APP_LOCALE } from 'env'
import { useDealStatuses } from 'modules/domain/collection/hooks'
import DealActions from 'modules/domain/deal/duck'
import { useCloseReasons, useDealFilterAndSortQuery, useDealList } from 'modules/domain/deal/hooks'
import DealSelectors from 'modules/domain/deal/selectors'
import { AvailableLanguages } from 'types/entities'
import * as ColumnBoard from 'views/components/ColumnBoard/styled'
import { Spinner } from 'views/components/CommonTableComponents/CommonTableComponents'
import TableSearch from 'views/components/TableSearch/TableSearch'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import { DealFilters } from 'views/pages/Deal/DealBoard/DealFilters'
import { DealItem } from 'views/pages/Deal/DealBoard/DealItem'
import { MarginSum } from 'views/pages/Deal/DealBoard/MarginSum'
import { OpenClosedSegment } from 'views/pages/Deal/DealBoard/OpenClosedSegment'
import { ColumnHeader, ContentWrapper, HeaderTitle, StyledContainer } from 'views/pages/Deal/DealBoard/styled'
import useScrollToDeal from 'views/pages/Deal/DealBoard/useScrollToDeal'
import { FirstCapitalize } from 'views/styled/common'
import * as Header from 'views/ui/Header/Header'

const COLUM_MIN_WIDTH = 272

export const DealBoard: React.FC = () => {
  useCloseReasons()
  const { t } = useTranslation('deal')
  const [progress] = useDealList()
  const columns = useSelector(DealSelectors.dealColumns)
  const [statusesProgress, statuses] = useDealStatuses()
  const statusesWithoutDrafts = useMemo(() => statuses.filter(s => s.slug !== 'draft'), [statuses])
  const columnCount = statusesWithoutDrafts.length
  const filterState = useSelector(DealSelectors.filter)
  const filterUpdated = useAction(DealActions.filterUpdated)
  const { filter } = useDealFilterAndSortQuery()
  const boardRef = useRef<HTMLDivElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const isRussia = REACT_APP_LOCALE === AvailableLanguages.ru

  useScrollToDeal({ progress, boardRef, wrapperRef })

  useHelmet({ title: t('title') })

  const onChangeSearch = useCallback(
    (search: string) => {
      filterUpdated({ ...filterState, search })
    },
    [filterUpdated, filterState],
  )

  return (
    <>
      <Layout.Header>
        <Header.Root>
          <Header.Title title={t('title')}>
            <OpenClosedSegment />
          </Header.Title>
          <TableSearch
            value={filter.search || filterState.search || ''}
            onChangeSearch={onChangeSearch}
            placeholder={t('searchPlaceholder')}
          />
        </Header.Root>
      </Layout.Header>
      <DealFilters />
      <Layout.Content>
        <ContentWrapper ref={wrapperRef}>
          {statusesProgress === Progress.WORK ? (
            <Spinner />
          ) : (
            <StyledContainer ref={boardRef} columnMinWidth={COLUM_MIN_WIDTH} columnCount={columnCount}>
              {statusesWithoutDrafts.map(status => {
                const deals = columns[status.slug]?.deals || []
                return (
                  <ColumnBoard.Column key={status.slug}>
                    <ColumnHeader size="big" locale={isRussia}>
                      <HeaderTitle>
                        <FirstCapitalize>{status.label}</FirstCapitalize>
                        <ColumnBoard.Counter>{deals.length}</ColumnBoard.Counter>
                      </HeaderTitle>
                      <MarginSum deals={deals} />
                    </ColumnHeader>
                    {progress === Progress.WORK ? (
                      <Spinner />
                    ) : (
                      deals.map(deal => {
                        const {
                          id,
                          sale_bid,
                          purchase_bid,
                          margin,
                          margin_level,
                          margin_percent,
                          actual_margin,
                          actual_margin_percent,
                          product,
                          quantity,
                          quantity_in_bags,
                          deal_type,
                          closed_reason,
                          margin_on_way,
                        } = deal
                        const sale_company_name = deal.sale_bid.company.name
                        const purchase_company_name = deal.purchase_bid.company.name
                        return (
                          <DealItem
                            {...{
                              key: id,
                              id,
                              sale_bid,
                              purchase_bid,
                              margin,
                              margin_level,
                              margin_percent,
                              actual_margin,
                              actual_margin_percent,
                              margin_on_way,
                              product,
                              quantity,
                              quantity_in_bags,
                              deal_type,
                              closed_reason,
                              sale_company_name,
                              purchase_company_name,
                            }}
                          />
                        )
                      })
                    )}
                  </ColumnBoard.Column>
                )
              })}
            </StyledContainer>
          )}
        </ContentWrapper>
      </Layout.Content>
    </>
  )
}
