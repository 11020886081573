import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useHelmet } from '@agro-club/agroclub-shared'

import logo from 'assets/images/logo.svg'
import isLangSelect from 'helpers/isLangSelect'
import AuthSelectors from 'modules/domain/auth/selectors'
import { I18nLangSelect } from 'views/components/I18nLangSelect/I18nLangSelect'

import GetCodeForm from './GetCodeForm'
import LoginForm from './LoginForm'
import * as S from './styled'

function AuthPage() {
  const { t } = useTranslation(['auth'])
  useHelmet({ title: t('metaTitle') })

  const step = useSelector(AuthSelectors.getStep)

  const renderStep = useCallback(() => {
    switch (step) {
      case 'get_code':
        return <GetCodeForm />
      case 'login':
        return <LoginForm />
      default:
        return null
    }
  }, [step])

  return (
    <>
      <S.Wrapper>
        <S.ContentWrapper>
          <S.LogoStyled src={logo} alt="logo" />
          {renderStep()}
        </S.ContentWrapper>
      </S.Wrapper>
      {isLangSelect() && (
        <div style={{ position: 'absolute', bottom: 8, left: 8 }}>
          <I18nLangSelect />
        </div>
      )}
    </>
  )
}

export default AuthPage
