import React from 'react'

import { Button, ButtonFullProps, IconAdd } from '@agro-club/agroclub-shared'
import styled, { useTheme } from 'styled-components'

const StyledButton = styled(Button)`
  padding: 0;
  border-radius: 8px;
  width: 32px;
  height: 32px;
  border: none;
  background-color: ${({ theme }) => theme.color.accentApproving50};
  transition: opacity 0.2s ease-out;
  &:active,
  &:hover {
    opacity: 0.8;
  }
`
const Circle = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.accentApproving};
`

export const SmallAddButton: React.FC<Partial<ButtonFullProps>> = props => {
  const theme = useTheme()
  return (
    <StyledButton intent="action" {...props}>
      <Circle>
        <IconAdd fill={theme.color.accentApproving50} />
      </Circle>
    </StyledButton>
  )
}
