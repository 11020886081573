import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { SimpleSelect, SimpleSelectProps } from '@agro-club/agroclub-shared'

import { useProducts } from 'modules/domain/collection/hooks'
import { Product } from 'modules/domain/collection/types'

type Props = {
  onProductChange: (product: Product) => void
  selected?: string
  invalid?: SimpleSelectProps['invalid']
}

export const ProductSelect: React.FC<Props> = props => {
  const { onProductChange, selected, invalid } = props
  const [progress, products] = useProducts()

  const { t } = useTranslation(['bid'])
  const onChange = useCallback(
    productId => {
      const product = products?.find(p => p.id === productId)
      product && onProductChange(product)
    },
    [products, onProductChange],
  )

  const options = useMemo(() => products?.map(p => ({ id: p.id, title: p.title })) || [], [products])

  return (
    <SimpleSelect
      invalid={invalid}
      errorText={t('form.noProductError')}
      progress={progress}
      onChange={onChange}
      label={t('form.product')}
      placeholder={t('form.selectProductPlaceholder')}
      loadingMessage={t('common:loading')}
      noOptionsMessage={() => t('form.noProductOptionsMessage')}
      options={options}
      value={selected}
    />
  )
}
