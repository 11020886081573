import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { useAction } from '@agro-club/agroclub-shared'
import { isNil } from 'ramda'
import styled from 'styled-components'

import DealActions from 'modules/domain/deal/duck'
import DealSelectors from 'modules/domain/deal/selectors'
import { Filters } from 'views/components/CommonTableComponents/CommonTableComponents'
import { ProductFilter } from 'views/components/ProductFilter/ProductFilter'
import { ResetFilterButton } from 'views/components/TableFilters/ResetFilterButton'
import { TeamFilter } from 'views/components/TeamFilter/TeamFilter'
import { MonthFilter } from 'views/pages/Deal/DealBoard/MonthFilter'

const StyledFilters = styled(Filters)`
  border-bottom: ${({ theme }) => `1px solid ${theme.color.outlineMid}`};
`

export const DealFilters: React.FC = () => {
  const filterState = useSelector(DealSelectors.filter)
  const filterUpdated = useAction(DealActions.filterUpdated)

  const selectedTeams = filterState.team

  const onChangeTeam = (id: string) => {
    const set = new Set(selectedTeams)
    set.has(id) ? set.delete(id) : set.add(id)
    filterUpdated({ ...filterState, team: [...set] })
  }

  const onChangeProduct = (id: string) => {
    const set = new Set(filterState.product || [])
    set.has(id) ? set.delete(id) : set.add(id)
    filterUpdated({ ...filterState, product: [...set] })
  }

  const onClearProduct = () => {
    filterUpdated({ ...filterState, product: undefined })
  }

  const isFilterApplied = Object.values({ ...filterState, dealCondition: undefined }).some(value => !isNil(value))
  const handleClearFilters = useCallback(() => {
    filterUpdated({ dealCondition: filterState.dealCondition })
  }, [filterUpdated, filterState])

  return (
    <StyledFilters>
      <TeamFilter
        selectedIds={selectedTeams}
        onChange={onChangeTeam}
        onClear={() => {
          filterUpdated({ ...filterState, team: undefined })
        }}
      />
      <MonthFilter />
      <ProductFilter onChange={onChangeProduct} onClear={onClearProduct} selectedIds={filterState.product} />
      <ResetFilterButton {...{ handleClearFilters, isFilterApplied }} />
    </StyledFilters>
  )
}
