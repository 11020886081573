import { Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import { box } from 'views/styled/common'

export const RightWrapper = styled.div`
  display: grid;
  gap: 24px;
`

export const Box = styled.div`
  ${box}
`

export const Header = styled.div`
  padding: 12px 24px;
  border-bottom: ${({ theme }) => `1px solid ${theme.color.outlineMid}`};
  ${Typography.largeHeadlineBold}
`

export const BidContent = styled.div`
  padding: 16px 24px;
`

export const CommentsContent = styled.div`
  padding: 16px 24px;
  height: 600px;
`
