import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { useAction } from '@agro-club/agroclub-shared'

import CarActions from 'modules/domain/car/duck'
import CarSelectors from 'modules/domain/car/selectors'
import { DocumentsView } from 'views/components/DocumentsView/DocumentsView'

export const Documents: React.FC = () => {
  const { car_id: carId } = useParams<{ car_id: string }>()

  const upload = useAction(CarActions.uploadDocumentsRequested)
  const fetchFiles = useAction(CarActions.documentsRequested, carId)

  useEffect(fetchFiles, [fetchFiles])

  const files = useSelector(state => CarSelectors.files(state, carId))
  const uploadProgress = useSelector(state => CarSelectors.filesUploadProgress(state, carId))
  const fetchProgress = useSelector(state => CarSelectors.filesFetchProgress(state, carId))
  const deleteDocument = useAction(CarActions.deleteDocumentRequested)
  const deleteDocumentProgress = useSelector(CarSelectors.deleteDocumentProgress)

  return (
    <DocumentsView
      onDelete={fileId => {
        deleteDocument(carId, fileId)
      }}
      deleteDocumentProgress={deleteDocumentProgress}
      onDrop={files => upload(carId, files)}
      files={files}
      uploadProgress={uploadProgress}
      fetchProgress={fetchProgress}
    />
  )
}
