import useFormatNumber from 'hooks/useFormatNumber'
import { Bid } from 'modules/domain/bid/types'

export default function useMainParameter(parameters?: Bid['parameters']) {
  const formatNumber = useFormatNumber()

  const parameter = parameters?.length ? parameters[0] : undefined
  return parameter
    ? `${parameter.parameter.name} ${parameter.condition_label} ${formatNumber(parameter.parameter_value)}`
    : ''
}
