import React from 'react'
import { useTranslation } from 'react-i18next'
import { CellProps } from 'react-table'

import { Carrier } from 'modules/domain/carrier/types'
import { SelectablePhone } from 'views/ui/SelectablePhone/SelectablePhone'

export const PhoneCell: React.FC<CellProps<Carrier>> = ({ row }) => {
  const { phone } = row.values
  return <SelectablePhone phone={phone} />
}

export const ActiveCell: React.FC<CellProps<Carrier>> = ({ value }) => {
  const { t } = useTranslation('common')
  return value ? t('yes') : t('no')
}
