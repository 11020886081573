import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import {
  Progress,
  useAction,
  useDidMount,
  useDidMountWithProgress,
  usePageQuery,
  useQuery,
} from '@agro-club/agroclub-shared'
import { isEmpty } from 'ramda'

import AuthSelectors from 'modules/domain/auth/selectors'
import UserActions, { ListRequestedParams } from 'modules/domain/user/duck'
import UserSelectors from 'modules/domain/user/selectors'
import { User, UserListRequestFilter, UserListRequestSorting } from 'modules/domain/user/types'
import { ResourceHook } from 'modules/types'

export const useUserFilterAndSortQuery = () => {
  const query = useQuery()
  const search = query.get('search')
  const date_joined_before = query.get('date_joined_before')
  const date_joined_after = query.get('date_joined_after')
  const sort_field: keyof User = query.get('sort_field') as keyof User
  const sort_reversed = query.get('sort_reversed')
  const profile_role = query.get('profile_role')
  const profile_type = query.get('profile_type')
  const is_active: string | null = query.get('is_active')
  const is_linked_to_active_task: string | null = query.get('is_linked_to_active_task')

  const teamStr = query.get('team')
  const teamArr = teamStr ? teamStr.split(',') : undefined

  const regionStr = query.get('region')
  const regionArr = regionStr ? regionStr.split(',') : undefined

  const districtStr = query.get('district')
  const districtArr = districtStr ? districtStr.split(',') : undefined

  const filter: UserListRequestFilter = {
    ...(search && { search }),
    ...(date_joined_before && { date_joined_before }),
    ...(date_joined_after && { date_joined_after }),
    ...(teamArr && teamArr.length && { team: teamArr }),
    ...(profile_role && { profile_role }),
    ...(profile_type && { profile_type }),
    ...(is_active && { is_active: is_active === 'true' }),
    ...(is_linked_to_active_task && {
      is_linked_to_active_task: is_linked_to_active_task === 'false' ? false : undefined,
    }),
    ...(regionArr && regionArr.length && { region: regionArr }),
    ...(districtArr && districtArr.length && { district: districtArr }),
  }

  const sorting: UserListRequestSorting = {
    ...(sort_field && { sort_field }),
    ...(sort_reversed && { sort_reversed: sort_reversed === 'true' }),
  }

  return { filter, sorting }
}

export const useUserList: ResourceHook<User[], void[]> = () => {
  const progress = useSelector(UserSelectors.listFetchProgress)
  const list = useSelector(UserSelectors.userList)
  const page = usePageQuery()
  const currentTeam = useSelector(AuthSelectors.team)
  let { filter } = useUserFilterAndSortQuery()
  const { sorting } = useUserFilterAndSortQuery()

  if (!filter?.team?.length && currentTeam?.id) {
    filter = { ...filter, team: [String(currentTeam.id)] }
  }

  const params: ListRequestedParams = {
    ...(!isEmpty(filter) && { filter }),
    ...(!isEmpty(sorting) && { sorting }),
    ...(page && { page }),
  }

  const fetchAction = useAction(UserActions.listRequested, params)

  useDidMount(fetchAction)

  return [progress, list]
}

export const useUser: ResourceHook<User, [string]> = (id: string) => {
  const meta = useSelector(state => UserSelectors.meta(state, id))
  const user = useSelector(state => UserSelectors.user(state, id))
  const fetchAction = useAction(UserActions.itemRequested, id)

  const progress = useDidMountWithProgress(fetchAction, meta.fetchProgress)

  return [progress, user]
}

export const useUserById: ResourceHook<User, [string]> = (id: string) => {
  const meta = useSelector(state => UserSelectors.meta(state, id))
  const item = useSelector(state => UserSelectors.user(state, id))
  const fetchAction = useAction(UserActions.itemRequested, id)
  const notItem = !item

  useEffect(() => {
    if (id && notItem && meta.fetchProgress !== Progress.WORK) {
      fetchAction()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notItem, id, meta.fetchProgress])

  return [meta.fetchProgress, item]
}
