import formatSortParam from 'helpers/formatSortParam'
import { Cars, CarsListRequestFilter, CarsListRequestSorting } from 'modules/domain/cars/types'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { ListResponse } from 'types/api.d'

export const getCarsList = (
  filter: CarsListRequestFilter,
  sorting: CarsListRequestSorting,
  page: number,
  pageSize?: number,
) => {
  try {
    return apiClient.get<ListResponse<Cars>>(endpoints.car(), {
      ...filter,
      sort: formatSortParam(sorting),
      page,
      page_size: pageSize,
    })
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Cars list fetch error!', err)
    throw err
  }
}

export const getCars = (id: string) => {
  try {
    return apiClient.get<Cars>(endpoints.car(id))
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Cars fetch error!', err)
    throw err
  }
}
