import { AvailableLanguages } from 'types/entities'

export const DEVELOPMENT = process.env.NODE_ENV === 'development'

const getEnv = (key: string) => {
  if (DEVELOPMENT) {
    return process.env[key]
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return window[key]
}
export const { REACT_APP_REDUX_LOGGER } = process.env
export const { REACT_APP_REDUX_LOGGER_HIDE_ACTIONS } = process.env

export const REACT_APP_API_BASE_URL = getEnv('REACT_APP_API_BASE_URL') || ''
export const SENTRY_DSN = getEnv('REACT_APP_SENTRY_DSN') || null
export const INTERCOM_APP_ID = getEnv('REACT_APP_INTERCOM_APP_ID') || ''
export const SEGMENT_WRITE_KEY = getEnv('REACT_APP_SEGMENT_WRITE_KEY') || ''
export const REACT_APP_YA_MAPS_API_KEY = getEnv('REACT_APP_YA_MAPS_API_KEY')
export const REACT_APP_LOCALE: string = getEnv('REACT_APP_LOCALE') || AvailableLanguages.ru
export const REACT_APP_CURRENCY_SYMBOL: string = getEnv('REACT_APP_CURRENCY_SYMBOL') || '₽'
export const REACT_APP_USE_GOOGLE_MAPS = getEnv('REACT_APP_USE_GOOGLE_MAPS') === 'true'
export const REACT_APP_GOOGLE_MAPS_API_KEY = getEnv('REACT_APP_GOOGLE_MAPS_API_KEY')

export default {
  SEGMENT_WRITE_KEY,
  REACT_APP_API_BASE_URL,
  REACT_APP_REDUX_LOGGER,
  SENTRY_DSN,
  DEVELOPMENT,
  INTERCOM_APP_ID,
  REACT_APP_REDUX_LOGGER_HIDE_ACTIONS,
  REACT_APP_YA_MAPS_API_KEY,
  REACT_APP_LOCALE,
  REACT_APP_CURRENCY_SYMBOL,
  REACT_APP_USE_GOOGLE_MAPS,
  REACT_APP_GOOGLE_MAPS_API_KEY,
}
