import React from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'
import { Link } from 'react-router-dom'

import { IconLinkOpener, Typography } from '@agro-club/agroclub-shared'
import { User } from '@sentry/browser'
import styled, { StyledProps } from 'styled-components'

import env from 'env'
import useFormatDate from 'hooks/useFormatDate'
import useFormatNumber from 'hooks/useFormatNumber'
import useLocationFrom from 'hooks/useLocationFrom'
import { DashboardTask } from 'modules/domain/dashboard/types'
import { Deal, DealSource, DealType, HighlightPrice, WhoResponded } from 'modules/domain/deal/types'
import { CurvedArrow } from 'views/pages/Dashboard/CurvedArrow'
import DealRoutes from 'views/pages/Deal/routes'
import UserRoutes from 'views/pages/User/routes'
import { A, T } from 'views/ui/LetterIcons/LetterIcons'

import { AbsoluteContainer, InputHeader, Item, ItemBody, ItemLink } from './styled'

const Price = styled.div<StyledProps<{ $isBold: boolean }>>`
  ${({ $isBold }) => ($isBold ? Typography.subheadlineBold : Typography.body2Default)};
`
export const Tax = styled.div<{ sup?: boolean }>`
  ${Typography.caption1Default};
  color: ${props => props.theme.color.textSecondary};
  ${({ sup }) => (sup ? 'margin-top: -4px' : 'margin-bottom: 4px')};
`

const Company = styled.div`
  ${Typography.body2Bold}
`
const CompanyLinkIcon = styled(IconLinkOpener)`
  fill: ${props => props.theme.color.secondary200};
  margin-left: 5px;
  vertical-align: middle;
  cursor: pointer;
  transition: fill 0.1s ease-in;
  position: relative;
  z-index: 1;
  &:hover {
    fill: ${props => props.theme.color.primary500};
  }
`

const Product = styled.div`
  ${Typography.body2Bold}
`
const Quantity = styled.div`
  ${Typography.body2Default};
  color: ${({ theme }) => theme.color.textSecondary};
`

const Distance = styled.span`
  ${Typography.body2Bold};
`

const PricePerKm = styled.span`
  ${Typography.body2Default};
  color: ${({ theme }) => theme.color.textSecondary};
`

const Badges = styled.div`
  position: absolute;
  top: 8px;
  right: 0;
`

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ArrowWrapper = styled.div<StyledProps<{ $whoResponded: Deal['who_responded'] }>>`
  padding-left: 5px;
  transform: ${({ $whoResponded }) => ($whoResponded === WhoResponded.purchase ? 'scaleY(-1)' : 'none')};
`

const DateWraper = styled.div`
  ${Typography.body2Default};
  color: ${({ theme }) => theme.color.textSecondary};
`

type Props = {
  task: DashboardTask
}

export const DealItem: React.FC<Props> = props => {
  const { task } = props
  const deal = task.linked_deal
  const { t } = useTranslation('dashboard')
  const saleBidOwnerId = (deal?.sale_bid.owner as User)?.id ?? deal?.sale_bid.owner
  const purchaseBidOwnerId = (deal?.purchase_bid.owner as User)?.id ?? deal?.purchase_bid.owner
  const formatNumber = useFormatNumber()
  const formatDate = useFormatDate()
  const from = useLocationFrom()
  const currency = env.REACT_APP_CURRENCY_SYMBOL

  return (
    <Item>
      {deal?.id && <ItemLink to={{ pathname: generatePath(DealRoutes.Edit, { id: deal?.id }), state: { from } }} />}

      <InputHeader task={task} />

      <ItemBody>
        <AbsoluteContainer>
          <Badges>{deal?.deal_type === DealType.agent ? <A /> : <T />}</Badges>
        </AbsoluteContainer>

        <DateWraper>
          <div>
            {t('dealId')} {deal?.id}
          </div>
          <div>
            {t('created')} {formatDate(deal?.created, true)}
          </div>
        </DateWraper>

        <PriceWrapper>
          <div>
            <Price $isBold={deal?.highlight_price === HighlightPrice.exw}>
              {t('common:rubKgEXW', {
                price: formatNumber(deal?.price_exw, true),
                currency,
              })}
            </Price>
            <Tax>
              {t('common:tax_per_bag')} {currency} {deal?.tax_from_exw}
            </Tax>
            <Price $isBold={deal?.highlight_price === HighlightPrice.cpt}>
              {t('common:rubKgCPT', { price: formatNumber(deal?.price_cpt, true), currency })}
            </Price>
          </div>
          {deal?.source === DealSource.response && !!deal?.who_responded && (
            <ArrowWrapper $whoResponded={deal.who_responded}>
              <CurvedArrow />
            </ArrowWrapper>
          )}
        </PriceWrapper>

        <Company>
          {deal?.sale_bid.company.name}
          <Link
            to={{
              pathname: generatePath(UserRoutes.Detail, { id: saleBidOwnerId as string }),
              state: { from },
            }}
          >
            <CompanyLinkIcon />
          </Link>
        </Company>

        <Company>
          {deal?.purchase_bid.company.name}
          <Link
            to={{
              pathname: generatePath(UserRoutes.Detail, { id: purchaseBidOwnerId as string }),
              state: { from },
            }}
          >
            <CompanyLinkIcon />
          </Link>
        </Company>

        <div>
          <Product>{deal?.product.title}</Product>
          <Quantity>
            {formatNumber(deal?.quantity_in_bags)} {t('common:bags')}
            &nbsp; ({formatNumber(deal?.quantity)} {t('common:ton')})
          </Quantity>
        </div>
        <div>
          <Distance>
            {formatNumber(deal?.distance)} {t('common:km')}
          </Distance>
          &nbsp;
          <PricePerKm>{t('common:rubKg', { price: formatNumber(deal?.price_logistics, true), currency })}</PricePerKm>
        </div>
      </ItemBody>
    </Item>
  )
}
