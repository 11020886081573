import React from 'react'

import styled from 'styled-components'

type Props = {
  email: string
}

const StyledEmail = styled.a`
  color: ${({ theme }) => theme.color.primary600};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

export const Email: React.FC<Props> = props => {
  const { email } = props
  return (
    <StyledEmail
      href={`mailto:${email}`}
      onClick={e => {
        e.stopPropagation()
      }}
    >
      {email}
    </StyledEmail>
  )
}
