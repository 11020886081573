import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { generatePath, useHistory, useParams } from 'react-router-dom'

import { DatePicker, Progress, Spinner, useAction } from '@agro-club/agroclub-shared'
import { useFormik } from 'formik'

import env from 'env'
import formatDateWithoutLocale from 'helpers/formatDateWithoutLocale'
import useDateFnsLocale from 'hooks/useDateFnsLocale'
import { useNotificationProgress } from 'hooks/useNotificationProgress'
import usePrevious from 'hooks/usePrevious'
import { SERVER_DATE_FORMAT } from 'modules/constants'
import { useCarById } from 'modules/domain/car/hooks'
import { useDeal } from 'modules/domain/deal/hooks'
import TripActions from 'modules/domain/trip/duck'
import TripSelectors from 'modules/domain/trip/selectors'
import { AddTrailer } from 'views/components/AddTrailer/AddTrailer'
import { DriverSelect } from 'views/components/DriverSelect/DriverSelect'
import { TrailerSelect } from 'views/components/TrailerSelect/TrailerSelect'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import { TrailerWrapper } from 'views/pages/Carrier/CarrierDetail/UserCars/styled'
import LogisticsRoutes from 'views/pages/Logistics/routes'
import { Container, LoadingWrapper, TwoColumnLayout } from 'views/pages/Logistics/TripPages/styled'
import { AddDriverPopup } from 'views/pages/Logistics/TripPages/TripEditor/AddDriverPopup'
import * as S from 'views/pages/Logistics/TripPages/TripEditor/styled'
import { TripEditableFields } from 'views/pages/Logistics/TripPages/TripEditor/types'
import { TripInfoBlock } from 'views/pages/Logistics/TripPages/TripInfoBlock'
import { AddTextBtn } from 'views/styled/common'
import { FormItem } from 'views/ui/FormItem/FormItem'
import * as Header from 'views/ui/Header/Header'
import { NumberInput } from 'views/ui/NumberInput/NumberInput'

export const TripAddPage: React.FC = () => {
  const { t } = useTranslation('tripPages')
  const history = useHistory()
  const { dealId, carId } = useParams<{ dealId: string; carId: string }>()
  const [isDriverPopupShow, setDriverPopupShow] = useState<boolean>(false)

  const [carProgress, car] = useCarById(carId)
  const [dealProgress, deal] = useDeal(dealId)
  const addTrip = useAction(TripActions.addRequested)
  const progress = useSelector(TripSelectors.addProgress)
  const errorFields = useSelector(TripSelectors.addErrorFields)
  const currency = env.REACT_APP_CURRENCY_SYMBOL
  const locale = useDateFnsLocale()

  useNotificationProgress(progress, t('tripNotifyAddSuccess'), t('tripNotifyAddFail'))

  const formik = useFormik<TripEditableFields>({
    initialValues: {
      deal: dealId,
      car: carId,
      driver: null,
      load_date: '',
      load_quantity: 0,
      price_logistics: 0,
      trailer: null,
    },

    onSubmit(values) {
      if (!formik.dirty) return
      addTrip({
        deal: dealId,
        car: carId,
        driver: values.driver,
        load_date: formatDateWithoutLocale(values.load_date, SERVER_DATE_FORMAT),
        unload_date: formatDateWithoutLocale(values.unload_date, SERVER_DATE_FORMAT) || null,
        price_logistics: values.price_logistics,
        load_quantity: values.load_quantity,
        unload_quantity: values.unload_quantity,
        trailer: values.trailer,
      })
    },
  })

  const handleSubmit = useCallback(() => {
    if (formik.isValid && formik.dirty) {
      formik.submitForm().then()
    }
  }, [formik])

  const onClose = useCallback(() => {
    history.push(generatePath(LogisticsRoutes.Cars, { id: dealId }))
  }, [history, dealId])

  const onSuccess = useCallback(() => {
    history.push(generatePath(LogisticsRoutes.Detail, { id: dealId }))
  }, [dealId, history])

  const prevProgress = usePrevious<Progress>(progress)
  React.useEffect(() => {
    if (prevProgress === Progress.WORK && progress === Progress.SUCCESS) {
      onSuccess()
    }
  }, [onSuccess, prevProgress, progress])

  if (carProgress === Progress.WORK || dealProgress === Progress.WORK || !car || !deal) {
    return (
      <LoadingWrapper>
        <Spinner />
      </LoadingWrapper>
    )
  }

  return (
    <>
      <Layout.Header hasBottomBorder>
        <Header.Root onClickBack={onClose}>
          <Header.Title compact title={t('tripEditor.tripAddHeader')} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <TwoColumnLayout>
          <Container>
            <form
              onSubmit={e => {
                e.preventDefault()
                handleSubmit()
              }}
            >
              <TripInfoBlock car={car} deal={deal} />

              <S.Field>
                <FormItem
                  fieldName="trailer"
                  errorFields={errorFields}
                  formikInstance={formik}
                  render={additionalProps => (
                    <div>
                      <TrailerWrapper>
                        <TrailerSelect
                          label={t('tripDetails.trailerLabel')}
                          selectedId={formik.values.trailer}
                          onChange={value => {
                            formik.setFieldValue('trailer', value)
                          }}
                          owner={car.owner.id}
                          invalid={additionalProps.invalid}
                        />
                      </TrailerWrapper>
                      <div>
                        <AddTrailer owner={car.owner.id} />
                      </div>
                    </div>
                  )}
                />
              </S.Field>

              <S.Field>
                <FormItem
                  fieldName="driver"
                  errorFields={errorFields}
                  formikInstance={formik}
                  render={additionalProps => (
                    <>
                      <DriverSelect
                        carId={carId}
                        driverId={formik.values.driver}
                        onChangeDriver={driverId => formik.setFieldValue('driver', driverId)}
                        {...additionalProps}
                      />

                      <AddTextBtn onClick={() => setDriverPopupShow(true)}>{t('tripEditor.addDriverLink')}</AddTextBtn>
                    </>
                  )}
                />
              </S.Field>

              <S.TwoColumn>
                <S.Field>
                  <FormItem
                    fieldName="load_date"
                    errorFields={errorFields}
                    formikInstance={formik}
                    render={additionalProps => (
                      <DatePicker
                        label={t('tripEditor.loadDate')}
                        date={formik.values.load_date}
                        onChange={d => {
                          formik.setFieldValue('load_date', d)
                        }}
                        required
                        {...additionalProps}
                        reactDatePickerProps={{
                          locale,
                          dateFormat: 'P',
                          placeholderText: t('common:datePlaceholder'),
                        }}
                      />
                    )}
                  />
                </S.Field>

                <S.Field>
                  <FormItem
                    fieldName="unload_date"
                    errorFields={errorFields}
                    formikInstance={formik}
                    render={additionalProps => (
                      <DatePicker
                        label={t('tripEditor.unloadDate')}
                        date={formik.values.unload_date ?? undefined}
                        onChange={d => {
                          formik.setFieldValue('unload_date', d)
                        }}
                        {...additionalProps}
                        reactDatePickerProps={{
                          locale,
                          dateFormat: 'P',
                          placeholderText: t('common:datePlaceholder'),
                        }}
                      />
                    )}
                  />
                </S.Field>

                <S.Field>
                  <FormItem
                    fieldName="load_quantity"
                    errorFields={errorFields}
                    formikInstance={formik}
                    render={additionalProps => (
                      <NumberInput
                        required
                        label={t('tripEditor.loadQuantity')}
                        value={formik.values.load_quantity}
                        onChange={v => {
                          formik.setFieldValue('load_quantity', v ?? 0)
                        }}
                        {...additionalProps}
                      />
                    )}
                  />
                </S.Field>

                <S.Field>
                  <FormItem
                    fieldName="unload_quantity"
                    errorFields={errorFields}
                    formikInstance={formik}
                    render={additionalProps => (
                      <NumberInput
                        label={t('tripEditor.unloadQuantity')}
                        value={formik.values.unload_quantity ?? undefined}
                        onChange={v => {
                          formik.setFieldValue('unload_quantity', v)
                        }}
                        {...additionalProps}
                      />
                    )}
                  />
                </S.Field>

                <S.Field>
                  <FormItem
                    fieldName="price_logistics"
                    errorFields={errorFields}
                    formikInstance={formik}
                    render={additionalProps => (
                      <NumberInput
                        label={t('tripEditor.logisticsPrice', { currency })}
                        min={0}
                        max={100}
                        value={formik.values.price_logistics}
                        required
                        onChange={v => {
                          formik.setFieldValue('price_logistics', v ?? 0)
                        }}
                        {...additionalProps}
                      />
                    )}
                  />
                </S.Field>
              </S.TwoColumn>

              <S.FormButton
                intent="primary"
                size="small"
                filled
                disabled={!formik.dirty}
                onClick={formik.submitForm}
                progress={progress}
              >
                {t('common:save')}
              </S.FormButton>
              <S.FormButton intent="primary" size="small" onClick={onClose}>
                {t('common:cancel')}
              </S.FormButton>
            </form>
          </Container>
        </TwoColumnLayout>
      </Layout.Content>

      {isDriverPopupShow && <AddDriverPopup carId={carId} close={() => setDriverPopupShow(false)} />}
    </>
  )
}
