import { useEffect, useState } from 'react'

import { Progress } from '@agro-club/agroclub-shared'

import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'

export type Assignee = {
  id: string
  phone: string
  short_name: string
}

export const useAssignees = (team?: string): [Progress, Assignee[]] => {
  const [Assignees, setAssignees] = useState<Assignee[]>([])
  const [progress, setProgress] = useState<Progress>(Progress.SUCCESS)

  useEffect(() => {
    setProgress(Progress.WORK)
    apiClient
      .get<Assignee[]>(endpoints.taskAssignees(), { ...(team && { team }) })
      .then(data => {
        setAssignees(data)
        setProgress(Progress.SUCCESS)
      })
      .catch(() => setProgress(Progress.ERROR))
  }, [team])

  return [progress, Assignees]
}
