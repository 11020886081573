import React, { VFC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button, Input, Progress, SectionBody, SimpleSelect, useAction } from '@agro-club/agroclub-shared'
import { useFormik } from 'formik'

import env from 'env'
import { useNotificationProgress } from 'hooks/useNotificationProgress'
import UserActions from 'modules/domain/user/duck'
import UserSelectors from 'modules/domain/user/selectors'
import { ProfileType, User, UserDTO } from 'modules/domain/user/types'
import { UserAddressDTO } from 'modules/domain/userAddress/types'
import { CompanySelect } from 'views/components/CompanySelect/CompanySelect'
import { FormSection } from 'views/components/FormComponents/FormComponents'
import { LegalTypeSelect } from 'views/components/LegalTypeSelect/LegalTypeSelect'
import AddressForm from 'views/components/Maps/AddressForm'
import { TeamsSelect } from 'views/components/TeamsSelect/TeamsSelect'
import {
  AddressColumn,
  BlockTitle,
  Column,
  CompanyHelp,
  Fio,
  Form,
  InputWrapper,
  LegalTypeWrapper,
  OneLineField,
  StyledLink,
  TwoColumn,
} from 'views/components/UserForm/styled'
import { useFirstNameLastName } from 'views/components/UserForm/useFirstNameLastName'
import StickyFooterLayout from 'views/layouts/StickyFooterLayout/StickyFooterLayout'
import CompanyRoutes from 'views/pages/Company/routes'
import { FormItem } from 'views/ui/FormItem/FormItem'
import { NumberInput } from 'views/ui/NumberInput/NumberInput'

export type EditableFields = {
  phone: string
  email: string
  address?: UserAddressDTO
  profile: {
    profile_type: ProfileType | null
    first_name: string
    last_name: string
    patronymic_name: string
    company: string | null
    team: string | null
    recommended_margin_percent?: number
    credit_limit?: number
  }
}

type Props = {
  mode: 'edit' | 'create'
  onSubmit(values: UserDTO): void
  progress: Progress
  initialValues?: User
  goBack: () => void
  isCarrierForm?: boolean
}

export const UserForm: VFC<Props> = props => {
  const {
    isCarrierForm,
    progress,
    onSubmit,
    initialValues: { id, phone = '', email = '', profile } = {},
    mode,
    goBack,
  } = props
  const isEdit = mode === 'edit'
  const { t } = useTranslation(['user'])
  const first_name = profile?.first_name || ''
  const last_name = profile?.last_name || ''
  const patronymic_name = profile?.patronymic_name || ''
  const profile_type = profile?.profile_type || null
  const company = profile?.company?.id || null
  const team = profile?.team?.id || null
  const { recommended_margin_percent, credit_limit } = profile ?? {}
  const addErrorFields = useSelector(UserSelectors.addErrorFields)
  const meta = useSelector(state => UserSelectors.meta(state, id))
  const resetAddErrors = useAction(UserActions.resetAddErrors)
  const errorFields = isEdit ? meta?.updateErrorFields : addErrorFields
  const currency = env.REACT_APP_CURRENCY_SYMBOL

  useEffect(
    () => () => {
      resetAddErrors()
    },
    [resetAddErrors],
  )

  useNotificationProgress(progress, isEdit ? t('form.notifyEditSuccess') : t('form.notifyAddSuccess'))

  const formik = useFormik<EditableFields>({
    initialValues: {
      phone,
      email,
      profile: {
        profile_type: isCarrierForm ? ProfileType.carrier : profile_type,
        first_name,
        last_name,
        patronymic_name,
        company,
        team,
        recommended_margin_percent,
        credit_limit,
      },
    },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: () => {
      if (!formik.isValid) {
        return
      }
      const { email, phone, address } = formik.values
      onSubmit({
        email,
        phone,
        addresses: address ? [address] : [],
        profile: {
          ...formik.values.profile,
          profile_type: formik.values.profile.profile_type as ProfileType,
          company: formik.values.profile.company || undefined,
        },
      })
    },
  })

  const firstNameLastName = useFirstNameLastName(formik, errorFields)

  const isCarrier = formik.values['profile.profile_type'] === ProfileType.carrier

  const renderBody = () => (
    <Form
      onSubmit={e => {
        e.preventDefault()
        formik.submitForm().catch()
      }}
    >
      <SectionBody>
        <FormSection>
          <FormItem
            width={306}
            fieldName="profile.profile_type"
            errorFields={errorFields}
            render={additionalProps => (
              <SimpleSelect
                label={t('form.fields.profile_type')}
                placeholder={t('form.profileTypePlaceholder')}
                options={[
                  { id: ProfileType.seller, title: t(`profile_type.${ProfileType.seller}`) },
                  { id: ProfileType.customer, title: t(`profile_type.${ProfileType.customer}`) },
                  { id: ProfileType.carrier, title: t(`profile_type.${ProfileType.carrier}`) },
                ]}
                onChange={(value: ProfileType) => {
                  formik.setFieldValue('profile.profile_type', value)
                }}
                value={formik.values.profile.profile_type}
                {...additionalProps}
              />
            )}
          />
          <Fio>{firstNameLastName}</Fio>
          <TwoColumn>
            <Column>
              <FormItem
                width={306}
                fieldName="phone"
                errorFields={errorFields}
                formikInstance={formik}
                render={additionalProps => (
                  <Input
                    {...formik.getFieldProps('phone')}
                    label={t('form.fields.phone')}
                    disabled={isEdit}
                    {...additionalProps}
                  />
                )}
              />

              <InputWrapper>
                <Input {...formik.getFieldProps('email')} label={t('form.fields.email')} />
              </InputWrapper>
              {isEdit && profile?.company ? (
                <InputWrapper>
                  <Input
                    value={profile?.company?.name?.trim() || profile?.company?.inn || ''}
                    label={t('common:company')}
                    disabled
                  />
                </InputWrapper>
              ) : (
                <InputWrapper>
                  <CompanySelect
                    menuPlacement="top"
                    onCompanyChange={company => {
                      formik.setFieldValue('profile.company', company.id)
                    }}
                  />
                  <CompanyHelp>
                    {t('form.helpText')}
                    &nbsp;
                    <StyledLink target="_blank" to={CompanyRoutes.List}>
                      {t('menu:companies')}
                    </StyledLink>
                  </CompanyHelp>
                </InputWrapper>
              )}

              <InputWrapper>
                <TeamsSelect
                  menuPlacement="top"
                  defaultTeamId={String(team)}
                  onChangeTeam={teamId => {
                    formik.setFieldValue('profile.team', teamId)
                  }}
                />
              </InputWrapper>

              {!isCarrier && (
                <>
                  <BlockTitle>{t('recommendation')}</BlockTitle>
                  <OneLineField>
                    <NumberInput
                      value={formik.values.profile.recommended_margin_percent}
                      onChange={v => formik.setFieldValue('profile.recommended_margin_percent', v)}
                      label={t('form.fields.recommendedMarginPercent')}
                    />
                    <NumberInput
                      value={formik.values.profile.credit_limit}
                      onChange={v => formik.setFieldValue('profile.credit_limit', v)}
                      label={t('form.fields.justCreditLimit', { currency })}
                    />
                  </OneLineField>
                </>
              )}
            </Column>
            {!isEdit && !isCarrier && (
              <AddressColumn>
                <AddressForm
                  placeholder={t('user:form.addressPlaceholder')}
                  noOptionsMessage={() => t('user:form.noAddressOptionMessage')}
                  mapWidth={306}
                  label={t('common:address')}
                  onChange={({ coords, address, geoObject }) => {
                    formik.setFieldValue(
                      'address',
                      address
                        ? {
                            ...formik.values.address,
                            address,
                            geoObject,
                            latitude: coords?.[0],
                            longitude: coords?.[1],
                          }
                        : {
                            ...formik.values.address,
                            address: undefined,
                            geoObject: undefined,
                            latitude: undefined,
                            longitude: undefined,
                          },
                    )
                  }}
                >
                  <LegalTypeWrapper>
                    <FormItem
                      width={306}
                      errorFields={errorFields}
                      fieldName="addresses.legal_type"
                      render={additionalProps => (
                        <LegalTypeSelect
                          menuPlacement="top"
                          onChange={val => {
                            formik.setFieldValue('address.legal_type', val)
                          }}
                          value={formik.values.address?.legal_type}
                          {...additionalProps}
                        />
                      )}
                    />
                  </LegalTypeWrapper>
                </AddressForm>
              </AddressColumn>
            )}
          </TwoColumn>
        </FormSection>
      </SectionBody>
    </Form>
  )

  const renderFooter = () => (
    <>
      <Button filled progress={progress} intent="primary" onClick={formik.submitForm}>
        {t('common:save')}
      </Button>
      <Button
        intent="primary"
        onClick={() => {
          goBack()
        }}
      >
        {t('common:cancel')}
      </Button>
    </>
  )

  return <StickyFooterLayout body={renderBody()} footer={renderFooter()} />
}
