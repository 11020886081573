import { Typography } from '@agro-club/agroclub-shared'
import styled, { StyledProps } from 'styled-components'

import { smallBox } from 'views/styled/common'

type ContainerProps = StyledProps<{ columnMinWidth: number; columnCount: number }>
export const Container = styled.div<ContainerProps>`
  display: inline-grid;
  grid-column-gap: 24px;
  grid-auto-flow: column;
  grid-template-columns: ${({ columnMinWidth, columnCount }) =>
    `repeat(${columnCount}, minmax(${columnMinWidth}px, 1fr))`};
  height: auto;
  min-height: 100%;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
`

type HeaderProps = StyledProps<{ size: 'big' | 'small' }>
export const Header = styled.div<HeaderProps>`
  display: flex;
  align-items: center;
  height: ${({ size }) => (size === 'small' ? '48px' : '68px')};
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1;
  margin-bottom: 22px;
  padding: 12px 12px 14px 12px;
  ${smallBox};
  ${Typography.body1Bold};
`

export const Item = styled.div`
  position: relative;
  transition: background-color 0.1s;
  width: 100%;
  margin-bottom: 16px;
  padding: 10px 28px 10px 12px;
  ${smallBox}
`

type CounterProps = StyledProps<{ isImportant?: boolean }>

export const Counter = styled.span<CounterProps>`
  padding: 0 6px;
  margin-left: 4px;
  color: ${({ theme, isImportant }) => (isImportant ? theme.color.onPrimaryLight : theme.color.onSurfaceMidEmphasys)};
  background-color: ${({ theme, isImportant }) =>
    isImportant ? theme.color.accentDestruct500 : theme.color.secondary25};
  border-radius: 10px;
  ${Typography.caption1Bold}
`
