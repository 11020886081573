import { Progress } from '@agro-club/agroclub-shared'
import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer'

import { AddError, FetchError, RemoveError, UpdateError } from 'modules/domain/types'
import { arrToDict, getIds } from 'modules/utils/helpers'

import { Trip, TripDTO, TripState } from './types'

const initialState: TripState = {
  tripsByDeal: {},

  items: {},
  meta: {},
  ids: [],

  listFetchProgress: Progress.IDLE,
  listFetchError: null,
  itemFetchProgress: Progress.IDLE,
  itemFetchError: null,
  addProgress: Progress.IDLE,
  addError: null,
  updateProgress: Progress.IDLE,
  updateError: null,
  removeProgress: Progress.IDLE,
  removeError: null,

  getDocumentProgress: Progress.IDLE,
  getDocumentError: null,
  getDocumentErrorDetail: undefined,
  documentTypes: [],
  uploadProgress: Progress.IDLE,
  getDocumentFilesProgress: Progress.IDLE,
  getDocumentFilesError: null,
  getDocumentFilesErrorDetail: undefined,
  documentFiles: [],
  deleteDocumentProgress: Progress.IDLE,
}

class TripReducer extends ImmerReducer<TripState> {
  listRequested(dealId: string) {
    this.draftState.tripsByDeal[dealId] = this.draftState.tripsByDeal[dealId] ?? []
    this.draftState.listFetchProgress = Progress.WORK
    this.draftState.listFetchErrorDetail = undefined
    this.draftState.listFetchError = null
  }

  listRequestSucceed(dealId: string, list: Trip[]) {
    this.draftState.listFetchProgress = Progress.SUCCESS
    this.draftState.listFetchErrorDetail = undefined

    this.draftState.tripsByDeal[dealId] = getIds(list)
    this.draftState.items = arrToDict(list)
    this.draftState.meta = arrToDict(
      list.map(item => ({
        id: item.id,
        fetchProgress: Progress.SUCCESS,
        fetchError: null,
        removeProgress: Progress.IDLE,
        removeError: null,
        updateProgress: Progress.IDLE,
        updateError: null,
      })),
    )
    this.draftState.ids = getIds(list)
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  listRequestFailed(error: FetchError, errorDetail?: string) {
    this.draftState.listFetchProgress = Progress.ERROR
    this.draftState.listFetchError = error
    this.draftState.listFetchErrorDetail = errorDetail
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  itemRequested(id: string) {
    this.draftState.itemFetchProgress = Progress.WORK

    const meta = {
      id,
      updateProgress: Progress.IDLE,
      updateError: null,
      removeProgress: Progress.IDLE,
      removeError: null,
    }

    this.draftState.meta[id] = {
      ...meta,
      ...this.draftState.meta[id],
      fetchProgress: Progress.WORK,
      fetchError: null,
    }
  }

  itemRequestSucceed(item: Trip) {
    this.draftState.itemFetchProgress = Progress.SUCCESS
    this.draftState.meta[item.id].fetchProgress = Progress.SUCCESS
    this.draftState.meta[item.id].fetchError = null
    this.draftState.meta[item.id].fetchErrorDetail = undefined
    this.draftState.items[item.id] = item
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  itemRequestFailed(id: string, error: FetchError, errorDetail?: string) {
    this.draftState.itemFetchProgress = Progress.ERROR
    this.draftState.meta[id].fetchProgress = Progress.ERROR
    this.draftState.meta[id].fetchError = error
    this.draftState.meta[id].fetchErrorDetail = errorDetail
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  addRequested(dto: TripDTO) {
    this.draftState.addProgress = Progress.WORK
    this.draftState.addError = null
    this.draftState.addErrorDetail = undefined
    this.draftState.addErrorFields = undefined
  }

  addSucceed(item: Trip) {
    const trips = this.draftState.tripsByDeal[item.deal] ?? []
    this.draftState.items[item.id] = item
    this.draftState.addProgress = Progress.SUCCESS
    this.draftState.tripsByDeal[item.deal] = [...trips, item.id]
    this.draftState.ids = [...this.draftState.ids, item.id]
    this.draftState.meta[item.id] = {
      id: item.id,
      fetchProgress: Progress.SUCCESS,
      fetchError: null,
      updateProgress: Progress.IDLE,
      updateError: null,
      removeProgress: Progress.IDLE,
      removeError: null,
    }
    this.draftState.addErrorDetail = undefined
    this.draftState.addErrorFields = undefined
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  addFailed(error: AddError, errorDetail?: string, errorFields?: Record<string, string>) {
    this.draftState.addProgress = Progress.ERROR
    this.draftState.addError = error
    this.draftState.addErrorDetail = errorDetail
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.draftState.addErrorFields = errorFields
  }

  updateRequested(id: string, _item: Partial<TripDTO>) {
    this.draftState.updateProgress = Progress.WORK
    this.draftState.meta[id].updateProgress = Progress.WORK
    this.draftState.meta[id].updateError = null
    this.draftState.meta[id].updateErrorDetail = undefined
    this.draftState.meta[id].updateErrorFields = undefined
  }

  updateSucceed(item: Trip) {
    const { car } = this.draftState.items[item.id]
    this.draftState.items[item.id] = { ...item, car }
    this.draftState.updateProgress = Progress.SUCCESS
    this.draftState.meta[item.id].updateProgress = Progress.SUCCESS
    this.draftState.meta[item.id].updateError = null
    this.draftState.meta[item.id].updateErrorDetail = undefined
    this.draftState.meta[item.id].updateErrorFields = undefined
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  updateFailed(id: string, error: UpdateError, errorText?: string, errorFields?: Record<string, string>) {
    this.draftState.updateProgress = Progress.ERROR
    this.draftState.meta[id].updateProgress = Progress.ERROR
    this.draftState.meta[id].updateError = error
    this.draftState.meta[id].updateErrorDetail = errorText
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.draftState.meta[id].updateErrorFields = errorFields
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  removeRequested(id: string) {
    this.draftState.removeProgress = Progress.WORK
    this.draftState.meta[id].removeProgress = Progress.WORK
    this.draftState.meta[id].removeError = null
    this.draftState.meta[id].removeErrorDetail = undefined
  }

  removeSucceed(id: string) {
    this.draftState.removeProgress = Progress.SUCCESS
    delete this.draftState.items[id]
    delete this.draftState.meta[id]
    const i = this.draftState.ids.findIndex(item => item.toString() === id)
    if (i !== -1) {
      const { ids } = this.draftState
      this.draftState.ids = [...ids.slice(0, i), ...ids.slice(i + 1)]
    }

    for (const dealId in this.draftState.tripsByDeal)
      if (Object.prototype.hasOwnProperty.call(this.draftState.tripsByDeal, dealId)) {
        const trips = this.draftState.tripsByDeal[dealId]

        if (i !== -1) {
          this.draftState.tripsByDeal[dealId] = [...trips.slice(0, i), ...trips.slice(i + 1)]
        }
      }
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  removeFailed(id: string, error: RemoveError, errorDetail?: string) {
    this.draftState.removeProgress = Progress.ERROR
    this.draftState.meta[id].removeProgress = Progress.ERROR
    this.draftState.meta[id].removeError = error
    this.draftState.meta[id].removeErrorDetail = errorDetail
  }

  getDocumentTypesRequested(_id: string) {
    this.draftState.getDocumentProgress = Progress.WORK
    this.draftState.getDocumentErrorDetail = undefined
    this.draftState.getDocumentError = null
  }

  getDocumentTypesSucceed(list) {
    this.draftState.getDocumentProgress = Progress.SUCCESS
    this.draftState.getDocumentErrorDetail = undefined

    this.draftState.documentTypes = list
  }

  getDocumentTypesFailed(error: FetchError, errorDetail?: string) {
    this.draftState.getDocumentProgress = Progress.ERROR
    this.draftState.getDocumentError = error
    this.draftState.getDocumentErrorDetail = errorDetail
  }

  uploadDocumentRequested(_id: string, _document_type: string, _file: File) {
    this.draftState.uploadProgress = Progress.WORK
  }

  uploadDocumentSucccess() {
    this.draftState.uploadProgress = Progress.SUCCESS
  }

  uploadDocumentFailed() {
    this.draftState.uploadProgress = Progress.ERROR
  }

  getDocumentFilesRequested(_id: string) {
    this.draftState.getDocumentFilesProgress = Progress.WORK
    this.draftState.getDocumentFilesErrorDetail = undefined
    this.draftState.getDocumentFilesError = null
  }

  getDocumentFilesSucceed(list) {
    this.draftState.getDocumentFilesProgress = Progress.SUCCESS
    this.draftState.getDocumentFilesErrorDetail = undefined

    this.draftState.documentFiles = list
  }

  getDocumentFilesFailed(error: FetchError, errorDetail?: string) {
    this.draftState.getDocumentFilesProgress = Progress.ERROR
    this.draftState.getDocumentFilesError = error
    this.draftState.getDocumentFilesErrorDetail = errorDetail
  }

  deleteDocumentRequested(_id: string, _document_id: string) {
    this.draftState.deleteDocumentProgress = Progress.WORK
  }

  deleteDocumentSucccess() {
    this.draftState.deleteDocumentProgress = Progress.SUCCESS
  }

  deleteDocumentFailed() {
    this.draftState.deleteDocumentProgress = Progress.ERROR
  }
}

export const TripActions = createActionCreators(TripReducer)
export default TripActions
export const reducer = createReducerFunction(TripReducer, initialState)
