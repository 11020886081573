import { CompanyDadata, DealStatus, LogisticStatus, Product, Region, Team } from 'modules/domain/collection/types'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { ListResponse } from 'types/api.d'

export const fetchProducts = () => apiClient.get<Product[]>(endpoints.products())

export const fetchCompanies = (query: string) => apiClient.get<CompanyDadata[]>(endpoints.companyDadata(), { query })

export const fetchDealStatuses = () => apiClient.get<DealStatus[]>(endpoints.dealStatuses())

export const fetchTeams = () => apiClient.get<ListResponse<Team>>(endpoints.teams(), { page_size: 10000 })

export const fetchRegions = () => apiClient.get<Region[]>(endpoints.regions())

export const fetchLogisticStatuses = () => apiClient.get<LogisticStatus[]>(endpoints.logisticStatuses)
