import formatSortParam from 'helpers/formatSortParam'
import { DocumentWithTypeFile, RespFile } from 'modules/domain/types'
import { User, UserDTO, UserListRequestFilter, UserListRequestSorting } from 'modules/domain/user/types'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { ListResponse } from 'types/api.d'

export const getUsersList = (
  filter: UserListRequestFilter,
  sorting: UserListRequestSorting,
  page: number,
  pageSize?: number,
) => {
  try {
    return apiClient.get<ListResponse<User>>(endpoints.users(), {
      ...filter,
      sort: formatSortParam(sorting),
      page,
      page_size: pageSize,
    })
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Users list fetch error!', err)
    throw err
  }
}

export const getUser = (id: string) => {
  try {
    return apiClient.get<User>(endpoints.users(id))
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('User fetch error!', err)
    throw err
  }
}

export const addUser = (user: UserDTO) => {
  try {
    return apiClient.post<User>(endpoints.users(), user)
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('User create error!', err)
    throw err
  }
}

export const updateUser = (id: string, user: Partial<UserDTO>) => apiClient.put<User>(endpoints.users(id), user)

export const removeUser = (id: string) => apiClient.delete(endpoints.users(id))

export const verifyCoordinator = (id: string) => apiClient.post(endpoints.userVerifyCoordinator(id))
export const verifySecurity = (id: string) => apiClient.post(endpoints.userVerifySecurity(id))

export const blockUser = (id: string) => apiClient.post(endpoints.blockUser(id))

export const fetchDocuments = (id: string) => apiClient.get<RespFile[]>(endpoints.userFiles(id))

export const uploadFiles = (id: string, files: File[]) => {
  const promises = files.map(file => {
    const form = new FormData()
    form.append('uploaded_file', file)
    return apiClient
      .post<RespFile>(endpoints.userFiles(id), form)
      .then(results => ({
        status: 'fulfilled',
        results,
      }))
      .catch(err => ({ status: 'rejected', err }))
  })

  return promises
}

export const deleteDocument = (userId: string, fileId: string) => apiClient.delete(endpoints.userFiles(userId, fileId))

export const getDocumentTypes = (id: string) =>
  apiClient.get<ListResponse<DocumentType>>(endpoints.userDocumentTypes(id))

export const uploadFile = (id: string, document_type: string, file: File) => {
  const form = new FormData()
  form.append('uploaded_file', file)
  form.append('document_type', document_type)
  return apiClient.post<DocumentWithTypeFile>(endpoints.userDocuments(id), form)
}

export const getDocumentFiles = (id: string) =>
  apiClient.get<ListResponse<DocumentType>>(endpoints.userDocumentFiles(id))

export const deleteDocumentFile = (id: string, document_id: string) =>
  apiClient.delete(endpoints.userDeleteDocumentFile(id, document_id))
