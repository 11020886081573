import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import PageWrapper from 'views/components/PageWrapper/PageWrapper'
import CallStatisticsList from 'views/pages/CallStatistics/CallStatisticsList'
import Routes from 'views/pages/CallStatistics/routes'

const CallStatisticsPage = () => (
  <PageWrapper>
    <Switch>
      <Route path={Routes.List}>
        <CallStatisticsList />
      </Route>
      <Redirect to={Routes.List} />
    </Switch>
  </PageWrapper>
)

export default CallStatisticsPage
