import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { IconDescription, Typography, useAction } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import { stringifyUrl } from 'helpers/stringifyUrl'
import { BidStatus } from 'modules/domain/bid/types'
import UserSelectors from 'modules/domain/user/selectors'
import { ProfileType } from 'modules/domain/user/types'
import UserAddressActions from 'modules/domain/userAddress/duck'
import UserAddressSelectors from 'modules/domain/userAddress/selectors'
import { UserAddress } from 'modules/domain/userAddress/types'
import { WarehouseModal } from 'views/components/WarehouseModal/WarehouseModal'
import BidRoutes from 'views/pages/Bid/routes'
import { BinButton } from 'views/ui/BinButton/BinButton'
import { ConfirmationTooltip } from 'views/ui/ConfirmationTooltip/ConfirmationTooltip'

const AddressButtonWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  align-items: center;
  & > * + * {
    margin-top: 3px;
  }
  & a svg {
    margin-top: 5px;
  }
  & a svg path,
  & button svg path {
    fill: ${({ theme }) => theme.color.secondary300};
  }
  & > button {
    margin-top: 0;
  }
  & a:hover,
  & button:hover {
    & svg path {
      fill: ${({ theme }) => theme.color.primary600};
    }
  }
`

const Sure = styled.div`
  ${Typography.body2Default};
`

type Props = {
  isCarrier: boolean
  addressId: string
  userId: string
  profileType?: ProfileType
  setShown: React.Dispatch<React.SetStateAction<boolean>>
  address?: UserAddress
}

export const AddressButtons: React.FC<Props> = ({ addressId, userId, isCarrier, profileType, setShown, address }) => {
  const { t } = useTranslation('address')

  const deleteAction = useAction(UserAddressActions.deleteRequested)
  const deleteProgress = useSelector(state => UserAddressSelectors.deleteProgress(state, addressId))

  const [isConfirmVisible, setConfirmVisible] = useState<boolean>(false)
  const hideConfirm = useCallback(() => {
    setConfirmVisible(false)
  }, [setConfirmVisible])

  const user = useSelector(state => UserSelectors.user(state, userId))
  const isSeller = user?.profile?.profile_type === ProfileType.seller

  const query = useMemo(
    () =>
      isSeller
        ? {
            bid_type: 'purchase',
            status: BidStatus.published,
            sort_field: 'price_exw',
            sort_reversed: false,
            address: address?.address,
            latitude: address?.latitude,
            longitude: address?.longitude,
          }
        : {
            bid_type: 'sale',
            status: BidStatus.published,
            sort_field: 'price_cpt',
            sort_reversed: true,
            address: address?.address,
            latitude: address?.latitude,
            longitude: address?.longitude,
          },
    [address?.address, address?.latitude, address?.longitude, isSeller],
  )

  return (
    <AddressButtonWrapper>
      {address && (
        <Link
          to={stringifyUrl({
            url: BidRoutes.List,
            query,
          })}
          target="_blank"
          title={t('showPrices')}
        >
          <IconDescription />
        </Link>
      )}
      {!isCarrier && (
        <WarehouseModal
          userId={userId}
          addressId={addressId}
          type={profileType === ProfileType.seller ? 'loading' : 'unloading'}
          onClickEdit={() => {
            setShown(true)
          }}
        />
      )}
      <ConfirmationTooltip
        hideArrow
        visible={isConfirmVisible}
        placement="bottomRight"
        overlayProps={{
          header: t('addressDeleting'),
          body: <Sure>{t('areYouSureToDelete')}</Sure>,
          okText: t('common:delete'),
          okAction: () => {
            deleteAction(userId, addressId)
          },
          okProgress: deleteProgress,
          cancelText: t('common:cancel'),
          close: hideConfirm,
        }}
      >
        <div>
          <BinButton
            title={t('common:delete')}
            onClick={() => {
              setConfirmVisible(true)
            }}
          />
        </div>
      </ConfirmationTooltip>
    </AddressButtonWrapper>
  )
}
