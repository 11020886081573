import { useTranslation } from 'react-i18next'

import { AvailableLanguages } from 'types/entities'

const useLanguage = () => {
  const { i18n } = useTranslation()
  return i18n.language as AvailableLanguages
}

export default useLanguage
