import React from 'react'
import { useTranslation } from 'react-i18next'
import { CellProps } from 'react-table'

import { AdvancedHeadCell, Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import { getUserPhone } from 'helpers/getUserData'
import useFormatNumber from 'hooks/useFormatNumber'
import useMainParameter from 'hooks/useMainParameter'
import useUserName from 'hooks/useUserName'
import { Bid } from 'modules/domain/bid/types'
import { Comment } from 'views/pages/Bid/BidTable/Comment'
import { Phone } from 'views/ui/Phone/Phone'

export const BidsSortableHeadCell = AdvancedHeadCell<keyof Bid>()

export const BoldText = styled.div`
  ${Typography.body2Bold}
`

export const StyledText = styled.div`
  ${Typography.caption1Default}
`

export const Address = styled.div`
  ${Typography.caption1Default};
  text-align: right;
`

const BoldNumber = styled.div`
  text-align: right;
  letter-spacing: 0;
  ${Typography.body2Bold}
`

export function ProductCell({ row }: CellProps<Bid>) {
  const { product, last_comment, parameters } = row.original
  const parameter = useMainParameter(parameters)
  return (
    <>
      <BoldText>{product?.title || ''}</BoldText>
      <StyledText>{parameter}</StyledText>
      {last_comment && <Comment {...last_comment} />}
    </>
  )
}

export function DistanceCell({ row }: CellProps<Bid>) {
  const { distance, address } = row.original
  const formatNumber = useFormatNumber()
  return (
    <>
      <BoldNumber>{formatNumber(distance) || '-'}</BoldNumber>
      <Address>{address?.address || ''}</Address>
    </>
  )
}

export function CompanyCell({ cell: _cel, column: _col, row }: CellProps<Bid>) {
  const { company, owner } = row.values
  const name = useUserName(owner)
  return (
    <>
      <BoldText>{company.name}</BoldText>
      <StyledText>{name}</StyledText>
      <StyledText>
        <Phone phone={getUserPhone(owner)} />
      </StyledText>
    </>
  )
}

export const QuantityCell: React.FC<CellProps<Bid>> = ({ cell }) => {
  const { t } = useTranslation(['bid', 'common'])
  const formatNumber = useFormatNumber()
  return (
    <BoldNumber>
      {formatNumber(cell.value)} {t('common:ton')}
    </BoldNumber>
  )
}
export const QuantityCellBag: React.FC<CellProps<Bid>> = ({ cell }) => {
  const { t } = useTranslation(['bid', 'common'])
  const formatNumber = useFormatNumber()
  return (
    <BoldNumber>
      {formatNumber(cell.value)} {t('common:bag')}
    </BoldNumber>
  )
}

export const ExwCell: React.FC<CellProps<Bid>> = ({ cell }) => {
  const formatNumber = useFormatNumber()
  return <BoldNumber>{formatNumber(cell.value, true)}</BoldNumber>
}

const NormalNumber = styled.div`
  letter-spacing: 0;
  text-align: right;
  ${Typography.body2Default}
`

export const CptCell: React.FC<CellProps<Bid>> = ({ cell }) => {
  const formatNumber = useFormatNumber()
  return <NormalNumber>{formatNumber(cell.value, true)}</NormalNumber>
}

export const QuantityHeader: React.FC = () => {
  const { t } = useTranslation(['bid', 'common'])
  return (
    <div>
      <div>{t('list.tableHeaders.quantity')}</div>
    </div>
  )
}

export const ExwHeader: React.FC = () => {
  const { t } = useTranslation(['bid', 'common'])
  return (
    <div style={{ width: 70 }}>
      <div>{t('list.tableHeaders.price_exw')}</div>
    </div>
  )
}
export const ExwTaxHeader: React.FC = () => {
  const { t } = useTranslation(['bid', 'common'])
  return (
    <div style={{ width: 70 }}>
      <div>{t('list.tableHeaders.exw_tax_per_bag')}</div>
    </div>
  )
}
export const CptHeader: React.FC = () => {
  const { t } = useTranslation(['bid', 'common'])
  return (
    <div style={{ width: 70 }}>
      <div>{t('list.tableHeaders.price_cpt')}</div>
    </div>
  )
}
