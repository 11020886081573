import { Progress } from '@agro-club/agroclub-shared'

import { AddError, EntityMetadata, FetchError, RemoveError, RespFile, UpdateError } from 'modules/domain/types'
import { Dict } from 'types/generics.d'

export type Company = {
  id: string
  inn: string
  name: string
  created: string
  payment_delay_days: number
  prepayment?: keyof typeof Prepayment
  vat?: boolean
  cooperation_type_agent: boolean
  cooperation_type_trader: boolean
  state_reg_number?: string
}

export type CompanyBR = Company & {
  inn: string | null
}

export type CompanyListRequestFilter = {
  search?: string
  created_after?: string
  created_before?: string
}

export type CompanyListRequestSorting = {
  sort_field?: keyof Company
  sort_reversed?: boolean
}

export type CompanyState = {
  items: Dict<Company>
  meta: Dict<EntityMetadata<Company>>
  ids: string[]
  listFetchProgress: Progress
  listFetchError: FetchError | null
  listFetchErrorDetail?: string
  itemFetchProgress: Progress
  itemFetchError: FetchError | null
  addProgress: Progress
  addError: AddError | null
  addErrorDetail?: string
  addErrorFields?: Record<keyof Company, string[]>
  updateProgress: Progress
  updateError: UpdateError | null
  removeProgress: Progress
  removeError: RemoveError | null
  removeErrorDetail?: string

  filter: CompanyListRequestFilter
  sorting: CompanyListRequestSorting
  page: number
  total: number
  pageSize: number

  extraData: Dict<CompanyExtraData> // dict by company ID
  extraDataMeta: Dict<EntityMetadata<CompanyExtraData>> // dict by company ID
}

export enum Prepayment {
  yes = 'yes',
  no = 'no',
  unknown = 'unknown',
}

export type CompanyDTO = Partial<Company | CompanyBR>

export enum ActivityType {
  processing = 'processing ',
  export = 'export',
}

export type CompanyExtraData = {
  id: string
  company_id: string
  activity_type: ActivityType | ''
  employees_count: number
  established_at: string // "1995-02-21"
  has_agro_arbitration_cases: boolean | null
  arbitration_claims_sum: number | null
  has_enforcement_proceeding: boolean | null
  enforcement_proceeding_sum: number
  has_blocked_bank_accounts: boolean | null
  directors_companies_have_problems: boolean | null
  connected_companies_have_problems: boolean | null
  last_accounting_statements_date: string // '2017-01-01'
  revenue: number
  profit: number
  equity: number
  current_assets: number
  fixed_assets: number
  current_liabilities: number
  has_all_documents: boolean
  paid_us_without_delay_amount: number | null
  okved_code: string
  okved_text: string
  kontur_brief_report: RespFile | null
}

export type CompanyExtraDataDTO = {
  activity_type: CompanyExtraData['activity_type']
  has_agro_arbitration_cases: CompanyExtraData['has_agro_arbitration_cases']
  arbitration_claims_sum: CompanyExtraData['arbitration_claims_sum']
  has_blocked_bank_accounts: CompanyExtraData['has_blocked_bank_accounts']
  directors_companies_have_problems: CompanyExtraData['directors_companies_have_problems']
  connected_companies_have_problems: CompanyExtraData['connected_companies_have_problems']
  has_all_documents: CompanyExtraData['has_all_documents']
  paid_us_without_delay_amount: CompanyExtraData['paid_us_without_delay_amount']
}
