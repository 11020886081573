import { Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const TrailersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const TrailerItem = styled.div<{ archived?: boolean }>`
  ${({ archived }) => (archived ? 'opacity: 0.5;' : '')}
`

export const TrailerTitle = styled.div`
  ${Typography.body2Default};
`

export const TrailerDescription = styled.div`
  ${Typography.body2Default};
  color: ${({ theme }) => theme.color.secondary300};
`
