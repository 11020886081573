import React from 'react'
import { useTranslation } from 'react-i18next'

import { Icons } from '@agro-club/agroclub-shared'

import { VerifyList } from 'views/ui/types'
import {
  ActiveUser,
  NotVerified,
  VerifiedUser,
  VerifyCoordinator,
  VerifySecurity,
} from 'views/ui/VerifyUserStatus/styled'

type VerifyUsers = Omit<VerifyList, 'verifyCoordinatorAction' | 'verifySecurityAction' | 'blockUserAction'> & {
  noText?: boolean
}

const VerifyUserStatus: React.FC<VerifyUsers> = ({ isVerifyCoordinator, isVerifySecurity, isActive, noText }) => {
  const { t } = useTranslation('user')

  if (!isActive) {
    return (
      <ActiveUser>
        <Icons.IconCancelled />
        {!noText && t('blocked')}
      </ActiveUser>
    )
  }

  if (isVerifyCoordinator && isVerifySecurity) {
    return (
      <VerifiedUser>
        <Icons.IconCheckMark />
        {!noText && t('verify_user')}
      </VerifiedUser>
    )
  }

  return (
    <>
      {isVerifyCoordinator && (
        <VerifyCoordinator>
          <Icons.IconCheckMark />
          {!noText && t('verify_coordinator')}
        </VerifyCoordinator>
      )}
      {isVerifySecurity && (
        <VerifySecurity>
          <Icons.IconDefend />
          {!noText && t('verify_security')}
        </VerifySecurity>
      )}
      {!isVerifySecurity && !isVerifyCoordinator ? (
        <NotVerified>
          <Icons.IconCheckMark />
          {!noText && t('not_verify_user')}
        </NotVerified>
      ) : null}
    </>
  )
}

export default VerifyUserStatus
