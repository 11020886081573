import React from 'react'
import { useTranslation } from 'react-i18next'

import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import { SelectableCell } from 'views/components/CommonTableComponents/CommonTableComponents'
import { ActiveCell, PhoneCell } from 'views/pages/Carrier/CarrierTable/Cells'

const useColumns = () => {
  const { t } = useTranslation('carrier')
  return React.useMemo(
    () => [
      {
        Header: t('list.tableHeaders.full_name'),
        accessor: 'full_name' as const,
        Cell: SelectableCell,
      },
      {
        Header: t('list.tableHeaders.phone'),
        Cell: PhoneCell,
        accessor: 'phone' as const,
      },
      {
        Header: t('list.tableHeaders.company_name'),
        accessor: 'company_name' as const,
      },
      {
        Header: t('list.tableHeaders.cars_count'),
        accessor: 'cars_count' as const,
      },
      {
        Header: t('list.tableHeaders.team_name'),
        accessor: 'team_name' as const,
      },
      {
        Header: t('list.tableHeaders.is_active'),
        accessor: 'is_active' as const,
        Cell: ActiveCell,
      },
      {
        Header: t('list.tableHeaders.date_joined'),
        accessor: 'date_joined' as const,
        sortable: true,
        Cell: TComponents.DateCell,
      },
    ],
    [t],
  )
}

export default useColumns
