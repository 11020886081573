import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import { RespFile } from 'modules/domain/types'
import UserActions from 'modules/domain/user/duck'
import * as managers from 'modules/domain/user/managers'
import UserSelectors from 'modules/domain/user/selectors'
import { User } from 'modules/domain/user/types'
import { RequestError } from 'modules/errors'
import { updateLocationQuery } from 'modules/sagaHelpers'
import { ListResponse } from 'types/api.d'
import UserRoutes from 'views/pages/User/routes'

export const fetchList = function* () {
  try {
    let currentPage = yield select(UserSelectors.page)
    const filter = yield select(UserSelectors.filter)
    const filterForRequestManager = yield select(UserSelectors.filterForRequestManager)
    const sorting = yield select(UserSelectors.sorting)
    const pageSize = yield select(UserSelectors.pageSize)

    let response: ListResponse<User> = yield call(
      managers.getUsersList,
      filterForRequestManager,
      sorting,
      currentPage,
      pageSize,
    )
    const pages = Math.ceil(response.count / pageSize)
    if (pages !== 0 && pages < currentPage) {
      response = yield call(managers.getUsersList, filterForRequestManager, sorting, pages, pageSize)
      currentPage = pages
    }

    const { results, count, current } = response
    yield put(UserActions.listRequestSucceed(results, count, current))
    yield call(updateLocationQuery, UserRoutes.List, { page: currentPage, ...filter, ...sorting })
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(UserActions.listRequestFailed(errType))
  }
}

export const fetchUser = function* ({ payload: id }: ReturnType<typeof UserActions.itemRequested>) {
  try {
    const user: User = yield call(managers.getUser, id)
    yield put(UserActions.itemRequestSucceed(user))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(UserActions.itemRequestFailed(id, errType))
  }
}

export const addUser = function* ({ payload: dto }: ReturnType<typeof UserActions.addRequested>) {
  try {
    const user: User = yield call(managers.addUser, dto)
    yield put(UserActions.addSucceed(user))
  } catch (err) {
    const { type, detail, errors } = RequestError.parseError(err)
    yield put(UserActions.addFailed(type, detail, errors))
  }
}
export const updateUser = function* ({ payload: [id, dto] }: ReturnType<typeof UserActions.updateRequested>) {
  try {
    const user: User = yield call(managers.updateUser, id, dto)
    yield put(UserActions.updateSucceed(user))
  } catch (err) {
    const { type, detail, errors } = RequestError.parseError(err)
    yield put(UserActions.updateFailed(id, type, detail, errors))
  }
}

export const removeUser = function* ({ payload }: ReturnType<typeof UserActions.removeRequested>) {
  try {
    yield call(managers.removeUser, payload)
    yield put(UserActions.removeSucceed(payload))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(UserActions.removeFailed(payload, errType))
  }
}

export const verifyCoordinator = function* ({
  payload: id,
}: ReturnType<typeof UserActions.userVerifyCoordinatorRequested>) {
  try {
    yield call(managers.verifyCoordinator, id)
    yield put(UserActions.userVerifyCoordinatorSucceed(id))
  } catch (err) {
    yield put(UserActions.userVerifyCoordinatorFailed())
  }
}

export const verifySecurity = function* ({ payload: id }: ReturnType<typeof UserActions.userVerifySecurityRequested>) {
  try {
    yield call(managers.verifySecurity, id)
    yield put(UserActions.userVerifySecuritySucceed(id))
  } catch (err) {
    yield put(UserActions.userVerifySecurityFailed())
  }
}

export const blockUser = function* ({ payload: id }: ReturnType<typeof UserActions.userBlockRequested>) {
  try {
    yield call(managers.blockUser, id)
    yield put(UserActions.userBlockSucceed(id))
  } catch (err) {
    yield put(UserActions.userBlockFailed())
  }
}

export const uploadDocuments = function* ({
  payload: [id, files],
}: ReturnType<typeof UserActions.uploadDocumentsRequested>) {
  try {
    const res = yield all(managers.uploadFiles(id, files))
    const repFiles: RespFile[] = res.filter(r => r.status === 'fulfilled').map(r => r.results)
    yield put(UserActions.uploadDocumentsSucccess(id, repFiles))
  } catch (err) {
    yield put(UserActions.uploadDocumentsFailed(id))
  }
}

export const fetchDocuments = function* ({ payload: id }: ReturnType<typeof UserActions.documentsRequested>) {
  try {
    const files = yield call(managers.fetchDocuments, id)
    yield put(UserActions.documentsRequestSuccess(id, files))
  } catch (err) {
    yield put(UserActions.documentsRequestFailed(id))
  }
}

export const deleteDocument = function* ({
  payload: [userId, fileId],
}: ReturnType<typeof UserActions.deleteDocumentRequested>) {
  try {
    yield call(managers.deleteDocument, userId, fileId)
    yield put(UserActions.deleteDocumentSucccess(userId, fileId))
  } catch (err) {
    yield put(UserActions.deleteDocumentFailed(fileId))
  }
}

export const getDocumentTypes = function* ({ payload: id }: ReturnType<typeof UserActions.getDocumentTypesRequested>) {
  try {
    const response = yield call(managers.getDocumentTypes, id)
    yield put(UserActions.getDocumentTypesSucceed(id, response))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(UserActions.getDocumentTypesFailed(id, errType))
  }
}

export const uploadDocument = function* ({
  payload: [id, document_type, file],
}: ReturnType<typeof UserActions.uploadDocumentRequested>) {
  try {
    yield call(managers.uploadFile, id, document_type, file)
    yield put(UserActions.uploadDocumentSucccess(id))
  } catch (err) {
    const { type } = RequestError.parseError(err)
    const detail = err instanceof RequestError ? err?.errors?.uploaded_file?.[0] : undefined
    yield put(UserActions.uploadDocumentFailed(id, type, detail))
  }
}

export const getDocumentFiles = function* ({ payload: id }: ReturnType<typeof UserActions.getDocumentFilesRequested>) {
  try {
    const response = yield call(managers.getDocumentFiles, id)
    yield put(UserActions.getDocumentFilesSucceed(id, response))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(UserActions.getDocumentFilesFailed(id, errType))
  }
}

export const deleteTypedDocument = function* ({
  payload: [id, document_id],
}: ReturnType<typeof UserActions.deleteDocumentRequested>) {
  try {
    yield call(managers.deleteDocumentFile, id, document_id)
    yield put(UserActions.deleteTypedDocumentSucccess(id))
  } catch (err) {
    yield put(UserActions.deleteDocumentFailed(''))
  }
}

const UsersSaga = function* () {
  yield all([
    takeLatest(UserActions.itemRequested.type, fetchUser),

    takeLatest(UserActions.listRequested.type, fetchList),
    takeLatest(UserActions.filterUpdated.type, fetchList),

    takeLatest(UserActions.sortingUpdated.type, fetchList),
    takeLatest(UserActions.filterHasBeenReset.type, fetchList),
    takeLatest(UserActions.sortingHasBeenReset.type, fetchList),

    takeLatest(UserActions.addRequested.type, addUser),
    takeLatest(UserActions.updateRequested.type, updateUser),
    takeLatest(UserActions.removeRequested.type, removeUser),

    takeLatest(UserActions.userVerifyCoordinatorRequested.type, verifyCoordinator),
    takeLatest(UserActions.userVerifySecurityRequested.type, verifySecurity),
    takeLatest(UserActions.userBlockRequested.type, blockUser),

    takeLatest(UserActions.documentsRequested.type, fetchDocuments),
    takeLatest(UserActions.uploadDocumentsRequested.type, uploadDocuments),
    takeLatest(UserActions.deleteDocumentRequested.type, deleteDocument),

    takeLatest(UserActions.getDocumentTypesRequested.type, getDocumentTypes),
    takeLatest(UserActions.uploadDocumentRequested.type, uploadDocument),
    takeLatest(UserActions.getDocumentFilesRequested.type, getDocumentFiles),
    takeLatest(UserActions.deleteTypedDocumentRequested.type, deleteTypedDocument),
  ])
}

export default UsersSaga
