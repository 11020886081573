import React, { VFC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { CellProps, HeaderProps } from 'react-table'

import { Button, Spinner as SpinnerComponent, TableBodyCell, Typography } from '@agro-club/agroclub-shared'
import styled, { keyframes } from 'styled-components'

import useFormatDate from 'hooks/useFormatDate'
import useFormatNumber from 'hooks/useFormatNumber'
import { SelectableText } from 'views/ui/SelectableText/SelectableText'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  th[class^='HeadCell-'] {
    ${Typography.caption2Default}
  }
`

export const Filters = styled.div`
  background-color: ${({ theme }) => theme.color.onPrimaryLight};
  display: flex;
  flex-wrap: wrap;
  border-top: ${({ theme }) => `1px solid ${theme.color.outlineMid}`};
  padding: 0 24px 12px 36px;
  z-index: 2;
  & > *:not(:last-child) {
    margin-right: 16px;
  }
  & > * {
    margin-top: 12px;
  }
`

const appear = keyframes`
  to {
    opacity: 1;
  }
`

export const SpinnerWrapper = styled.div`
  margin: 20px auto;
  display: flex;
  justify-content: center;
  opacity: 0;
  animation: ${appear} 0.3s;
  animation-fill-mode: forwards;
  animation-delay: 0.3s;
`

export function Spinner() {
  return (
    <SpinnerWrapper>
      <SpinnerComponent />
    </SpinnerWrapper>
  )
}

export const ClearButton = styled(Button)`
  margin-top: 20px;
`

export const DateCell: React.FC<CellProps<any>> = ({ cell, column: _col, row: _ }) => {
  const formatDate = useFormatDate()
  return <>{formatDate(cell.value)}</>
}

export const DateTimeCell: React.FC<CellProps<any>> = ({ cell, column: _col, row: _ }) => {
  const formatDate = useFormatDate()
  return <>{formatDate(cell.value, true)}</>
}

const NumberEl = styled.div`
  ${Typography.body2Default};
  text-align: right;
`

export const FloatNumberCell: VFC<CellProps<any>> = ({ value }) => {
  const formatNumber = useFormatNumber()
  return <NumberEl>{formatNumber(value, true)}</NumberEl>
}
export const NumberCell: VFC<CellProps<any>> = ({ value }) => {
  const formatNumber = useFormatNumber()
  return <NumberEl>{formatNumber(value)}</NumberEl>
}

export const EmailPhoneHeader: VFC<HeaderProps<any>> = () => {
  const { t } = useTranslation('common')
  return (
    <span>
      {t('phone')} / {t('email')}
    </span>
  )
}

export const RelativeTableBodyCell = styled(TableBodyCell)`
  position: relative;
`

export const TableCellLink = styled(Link)`
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
`

export const SelectableCell: VFC<CellProps<any>> = ({ value }) => <SelectableText>{value}</SelectableText>
