import { Button } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const FlexHorizontal = styled.div`
  display: flex;
`

export const TwoColumnGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
`

export const FiveColumnGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 24px;
`

export const BottomPanel = styled.div`
  display: flex;
  justify-content: end;
`

export const DeleteButton = styled(Button)`
  background-color: ${({ theme }) => theme.color.accentDestruct16a};
  color: ${({ theme }) => theme.color.accentDestruct500};

  :hover {
    background-color: ${({ theme }) => theme.color.accentDestruct100};
  }
`
export const Gray = styled.div`
  display: inline;
  margin-left: 8px;
  font-weight: 500;
  color: ${props => props.theme.color.textSecondary};
`
export const AccordionContainer = styled.div`
  width: 566px;
  min-width: 566px;
  && > div > div:before {
    background-color: ${props => props.theme.color.secondary50};
  }
`
