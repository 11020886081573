import React from 'react'

import { Button, ButtonFullProps, IconCross } from '@agro-club/agroclub-shared'
import styled, { useTheme } from 'styled-components'

const StyledButton = styled(Button)`
  padding: 0;
  width: 24px;
  height: 24px;
`
export const CrossButton: React.FC<Partial<ButtonFullProps>> = props => {
  const theme = useTheme()
  return (
    <StyledButton size="small" intent="minor-action" {...props}>
      <IconCross fill={theme.color.secondary200} />
    </StyledButton>
  )
}
