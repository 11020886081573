import { Progress } from '@agro-club/agroclub-shared'
import { createSelector } from 'reselect'

import filterDistricts from 'helpers/filterDistricts'
import { Bid } from 'modules/domain/bid/types'
import { EntityMetadata } from 'modules/domain/types'
import { AppGlobalState } from 'modules/types'

const dict = (state: AppGlobalState) => state.bid.items
const ids = (state: AppGlobalState) => state.bid.ids
const item = (state: AppGlobalState, id: string): Bid | undefined => state.bid.items[id]
const meta = (state: AppGlobalState, id: string): EntityMetadata<Bid> =>
  state.bid.meta[id] || {
    fetchError: null,
    fetchProgress: Progress.IDLE,
    id,
    removeError: null,
    removeProgress: Progress.IDLE,
    updateError: null,
    updateProgress: Progress.IDLE,
  }
const itemFetchProgress = (state: AppGlobalState) => state.bid.itemFetchProgress
const listFetchProgress = (state: AppGlobalState) => state.bid.listFetchProgress
const listFetchError = (state: AppGlobalState) => state.bid.listFetchError
const listFetchErrorDetail = (state: AppGlobalState) => state.bid.listFetchErrorDetail
const list = createSelector(dict, ids, listFetchProgress, (dict, ids, progress) => {
  if (progress === Progress.WORK) {
    return []
  }
  const result: Bid[] = []
  for (const id of ids) {
    result.push(dict[id])
  }
  return result
})

const filter = (state: AppGlobalState) => state.bid.filter
const filterForRequestManager = (state: AppGlobalState) => {
  const { filter } = state.bid
  return filterDistricts(state, filter)
}
const sorting = (state: AppGlobalState) => state.bid.sorting
const page = (state: AppGlobalState) => state.bid.page
const total = (state: AppGlobalState) => state.bid.total
const updateProgress = (state: AppGlobalState) => state.bid.updateProgress
const removeProgress = (state: AppGlobalState) => state.bid.removeProgress
const addProgress = (state: AppGlobalState) => state.bid.addProgress
const hasNext = (state: AppGlobalState) => state.bid.total > state.bid.ids.length
const pageSize = (state: AppGlobalState) => state.bid.pageSize
const pages = (state: AppGlobalState) => Math.ceil(state.bid.total / state.bid.pageSize)

const lastAddedId = (state: AppGlobalState) => state.bid.lastAddedId

const changeStatusProgress = (state: AppGlobalState, id?: string) =>
  (id && state.bid.changeStatusProgress?.[id]) || Progress.IDLE

const userBidFilter = (state: AppGlobalState) => state.bid.userBidFilter
const userBidIds = (state: AppGlobalState, userId?: string) => (userId && state.bid.userBidIds?.[userId]) || []
const userBidFetchProgress = (state: AppGlobalState) => state.bid.userBidFetchProgress
const userBidList = (state: AppGlobalState, userId?: string) => {
  const progress = userBidFetchProgress(state)
  const ids = userBidIds(state, userId)
  const dictionary = dict(state)
  if (progress === Progress.WORK) {
    return []
  }
  const result: Bid[] = []
  for (const id of ids) {
    result.push(dictionary[id])
  }
  return result
}

const BidSelectors = {
  filter,
  filterForRequestManager,
  sorting,
  page,
  total,
  updateProgress,
  removeProgress,
  addProgress,
  item,
  meta,
  itemFetchProgress,
  listFetchProgress,
  listFetchError,
  listFetchErrorDetail,
  list,
  dict,
  ids,
  hasNext,
  pageSize,
  pages,

  lastAddedId,
  changeStatusProgress,

  userBidFilter,
  userBidList,
  userBidFetchProgress,
}

export default BidSelectors
