import React, { useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { useAction } from '@agro-club/agroclub-shared'

import CarsActions from 'modules/domain/cars/duck'
import PageWrapper from 'views/components/PageWrapper/PageWrapper'
import { CarsDetail } from 'views/pages/Cars/CarsDetail/CarsDetail'
import CarsList from 'views/pages/Cars/CarsList'
import Routes from 'views/pages/Cars/routes'

function CarsPage() {
  const resetFilter = useAction(CarsActions.filterResetWithoutRequest)

  useEffect(
    () => () => {
      resetFilter()
    },
    [resetFilter],
  )

  return (
    <PageWrapper>
      <Switch>
        <Route path={Routes.List} exact>
          <CarsList />
        </Route>
        <Route path={Routes.Detail} exact>
          <CarsDetail />
        </Route>
        <Redirect to={Routes.List} />
      </Switch>
    </PageWrapper>
  )
}

export default CarsPage
