import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import { LIST_PAGE_SIZE } from 'modules/constants'
import CarTripActions from 'modules/domain/carTrip/duck'
import * as managers from 'modules/domain/carTrip/managers'
import CarTripSelectors from 'modules/domain/carTrip/selectors'
import { CarTrip } from 'modules/domain/carTrip/types'
import { RequestError } from 'modules/errors'
import { updateLocationQuery } from 'modules/sagaHelpers'
import { ListResponse } from 'types/api.d'
import CarsRoutes from 'views/pages/Cars/routes'

export const fetchList = function* ({ payload: { carId, dealId } }: ReturnType<typeof CarTripActions.listRequested>) {
  try {
    const pageSize = dealId ? 1000 : LIST_PAGE_SIZE
    let currentPage = yield select(CarTripSelectors.page)

    let response: ListResponse<CarTrip> = yield call(managers.getList, { carId, dealId, page: currentPage }, pageSize)

    const pages = Math.ceil(response.count / pageSize)

    if (pages !== 0 && pages < currentPage) {
      response = yield call(managers.getList, { carId, dealId, page: pages }, pageSize)
      currentPage = pages
    }

    yield put(
      CarTripActions.listRequestSucceed(response.results, { carId, dealId, page: response.current }, response.count),
    )

    yield call(updateLocationQuery, CarsRoutes.Detail, { page: currentPage })
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(CarTripActions.listRequestFailed(type, detail))
  }
}

const CarTripSaga = function* () {
  yield all([takeLatest(CarTripActions.listRequested.type, fetchList)])
}

export default CarTripSaga
