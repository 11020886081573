import React from 'react'

import { Chip, ChipColor } from '@agro-club/agroclub-shared'

import { StrictRecord } from 'types/generics.d'

import * as Styled from './styles'

type FilterValue<TOption, TMulti = true> = TMulti extends false ? undefined | TOption : undefined | TOption | TOption[]

export function getMultiOptionsFilterValue<TOption>(
  value: TOption,
  filterValue: FilterValue<TOption>,
): FilterValue<TOption> {
  if (!filterValue) {
    return value
  }

  if (filterValue === value) {
    return undefined
  }

  const newFilterValue = Array.isArray(filterValue) ? [...filterValue] : [filterValue]

  if (newFilterValue.includes(value)) {
    newFilterValue.splice(newFilterValue.indexOf(value), 1)
    return newFilterValue.length > 1 ? newFilterValue : newFilterValue[0]
  }
  newFilterValue.push(value)
  return newFilterValue
}

export function isOptionActive(value: unknown, filterValue?: unknown | unknown[]): boolean {
  if (typeof filterValue !== 'undefined') {
    return Array.isArray(filterValue) ? filterValue.includes(value) : filterValue === value
  }

  return false
}

export const Filter: React.FC<{ title?: string }> = ({ children, title }) => (
  <Styled.StyledFilter>
    {title && <Styled.FilterTitle>{title}</Styled.FilterTitle>}
    <Styled.FilterOptions>{children}</Styled.FilterOptions>
  </Styled.StyledFilter>
)

type FilterValueObj<TShape, TField extends keyof TShape, TOption, TMulti = true> = StrictRecord<
  TShape,
  TField,
  FilterValue<TOption, TMulti>
>

export type FilterOption<TOption> = {
  value: TOption
  title: string | React.ReactElement
  bullet?: boolean
  color?: ChipColor
}
export type FilterProps<TShape, TField extends keyof TShape, TOption, TMulti = true> = {
  handleFilterChange: (filter: FilterValueObj<TShape, TField, TOption, TMulti>) => void
  title: string
  options: FilterOption<TOption>[]
  filterValue: FilterValueObj<TShape, TField, TOption, TMulti>
  multi?: boolean
}

export function TableFilter<TShape, TField extends keyof TShape, TOption, TMulti = true>(
  multi = true,
): React.FC<FilterProps<TShape, TField, TOption, TMulti>> {
  return function InnerTableFilter({ handleFilterChange, options, title, filterValue }) {
    const [field, value] = Object.entries(filterValue)[0] as [TField, FilterValue<TOption, TMulti>]
    const onClick = (newValue: TOption) => {
      let newFilterValue
      if (multi) {
        newFilterValue = getMultiOptionsFilterValue<TOption>(newValue, value)
      } else {
        newFilterValue = value === newValue ? undefined : newValue
      }
      handleFilterChange({ [field]: newFilterValue } as FilterValueObj<TShape, TField, TOption, TMulti>)
    }

    return (
      <Filter title={title}>
        {options.map(option => (
          <Chip
            value={option.value as unknown as string}
            onClick={onClick}
            active={isOptionActive(option.value, value)}
            key={`${option.value}`}
            color={option.color}
            bullet={option.bullet}
          >
            {option.title}
          </Chip>
        ))}
      </Filter>
    )
  }
}

export function TableFilterMulti<TShape, TField extends keyof TShape, TOption>(): React.FC<
  FilterProps<TShape, TField, TOption>
> {
  return TableFilter<TShape, TField, TOption>()
}

export function TableFilterSingle<TShape, TField extends keyof TShape, TOption>(): React.FC<
  FilterProps<TShape, TField, TOption, false>
> {
  return TableFilter<TShape, TField, TOption, false>(false)
}
