import React, { useRef } from 'react'

import { IconAdd } from '@agro-club/agroclub-shared'

import {
  AddFileButton,
  Ellipsis,
  FileInput,
  FilesContent,
  FilesHeader,
  FilesStyled,
  SmallText,
} from 'views/components/FilesByTypes/styled'
import Tooltip from 'views/ui/Tooltip/Tooltip'

interface Props {
  header: string
  onUpload(file: File): void
}

const FilesGroup: React.FC<Props> = ({ header, onUpload, children }) => {
  const ref = useRef<HTMLInputElement>(null)

  const addFile = () => {
    ref.current?.click()
  }

  const onChange = (event): void => {
    const [file] = [...event.target.files]
    onUpload(file)
  }

  return (
    <FilesStyled>
      <FilesHeader>
        <Tooltip placement="top" overlay={<SmallText>{header}</SmallText>}>
          <Ellipsis>{header}</Ellipsis>
        </Tooltip>
        <AddFileButton intent="minor-action" filled size="medium" onClick={addFile}>
          <IconAdd />
        </AddFileButton>
      </FilesHeader>
      {children && <FilesContent>{children}</FilesContent>}
      <FileInput type="file" ref={ref} onChange={onChange} />
    </FilesStyled>
  )
}

export default FilesGroup
