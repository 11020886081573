import React, { useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Progress, TextArea, useAction } from '@agro-club/agroclub-shared'

import useFormatDate from 'hooks/useFormatDate'
import CommentActions from 'modules/domain/comment/duck'
import CommentSelectors from 'modules/domain/comment/selectors'
import { CommentType } from 'modules/domain/comment/types'
import { CommentsList } from 'views/components/Comments/CommentsList'
import { useComments } from 'views/components/Comments/hooks'
import * as S from 'views/components/Comments/styled'

type Props = {
  url: string
  id: string
  type: CommentType
  isClosed?: boolean
  maxHeight?: number
  popSummary?: (v: string) => void
}

export const Comments: React.FC<Props> = props => {
  const { url, id, type, isClosed, popSummary } = props
  const { t } = useTranslation('common')
  const comment = useSelector(state => CommentSelectors.inputComment(state, id, type))
  const changeInputComment = useAction(CommentActions.changeInputComment)
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const formatDate = useFormatDate()

  const setComment = useCallback(
    value => {
      changeInputComment(id, value, type)
    },
    [id, type, changeInputComment],
  )

  const onSuccess = useCallback(() => {
    setComment('')
    textAreaRef?.current && (textAreaRef.current.value = '')
  }, [setComment])

  const { comments, addComment, adding, loading } = useComments(url, onSuccess)

  useEffect(() => {
    if (popSummary && comments?.length) {
      popSummary(formatDate(comments.slice(-1).pop()?.created, true))
    }
  }, [comments, formatDate, popSummary])

  return (
    <S.CommentWrapper>
      <CommentsList maxHeight={props.maxHeight} isLoading={loading === Progress.WORK} comments={comments} />
      {!isClosed && (
        <S.CommentTextAreaWrapper>
          <TextArea
            placeholder={t('leaveComment')}
            defaultValue={comment}
            ref={textAreaRef}
            onChange={e => {
              setComment(e.target.value)
            }}
          />
          <S.SendCommentButton
            onClick={() => {
              addComment(comment).catch()
            }}
            disabled={!comment}
            progress={adding}
            size="small"
            intent="primary"
            filled
          >
            {t('send')}
          </S.SendCommentButton>
        </S.CommentTextAreaWrapper>
      )}
    </S.CommentWrapper>
  )
}
