import React from 'react'

import { Typography } from '@agro-club/agroclub-shared'
import styled, { StyledProps, useTheme } from 'styled-components'

type CircleProps = StyledProps<{ bgColor: string }>
const Circle = styled.div<CircleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ theme }) => theme.color.onPrimaryLight};
  ${Typography.caption1Bold}
`

export const A: React.FC = () => {
  const theme = useTheme()
  return <Circle bgColor={theme.color.accentNotify}>A</Circle>
}

export const T: React.FC = () => {
  const theme = useTheme()
  return <Circle bgColor={theme.color.primary600}>T</Circle>
}
