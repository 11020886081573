import React from 'react'

import { CommentsItem } from 'views/components/Comments/CommentsItem'
import { Comment } from 'views/components/Comments/hooks'
import * as S from 'views/components/Comments/styled'

import { Spinner } from '../CommonTableComponents/CommonTableComponents'

type Props = {
  isLoading?: boolean
  comments: Comment[]
  maxHeight?: number
}

export const CommentsList: React.FC<Props> = ({ isLoading, comments, maxHeight }) => {
  const listRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (isLoading) return

    setTimeout(() => {
      listRef?.current?.scrollTo({ top: listRef?.current?.scrollHeight })
    }, 100)
  }, [comments.length, isLoading])

  const sortedComments = React.useMemo(
    () =>
      comments.map(comment => (
        <CommentsItem key={comment.id} text={comment.comment} date={comment.created} userName={comment.author} />
      )),
    [comments],
  )

  return sortedComments.length > 0 ? (
    <S.CommentsList maxHeight={maxHeight} ref={listRef}>
      {isLoading ? <Spinner /> : sortedComments}
    </S.CommentsList>
  ) : null
}
