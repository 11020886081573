import { generatePath } from 'react-router'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import { push } from 'connected-react-router'

import CompanyActions from 'modules/domain/company/duck'
import * as managers from 'modules/domain/company/managers'
import CompanySelectors from 'modules/domain/company/selectors'
import { Company, CompanyExtraData } from 'modules/domain/company/types'
import { RequestError } from 'modules/errors'
import { updateLocationQuery } from 'modules/sagaHelpers'
import { ListResponse } from 'types/api.d'
import CompanyRoutes from 'views/pages/Company/routes'

export const fetchList = function* () {
  try {
    let currentPage = yield select(CompanySelectors.page)
    const filter = yield select(CompanySelectors.filter)
    const sorting = yield select(CompanySelectors.sorting)
    const pageSize = yield select(CompanySelectors.pageSize)

    let response: ListResponse<Company> = yield call(managers.getList, filter, sorting, currentPage, pageSize)
    const pages = Math.ceil(response.count / pageSize)

    if (pages !== 0 && pages < currentPage) {
      response = yield call(managers.getList, filter, sorting, pages, pageSize)
      currentPage = pages
    }

    const { results, count, current } = response
    yield put(CompanyActions.listRequestSucceed(results, count, current))

    yield call(updateLocationQuery, CompanyRoutes.List, { page: currentPage, ...filter, ...sorting })
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(CompanyActions.listRequestFailed(type, detail))
  }
}

export const fetchItem = function* ({ payload: id }: ReturnType<typeof CompanyActions.itemRequested>) {
  try {
    const item: Company = yield call(managers.getItem, id)
    yield put(CompanyActions.itemRequestSucceed(item))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(CompanyActions.itemRequestFailed(id, type, detail))
  }
}

export const addItem = function* ({ payload: dto }: ReturnType<typeof CompanyActions.addRequested>) {
  try {
    const item: Company = yield call(managers.addItem, dto)
    yield put(CompanyActions.addSucceed(item))
    yield put(push(generatePath(CompanyRoutes.Detail, { id: item.id })))
  } catch (err) {
    const { type, detail, errors } = RequestError.parseError(err)
    yield put(CompanyActions.addFailed(type, detail, errors))
  }
}

export const updateItem = function* ({ payload: [id, dto] }: ReturnType<typeof CompanyActions.updateRequested>) {
  try {
    const item: Company = yield call(managers.updateItem, id, dto)
    yield put(CompanyActions.updateSucceed(item))
  } catch (err) {
    const { type, detail, errors } = RequestError.parseError(err)
    yield put(CompanyActions.updateFailed(id, type, detail, errors))
  }
}

export const removeItem = function* ({ payload }: ReturnType<typeof CompanyActions.removeRequested>) {
  try {
    yield call(managers.removeItem, payload)
    yield put(CompanyActions.removeSucceed(payload))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(CompanyActions.removeFailed(payload, type, detail))
  }
}

export const fetchExtraData = function* ({ payload: id }: ReturnType<typeof CompanyActions.extraDataRequested>) {
  try {
    const item: CompanyExtraData = yield call(managers.getExtraData, id)
    yield put(CompanyActions.extraDataRequestSucceed(item))
  } catch (err) {
    const { type, detail } = RequestError.parseError(err)
    yield put(CompanyActions.extraDataRequestFailed(id, type, detail))
  }
}

export const updateExtraData = function* ({
  payload: [companyId, id, dto],
}: ReturnType<typeof CompanyActions.updateExtraRequested>) {
  try {
    const item: CompanyExtraData = yield call(managers.putExtraData, companyId, id, dto)
    yield put(CompanyActions.updateExtraSucceed(companyId, item))
  } catch (err) {
    const { type, detail, errors } = RequestError.parseError(err)
    yield put(CompanyActions.updateExtraFailed(companyId, type, detail, errors))
  }
}

const CompanySaga = function* () {
  yield all([
    takeLatest(CompanyActions.itemRequested.type, fetchItem),
    takeLatest(CompanyActions.listRequested.type, fetchList),
    takeLatest(CompanyActions.filterUpdated.type, fetchList),
    takeLatest(CompanyActions.sortingUpdated.type, fetchList),
    takeLatest(CompanyActions.filterHasBeenReset.type, fetchList),
    takeLatest(CompanyActions.sortingHasBeenReset.type, fetchList),

    takeLatest(CompanyActions.addRequested.type, addItem),
    takeLatest(CompanyActions.updateRequested.type, updateItem),
    takeLatest(CompanyActions.removeRequested.type, removeItem),

    takeLatest(CompanyActions.extraDataRequested.type, fetchExtraData),
    takeLatest(CompanyActions.updateExtraRequested.type, updateExtraData),
  ])
}

export default CompanySaga
