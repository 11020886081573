import { Link } from 'react-router-dom'

import { Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import { box } from 'views/styled/common'

export const Form = styled.form`
  ${box};
  display: inline-block;
  width: 684px;
`

export const OneLineField = styled.div`
  display: grid;
  grid-template-columns: 96px 202px;
  grid-column-gap: 8px;
`

export const Fio = styled.div`
  display: grid;
  column-gap: 24px;
  grid-auto-flow: column;
  width: fit-content;
`

export const CompanyHelp = styled.div`
  ${Typography.caption1Default};
  color: ${({ theme }) => theme.color.onSurfaceMidEmphasys};
`

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.color.primary600};

  &:hover {
    text-decoration: underline;
  }
`

export const TwoColumn = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 24px;
`

export const Column = styled.div`
  flex-grow: 1;
  display: grid;
  grid-gap: 16px;
`

export const AddressColumn = styled(Column)`
  grid-template-rows: 1fr auto;
  & ymaps {
    max-width: 306px;
    border-radius: 8px;
  }
`

export const BlockTitle = styled.div`
  ${Typography.subheadlineCAPS};
  color: ${({ theme }) => theme.color.textSecondary};
`

export const LegalTypeWrapper = styled.div`
  margin-bottom: 38px;
`

export const InputWrapper = styled.div`
  & > * {
    width: 306px;
  }
`
