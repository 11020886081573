import { generatePath, useHistory, useLocation } from 'react-router-dom'

import { LocationStateFrom } from 'types/location'
import LogisticsRoutes from 'views/pages/Logistics/routes'

export const useLogisticsDetailBack = () => {
  const location = useLocation<LocationStateFrom>()
  const history = useHistory()
  const defaultBackPath = generatePath(LogisticsRoutes.List)
  const from = location.state?.from

  return () => {
    history.push({
      pathname: from?.pathname || defaultBackPath,
      search: from?.search || undefined,
    })
  }
}

export const useLogisticsCarsBack = (dealId: string) => {
  const location = useLocation<LocationStateFrom>()
  const history = useHistory()

  return () => {
    history.push({
      pathname: generatePath(LogisticsRoutes.Detail, { id: dealId }),
      state: location.state,
    })
  }
}
