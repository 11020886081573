import { Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const AverageInner = styled.div`
  width: 566px;
  height: 232px;
  padding: 16px 51px 16px 16px;
`

export const GeneralInner = styled.div`
  width: 566px;
  min-width: 200px;
  height: 180px;
  border-bottom: 1px solid #e5e5e5;
  padding: 16px 51px 24px 16px;
`

export const TextAlignRight = styled.div`
  text-align: right;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  &:not(:last-child) {
    margin-right: 44px;
  }
`

export const BorderBottom = styled.div`
  z-index: 2;
  border-bottom: ${({ theme }) => `1px solid ${theme.color.outlineMid}`};
`

export const Row = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
`

export const HeaderTitle = styled.div`
  ${Typography.subheadlineBold}
`

export const Title = styled.div`
  color: ${({ theme }) => theme.color.textSecondary};
  ${Typography.subheadlineDefault};
`
export const Value = styled.div`
  margin-top: 4px;
  ${Typography.subheadlineSemibold};
`
export const Content = styled.div`
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-direction: row;
`

export const RightPanelWrapper = styled.div`
  margin-top: 16px;
  margin-right: 32px;
  width: 566px;
  height: 412px;
  background-color: ${({ theme }) => theme.color.backgroundSecondary};
  border-radius: 8px;
`
