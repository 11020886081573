import { REACT_APP_LOCALE } from 'env'
import { AvailableLanguages } from 'types/entities'

const formatPhone = (phone: string) => {
  if (REACT_APP_LOCALE !== AvailableLanguages.ru) {
    return phone
  }
  const match = phone?.match(/^(\+7)(\d{3})(\d{3})(\d{2})(\d{2})$/)
  if (match) {
    return `${match[1]}\u00A0${match[2]}\u00A0${match[3]}\u00A0${match[4]}\u00A0${match[5]}`
  }
  return phone || ''
}

export default formatPhone
