import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import styled from 'styled-components'

import useFormatNumber from 'hooks/useFormatNumber'
import { Deal, DealBid } from 'modules/domain/deal/types'
import { LogisticsDeal } from 'modules/domain/logisticsDeal/types'
import { WarehouseDTO } from 'modules/domain/types'
import UserAddressSelectors from 'modules/domain/userAddress/selectors'
import { DeliveryMethod } from 'views/components/WarehouseProps/DeliveryMethod'
import { Key, Value } from 'views/components/WarehouseProps/styled'
import { DangerousHtml } from 'views/ui/DangerousHtml/DangerousHtml'

const Properties = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-auto-rows: max-content;
  row-gap: 12px;
  column-gap: 20px;
`

const removeSeconds = (time?: string | null) => {
  if (time) {
    const timeArr = time.split(':')
    if (timeArr?.length === 3) {
      timeArr.pop()
      return timeArr.join(':')
    }
    return time
  }
  return '-'
}

const formatTime = (from?: string | null, to?: string | null) => {
  if (!from && !to) {
    return '-'
  }
  return `${removeSeconds(from)}&nbsp;-&nbsp;${removeSeconds(to)}`
}

export type Props = {
  deal?: Deal | LogisticsDeal | undefined
  type: 'loading' | 'unloading'
  addressId?: string
}

export const WarehouseProps: React.FC<Props> = ({ deal, type, addressId }) => {
  const { t } = useTranslation('warehouse')
  const sellerAddressId = addressId || (deal?.sale_bid as DealBid)?.address?.id
  const customerAddressId = addressId || (deal?.purchase_bid as DealBid)?.address?.id
  const sellerWarehouse = useSelector(state => UserAddressSelectors.addressById(state, sellerAddressId))
  const customerWarehouse = useSelector(state => UserAddressSelectors.addressById(state, customerAddressId))
  const formatNumber = useFormatNumber()

  const formatWeekends = useCallback(
    (works_on_weekend: WarehouseDTO['works_on_weekend']) =>
      works_on_weekend ? t(`yesNoUnknown.${works_on_weekend}`) : t('yesNoUnknown.unknown'),
    [t],
  )

  const formatVehicleType = useCallback(
    (vehicle_type: WarehouseDTO['vehicle_type']) =>
      vehicle_type?.map(vehicle => t(`vehicles:vehicleTypes.${vehicle}`))?.join(', ') || '-',
    [t],
  )

  const formatLoaderType = useCallback(
    (loader_type: WarehouseDTO['loader_type']) =>
      loader_type?.map(loader => t(`address:loaderTypes.${loader}`))?.join(', ') || '-',
    [t],
  )

  return (
    <Properties>
      {type === 'loading' && (
        <>
          <Key>{t('fields.gates_height')}</Key>
          <Value>
            {formatNumber(sellerWarehouse.gates_height) || '-'} {t('common:m')}
          </Value>

          <Key>{t('fields.loader_type')}</Key>
          <Value>{formatLoaderType(sellerWarehouse.loader_type)}</Value>

          <Key>{t('fields.scales_capacity')}</Key>
          <Value>
            {formatNumber(sellerWarehouse.scales_capacity) || '-'} {t('common:t')}
          </Value>

          <Key>{t('fields.scales_remoteness')}</Key>
          <Value>
            {formatNumber(sellerWarehouse.scales_remoteness) || '-'} {t('common:km')}
          </Value>

          <Key>{t('fields.scales_length')}</Key>
          <Value>
            {formatNumber(sellerWarehouse.scales_length) || '-'} {t('common:m')}
          </Value>

          <Key>{t('fields.volume_per_day.seller')}</Key>
          <Value>
            {formatNumber(sellerWarehouse.volume_per_day) || '-'} {t('common:tDay')}
          </Value>

          <Key>{t('fields.vehicle_type')}</Key>
          <Value>{formatVehicleType(sellerWarehouse.vehicle_type)}</Value>

          <Key>{t('fields.schedule.seller')}</Key>
          <Value>
            <DangerousHtml html={`${formatTime(sellerWarehouse.schedule_from, sellerWarehouse.schedule_to)}`} />
            <div>
              {t('fields.works_on_weekend')}: {formatWeekends(sellerWarehouse.works_on_weekend)}
            </div>
          </Value>
          <DeliveryMethod
            car_delivery={sellerWarehouse.car_delivery}
            railway_delivery={sellerWarehouse.railway_delivery}
          />
        </>
      )}

      {type === 'unloading' && (
        <>
          <Key>{t('fields.vehicle_type')}</Key>
          <Value>{formatVehicleType(customerWarehouse.vehicle_type)}</Value>

          <Key>{t('fields.is_dump_truck')}</Key>
          <Value>{customerWarehouse.is_dump_truck ? t('common:yes') : t('common:no')}</Value>

          <Key>{t('fields.unloading_type')}</Key>
          <Value>
            {customerWarehouse.unloading_type ? t(`vehicles:unloadingType.${customerWarehouse.unloading_type}`) : '-'}
          </Value>

          <Key>{t('fields.volume_per_day.customer')}</Key>
          <Value>
            {formatNumber(customerWarehouse.volume_per_day) || '-'} {t('common:tDay')}
          </Value>

          <Key>{t('fields.schedule.customer')}</Key>
          <Value>
            <DangerousHtml html={`${formatTime(customerWarehouse.schedule_from, customerWarehouse.schedule_to)}`} />
            <div>
              {t('fields.works_on_weekend')}: {formatWeekends(customerWarehouse.works_on_weekend)}
            </div>
          </Value>
          <DeliveryMethod
            car_delivery={customerWarehouse.car_delivery}
            railway_delivery={customerWarehouse.railway_delivery}
          />
        </>
      )}
    </Properties>
  )
}
