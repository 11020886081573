import React, { VFC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'
import { Link } from 'react-router-dom'
import { CellProps } from 'react-table'

import { Typography, defaultTheme } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import { Bid } from 'modules/domain/bid/types'
import { PotentialBid } from 'modules/domain/potentialBid/types'
import {
  CompanyCell,
  CptCell,
  CptHeader,
  DistanceCell,
  ExwCell,
  ExwHeader,
  ExwTaxHeader,
  ProductCell,
  QuantityCell,
  QuantityCellBag,
  QuantityHeader,
  StyledText,
} from 'views/components/CommonBidTable/CommonBidTable'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import UserRoutes from 'views/pages/User/routes'

const StatusCell: VFC<CellProps<Bid | PotentialBid>> = ({ value }) => {
  const { t } = useTranslation('bid')
  return <StyledText>{value ? t(`bidStatuses.${value}`) : ''}</StyledText>
}

const TeamCell: VFC<CellProps<Bid | PotentialBid>> = ({ row }) => (
  <StyledText>{row.original.owner?.profile?.team?.name || ''}</StyledText>
)

export const CreatorLink = styled(Link)`
  ${Typography.body2Default}
  color: ${defaultTheme.color.primary600};

  &:hover {
    text-decoration: underline;
  }
`

const CreatorCell: VFC<CellProps<Bid | PotentialBid>> = ({ row }) => {
  const creator = row.original.created_by
  return <CreatorLink to={generatePath(UserRoutes.Detail, { id: creator.id })}>{creator.full_name || ''}</CreatorLink>
}

export default function useColumns() {
  const { t } = useTranslation('bid')
  const visibleColumns = React.useMemo(
    () => [
      {
        Header: t('list.tableHeaders.product'),
        accessor: 'product' as const,
        Cell: ProductCell,
      },
      {
        Header: t('list.tableHeaders.status'),
        accessor: 'status' as const,
        Cell: StatusCell,
      },
      {
        Header: t('common:team'),
        accessor: 'team',
        Cell: TeamCell,
      },
      {
        Header: t('list.tableHeaders.createdBy'),
        accessor: 'created_by',
        Cell: CreatorCell,
      },
      {
        Header: QuantityHeader,
        Cell: QuantityCellBag,
        accessor: 'quantity_in_bags' as const,
        sortable: true,
      },
      {
        Header: QuantityHeader,
        Cell: QuantityCell,
        accessor: 'quantity' as const,
        sortable: true,
      },
      {
        Header: ExwHeader,
        Cell: ExwCell,
        sortable: true,
        accessor: 'price_exw' as const,
      },
      {
        Header: ExwTaxHeader,
        Cell: ExwCell,
        sortable: true,
        accessor: 'tax_from_exw' as const,
      },
      {
        Header: CptHeader,
        Cell: CptCell,
        sortable: true,
        accessor: 'price_cpt' as const,
      },
      {
        Header: t('list.tableHeaders.distance'),
        accessor: 'distance' as const,
        sortable: true,
        Cell: DistanceCell,
        headerHorizontalAlign: 'right',
      },
      {
        Header: t('list.tableHeaders.company'),
        accessor: 'company' as const,
        Cell: CompanyCell,
      },
      {
        Header: t('list.tableHeaders.created'),
        Cell: TComponents.DateCell,
        accessor: 'created' as const,
        sortable: true,
      },
      {
        Header: t('list.tableHeaders.modified'),
        Cell: TComponents.DateCell,
        accessor: 'modified' as const,
        sortable: true,
      },
    ],
    [t],
  )
  const hiddenColumns: (keyof Bid | keyof PotentialBid)[] = useMemo(
    () => ['id', 'parameters_description', 'last_comment', 'address'],
    [],
  )

  const columnsAll = useMemo(
    () => [
      ...visibleColumns,
      ...hiddenColumns.map(col => ({
        Header: col,
        accessor: col,
        hidden: true,
      })),
    ],
    [hiddenColumns, visibleColumns],
  )

  return { columnsAll, hiddenColumns, visibleColumnsLength: visibleColumns.length }
}
