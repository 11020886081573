import React from 'react'

import { TableHeadCell, TableHeadCellProps } from './Table'

export type SortableHeadCellProps<T> = TableHeadCellProps & {
  hidden?: boolean
  sortable?: boolean
  id: T
  testId?: string
  sortField?: T
  sortDesc?: boolean
  onChange: ({ sort_field, sort_reversed }: { sort_field?: T; sort_reversed?: boolean }) => void
  className?: string
  emphatic?: boolean
  transparent?: boolean
}

export function AdvancedHeadCell<T>(): React.FC<SortableHeadCellProps<T>> {
  return function InnerAdvancedHeadCell({ id, sortable, children, sortDesc, sortField, testId, onChange, ...props }) {
    const handleChange = () => {
      let sort_field: typeof id | undefined = id
      let sort_reversed: boolean | undefined = false
      if (sortField === id) {
        if (sortDesc) {
          sort_field = undefined
          sort_reversed = undefined
        } else {
          sort_reversed = true
        }
      }
      onChange({ sort_field, sort_reversed })
    }

    if (!sortable) {
      return (
        <TableHeadCell testId={testId} {...props}>
          {children}
        </TableHeadCell>
      )
    }

    const isSorted = sortField === id

    return (
      <TableHeadCell
        testId={testId}
        isSorted={isSorted}
        desc={isSorted && sortDesc}
        onClick={handleChange}
        sortable={sortable}
        {...props}
      >
        {children}
      </TableHeadCell>
    )
  }
}
