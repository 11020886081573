import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import CallStatisticsActions from 'modules/domain/callStatistics/duck'
import * as managers from 'modules/domain/callStatistics/managers'
import { CallStatistics } from 'modules/domain/callStatistics/types'
import { RequestError } from 'modules/errors'
import { updateLocationQuery } from 'modules/sagaHelpers'
import { ListResponse } from 'types/api'
import CallStatisticsRoutes from 'views/pages/CallStatistics/routes'

import CallStatisticsSelectors from './selectors'

function* fetchList() {
  try {
    let currentPage = yield select(CallStatisticsSelectors.page)
    const filter = yield select(CallStatisticsSelectors.filter)
    const filterForRequestManager = yield select(CallStatisticsSelectors.filterForRequestManager)
    const sorting = yield select(CallStatisticsSelectors.sorting)
    const pageSize = yield select(CallStatisticsSelectors.pageSize)

    let response: ListResponse<CallStatistics> = yield call(
      managers.getCallStatisticsList,
      filterForRequestManager,
      sorting,
      currentPage,
      pageSize,
    )
    const pages = Math.ceil(response.count / pageSize)
    if (pages !== 0 && pages < currentPage) {
      response = yield call(managers.getCallStatisticsList, filterForRequestManager, sorting, pages, pageSize)
      currentPage = pages
    }
    const { results, count, current } = response

    yield put(CallStatisticsActions.listRequestSucceed(results, count, current))
    yield call(updateLocationQuery, CallStatisticsRoutes.List, { page: currentPage, ...filter, ...sorting })
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(CallStatisticsActions.listRequestFailed(errType))
  }
}

function* fetchAverageStatistics() {
  try {
    const response = yield call(managers.getAverageStatistics)
    yield put(CallStatisticsActions.statisticsAverageRequestedSuccess(response))
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err)
  }
}

function* fetchGeneralStatistics() {
  try {
    const response = yield call(managers.getGeneralStatistics)
    yield put(CallStatisticsActions.statisticsGeneralRequestedSuccess(response))
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err)
  }
}

export default function* CallStatisticsSaga() {
  yield all([
    takeLatest(CallStatisticsActions.listRequested.type, fetchList),
    takeLatest(CallStatisticsActions.filterUpdated.type, fetchList),
    takeLatest(CallStatisticsActions.sortingUpdated.type, fetchList),
    takeLatest(CallStatisticsActions.filterHasBeenReset.type, fetchList),
    takeLatest(CallStatisticsActions.sortingHasBeenReset.type, fetchList),

    takeLatest(CallStatisticsActions.statisticsAverageRequested.type, fetchAverageStatistics),
    takeLatest(CallStatisticsActions.statisticsGeneralRequested.type, fetchGeneralStatistics),
  ])
}
