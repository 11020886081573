import { Button, IconCross, Typography } from '@agro-club/agroclub-shared'
import styled, { StyledProps } from 'styled-components'

type ThemedProps = StyledProps<Record<string, unknown>>

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const FilterTitle = styled.div`
  color: ${({ theme }: ThemedProps) => theme.color.onSurfaceMidEmphasys};
  margin-bottom: 8px;
  ${Typography.caption1Default};
`

export const StyledFilter = styled.div`
  flex-shrink: 0;

  &:not(:first-child) {
    //margin-left: 24px;
  }
`

export const FilterOptions = styled.div`
  display: flex;

  > button {
    &:not(:first-child) {
      margin-left: 8px;
    }
  }
`

export const StyledResetButton = styled(Button)`
  padding: 0;
  height: 32px;
  white-space: nowrap;
`

export const StyledCross = styled(IconCross)`
  fill: ${({ theme }) => theme.color.primary500};
`

export const IconWrapper = styled.i`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 24px;
  height: 24px;
`

export const ResetFilterWrapper = styled.div`
  display: flex;
  align-items: end;
`
