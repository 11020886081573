import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { generatePath, useHistory, useLocation, useParams } from 'react-router-dom'

import { Button, Progress, useHelmet } from '@agro-club/agroclub-shared'

import useLocationFrom from 'hooks/useLocationFrom'
import { useCompany } from 'modules/domain/company/hooks'
import CompanySelectors from 'modules/domain/company/selectors'
import { LocationStateFrom } from 'types/location'
import Item404 from 'views/layouts/Item404/Item404'
import ItemGenericError from 'views/layouts/ItemGenericError/ItemGenericError'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import { CompanyData } from 'views/pages/Company/CompanyDetail/CompanyData'
import { CompanyExtraData } from 'views/pages/Company/CompanyDetail/CompanyExtraData'
import { CompanySubTitle } from 'views/pages/Company/CompanyDetail/CompanySubTitle'
import { useCompanyGoBack } from 'views/pages/Company/CompanyDetail/hooks'
import { ButtonsWrapper, TwoColumn } from 'views/pages/Company/CompanyDetail/styled'
import CompanyRoutes from 'views/pages/Company/routes'
import * as Header from 'views/ui/Header/Header'

export function CompanyDetail() {
  const history = useHistory()
  const location = useLocation<LocationStateFrom>()
  const { t } = useTranslation('company')
  const { id } = useParams<{ id: string }>()
  const [fetchProgress, company] = useCompany(id)
  const meta = useSelector(state => CompanySelectors.meta(state, id))
  const goBack = useCompanyGoBack()
  const loading = () => <ItemLoadingLayout id={id} onBack={goBack} />
  const error404 = () => <Item404 id={id} onBack={goBack} title={t('errors.notFoundTitle')} />
  const errorUnknown = () => <ItemGenericError id={id} onBack={goBack} title={t('errors.unknownErrorTitle')} />
  const from = useLocationFrom()

  const extraData = useSelector(state => CompanySelectors.extraData(state, id))

  useHelmet({ title: company?.name || '' })

  if (fetchProgress === Progress.ERROR) {
    if (meta.fetchError === 'not_found') {
      return error404()
    }
    return errorUnknown()
  }

  if (fetchProgress === Progress.WORK || !company) {
    return loading()
  }

  const report = extraData?.kontur_brief_report?.uploaded_file

  return (
    <>
      <Layout.Header hasBottomBorder>
        <Header.Root
          onClickBack={goBack}
          rightBlock={
            <ButtonsWrapper>
              {report && (
                <a target="_blank" rel="noreferrer" href={report}>
                  <Button filled intent="primary" size="small">
                    {t('common:downloadReport')}
                  </Button>
                </a>
              )}
              <Button
                onClick={() => {
                  history.push({
                    pathname: generatePath(CompanyRoutes.Edit, { id }),
                    state: { from: location.state?.from ? { ...location.state?.from } : { ...from } },
                  })
                }}
                filled
                intent="primary"
                size="small"
              >
                {t('common:edit')}
              </Button>
            </ButtonsWrapper>
          }
        >
          <Header.Title compact title={company.name} />
          <CompanySubTitle company={company} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <TwoColumn>
          <div>
            <CompanyData company={company} />
          </div>
          <div>
            <CompanyExtraData />
          </div>
        </TwoColumn>
      </Layout.Content>
    </>
  )
}
