import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { CheckboxSelect } from '@agro-club/agroclub-shared'

import { BidStatus } from 'modules/domain/bid/types'
import { Filter } from 'views/components/TableFilters/TableFilters'

type Props = {
  onChange: (id: string) => void
  selectedIds?: string[]
  onClear: () => void
  isDisabled?: boolean
}

export const BidStatusFilter: React.FC<Props> = props => {
  const { onChange, onClear, selectedIds = [], isDisabled = false } = props
  const { t } = useTranslation('bid')
  const options = useMemo(
    () => Object.keys(BidStatus).map(status => ({ id: status, title: t(`bidStatuses.${status}`) })),
    [t],
  )

  const isSome = selectedIds.length > 1

  return (
    <Filter title={t('list.tableHeaders.status')}>
      <CheckboxSelect
        isDisabled={isDisabled}
        options={options}
        placeholder={isSome ? t('someStatuses') : t('allStatuses')}
        selected={selectedIds}
        onChange={onChange}
        size="small"
        additionalStyles={{
          control: {
            width: 186,
          },
        }}
        onClear={onClear}
        singleSelected={selectedIds.length === 1 ? t(`bidStatuses.${selectedIds[0]}`) : undefined}
        isSearchable
      />
    </Filter>
  )
}
