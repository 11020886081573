import React, { useMemo, useState } from 'react'

import { HelmetContext, HelmetProps } from '@agro-club/agroclub-shared'

export const HelmetProvider: React.FC = props => {
  const [helmet, updateHelmet] = useState<HelmetProps>({})
  const helmetProviderData = useMemo(() => ({ helmet, updateHelmet }), [helmet, updateHelmet])

  return <HelmetContext.Provider value={helmetProviderData}>{props.children}</HelmetContext.Provider>
}
