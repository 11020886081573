import 'rc-tooltip/assets/bootstrap_white.css'

import React from 'react'

import RcTooltip from 'rc-tooltip'
import { TooltipProps } from 'rc-tooltip/lib/Tooltip'
import { createGlobalStyle } from 'styled-components'

export const TooltipGlobalStyle = createGlobalStyle`
  .rc-tooltip {
    background-color: transparent !important;
    padding: 0 !important;
    opacity: 1 !important;
  }

  .default-tooltip-overlay.rc-tooltip-placement-right .rc-tooltip-arrow,
  .default-tooltip-overlay.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
  .default-tooltip-overlay.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
    border: none;
  }

  .default-tooltip-overlay.rc-tooltip-placement-top .rc-tooltip-arrow,
  .default-tooltip-overlay.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
  .default-tooltip-overlay.rc-tooltip-placement-topRight .rc-tooltip-arrow {
    border: none;
  }

  .default-tooltip-overlay.rc-tooltip-placement-left .rc-tooltip-arrow,
  .default-tooltip-overlay.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
  .default-tooltip-overlay.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
    border: none;
  }

  .default-tooltip-overlay.rc-tooltip-placement-bottom .rc-tooltip-arrow,
  .default-tooltip-overlay.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
  .default-tooltip-overlay.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
    border: none;
  }

  .default-tooltip-overlay .rc-tooltip-inner {
    border: none;
    color: white;
    background-color: ${({ theme }) => theme.color.secondary600};
    border-radius: 4px;
    padding: 8px;
    min-height: unset;
    max-width: 200px;
  }

  .modal-tooltip-overlay .rc-tooltip-inner {
    border: none;
    padding: 0;
    background-color: ${({ theme }) => theme.color.onPrimaryLight};
    border-radius: 16px;
    box-shadow: 0 16px 48px rgba(0, 0, 0, 0.16);
  }

  .modal-tooltip-overlay.rc-tooltip-placement-right .rc-tooltip-arrow,
  .modal-tooltip-overlay.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
  .modal-tooltip-overlay.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
    border-right-color: ${({ theme }) => theme.color.onPrimaryLight};
  }

  .modal-tooltip-overlay.rc-tooltip-placement-top .rc-tooltip-arrow,
  .modal-tooltip-overlay.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
  .modal-tooltip-overlay.rc-tooltip-placement-topRight .rc-tooltip-arrow {
    border-top-color: ${({ theme }) => theme.color.onPrimaryLight};
  }

  .modal-tooltip-overlay.rc-tooltip-placement-left .rc-tooltip-arrow,
  .modal-tooltip-overlay.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
  .modal-tooltip-overlay.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
    border-left-color: ${({ theme }) => theme.color.onPrimaryLight};
  }

  .modal-tooltip-overlay.rc-tooltip-placement-bottom .rc-tooltip-arrow,
  .modal-tooltip-overlay.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
  .modal-tooltip-overlay.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
    border-bottom-color: ${({ theme }) => theme.color.onPrimaryLight};
  }

  .modal-tooltip-overlay.hide-arrow .rc-tooltip-arrow {
    display: none;
  }
`

interface Props extends TooltipProps {
  isModal?: boolean
  hideArrow?: boolean
}

const Tooltip: React.FC<Props> = props => {
  const { isModal, children, hideArrow, ...other } = props
  const overlayClassName = [
    isModal ? 'modal-tooltip-overlay' : 'default-tooltip-overlay',
    ...[hideArrow ? ['hide-arrow'] : []],
  ].join(' ')

  return (
    <RcTooltip overlayClassName={overlayClassName} {...other}>
      {children}
    </RcTooltip>
  )
}

export default Tooltip
