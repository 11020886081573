import React, { useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { BackButton, useAction } from '@agro-club/agroclub-shared'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import formatTime from 'helpers/formatTime'
import useTimer from 'hooks/useTimer'
import i18n from 'i18n'
import AuthActions from 'modules/domain/auth/duck'
import AuthSelectors from 'modules/domain/auth/selectors'

import {
  BackButtonWrapper,
  CodeWrapper,
  ErrorWrapper,
  FormWrapper,
  InputStyled,
  InputWrapper,
  RetryBtn,
  RetryDelay,
  SubmitButton,
  SubmitButtonWrapper,
} from './styled'

type FormFields = {
  code: string
}

const validationSchema = Yup.object({
  code: Yup.string()
    .required(i18n.t('validation:code_required'))
    .test('len', i18n.t('validation:code_length'), val => !!val && val.length === 6),
})

const LoginForm: React.FC = () => {
  const submitAction = useAction(AuthActions.tokenRequested)
  const error = useSelector(AuthSelectors.getError)
  const { t } = useTranslation(['auth', 'common'])
  const progress = useSelector(AuthSelectors.tokenRequestProgress)
  const goBack = useAction(AuthActions.goToGetCodeStep)
  const getCodeAction = useAction(AuthActions.codeRequested)
  const codeError = useSelector(AuthSelectors.getCodeError)
  const phone = useSelector(AuthSelectors.getPhone)
  const codeProgress = useSelector(AuthSelectors.codeRequestedProgress)

  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  const formik = useFormik<FormFields>({
    initialValues: { code: '' },
    validationSchema,
    onSubmit: submitAction,
  })

  const handleSubmit = useCallback(() => {
    if (formik.isValid && formik.dirty) {
      formik.submitForm()
    }
  }, [formik])

  const { seconds, startTimer } = useTimer({
    initialSeconds: 60,
  })
  const time = formatTime(seconds)

  const getCode = useCallback(() => {
    getCodeAction({ phone })
    startTimer()
  }, [phone, startTimer, getCodeAction])

  return (
    <FormWrapper
      onSubmit={e => {
        e.preventDefault()
        handleSubmit()
      }}
    >
      <CodeWrapper>
        <InputWrapper>
          <InputStyled
            label={t('auth:smsCode')}
            placeholder=""
            type="text"
            {...formik.getFieldProps('code')}
            onChange={({ target: { value } }) => {
              formik.setFieldValue('code', value.replace(/\s*/g, ''))
            }}
            invalid={formik.touched.code && !!formik.errors.code}
            errorText={formik.errors.code}
          />
        </InputWrapper>
        {seconds > 0 ? (
          <RetryDelay>{time}</RetryDelay>
        ) : (
          <RetryBtn disabled={codeProgress === 'WORK'} onClick={getCode}>
            {t('auth:sendAnotherCode')}
          </RetryBtn>
        )}
      </CodeWrapper>
      <SubmitButtonWrapper>
        <BackButtonWrapper>
          <BackButton size="big" onClick={goBack} />
        </BackButtonWrapper>
        <SubmitButton
          size="big"
          type="submit"
          intent="primary"
          filled
          disabled={!formik.isValid || !formik.dirty}
          progress={progress}
        >
          {t('auth:send')}
        </SubmitButton>
      </SubmitButtonWrapper>
      <ErrorWrapper>{error || codeError}</ErrorWrapper>
    </FormWrapper>
  )
}

export default LoginForm
