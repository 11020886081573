import { useSelector } from 'react-redux'
import { generatePath, useHistory, useLocation } from 'react-router-dom'

import { stringifyUrl } from 'helpers/stringifyUrl'
import CarrierSelectors from 'modules/domain/carrier/selectors'
import { LocationStateFrom } from 'types/location'
import CarrierRoutes from 'views/pages/Carrier/routes'

export const useCarrierListLocation = () => {
  const page = useSelector(CarrierSelectors.page)
  const filter = useSelector(CarrierSelectors.filter)
  const sorting = useSelector(CarrierSelectors.sorting)

  const search = stringifyUrl({ url: '', query: { ...filter, ...sorting, page } })

  return {
    pathname: CarrierRoutes.List,
    search,
  }
}

export const useCarrierGoBack = () => {
  const location = useLocation<LocationStateFrom>()
  const defaultBackLocation = useCarrierListLocation()
  const from = location.state?.from
  const history = useHistory()

  return () => {
    history.push({
      pathname: from?.pathname || defaultBackLocation.pathname,
      search: from?.pathname ? from?.search : defaultBackLocation.search,
    })
  }
}

export const useCarrierEditGoBack = (id: string) => {
  const location = useLocation<LocationStateFrom>()
  const history = useHistory()

  return () => {
    history.push({
      pathname: generatePath(CarrierRoutes.Detail, { id }),
      state: location.state,
    })
  }
}
