enum CarrierRoutes {
  List = '/carriers',
  Detail = '/carriers/:id(\\d+)',
  Add = '/carriers/add',
  Edit = '/carriers/:id(\\d+)/edit',
  CarAdd = '/carriers/:id(\\d+)/add-car',
  CarEdit = '/carriers/:id(\\d+)/edit-car/:car_id(\\d+)',
  CarDetail = '/carriers/:id(\\d+)/car/:car_id(\\d+)',
}

export default CarrierRoutes
