import 'rc-notification/assets/index.css'

import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { IconCheck, IconCross, Progress, Typography } from '@agro-club/agroclub-shared'
import Notification from 'rc-notification'
import { NotificationInstance } from 'rc-notification/es/Notification'
import styled, { createGlobalStyle, useTheme } from 'styled-components'

import usePrevious from 'hooks/usePrevious'

export const NotificationGlobalStyle = createGlobalStyle`
  .rc-notification-notice {
    margin: 0 0 8px;
    padding: 12px 16px;
    background-color: #FFFFFF;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
    border-radius: 16px;
  }

  .rc-notification {
    top: 8px !important;
    z-index: 10000;
  }
`

let notification: NotificationInstance | null = null
Notification.newInstance(
  {
    maxCount: 5,
  },
  n => {
    notification = n
  },
)

const NotificationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const NotificationText = styled.span`
  ${Typography.subheadlineSemibold};
  padding-left: 10px;
`

export const useNotificationProgress = (progress?: Progress, textSuccess?: string, textError?: string) => {
  const { t } = useTranslation('common')
  const theme = useTheme()

  const contentSuccess = useMemo(
    () => (
      <NotificationWrapper>
        <IconCheck width="20px" height="15px" fill={theme.color.primary600} />
        <NotificationText>{textSuccess || t('notifySuccess')}</NotificationText>
      </NotificationWrapper>
    ),
    [t, textSuccess, theme.color.primary600],
  )

  const contentError = useMemo(
    () => (
      <NotificationWrapper>
        <IconCross width="20px" fill={theme.color.accentDestructive} />
        <NotificationText>{textError || t('notifyError')}</NotificationText>
      </NotificationWrapper>
    ),
    [t, textError, theme.color.accentDestructive],
  )

  const notifySuccess = useCallback(() => {
    if (!notification) return
    notification.notice({
      content: contentSuccess,
      duration: 2,
    })
  }, [contentSuccess])

  const notifyError = useCallback(() => {
    if (!notification) return
    notification.notice({
      content: contentError,
      duration: 3,
    })
  }, [contentError])

  const prevProgress = usePrevious(progress)

  useEffect(() => {
    if (progress === Progress.SUCCESS && prevProgress === Progress.WORK) {
      notifySuccess()
    }
  }, [notifySuccess, prevProgress, progress])

  useEffect(() => {
    if (progress === Progress.ERROR && prevProgress === Progress.WORK) {
      notifyError()
    }
  }, [notifyError, prevProgress, progress])
}
