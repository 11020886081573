import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Button, DatePicker, Input, Progress, SimpleSelect, useAction } from '@agro-club/agroclub-shared'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import env from 'env'
import formatDateWithoutLocale from 'helpers/formatDateWithoutLocale'
import useDateFnsLocale from 'hooks/useDateFnsLocale'
import { useNotificationProgress } from 'hooks/useNotificationProgress'
import usePrevious from 'hooks/usePrevious'
import { SERVER_DATE_FORMAT } from 'modules/constants'
import { useProducts } from 'modules/domain/collection/hooks'
import DealSelectors from 'modules/domain/deal/selectors'
import SpecificationActions from 'modules/domain/specification/duck'
import SpecificationSelectors from 'modules/domain/specification/selectors'
import { useUserAddress } from 'modules/domain/userAddress/hooks'
import * as S from 'views/components/AddSpecification/styled'
import FilesByTypes from 'views/components/FilesByTypes/FilesByTypes'
import { CancelButton } from 'views/styled/common'
import { FormItem } from 'views/ui/FormItem/FormItem'
import { ModalSmall } from 'views/ui/ModalSmall/ModalSmall'
import { NumberInput } from 'views/ui/NumberInput/NumberInput'

type EditableFields = {
  number: string
  signed_date: string
  address: string
  product: string
  price: string
  quantity: string
  deadline: string
  files?: string[]
}

type Props = {
  isOpen: boolean
  onCancel: () => void
  owner: string
  company: string
  disableProduct?: boolean
}
const currency = env.REACT_APP_CURRENCY_SYMBOL

const validationSchema = Yup.object().shape({
  number: Yup.string().min(2).max(10).required('Required'),
  address: Yup.string().required('Required'),
  signed_date: Yup.string().required('Required'),
  price: Yup.string().min(1).required('Required'),
  quantity: Yup.string().min(1).max(7).required('Required'),
  deadline: Yup.string().min(1).required('Required'),
})

export const SpecificationModal: React.FC<Props> = ({ disableProduct, owner, company, isOpen, onCancel }) => {
  const { t } = useTranslation('specification')
  const [, list] = useUserAddress(owner)
  const [, products] = useProducts()
  const locale = useDateFnsLocale()
  const { id } = useParams<{ id: string }>()

  const addRequested = useAction(SpecificationActions.AddRequested)
  const addResetErrors = useAction(SpecificationActions.AddResetErrors)
  const uploadDocumentRequested = useAction(SpecificationActions.uploadDocumentRequested)
  const deleteOwnerDocument = useAction(SpecificationActions.deleteOwnerDocument)

  const deal = useSelector(state => DealSelectors.item(state, id))
  const ownerSpecificationDocuments = useSelector(SpecificationSelectors.ownerSpecificationDocuments)

  const filesForForm = ownerSpecificationDocuments?.map(el => String(el.pk))
  const ownerFilesList = ownerSpecificationDocuments?.map(file => ({
    id: file.pk,
    original_filename: file.original_filename,
    uploaded_file: file.uploaded_file,
    document_type: 'specification_file',
  }))

  const productList = useMemo(
    () =>
      products?.map(el => ({
        id: el.id,
        title: el.title,
      })) || [],
    [products],
  )

  const optionList = useMemo(
    () =>
      list?.map(el => ({
        id: el.id,
        title: el.address,
      })) || [],
    [list],
  )

  const progress = useSelector(SpecificationSelectors.specificationAddProgress)
  const errorFields = useSelector(SpecificationSelectors.specificationAddErrorFields)

  const prevProgress = usePrevious(progress)
  const prevIsOpen = usePrevious(isOpen)
  useNotificationProgress(progress, t('specification_add_success'))

  const address = deal?.purchase_bid.address.id || ''
  const product = deal?.product.id || ''
  const formik = useFormik<EditableFields>({
    validationSchema,
    initialValues: {
      number: '',
      address,
      signed_date: '',
      product,
      price: '',
      quantity: '',
      deadline: '',
      files: [],
    },
    enableReinitialize: false,
    validateOnBlur: false,

    onSubmit: () => {
      if (!formik.isValid) {
        return
      }

      const { number, signed_date, address, product, price, quantity, deadline } = formik.values
      addRequested({
        owner,
        company,
        number,
        address,
        signed_date: formatDateWithoutLocale(signed_date, SERVER_DATE_FORMAT),
        product,
        price,
        quantity,
        deadline: formatDateWithoutLocale(deadline, SERVER_DATE_FORMAT),
        files: filesForForm,
      })
    },
  })

  useEffect(() => {
    if (prevIsOpen && !isOpen) {
      formik.resetForm()
      deleteOwnerDocument()
      addResetErrors()
    }
  }, [prevIsOpen, isOpen, formik, addResetErrors, deleteOwnerDocument])

  useEffect(() => {
    if (prevProgress === Progress.WORK && progress === Progress.SUCCESS) {
      formik.resetForm()
      addResetErrors()
      deleteOwnerDocument()
      onCancel()
    }
  }, [progress, prevProgress, formik, addResetErrors, onCancel, deleteOwnerDocument])

  const content = useMemo(
    () => (
      <S.FieldWrapper>
        <S.TwoFields>
          <label htmlFor="number">{t('form.number')}</label>
          <FormItem
            fieldName="number"
            errorFields={errorFields}
            render={additionalProps => (
              <Input
                inputSize="small"
                onChange={(event, value) => {
                  formik.setFieldValue('number', value)
                }}
                value={formik.values.number}
                {...additionalProps}
              />
            )}
          />
        </S.TwoFields>
        <S.TwoFields>
          <label htmlFor="address">{t('form.address')}</label>
          <FormItem
            fieldName="address"
            errorFields={errorFields}
            render={additionalProps => (
              <SimpleSelect
                size="small"
                onChange={value => formik.setFieldValue('address', value)}
                options={optionList}
                value={formik.values.address}
                isClearable
                {...additionalProps}
              />
            )}
          />
        </S.TwoFields>
        <S.TwoFields>
          <label htmlFor="signed_date">{t('form.signed_date')}</label>
          <FormItem
            fieldName="signed_date"
            errorFields={errorFields}
            render={additionalProps => (
              <DatePicker
                size="small"
                date={formik.values.signed_date}
                onChange={value => formik.setFieldValue('signed_date', value)}
                reactDatePickerProps={{
                  locale,
                  dateFormat: 'P',
                  placeholderText: t('common:datePlaceholder'),
                }}
                {...additionalProps}
              />
            )}
          />
        </S.TwoFields>
        <S.TwoFields>
          <label htmlFor="product">{t('form.product')}</label>
          <FormItem
            fieldName="product"
            errorFields={errorFields}
            render={additionalProps => (
              <SimpleSelect
                size="small"
                onChange={value => {
                  formik.setFieldValue('product', value)
                }}
                value={formik.values.product}
                options={productList}
                additionalStyles={{ menuList: { height: 350 } }}
                isDisabled={disableProduct}
                isClearable
                {...additionalProps}
              />
            )}
          />
        </S.TwoFields>
        <S.TwoFields>
          <label htmlFor="quantity">{t('form.quantity')}</label>
          <FormItem
            fieldName="quantity"
            errorFields={errorFields}
            render={additionalProps => (
              <NumberInput
                inputSize="small"
                onChange={value => {
                  formik.setFieldValue('quantity', value)
                }}
                value={formik.values.quantity}
                {...additionalProps}
              />
            )}
          />
        </S.TwoFields>
        <S.TwoFields>
          <label htmlFor="price">{t('form.price', { currency })}</label>
          <FormItem
            fieldName="price"
            errorFields={errorFields}
            render={additionalProps => (
              <NumberInput
                inputSize="small"
                onChange={value => {
                  formik.setFieldValue('price', value)
                }}
                value={formik.values.price}
                {...additionalProps}
              />
            )}
          />
        </S.TwoFields>
        <S.TwoFields>
          <label htmlFor="deadline">{t('form.deadline')}</label>
          <FormItem
            fieldName="deadline"
            errorFields={errorFields}
            render={additionalProps => (
              <DatePicker
                size="small"
                date={formik.values.deadline}
                onChange={value => formik.setFieldValue('deadline', value)}
                reactDatePickerProps={{
                  locale,
                  dateFormat: 'P',
                  placeholderText: t('common:datePlaceholder'),
                }}
                {...additionalProps}
              />
            )}
          />
        </S.TwoFields>
        <S.FileField>
          <FormItem
            fieldName="files"
            errorFields={errorFields}
            render={additionalProps => (
              <FilesByTypes
                files={ownerFilesList}
                onDelete={id => deleteOwnerDocument(id)}
                onUpload={(_id, file) => uploadDocumentRequested(file)}
                types={[{ slug: 'specification_file', label: t('form.specification_file') }]}
                {...additionalProps}
              />
            )}
          />
        </S.FileField>
      </S.FieldWrapper>
    ),
    [
      t,
      errorFields,
      formik,
      optionList,
      locale,
      productList,
      disableProduct,
      ownerFilesList,
      deleteOwnerDocument,
      uploadDocumentRequested,
    ],
  )

  return (
    <ModalSmall
      width={566}
      isOpen={isOpen}
      close={onCancel}
      title={t('specification')}
      content={content}
      footer={
        <>
          <Button
            onClick={() => {
              formik.dirty && formik.submitForm().catch()
            }}
            disabled={!formik.isValid || !formik.dirty}
            intent="primary"
            progress={progress}
            filled
          >
            {t('common:add')}
          </Button>
          <CancelButton onClick={onCancel} intent="minor-action">
            {t('common:cancel')}
          </CancelButton>
        </>
      }
    />
  )
}
