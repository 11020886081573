import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Checkbox, useAction } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import UserActions from 'modules/domain/user/duck'
import UserSelectors from 'modules/domain/user/selectors'

const cbClassName = 'no-tasks-filter-cb'
const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;

  & .${cbClassName} {
    height: 32px;
  }
`

export function NoTasksFilter() {
  const { t } = useTranslation(['user'])
  const filterUpdated = useAction(UserActions.filterUpdated)
  const { is_linked_to_active_task, ...restFilter } = useSelector(UserSelectors.filter)

  const handleChange = (_, isChecked) => {
    filterUpdated({ ...restFilter, is_linked_to_active_task: isChecked ? false : undefined })
  }

  return (
    <Wrapper>
      <Checkbox
        onChange={handleChange}
        label={t('noTasks')}
        className={cbClassName}
        isChecked={is_linked_to_active_task === false}
      />
    </Wrapper>
  )
}
