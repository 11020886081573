// const stripTrailingSlash = (url: string) => (url.endsWith('/') ? url.slice(0, -1) : url)
const addTrailingSlash = (url: string) => (url.endsWith('/') ? url : `${url}/`)

const urlMaker = (url: string) => (id?: number | string) => {
  if (typeof id === 'undefined') {
    return addTrailingSlash(url)
  }
  return `${addTrailingSlash(url)}${id}/`
}

export const endpoints = {
  login: () => '/auth/login/',
  getCode: () => '/auth/get_code/',
  refreshToken: () => '/auth/token-refresh/',

  users: urlMaker('/users/'),
  userFiles: (userId: string, fileId?: string) => `/users/${userId}/files/${fileId ? `${fileId}/` : ''}`,
  carriersDocuments: (id: string) => `/logistics/carriers/${id}/documents/`,
  profile: () => '/users/me/',
  myTasks: () => '/users/me/tasks/',

  userBidList: (userId: string) => `/users/${userId}/bids/`,
  userComments: (userId: string) => `/users/${userId}/comments/`,
  userAddress: (userId: string, addressId?: string) => `/users/${userId}/addresses/${addressId ? `${addressId}/` : ''}`,
  blockUser: (userId: string) => `/users/${userId}/block/`,
  userTasks: (userId: string) => `/users/${userId}/tasks/`,
  userContacts: (userId: string, contactId?: string) => `/users/${userId}/contacts/${contactId ? `${contactId}/` : ''}`,
  userCars: (userId: string) => `/users/${userId}/cars/`,
  userVerifyCoordinator: (userId: string) => `/users/${userId}/verify-as-coordinator/`,
  userVerifySecurity: (userId: string) => `/users/${userId}/verify-as-security/`,
  specificationForPurchase: (ownerId: string, productSlug: string) =>
    `/users/${ownerId}/specifications/?status=open&product=${productSlug}`,

  userDocumentTypes: (id: string) => `/users/${id}/document_types/`,
  userDocumentFiles: (id: string) => `/users/${id}/documents/`,
  userDeleteDocumentFile: (id: string, document_id: string) => `/users/${id}/documents/${document_id}/`,
  userDocuments: (id: string) => `/users/${id}/documents/`,

  carDrivers: (carId: string, driverId?: string) =>
    `/logistics/cars/${carId}/drivers/${driverId ? `${driverId}/` : ''}`,
  allDrivers: (driverId: string) => `/logistics/drivers/${driverId}/`,
  carTrips: (carId: string) => `/logistics/cars/${carId}/trips/`,
  carrierDocumentTypes: (id: string) => `logistics/carriers/${id}/document_types/`,
  carrierDocumentFiles: (id: string) => `logistics/carriers/${id}/documents/`,
  carrierDeleteDocumentFile: (id: string, document_id: string) => `/logistics/carriers/${id}/documents/${document_id}/`,
  carChangeStatus: (carId: string) => `/logistics/cars/${carId}/change_status/`,

  company: urlMaker('/companies/'),
  employee: (companyId: string) => `/companies/${companyId}/employees/`,
  companyDadata: () => '/companies/suggest/',
  companyExtraData: (companyId: string, id?: string) => `/companies/${companyId}/extra-data/${id ? `${id}/` : ''}`,
  legalTypes: () => '/companies/legal-types/',
  userLegalTypes: (userId: string) => `/users/${userId}/legal-types/`,

  bid: urlMaker('/bids/'),
  bidChangeStatus: (id: string) => `/bids/${id}/change_status/`,
  deals: (bidId: string) => `/bids/${bidId}/deals/`,

  potentialBid: urlMaker('/products/product-stocks/'),
  userPotentialBidList: (userId: string) => `/users/${userId}/product-stocks/`,

  products: urlMaker('/products/'),
  teams: () => '/teams/',

  deal: urlMaker('/deals/'),
  dealActual: urlMaker('/deals/actual/'),
  dealClosed: urlMaker('/deals/closed/'),
  dealStatuses: () => '/deals/statuses/',
  dealShipment: (dealId: string, shipmentId?: string) =>
    `/deals/${dealId}/shipments/${shipmentId ? `${shipmentId}/` : ''}`,
  dealComment: (dealId: string) => `/deals/${dealId}/comments/`,
  dealStatus: (dealId: string) => `/deals/${dealId}/change_status/`,
  dealClose: (dealId: string) => `/deals/${dealId}/close/`,
  dealUpdatePurchaseBid: (dealId: string) => `/deals/${dealId}/update_purchase_bid/`,
  dealUpdateSaleBid: (dealId: string) => `/deals/${dealId}/update_sale_bid/`,
  dealFiles: (dealId: string, fileId?: string) => `/deals/${dealId}/files/${fileId ? `${fileId}/` : ''}`,
  dealsCloseReasons: () => '/deals/closed_reasons/',
  specificationDealBinding: (dealId: string) => `/deals/${dealId}/attach-purchase-specification/`,

  task: urlMaker('/tasks/'),
  taskDeals: urlMaker('/tasks/deals/'),
  taskAssignees: () => '/tasks/assignees/',
  completeTask: (taskId: string) => `/tasks/${taskId}/complete/`,

  dashboardResponses: () => '/dashboard/responses/',
  dashboardMatches: () => '/dashboard/matches/',
  dashboardNew: () => '/dashboard/new-bids/',
  dashboardNewUsers: () => '/dashboard/new-users/',
  dashboardCalls: () => '/dashboard/calls/',

  regions: () => '/geo/regions/',

  car: urlMaker('/logistics/cars'),
  carFiles: (carId: string, fileId?: string) => `/logistics/cars/${carId}/files/${fileId ? `${fileId}/` : ''}`,
  relevantCars: (dealId: string) => `/logistics/deals/${dealId}/relevant-cars/`,
  involvedCars: (dealId: string) => `/logistics/deals/${dealId}/involved-cars/`,

  logisticsDeal: urlMaker('/logistics/deals/'),
  trip: urlMaker('/logistics/trips'),
  carrier: urlMaker('/logistics/carriers/'),
  userCalls: (userId: string) => `/users/${userId}/calls/`,
  carDeals: (carId: string) => `/logistics/cars/${carId}/deals/`,
  logisticStatuses: '/logistics/deals/statuses/',
  logisticChangeStatus: (id: string) => `/logistics/deals/${id}/change_status/`,

  trailers: urlMaker('/logistics/trailers/'),
  carrierTrailers: (carrierId: string) => `/logistics/carriers/${carrierId}/trailers`,
  archiveTrailers: (id: string) => `/logistics/trailers/${id}/change_status/`,

  calls: urlMaker('/twilio/calls'),
  makeCall: urlMaker('/vats/make_call'),
  accounts: urlMaker('/vats/vats_accounts'),
  accountsTwilio: urlMaker('/twilio/accounts'),
  tripComments: (tripId: string) => `/logistics/trips/${tripId}/comments/`,

  tripsDocumentTypes: (id: string) => `logistics/trips/${id}/document_types/`,
  tripsDocumentFiles: (id: string) => `logistics/trips/${id}/documents/`,
  tripsDocuments: (id: string) => `/logistics/trips/${id}/documents/`,
  tripsDeleteDocumentFile: (id: string, document_id: string) => `/logistics/trips/${id}/documents/${document_id}/`,

  callStatistics: urlMaker('/vats/call-statistics/vats-accounts/'),
  callStatisticsAverage: urlMaker('/vats/call-statistics/average/'),
  callStatisticsGeneral: urlMaker('/vats/call-statistics/general/'),

  files: urlMaker('/files/'),

  specification: urlMaker('/specifications/'),

  executors: (id: string) => `/deals/${id}/executors/`,
  executorsByType: (type: string) => `/users/${type}/`,

  taxesCalculation: (id: string) => `/deals/${id}/taxes-calculation/`,
}
