import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useAction } from '@agro-club/agroclub-shared'

import BidActions from 'modules/domain/bid/duck'
import BidSelectors from 'modules/domain/bid/selectors'
import { Filter } from 'views/components/TableFilters/TableFilters'
import { UserAddressSelect } from 'views/components/UserAddressSelect/UserAddressSelect'

type Props = {
  userId: string
}

export const UserAddressFilter: React.FC<Props> = ({ userId }) => {
  const { t } = useTranslation('bid')
  const filter = useSelector(BidSelectors.userBidFilter)
  const setFilters = useAction(BidActions.userBidFilterUpdated)
  return (
    <Filter title={t('common:address')}>
      <UserAddressSelect
        size="small"
        additionalStyles={{
          control: {
            width: 500,
          },
        }}
        placeholder={t('form.userAddressPlaceholder')}
        noOptionsMessage={() => t('form.noAddressMessage')}
        userId={userId}
        initialValue={filter?.address}
        onChange={address => {
          setFilters({ userId, filter: { ...filter, address: address.id } })
        }}
        onClear={() => {
          setFilters({ userId, filter: { ...filter, address: undefined } })
        }}
        isClearable
      />
    </Filter>
  )
}
