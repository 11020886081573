import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Input, Progress, useDidMount } from '@agro-club/agroclub-shared'
import { useFormik } from 'formik'

import { useNotificationProgress } from 'hooks/useNotificationProgress'
import usePrevious from 'hooks/usePrevious'
import { useDrivers, useDriversResetErrors } from 'modules/domain/driver/hooks'
import DriverSelectors from 'modules/domain/driver/selectors'
import { AddDriverItem, FormButton } from 'views/pages/Logistics/TripPages/TripEditor/styled'
import { ModalSmall } from 'views/ui/ModalSmall/ModalSmall'

type EditableFields = {
  name: string
  phone: string
}

type Props = {
  carId: string
  close: () => void
}

export const AddDriverPopup: React.FC<Props> = ({ carId, close }) => {
  const { t } = useTranslation('tripPages')
  const drivers = useDrivers(carId, true)
  const errorFields = useSelector(DriverSelectors.addDriverErrorFields)
  const resetErrors = useDriversResetErrors()

  useNotificationProgress(
    drivers.addProgress,
    t('tripEditor.driverNotifyAddSuccess'),
    t('tripEditor.driverNotifyAddFail'),
  )

  const prevAddProgress = usePrevious(drivers.addProgress)

  useEffect(() => {
    if (prevAddProgress === Progress.WORK && drivers.addProgress === Progress.SUCCESS) {
      close()
    }
  }, [prevAddProgress, drivers.addProgress, close])

  useDidMount(() => {
    resetErrors()
  })

  const handleSubmit = ({ name, phone }) => {
    drivers.add(carId, {
      name,
      phone,
    })
  }

  const formik = useFormik<EditableFields>({
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues: { name: '', phone: '+7' },
    onSubmit: handleSubmit,
  })

  const body = (
    <form
      onSubmit={e => {
        e.preventDefault()
        formik.dirty && formik.submitForm()
      }}
    >
      <AddDriverItem
        fieldName="name"
        errorFields={errorFields}
        formikInstance={formik}
        render={additionalProps => (
          <Input label={t('common:fio')} {...formik.getFieldProps('name')} {...additionalProps} />
        )}
      />
      <AddDriverItem
        fieldName="phone"
        errorFields={errorFields}
        formikInstance={formik}
        render={additionalProps => (
          <Input label={t('tripEditor.phoneInputLabel')} {...formik.getFieldProps('phone')} {...additionalProps} />
        )}
        width={140}
      />
    </form>
  )
  return (
    <ModalSmall
      width={448}
      content={body}
      title={t('tripEditor.addDriverPopupTitle')}
      isOpen
      close={close}
      footer={
        <>
          <FormButton
            filled
            intent="primary"
            size="small"
            type="submit"
            onClick={() => {
              formik.dirty && formik.submitForm()
            }}
            disabled={!formik.dirty}
          >
            {t('tripEditor.addDriverButton')}
          </FormButton>

          <FormButton intent="primary" size="small" onClick={close}>
            {t('common:cancel')}
          </FormButton>
        </>
      }
    />
  )
}
