import { Progress } from '@agro-club/agroclub-shared'

import { Bid, BidStatus } from 'modules/domain/bid/types'
import { DealGroup } from 'modules/domain/userBid/types'
import { AppGlobalState } from 'modules/types'

const bidList = (state: AppGlobalState, userId: string): Bid[] => state.userBid?.[userId]?.bidList || []
const archivedBids = (state: AppGlobalState, userId: string) =>
  bidList(state, userId).filter(bid => bid.status === BidStatus.archived)
const publishedBids = (state: AppGlobalState, userId: string) =>
  bidList(state, userId).filter(bid => bid.status !== BidStatus.archived)
const bidListProgress = (state: AppGlobalState, userId: string): Progress =>
  state.userBid?.[userId]?.fetchBidListProgress || []
const activeBidId = (state: AppGlobalState, userId: string): string | undefined => state.userBid?.[userId]?.activeBidId
const dealGroupListProgress = (state: AppGlobalState, userId: string): Progress =>
  state.userBid?.[userId]?.fetchDealGroupListProgress || []
const dealGroupList = (state: AppGlobalState, userId: string): DealGroup[] =>
  state.userBid?.[userId]?.dealGroupList || []

const UserBidSelectors = {
  bidList,
  archivedBids,
  publishedBids,
  bidListProgress,
  activeBidId,
  dealGroupListProgress,
  dealGroupList,
}

export default UserBidSelectors
