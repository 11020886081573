import React, { VFC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { generatePath, useHistory, useLocation, useParams } from 'react-router-dom'

import { Button, Chip, Progress, useAction, useHelmet } from '@agro-club/agroclub-shared'

import useFormatDate from 'hooks/useFormatDate'
import useLocationFrom from 'hooks/useLocationFrom'
import { useNotificationProgress } from 'hooks/useNotificationProgress'
import usePrevious from 'hooks/usePrevious'
import BidActions from 'modules/domain/bid/duck'
import BidSelectors from 'modules/domain/bid/selectors'
import { BidStatus, Bid as BidType } from 'modules/domain/bid/types'
import { CommentType } from 'modules/domain/comment/types'
import PotentialBidSelectors from 'modules/domain/potentialBid/selectors'
import { PotentialBid } from 'modules/domain/potentialBid/types'
import { endpoints } from 'modules/endpoints'
import { LocationStateFrom } from 'types/location'
import { Comments } from 'views/components/Comments/Comments'
import { TaskModal } from 'views/components/TaskModal/TaskModal'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import { Column, TwoColumn } from 'views/pages/Bid/BidAdd/styled'
import { Bid } from 'views/pages/Bid/BidDetail/Bid'
import { DeletePotentialButton } from 'views/pages/Bid/BidDetail/DeletePotentialButton'
import { useBidGoBack } from 'views/pages/Bid/hooks'
import BidRoutes from 'views/pages/Bid/routes'
import * as Header from 'views/ui/Header/Header'

import * as Styled from './styled'

type Props = {
  fetchProgress: Progress
  bid?: BidType | PotentialBid
  isPotential?: boolean
}

export const BidDetailCommon: VFC<Props> = ({ fetchProgress, bid, isPotential }) => {
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation(['bid', 'common'])
  const location = useLocation<LocationStateFrom>()
  const from = useLocationFrom()
  const formatDate = useFormatDate()
  const deleteProgress = useSelector(PotentialBidSelectors.removeProgress)
  const prevDeleteProgress = usePrevious(deleteProgress)
  const goBack = useBidGoBack()

  useNotificationProgress(deleteProgress, t('potentialDeleteSuccess'))

  useEffect(() => {
    if (deleteProgress === Progress.SUCCESS && prevDeleteProgress === Progress.WORK) {
      goBack()
    }
  }, [deleteProgress, id, goBack, prevDeleteProgress])

  useHelmet({ title: `${t('common:bid')} - ${bid?.product?.title}` })

  const changeStatus = useAction(BidActions.changeStatusRequested)
  const changeStatusProgress = useSelector(state => BidSelectors.changeStatusProgress(state, bid?.id))

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const loading = () => <ItemLoadingLayout id={id} onBack={goBack} />
  if (fetchProgress === Progress.WORK || !bid) {
    return loading()
  }

  return (
    <>
      <Layout.Header hasBottomBorder>
        <Header.Root
          onClickBack={goBack}
          rightBlock={
            <Header.ButtonWrapper>
              {!isPotential && (
                <Button filled intent="primary" onClick={() => setIsOpen(true)} size="small">
                  {t('common:setTask')}
                </Button>
              )}
              <Button
                onClick={() => {
                  history.push({
                    pathname: generatePath(isPotential ? BidRoutes.PotentialEdit : BidRoutes.Edit, { id }),
                    state: { from: location.state?.from ? { ...location.state?.from } : { ...from } },
                  })
                }}
                filled
                intent="primary"
                size="small"
              >
                {t('common:edit')}
              </Button>
              {isPotential && <DeletePotentialButton id={id} />}
              {!isPotential && (
                <Button
                  progress={changeStatusProgress}
                  disabled={bid.status === BidStatus.archived}
                  filled
                  intent="primary"
                  size="small"
                  onClick={() => {
                    changeStatus(bid.id, BidStatus.archived)
                  }}
                >
                  {t('common:archive')}
                </Button>
              )}
            </Header.ButtonWrapper>
          }
        >
          <Header.Title compact title={bid?.product?.title}>
            <Chip color="gray" active>
              {t(`bidStatuses.${bid.status}`)}
            </Chip>
          </Header.Title>

          <Header.SubTitle>
            ID {bid.id} / {bid.created && formatDate(bid.created, true)}
          </Header.SubTitle>
        </Header.Root>
      </Layout.Header>

      <Layout.Content>
        <TwoColumn>
          <Column>
            <Styled.Box>
              <Styled.Header>{t('bid')}</Styled.Header>
              <Styled.BidContent>
                <Bid bid={bid} />
              </Styled.BidContent>
            </Styled.Box>
          </Column>
          <Column>
            <Styled.RightWrapper>
              <Styled.Box>
                <Styled.Header>{t('form.userComment')}</Styled.Header>
                <Styled.CommentsContent>
                  <Comments id={bid?.owner?.id} type={CommentType.user} url={endpoints.userComments(bid?.owner?.id)} />
                </Styled.CommentsContent>
              </Styled.Box>
            </Styled.RightWrapper>
          </Column>
        </TwoColumn>
      </Layout.Content>
      <TaskModal user={bid.owner} bid={bid} isOpen={isOpen} close={() => setIsOpen(false)} />
    </>
  )
}
