import { useSelector } from 'react-redux'

import { useAction, useDidMount, useQuery } from '@agro-club/agroclub-shared'

import AuthSelectors from 'modules/domain/auth/selectors'
import DashboardActions from 'modules/domain/dashboard/duck'
import { DashboardFilter } from 'modules/domain/dashboard/types'

export const useDashboardFilterAndSortQuery = () => {
  const query = useQuery()
  const teamStr = query.get('team')
  const teamArr = teamStr ? teamStr.split(',') : undefined
  const regionStr = query.get('region')
  const regionArr = regionStr ? regionStr.split(',') : undefined
  const districtStr = query.get('district')
  const districtArr = districtStr ? districtStr.split(',') : undefined
  const assigneeStr = query.get('assignee')
  const assigneeArr = assigneeStr ? assigneeStr.split(',') : undefined

  const filter: DashboardFilter = {
    ...(teamArr && teamArr.length && { team: teamArr }),
    ...(regionArr && regionArr.length && { region: regionArr }),
    ...(districtArr && districtArr.length && { district: districtArr }),
    ...(assigneeArr && assigneeArr.length && { assignee: assigneeArr }),
  }

  return { filter }
}

export const useFilterUpdateOnMount = () => {
  const updateFilter = useAction(DashboardActions.filterUpdate)
  const currentTeam = useSelector(AuthSelectors.team)
  let { filter } = useDashboardFilterAndSortQuery()

  if (!filter?.team?.length && currentTeam?.id) {
    filter = { ...filter, team: [String(currentTeam.id)] }
  }

  useDidMount(() => {
    updateFilter(filter)
  })
}
