import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { useAction } from '@agro-club/agroclub-shared'

import { Bid } from 'modules/domain/bid/types'
import DealSelectors from 'modules/domain/deal/selectors'
import UserActions from 'modules/domain/user/duck'
import UserSelectors from 'modules/domain/user/selectors'
import { DocumentsView } from 'views/components/DocumentsView/DocumentsView'

export const PurchaseFilesSummary: React.FC = () => {
  const { t } = useTranslation(['user', 'common'])
  const { id } = useParams<{ id: string }>()
  const deal = useSelector(state => DealSelectors.item(state, id))
  const bid = (deal?.purchase_bid as Bid) || undefined
  const ownerId = bid?.owner.id
  const list = useSelector(state => UserSelectors.files(state, ownerId))
  return <div>{list.length || t('common:no')}</div>
}

export const PurchaseFiles: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const deal = useSelector(state => DealSelectors.item(state, id))
  const bid = (deal?.purchase_bid as Bid) || undefined
  const ownerId = bid?.owner.id
  const upload = useAction(UserActions.uploadDocumentsRequested)
  const fetchFiles = useAction(UserActions.documentsRequested, ownerId)

  useEffect(fetchFiles, [fetchFiles])

  const files = useSelector(state => UserSelectors.files(state, ownerId))
  const uploadProgress = useSelector(state => UserSelectors.filesUploadProgress(state, ownerId))
  const fetchProgress = useSelector(state => UserSelectors.filesFetchProgress(state, ownerId))
  const deleteDocument = useAction(UserActions.deleteDocumentRequested)
  const deleteDocumentProgress = useSelector(UserSelectors.deleteDocumentProgress)

  return (
    <DocumentsView
      files={files}
      fetchProgress={fetchProgress}
      onDelete={fileId => {
        deleteDocument(ownerId, fileId)
      }}
      deleteDocumentProgress={deleteDocumentProgress}
      onDrop={files => upload(ownerId, files)}
      uploadProgress={uploadProgress}
    />
  )
}
