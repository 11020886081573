import { Progress } from '@agro-club/agroclub-shared'
import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer'

import { AddError, RemoveError, WarehouseDTO } from 'modules/domain/types'
import { UserAddress, UserAddressDTO, UserAddressState, UserAdressItem } from 'modules/domain/userAddress/types'
import { arrToDict, getIds } from 'modules/utils/helpers'

const initialState: UserAddressState = {
  addressDict: {},
  items: {},
  deleteProgress: {},
  editProgress: {},
  addError: '',
  addErrorDetail: '',
  deleteError: {},
  deleteErrorDetail: {},
}

const DEFAULT_ITEM: UserAdressItem = {
  addressIds: [],
  addProgress: Progress.IDLE,
  listFetchProgress: Progress.WORK,
}

class UserAddressReducer extends ImmerReducer<UserAddressState> {
  listRequested(userId: string) {
    if (!this.draftState.items[userId]) {
      this.draftState.items[userId] = { ...DEFAULT_ITEM }
    } else {
      this.draftState.items[userId].listFetchProgress = Progress.WORK
    }
  }

  listRequestSucceed(userId: string, list: UserAddress[]) {
    this.draftState.items[userId].listFetchProgress = Progress.SUCCESS
    this.draftState.addressDict = {
      ...this.draftState.addressDict,
      ...arrToDict(list),
    }
    this.draftState.items[userId].addressIds = getIds(list)
  }

  listRequestFailed(userId: string) {
    this.draftState.items[userId].listFetchProgress = Progress.ERROR
  }

  addRequested(userId: string, _dto: UserAddressDTO) {
    if (!this.draftState.items[userId]) {
      this.draftState.items[userId] = {
        ...DEFAULT_ITEM,
        addProgress: Progress.WORK,
      }
      this.draftState.addError = ''
      this.draftState.addErrorDetail = ''
    } else {
      this.draftState.items[userId].addProgress = Progress.WORK
    }
  }

  addSucceed(userId: string) {
    if (!this.draftState.items[userId]) {
      this.draftState.items[userId] = {
        ...DEFAULT_ITEM,
        addProgress: Progress.SUCCESS,
      }
    } else {
      this.draftState.items[userId].addProgress = Progress.SUCCESS
    }
  }

  addFailed(userId: string, error: AddError, errorDetail?: string) {
    if (!this.draftState.items[userId]) {
      this.draftState.items[userId] = {
        ...DEFAULT_ITEM,
        addProgress: Progress.ERROR,
      }
    } else {
      this.draftState.items[userId].addProgress = Progress.ERROR
    }
    this.draftState.addError = error
    this.draftState.addErrorDetail = errorDetail
  }

  deleteRequested(_userId: string, addressId: string) {
    this.draftState.deleteProgress[addressId] = Progress.WORK
    this.draftState.deleteError[addressId] = null
    this.draftState.deleteErrorDetail[addressId] = ''
  }

  deleteSucceed(userId: string, addressId: string) {
    this.draftState.items[userId] = {
      ...this.draftState.items[userId],
      addressIds: this.draftState.items[userId].addressIds.filter(id => String(addressId) !== String(id)),
    }
    this.draftState.deleteProgress[addressId] = Progress.SUCCESS
  }

  deleteFailed(addressId: string, error: RemoveError, errorDetail?: string) {
    this.draftState.deleteProgress[addressId] = Progress.ERROR
    this.draftState.deleteError[addressId] = error
    this.draftState.deleteErrorDetail[addressId] = errorDetail || ''
  }

  editRequested(_userId: string, addressId: string, _dto: WarehouseDTO) {
    this.draftState.editProgress[addressId] = Progress.WORK
  }

  editSucceed(addressId: string, dto: WarehouseDTO) {
    this.draftState.addressDict[addressId] = {
      ...this.draftState.addressDict[addressId],
      ...dto,
    }
    this.draftState.editProgress[addressId] = Progress.SUCCESS
  }

  editFailed(addressId: string) {
    this.draftState.editProgress[addressId] = Progress.ERROR
  }

  itemRequestSucceed(item: UserAddress) {
    this.draftState.addressDict[item.id] = item
  }
}

export const UserAddressActions = createActionCreators(UserAddressReducer)
export default UserAddressActions
export const reducer = createReducerFunction(UserAddressReducer, initialState)
