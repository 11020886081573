import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'

import { UserContact, UserContactDTO } from './types'

export const getList = (userId: string) => apiClient.get<UserContact[]>(endpoints.userContacts(userId))

export const addItem = (userId: string, dto: UserContactDTO) =>
  apiClient.post<UserContact>(endpoints.userContacts(userId), dto)

export const editItem = (userId: string, addressId: string, dto: UserContactDTO) =>
  apiClient.put<UserContact>(endpoints.userContacts(userId, addressId), dto)

export const deleteItem = (userId: string, addressId: string) =>
  apiClient.delete<unknown>(endpoints.userContacts(userId, addressId))
