import { all, call, put, takeLatest } from 'redux-saga/effects'

import UserContactActions from './duck'
import * as managers from './managers'

export const fetchList = function* ({ payload: userId }: ReturnType<typeof UserContactActions.listRequested>) {
  try {
    const list = yield call(managers.getList, userId)
    yield put(UserContactActions.listRequestSucceed(userId, list))
  } catch (err) {
    yield put(UserContactActions.listRequestFailed(userId))
  }
}

export const addItem = function* ({ payload: [userId, dto] }: ReturnType<typeof UserContactActions.addRequested>) {
  try {
    yield call(managers.addItem, userId, dto)
    yield put(UserContactActions.listRequested(userId))
    yield put(UserContactActions.addSucceed(userId))
  } catch (err) {
    yield put(UserContactActions.addFailed(userId))
  }
}

export const deleteItem = function* ({
  payload: [userId, addressId],
}: ReturnType<typeof UserContactActions.deleteRequested>) {
  try {
    yield call(managers.deleteItem, userId, addressId)
    yield put(UserContactActions.deleteSucceed(userId, addressId))
  } catch (err) {
    yield put(UserContactActions.deleteFailed(userId, addressId))
  }
}

export const editItem = function* ({
  payload: [userId, addressId, dto],
}: ReturnType<typeof UserContactActions.editRequested>) {
  try {
    yield call(managers.editItem, userId, addressId, dto)
    yield put(UserContactActions.listRequested(userId))
    yield put(UserContactActions.editSucceed(userId, addressId))
  } catch (err) {
    yield put(UserContactActions.editFailed(userId, addressId))
  }
}

const UserContactSaga = function* () {
  yield all([
    takeLatest(UserContactActions.listRequested.type, fetchList),
    takeLatest(UserContactActions.addRequested.type, addItem),
    takeLatest(UserContactActions.deleteRequested.type, deleteItem),
    takeLatest(UserContactActions.editRequested.type, editItem),
  ])
}

export default UserContactSaga
