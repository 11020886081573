import { Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import { CrossButton } from 'views/ui/CrossButton/CrossButton'

export const Content = styled.div`
  position: relative;
  width: 325px;
`

export const Cross = styled(CrossButton)`
  position: absolute;
  top: 12px;
  right: 21px;
`

export const Header = styled.div`
  padding: 16px 24px;
  ${Typography.title3Semibold};
`

export const Body = styled.div`
  padding: 0 24px 24px;
`

export const Footer = styled.div`
  display: grid;
  gap: 12px;
  grid-auto-flow: column;
  padding: 0 24px 16px;
`

export const BackgroundOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1;
`
