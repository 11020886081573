import * as React from 'react'

import { useTheme } from 'styled-components'

export const CurvedArrow: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  const { fill, ...rest } = props
  const theme = useTheme()
  return (
    <svg width={8} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M3.173 15.459L.448 12.734V20h7.266l-2.725-2.725a10.273 10.273 0 000-14.532A10.155 10.155 0 001.2.346C.596.13
          0 .608 0 1.249v.657c0 .443.294.826.703.995.899.371 1.74.929 2.47 1.658a7.713 7.713 0 010 10.9z"
        fill={fill || theme.color.secondary200}
      />
    </svg>
  )
}
