import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { generatePath, useHistory, useLocation } from 'react-router-dom'

import { Progress, useAction } from '@agro-club/agroclub-shared'
import { FormikContextType, useFormik } from 'formik'
import * as Yup from 'yup'

import { useNotificationProgress } from 'hooks/useNotificationProgress'
import usePrevious from 'hooks/usePrevious'
import BidActions from 'modules/domain/bid/duck'
import BidSelectors from 'modules/domain/bid/selectors'
import { AgroundImputs, ParameterCondition, TaxRegimes } from 'modules/domain/bid/types'
import PotentialBidActions from 'modules/domain/potentialBid/duck'
import PotentialBidSelectors from 'modules/domain/potentialBid/selectors'
import { User } from 'modules/domain/user/types'
import { LocationStateFrom } from 'types/location'
import { BidAddFields } from 'views/pages/Bid/BidAdd/types'
import BidRoutes from 'views/pages/Bid/routes'

export const InitialMainParameter = {
  parameter: undefined,
  condition: Object.keys(ParameterCondition)[1] as keyof typeof ParameterCondition,
  parameter_value: undefined,
}

export const useBidAddFormik = (isPotential: boolean, user?: User): [Progress, FormikContextType<BidAddFields>] => {
  const { t } = useTranslation('bid')
  const createAction = useAction(BidActions.addRequested)
  const createPotentialAction = useAction(PotentialBidActions.addRequested)
  const progress = useSelector(BidSelectors.addProgress)
  const potentialProgress = useSelector(PotentialBidSelectors.addProgress)
  const prevProgress = usePrevious(progress)
  const prevPotentialProgress = usePrevious(potentialProgress)
  const lastAddedId = useSelector(BidSelectors.lastAddedId)
  const potentialLastAddedId = useSelector(PotentialBidSelectors.lastAddedId)

  useNotificationProgress(progress, t('form.notifySuccess'))
  useNotificationProgress(potentialProgress, t('form.notifySuccess'))

  const location = useLocation<LocationStateFrom>()
  const history = useHistory()
  const from = location.state?.from

  useEffect(() => {
    if (progress === Progress.SUCCESS && prevProgress === Progress.WORK && lastAddedId) {
      history.push({
        pathname: generatePath(BidRoutes.Detail, { id: lastAddedId }),
        state: { from },
      })
    }
  }, [progress, lastAddedId, prevProgress, history, from])

  useEffect(() => {
    if (potentialProgress === Progress.SUCCESS && prevPotentialProgress === Progress.WORK && potentialLastAddedId) {
      history.push({
        pathname: generatePath(BidRoutes.PotentialDetail, { id: potentialLastAddedId }),
        state: { from },
      })
    }
  }, [potentialProgress, potentialLastAddedId, prevPotentialProgress, history, from])

  const validationSchema = useMemo(
    () =>
      Yup.object({
        company: Yup.string().required(),
        owner: Yup.string().required(),
        address: Yup.string().required(),
        product: Yup.string().required(),
        ...(!isPotential && {
          price: Yup.string().required(),
          quantity: Yup.string().required(),
          aground_imputs: Yup.string().required(),
          tax_regime: Yup.string().required(),
          mainParameter: Yup.object({
            condition: Yup.string().required(),
            parameter: Yup.number().required(),
            parameter_value: Yup.number().required(),
          }),
        }),
      }),
    [isPotential],
  )

  const formik = useFormik<BidAddFields>({
    validationSchema,
    initialValues: {
      company: user?.profile?.company?.id || undefined,
      owner: user?.id || undefined,
      address: undefined,
      product: undefined,
      price: undefined,
      quantity: undefined,
      parameters: [],
      mainParameter: InitialMainParameter,
      aground_imputs: AgroundImputs.YES,
      tax_regime: TaxRegimes.ACTUAL_PROFIT,
      bid_tag: undefined,
    },
    enableReinitialize: false,
    validateOnChange: false,
    validateOnBlur: false,

    onSubmit: () => {
      if (!formik.isValid) {
        return
      }
      const { mainParameter, parameters } = formik.values
      const dto = {
        company: formik.values.company,
        quantity: formik.values.quantity,
        price: formik.values.price,
        product: formik.values.product,
        owner: formik.values.owner,
        address: formik.values.address,
        tax_regime: formik.values.tax_regime,
        aground_imputs: formik.values.aground_imputs,
        bid_tag: formik.values.bid_tag,
        parameters: [...parameters, mainParameter].filter(p => typeof p.parameter_value !== 'undefined'),
      }
      if (isPotential) {
        createPotentialAction({ dto })
      } else {
        createAction({ dto: { ...dto, quantity: formik.values.quantity ?? undefined } })
      }
    },
  })

  return [isPotential ? potentialProgress : progress, formik]
}
