import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useAction } from '@agro-club/agroclub-shared'
import { isNil } from 'ramda'

import CallsActions from 'modules/domain/calls/duck'
import CallsSelectors from 'modules/domain/calls/selectors'
import { Filters } from 'views/components/CommonTableComponents/CommonTableComponents'
import { ResetFilterButton } from 'views/components/TableFilters/ResetFilterButton'
import { Filter } from 'views/components/TableFilters/TableFilters'
import { CallStartFilter } from 'views/pages/Calls/CallsTable/CallStartFilter'
import { CallTypeFilter } from 'views/pages/Calls/CallsTable/CallTypeFilter'
import { CoordinatorFilter } from 'views/pages/Calls/CallsTable/CoordinatorFilter'

const CallsFilter: React.FC = () => {
  const { t } = useTranslation('calls')

  const filterUpdated = useAction(CallsActions.filterUpdated)
  const filterState = useSelector(CallsSelectors.filter)

  const handleClearFilters = useCallback(() => {
    filterUpdated({})
  }, [filterUpdated])

  const isFilterApplied = Object.values(filterState).some(value => !isNil(value))

  return (
    <Filters>
      <Filter title={t('call_type')}>
        <CallTypeFilter />
      </Filter>
      <Filter title={t('coordinator')}>
        <CoordinatorFilter />
      </Filter>
      <Filter title={t('call_start')}>
        <CallStartFilter />
      </Filter>
      <ResetFilterButton {...{ handleClearFilters, isFilterApplied }} />
    </Filters>
  )
}

export default CallsFilter
