import { ErrorInfo } from 'react'
import { Action, Middleware } from 'redux'

import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'

import env from 'env'
import { AppGlobalState } from 'modules/types'

import AuthActions from './modules/domain/auth/duck'

function isInitSucceedAction(action: Action): action is ReturnType<typeof AuthActions.initSucceed> {
  return action.type === AuthActions.initSucceed.type
}

class SentryAdapter {
  sentryInstance: typeof import('@sentry/browser') | null

  constructor() {
    this.sentryInstance = null
  }

  async init() {
    if (env.SENTRY_DSN) {
      this.sentryInstance = await import('@sentry/browser')
      this.sentryInstance.init({ dsn: env.SENTRY_DSN })
    }
  }

  handleAxiosRequest(config: AxiosRequestConfig) {
    if (!this.sentryInstance) {
      return
    }
    this.sentryInstance.addBreadcrumb({
      category: 'request',
      type: 'http',
      data: {
        type: 'initiated',
        url: config.url,
        params: config.params,
        data: config.data,
      },
    })
  }

  handleAxiosError(error: AxiosError) {
    if (!this.sentryInstance) {
      return
    }
    this.sentryInstance.addBreadcrumb({
      category: 'request',
      type: 'http',
      data: {
        path: error.config.url,
        method: error.config.method,
        type: 'error',
        error: {
          message: error.message,
          name: error.name,
        },
      },
    })

    this.sentryInstance.withScope(scope => {
      const sentry = this.sentryInstance
      if (!sentry) {
        return
      }

      const httpStatus = error.response?.status?.toString() || 'unknown'
      const apiHandle = error.config.url as string

      if (error.response?.status && error.response.status < 500) {
        scope.setLevel('warning')
      } else {
        scope.setLevel('error')
      }

      scope.setTag('kind', 'apiRequest')
      scope.setTag('apiHandle', apiHandle)
      scope.setTag('httpStatus', httpStatus)
      scope.setExtras({
        httpStatus,
        apiHandle,
        method: error.config.method,
        query: error.config.params,
        data: error.config.data,
        response: JSON.stringify(error?.response?.data),
      })
      const newError = new Error(`${error.message} ${error.config.method} ${apiHandle}`, { cause: error })
      sentry.captureException(newError, {
        fingerprint: [apiHandle],
      })
    })
  }

  handleAxiosResponse(response: AxiosResponse) {
    if (!this.sentryInstance) {
      return
    }
    this.sentryInstance.addBreadcrumb({
      category: 'request',
      type: 'http',
      data: {
        path: response.config.url,
        method: response.config.method,
        type: 'response',
      },
    })
  }

  handleReactException(error: Error, errorInfo: ErrorInfo, storeSnapshot: AppGlobalState) {
    if (!this.sentryInstance) {
      return
    }
    this.sentryInstance.withScope(scope => {
      scope.setExtra('ComponentStack', errorInfo.componentStack)
      scope.setExtra('ReduxStoreSnapshot', storeSnapshot)
      if (this.sentryInstance) {
        this.sentryInstance.captureException(error)
      }
    })
  }

  handleReduxAction(action: Action) {
    if (!this.sentryInstance) {
      return
    }
    if (isInitSucceedAction(action)) {
      this.sentryInstance.setUser({ id: action.payload.id, username: action.payload.phone })
    }
    this.sentryInstance.addBreadcrumb({
      category: 'redux-action',
      message: action.type,
    })
  }
}

const Singleton = new SentryAdapter()

export const middleware: Middleware = _store => next => action => {
  Singleton.handleReduxAction(action)
  return next(action)
}

export const Sentry = Singleton
